import { Component, Inject, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { DefaultValuesService } from "../../services/defaultvalues.service";

@Component({
    selector: "operationmode-opcounters-list",
    templateUrl: './operationmode-list-opcounters.component.html',
    styleUrls: ['../../style/list.component.less', './operationmode-list.component.less']
})

export class OperationModeOperationalCountersListComponent implements OnInit{

    @Input() selected: OperationMode | undefined;
    list: OperationMode[] = [];

    header: any[] = [
        { label: 'Is out of Service?', show: true, searchtype: 'checkbox', checkbox: true, title:"Reference item: User story #1279. 'Out of service' is any mode of operation that prevents the equipment from performing its normal function of automatically servicing hall calls." }
        , { label: 'Operation Hours?', show: true, searchtype: 'checkbox', checkbox: true, title: "Operating Hours is any time the elevator is operating in a mode where the customer still has use and control of the equipment and the equipment is not blocked due to maintenance being performed, or a failure, or any other equipment defect that is the responsibility of TKE to prevent or repair after the fact." }
        , { label: 'Breakdown Hours?', show: true, searchtype: 'checkbox', checkbox: true, title: "Breakdown Hours is any time the elevator is operating in a mode where the equipment is blocked due to a failure, or any other equipment defect that is the responsibility of TKE to prevent or repair after the fact." }
        , { label: 'Is Available?', show: true, searchtype: 'checkbox', checkbox: true, title: "This is the exact opposite of PTOR item #1279 (Out of service). It is any mode of operation that allows the equipment to perform its normal function of automatically servicing hall calls." }
        , { label: 'Special Trip?', show: true, searchtype: 'checkbox', checkbox: true, title: "Any operation mode activated by the customer that behaves in a certain way while ignoring all hall calls (it is under control of the customer)." }
    ];

    constructor(private activatedRoute: ActivatedRoute,
        private router: Router
        , private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , public defaults: DefaultValuesService
    ) {
    }

    ngOnInit() {
        if(this.selected)
            this.list.push(this.selected);
    }

    /**
     * this is from who calls (inner component)
     * @param list
     * @param row
     * @param column
     */
    getData(list: OperationMode[], row: number, column: number): string {
        if (list) {
            let e = list[row];
            switch (column) {
                case 0:
                    return e.OpStatusOutService ? "1" : "0";
                case 1:
                    return e.OpHours ? "1" : "0";
                case 2:
                    return e.BrkHours ? "1" : "0";
                case 3:
                    return e.UnitAvailable ? "1" : "0";
                default:
                    return e.SpecialTrips ? "1" : "0";
            }
        }
        else
            return "";
    }

    //isToShowOpStatusOutService(index: number) {
    //    if (this.list)
    //        return (this.list[index].OpStatusOutService != null);
    //    return false;
    //}

    //isToShowOpHours(index: number) {
    //    if (this.list)
    //        return (this.list[index].OpHours != null);
    //    return false;
    //}

    //isToShowBrkHours(index: number) {
    //    if (this.list)
    //        return (this.list[index].BrkHours != null);
    //    return false;
    //}

    //isToShowUnitAvailable(index: number) {
    //    if (this.list)
    //        return (this.list[index].UnitAvailable != null);
    //    return false;
    //}

    //isToShowSpecialTrips(index: number) {
    //    if (this.list)
    //        return (this.list[index].SpecialTrips != null);
    //    return false;
    //}
}