<div class="userhistoryDialogue">
    <mat-toolbar class="header heading-background">
        <div class="col-lg-6">
            <h5>
                Lever {{label}} Changelog
            </h5>
        </div>

    </mat-toolbar>
    <div #tableBody class="custom_small_panelbody_fix">
        <table #historyTable mat-table
               [dataSource]="dataSource"
               multiTemplateDataRows
               class="mat-elevation-z8 mat-table-custom">

            <ng-container matColumnDef="stage">
                <th mat-header-cell *matHeaderCellDef>Stage</th>
                <td mat-cell *matCellDef="let row">
                    {{row.ExcellenceStage}}
                </td>
            </ng-container>
            <ng-container matColumnDef="contact">
                <th mat-header-cell *matHeaderCellDef>Contact</th>
                <td mat-cell *matCellDef="let row">
                    {{row.Contact}}
                </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef>Action</th>
                <td mat-cell *matCellDef="let row">
                    {{row.Action}}
                </td>
            </ng-container>
            <ng-container matColumnDef="notes">
                <th mat-header-cell *matHeaderCellDef>Notes</th>
                <td mat-cell *matCellDef="let row">
                    {{row.Notes}}
                </td>
            </ng-container>

            <ng-container matColumnDef="bestpractices">
                <th mat-header-cell *matHeaderCellDef>Best Practices</th>
                <td mat-cell *matCellDef="let row">
                    {{row.BestPractices}}
                </td>
            </ng-container>
            <ng-container matColumnDef="user">
                <th mat-header-cell *matHeaderCellDef>User</th>
                <td mat-cell *matCellDef="let row">
                    {{row.CreatedBy}}
                </td>
            </ng-container>
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef>UTC Date</th>
                <td mat-cell *matCellDef="let row">
                    {{datePipe.transform(row.CreatedAtUtc,'yyyy-MM-dd HH:mm')}}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky:true;"></tr>
            <tr mat-row *matRowDef="let row; columns: columnsToDisplay;">
            </tr>
        </table>
    </div>
    <br />
    <div style="text-align:center">
        <button mat-raised-button class="btn button-color-fix_red " (click)="close()">
            <mat-icon class=" icon-color-fix ">arrow_back_ios</mat-icon>
            Back
        </button>
    </div>
</div>



