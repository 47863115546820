
<div class="internalMax_Custom">
    <form [formGroup]="internalMaxForm" class="internalMax" (ngSubmit)="onSubmit()">
        <div class="row">
            <div class="col-sm-6 form-group">

                <!--Username Region-->
                <mat-form-field appearance="outline">
                    <mat-label> UserName </mat-label>
                    <input matInput formControlName="userName" required (keyup)="changeEmail($event)">
                    <mat-spinner #spinner matSuffix [diameter]="18" style="float: right; margin-left: 8px; visibility:hidden"></mat-spinner>
                </mat-form-field>
                <p *ngIf="
          internalMaxForm.get('userName')?.invalid &&
            (internalMaxForm.get('userName')?.dirty ||
              internalMaxForm.get('userName')?.touched)">
                    <mat-error *ngIf="internalMaxForm.get('userName')?.hasError('required')">This field is required</mat-error>
                    <mat-error *ngIf="internalMaxForm.get('userName')?.hasError('alphanumericError')">
                        {{internalMaxForm?.get('userName') ? internalMaxForm['userName']?.errors['alphanumericError'] : ''}}
                    </mat-error>
                </p>
                <p igIf="emailError">
                    <mat-error>{{emailError}}</mat-error>
                </p>
                <p *ngIf="!(
          internalMaxForm.get('userName')?.invalid &&
            (internalMaxForm.get('userName')?.dirty ||
              internalMaxForm.get('userName')?.touched)) && !emailError"><br> </p>

            </div>
            <div class="col-sm-6 form-group">
                <br />
                <p>{{domain}}</p>
            </div>

        </div>

        <div class="row">
            <div class="col-sm-6 form-group">

                <mat-form-field appearance="outline">
                    <mat-label> First Name </mat-label>
                    <input matInput formControlName="firstName" required>
                </mat-form-field>
                <p *ngIf="
          internalMaxForm.get('firstName')?.invalid &&
            (internalMaxForm.get('firstName')?.dirty ||
              internalMaxForm.get('firstName')?.touched)">
                    <mat-error *ngIf="internalMaxForm.get('firstName')?.hasError('required')">This field is required</mat-error>
                </p>
                <p *ngIf="!(
          internalMaxForm.get('firstName')?.invalid &&
            (internalMaxForm.get('firstName')?.dirty ||
              internalMaxForm.get('firstName')?.touched))"><br> </p>

            </div>
            <div class="col-sm-6 form-group">

                <mat-form-field appearance="outline">
                    <mat-label> Last Name </mat-label>
                    <input matInput formControlName="lastName" required>
                </mat-form-field>
                <p *ngIf="
          internalMaxForm.get('lastName')?.invalid &&
            (internalMaxForm.get('lastName')?.dirty ||
              internalMaxForm.get('lastName')?.touched)">
                    <mat-error *ngIf="internalMaxForm.get('lastName')?.hasError('required')">This field is required</mat-error>
                </p>
                <p *ngIf="!(
          internalMaxForm.get('lastName')?.invalid &&
            (internalMaxForm.get('lastName')?.dirty ||
              internalMaxForm.get('lastName')?.touched))"><br> </p>
            </div>
        </div>
        <br />

        <div class="row">
            <div class="col-sm-12 form-group">

                <!--Displayname Region-->
                <mat-form-field appearance="outline">
                    <mat-label> Display Name </mat-label>
                    <input matInput formControlName="displayName" required>
                </mat-form-field>
                <p *ngIf="
          internalMaxForm.get('displayName')?.invalid &&
            (internalMaxForm.get('displayName')?.dirty ||
              internalMaxForm.get('displayName')?.touched)">
                    <mat-error *ngIf="internalMaxForm.get('displayName')?.hasError('required')">This field is required</mat-error>
                </p>
                <p *ngIf="!(
          internalMaxForm.get('displayName')?.invalid &&
            (internalMaxForm.get('displayName')?.dirty ||
              internalMaxForm.get('displayName')?.touched))"><br> </p>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-6 form-group">


                <!--Password Region-->
                <div>
                    <mat-form-field appearance="outline">
                        <mat-label>Password</mat-label>
                        <input matInput formControlName="password" required [type]="passHide ? 'password' : 'text'">
                        <button mat-icon-button matSuffix (click)="passHideFunc($event)" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="passHide">
                            <mat-icon>{{passHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                        <button mat-icon-button matSuffix (click)="genPass($event)">
                            <mat-icon>settings_suggest</mat-icon>
                        </button>
                    </mat-form-field>

                    <p *ngIf="
          internalMaxForm.get('password')?.invalid &&
            (internalMaxForm.get('password')?.dirty ||
              internalMaxForm.get('password')?.touched)">
                        <mat-error *ngIf="internalMaxForm.get('password')?.hasError('required')">This field is required</mat-error>
                        <mat-error *ngIf="internalMaxForm.get('password')?.hasError('passwordStrength')">
                            {{internalMaxForm?.get('password') ? internalMaxForm['password']?.errors['passwordStrength'] : ''}}
                        </mat-error>
                        <mat-error *ngIf="internalMaxForm.get('password')?.hasError('passwordContained')">
                            Password cannot be contained in UserName
                        </mat-error>
                    </p>
                    <p *ngIf="!(
          internalMaxForm.get('password')?.invalid &&
            (internalMaxForm.get('password')?.dirty ||
              internalMaxForm.get('password')?.touched))"><br> </p>
                </div>


            </div>
            <div class="col-sm-6 form-group">

                <!--Confirm Password Region-->
                <div>
                    <mat-form-field appearance="outline">
                        <mat-label>Confirm Password</mat-label>
                        <input matInput formControlName="confirmPassword" required [type]="confirmPassHide ? 'password' : 'text'">
                        <button mat-icon-button matSuffix (click)="handleConfirmPass($event)" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="confirmPassHide">
                            <mat-icon>{{confirmPassHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                    </mat-form-field>


                    <p *ngIf="
    internalMaxForm.get('confirmPassword')?.invalid &&
      (internalMaxForm.get('confirmPassword')?.dirty ||
        internalMaxForm.get('confirmPassword')?.touched)">
                        <mat-error *ngIf="internalMaxForm.get('confirmPassword')?.hasError('required')">This field is required</mat-error>
                        <mat-error *ngIf="internalMaxForm.get('confirmPassword')?.hasError('confirmPasswordValidator')">
                            Confirm Password is not same
                        </mat-error>
                    </p>
                    <p *ngIf="!(
          internalMaxForm.get('confirmPassword')?.invalid &&
            (internalMaxForm.get('confirmPassword')?.dirty ||
              internalMaxForm.get('confirmPassword')?.touched))"><br> </p>
                </div>

            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 form-group">

                <p class="darkBackground"><mat-checkbox formControlName="boolPassChange">Must change password next login</mat-checkbox></p>

            </div>
        </div>

        <div class="row">
            <div class="col-sm-4 form-group">
                <br />
                <mat-label> <span>Tenant</span><span class="orange-asterisk"> *</span></mat-label>


            </div>
            <div class="col-sm-8 form-group">

                <!--Primary Tenant Drop Down-->
                <ng-select [items]="tenants"
                           [searchable]="true"
                           [clearable]="false"
                           class="tenantStyling"
                           bindLabel="text"
                           bindValue="value"
                           formControlName="tenant"
                           required>
                </ng-select>

                <br>

            </div>
        </div>

        <div class="row">
            <div class="col-sm-4 form-group">
                <br />
                <mat-label>Secondary Tenant</mat-label>


            </div>
            <div class="col-sm-8 form-group">

                <!--Secondary Tenant Drop Down-->
                <ng-select (change)="onSecTenChange()"
                           [matTooltip]="selectedSecTenString"
                           [matTooltipPosition]="'above'"
                           [items]="secondaryTenants"
                           groupBy="selectedAllGroup"
                           [selectableGroup]="true"
                           [selectableGroupAsModel]="false"
                           [searchable]="true"
                           [clearable]="true"
                           [closeOnSelect]="false"
                           [multiple]="true"
                           bindLabel="text"
                           bindValue="value"
                           formControlName="secondaryTenant">

                    <ng-template ng-optgroup-tmp>
                        Select All
                    </ng-template>

                    <ng-template ng-option-tmp
                                 let-item="item">
                        {{ item.text }}
                    </ng-template>

                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <div class="items-selected" *ngIf="this.internalMaxForm.controls['secondaryTenant'].value.length <= 2">
                            <div class="ng-value" *ngFor="let item of this.internalMaxForm.controls['secondaryTenant'].value ">
                                <span class="ng-value-label">{{item}}</span>

                            </div>
                        </div>

                        <div class="ng-value" *ngIf="items.length > 2">
                            <span class="ng-value-label">{{items.length}} Selected...</span>
                        </div>

                    </ng-template>

                </ng-select>
                <br>

            </div>
        </div>

        <div class="row">
            <div class="col-sm-4 form-group">
                <br />
                <mat-label>Email</mat-label>


            </div>
            <div class="col-sm-8 form-group">

                <!--Email Region-->
                <div>
                    <mat-form-field appearance="outline">
                        <mat-label>Email</mat-label>
                        <input matInput placeholder="pat@example.com" formControlName="email">
                    </mat-form-field>

                    <p *ngIf="
                          internalMaxForm.get('email')?.invalid &&
                            (internalMaxForm.get('email')?.dirty ||
                              internalMaxForm.get('email')?.touched)">
                        <!--<mat-error *ngIf="internalMaxForm.get('email').hasError('required')">This field is required</mat-error>-->
                        <mat-error *ngIf="internalMaxForm.get('email')?.hasError('pattern')">
                            Enter valid email
                        </mat-error>
                    </p>
                    <p *ngIf="!(
          internalMaxForm.get('email')?.invalid &&
            (internalMaxForm.get('email')?.dirty ||
              internalMaxForm.get('email')?.touched))"><br> </p>
                </div>

            </div>
        </div>


        <div class="row">
            <div class="panel-body">
                <button (click)="backButtonHandler($event)" mat-raised-button class="btn  button-color-fix_red ">
                    <mat-icon class=" icon-color-fix ">arrow_back_ios</mat-icon>
                    Back
                </button>

                <button mat-raised-button type="submit" class="btn button-color-fix pull-right" [disabled]="!internalMaxForm.valid || emailError || waiting4Mail">
                    Create
                    <mat-icon class=" icon-color-fix ">person</mat-icon>
                </button>

            </div>
        </div>

    </form>
</div>
