<div class='left-menu'>
    <ul class='nav navbar-nav'>
        <li [routerLinkActive]="['link-active']" *ngIf="!defaults.isReadonlyUser">
            <a class='menu-callback' (click)="onCreateHierarchyKey()" title="Create a new Branch">
                <span class='glyphicon glyphicon-plus-sign'></span> New Branch
            </a>
        </li>
        <!--<li [routerLinkActive]="['link-active']">
            <a class='menu-callback' (click)="onReload.emit()" title="Refresh">
                <span class='glyphicon glyphicon-refresh'></span> Refresh
            </a>
        </li>-->
    </ul>
</div>