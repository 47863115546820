<div style="display:flow-root">
    <div style="float:left">
        <h5>This is the EMT Peripheral Software management screen. </h5>
    </div>
</div>
<div class="flex-center">
    <div class="contentWidth">
        <div style="height: calc(90vh - 100px); overflow-y: auto;">
            <form [formGroup]="PSForm" autocomplete="off">
                <ng-container formArrayName="PSRows">
                    <table #objTable
                           mat-table
                           matSort
                           [dataSource]="dataSource"
                           class="table-add-butt-header-orange mat-elevation-z8 mat-table-custom">

                        <ng-container matColumnDef="headertitle">
                            <th class="title-col" mat-header-cell *matHeaderCellDef colspan="5">
                                <div class="pull-left">
                                    <h4>Peripheral Software Versions</h4>
                                </div>
                            </th>
                        </ng-container>
                        <ng-container matColumnDef="headeraddbutton">
                            <th class="butt-col" mat-header-cell *matHeaderCellDef>
                                <div class="OrangeButton">
                                    <button mat-raised-button (click)="addNewRow()"
                                            matTooltip="Add Version" class="button-color-fix pull-right">
                                        <mat-icon class="icon-color-fix icon-padding-fix">add_box</mat-icon>
                                    </button>
                                </div>
                            </th>
                        </ng-container>

                        <!-- Version Column -->
                        <ng-container matColumnDef="version">
                            <th mat-header-cell *matHeaderCellDef style="width:20%">Version</th>
                            <td mat-cell *matCellDef="let element; let i = index" style="width:20%">
                                <div [innerHTML]="PSForm?.get('PSRows')?.value[i].peripheralSoftwareVersion" *ngIf="!PSForm?.get('PSRows')?.value[i]?.isNewRow"></div>
                                <mat-form-field style="width: 100%" *ngIf="PSForm?.get('PSRows')?.value[i]?.isNewRow">
                                    <input matInput type="text"
                                           [ngModelOptions]="{standalone: true}"
                                           [(ngModel)]="PSForm?.get('PSRows')?.value[i].peripheralSoftwareVersion"
                                           maxlength="50"
                                           matTooltip="Max 50 characters"
                                           (keydown)="checkInput($event)" />
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <!-- Family Code Column -->
                        <ng-container matColumnDef="familycode">
                            <th mat-header-cell *matHeaderCellDef style="width:20%">Family Code</th>
                            <td mat-cell *matCellDef="let element; let i = index" style="width:20%">
                                <div [innerHTML]="getFamilyCodeText(PSForm?.get('PSRows')?.value[i].familyCodeId)" *ngIf="!PSForm?.get('PSRows')?.value[i]?.isNewRow"></div>
                                <mat-form-field floatLabel="never" *ngIf="PSForm?.get('PSRows')?.value[i]?.isNewRow">
                                    <mat-select style="min-width: 200px; color:red" [(ngModel)]="PSForm?.get('PSRows')?.value[i].familyCodeId"
                                                [ngModelOptions]="{standalone: true}">
                                        <mat-option [value]="-1" [disabled]="true">Select</mat-option>
                                        <mat-option *ngFor="let fc of availableFamilyCodes" [value]="fc.familyCodeId">
                                            {{getFamilyCodeText(fc.familyCodeId)}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <!-- Software file Column -->
                        <ng-container matColumnDef="file">
                            <th mat-header-cell *matHeaderCellDef style="width:25%">Software File</th>
                            <td mat-cell *matCellDef="let element; let i = index" style="width:25%">
                                <div [innerHTML]="PSForm?.get('PSRows')?.value[i].softwareFileName" *ngIf="!PSForm?.get('PSRows')?.value[i]?.isNewRow"></div>
                                <input id={{getFileUploadId(i)}} type="file" *ngIf="PSForm?.get('PSRows')?.value[i]?.isNewRow"
                                       class="form-control"
                                       (change)="onFileChange($event, i)" />
                            </td>
                        </ng-container>

                        <!-- Release Date Column -->
                        <ng-container matColumnDef="releasedate">
                            <th mat-header-cell *matHeaderCellDef style="width:15%">Release UTC Date</th>
                            <td mat-cell *matCellDef="let element; let i = index" style="width:15%">
                                <div [innerHTML]="datePipe.transform(PSForm?.get('PSRows')?.value[i]?.releaseDate,'yyyy-MM-dd')" *ngIf="PSForm?.get('PSRows')?.value[i]?.isEditable"></div>
                                <mat-form-field *ngIf="!PSForm?.get('PSRows')?.value[i]?.isEditable">
                                    <input matInput [matDatepicker]="picker"
                                           [ngModelOptions]="{standalone: true}"
                                           [min]="minDate"
                                           [(ngModel)]="PSForm.get('PSRows')?.value[i].releaseDate"
                                           (dateChange)="getDateUTCChange($event, i)" />
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <!-- Comment Column -->
                        <ng-container matColumnDef="comment">
                            <th mat-header-cell *matHeaderCellDef style="width:15%">Comment</th>
                            <td mat-cell *matCellDef="let element; let i = index" style="width:15%">
                                <ng-container>
                                    <!-- This span triggers the overlay and is it's origin -->
                                    <span (click)="toggleOvl(i)" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
                                        {{getCommentText(i)}}
                                    </span>

                                    <!-- This template displays the overlay content and is connected to the span -->
                                    <ng-template cdkConnectedOverlay
                                                 [cdkConnectedOverlayOrigin]="trigger"
                                                 [cdkConnectedOverlayOpen]="PSForm?.get('PSRows')?.value[i].isOpenOverlay"
                                                 [cdkConnectedOverlayHasBackdrop]="PSForm?.get('PSRows')?.value[i].isOpenOverlay"
                                                 (backdropClick)="toggleOvl(i)"
                                                 (attach)="fitTextAreaToContent(i)"
                                                 [cdkConnectedOverlayOffsetY]="5">
                                        <textarea id={{getTextAreaId(i)}}
                                                  oninput='this.style.height = "";this.style.height = this.scrollHeight + 3 + "px"'
                                                  style="max-height:250px"
                                                  [ngModelOptions]="{standalone: true}"
                                                  [(ngModel)]="PSForm?.get('PSRows')?.value[i].comment"
                                                  maxlength="2000"
                                                  matTooltip="Max 2000 characters"
                                                  class="form-control"></textarea>
                                    </ng-template>
                                </ng-container>

                            </td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef style="width:5%"></th>
                            <td mat-cell
                                *matCellDef="let element; let i = index" style="width:5%">

                                <button mat-icon-button
                                        *ngIf="!PSForm.get('PSRows')?.value[i]?.isEditable"
                                        matTooltip="Cancel Changes"
                                        color="warn"
                                        (click)="resetRow(i)">
                                    <mat-icon class="actionButtons">rotate_left</mat-icon>
                                </button>

                                <button mat-icon-button
                                        *ngIf="PSForm.get('PSRows')?.value[i]?.isEditable"
                                        matTooltip="Edit"
                                        color="primary"
                                        (click)="editRow(i)">
                                    <mat-icon class="actionButtons">edit</mat-icon>
                                </button>

                                <button mat-icon-button
                                        *ngIf="false"
                                        matTooltip="Delete"
                                        color="warn"
                                        (click)="deleteRow(i)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="savefooter">
                            <th mat-footer-cell *matFooterCellDef colspan="6">
                                <div class="OrangeButton">
                                    <button mat-raised-button type="submit" (click)="onSave()"
                                            [disabled]="!anyChange()"
                                            [matTooltip]="anyChange()? 'Save changes' : 'Save disabled'"
                                            class="button-color-fix pull-right">
                                        Save
                                        <mat-icon style="color:white !important" class="icon-color-fix">save</mat-icon>
                                    </button>
                                </div>
                            </th>

                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="['headertitle', 'headeraddbutton']; sticky: true" class="stickyTopz100"></tr>
                        <tr class="col-headers" mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; let i = index; columns: columnsToDisplay;" [class.alternative2]="!PSForm.get('PSRows')?.value[i]?.isEditable"></tr>
                        <tr mat-footer-row *matFooterRowDef="['savefooter']; sticky: true"></tr>
                    </table>
                </ng-container>
            </form>
        </div>
    </div>
</div>
