 <!--The Heading sections (Heading + Serach )-->
<div class="row header-section mt-5">

    <div class="col-lg-6 col-md-8 col-sm-8 col-xs-12">
        <h4>
            Wave Excellence Lever Configuration
        </h4>
    </div>

    <div class="col-lg-6 col-md-4 col-sm-4 col-xs-12 flex-right">
        <div class="OrangeButton" style="margin-right: 50px; display:flex;">
            <button mat-raised-button (click)="openAssignExcellenceLeverWave()"
                    *ngIf="!isRemoveLeverAssignmentEnabled"
                    title="Assign Excellence Lever to a Wave" class="btn btn-sm button-color-fix">
                Add Lever <mat-icon class="add-button icon-color-fix">add_box</mat-icon>
            </button>
            <button mat-raised-button (click)="openImportExcellenceLevers()"
                    *ngIf="!isRemoveLeverAssignmentEnabled"
                    title="Import Excellence Levers from a Wave" class="btn btn-sm button-color-fix">
                Import Levers <mat-icon class="add-button icon-color-fix">content_copy</mat-icon>
            </button>
            <button mat-raised-button (click)="enableRemoveAssignmentMode()"
                    *ngIf="!isRemoveLeverAssignmentEnabled"
                    title="Remove assigned branches" class="btn btn-sm button-color-fix">
                Unassign <mat-icon class="add-button icon-color-fix">remove_circle</mat-icon>
            </button>
            <button mat-raised-button (click)="enableRemoveAssignmentMode()"
                    *ngIf="isRemoveLeverAssignmentEnabled"
                    title="Close desassignment mode" class="btn btn-sm button-color-fix">
                Close <mat-icon class="add-button icon-color-fix">close</mat-icon>
            </button>
        </div>
    </div>

</div>

<div class="">
    <table mat-table #itemsList
           [dataSource]="dataSource"
           multiTemplateDataRows
           class="mat-elevation-z8 mat-table-custom" id="wavesconfiguration">

        <ng-container matColumnDef="Checkbox">
            <th mat-header-cell *matHeaderCellDef>
                <div class="defCheckbox">
                </div>
            </th>
            <td mat-cell *matCellDef="let element">
                <div class="">
                    <mat-checkbox (click)="$event.stopPropagation();" *ngIf="isRemoveLeverAssignmentEnabled" (change)="controlAssignedItem($event.checked, element)">
                    </mat-checkbox>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="ExcellenceLever">
            <th mat-header-cell *matHeaderCellDef> Excellence Lever </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.ExcellenceLeverName"></p>
            </td>
        </ng-container>

        <!-- <ng-container matColumnDef="ExcellenceLever">
            <th mat-header-cell *matHeaderCellDef> Excellence Lever </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.ExcellenceLeverName"></p>
            </td>
        </ng-container> -->

        <ng-container matColumnDef="ChangedBy">
            <th mat-header-cell *matHeaderCellDef> Changed By </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.ChangedBy"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="ChangedAtUtc">
            <th mat-header-cell *matHeaderCellDef> Changed At Utc </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.ChangedAtUtc | date:'yyyy-MM-dd HH:mm'"></p>
            </td>
        </ng-container>

        <tr mat-header-row class="tableheader" *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; let i = dataIndex; columns: columnsToDisplay;"
            class="item">
        </tr>
    </table>
</div>


<div *ngIf="isRemoveLeverAssignmentEnabled" class="stickybottompanel">
    <div *ngIf="removeExcellenceLeverList.length > 0" class="col-lg-6" style="width:50%">
        Total selected Levers : {{removeExcellenceLeverList.length}}
    </div>
    <div *ngIf="removeExcellenceLeverList.length <= 0" class="col-lg-6" style="width:50%">
        Please select at least one Lever
    </div>
    <button *ngIf="removeExcellenceLeverList.length > 0" class="btn btn-realease btn-sm pull-right button-margin" (click)="confirmRemoveExcellenceLevers()">
        Confirm
    </button>
</div>
