<div class="container-tab-lists">

    <div class="inner-tcontainer-tab">
        <h2>Create User</h2>

        <mat-tab-group [dynamicHeight]="true" [selectedIndex]="1">
            <!--Messages-->
            <mat-tab *ngIf="defaults.isadminuser || defaults.isHomeUser">
                <ng-template mat-tab-label>
                    INTERNAL MAX
                </ng-template>
                <div>
                    <div class="tab-body">
                        <div class="tab-content">
                            <internalmaxuser-form #internalmaxuserform [tenants]="createUserPopupInfo.tenants"
                                                  [secondaryTenants]="createUserPopupInfo.secondaryTenants"
                                                  [domains]="createUserPopupInfo.domains"
                                                  (onCreateUserTrigger)="onCloseFunction($event)"
                                                  (onBackTrigger)="onCloseDialogueFunction()"
                                                  (onEmailTypeIn)="checkTypedInEmails(internalmaxuserform,$event)"></internalmaxuser-form>
                        </div>
                    </div>
                </div>
            </mat-tab>

            <mat-tab>
                <ng-template mat-tab-label>
                    TK ELEVATOR USER
                </ng-template>
                <div>
                    <div class="tab-body">
                        <div class="tab-content">
                            <tkeuser-form #tkeuserform [tenants]="createUserPopupInfo.tenants"
                                          [secondaryTenants]="createUserPopupInfo.secondaryTenants"
                                          (onCreateUserTrigger)="onCloseFunction($event)"
                                          (onBackTrigger)="onCloseDialogueFunction()"
                                          (onEmailTypeIn)="checkTypedInEmails(tkeuserform,$event)"></tkeuser-form>
                        </div>
                    </div>
                </div>
            </mat-tab>

            <mat-tab>
                <ng-template mat-tab-label>
                    TK ELEVATOR BULK External USER
                </ng-template>
                <div>
                    <div class="tab-content">
                        <div class="tab-content">
                            <tkebulkextuser [tenants]="createUserPopupInfo.tenants" (onBulkCreateUserTrigger)="onCloseBulkFunction($event)"
                                            (onBackTrigger)="onCloseDialogueFunction()"></tkebulkextuser>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>

