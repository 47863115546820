<form [formGroup]="form">
    <table>
        <tbody class="inner">
            <tr>
                <td>&nbsp;</td>
                <td>
                    <span *ngIf="!newRow">{{selected.ActionDescription}}<br /></span>
                    <textarea id="Action"
                              formControlName="Action"
                              placeholder="enter the action..."
                              [ngClass]="classname[ACTION]"
                              title="{{header[ACTION].label}}"
                              (change)="onActionChange()"
                              class="form-control">
                    </textarea>
                    <button *ngIf="!newRow" type="button" class="btn btn-default" title="Undo changes" [disabled]="isundodisabled[ACTION] == true" (click)="onActionUndo()">
                        <span class='glyphicon glyphicon-arrow-left'></span>
                    </button>
                </td>
                <td>&nbsp;</td>

                <td>
                    <button type="button"
                            class="btn btn-warning"
                            (click)="onRowCancel()">
                        Cancel
                    </button>
                </td>

            </tr>
        </tbody>
    </table>
</form>