<table-fixed-header-search-collapse 
    *ngIf="list && !defaults.isReadonlyUser"
    id-table="maintenance-control-plan-group-action-id"
    [header]="header"
    [data]="list"
    [getValue]="getData"

    [selectable]="false"
    
    [rowNewTemplate]="newaction"
    [rowEditTemplate]="actionedit"
    [rowExpandTemplate]="expandtemplate"
    
    [newService]="newService"
    [expanded]="expanded"

    (onExpand)="onExpand($event)"
    (onNewChild)="onNewChild($event)"
    (onDelete)="onDelete($event)"
>
</table-fixed-header-search-collapse>

<table-fixed-header-search-collapse *ngIf="list && defaults.isReadonlyUser"
                                    id-table="maintenance-control-plan-group-action-id"
                                    [header]="header"
                                    [data]="list"
                                    [getValue]="getData"
                                    [selectable]="false"
                                    [rowExpandTemplate]="expandtemplate"
                                    [expanded]="expanded"
                                    (onExpand)="onExpand($event)">
</table-fixed-header-search-collapse>

<ng-template #expandtemplate let-index="index">
    <maintenance-control-plan-group-action-component-list *ngIf="expanded[index]==true" [newService]="newChildService" [action]="list[index]"></maintenance-control-plan-group-action-component-list>
</ng-template>

<ng-template #actionedit let-index="index">
    <maintenance-control-plan-group-action-new [header]="header" [list]="list" [rowIndex]="index" [newRow]="false"></maintenance-control-plan-group-action-new>
</ng-template>

<ng-template #newaction>
    <maintenance-control-plan-group-action-new [mcpgroup]="mcpgroup" [header]="header" [list]="list"></maintenance-control-plan-group-action-new>
</ng-template>


<!--id-table="operationmode-properties-id"
[header]="header"
[data]="list"
[getValue]="getData"
[getClass]="notTranslatedClass"
[selectable]="false"
[rowEditTemplate]="opmodeedit"
[rowExpandTemplate]="expandtemplate"
[rowNewTemplate]="newopmode"
[newService]="newService"
[(expanded)]="expanded"
(onExpand)="onExpand($event)"
(onDelete)="onDelete($event)"-->
