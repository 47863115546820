import { Component, Inject, OnInit, ElementRef, ViewChild, Output, EventEmitter, Input } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Utils } from "../../utils/utils";
import { DialogService } from "../../services/dialog.service";
import { DefaultValuesService } from "../../services/defaultvalues.service";
import { SaveService } from '../../services/save.service';

@Component({
    selector: "errorcode-new",
    templateUrl: './errorcode-new.component.html',
    styleUrls: ['../../style/form.component.less']
})

export class ErrorCodeNewComponent implements OnInit {
    @Input() textErrorCode = 0;
    @Input() header: any[] = [];
    @Input() list: ErrorCode[] = [];
    title: string | undefined;
    selected: ErrorCode = <ErrorCode>{};
    form!: FormGroup;

    weights: number[] = [0, 1, 2, 3];

    @Output() successEvent = new EventEmitter<ErrorCode>();
    @Output() cancelEvent = new EventEmitter<{}>();

    ERROR_CODE: number = 0;
    DESCRIPTION: number = 1;
    ERROR_WEIGHT: number = 2;
    CONTROLLER_BLOCKED: number = 3;
    POTENTIALREMOTERESETFIX: number = 4;
    ISGOODEVENT: number = 5;
    COMPONENT: number = 6;
    STABLEALERTRULE = 5;
    PRODALERTRULE = 6;

    alertRules: AlertRule[] = [];
    constructor(
        private http: HttpClient
        , private fb: FormBuilder
        , @Inject('BASE_URL') private baseUrl: string
        , private dialog: DialogService
        , public saveService: SaveService
        , public defaultService: DefaultValuesService
    ) {
        this.createForm();
    }

    ngOnInit() {
        if (this.defaultService.controllertype.BK === 'CT1001') {
            this.STABLEALERTRULE = 7;
            this.PRODALERTRULE = 8;
        }
        this.dialog.tthis = this;
        this.createForm();
        this.saveService.addListener(this);
        this.title = "Create a new Error Code";
        Utils.httpGetAllAlertRules(
            this.http
            , this.baseUrl
            , this
            , function (tthis: ErrorCodeNewComponent, list: AlertRule[]) {
                tthis.alertRules = list;
            }
        );
    }
    ngOnDestroy() {
        this.onSave = () => { }
    }

    createForm() {
        this.form = this.fb.group({
            ErrorCode: [this.textErrorCode, Validators.required],
            Description: ['', Validators.required],
            Weight: ['0', Validators.required],
            ControllerBlocked: [''],
            PotentialRemoteResetFix: [''],
            IsGoodEvent: [''],
            Component: ['', Validators.required],
            StableAlertRule: ['0'],
            ProdAlertRule: ['0'],
        });
    }

    onBack() {
        this.cancelEvent.emit({});
    }

    onSave() {
        if (this.form && this.selected) {
            this.selected.ErrorCode = this.form.value.ErrorCode;
            this.selected.Description = this.form.value.Description;
            this.selected.ControllerBlocked = this.form.value.ControllerBlocked !== "" ? true : false;
            this.selected.IsPotentialRemoteResetFix = this.form.value.PotentialRemoteResetFix !== "" ? true : false;
            this.selected.IsGoodEvent = this.form.value.IsGoodEvent !== "" ? true : false;
            this.selected.Component = this.form.value.Component
            this.selected.ErrorWeight = this.form.value.Weight;
            this.selected.StableAlertRuleCatId = this.form.controls["StableAlertRule"].value;
            this.selected.ProdAlertRuleCatId = this.form.controls["ProdAlertRule"].value;
            this.http
                .put<ErrorCode>(
                    this.baseUrl + Utils.getErrorCodeAPI() + "add"
                    , this.selected
                )
                .subscribe(
                    res => {
                        this.list.unshift(res)
                    }
                    , error => this.dialog.showErrorDialog(error)
                );
        }
    }

    private onFieldChange(index: number, field1: any, field2: any): boolean {
        return this.saveService.onFieldChange(index, field1, field2, this);
    }

    onErrorCodeChange() {
        if (this.form)
            this.onFieldChange(this.ERROR_CODE, this.form.value.ErrorCode, this.selected.ErrorCode)
    }

    onWeightChange() {
        if (this.form)
            this.onFieldChange(this.ERROR_WEIGHT, this.form.value.Weight, this.selected.ErrorWeight)
    }

    onDescriptionChange() {
        if (this.form)
            this.onFieldChange(this.DESCRIPTION, this.form.value.Description, this.selected.Description)
    }

    onControllerBlockedChange() {
        if (this.form)
            this.onFieldChange(this.CONTROLLER_BLOCKED, this.form.value.ControllerBlocked, this.selected.ControllerBlocked)
    }

    onPotentialRemoteResetFixChange() {
        if (this.form)
            this.onFieldChange(this.POTENTIALREMOTERESETFIX, this.form.value.PotentialRemoteResetFix, this.selected.IsPotentialRemoteResetFix)
    }

    onIsGoodEventChange() {
        if (this.form)
            this.onFieldChange(this.ISGOODEVENT, this.form.value.IsGoodEvent, this.selected.IsGoodEvent)
    }

    onComponentChange() {
        if (this.form)
            this.onFieldChange(this.COMPONENT, this.form.value.Component, this.selected.Component)
    }

    onStableAlertRuleChange() {
        if (this.form)
            this.onFieldChange(this.STABLEALERTRULE, this.form.controls['StableAlertRule'].value, this.selected.StableAlertRuleCatId)
    }
    onProdAlertRuleChange() {
        if (this.form)
            this.onFieldChange(this.PRODALERTRULE, this.form.controls['ProdAlertRule'].value, this.selected.ProdAlertRuleCatId)
    }
}