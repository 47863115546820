<div class="example-container">
    <button mat-icon-button (click)="openCreatePopup()" matTooltip="Add new Parameter Config" *ngIf="defaults.isadminuser">
        <mat-icon>add</mat-icon>
    </button>
    <table mat-table
           [dataSource]="dataSource"
           multiTemplateDataRows
           matSort
           class="mat-elevation-z8 mat-table-custom" id="parametersconfig">
        <ng-container *ngFor="let item of columnsToDisplay" matColumnDef="{{item}}">
            <th mat-header-cell *matHeaderCellDef>
                {{item === 'Enabled' ? '' : item}}
                <mat-icon *ngIf="item !== 'Enabled'" matTooltipClass='opmodes-custom-tooltip' data-html="true" matTooltip="{{tooltips[item]}}">info</mat-icon>
            </th>
            <ng-container *ngIf="item==='Enabled'">
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="defaults.isControllerOwner">
                        <button *ngIf="getInput(element,'Enabled') === true" mat-icon-button (click)="changeElement(element, 'Enabled', false)" matTooltip="Disallow Parameter Config">
                            <mat-icon class="mat-icon-green">toggle_on</mat-icon>
                        </button>
                        <button *ngIf="!getInput(element,'Enabled')" mat-icon-button (click)="onRowEditInit(element, true)" matTooltip="Allow Parameter Config">
                            <mat-icon class="mat-icon-grey">toggle_off</mat-icon>
                        </button>
                        <button *ngIf="getInput(element,'Enabled')" mat-icon-button matTooltip="Edit Row" (click)="onRowEditInit(element, element.Enabled)">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </ng-container>
                    <ng-container *ngIf="!defaults.isControllerOwner">
                        <p class="mat-icon-green" *ngIf="getInput(element,'Enabled') === true">Enabled</p>
                        <p class="mat-icon-grey" *ngIf="getInput(element,'Enabled') === false">Disabled</p>
                    </ng-container>
                </td>
            </ng-container>
            <ng-container *ngIf="item==='ControllerParameterId'">
                <ng-container *matCellDef="let element" [ngSwitch]="getDataType(item)">
                    <td mat-cell *ngSwitchCase="'string'">
                        <p [innerHTML]="highlightWithKeywords(getInput(element,item))"></p>
                    </td>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="item==='ShowCustomer'">
                <ng-container *matCellDef="let element">
                    <td mat-cell>
                        <ng-container *ngIf="defaults.isControllerOwner">
                            <button *ngIf="getInput(element,'ShowCustomer') === true" mat-icon-button (click)="changeElement(element, 'ShowCustomer', false)" matTooltip="Hide to customer">
                                <mat-icon class="mat-icon-green">toggle_on</mat-icon>
                            </button>
                            <button *ngIf="!getInput(element,'ShowCustomer')" mat-icon-button (click)="changeElement(element, 'ShowCustomer', true)" matTooltip="Show to customer">
                                <mat-icon class="mat-icon-grey">toggle_off</mat-icon>
                            </button>
                        </ng-container>
                        <ng-container *ngIf="!defaults.isControllerOwner">
                            <p class="mat-icon-green" *ngIf="getInput(element,'ShowCustomer') === true">Enabled</p>
                            <p class="mat-icon-grey" *ngIf="getInput(element,'ShowCustomer') === false">Disabled</p>
                        </ng-container>
                    </td>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="item!=='Enabled' && item!=='ControllerParameterId'">
                <ng-container *matCellDef="let element" [ngSwitch]="getDataType(item)">
                    <td mat-cell *ngSwitchCase="'string'">
                        <p [innerHTML]="getInput(element,item)"></p>
                    </td>
                </ng-container>
            </ng-container>
        </ng-container>

        <tr mat-header-row class="tableheader" *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; let i = dataIndex; columns: columnsToDisplay;" class="item">
    </table>
</div>
