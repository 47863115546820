<div class="tkebulk">
    <div class="container-fluid">
        <br />
        <br />

        <div class="row">
            <div class="col-sm-6">
                Download Template
            </div>
            <div class="col-sm-6">
                <a style="color: #ff8708; cursor:pointer"  (click)="getexcelfile()">add_bulk_user_template</a>
            </div>
        </div>

        <br />
        <br />

        <div class="row">
            <div class="col-sm-12 m-15">
                <div ng2FileDrop
                     [ngClass]="{'another-file-over-class': hasBaseDropZoneOver}"
                     (fileOver)="fileOverBase($event)"
                     [uploader]="uploader"
                     (click)="fileInput.click()"
                     (onFileDrop)="onFileChange($event)"
                     class="well my-drop-zone mt-2">
                    Drag and drop files here or click to select*
                </div>
                <div *ngIf=""
                     class="well my-drop-zone mt-3 cursor-default text-secondary">
                    Drag and drop files here or click to select*
                </div>
                <span class="hidden-file">
                    <input type="file" #fileInput ng2FileSelect [multiple]="false"  (onFileSelected)="onFileChange($event)"  onclick="this.value=null;"  [uploader]="uploader"  accept=".xlsx" />
                </span>

            </div>
        </div>

        <br />

        <div class="col-sm-12">
            <p style="color:red" *ngIf="isfilePreloadError">{{preLoadErrorMsg}}</p>
            <table *ngIf="!isfilePreloadError"  class="table">
                <tbody>
                    <tr>
                        <th>Name</th>
                        <th>Size</th>
                    </tr>
                    <tr>

                        <td>
                            {{ fileName }}
                        </td>
                        <td>
                            {{ fileSize/1024/1024 | number:'.2' }} MB
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div *ngIf="!isfilePreloadError"  class="row ">
            <div class="col-sm-12">
                <div style="display: flex; flex-direction: row; justify-content: center; background-color: darkgreen" *ngIf="showAllSuccessfullyValidated">
                    All data was successfully validated
                </div>
                <div style="display:flex; flex-direction: row; justify-content: center">
                    <mat-progress-bar class="wikiPrgressBar" *ngIf="isLoading" mode="indeterminate" style=" width: 54%;"></mat-progress-bar>
                </div>
                <div [hidden]="hideBulkTable">
                    <!-- Your content here -->
                    <div style="background-color: #ff8708; display: flex; flex-direction: row; justify-content: center">
                        WARNINGS
                    </div>

                    <div class="bulk_scrollable-container">
                        <table border="1" class="table">
                            <tbody>
                                <tr>
                                    <th>Email</th>
                                    <th>Validation</th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Display Name</th>
                                    <th>Primary Tenant</th>
                                    <th>Company Name</th>
                                    <th *ngFor="let row of allAvlblRoles | keyvalue">{{row?.key}}</th>
                                </tr>
                                <tr *ngFor="let row of data">
                                    <td *ngFor="let val of row; index as i" [style.color]="getColor(val, i)">
                                        {{val}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>

        <br />

       

        <div class="row">
            <div class="panel-body">
                <button (click)="backButtonHandler($event)" mat-raised-button class="btn  button-color-fix_red ">
                    <mat-icon class=" icon-color-fix ">arrow_back_ios</mat-icon>
                    Back
                </button>
                <button mat-raised-button (click)="bulkUsers()" type="submit" class="btn button-color-fix pull-right" [disabled]="isButtonDisabled || isfilePreloadError">
                    Create
                    <mat-icon class=" icon-color-fix ">person</mat-icon>
                </button>


            </div>
        </div>


    </div>


</div>



