import { Component, Inject, OnInit, OnDestroy } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Utils } from "../../../utils/utils";
import { DefaultValuesService } from "../../../services/defaultvalues.service";

@Component({
    selector: "harmonizedoperationmode",
    templateUrl: './harmonizedopmode.component.html',
    styleUrls: ['../../../style/container-tab-list.component.less']
})

export class HarmonizedOperationModeComponent implements OnInit, OnDestroy {
    title = "Equipment Specific Status";
    language = {} as Language;
    translationarea = {} as TranslationArea;
    harmonizedoperationmodes: HarmonizedOperationMode[] = [];
    selected = {} as HarmonizedOperationMode;
    editMode = false;

    subscriptionLanguage: any;

    constructor(
        private http: HttpClient
        , private defaults: DefaultValuesService
        , @Inject('BASE_URL') private baseUrl: string
    ) {
    }

    ngOnInit() {
        if (this.harmonizedoperationmodes.length === 0)
            this.refreshAll();
        this.subscriptionLanguage = this.defaults.languageMessageChanges$.subscribe((item: Language) => this.onLanguageChanged(item));
        this.language = this.defaults.language;
    }

    ngOnDestroy() {
        this.subscriptionLanguage.unsubscribe();
    }

    onCancel() {
        this.editMode = false;
    }

    onTranslationAreaChanged(ta: TranslationArea) {
        this.translationarea = ta;
        this.refreshAll();
    }

    onLanguageChanged(l: Language) {
        this.language = l;
        this.refreshAll();
    }

    refreshAll() {
        Utils.httpGetAllOperationModesHarmonized(
            this.http
            , this.baseUrl
            , this
            , function (tthis: any, list: HarmonizedOperationMode[]) {
                tthis.harmonizedoperationmodes = list;
            }
        );
    }
}