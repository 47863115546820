 <!--The Heading sections (Heading + Serach )-->
<div class="row header-section mt-5">

    <div class="col-lg-6 col-md-9 col-sm-9 col-xs-12">
        <h4>
            Waves
        </h4>
    </div>

    <div class="col-lg-6 col-md-3 col-sm-3 col-xs-12 flex-right">
        <div class="OrangeButton" style="margin-right: 50px;">
            <button mat-raised-button (click)="openCreateWavePopup()" title="Create new Wave" class="btn btn-sm button-color-fix">
                <mat-icon class="add-button icon-color-fix">add_box</mat-icon>
            </button>
        </div>
    </div>

</div>

<div class="">
    <table mat-table #itemsList
           [dataSource]="dataSource"
           multiTemplateDataRows
           class="mat-elevation-z8 mat-table-custom" id="waves">

        <ng-container matColumnDef="Select">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element"
                (click)="onSelectWave(element)">
                <span class="material-icons" *ngIf="selectedWave?.WaveId === element.WaveId; else show_expand_more" matTooltip="Collapse">
                    expand_less
                </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.Name"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="StartDate">
            <th mat-header-cell *matHeaderCellDef> Start Date </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.StartDate | date:'yyyy-MM-dd HH:mm'"></p>
            </td>
        </ng-container>
        <ng-container matColumnDef="EndDate">
            <th mat-header-cell *matHeaderCellDef> End Date </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.EndDate | date:'yyyy-MM-dd HH:mm'"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="ChangedBy">
            <th mat-header-cell *matHeaderCellDef> Changed By </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.ChangedBy"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="ChangedAtUtc">
            <th mat-header-cell *matHeaderCellDef> Changed At Utc </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.ChangedAtUtc | date:'yyyy-MM-dd HH:mm'"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let element; let i = dataIndex">
                <button mat-icon-button matTooltip="Edit Excellence Lever" (click)="onEditWave(element)">
                    <mat-icon>edit</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row class="tableheader" *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; let i = dataIndex; columns: columnsToDisplay;" class="item"
            [ngClass]="selectedWave.WaveId === element.WaveId ? 'orange-background':''">
        </tr>
    </table>
</div>




<ng-template #show_expand_more>
    <span class="material-icons" matTooltip="More Details">
        expand_more
    </span>
</ng-template>
