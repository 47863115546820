<div class="example-container new-table-style">
    <table mat-table
           [dataSource]="dataSource"
           class="mat-elevation-z8 mat-table-custom">

        <ng-container matColumnDef="bk">
            <th mat-header-cell *matHeaderCellDef> Business Key </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.BK"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="desc">
            <th mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.Description" *ngIf="!isEditingRow(element)"></p>
                <mat-form-field floatLabel="never" [ngClass]="notTranslatedClass(element.Description)" *ngIf="isEditingRow(element)">
                    <input matInput [value]="element.Description" [(ngModel)]="element.Description">
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container matColumnDef="descref">
            <th mat-header-cell *matHeaderCellDef> Description Ref </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.Description_REF"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button *ngIf="!isEditingRow(element)" matTooltip="Edit Row"
                        (click)="onRowEditInit(element)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button *ngIf="isEditingRow(element)" matTooltip="Cancel Row"
                        (click)="onRowEditCancel(element)">
                    <mat-icon>rotate_left</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; let i = dataIndex;
            columns: columnsToDisplay;"
            class="item"
            [class.alternative2]="isEditingRow(element)">
        </tr>
    </table>
</div>