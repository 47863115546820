
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, OnChanges, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from
    "@angular/forms"
import { ComponentTool } from '../../../../../interfaces/componenttool';
import { DefaultValuesService } from '../../../../../services/defaultvalues.service';
import { Utils } from '../../../../../utils/utils';
import { PasswordStrengthValidator, ConfirmPasswordValidator, GeneratePassword } from "../common_form_tools"
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
    selector: "tkeuser-form",
    templateUrl: './tkeuser-form.component.html',
    styleUrls: ['./tkeuser-form.component.less'],
    encapsulation: ViewEncapsulation.None
})


export class TKEUserComponent implements OnChanges {

    @Input('tenants') tenants: SelectList[] = [];
    @Input('secondaryTenants') secondaryTenants: SelectList[] = [];
    @Output() onCreateUserTrigger = new EventEmitter<string>();
    @Output() onBackTrigger = new EventEmitter();
    @ViewChild('spinner') spinnerElement!: MatProgressSpinner;

    passHide = true;
    confirmPassHide = true;
    public tkeUserForm: FormGroup;
    selectedSecTenString = '';
    componentToolEnum: typeof ComponentTool = ComponentTool;

    @Output() onEmailTypeIn = new EventEmitter<string>();
    timeoutFunction: any;
    emailError: string | null = null;
    waiting4Mail: boolean = false;
    constructor(fb: FormBuilder,
        private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string,
        public defaults: DefaultValuesService) {


        this.tkeUserForm = fb.group(
            {
                firstName: ['', Validators.required],
                lastName: ['', Validators.required],
                displayName: ['', Validators.required],

                boolPassChange: [false],
                tenant: [null, Validators.required],
                secondaryTenant: [null],
                email: ['', <any>[Validators.required, Validators.email]],
                companyName: ['', Validators.required]
            }, {
        });
    }
    ngOnChanges() {
        this.selectAllForDropdownItems(this.secondaryTenants);
    }

    selectAllForDropdownItems(items) {
        let allSelect = items => {
            items.forEach(element => {
                element['selectedAllGroup'] = 'selectedAllGroup';
            });
        };

        allSelect(items);

    }

    onSubmit() {
        Utils.httpCreateUser(
            this.http
            , this.baseUrl
            , this.tkeUserForm.value
            , false
            , this
            , function (tthis: TKEUserComponent, data: string = '') {
                tthis.onCreateUserTrigger.emit(data);
            }
        )
    }

    onSecTenChange() {
        this.selectedSecTenString = this.tkeUserForm.controls['secondaryTenant'].value.map(item => item).join(', ');
    }

    genPass(event: MouseEvent) {
        event.preventDefault();
        const tempPass = GeneratePassword();
        this.tkeUserForm.patchValue({
            password: tempPass,
            confirmPassword: tempPass
        })
    }


    passHideFunc(event: any) {
        event.preventDefault();
        this.passHide = !this.passHide
    }
    handleConfirmPass(event: any) {
        event.preventDefault();
        this.confirmPassHide = !this.confirmPassHide;
    }
    backButtonHandler(event: any) {
        event.preventDefault();
        this.onBackTrigger.emit();
    }

    changeEmail($event: any) {
        if ($event.key !== "ArrowLeft" && $event.key !== "ArrowRight" && $event.key !== "ArrowUp" && $event.key !== "ArrowDown") {
            this.emailError = null;
            this.waiting4Mail = true;
            if (this.timeoutFunction) {
                clearTimeout(this.timeoutFunction);
                this.timeoutFunction = null;
            }
            this.timeoutFunction = setTimeout(() => {
                if ($event.target.value.trim() !== '') {
                    this.onEmailTypeIn.emit($event.target.value.trim());
                    this.spinnerElement._elementRef.nativeElement.style.visibility = "visible";
                }
            }, 1000)
        }
    }

    showEmailError(text) {
        this.waiting4Mail = false;
        this.spinnerElement._elementRef.nativeElement.style.visibility = "hidden";
        if (text)
            this.emailError = text;
        else
            this.emailError = null;
    }
}







