<div class="custom-mat-dialog-form">
    <div>
        <div  *ngIf="this.action == 'OM'">
            Fingerprint rule definition
            <br />
        </div>
        <div  *ngIf="this.action != 'OM'">
            Fingerprint trigger conditions
            <br />
        </div>
    </div>
    <div>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">

            <div class="mt-2" *ngIf="this.action == 'OM'">

                <br />
                Operation Mode: <br />
                <mat-select placeholder="Please select" style="width:300px;" class='form-control'
                            name="Controller Type" required formControlName="OperationMode">
                    <mat-option *ngFor="let c of operationModes; index as i" value="{{c.ErrorControllerId.toString()}}">
                        {{c.Mode}}
                    </mat-option>
                </mat-select>
                <br /><br />
                Building type: <br />
                <mat-select placeholder="Please select" style="width:300px;" class='form-control'
                            name="Controller Type" required formControlName="BuildingType">
                    <mat-option *ngFor="let c of buildingTypes; index as i" value="{{c.Description}}">
                        {{c.Description}}
                    </mat-option>
                </mat-select>
                <br /><br />
                Traffic Pattern: <br />
                <!--<input type="text" formControlName="TrafficPattern" />-->
                <mat-select placeholder="Please select" style="width:300px;" class='form-control'
                            name="TrafficPattern" required formControlName="TrafficPattern">
                    <mat-option *ngFor="let c of trafficpattern; index as i" value="{{c.ParamValue}}">
                        {{c.ParamDescription}}
                    </mat-option>
                </mat-select>
                <br /><br />
                Is Warning: <br />
                <mat-checkbox formControlName="IsWarning"></mat-checkbox>
                <!--<input type="checkbox" formControlName="IsWarning" />-->
            </div>

            <div class="mt-2" *ngIf="this.action != 'OM'">
                <br />
                Feature: <br />
                <!--<input type="text" formControlName="Feature" />-->
                <mat-select placeholder="Please select" style="width:300px;" class='form-control'
                            name="Feature" required formControlName="Feature">
                    <mat-option *ngFor="let c of features; index as i" value="{{c.ParamValue}}">
                        {{c.ParamDescription}}
                    </mat-option>
                </mat-select>

                <br /><br />
                Threshold type: <br />
                <mat-select placeholder="Please select" style="width:300px;" class='form-control'
                            name="Threshold Type" required formControlName="ThresholdType">
                    <mat-option value="PercentageLow">
                        Percentage Low
                    </mat-option>
                    <mat-option value="PercentageHigh">
                        Percentage High
                    </mat-option>
                    <mat-option value="Percentage+-">
                        Percentage + -
                    </mat-option>
                    <mat-option value="TimesMore">
                        Times More
                    </mat-option>
                    <mat-option value="TimesLess">
                        Times Less
                    </mat-option>
                </mat-select>
                <br /><br />
                Threshold value: <br />
                <input type="text" formControlName="ThresholdValue" />
                <br /><br />
                Environment: <br />
                <mat-select placeholder="Please select" style="width:300px;" class='form-control'
                            name="EnvironmentType" required formControlName="EnvironmentType">
                    <mat-option value="PRODUCTION">
                        PRODUCTION
                    </mat-option>
                    <mat-option value="STABLE">
                        STABLE
                    </mat-option>
                </mat-select>
                <br /><br />
            </div>

            <br />


            <div class="mt-2 right">
                <button type="button"
                        class="btn btn-danger"
                        (click)="onNoClick()">
                    Cancel
                </button>
                <button type="button"
                        class="btn btn-primary"
                        (click)="onSubmit()">
                    Save
                </button>
            </div>
        </form>
    </div>
</div>
