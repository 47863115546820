<div class="container-tab-lists">
    <hierarchysetup-menu (rldBranchHierarchyKey)="GetBranchHierarchyKeys(defaults.country)"></hierarchysetup-menu>

    <h2>Hierarchy Setup</h2>
    <mat-form-field class="search-input">
        <input placeholder="Filter"
               matInput
               (keyup)="filter($event)">
    </mat-form-field>
    <hierarchysetup-list (rldBranchHierarchyKey)="GetBranchHierarchyKeys(defaults.country)" [alllist]="alllist" [list]="list"></hierarchysetup-list>
</div> 