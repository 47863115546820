import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AlertDialogComponent } from '../components/dialogbox/dialog-alert.component';
import { ConfirmationDialogComponent } from '../components/dialogbox/dialog-confirmation.component';

@Injectable()

export class CustomDialogService {
    constructor(private dialog: MatDialog) {

    }

    confirmDialogRef!: MatDialogRef<ConfirmationDialogComponent>;
    alertDialogRef!: MatDialogRef<AlertDialogComponent>;

    public openConfirm(options : any) {
        this.confirmDialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {
                title: options.title,
                message: options.message,
                caller: options.caller
            }
        });
    }

    public confirmed(): Observable<any> {
        return this.confirmDialogRef.afterClosed().pipe(take(1), map((res) => {
            return res;
        }));
    }

    public openError(options: any) {
        this.alertDialogRef = this.dialog.open(AlertDialogComponent, {
            data: {
                title: 'Error',
                message: options.message
            }
        });
    }

    public openDisplayInfoDialog(options: any) {
        this.alertDialogRef = this.dialog.open(AlertDialogComponent, {
            data: {
                title: options.title,
                message: options.message
            }
        });
    }
}