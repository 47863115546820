import { Component, Inject, Input, OnInit, OnChanges, EventEmitter, Output } from "@angular/core";
import { DialogService } from "../../../../services/dialog.service";
import { HttpClient } from "@angular/common/http";
import { DefaultValuesService } from "../../../../services/defaultvalues.service";
import { MatDialog } from "@angular/material/dialog";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { KpiDetailFormComponent } from "../kpidetail-form.component";
import { MatTableDataSource } from "@angular/material/table";
import { SelectionModel } from "@angular/cdk/collections";


@Component({
    selector: "actualreporting-list",
    templateUrl: './actualreporting-list.component.html',
    styleUrls: ['./actualreporting-list.component.less',
                    '../../../../style/new-generic-styles.component.less'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*', })),
            transition('expanded => collapsed', [animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
        ]),
    ]

})

export class ActualReportingListComponent implements OnInit, OnChanges {
    @Input('list') list: KpiManagement[] = [];
    @Input('filter') filter: string = ''
    @Input('inclusiveMode') inclusiveMode: boolean = false
    @Input('selectorFilter') selectorFilter: string = ''

    @Input('isEditModeEnabled') isEditModeEnabled: boolean = false;
    @Input('isShowAllEnabled') isShowAllEnabled: boolean = false;
    @Input('isReleaseModeEnabled') isReleaseModeEnabled: boolean = false;
    @Input('auxFilterPredicate') auxFilterPredicate!: (filters: string, data: any, parentLevel: boolean, showAllEnabled) => boolean;


    @Input('isRevokeActive') isRevokeActive: boolean = false;
    @Input('isReleaseActive') isReleaseActive: boolean = false;
    @Output() onClickReleaseMode: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onClickRevokeMode: EventEmitter<boolean> = new EventEmitter<boolean>();

    columnsToDisplay = ['arrow', 'Account', 'KpiName', 'KpiOwner', 'KpiUnit', 'LocalCurrency', 'AvailabilityTime', 'ReportingType', 'actions'];

    public dataSource = new MatTableDataSource<KpiManagement>();

    expandedElements = new Set();
    isAllCollapsed = false;
    Filter = 0;

    P_current: string;
    P_1: string;
    P_2: string;
    P_3: string;

    updatedItems: KpiManagementUpdate[] = []


    releaseList: selectedReleaseRow[] = []
    selection = new SelectionModel<UserSummary>(true, []);
    constructor(
        private http: HttpClient
        , private dialogService: DialogService
        , private defaults: DefaultValuesService
        , @Inject('BASE_URL') private baseUrl: string
        , private dialogForm: MatDialog

    ) {
        this.P_current = this.P_1 = this.P_2 = this.P_3 = ''
        this.UpdatePeriodHeader()
    }

    ngOnChanges(changes: any) {
        this.UpdatePeriodHeader();
        if (changes.list) {
            this.dataSource.data = this.list;
        }

        this.updatedItems = [];

        if (changes.filter || changes.inclusiveFilter || changes.selectorFilter) {
            this.dataSource.filter = this.filter;
        }

        if (changes.isReleaseModeEnabled) {
            this.expandedElements.clear()
            this.updatedItems = []
        }
    }

    ngOnInit() {
        this.dialogService.tthis = this;
        this.dataSource.filterPredicate = (data: KpiManagement, filters: string) => {
            if (this.selectorFilter === '0') {  // Just applying filter on first level
                if (data.subtable)
                    data.subtable.filter = this.filter
                return this.auxFilterPredicate(filters, data, true, this.isShowAllEnabled)
            }
            else {
                if (!data.subtable)
                    this.createMatSubtable(data)
                if (this.selectorFilter === '1')
                    data.subtable.filter = this.filter
                if (data.subtable.filteredData.length > 0 && this.filter[0] !== '+')
                    this.expandElement(data)
                return this.auxFilterPredicate(filters, data, true, this.isShowAllEnabled) || data.subtable.filteredData.length > 0
            }
        };
    }

    checkExpanded(element) {
        return this.expandedElements.has(element);
    }

    pushPopElement(element) {
        if (this.expandedElements.has(element)) {
            this.expandedElements.delete(element);
        }
        else {
            this.expandElement(element)
            if (!element.subtable) {
                this.createMatSubtable(element)
                element.subtable.filter = this.filter
            }
        }
    }

    collapseOrExpandAll() {
        if (this.isAllCollapsed === false) {
            this.isAllCollapsed = true;
            this.dataSource.data.forEach((el) => {
                if (!this.expandedElements.has(el)) {
                    if (!el.subtable)
                        this.createMatSubtable(el)
                    this.expandElement(el)
                }
            })
        }
        else {
            this.isAllCollapsed = false;
            this.expandedElements.clear()
        }
    }

    private createMatSubtable(el: any) {
        let dataSource = new MatTableDataSource<any>();
        dataSource.data = el.KpiDetail
        dataSource.filterPredicate = (data2: any, filters: string) => {
            return this.auxFilterPredicate(filters, data2, false, this.isShowAllEnabled)
        }
        el.subtable = dataSource
    }

    private expandElement(el: any) {
        this.expandedElements.add(el);
    }

    formatCurrency(value, decimalDigits): string {

        if (value == undefined)
            return '';
        else
            return parseFloat(value).toLocaleString('de', { maximumFractionDigits: decimalDigits });
    }

    private UpdatePeriodHeader() {
        let year = this.defaults.fiscalYear
        if (year === -1) {
            const temp = new Date();
            year = temp.getFullYear()
        }
        const date = new Date(year, this.defaults.period - 4)
        const month_1 = new Date(date.getFullYear(), date.getMonth() - 1);
        const month_2 = new Date(date.getFullYear(), date.getMonth() - 2);
        const month_3 = new Date(date.getFullYear(), date.getMonth() - 3);
        this.P_current = `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear().toString()}`;
        this.P_1 = `${(month_1.getMonth() + 1).toString().padStart(2, '0')}-${month_1.getFullYear().toString()}`;
        this.P_2 = `${(month_2.getMonth() + 1).toString().padStart(2, '0')}-${month_2.getFullYear().toString()}`;
        this.P_3 = `${(month_3.getMonth() + 1).toString().padStart(2, '0')}-${month_3.getFullYear().toString()}`;
    }

    onDetail(element: any) {

        var KpiId = "null";

        if (element.KpiId != undefined)
            KpiId = element.KpiId;

        const Dialog = this.dialogForm.open(KpiDetailFormComponent, {
            panelClass: 'custom-mat-dialog',
            disableClose: false,
            data: { "KpiDefinitionId": element.KpiDefinitionId, "KpiId": KpiId }
        });
        Dialog.afterClosed().subscribe(() => {
        });

    }

    onChange(breakdown: any, $e: any, account: any) {
            //console.log($e)
        let pM1 = 0

        if (account.ReportingType == 'YTD') {
            //For YTD kpi type we store difference from previous month 
            if (breakdown.ValueM1 !== undefined)
                pM1 = parseFloat(breakdown.ValueM1);
        }
        let tempObject: KpiManagementUpdate = {
            'KpiId': breakdown.KpiId,
            'KpiDefinitionId': breakdown.KpiDefinitionId,
            'KpiVersion': 'ACTUAL',
            'KpiBreakdown1Id': breakdown.KpiBreakdown1Id,
            'KpiBreakdown2Id': breakdown.KpiBreakdown2Id,
            'KpiMovementTypeId': breakdown.KpiMovementTypeId,
            'KpiProductHierarchyId': breakdown.KpiProductHierarchyId,
            'KpiValue': (parseFloat($e) - (this.defaults.period !== 1 ? pM1 : 0)).toFixed(account.DecimalDigits),
            ApprovalStatus: breakdown.ApprovalStatus,
            KpiUnit: account.KpiUnit,
            DecimalDigits: account.DecimalDigits,
            auxFYFC: '0',
            IsAccumulative: false,
            AccountId: account.AccountID

        }
        this.addUpdatedItem(tempObject);
    }

    private addUpdatedItem(newObject: KpiManagementUpdate) {
        const index = this.updatedItems.findIndex(
            (obj) =>
                obj.KpiDefinitionId === newObject.KpiDefinitionId &&
                obj.KpiBreakdown1Id === newObject.KpiBreakdown1Id &&
                obj.KpiBreakdown2Id === newObject.KpiBreakdown2Id &&
                obj.KpiId === newObject.KpiId &&
                obj.KpiVersion === newObject.KpiVersion &&
                obj.KpiMovementTypeId === newObject.KpiMovementTypeId &&
                obj.KpiProductHierarchyId === newObject.KpiProductHierarchyId
        );

        if (index !== -1) {
            if (newObject.KpiValue === 'NaN')
                this.updatedItems.splice(index, 1)
            else
                this.updatedItems[index] = newObject;
        } else {
            this.updatedItems.push(newObject);
        }
    }

    disableArrowKeys(event: KeyboardEvent) {
        if (
            event.key === 'ArrowUp' ||
            event.key === 'ArrowDown'
        ) {
            event.preventDefault();
        }
    }

    mapReleaseRow(row) {
        var selectedReleaseRow: selectedReleaseRow = {
            KpiDefinitionId: row.KpiDefinitionId,
            KpiMovementTypeId: row.KpiMovementTypeId,
            KpiProductHierarchyId: row.KpiProductHierarchyId,
            KpiBreakdown1Id: row.KpiBreakdown1Id,
            KpiBreakdown2Id: row.KpiBreakdown2Id

        };
        return selectedReleaseRow;
    }

    accountCheckboxCheck(row) {
        var Checked = row.KpiDetail.every((row) => {
            var mappedRow = this.mapReleaseRow(row);
            return this.releaseList.some((object) => this.objectsEqual(mappedRow, object));
        })
        return Checked
    }
    accountItemsIndetermine(row) {
        var Indeterminate = this.releaseList.some((selected) => (selected.KpiDefinitionId === row.KpiDefinitionId))
        return Indeterminate;
    }
    accountItemsDisable(row) {
        //console.log(row)
        var Disable = !row.KpiDetail.some((item) => {
            return (this.isReleaseActive ? (item.ApprovalStatus == "NEW") :
                (item.ApprovalStatus == "RELEASED"))
        }
        )
        return Disable;
    }

    accountItemsSelected(ischecked, row) {
        row.KpiDetail.forEach((item) => {
            this.isReleaseActive ? (item.ApprovalStatus == "NEW" ? this.expandedItemSelected(ischecked, item) : null) :
                (item.ApprovalStatus == "RELEASED" ? this.expandedItemSelected(ischecked, item) : null)
        });

    }
    expandedItemSelected(ischecked, row) {
        if ((this.isReleaseActive || this.isRevokeActive) && (this.isReleaseActive ? (row.ApprovalStatus == 'NEW') : (row.ApprovalStatus == 'RELEASED'))) {
            var selectedReleaseRow = this.mapReleaseRow(row);
            if (ischecked) {
                this.selection.select(row)
                this.onSelect(selectedReleaseRow);
            } else {
                this.selection.deselect(row)
                this.onDeselect(selectedReleaseRow);
            }
        }

    }
    onSelect(selectedReleaseRow) {
        //this.releaseList.push(selectedReleaseRow)

        if (!this.releaseList.some((object) => this.objectsEqual(object, selectedReleaseRow))){
            this.releaseList.push(selectedReleaseRow)
        }

    }
    onDeselect(selectedReleaseRow) {
        var mappedRow = this.mapReleaseRow(selectedReleaseRow)
        const index = this.releaseList.findIndex((object) => this.objectsEqual(mappedRow, object));
        if (index !== -1) {
            this.releaseList.splice(index, 1);
        }
    }

    objectsEqual(object1, object2) {
        if (object1 === object2) {
            return true;
        }
        if (Object.keys(object1).length !== Object.keys(object2).length) {
            return false;
        }
        for (const property in object1) {
            if (object1[property] !== object2[property]) {
                return false;
            }
        }
        return true;
    }
    getSelectionList() {
        return this.releaseList;
    }
    clearSelectionList() {
        this.releaseList = [];
        this.selection?.clear();
    }
    onClickReleaseMode_emitter() {
        this.onClickReleaseMode.emit();
    }
    onClickRevokeMode_emitter() {
        this.onClickRevokeMode.emit();
    }
    anyActionableItemExist() {
        //console.log(this.list);
        return this.isReleaseActive ?
            this.list.some((kpi) => kpi.KpiDetail.some((kpidef) => (kpidef.ApprovalStatus == "NEW"))) :
            this.list.some((kpi) => kpi.KpiDetail.some((kpidef) => (kpidef.ApprovalStatus == "RELEASED")))
    }
    textRelRevbuttonState() {
        return this.anyActionableItemExist() ?
            (((this.isReleaseActive || this.isRevokeActive && this.isReleaseActive) ? 'Release ' : 'Revoke ') + (!this.selection.hasValue() ? 'All' : 'Selected')) :
            (this.isReleaseActive ? 'Nothing to Release' : 'Nothing to Revoke')
    }
}
export interface SearchItem {
    name: string;
}
