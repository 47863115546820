
<iframe title="AMS" *ngIf='this.ReportType === "AMS" '
        style="height: 90vh; display: block; width: -webkit-fill-available"
        src="https://app.powerbi.com/reportEmbed?reportId=3019eee2-1c93-4108-a201-6670d36b02a4&autoAuth=true&ctid=739976a8-173b-4e4f-ad43-173a2664452a&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLW5vcnRoLWV1cm9wZS1mLXByaW1hcnktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D&pageName=ReportSectiond48dcb7237b57801fdfd"
        frameborder="0"
        allowFullScreen="true">
</iframe>

<div *ngIf='this.ReportType != "EA" && this.ReportType != "AMS"  '>
    <br /><br /><br /><br />
    <h4>Only available EA / AMS reports</h4>
</div>

<iframe title="EA" *ngIf='this.ReportType === "EA" '
        style="height: 90vh; display: block; width: -webkit-fill-available"
        src="https://app.powerbi.com/reportEmbed?reportId=62c723a5-024c-47f2-8e86-fa83859a0b98&autoAuth=true&ctid=739976a8-173b-4e4f-ad43-173a2664452a&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLW5vcnRoLWV1cm9wZS1mLXByaW1hcnktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D&pageName=ReportSectiond48dcb7237b57801fdfd"
        frameborder="0"
        allowFullScreen="true">
</iframe>



