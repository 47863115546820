<table-fixed-header-search-collapse 
        *ngIf="list && !defaults.isReadonlyUser"
        id-table="maintenance-control-plan-id"
        [header]="header"
        [data]="list"
        [getValue]="getData"
        [selectable]="false"
        [rowNewTemplate]="newmcp"
        [rowEditTemplate]="mcpedit"
        [newService]="newService"
        (onDelete)="onDelete($event)"
        (onRowClick)="onRowClick($event)">
</table-fixed-header-search-collapse>

<table-fixed-header-search-collapse *ngIf="list && defaults.isReadonlyUser"
                                    id-table="maintenance-control-plan-id"
                                    [header]="header"
                                    [data]="list"
                                    [getValue]="getData"
                                    [selectable]="false"
                                    [rowNewTemplate]="newmcp"
                                    (onRowClick)="onRowClick($event)">
</table-fixed-header-search-collapse>

<ng-template #mcpedit let-index="index">
   <maintenance-control-plan-new [header]="header" [list]="list" [rowIndex]="index" [newRow]="false"></maintenance-control-plan-new>
</ng-template>

<ng-template #newmcp>
    <maintenance-control-plan-new [header]="header" [list]="list"></maintenance-control-plan-new>
</ng-template>





<!--<div class="classname">
    <table-fixed-header-search *ngIf="list"
                               id-table="maintenance-control-plan-id"
                               [header]="getHeader()"
                               [data]="list"
                               [getValue]="getData"
                               [language]="defaults.language"
                               (onSelect)="onSelect($event)"
                               (onEdit)="onEdit($event)"
                               (onSearch)="onSearchList($event)"
                               (onReset)="onResetList()">
    </table-fixed-header-search>
</div>-->