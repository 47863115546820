<div class='left-menu'>
    <ul class='nav navbar-nav'>

        <li [routerLinkActive]="['link-active']">
            <a class='menu-callback' (click)="onReload.emit()" title="Refresh">
                <span class='glyphicon glyphicon-refresh'></span> Refresh
            </a>
        </li>
        <li class="separator" [ngClass]="saveService.classSaveButton"></li>
        <li [routerLinkActive]="['link-active']" [ngClass]="saveService.classSaveButton">
            <a class='menu-callback' (click)="saveService.fireSave()" title="Save all fingerprint rules">
                <span class='glyphicon glyphicon-floppy-disk'></span> Save
            </a>
        </li>
    </ul>
</div>