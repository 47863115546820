import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges, VERSION, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from
    "@angular/forms";
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DefaultValuesService } from '../../services/defaultvalues.service';
import { SpinnerOverlayService } from '../../services/spinner-overlay.service';
import { Utils } from '../../utils/utils';

@Component({
    selector: "wikinav",
    templateUrl: './wikinav.component.html',
    styleUrls: ['./wikinav.component.less'],
    encapsulation: ViewEncapsulation.None
})

export class WikiNavComponent {

    isExpanded = false;
    @Input() hideInlineDoc: boolean = true;
    @ViewChild('rightSidenav', { static: true }) sidenav;
    markdown: string | null = '';
    remoteUrl = '';
    subscriptions: Subscription[] = [];

    @Output() disableWikiBtn = new EventEmitter<boolean>();
    @Output() showLoadingBar = new EventEmitter<boolean>();

    constructor(
        private router: Router,
        private http: HttpClient,
        private route: ActivatedRoute,
        private defaults: DefaultValuesService,
        @Inject('BASE_URL') private baseUrl: string,
        private spinnerOverlayService: SpinnerOverlayService
    ) {

    }
    ngOnInit() {
        this.defaults.routeChangedObservable.subscribe(() => {
            this.subscriptions.forEach(x => x.unsubscribe())
            setTimeout(() => {
                this.wikiApiCallsManager();
            }, 1000);
        });
    }
    ngOnChanges() {
        this.displayWikiManager()

    }
    displayWikiManager() {
        this.hideInlineDoc ? this.sidenav.close() : this.sidenav.open();

    }


    wikiApiCallsManager() {
        this.getIsWikiAvailable()
    }

    toggleExpand() {
        this.isExpanded = !this.isExpanded;
    }

    getIsWikiAvailable() {
        
        this.showLoadingBar.emit(false);
        var temp = '';
        Utils.httpIsWikiAvailable(this.http
            , this.baseUrl, this.defaults.routeSeg, this.defaults.wikiTabName
            , this
            , function (tthis: WikiNavComponent, resp: any) {    
                //if we want to get the wiki we first check if it is available 
                // if it is available we load it if it is not availabel we clear and close the expanded node 
                resp ? tthis.getInlineWiki() : (tthis.markdown = "", tthis.isExpanded = false, tthis.disableWikiBtn.emit(!resp), tthis.sidenav.close())
            }
        )
        //


    }
    getInlineWiki() {
        this.showLoadingBar.emit(true);
        
        Utils.httpGetInlineWiki(this.http
            , this.baseUrl, this.defaults.routeSeg, this.defaults.wikiTabName
            , this
            , function (tthis: WikiNavComponent, data: any) {
                tthis.showLoadingBar.emit(false);
                tthis.markdown = data.content;
                tthis.remoteUrl = data.remoteUrl;
                tthis.disableWikiBtn.emit(false)
                tthis.displayWikiManager();
            }
        )
    }

    openLinkInNewTab() {
        const linkUrl = this.remoteUrl;
        window.open(linkUrl, '_blank');
    }




}
