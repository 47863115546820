<form [formGroup]="form">
    <table>
        <tbody class="inner">
            <tr>
                <td>&nbsp;</td>
                <td>
                    <!--<div class="menu-form" title="{{header[CONTROLLERTYPE].label}}">
                        <controller-type (controllerChanged)="onControllerChanged($event)"></controller-type>
                    </div>-->
                    <select id="ControllerType"
                            formControlName="ControllerType" required
                            class="form-control"
                            [ngClass]="classname[CONTROLLERTYPE]"
                            title="{{header[CONTROLLERTYPE].label}}"
                            (change)="onControllerChanged($event)">
                        <option *ngFor="let c of defaults.controllertypes; index as i" value="{{i}}" [selected]="isSelected(c)">{{c.Description}}</option>
                    </select>
                </td>
                <td>&nbsp;</td>
                <td>
                    <select id="Error"
                            formControlName="Error" required
                            class="form-control"
                            [ngClass]="classname[ERRORCODE]"
                            title="{{header[ERRORCODE].label}}"
                            (change)="onErrorChange($event)">
                        <option *ngFor="let e of errors" value="{{e.ErrorCodeID}}">{{e.ErrorCode}} - {{e.Description}}</option>
                    </select>
                </td>
                <td>
                    <button type="button"
                            class="btn btn-warning"
                            (click)="onRowCancel()">
                        Cancel
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</form>