import { Component, Inject, OnInit, OnDestroy } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Utils } from "../../../utils/utils";
import { DefaultValuesService } from "../../../services/defaultvalues.service";

@Component({
    selector: "controllertypetomasterdatarequired",
    templateUrl: './controllertypetomasterdatarequired.component.html',
    styleUrls: ['../../../style/container-tab-list.component.less']
})

export class ControllerTypeMasterDataRequiredComponent implements OnInit, OnDestroy {
    translationarea = {} as TranslationArea;
    controllertypetomasterdatarequired: ControllerTypeToMasterdataRequired[] = [];

    subscriptionLanguage: any;
    subscriptionControllerType: any;

    constructor(
        private http: HttpClient
        , private defaults: DefaultValuesService
        , @Inject('BASE_URL') private baseUrl: string
    ) {
    }

    ngOnInit() {
        if (this.controllertypetomasterdatarequired.length === 0)
            this.refreshAll();
        this.subscriptionLanguage = this.defaults.languageMessageChanges$.subscribe(() => {
            this.refreshAll()
        });
        this.subscriptionControllerType = this.defaults.controllerTypeMessageChanges$.subscribe(() => this.refreshAll());

    }

    ngOnDestroy() {
        this.subscriptionLanguage.unsubscribe();
    }

    refreshAll() {
        Utils.httpGetAllControllerTypeToMasterdataRequired(
            this.http
            , this.baseUrl
            , this
            , function (tthis: ControllerTypeMasterDataRequiredComponent, list: ControllerTypeToMasterdataRequired[]) {
                tthis.controllertypetomasterdatarequired = list;
            }
        );
    }
}