<div class="example-container new-table-style">
    <table mat-table
           [dataSource]="dataSource"
           class="mat-elevation-z8 mat-table-custom"
           id="controllertype-bytenant">

        <ng-container matColumnDef="index">
            <th mat-header-cell *matHeaderCellDef> Sort index </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.SortIndex"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="ct">
            <th mat-header-cell *matHeaderCellDef> Controller Type</th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.Description"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="country">
            <th mat-header-cell *matHeaderCellDef> Country </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.Name"></p>                
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="defaults.isReadonlyUser === false && element.CountryCode !== 'ZZ'">
                    <button mat-icon-button matTooltip="Move Controller Type Up" (click)="onUp(element)">
                        <mat-icon>keyboard_arrow_up</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="Move Controller Type Down" (click)="onDown(element)">
                        <mat-icon>keyboard_arrow_down</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="Delete" (click)="onDelete(element)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </ng-container>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; let i = dataIndex;
            columns: columnsToDisplay;"
            class="item">
        </tr>

    </table>
</div>
