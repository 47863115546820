import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Utils } from '../../../../../../utils/utils';
import { ExcellenceLeverKpiComponent } from '../excellence-lever-kpi.component';

@Component({
    selector: 'createexcellenceleverkpi-popup',
    templateUrl: './createexcellenceleverkpi-popup.component.html',
    styleUrls: ['./createexcellenceleverkpi-popup.component.less']
})
export class CreateExcellenceLeverKpiPopupComponent implements OnInit {

    form!: FormGroup
    accounts: any[] = []
    excellenceLevers: any[] = []
    subscriptions: any[] = []

    constructor(
        public dialogRef: MatDialogRef<CreateExcellenceLeverKpiPopupComponent>,
        private formBuilder: FormBuilder,
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private toastrService: ToastrService) { }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            excellenceLeverKpiId: [this.data?.ExcellenceLeverKpiId],
            excellenceLever: [this.data?.ExcellenceLeverId, Validators.required],
            kpiDefinitionId: [this.data?.KpiDefinitionId, Validators.required],
        });

        Utils.httpGetAccounts(
            this.http
            , this.baseUrl
            , this
            , function (tthis: CreateExcellenceLeverKpiPopupComponent, data: any[]) {
                tthis.accounts = data;
            },
            null
        )

        Utils.httpGetExcellenceLevers(
            this.http
            , this.baseUrl
            , this
            , function (tthis: CreateExcellenceLeverKpiPopupComponent, data: any[]) {
                tthis.excellenceLevers = data;
            },
            null
        )
    }
    onSubmit() {
        // stop here if form is invalid
        if (this.form)
            if (this.form.invalid) {
                return;
            }

        Utils.httpUpsertExcellenceLeverKpi(
            this.http
            , this.baseUrl
            , this.form.value
            , this
            , function (tthis: CreateExcellenceLeverKpiPopupComponent, result: any) {
                tthis.dialogRef.close({ event: 'submit' });
                if (result) {
                    tthis.toastrService.success('KPI was assigned to the Excellence lever successfully!');
                }
                else
                    tthis.toastrService.error('KPI could not be assigned to Excellence Lever. Please, contact with administrator');
            },
            function (tthis: CreateExcellenceLeverKpiPopupComponent, result: any) {
                tthis.dialogRef.close({ event: 'submit' });
                tthis.toastrService.error('KPI was assigned previously. If not, please, contact with administrator');
            }
        );
    }

    getExcellenceLevers() {
        Utils.httpGetExcellenceLevers(
            this.http,
            this.baseUrl,
            this,
            function (tthis: CreateExcellenceLeverKpiPopupComponent, list: any) {
                tthis.excellenceLevers = list
            },
            null

        )
    }

    backButtonHandler(event: any) {
        event.preventDefault();
        this.dialogRef.close();
    }
}
