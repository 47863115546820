<div class="example-container">
    <button mat-icon-button (click)="openCreatePopup()" matTooltip="Add new Function Config" *ngIf="defaults.isadminuser">
        <mat-icon>add</mat-icon>
    </button>
    <table mat-table
           [dataSource]="dataSource"
           multiTemplateDataRows
           matSort
           class="mat-elevation-z8 mat-table-custom" id="functionsconfig">
        <ng-container *ngFor="let item of columnsToDisplay" matColumnDef="{{item}}">
            <th mat-header-cell *matHeaderCellDef>
                {{item === 'Enabled' || item === 'arrow' || item === 'index'? '' : item}}
                <mat-icon *ngIf="item !== 'Enabled' && item !== 'arrow' && item !== 'index'" matTooltipClass='opmodes-custom-tooltip' data-html="true" matTooltip="{{tooltips[item]}}">info</mat-icon>
            </th>

            <ng-container *ngIf="item==='Enabled'">
                <ng-container *matCellDef="let element">
                    <ng-container *ngIf="defaults.isControllerOwner">
                        <td mat-cell *ngIf="getInput(element,'Enabled') === true">
                            <button mat-icon-button matTooltip="Add new Function Param" (click)="onRowEditInit(undefined, element)">
                                <mat-icon>library_add</mat-icon>
                            </button>
                            <button mat-icon-button (click)="changeElement(element, false)" matTooltip="Disallow Function">
                                <mat-icon class="mat-icon-green">toggle_on</mat-icon>
                            </button>
                        </td>
                        <td mat-cell *ngIf="!getInput(element,'Enabled')">
                            <button mat-icon-button (click)="changeElement(element, true)" matTooltip="Allow Function">
                                <mat-icon class="mat-icon-grey">toggle_off</mat-icon>
                            </button>
                        </td>
                    </ng-container>
                    <td mat-cell *ngIf="!defaults.isControllerOwner">
                        <p class="mat-icon-green" *ngIf="getInput(element,'Enabled') === true">Enabled</p>
                        <p class="mat-icon-grey" *ngIf="getInput(element,'Enabled') === false">Disabled</p>
                    </td>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="item==='arrow'">
                <ng-container *matCellDef="let element">
                    <td mat-cell *ngIf="element['Enabled'] === true"
                        (click)="pushPopElement(element)">
                        <span class="material-icons" *ngIf="checkExpanded(element); else show_expand_more" matTooltip="Collapse">
                            expand_less
                        </span>
                    </td>
                    <td mat-cell *ngIf="!element['Enabled']">
                    </td>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="item === 'index'">
                <td mat-cell *matCellDef="let i = dataIndex">
                    <p>{{i+1}}</p>
                </td>
            </ng-container>
            <ng-container *ngIf="item!=='Enabled' && item !== 'arrow' && item !== 'index'">
                <ng-container *matCellDef="let element">
                    <td mat-cell>
                        <p [innerHTML]="getInput(element,item)"></p>
                    </td>
                </ng-container>
            </ng-container>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                <div [@detailExpand]="checkExpanded(element) ? 'expanded' : 'collapsed'" *ngIf="element.Parameters"
                     [class.expandedDetail]="checkExpanded(element)">
                    <table mat-table [dataSource]="element.Parameters" class="mat-subtable-custom" id="functionparameters">
                        <ng-container *ngFor="let item of subcolumnsToDisplay" matColumnDef="{{item}}">
                            <th mat-header-cell *matHeaderCellDef> {{item === 'actions' ? '' : item === 'FunctionConfigControllerParameterId' ? 'Function Param Id' : item}}  
                                <mat-icon *ngIf="item !== 'Enabled' && item !== 'actions'" matTooltipClass='opmodes-custom-tooltip' data-html="true" matTooltip="{{tooltips[item]}}">info</mat-icon></th>
                            <ng-container *ngIf="item==='actions'">
                                <td mat-cell *matCellDef="let subelement">
                                    <button mat-icon-button matTooltip="Edit Row" *ngIf="defaults.isControllerOwner"
                                            (click)="onRowEditInit(subelement, element)">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                </td>
                            </ng-container>
                            <ng-container *ngIf="item!=='actions'">
                                <td mat-cell *matCellDef="let subelement">
                                    <p [innerHTML]="getInput(subelement,item)"></p>
                                </td>
                            </ng-container>
                        </ng-container>

                        <tr mat-header-row class="subtableheader" *matHeaderRowDef="subcolumnsToDisplay"></tr>
                        <tr mat-row *matRowDef="let i2 = index; columns: subcolumnsToDisplay;"
                            class="subitem"></tr>
                    </table>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row class="tableheader" *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; let i = dataIndex; columns: columnsToDisplay;" class="item">
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="row-subitem"></tr>

    </table>
</div>

<ng-template #show_expand_more>
    <span class="material-icons" matTooltip="More Details">
        expand_more
    </span>
</ng-template>
