<div style="display:flow-root">
    <div style="float:left; width:65%">
        <mat-form-field class="search-input">
            <input placeholder="Filter"
                   matInput
                   [matChipInputFor]="chipList"
                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                   [matChipInputAddOnBlur]="addOnBlur"
                   (matChipInputTokenEnd)="applyFilter($event)">
            <mat-chip-list #chipList>
            </mat-chip-list>
        </mat-form-field>
        <mat-chip *ngFor="let item of searchItems" [selectable]="selectable"
                  [removable]="removable" (removed)="remove(item)">
            {{item.name}}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>

    </div>
    <div style="text-align:right; float:right; width:35%">
        <h2 style="cursor:pointer; display:block;" class="flex-aligned-div" (click)="openDeploymentInformationPopup()">
            Deployment information
            <mat-icon data-html="true" style="color:white">info</mat-icon>
        </h2>
        
    </div>
</div>
<div class="example-container new-table-style">
    <operationmode-new [header]="header" [list]="list" *ngIf="show" (onUpdateTable)="onNewRowEvent()"></operationmode-new>
    <table mat-table
           [dataSource]="dataSource"
           multiTemplateDataRows
           matSort
           (matSortChange)="sortData($event)"
           class="mat-elevation-z8 mat-table-custom" id="operationmodetableprop">

        <ng-container matColumnDef="offset">
            <th mat-header-cell *matHeaderCellDef> Offset </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.Offset"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="mode">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="mode"> Mode </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.Mode | bold: dataSource.filter"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="value"> Value </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.Value | bold: dataSource.filter"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="available">
            <th mat-header-cell *matHeaderCellDef>
            <tr>
                Equipment Status
                <mat-icon matTooltipClass='opmodes-custom-tooltip'
                          data-html="true" matTooltip="Property to define between 'Available' and 'Unavailable' equipment status values. &#013;Available operation modes will trigger Return to Operation.">info</mat-icon>
            </tr>
            <mat-select class='form-control filter' placeholder='Select' (selectionChange)="filterColumn($event, 'available')">
                <mat-option [value]="undefined">Select</mat-option>
                <mat-option value="true">Available</mat-option>
                <mat-option value="false">Unavailable</mat-option>
            </mat-select>
            </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]=" element.isUnitAvailable ? 'Available' : 'Unavailable' | bold: dataSource.filter" *ngIf="!isEditingRow(element)"></p>
                <mat-select [(ngModel)]="element.isUnitAvailable" *ngIf="isEditingRow(element)">
                    <mat-option [value]="true">Available</mat-option>
                    <mat-option [value]="false">Unavailable</mat-option>
                </mat-select>
            </td>
        </ng-container>


        <ng-container matColumnDef="isgroupevent">
            <th mat-header-cell *matHeaderCellDef>
            <tr> Is Group Event <mat-icon matTooltipClass='opmodes-custom-tooltip' data-html="true" matTooltip="Flag to indicate an operation mode is only applicable in group context.">info</mat-icon></tr>
            <mat-select class='form-control filter' placeholder='Select' (selectionChange)="filterColumn($event, 'groupevent')">
                <mat-option [value]="undefined">Select</mat-option>
                <mat-option value="true">Yes </mat-option>
                <mat-option value="false">No </mat-option>
            </mat-select>
            </th>
            <td mat-cell *matCellDef="let element">
                <mat-checkbox [checked]="element.isGroupEvent" [ngClass]="isEditingRow(element) ? 'black' :''" [disabled]="true" *ngIf="!isEditingRow(element)"></mat-checkbox>
                <mat-select [(ngModel)]="element.isGroupEvent" *ngIf="isEditingRow(element)">
                    <mat-option [value]="true">Yes</mat-option>
                    <mat-option [value]="false">No</mat-option>
                </mat-select>
            </td>
        </ng-container>

        <ng-container matColumnDef="safetyrelated">
            <th mat-header-cell *matHeaderCellDef>
            <tr> Maintenance Priority <mat-icon matTooltipClass='opmodes-custom-tooltip' data-html="true" matTooltip="Flag to indicate if the operation mode requires a high priority maintenance task, not necessarily leading to a shutdown (e.g. Fire sensor failure).">info</mat-icon></tr>
            <mat-select class='form-control filter' placeholder='Select' (selectionChange)="filterColumn($event, 'safetyrelated')">
                <mat-option [value]="undefined">Select</mat-option>
                <mat-option value="true">Yes</mat-option>
                <mat-option value="false">No</mat-option>
            </mat-select>
            </th>
            <td mat-cell *matCellDef="let element">
                <mat-checkbox [checked]="element.isSafetyRelated" [ngClass]="isEditingRow(element) ? 'black' :''" [disabled]="true" *ngIf="!isEditingRow(element)"></mat-checkbox>
                <mat-select [(ngModel)]="element.isSafetyRelated" *ngIf="isEditingRow(element)">
                    <mat-option [value]="true">Yes</mat-option>
                    <mat-option [value]="false">No</mat-option>
                </mat-select>
            </td>
        </ng-container>

        <ng-container matColumnDef="potentialmisuse">
            <th mat-header-cell *matHeaderCellDef>
            <tr> Potential Misuse <mat-icon matTooltipClass='opmodes-custom-tooltip' data-html="true" matTooltip="Flag to indicate if the operation mode could be caused by a potential misuse of the asset.">info</mat-icon></tr>
            <mat-select class='form-control filter' placeholder='Select' (selectionChange)="filterColumn($event, 'potentialmisuse')">
                <mat-option [value]="-1">Select</mat-option>
                <mat-option value="true">Yes</mat-option>
                <mat-option value="false">No</mat-option>
            </mat-select>
            </th>
            <td mat-cell *matCellDef="let element">
                <mat-checkbox [checked]="element.isPotentialMisuse" [ngClass]="isEditingRow(element) ? 'black' :''" [disabled]="true" *ngIf="!isEditingRow(element)"></mat-checkbox>
                <mat-select [(ngModel)]="element.isPotentialMisuse" *ngIf="isEditingRow(element)">
                    <mat-option [value]="undefined">Select</mat-option>
                    <mat-option [value]="true">Yes</mat-option>
                    <mat-option [value]="false">No</mat-option>
                </mat-select>
            </td>
        </ng-container>

        <ng-container matColumnDef="stablealertrule">
            <th mat-header-cell *matHeaderCellDef>
            <tr>Stable Alert Rule <mat-icon matTooltipClass='opmodes-custom-tooltip' data-html="true" matTooltip="Alert rule selector for Stable and Evaluation environments.">info</mat-icon></tr>
            <mat-select style="min-width: 150px;" class='form-control filter' placeholder='Select' (selectionChange)="filterColumn($event, 'stablealertruleid')">
                <mat-option [value]="-1">Select</mat-option>
                <mat-option *ngFor="let dep of alertRules" [value]="dep.Id">
                    {{ dep.Description }}
                </mat-option>
            </mat-select>
            </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.StableAlertRuleCat | bold: dataSource.filter" *ngIf="!isEditingRow(element) && !element.StableAlertRuleCat.startsWith('N/A')"></p>
                <mat-form-field floatLabel="never" *ngIf="isEditingRow(element)">
                    <mat-select style="min-width: 200px; color:red" [(ngModel)]="element.StableAlertRuleCatId">
                        <mat-option *ngFor="let dep of alertRules" [value]="dep.Id">
                            {{ dep.Description }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container matColumnDef="prodalertrule">
            <th mat-header-cell *matHeaderCellDef>
            <tr>Production Alert Rule <mat-icon  matTooltipClass='opmodes-custom-tooltip' data-html="true" matTooltip="Alert rule selector for Production environment.">info</mat-icon></tr>
            <mat-select style="min-width: 150px;" class='form-control filter' placeholder='Select' (selectionChange)="filterColumn($event, 'prodalertruleid')">
                <mat-option [value]="-1">Select</mat-option>
                <mat-option *ngFor="let dep of alertRules" [value]="dep.Id">
                    {{ dep.Description }}
                </mat-option>
            </mat-select>
            </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.ProdAlertRuleCat | bold: dataSource.filter" *ngIf="!isEditingRow(element) && !element.ProdAlertRuleCat.startsWith('N/A')"></p>
                <mat-form-field floatLabel="never" *ngIf="isEditingRow(element)">
                    <mat-select style="min-width: 200px; color:red" [(ngModel)]="element.ProdAlertRuleCatId">
                        <mat-option *ngFor="let dep of alertRules" [value]="dep.Id">
                            {{ dep.Description }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container matColumnDef="isgoodevent">
            <th mat-header-cell *matHeaderCellDef>
            <tr> Is Good Event? </tr>
            <mat-select class='form-control filter' placeholder='Select' (selectionChange)="filterColumn($event, 'isgoodevent')">
                <mat-option [value]="-1">Select</mat-option>
                <mat-option value="true">Yes</mat-option>
                <mat-option value="false">No</mat-option>
            </mat-select>
            </th>
            <td mat-cell *matCellDef="let element">
                <mat-checkbox [checked]="element.IsGoodEvent" [ngClass]="isEditingRow(element) ? 'black' :''" [disabled]="true" *ngIf="!isEditingRow(element)"></mat-checkbox>
                <mat-select [(ngModel)]="element.IsGoodEvent" *ngIf="isEditingRow(element)">
                    <mat-option [value]="undefined">Select {{element.isGoodEvent}}</mat-option>
                    <mat-option [value]="true">Yes</mat-option>
                    <mat-option [value]="false">No</mat-option>
                </mat-select>
            </td>
        </ng-container>

        <ng-container matColumnDef="component">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="component"> Component </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.Component | bold: dataSource.filter" *ngIf="!isEditingRow(element)"></p>
                <mat-form-field floatLabel="never" *ngIf="isEditingRow(element)">
                    <input matInput [value]="element.Component" [(ngModel)]="element.Component">
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="eqStatus">
            <tr>Equipment Specific Status</tr>
            <tr><mat-icon matTooltipClass='opmodes-custom-tooltip' data-html="true" matTooltip="Internal mapping among all controller specific op modes to a predefined op modes. The relationship between op modes and harmonized op modes is 1: 1.">info</mat-icon></tr>
            </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.SpecificEquipmentStatus | bold: dataSource.filter" *ngIf="!isEditingRow(element)"></p>
                <mat-form-field floatLabel="never" [ngClass]="notIndicated(element.HarmonizedOperationModeID)" *ngIf="isEditingRow(element)">
                    <mat-select style="min-width: 200px; color:red" [(ngModel)]="element.HarmonizedOperationModeID">
                        <mat-option *ngFor="let dep of harmonizedopmodes" [value]="dep.HarmonizedOperationModeID">
                            {{ dep.HarmonizedDescription }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </ng-container>

        <!--<ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="description"> Description </th>
            <td mat-cell *matCellDef="let element" pEditableColumn>
                <mat-form-field floatLabel="never" *ngIf="isEditingRow(element)">
                    <input matInput type="text" [value]="element.Description" [(ngModel)]="element.Description">
                </mat-form-field>
            </td>
        </ng-container>-->

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button *ngIf="!isEditingRow(element)" matTooltip="Edit Row"
                        (click)="onRowEditInit(element)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button *ngIf="isEditingRow(element)" matTooltip="Cancel Row"
                        (click)="onRowEditCancel(element)">
                    <mat-icon>rotate_left</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Delete Row"
                        (click)="onDelete(element)">
                    <mat-icon>delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; let i = dataIndex;
            columns: columnsToDisplay;"
            class="item"
            [class.alternative2]="isEditingRow(element)">
        </tr>
    </table>
</div>
