import { Component, Inject, Input, Output, EventEmitter, OnInit, OnChanges } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Utils } from "../../utils/utils";
import { TestCaseFormComponent } from "./testcase-form.component";
import { DialogService } from "../../services/dialog.service";
import { SaveService } from "../../services/save.service";
import { MatDialog } from "@angular/material/dialog";
import { DefaultValuesService } from "../../services/defaultvalues.service";
import { TestCaseFormDatabricksTriggerComponent } from "./testcase-form-databricks-trigger.component";
import { Subject } from "rxjs";
import { MatTableDataSource } from "@angular/material/table";

@Component({
    selector: "testcase-list-databricks-trigger",
    templateUrl: './testcase-list-databricks-trigger.component.html',
    styleUrls: ['../../style/list.component.less', './testcase-list.component.less']
})

export class TestCaseListDatabricksTriggerComponent implements OnInit, OnChanges {
    @Input('list') list: TestCaseDatabricksTrigger[] = [];

    @Output() onRefresh = new EventEmitter<{}>();

    title: string;

    columnsToDisplay = ['deviceId', 'dateFrom', 'dateTo', 'executed'];
    public dataSource = new MatTableDataSource<TestCaseDatabricksTrigger>();

    protected _onDestroy = new Subject<void>();

    constructor(
        private http: HttpClient
        , private activatedRoute: ActivatedRoute
        , private dialogService: DialogService
        , private saveService: SaveService
        , @Inject('BASE_URL') private baseUrl: string
        , private dialog: MatDialog
        , public defaults: DefaultValuesService

    ) {
        this.title = "Databricks Triggers";
    }

    ngOnInit() {
        this.saveService.addListener(this);
    }

    ngOnChanges() {
        this.dataSource.data = this.list
    }

    ngOnDestroy() {
        this.saveService.removeListener(this);
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    /**
     * called by the SaveService
     * */
    onCancel() {
        this.dialog.closeAll();
    }

    onNewElement() {
        /**
         * set the tthis on dialgService to the child forms call the getHeader
         **/
        this.dialogService.tthis = this;
        this.saveService.addListener(this);
        const dialogRef = this.dialog.open(TestCaseFormDatabricksTriggerComponent, { panelClass: 'screen-dialog' });
        const tthis = this;
        dialogRef.afterClosed().subscribe(result => {
            this.onRefresh.emit({});
        });
    }

    /**
     * called by the SaveService
     * */
    onSave() {
        this.dialog.closeAll();
    }
}