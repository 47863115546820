import { Component, Inject, OnInit, OnDestroy } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Utils } from "../../utils/utils";
import { DefaultValuesService } from "../../services/defaultvalues.service";

@Component({
    selector: "businesskey",
    templateUrl: './businesskey.component.html',
    styleUrls: ['../../style/container-tab-list.component.less']
})

export class BusinessKeyComponent implements OnInit, OnDestroy {
    title = "Business Keys";
    language = {} as Language;
    translationarea = {} as TranslationArea;
    businesskeys: BusinessKey[] = [];
    selected = {} as BusinessKey;
    editMode = false;

    subscriptionLanguage: any;

    constructor(
        private http: HttpClient
        , private defaults: DefaultValuesService
        , @Inject('BASE_URL') private baseUrl: string
    ) {
    }

    ngOnInit() {
        this.subscriptionLanguage = this.defaults.languageMessageChanges$.subscribe((item: Language) => {
            this.onLanguageChanged(item)
        });
        this.language = this.defaults.language;
    }

    ngOnDestroy() {
        this.subscriptionLanguage.unsubscribe();
    }

    onCancel() {
        this.editMode = false;
    }

    onTranslationAreaChanged(ta: TranslationArea) {
        this.translationarea = ta;
        this.refreshAll();
    }

    onLanguageChanged(l: Language) {
        this.language = l;
        this.refreshAll();
    }

    onResetList() {
        this.refreshAll();
    }

    onSearchListDescription(obj: any) {
        //{ ShowRef: boolean, Index: number, Value: string }
        const l = <BusinessKey[]>[];
        let value = obj.Value;
        for (let i = 0; i < this.businesskeys.length; ++i) {

            switch (obj.Index) {
                case 0:
                    if (this.businesskeys[i].BK)
                        if (this.businesskeys[i].BK.toLowerCase().indexOf(obj.Value.toLowerCase()) != -1)
                            l.push(this.businesskeys[i]);
                    break;
                case 1:
                    if (obj.ShowRef) {
                        if (this.businesskeys[i].Description_REF)
                            if (this.businesskeys[i].Description_REF.toLowerCase().indexOf(obj.Value.toLowerCase()) != -1)
                                l.push(this.businesskeys[i]);
                    }
                    break;
                default:
                    if (this.businesskeys[i].Description)
                        if (this.businesskeys[i].Description.toLowerCase().indexOf(obj.Value.toLowerCase()) != -1)
                            l.push(this.businesskeys[i]);
            }
        }
        this.businesskeys = l;
    }

    refreshAll() {
        Utils.httpGetAllBusinessKeys(
            this.http
            , this.baseUrl
            , this.translationarea.TranslationAreaID
            , this
            , function (tthis: any, list: BusinessKey[]) {
                tthis.businesskeys = list;
            }
        );
    }

    downloadBusinessKeys() {
        Utils.httpDownloadBusinessKeys(
            this.http
            , this.baseUrl
            , this.translationarea.TranslationAreaID
            , this.translationarea.Name
        );
    }

}