<div style="display:flow-root">
<div style="float:left">
    <h5>This is the EMT Software Version For Configuration Generation management screen. </h5>
</div>
</div>
<div class="flex-center">
    <div class="contentWidth">
        <div style="height: calc(90vh - 100px); overflow-y: auto;">
            <form [formGroup]="SVCGForm" autocomplete="off">
                <ng-container formArrayName="SVCGRows">
                    <table #objTable
                           mat-table
                           matSort
                           [dataSource]="dataSource"
                           class="table-add-butt-header-orange mat-elevation-z8 mat-table-custom">

                        <ng-container matColumnDef="headertitle">
                            <th class="title-col" mat-header-cell *matHeaderCellDef colspan="2">
                                <div class="pull-left">
                                    <h4>Controller Software Configurations</h4>
                                </div>
                            </th>
                        </ng-container>
                        <ng-container matColumnDef="headeraddbutton">
                            <th class="butt-col" mat-header-cell *matHeaderCellDef>
                                <div class="OrangeButton">
                                    <button mat-raised-button (click)="addNewRow()"
                                            matTooltip="Add Configuration" class="button-color-fix pull-right">
                                        <mat-icon class="icon-color-fix icon-padding-fix">add_box</mat-icon>
                                    </button>
                                </div>
                            </th>
                        </ng-container>


                        <!-- Center Product Column -->
                        <ng-container matColumnDef="centerproduct">
                            <th mat-header-cell *matHeaderCellDef style="width:45%">Manufacturing Center Product</th>
                            <td mat-cell *matCellDef="let element; let i = index" style="width:45%">
                                <div [innerHTML]="getCenterProductText(SVCGForm?.get('SVCGRows')?.value[i].manufacturingCenterProductId)" *ngIf="SVCGForm?.get('SVCGRows')?.value[i]?.isEditable"></div>
                                <mat-form-field floatLabel="never" *ngIf="!SVCGForm?.get('SVCGRows')?.value[i]?.isEditable">
                                    <mat-select style="min-width: 200px; color:red" [(ngModel)]="SVCGForm?.get('SVCGRows')?.value[i].manufacturingCenterProductId"
                                                [ngModelOptions]="{standalone: true}">
                                        <mat-option [value]="-1" [disabled]="true">Select</mat-option>
                                        <mat-option *ngFor="let cp of availableCenterProducts" [value]="cp.manufacturingCenterProductID">
                                            {{getCenterProductText(cp.manufacturingCenterProductID)}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <!-- Controller Software Version Column -->
                        <ng-container matColumnDef="controllersoftwareversion">
                            <th mat-header-cell *matHeaderCellDef style="width:45%">Controller Software Version</th>
                            <td mat-cell *matCellDef="let element; let i = index" style="width:45%">
                                <div [innerHTML]="getControllerSoftwareText(SVCGForm?.get('SVCGRows')?.value[i].controllerSoftwareVersionId)" *ngIf="SVCGForm?.get('SVCGRows')?.value[i]?.isEditable"></div>
                                <mat-form-field floatLabel="never" *ngIf="!SVCGForm?.get('SVCGRows')?.value[i]?.isEditable">
                                    <mat-select style="min-width: 200px; color:red" [(ngModel)]="SVCGForm?.get('SVCGRows')?.value[i].controllerSoftwareVersionId"
                                                [ngModelOptions]="{standalone: true}">
                                        <mat-option [value]="-1" [disabled]="true">Select</mat-option>
                                        <mat-option *ngFor="let cs of availableControllerSoftwareReleases" [value]="cs.controllerSoftwareVersionID">
                                            {{getControllerSoftwareText(cs.controllerSoftwareVersionID)}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef style="width:10%"></th>
                            <td mat-cell
                                *matCellDef="let element; let i = index" style="width:10%">

                                <button mat-icon-button
                                        *ngIf="!SVCGForm.get('SVCGRows')?.value[i]?.isEditable"
                                        matTooltip="Cancel Changes"
                                        color="warn"
                                        (click)="resetRow(i)">
                                    <mat-icon class="actionButtons">rotate_left</mat-icon>
                                </button>

                                <button mat-icon-button
                                        *ngIf="SVCGForm.get('SVCGRows')?.value[i]?.isEditable"
                                        matTooltip="Edit"
                                        color="primary"
                                        (click)="editRow(i)">
                                    <mat-icon class="actionButtons">edit</mat-icon>
                                </button>

                                <button mat-icon-button
                                        *ngIf="false"
                                        matTooltip="Delete"
                                        color="warn"
                                        (click)="deleteRow(i)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="savefooter">
                            <th mat-footer-cell *matFooterCellDef colspan="3">
                                <div class="OrangeButton">
                                    <button mat-raised-button type="submit" (click)="onSave()"
                                            [disabled]="!anyChange()"
                                            [matTooltip]="anyChange()? 'Save changes' : 'Save disabled'"
                                            class="button-color-fix pull-right">
                                        Save
                                        <mat-icon style="color:white !important" class="icon-color-fix">save</mat-icon>
                                    </button>
                                </div>
                            </th>

                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="['headertitle', 'headeraddbutton']; sticky: true" class="stickyTopz100"></tr>
                        <tr class="col-headers" mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; let i = index; columns: columnsToDisplay;" [class.alternative2]="!SVCGForm.get('SVCGRows')?.value[i]?.isEditable"></tr>
                        <tr mat-footer-row *matFooterRowDef="['savefooter']; sticky: true"></tr>
                    </table>
                </ng-container>
            </form>
        </div>
    </div>
</div>
