import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-spinner-overlay',
    templateUrl: './spinner-overlay.component.html',
    encapsulation: ViewEncapsulation.None
    // styleUrls: ['./spinner-overlay.component.css'],
})
export class SpinnerOverlayComponent {
    constructor() { }
}