import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import * as XLSX from 'xlsx';
import { APICallStatus, EmailSearchfor, UMPAction, UMPCategory, Utils } from '../../../../../utils/utils';
import { DefaultValuesService } from '../../../../../services/defaultvalues.service';
import { usercreatexternal } from '../../../../../interfaces/usermanagementportal/usercreateexternal';
import { UserManagePersonaeChangelogViewModel } from '../../../../../interfaces/UserManagePersonaeChangelogViewModel';
type AOA = any[][];

interface Role {
    id: string,
    persona: string
}


const URL = '/api/';
const existingUserMessage = 'Existing user. No action will be taken';

@Component({
    selector: "tkebulkextuser",
    templateUrl: './tkebulkextuser.component.html',
    styleUrls: ['./tkebulkextuser.component.less'],
    encapsulation: ViewEncapsulation.None

})

export class TKEBulkExtComponent implements OnInit {
    @Input('tenants') tenants: SelectList[] = [];
    @Output() onBulkCreateUserTrigger = new EventEmitter<boolean>();
    @Output() onBackTrigger = new EventEmitter();


    public uploader!: FileUploader
    public hasBaseDropZoneOver: boolean = false;
    public hideBulkTable: boolean = true;
    public isButtonDisabled: boolean = true;
    public showAllSuccessfullyValidated: boolean = false;
    public fileName: string = '';
    public fileSize: number = 0;
    isfilePreloadError = false;
    tableResponseState: APICallStatus = APICallStatus.NotRequested;

    allAvlblRoles: { [RoleType: string]: Role[] } = {}; // saving alll the roles related to one role type inside a dictionary
    //Check the permission required for each section (Roles)(Made this way for scalability)
    sectionPermissionPair: { [RoleType: string]: boolean } = {
        'EMT': this.defaults.isEMTManagerUser
    };

    compulsoryFields = ['Email', 'FirstName', 'LastName', 'DisplayName', 'PrimaryTenant', 'CompanyName']
    specialFields = ['EMT Role'] //these are acceptad values for role the code is pretty dynamic to adapt to new roles
    preLoadErrorMsg = '';

    verificationMessage: string = "";
    constructor(private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , public defaults: DefaultValuesService
    ) {

    }

    get isLoading(): boolean {
        return this.tableResponseState === APICallStatus.Waiting4Response;
    }

    ngOnInit() {
        this.uploader = new FileUploader({
            url: URL,
            autoUpload: true,
            allowedFileType: ['xlsx']
        });
        this.uploader.onAfterAddingFile = f => {
            if (this.uploader.queue.length > 1) {
                this.uploader.removeFromQueue(this.uploader.queue[0]);
            }
        };
        this.uploader.onWhenAddingFileFailed = (item) => {
        }
    }
    fileObject: any;
    public fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
    }

    data: AOA = [[], []];
    validData: AOA = [[], []];
    wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };


    async onFileChange(evt: any) {
        this.tableResponseState = APICallStatus.Waiting4Response;
        this.hideBulkTable = true;
        this.showAllSuccessfullyValidated = false;
        this.isfilePreloadError = false;
        const files: FileList | null = evt;
        const file = files?.item(0) as File
        if (file !== null) {
            this.fileName = file.name;
            this.fileSize = file.size;
        }
        /* wire up file reader */
        // const target: DataTransfer = evt.target as DataTransfer;
        if (evt.length !== 1) {
            throw new Error('Cannot use multiple files');
        }
        await this.importExcelDoc(evt);
    }

    async importExcelDoc(event: any) {
        await this.parseExcelDoc(event[0]).catch(err => console.error(err));
    }

    parseExcelDoc(file: File): Promise<any> {
        /* wire up file reader */
        let fileReader = new FileReader();
        return new Promise((resolve, reject) => {
            fileReader.onload = async (e: any) => {
                /* read workbook */
                const bstr: string = e.target.result;
                const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

                /* grab first sheet */
                const wsname: string = wb.SheetNames[0];
                const ws: XLSX.WorkSheet = wb.Sheets[wsname];

                this.data = await XLSX.utils.sheet_to_json(ws, { range: 0, header: 1, blankrows: false }) as AOA;

                if (this.verifyTemplate()) {
                    if (await this.checkAndPopulRoles(this.data[0]))//Everytime file is uploaded we populate dictionary with all valid values of roles
                    {
                        this.data = this.data.slice(1); // We skip headers row
                        this.bulkVerification(); // Adds a 6th Second instead row with verifications and also puts the tenants in uppercase
                    }
                }
                resolve(this.data);
            }
            fileReader.onerror = reject;
            fileReader.readAsArrayBuffer(file);
        });
    };



    getColor(message, index) {
        if (index == 1) {
            if (message === 'Success')
                return 'green';
            else if (message === existingUserMessage)
                return 'orange';
            else
                return 'red';
        }
        else
            return null;
    }

    bulkVerification() {
        const emaiPattern: RegExp = /@tkelevator\.com$/i;
        var tenantArray: Array<string> = this.tenants.map(x => x.value);
        for (let i = 0; i < this.data.length; i++) {
            //let message = "";
            this.verificationMessage = "";
            if (this.data[i].length < 1)
                continue;
            if (this.data[i].length < 1) {
                this.verificationMessage = 'Invalid Primary Tenant';
            }
            // if the required fields are present we will continue to verify further 
            if (this.rowRequired(this.data[i])) {
                //To make the tenants upper case
                this.data[i][4] = this.data[i][4].toUpperCase();

                if (!this.stringInArray(tenantArray, this.data[i][4])) {
                    this.verificationMessage = 'Invalid Primary Tenant';
                }

                else {
                    this.verificationMessage = 'Success';
                }
            }
            this.data[i].splice(1, 0, this.verificationMessage);
        }

        if (this.data.filter(d => Object.keys(d).length > 0).some(d => d[1] === 'Success')) {
            Utils.httpGetUsersGroupListByFilter(
                this.http
                , this.baseUrl
                , {
                    "Page": 0,
                    "PageSize": 5,
                    "UserPrincipalName": "",
                    "GivenName": "",
                    "CdpGroup": [""],
                    "NodeId": [""],
                    "Tenant": tenantArray.map(t => t),
                    "MailO356Account": this.data.filter(d => d[1] === 'Success').map(d => d[0]).join(','),
                    "EmailSearchFor": EmailSearchfor.UserCreation
                }, this
                , function (tthis: TKEBulkExtComponent, data: UserList) {
                    if (data) {
                        tthis.tableResponseState = APICallStatus.ResponseDataOk;
                        if (data.users !== undefined && data.users.length > 0 || data.allowedNewUserType?.length > 0) {
                            for (let i = 0; i < tthis.data.length; i++) {
                                if (data.allowedNewUserType?.length > 0) {
                                    let showError = true
                                    data?.allowedNewUserType.forEach(type => {
                                        var typedot = type == '' ? type : '.' + type
                                        tthis.data[i][0].toLocaleLowerCase().includes(`${typedot}@tkelevator.com`) ? showError = false : null
                                    })
                                    if (showError) {
                                        tthis.data[i][1] = `Only Allowed ${data?.allowedNewUserType.join('/')} emails`;
                                        break;
                                    }
                                }
                                if (data.users !== undefined && data.users.length > 0) {

                                    if (data.users.some(x => x.userPrincipalName === tthis.data[i][0] && tthis.data[i][1] === 'Success'))
                                        tthis.data[i][1] = existingUserMessage;
                                }
                            }
                        }
                    }
                    else
                        tthis.tableResponseState = APICallStatus.ResponseEmptyOk;

                    tthis.validateCreate();
                }
                , this.defaults.isDataAnalytStage()
            )
        }
        else {
            this.tableResponseState = APICallStatus.ResponseDataOk;
            this.validateCreate();
        }
    }

    validateCreate() {
        this.validData = this.data.filter(d => Object.keys(d).length > 0 && d[1] === 'Success');
        this.data = this.data.filter(d => Object.keys(d).length > 0 && d[1] !== 'Success');
        this.showAllSuccessfullyValidated = this.validData.length > 0 && this.data.length == 0;
        this.isButtonDisabled = this.validData.length == 0;
        this.hideBulkTable = this.data.length == 0;
    }

    stringInArray(arr: string[], str: string): boolean {
        return arr.some((item) => item.toUpperCase() === str.toUpperCase());
    }


    rowRequired(row) {
        if (!row[0]) {
            this.verificationMessage = 'Email is a required field';
            return false;

        }
        else if (!row[1]) {
            this.verificationMessage = 'FirstName is a required field';
            return false;

        }
        else if (!row[2]) {
            this.verificationMessage = 'LastName is a required field';
            return false;

        }
        else if (!row[3]) {
            this.verificationMessage = 'DisplayName is a required field';
            return false;

        }
        else if (!row[4]) {
            this.verificationMessage = 'PrimaryTenant is a required field';
            return false;

        }
        else if (!row[5]) {
            this.verificationMessage = 'Company Name is a required field';
            return false;

        }

        else if (row.length >= 7) {
            //if there are more column than 6 we assum that we have roles and then using the dictionary we verify that the roles exist
            var allRoleVerified = false;
            let colIndex = 6;
            for (const [roleType, Roles] of Object.entries(this.allAvlblRoles)) {
                var roleFound = false
                //searching in the roles related to on type eg: EMTROLES: [{competitor},{admin}]....
                for (let role of Roles) {
                    if (role.persona.trim() == row[colIndex].trim() || !row[colIndex]) {
                        roleFound = true
                        break;
                    }
                }
                if (!roleFound) {
                    this.verificationMessage = `InValid ${roleType}`;
                    return false;
                }
                colIndex++;
                if (row.length == colIndex) {
                    allRoleVerified = true
                    return allRoleVerified

                }
            }
            //if the legth of column is longer than the number of roles we have we will have error 
            this.verificationMessage = "Could not Verify Roles";
            return allRoleVerified
        }

        else {
            return true;
        }
    }


    bulkUsers() {

        let externalUsers: usercreatexternal[] = []

        this.validData.forEach((obj: string[]) => {
            if (obj.length > 0)
                externalUsers.push({
                    email: obj[0],
                    firstName: obj[2],
                    lastName: obj[3],
                    displayName: obj[4],
                    primaryTenant: obj[5],
                    companyName: obj[6],
                    UMPChangelogViewModel: obj.length > 7 ? this.populateUmpChangLogObject(obj) : []
                    //    secondaryTenants: obj[5]? obj[5].split(','):['']
                })

        })
        if (externalUsers.length > 0) {
            Utils.httpCreateBulkExternalUsers(
                this.http
                , this.baseUrl
                , externalUsers
                , this
                , function (tthis: TKEBulkExtComponent, data: boolean) {
                    tthis.onBulkCreateUserTrigger.emit(data);
                }
            )
        }


    }
    populateUmpChangLogObject(row) {
        let UMPChangelogViewModel: UserManagePersonaeChangelogViewModel[] = [];
        let colIndex = 7;
        let groupIds: string[] = [];


        for (const [roleType, Roles] of Object.entries(this.allAvlblRoles)) {
            for (let role of Roles) {
                if (role.persona.trim() == row[colIndex].trim() || !row[colIndex]) {
                    groupIds.push(role.id)
                }
            }

            let role: UserManagePersonaeChangelogViewModel = {
                Personae: groupIds,
                UmpAction: UMPAction.Assign,
                UmpCategory: this.getCategory(roleType),
                Type: 0
            };
            UMPChangelogViewModel.push(role);
            colIndex++;
            if (row.length == colIndex) {
                break;
            }
        }
        return UMPChangelogViewModel;
    }

    getCategory(roleType) {
        let resp = UMPCategory.None
        switch (roleType) {
            case "EMT Role":
                resp = UMPCategory.EmtRoles
                break
        }
        return resp
    }

    backButtonHandler(event: any) {
        event.preventDefault();
        this.onBackTrigger.emit();
    }


    getexcelfile() {
        Utils.httpGetBulkUserExcelTemplate(
            this.http
            , this.baseUrl
            , this
            , function (tthis: TKEBulkExtComponent, data: any) {
                Utils.downloadBlobFile(data, 'BulkUserTemp.xlsx')
            }
        )
    }

    async checkAndPopulRoles(row) {
        if (row.length >= 7) {
            for (let index = 6; index < row.length; index++) {
                try {
                    const groupData = await this.funcGetRoles(row[index].split(" ")[0])
                    this.allAvlblRoles[row[index]] = groupData.personae;
                }
                catch (error) {
                    this.customError(error);
                    return false
                }
            }
        }
        return true
    }

    async funcGetRoles(roleType): Promise<any> {
        let response
        if (this.sectionPermissionPair[roleType]) {
            const url = this.baseUrl + Utils.getUMPUser() + "/allrelatedroles/" + roleType.toLowerCase() + 'role';
            response = await this.http.get<any>(url).toPromise();
            if (!response || (Array.isArray(response) && response.length === 0)) {
                this.tableResponseState = APICallStatus.ResponseEmptyOk;
                throw new Error(`No data found for ${roleType}`);
            }
        }
        else {

            throw new Error(`FileError: Insuffiecient permissions:: You donot have permissions for ${roleType} assignment,Download the bulk template again.`);
        }
        return response;
    }

    verifyTemplate() {
        let headerRow = this.data[0];
        const headerset = new Set(headerRow);
        if (headerset.size !== headerRow.length) {
            this.errorManager('RepeatedHeaders')
            return false;
        }


        for (let i = 0; i < this.compulsoryFields.length; i++) {
            if (this.compulsoryFields[i] !== headerRow[i]) {
                this.errorManager('CompulsoryFieldMissing')
                return false;
            }
        }
        if (headerRow.length >= 7) {
            for (let index = 6; index < headerRow.length; index++) {
                if (!this.specialFields.includes(headerRow[index])) {
                    this.errorManager('InValidOptionalField')
                    return false
                }
            }
        }
        if (this.data.some(row => row.length > headerRow.length)) {
            this.errorManager('DataRow')
            return false
        }
        return true
    }
    customError(errorMessage) {
        this.isfilePreloadError = true;
        this.preLoadErrorMsg = errorMessage;
    }
    errorManager(errorType) {

        if (errorType == 'CompulsoryFieldMissing') {
            this.isfilePreloadError = true;
            this.preLoadErrorMsg = 'FileError: Compulsory Field Missing In Header:: Please download the template again clicking add_bulk_user_template';
        }
        else if (errorType == 'InValidOptionalField') {
            this.isfilePreloadError = true;
            this.preLoadErrorMsg = 'FileError: Invalid Optional Field In Header:: Please download the template again clicking add_bulk_user_template';
        }
        else if (errorType == 'RepeatedHeaders') {
            this.isfilePreloadError = true;
            this.preLoadErrorMsg = 'FileError: Repeated Headers:: Repeated Headers in the template.';
        }
        else if (errorType == 'DataRow') {
            this.isfilePreloadError = true;
            this.preLoadErrorMsg = 'FileError: DataRowError:: More column in data row than headers row';
        }



    }

}






