import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from
    "@angular/forms";
import { ActivatedRoute } from '@angular/router';
import { forkJoin, EMPTY } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from "@angular/forms";
import { DialogService } from '../../../../../services/dialog.service';
import { Utils } from '../../../../../utils/utils';
import { CheckContainedPassword, ConfirmPasswordValidator, GeneratePassword, PasswordStrengthValidator } from '../../adduser/common_form_tools';
import { SpinnerOverlayService } from '../../../../../services/spinner-overlay.service';


interface Item {
    id: number;
    persona: string;
    // Add more properties if needed
}



@Component({
    selector: "resetpwddialogue",
    templateUrl: './resetpwddialogue.component.html',
    styleUrls: ['./resetpwddialogue.component.less'],

})

export class ResetPwdDialogue implements OnInit {
    public tkeUserForm: FormGroup;
    isCallSucceed: number = 0;
    passHide = true;
    confirmPassHide = true;

    constructor(
        fb: FormBuilder,
        private http: HttpClient
        , public dialogRef: MatDialogRef<ResetPwdDialogue>
        , private confimDialog: DialogService
        , private toastrService: ToastrService,
        private route: ActivatedRoute,
        private spinnerOverlayService: SpinnerOverlayService,
        @Inject('BASE_URL') private baseUrl: string,
        @Inject(MAT_DIALOG_DATA) public data: any

    ) {
       
        //this.UserUnAssignedGroups = data.UserUnAssignedGroups;
        this.tkeUserForm = fb.group(
            {
                useremail: [this.data.useremail],
                password: [null, Validators.compose([
                    Validators.required, PasswordStrengthValidator])],
                confirmPassword: ['', [Validators.required]],
                ForceChangePasswordNextLogin: [false],
              
            }, {
                validator: [ConfirmPasswordValidator("password", "confirmPassword"), CheckContainedPassword("password", "useremail")]
        });

    }

    ngOnInit() {

    }

    genPass(event: any) {
        event.preventDefault();
        const tempPass = GeneratePassword();
        this.tkeUserForm.patchValue({
            password: tempPass,
            confirmPassword: tempPass
        })
    }
    passHideFunc(event: any) {
        event.preventDefault();
        this.passHide = !this.passHide
    }
    handleConfirmPass(event: any) {
        event.preventDefault();
        this.confirmPassHide = !this.confirmPassHide;
    }

    onSubmit() {
        Utils.httpPostChangePassword(
            this.http
            , this.baseUrl
            , this.tkeUserForm.getRawValue()
            , this.data.userId
            , this
            , function (tthis: ResetPwdDialogue, data: any) {
                tthis.funcNotify();
                tthis.dialogRef.close();
            }
        )
    }
    funcNotify() {
        if (this.isCallSucceed === 1) {
            this.toastrService.success('Change Password request sent succefully !', 'Change Password Request!');
        }
        else if (this.isCallSucceed === -1) {
            this.toastrService.error('Change Password not request sent succefully !', 'Change Password Request!');
        }
    }
    backButtonHandler(event: any) {
        event.preventDefault();
        this.dialogRef.close();
    }

}
