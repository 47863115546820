<div class="container-tab-lists" *ngIf="form">
    <div class="form-edit">
        <h2>{{title}} {{description}}</h2>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">

            <p>
                {{message}}
                <br />{{this.resource === 14 ? '' : 'You must use the template Excel file, to fill with the new values'}}<br />
            </p>
            <div class="form-group">
                <label for="text">Excel File to Upload:</label>
                <br />
                <input type="file" required
                       formControlName="File"
                       class="form-control"
                       accept=".xlsx, .xml"
                       (change)="onFileChange($event)" />
            </div>
            <div class="form-group commands">
                <button type="submit"
                        [disabled]="form.invalid"
                        class="btn btn-success">
                    Upload
                </button>
                <button type="button"
                        title="Back"
                        (click)="onBack()"
                        class="btn btn-warning">
                    Cancel
                </button>
            </div> <!--form-group commands-->
        </form>
    </div> <!--form-edit-->
</div>
