import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import "rxjs/operators";
import { Data, Router } from '@angular/router';
import { DialogService } from '../../../services/dialog.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Utils } from '../../../utils/utils';
import { DefaultValuesService } from '../../../services/defaultvalues.service';
import * as moment from 'moment';
import { DatePipe } from '@angular/common'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: "controllertypeharmonizatioform",
    templateUrl: './controllertypeharmonization-form.component.html',
    styleUrls: ['../../../style/azdatafactory.component.less', '../../../style/custom-mat-dialog.component.less']

})

export class ControllerTypeHarmonizationFormComponent implements OnInit {


    selected = {} as ControllerTypeByTenant;

    controllerTypes: ControllerType[] = [];

    body: any;
    title: string;
    submitted = false;
    form: FormGroup = new FormGroup({});

    constructor(private http: HttpClient
        , private confimDialog: DialogService
        , private router: Router
        , @Inject(MAT_DIALOG_DATA) public data: any
        , @Inject('BASE_URL') private baseUrl: string
        , public dialogRef: MatDialogRef<ControllerTypeHarmonizationFormComponent>
        , private fb: FormBuilder
        , private defaults: DefaultValuesService
        , private formBuilder: FormBuilder
        , private datepipe: DatePipe) {

        this.confimDialog.tthis = this;
        this.title = "";
    }

    ngOnInit(): void {
        //this.body = {
        //    SessionId: ''
        //}

        const tthis = this;
        Utils.httpGetAllControllerTypesAvailable(
            this.http
            , this.baseUrl
            , this
            , function (tthis: any, list: ControllerType[]) {
                tthis.controllerTypes = list;
            }
        );

        this.body = {
            ControllerType: this.data.ControllerTypeBK
        }
        

        this.form = this.formBuilder.group({
            ControllerType: [this.data.ControllerTypeBK],
        });

        //this.form = this.formBuilder.group({
        //    ControllerType: [this.data.ControllerTypeBK, this.sessionIdValidator],
        //});



    }


    // convenience getter for easy access to form fields
    get f()
    {
        return this.form.controls;
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    onSubmit() {
        this.submitted = true;
        //alert(this.data._ControllerTypeMappingID);

        if (this.form.value.ControllerType != "")
        {
            let ContTypeLocal = encodeURIComponent(this.data.ControllerTypeLocal);

            let url = this.baseUrl + Utils.getCDPControllerTypeMappingAPI() + "update/" + this.data._ControllerTypeMappingID + "/" + this.form.value.ControllerType + "/" + ContTypeLocal;

            this.http
                .post<boolean>(url, {})
                .subscribe(res => {
                    this.dialogRef.close(true);
                }, error => {
                    Utils.writeLog(this.http, this.baseUrl, error); //Utils.showErrorDialog(null, error);
                }); //console.error(error));
        }
        else
            this.dialogRef.close(false);

        
    }

    onBack() {

    }



}


