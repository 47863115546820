import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, OnInit, Output, Self, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { Utils } from '../../../utils/utils';
import { DefaultValuesService } from '../../../services/defaultvalues.service';
import { DestroyService } from '../../../services/destroyservice';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'manufacturingcenterproduct',
  templateUrl: './manufacturingcenterproduct.component.html',
    styleUrls: ['../../../style/new-generic-styles.component.less',
        '../../../style/tables/table-add-butt-header-orange.less',
        '../emtcontrollersoftware.component.less',
        './manufacturingcenterproduct.component.less'],
        providers: [DestroyService]
})
export class ManufacturingCenterProductComponent implements OnInit {
    @Input() availableProductUsages: EMTProductUsageItem[] = [];
    @Input() availableCenters: MfgCenter[] = [];
    @Output() centerProductEvent = new EventEmitter<MFGCenterProductItem[]>();
    public dataSource = new MatTableDataSource<any>();
    CPForm!: FormGroup;
    genType: number = 0;
    @ViewChild(MatTable) objTable!: MatTable<any>;
    originalCenterProduct: MFGCenterProductItem[] = [];
    modifiedCenterProduct: MFGCenterProductItem[] = [];
    newCenterProduct: MFGCenterProductItem[] = [];
    isCallSucceed: number = 0;
    subscriptionStage: any;
    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string,
        private _formBuilder: FormBuilder,
        private toastrService: ToastrService,
        public defaults: DefaultValuesService,
        @Self() private readonly destroy$: DestroyService)
    {
    }

    columnsToDisplay = ["productcode", "center", "productusage", "productname", "action"];

    ngOnInit(): void {
        this.subscriptionStage = this.defaults.stageMessageChanges$.pipe(takeUntil(this.destroy$)).subscribe(() => { this.getManufacturingCentersProducts() });
        this.CPForm = this._formBuilder.group({
            CPRows: this._formBuilder.array([])
        });
        this.getManufacturingCentersProducts();
    }

    getCenterName(centerId: number) {
        return this.availableCenters.find(x => x.centerId === centerId)?.centerName ?? "---";
    }

    getProductUsageName(productUsageId: number) {
        return this.availableProductUsages.find(x => x.productUsageID === productUsageId)?.name ?? "---";
    }

    getManufacturingCentersProducts() {
        Utils.httpGetMfgCenterProducts(
            this.http,
            this.baseUrl,
            this,
            function (tthis: ManufacturingCenterProductComponent, result: MFGCenterProduct) {
                tthis.originalCenterProduct = result.centerProductList;
                tthis.mapDataToForm(tthis.originalCenterProduct);
                tthis.centerProductEvent.emit(tthis.originalCenterProduct);
            }
        );
    }

    mapDataToForm(data) {
        this.CPForm = this._formBuilder.group({
            CPRows: this._formBuilder.array([])
        });
        this.CPForm = this._formBuilder.group({
            CPRows: this._formBuilder.array(data.map(val => this._formBuilder.group({
                manufacturingCenterProductID: new FormControl(val.manufacturingCenterProductID),
                productCode: new FormControl(val.productCode),
                mfgCenterId: new FormControl(val.mfgCenterId ?? -1),
                productUsageId: new FormControl(val.productUsageId ?? -1),
                productName: new FormControl(val.productName),
                isDeleted: new FormControl(val.isDeleted),       
                isEditable: new FormControl(true),
                isNewRow: new FormControl(false),
                action: new FormControl('existingRecord'),
            })
            )) //end of fb array
        }); // end of form group cretation
        this.dataSource.data = (this.CPForm.get('CPRows') as FormArray).value;
    }

    initCPForm(): FormGroup {
        return this._formBuilder.group({
            productCode: new FormControl(`PC_${this.genType}`),
            mfgCenterId: new FormControl(-1),
            productUsageId: new FormControl(-1),
            productName: new FormControl(`Product_${this.genType++}`),
            isDeleted: new FormControl(false), 
            action: new FormControl('newRecord'),
            isEditable: new FormControl(false),
            isNewRow: new FormControl(true),
        });
    }

    addNewRow() {
        const control = this.CPForm.get('CPRows') as FormArray;
        control.insert(0, this.initCPForm());
        this.dataSource = new MatTableDataSource(control.controls)
    }

    editRow(index) {
        this.CPForm.value.CPRows[index].isEditable = false;
    }

    resetRow(i) {
        if (this.CPForm.value.CPRows[i]?.isNewRow)
            this.deleteRow(i);
        else {
            this.updateFormFeature(i);
            this.CPForm.value.CPRows[i].isEditable = true;
        }
    }

    deleteRow(i) {
        const control = this.CPForm.get('CPRows') as FormArray;
        control.removeAt(i);
        this.dataSource.data = control.value;
        this.refreshTable();
    }

    updateFormFeature(index) {
        var obj = this.CPForm.value.CPRows;
        var updated = this.originalCenterProduct.find(s => s.manufacturingCenterProductID == this.CPForm.value.CPRows[index]?.manufacturingCenterProductID);
        obj[index].mfgCenterId = updated?.mfgCenterId ?? -1;
        obj[index].productUsageId = updated?.productUsageId ?? -1;
        obj[index].productName = updated?.productName;
        obj[index].productCode = updated?.productCode;
    }

    anyChange() {
        this.mapFormToData();
        return this.modifiedCenterProduct?.length > 0 || this.newCenterProduct?.length > 0;
    }

    mapFormToData() {
        this.modifiedCenterProduct = this.CPForm.get('CPRows')?.value.filter(row => !row.isNewRow
            && !row.isEditable
            && row.productCode !== "" && row.productCode.length <= 30
            && row.productName !== "" && row.productName.length <= 50
            && row.mfgCenterId !== -1
            && row.productUsageId !== -1
        );
        this.newCenterProduct = this.CPForm.get('CPRows')?.value.filter(row => row.isNewRow
            && row.productCode !== "" && row.productCode.length <= 30
            && row.productName !== "" && row.productName.length <= 50
            && row.mfgCenterId !== -1
            && row.productUsageId !== -1
        );
    }

    onSave() {
        if (this.anyChange()) {
            var centerProductChanges = { 'modifiedCenterProduct': this.modifiedCenterProduct, 'newCenterProduct': this.newCenterProduct }
            Utils.httpUpdateCenterProducts(
                this.http
                , this.baseUrl
                , centerProductChanges
                , this
                , function (tthis: ManufacturingCenterProductComponent, result: any) {
                    tthis.getManufacturingCentersProducts();
                    tthis.funcNotify();
                }
            )
        }

        
    }

    funcNotify() {
        if (this.isCallSucceed === 1) {
            this.toastrService.success('Save request successfully sent!');
        }
        else if (this.isCallSucceed === -1) {
            this.toastrService.error('Save action could not be performed. Please, contact with administrator');
        }
    }

    refreshTable() {
        this.objTable.renderRows();
    }
}
