
<div class="tkeuser-form_Custom">
    <form [formGroup]="tkeUserForm" class="internalMax" (ngSubmit)="onSubmit()">
        <div class="row" *ngIf="defaults.componentTool === componentToolEnum.Rulebook">
            <div class="col-sm-12 form-group">
                <div style="border:1px solid; padding:10px">
                    <p>
                        User will get a basic Power BI license allowing to consume Power BI reports
                        <br>
                        published on a Power BI Premium Workspace.
                    </p>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-sm-6 form-group">

                <mat-form-field appearance="outline">
                    <mat-label> First Name </mat-label>
                    <input matInput formControlName="firstName" required>
                </mat-form-field>
                <p *ngIf="
          tkeUserForm.get('firstName')?.invalid &&
            (tkeUserForm.get('firstName')?.dirty ||
              tkeUserForm.get('firstName')?.touched)">
                    <mat-error *ngIf="tkeUserForm.get('firstName')?.hasError('required')">This field is required</mat-error>
                </p>
                <p *ngIf="!(
          tkeUserForm.get('firstName')?.invalid &&
            (tkeUserForm.get('firstName')?.dirty ||
              tkeUserForm.get('firstName')?.touched))"><br> </p>

            </div>
            <div class="col-sm-6 form-group">

                <mat-form-field appearance="outline">
                    <mat-label> Last Name </mat-label>
                    <input matInput formControlName="lastName" required>
                </mat-form-field>
                <p *ngIf="
          tkeUserForm.get('lastName')?.invalid &&
            (tkeUserForm.get('lastName')?.dirty ||
              tkeUserForm.get('lastName')?.touched)">
                    <mat-error *ngIf="tkeUserForm.get('lastName')?.hasError('required')">This field is required</mat-error>
                </p>
                <p *ngIf="!(
          tkeUserForm.get('lastName')?.invalid &&
            (tkeUserForm.get('lastName')?.dirty ||
              tkeUserForm.get('lastName')?.touched))"><br> </p>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 form-group">

                <!--Displayname Region-->
                <mat-form-field appearance="outline">
                    <mat-label> Display Name </mat-label>
                    <input matInput formControlName="displayName" required>
                </mat-form-field>
                <p *ngIf="
          tkeUserForm.get('displayName')?.invalid &&
            (tkeUserForm.get('displayName')?.dirty ||
              tkeUserForm.get('displayName')?.touched)">
                    <mat-error *ngIf="tkeUserForm.get('displayName')?.hasError('required')">This field is required</mat-error>
                </p>
                <p *ngIf="!(
          tkeUserForm.get('displayName')?.invalid &&
            (tkeUserForm.get('displayName')?.dirty ||
              tkeUserForm.get('displayName')?.touched))"><br> </p>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-4 form-group">
                <br />
                <mat-label> <span>Tenant</span><span class="orange-asterisk"> *</span></mat-label>
            </div>
            <div class="col-sm-8 form-group">

                <!--Primary Tenant Drop Down-->
                <ng-select [items]="tenants"
                           [searchable]="true"
                           [clearable]="false"
                           class="tenantStyling"
                           bindLabel="text"
                           bindValue="value"
                           formControlName="tenant"
                           required>
                </ng-select>
                <br>

            </div>
        </div>

        <!--<div class="row">
            <div class="col-sm-4 form-group">
                <br />
                <mat-label>Secondary Tenant</mat-label>
            </div>
            <div class="col-sm-8 form-group">-->

                <!--Secondary Tenant Drop Down-->
                <!--<ng-select (change)="onSecTenChange()"
                           [matTooltip]="selectedSecTenString"
                           [matTooltipPosition]="'above'"
                           [items]="secondaryTenants"
                           groupBy="selectedAllGroup"
                           [selectableGroup]="true"
                           [selectableGroupAsModel]="false"
                           [searchable]="true"
                           [clearable]="true"
                           [closeOnSelect]="false"
                           [multiple]="true"
                           bindLabel="text"
                           bindValue="value"
                           formControlName="secondaryTenant">

                    <ng-template ng-optgroup-tmp>
                        Select All
                    </ng-template>

                    <ng-template ng-option-tmp
                                 let-item="item">
                        {{ item.text }}
                    </ng-template>

                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <div class="items-selected" *ngIf="this.tkeUserForm.controls['secondaryTenant'].value.length <= 2">
                            <div class="ng-value" *ngFor="let item of this.tkeUserForm.controls['secondaryTenant'].value ">
                                <span class="ng-value-label">{{item}}</span>

                            </div>
                        </div>

                        <div class="ng-value" *ngIf="items.length > 2">
                            <span class="ng-value-label">{{items.length}} Selected...</span>
                        </div>

                    </ng-template>

                </ng-select>
                <br>

            </div>
        </div>-->

        <div class="row">
            <div class="col-sm-4 form-group">
                <br />
                <span>Email</span><span class="orange-asterisk"> *</span>
            </div>
            <div class="col-sm-8 form-group">

                <!--Email Region-->
                <div>
                    <mat-form-field appearance="outline">
                        <mat-label>Email</mat-label>
                        <input matInput placeholder="pat@example.com" formControlName="email" required (keyup)="changeEmail($event)">
                        <mat-spinner #spinner matSuffix [diameter]="18" style="float: right; margin-left: 8px; visibility:hidden"></mat-spinner>
                    </mat-form-field>

                    <p *ngIf="
                          tkeUserForm.get('email')?.invalid &&
                            (tkeUserForm.get('email')?.dirty ||
                              tkeUserForm.get('email')?.touched)">
                        <mat-error *ngIf="tkeUserForm.get('email')?.hasError('required')">This field is required</mat-error>
                        <mat-error *ngIf="tkeUserForm.get('email')?.hasError('pattern')">
                            Enter valid email
                        </mat-error>
                    </p>
                    <p igIf="emailError">
                        <mat-error>{{emailError}}</mat-error>
                    </p>
                    <p *ngIf="!(
          tkeUserForm.get('email')?.invalid &&
            (tkeUserForm.get('email')?.dirty ||
              tkeUserForm.get('email')?.touched)) && !emailError"><br> </p>
                </div>

            </div>
        </div>
        <div class="row">
            <div class="col-sm-4 form-group">
                <br />
                <span>Company Name</span><span class="orange-asterisk"> *</span>
            </div>
            <div class="col-sm-8 form-group">

                <!--Company Name Region-->
                <div>
                    <mat-form-field appearance="outline">
                        <mat-label>Company Name</mat-label>
                        <input matInput formControlName="companyName" required>
                    </mat-form-field>

                    <p *ngIf="
                          tkeUserForm.get('companyName')?.invalid &&
                            (tkeUserForm.get('companyName')?.dirty ||
                              tkeUserForm.get('companyName')?.touched)">
                        <mat-error *ngIf="tkeUserForm.get('companyName')?.hasError('required')">This field is required</mat-error>
                    </p>
                    <p *ngIf="!(
          tkeUserForm.get('companyName')?.invalid &&
            (tkeUserForm.get('companyName')?.dirty ||
              tkeUserForm.get('companyName')?.touched))"><br> </p>
                </div>

            </div>
        </div>




        <div class="row">
            <div class="panel-body">
                <button (click)="backButtonHandler($event)" mat-raised-button class="btn  button-color-fix_red ">
                    <mat-icon class=" icon-color-fix ">arrow_back_ios</mat-icon>
                    Back
                </button>
                <button mat-raised-button type="submit" class="btn button-color-fix pull-right" [disabled]="!tkeUserForm.valid || emailError || waiting4Mail">
                    Create
                    <mat-icon class=" icon-color-fix ">person</mat-icon>
                </button>

            </div>
        </div>

    </form>
</div>
