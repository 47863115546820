import { Component, Inject, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Utils } from "../../../utils/utils";
import { DefaultValuesService } from "../../../services/defaultvalues.service";

@Component({
    selector: "controllertypeharmonization",
    templateUrl: './controllertypeharmonization.component.html',
    styleUrls: ['../../../style/container-tab-list.component.less']
})




export class ControllerTypeHarmonizationComponent {

    title: string;

    constructor(
        private defaults: DefaultValuesService
    )
    {
        this.title = "Controller harmonization";
    }

}