


import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import {
    MsalInterceptorConfiguration, 
    MsalGuardConfiguration, ProtectedResourceScopes
} from '@azure/msal-angular';

import { msalConfig, loginRequest, protectedResources } from './auth-config';
import { MatSidenavContainer } from '@angular/material/sidenav';
import { environment } from '../environments/environment'


/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication(msalConfig);
}

/**
 * MSAL Angular will automatically retrieve tokens for resources
 * added to protectedResourceMap. For more info, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
 */
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>();

    protectedResourceMap.set(protectedResources.api.endpoint, [
        {
            httpMethod: 'GET',
            scopes: [...protectedResources.api.scopes.read]
        },
        {
            httpMethod: 'PUT',
            scopes: [...protectedResources.api.scopes.read]
        },
        {
            httpMethod: 'POST',
            scopes: [...protectedResources.api.scopes.read]
        },
        {
            httpMethod: 'DELETE',
            scopes: [...protectedResources.api.scopes.read]
        },
    ]);  

    //protectedResourceMap.set(protectedResources.api.endpoint, [
    //    {
    //        httpMethod: 'POST',
    //        scopes: [...protectedResources.api.scopes.read]
    //    },
    //]);  

    return {
        interactionType: InteractionType.Popup,
        protectedResourceMap,
    };
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: loginRequest
    };
}

export function getBaseUrl() {
    return environment.endpoint;
}