import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Utils } from '../../utils/utils';
import { SaveService } from '../../services/save.service';
import { DialogService } from '../../services/dialog.service';
import { CustomDialogService } from '../../services/customdialog.service';
import { DefaultValuesService } from '../../services/defaultvalues.service';

@Component({
    selector: 'FPErrorCodeMenu',
    templateUrl: './fp_errorcode-menu.component.html',
    styleUrls: ['../../style/menu.component.less']
})

export class FPErrorCodeMenuComponent implements OnInit {
    //title: string;
    @Output() controllerChanged = new EventEmitter<ControllerType>();
    @Output() languageChanged = new EventEmitter<Language>();

    @Output() deleteEvent = new EventEmitter<{}>();
    @Output() newEvent = new EventEmitter<{}>();
    @Output() newResolutionEvent = new EventEmitter<{}>();

    @Output() onReload = new EventEmitter<{}>();

    //languageList: Language[] | undefined = [];
    deployUrl: string = this.baseUrl + Utils.getErrorCodeAPI() + "deploy";
    DOC_URL = "https://tkebbxdev.visualstudio.com/E2E%20MAX/_wiki/wikis/E2E-MAX.wiki/2058/Error-Codes-Modeling";

    //controllerType: ControllerType = <ControllerType>{};

    constructor(private activatedRoute: ActivatedRoute,
        private router: Router
        , private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , public saveService: SaveService
        , private dialog: DialogService
        , private customDialog: CustomDialogService
        , public defaults: DefaultValuesService

    ) {
    }

    ngOnInit() {
    }

    onUpload() {
        this.router.navigate(["file/" + Utils.getFileUploadErrorCodeValue()]);
    }

    onNewLanguage() {
        if (this.saveService.isSaveButtonShown())
            this.dialog.showErrorStringDialog("You must save the data before continue!");
        else
            this.router.navigate(["language/new"]);
    }

    onCopyRulesToProd() {
        this.customDialog.openConfirm({
            title: "Copy all rules to prod",
            message: `All Stable alert rules will be copied to Prod. Are you sure?`,
            caller: this
        });
        this.customDialog.confirmed().subscribe(res => {
            let url = this.baseUrl + Utils.getErrorCodeAPI() + "alertrulestoprod";
            this.http
                .put<boolean>(url, {})
                .subscribe(res => {
                    this.onReload.emit({});
                }, error => {
                    this.dialog.showErrorDialog(error);
                });
        })
    }
    openDocumentationModal() {
        window.open(this.DOC_URL, "_blank");
    }
}
