import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { DefaultValuesService } from "../../../services/defaultvalues.service";
import { Utils } from "../../../utils/utils";
@Component({
    selector: "functionsconfig",
    templateUrl: 'functionsconfig.component.html',
})

export class FunctionsConfigComponent implements OnInit, OnDestroy {

    list: FunctionConfig[] = [];
    mergedList: any[] = [];

    subscriptionControllerType: any;

    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string,
        public defaults: DefaultValuesService
    ) { }

    ngOnInit(): void {
        this.subscriptionControllerType = this.defaults.controllerTypeMessageChanges$.subscribe(() => { this.getFunctionsConfig() });
        if (this.defaults.tenant) {
            this.getFunctionsConfig()
        }
    }
    ngOnChanges() {
        this.getFunctionsConfig()
    }

    ngOnDestroy() {
        this.subscriptionControllerType.unsubscribe();
    }

    reload($event: CommandConfigTemplate) {
        this.getFunctionsConfig()
    }

    getFunctionsConfig() {
        Utils.httpGetFunctionConfig(
            this.http
            , this.baseUrl
            , this
            , function (tthis: FunctionsConfigComponent, list: FunctionConfig[]) {
                tthis.list = list
            }
        );
    }
}
