import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { Utils } from '../../../../../utils/utils';

@Component({
    selector: 'create-measure-popup',
    templateUrl: './create-measure-popup.component.html',
    styleUrls: ['./create-measure-popup.component.less']
})
export class CreateMeasurePopupComponent implements OnInit {

    form!: FormGroup
    availableKpis: any[] = []
    subscriptions: any[] = []
    buttonLabel: string = 'Create';
    buttonIcon: string = 'add';

    constructor(
        public dialogRef: MatDialogRef<CreateMeasurePopupComponent>,
        private formBuilder: FormBuilder,
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private toastrService: ToastrService) { }

    ngOnInit(): void {
        this.data.KpiDescription = this.data.KpiDescription ?? this.data.KpiName;
        this.form = this.formBuilder.group({
            measureId: [this.data?.MeasureId],
            name: [this.data?.Name, Validators.required],
            description: [this.data?.Description, Validators.required],
            kpiDefinitionId: [this.data?.KpiDefinitionId, Validators.required],
            owner: [this.data?.Owner, Validators.required],
        });
        Utils.httpGetUnassignedKpisByFieldBranchAndWave(
            this.http
            , this.baseUrl
            , this.data.waveId
            , this
            , function (tthis: CreateMeasurePopupComponent, data: any[]) {
                tthis.availableKpis = data;
                if (tthis.data.KpiDefinitionId)
                    tthis.availableKpis.unshift({ Id: tthis.data.KpiDefinitionId, Name: tthis.data.KpiDescription })
            },
            null
        );
        this.buttonLabel = this.data?.KpiDefinitionId ? 'Update' : 'Create';
        this.buttonIcon = this.data?.KpiDefinitionId ? 'save' : 'add';
    }

    onSubmit() {
        // stop here if form is invalid
        if (this.form)
            if (this.form.invalid) {
                return;
            }

        Utils.httpUpsertMeasure(
            this.http
            , this.baseUrl
            , this.data.waveId
            , this.form.value
            , this
            , function (tthis: CreateMeasurePopupComponent, result: any) {
                tthis.dialogRef.close({ event: 'submit' });
                if (result) {
                    tthis.toastrService.success(`Measure was ${tthis.data.KpiDefinitionId ? 'updated' : 'created'} successfully!`);
                }
                else
                    tthis.toastrService.error(`Measure could not be ${tthis.data.KpiDefinitionId ? 'updated' : 'created'}. Please, contact with administrator`);
            },
            null
        );
    }

    backButtonHandler(event: any) {
        event.preventDefault();
        this.dialogRef.close();
    }
}
