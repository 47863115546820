<div class="example-container new-table-style">
    <table mat-table
           [dataSource]="dataSource"
           class="mat-elevation-z8 mat-table-custom">

        <ng-container matColumnDef="desc">
            <th mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.HarmonizedDescription"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="customerDesc">
            <th mat-header-cell *matHeaderCellDef> Customer Description </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.CustomerDescription" *ngIf="!isEditingRow(element)"></p>
                <mat-form-field floatLabel="never" *ngIf="isEditingRow(element)">
                    <input matInput [value]="element.CustomerDescription" [(ngModel)]="element.CustomerDescription">
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button *ngIf="!isEditingRow(element)" matTooltip="Edit Row"
                        (click)="onRowEditInit(element)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button *ngIf="isEditingRow(element)" matTooltip="Cancel Row"
                        (click)="onRowEditCancel(element)">
                    <mat-icon>rotate_left</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; let i = dataIndex;
            columns: columnsToDisplay;"
            class="item"
            [class.alternative2]="isEditingRow(element)">
        </tr>
    </table>
</div>