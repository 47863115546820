import { Component, Inject, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { DialogService } from '../../services/dialog.service';
import { Utils } from '../../utils/utils';
import { SaveService } from '../../services/save.service';
import { DefaultValuesService } from '../../services/defaultvalues.service';
import { MatDialog } from "@angular/material/dialog";

@Component({
    selector: 'maintenancewarning-form'
    , templateUrl: './maintenancewarning-form.component.html'
    , styleUrls: ['../../style/form.component.less', './maintenancewarning.less']
})
export class MaintenanceWarningFormComponent implements OnInit {
    @Input() list: MaintenanceWarning[] = [];
    @Input() header: any[] = [];
    title: string;

    selected: MaintenanceWarning = {} as MaintenanceWarning;

    classname: string[] = [];
    isundodisabled: boolean[] = [];

    newRow = true;
    isToShowRef = false;

    form: FormGroup = new FormGroup({});

    TYPE = 0;
    DESCRIPTION = 1;

    constructor(private http: HttpClient
        , private dialog: DialogService
        , private matDialog: MatDialog 
        , private defaults: DefaultValuesService
        , private saveService: SaveService
        , @Inject('BASE_URL') private baseUrl: string
    ) {

        this.title = "Manage Maintenance Warning Description";
    }

    ngOnInit() {

        if (this.header.length === 0) { //this is a new form
            this.header = this.dialog.getHeader();
        }
        this.newRow = true;
        this.isToShowRef = this.defaults.isToShowReference();
        this.saveService.addListener(this);
        this.saveService.initUndo(this);
        this.createForm();

    }

    onCancelForm() {
        const value = -1 ;
        this.saveService.fireCancel(value, this);
    }

    /**
     * called by the SaveService
     * */
    onSave() {
        if (this.newRow)
            this.createObject();

        const tthis = this;
        this.http
            .post<MaintenanceWarning>(
                this.baseUrl + Utils.getMaintenanceWarningAPI() + "mergemaintenancewarning"
                , this.selected
            )
            .subscribe(
                res => {
                    tthis.list.unshift(res);
                    tthis.saveService.removeListener(tthis)
                    tthis.onCancel();
                }
                , error => {
                    tthis.dialog.showErrorDialog(error);
                }
            );
    }

    /**
     * called by the save button on this form
     * 
     * */
    onSubmit() {
        const value = -1;
        this.saveService.fireSave(value, this);
    }

    private createForm() {
        this.form = new FormGroup({
            Type: new FormControl('', [Validators.required]),
            Description: new FormControl('', [Validators.required]),
        });
    }

    private createObject() {
        this.selected.MaintenanceWarningID = -1;
        this.selected.Type = this.form.controls['Type'].value;
        this.selected.Description = this.form.controls['Description'].value;
    }

    onCancel() {
        this.matDialog.closeAll();
    }
}


