import { Component, Inject, OnInit, OnDestroy } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Utils } from "../../../../utils/utils";
import { DefaultValuesService } from "../../../../services/defaultvalues.service";

@Component({
    selector: "controllerconfig",
    templateUrl: './controllerconfig.component.html',
    styleUrls: ['../../../../style/container-tab-list.component.less']
})

export class ControllerConfigComponent implements OnInit, OnDestroy {
    translationarea = {} as TranslationArea;
    controllerconfigs: ControllerConfig[] = [];
    controllerconfigdatatypes: ControllerConfigDataType[] = [];
    controllerconfigdescriptions: ControllerConfigDescription[] = [];

    subscriptionLanguage: any;

    constructor(
        private http: HttpClient
        , private defaults: DefaultValuesService
        , @Inject('BASE_URL') private baseUrl: string
    ) {
    }

    ngOnInit() {
        if (this.controllerconfigs.length === 0)
            this.refreshAll();
        this.subscriptionLanguage = this.defaults.languageMessageChanges$.subscribe(() => {
            this.refreshAll()
        });
    }

    ngOnDestroy() {
        this.subscriptionLanguage.unsubscribe();
    }

    refreshAll() {
        Utils.httpGetAllControllerConfig(
            this.http
            , this.baseUrl
            , this
            , function (tthis: ControllerConfigComponent, list: ControllerConfig[]) {
                tthis.controllerconfigs = list;
                Utils.httpGetAllControllerConfigDescription(
                    tthis.http
                    , tthis.baseUrl
                    , tthis
                    , function (ttthis: ControllerConfigComponent, values: ControllerConfigDescription[]) {
                        ttthis.controllerconfigdescriptions = values;
                    }
                );

                Utils.httpGetAllControllerConfigDataType(
                    tthis.http
                    , tthis.baseUrl
                    , tthis
                    , function (ttthis: ControllerConfigComponent, values: ControllerConfigDataType[]) {
                        ttthis.controllerconfigdatatypes = values;
                    }
                );
            }
        );
    }
}