<div class='left-menu'>
    <ul class='nav navbar-nav'>
        <li [routerLinkActive]="['link-active']" *ngIf="!defaults.isReadonlyUser">
            <a class='menu-callback' (click)="onNewMaintenanceWarning()" title="Create a new Maintenance Warning">
                <span class='glyphicon glyphicon-plus-sign'></span> New Maintenance Warning
            </a>
        </li>

        <li [routerLinkActive]="['link-active']">
            <a class='menu-callback' (click)="onRefresh.emit()" title="Refresh">
                <span class='glyphicon glyphicon-refresh'></span> Refresh
            </a>
        </li>
        <li class="submenu-title">
            <h4>Excel</h4>
        </li>
        <li [routerLinkActive]="['link-active']">
            <a class='menu-callback' target="_blank" (click)="onDownload()" title="Dowload">
                <span class='glyphicon glyphicon-download'></span> Download
            </a>
        </li>
        <li class="separator" [ngClass]="saveService.classSaveButton" *ngIf="!defaults.isReadonlyUser"></li>
        <li [routerLinkActive]="['link-active']" [ngClass]="saveService.classSaveButton" *ngIf="!defaults.isReadonlyUser">
            <a class='menu-callback' (click)="saveService.fireSave()" title="Save">
                <span class='glyphicon glyphicon-floppy-disk'></span> Save
            </a>
        </li>
    </ul>
</div>