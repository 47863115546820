import { Component, Inject, Input, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { MatChipInputEvent } from "@angular/material/chips";
import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';
import { SaveService } from '../../../../services/save.service';
import { Utils } from '../../../../utils/utils';
import { DefaultValuesService } from '../../../../services/defaultvalues.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DialogService } from "../../../../services/dialog.service";

@Component({
    selector: "elevator-list",
    templateUrl: './elevator-list.component.html',
    styleUrls: ['./elevator-list.component.less', '../../../../style/mat-table.component.less'],

    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' })),
            transition('expanded => collapsed', [animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
        ]),
    ]
})

export class ElevatorListComponent implements OnInit {
    @Input('list') list: Elevator[] = [];
    @Input('showLocalFilter') showLocalFilter = false;

    columnsToDisplay = ['arrow', 'index', 'UnitID', 'BranchNumber', 'ControllerType', 'BuildingAddr1', 'BuildingCity', 'ElevatorNickname', 'ProvisioningStatus', '_ChangedAtUtc', 'actions'];

    subcolumnsToDisplay = ['DeviceId', 'ElevatorType', 'RegionNumber', 'BuildingType', 'BuildingAddr2',
        'BuildingName', 'BuildingState', 'ZipCode', 'CountryCode', 'Longitude', 'Latitude',
        'NumberOfStops', 'Capacity', 'Speed', 'DoorType', 'NumberOfCarsInGroup', 'TechnicianID', 'TechnicianName',
        'ElevatorGroupname', 'MotorType', 'DriveType', 'ValveType', 'ReevingRatio', 'WrappingType',
        'MachineRoomLocation', 'NumberOfJacks', 'MachineBrakeType', 'EmergencyBrakeType',
        'NumberOfPulleys', 'TravelHeight', 'CommandandControlAuthorized', 'ControllerDescription',
        'ControllerNumber', 'ControllerSoftwareVersion', 'NumberRelevels', 'NumberTrips',
        'NumberTripsSinceService', 'OverloadThreshold', 'GovernmentAssetId', 'HasResidentMechanic', 'IsMAXCompatible',
        'Timezone', 'ConfigItProjectID', 'ProjectName', 'ProjectType', 'ProjectBranchCode', 'ProjectVersion', 'PM8ID',
        'PMName', 'PMEmail', 'UEID', 'ProductModel', 'PONumber', 'POItemNo', 'ExpectedDeliveryDate', 'TP2',
        'TP2Currency', 'UnitGovId', 'IsNIProvisioning', 'Manufacturingid', 'ModelId', 'OrderNumber', 'SerialNumber', 'ManufactoringDate',
        'MAXSoftwareVersion', 'ANI', 'ADA', 'MultimediaVendorId', 'MultimediaDeviceId', 'SIPUrl',
        'Office', 'SupervisorName', 'Supervisor8id', 'MaintenanceFrequencyPerYear', 'MaintenanceRoute', 'CommercialSector',
        'BankLocationReference', 'ContractExternalReference', 'IsSeasonalUnit', 'SeasonalUnitFromMonth', 'SeasonalUnitToMonth',
        'PreferredMaintenanceServiceWeekOfMonth', 'SourceSystemId', 'InstalledControllerType', 'ErpControllerType', 'IsNIProvisioningERP',
        'AcquiredCompany', 'AcquisitionDate', 'RegulatoryCode', 'Client', 'CreatorSource', 'ChangedSource', 'OptOut', 'AcceptanceDate', 'CellularNetwork',
        '_CreatedAtUtc', '_ChangedByUser', '_ChangedByMsgType', 'Location', 'AssetType', 'DoorOperatorStyle', 'CarGuideType', 'PistonType', 'Multiple_ElevatorsInHoistway',
        'ProvisioningStatusChangeDate', 'StartOfOperation', 'MgmtId', 'BU'];

    booleanColumns = ['CommandandControlAuthorized', 'HasResidentMechanic', 'IsMAXCompatible', 'IsNIProvisioning', 'IsNIProvisioningERP', 'IsSeasonalUnit', 'Multiple_ElevatorsInHoistway']
    dateColumns = ['_ChangedAtUtc', 'ProvisioningStatusChangeDate', 'StartOfOperation', '_CreatedAtUtc', 'ManufactoringDate', 'AcquisitionDate', 'AcceptanceDate']
    numberColumns = ['NumberOfStops', 'Capacity', 'Speed', 'NumberOfCarsInGroup', 'NumberOfJacks', 'NumberOfPulleys', 'TravelHeight', 'NumberRelevels',
        'NumberTrips', 'NumberTripsSinceService', 'OverloadThreshold', 'MaintenanceFrequencyPerYear', 'SeasonalUnitFromMonth',
        'SeasonalUnitToMonth', 'PreferredMaintenanceServiceWeekOfMonth', '_ChangedByMsgType']

    notEditableColumns = ['DeviceId', 'UnitID', 'ControllerDescription', 'ControllerSoftwareVersion', 'NumberRelevels', 'NumberTrips', 'NumberTripsSinceService',
        'IsMAXCompatible', '_ChangedAtUtc', 'ProvisioningStatus', 'ModelId', 'OrderNumber', 'SerialNumber', 'ManufactoringDate', 'MAXSoftwareVersion',
        'OverloadThreshold', 'ProvisioningStatusChangeDate', '_CreatedAtUtc', '_ChangedByMsgType', '_ChangedByUser', 'AssetType']

    @ViewChild(MatTable, { static: true }) table!: MatTable<any>;

    public dataSource = new MatTableDataSource<Elevator>();
    selected: Elevator | undefined;
    expanded: boolean[] = [];
    expandedElements = new Set();
    clonedList: { [s: string]: Elevator; } = {};

    filter = '';

    subscriptionTenant: any;

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [COMMA, TAB, ENTER];
    searchItems: SearchItem[] = [];

    isPrimaryElevator = '-1'

    constructor(private activatedRoute: ActivatedRoute
        , private router: Router
        , private http: HttpClient
        , private saveService: SaveService
        , private defaults: DefaultValuesService
        , @Inject('BASE_URL') private baseUrl: string
        , private confimDialog: DialogService

    ) {
    }

    ngOnChanges() {
        this.dataSource.data = this.list
        this.clonedList = {}
        this.saveService.showSaveButton(false)
        this.expandedElements.clear();
    }

    ngOnInit() {
        this.getHeader();
        this.subscriptionTenant = this.defaults.tenantMessageChanges$.subscribe(() => {
            this.getHeader()
        });
        this.dataSource.filterPredicate = (data: Elevator, filters: string) => {
            const matchFilter: boolean[] = [];
            const filterArray = filters.split('+').filter(x => x.indexOf('notextfilters') < 0)
            if (filterArray.length > 0) {
                filterArray.forEach((filter) => {
                    const fields: string[] = []
                    fields.push(data.UnitID ? data.UnitID : '')
                    fields.push(data.BranchNumber ? data.BranchNumber : '')
                    fields.push(data.ControllerType ? data.ControllerType : '')
                    fields.push(data.BuildingAddr1 ? data.BuildingAddr1 : '')
                    fields.push(data.BuildingCity ? data.BuildingCity : '')
                    fields.push(data.ElevatorNickname ? data.ElevatorNickname : '')
                    return matchFilter.push(fields.some(item => item.includes(filter)));
                });
            }
            else {
                const result = true;
                matchFilter.push(result);
            }
            return matchFilter.some(Boolean);
        };
    }

    getHeader() {
        if (!this.defaults.isReadonlyUser)
            this.columnsToDisplay = ['arrow', 'index', 'UnitID', 'BranchNumber', 'ControllerType', 'BuildingAddr1', 'BuildingCity', 'ElevatorNickname', 'ProvisioningStatus', '_ChangedAtUtc', 'actions'];
        else
            this.columnsToDisplay = ['arrow', 'index', 'UnitID', 'BranchNumber', 'ControllerType', 'BuildingAddr1', 'BuildingCity', 'ElevatorNickname', 'ProvisioningStatus', '_ChangedAtUtc'];

        this.subcolumnsToDisplay.sort((x, y) => (x > y ? 1 : -1));
    }

    getInput(data: Elevator, property: string): any {
        return data[property];
    }

    getDataType(property: string) {
        let result = ''
        if (this.booleanColumns.indexOf(property) > -1) result = 'boolean'
        else if (this.dateColumns.indexOf(property) > -1) result = 'date'
        else if (this.numberColumns.indexOf(property) > -1) result = 'number'
        else result = 'string'
        return result
    }

    changeElement(object: any, type: string, value: boolean) {
        object[type] = value;
        this.table.renderRows();
    }

    pushPopElement(element: Elevator) {
        if (this.expandedElements.has(element)) {
            this.expandedElements.delete(element);
        }
        else {
            this.expandedElements.add(element);
            this.onExpand(element)
        }
    }

    checkExpanded(element: Elevator) {
        return this.expandedElements.has(element);
    }

    onExpand(element: Elevator) {
        Utils.httpGetDetailedElevator(
            this.http
            , this.baseUrl
            , this.defaults.stage
            , element
            , this
            , function (tthis: ElevatorListComponent, list: ElevatorDetails[]) {
                element.Details = list;
                tthis.expandedElements.add(element);
            }
        );
    }

    onRowEditInit(elevator: Elevator) {
        this.saveService.addListener(this);
        this.clonedList[this.getDictionaryHash(elevator)] = { ...elevator };
        if (Object.keys(this.clonedList).length !== 0) this.onShowSavebutton()
        this.onExpand(elevator)
    }

    onRowEditCancel(elevator: Elevator) {
        this.dataSource.data[this.dataSource.data.findIndex(x => x.UnitID === elevator.UnitID)] = this.clonedList[this.getDictionaryHash(elevator)];
        delete this.clonedList[this.getDictionaryHash(elevator)];
        if (Object.keys(this.clonedList).length === 0) this.saveService.showSaveButton(false)
        this.table.renderRows();
    }

    isEditingRow(elevator: Elevator, property: string) {
        return this.clonedList[this.getDictionaryHash(elevator)] &&
            (this.notEditableColumns.indexOf(property) < 0)
    }

    onShowSavebutton() {
        this.saveService.showSaveButton();
    }

    onSave() {
        const updatedList: any[] = []
        for (let i in this.clonedList) {
            const index = this.dataSource.data.findIndex(x => x.UnitID === this.clonedList[i].UnitID)
            const obj = Utils.OmitKeysSpreadOperator(['Details'], this.dataSource.data[index])
            let item = { ...this.dataSource.data[index].Details[0], ...obj, ResidentMechanic: false }
            item.ResidentMechanic = item.HasResidentMechanic;
            updatedList.push(Utils.RemoveBlankFields(item))
        }
        if (updatedList.length > 0)
            Utils.httpUpdateElevators(
                this.http
                , this.baseUrl
                , updatedList
                , this
                , function (tthis: ElevatorListComponent) {
                    tthis.clonedList = {}
                    tthis.saveService.showSaveButton(false)
                    tthis.expandedElements.clear();
                    tthis.table.renderRows();
                }, function (tthis: ElevatorListComponent) {
                    tthis.saveService.addListener(tthis)
                    tthis.saveService.showSaveButton(true)
                    tthis.confimDialog.showErrorDialog({ error: { message: "Incorrect Branch Number. Please, indicate a proper branch for selected country checking Branch Hierarchy section." } });
                }
            );
    }

    applyFilter(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            this.searchItems.push({ name: value.replace('+', '').trim() });
        }
        // Reset the input value
        if (input) {
            input.value = '';
        }
        this.updatefilter()
    }

    remove(item: SearchItem): void {
        const index = this.searchItems.indexOf(item);
        if (index >= 0) {
            this.searchItems.splice(index, 1);
        }
        this.updatefilter()
    }
    updatefilter() {
        let filterString: string
        if (this.searchItems.length === 0) {
            this.dataSource.filter = 'notextfilters'
        }
        else {
            filterString = this.searchItems.map(e => { return e.name }).join('+') + '+checkboxes'
            this.dataSource.filter = filterString
        }
    }

    getDictionaryHash(elevator: Elevator) {
        return elevator.UnitID + elevator.TenantID
    }

}
export interface SearchItem {
    name: string;
}