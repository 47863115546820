import { Component, OnInit, OnDestroy, Inject, Input } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { DefaultValuesService } from "../../../services/defaultvalues.service";
import { Utils } from "../../../utils/utils";
@Component({
    selector: "parametersconfig",
    templateUrl: 'parametersconfig.component.html',
})

export class ParametersConfigComponent implements OnInit, OnDestroy {

    list: ParameterConfigController[] = [];
    mergedList: any[] = [];
    assetParameterList: AssetParameterFunctionality[] = [];
    parameterKeywords: string[] = [];

    subscriptionControllerType: any;

    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string,
        public defaults: DefaultValuesService
    ) { }

    ngOnInit(): void {
        this.subscriptionControllerType = this.defaults.controllerTypeMessageChanges$.subscribe(() => { this.getParametersConfig() });
        if (this.defaults.tenant) {
            this.getParametersConfig()
        }
    }
    ngOnChanges() {
        this.getParametersConfig()
    }

    ngOnDestroy() {
        this.subscriptionControllerType.unsubscribe();
    }

    getParametersConfig() {
        Utils.httpGetAssetParameters(
            this.http
            , this.baseUrl
            , this
            , function (tthis: ParametersConfigComponent, list: AssetParameterFunctionality[]) {
                tthis.assetParameterList = list;
                Utils.httpGetParameterConfigController(
                    tthis.http
                    , tthis.baseUrl
                    , tthis
                    , function (ttthis: ParametersConfigComponent, list: ParameterConfigController[]) {
                        const local2: any[] = []
                        list.forEach(x => {
                            const local = ttthis.assetParameterList.find(y => y.AssetParameterFunctionalityId === x.AssetParameterFunctionalityId)
                            x.AssetParameterFunctionality = local ? local.AssetParameterFunctionalityName : '';
                            x.ControllerParameterId = x.ControllerParameterId ? x.ControllerParameterId : ''
                            x.Unit = x.Unit ? x.Unit : ''
                        })
                        ttthis.list = list.sort((a, b) => (+b.Enabled) - (+a.Enabled)
                            || a.AssetParameterFunctionality.localeCompare(b.AssetParameterFunctionality)
                            || a.ControllerParameterId.replace(/</g, '').replace(/>/g, '').localeCompare(b.ControllerParameterId.replace(/</g, '').replace(/>/g, '')));
                    }
                );
            }
        );

        Utils.httpGetParameterKeywords(
            this.http
            , this.baseUrl
            , this
            , function (tthis: ParametersConfigComponent, list: string[]) {
                tthis.parameterKeywords = list
            }
        )
    }
}
