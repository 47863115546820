import { Component, OnInit, OnDestroy, Inject, ViewChild } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Utils } from '../../../../utils/utils';
import { SaveService } from '../../../../services/save.service';
import { SignalRService } from "../../../../services/signal-r";
import { DefaultValuesService } from "../../../../services/defaultvalues.service";
import { CustomerFacilityManagerListComponent } from "./customerfacilitymanager-list.component";
import { CustomerFacilityManagerFilterComponent } from "./customerfacilitymanager-filter.component";
import { Subscription } from "rxjs";

@Component({
    selector: "customerfacilitymanager",
    templateUrl: 'customerfacilitymanager.component.html',
})

export class CustomerFacilityManagerComponent implements OnInit, OnDestroy {

    list: CustomerFacilityManager[] = [];

    showLocalFilter = false;

    subscriptionStage: any;
    subscriptionTenant: any;
    subscriptions: Subscription[] = [];

    @ViewChild(CustomerFacilityManagerListComponent, { static: true }) listComponent!: CustomerFacilityManagerListComponent;
    @ViewChild(CustomerFacilityManagerFilterComponent, { static: true }) filterComponent!: CustomerFacilityManagerFilterComponent;

    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string
        , private defaults: DefaultValuesService
        , public signalRService: SignalRService
        , private saveService: SaveService
    ) { }

    ngOnInit(): void {
        this.subscriptionStage = this.defaults.stageMessageChanges$.subscribe(() => {
            this.getFilteredItemsByTenant()
            this.listComponent.clonedList = {}
        });
        this.subscriptionTenant = this.defaults.tenantMessageChanges$.subscribe(() => {
            this.getFilteredItemsByTenant()
            this.listComponent.clonedList = {}
        });
        if (this.defaults.tenant)
            this.getFilteredItemsByTenant()
    }

    ngOnDestroy() {
        this.saveService.removeListener(this);
        this.subscriptionStage.unsubscribe();
        this.subscriptionTenant.unsubscribe();
        this.subscriptions.forEach(x => x.unsubscribe())
    }

    getFilteredItemsByTenant() {
        const filter: CustomerFacilityManagerFilter = {
            'CustomerIds': this.filterComponent.customerIds,
            'FacilityManagerIds': this.filterComponent.facilityManagerIds
        }
        Utils.httpGetCustomerFacilityManagers(
            this.http
            , this.baseUrl
            , this.defaults.stage
            , filter
            , this
            , function (tthis: any, list: CustomerFacilityManager[]) {
                tthis.list = list;
                tthis.listComponent.clonedList = {}
                tthis.listComponent.expandedElements = new Set();
                tthis.saveService.showSaveButton(false)
                if (filter.CustomerIds.length < 1 && filter.FacilityManagerIds.length < 1)
                    tthis.showLocalFilter = false;
                else
                    tthis.showLocalFilter = true;
                tthis.subscriptions.pop();
            }
        );
    }
    onReload() {
        this.getFilteredItemsByTenant();
        this.listComponent.clonedList = {}
        this.saveService.showSaveButton(false)
    }

    downloadFacilityManagerFiltered() {
        const filter: CustomerFacilityManagerFilter = {
            'CustomerIds': this.filterComponent.customerIds,
            'FacilityManagerIds': this.filterComponent.facilityManagerIds
        }
        Utils.httpDownloadCustomerFacilityManager(
            this.http
            , this.baseUrl
            , this.defaults.stage
            , this.defaults.tenant
            , filter
            , this
            , null)
    }
}
