import { Component, Output, Inject, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Injectable, AfterViewInit } from '@angular/core';
import { ViewChild } from '@angular/core';
import { DefaultValuesService } from '../../services/defaultvalues.service';
import { Utils } from '../../utils/utils';


interface DialerCodes {
    Name: string;
    RegulatoryCodes: string[];
}

@Component({
    selector: 'regulatoryanddialer',
    templateUrl: './regulatoryanddialer.component.html',
    styleUrls: ['../../style/mat-select.component.less', './regulatoryanddialer.component.less',],


})
export class RegulatoryAndDialerComponent {
    protected _onDestroy = new Subject<void>();

    responseDialerCodes: DialerCodes[]=[]
    allDialers: string[] = [];
    relatedRegualatoryCodes: string[] = [];
    selectedDialer = '';
    selectedRegCode = '';
    constructor(
        private route: ActivatedRoute,
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string,
        private defaults: DefaultValuesService,
        private router: Router) {

        
        
        this.defaults.currentTenantObs$.subscribe((value) => value !== undefined ? (this.funcGetDialerNameAndRegulatoryCodes()) : null);
        this.defaults.stageMessageChanges$.subscribe((value) => value !== undefined ? (this.funcGetDialerNameAndRegulatoryCodes()) : null);
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    onRegCodeChange() {
        this.defaults.updateRegCode(this.selectedRegCode);
    }

    funcGetDialerNameAndRegulatoryCodes() {
        this.responseDialerCodes = []
        this.allDialers = [];
        this.relatedRegualatoryCodes = [];
        this.selectedDialer = '';
        this.selectedRegCode = '';
        this.defaults.updateRegCode(undefined)
        this.defaults.dialerCode = '';

        Utils.httpGetDialerNameAndRegulatoryCodes(
            this.http
            , this.baseUrl
            , this.defaults.tenant
            , this
            , function (tthis: RegulatoryAndDialerComponent, response: DialerCodes[]) {
                    tthis.responseDialerCodes = response;
                    tthis.allDialers = tthis.responseDialerCodes.map(x => x.Name);
                    tthis.selectedDialer = tthis.allDialers[0];
                    tthis.getRegCodeForDialer(tthis.selectedDialer);
                }
        )
    }

    getRegCodeForDialer(dialer: string)
    {
        this.defaults.dialerCode = dialer;
        this.relatedRegualatoryCodes = this.responseDialerCodes.find(x => x.Name == dialer)?.RegulatoryCodes ?? [];
        this.selectedRegCode = this.relatedRegualatoryCodes !== undefined ? this.relatedRegualatoryCodes[0] : "";
        //this.defaults.updateDialerCode(dialer);
        this.defaults.updateRegCode(this.relatedRegualatoryCodes[0]);

    }

}