<mat-dialog-content id="form-json-ingress">
    <h2 mat-dialog-title>{{title}}</h2>

    <form [formGroup]="form" (ngSubmit)="onSubmit()">

        <mat-form-field>
            <mat-label>Connection Type</mat-label>
            <mat-select placeholder="predefinedItem" name="predefinedItem" required formControlName="predefinedItem"
                        title="Connection Type">
                <mat-option *ngFor="let a of predefinedItems; index as i" value="{{a.ControllerConfigPredefinedItemsDescriptionID}}">
                    {{a.Description}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div mat-dialog-actions>
            <button mat-raised-button [disabled]="!form.valid">Save</button>
            <button mat-button (click)="onCancelForm()" tabindex="-1">Cancel</button>
        </div>
    </form>
</mat-dialog-content>