import { Component, OnInit, Inject, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import "rxjs/operators";
import { Router } from '@angular/router';
import { DialogService } from '../../../../services/dialog.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DefaultValuesService } from '../../../../services/defaultvalues.service';
import { DatePipe } from '@angular/common'
import { Utils } from '../../../../utils/utils';


@Component({
    selector: 'parametersconfigpopup',
    templateUrl: './parametersconfigpopup.component.html',
    styleUrls: ['../../../../style/custom-mat-dialog.component.less']
})

export class ParametersConfigPopupComponent implements OnInit {

    parameterKeywords: string[] = [];
    isShowingKeywordsSelector = false;

    body: any;
    caret = -1;
    @ViewChildren(MatSelect, { read: MatSelect }) items: QueryList<MatSelect> | undefined;

    submitted = false;
    form!: FormGroup

    constructor(private http: HttpClient
        , private confimDialog: DialogService
        , private router: Router
        , @Inject(MAT_DIALOG_DATA) public data: any
        , @Inject('BASE_URL') private baseUrl: string
        , public dialogRef: MatDialogRef<ParametersConfigPopupComponent>
        , private fb: FormBuilder
        , public defaults: DefaultValuesService
        , private formBuilder: FormBuilder
        , private datepipe: DatePipe) {

        this.confimDialog.tthis = this;

    }

    ngOnInit(): void {
        Utils.httpGetParameterKeywords(
            this.http
            , this.baseUrl
            , this
            , function (tthis: ParametersConfigPopupComponent, list: string[]) {
                tthis.parameterKeywords = list
            }
        )

        this.body = {
            ControllerParameterId: this.data.controllerParameterId,
            AssetParameterFunctionalityId: this.data.assetParameterFunctionalityId,
            ParameterConfigKey: this.data.parameterKey,
            Unit: this.data.unit,
            Enabled: this.data.enabled
        }    

        this.form = this.formBuilder.group({
            unit: ['', [Validators.required]],
            controllerParameterId: ['', [Validators.required]],
            assetParameterFunctionalityId: [this.body.AssetParameterFunctionalityId, [Validators.required]]
        });
    }
    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.form)
            if (this.form.invalid) {
                return;
            }
        Utils.httpUpdateParameterConfigController(
            this.http
            , this.baseUrl
            , this.body
            , this
            , function (tthis: ParametersConfigPopupComponent, object: any) {
                tthis.dialogRef.close();
            }
        );
    }

    onBack() {

    }

    async onShowKeywords() {
        const input = document.getElementById('controllerParameterId') as HTMLInputElement
        if (input)
            this.caret = input.selectionStart || 0;
        this.isShowingKeywordsSelector = true;
        let target: any;
        do {
            target = document.getElementById('keywords');
            await new Promise(r => setTimeout(r, 20));
        }
        while (!target)
        if (this.items)
            this.items.forEach((div) => {
                if (div.id === 'keywords') {
                    setTimeout(() => {
                        div.open()
                    })
                }
            });
    }

    openedChange($event: boolean) {
        if (!$event)
            this.isShowingKeywordsSelector = false;
    }

    onSelectItem(value: string) {

        // Intersect or append depending on previous user event
        // If field is empty -> add directly
        if (!this.body.ControllerParameterId || this.body.ControllerParameterId === '') {
            this.body.ControllerParameterId =
                value
        }
        else {
            if (this.caret === 0) {
                this.body.ControllerParameterId =
                    value + ' ' + this.body.ControllerParameterId
            }
            else if (this.caret === this.body.ControllerParameterId.length) {
                this.body.ControllerParameterId =
                    this.body.ControllerParameterId + ' ' + value
            }
            else {
                this.body.ControllerParameterId =
                    this.body.ControllerParameterId.substring(0, this.caret) +
                    ' ' +
                    value +
                    ' ' +
                    this.body.ControllerParameterId.substring(this.caret);
            }
        }

        // Cleaining variables
        this.isShowingKeywordsSelector = false;
        this.caret = 0;
    }

}


