import { Component, Inject, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Utils } from "../../../utils/utils";
import { DefaultValuesService } from "../../../services/defaultvalues.service";
import { DialogService } from "../../../services/dialog.service";
import { SaveService } from "../../../services/save.service";
import { Router } from "@angular/router";
import { FilterService } from "../../../services/filter.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

@Component({
    selector: "configuration",
    templateUrl: './configuration.component.html',
    styleUrls: ['../../../style/container-tab-list.component.less']
})

export class ConfigurationComponent implements OnInit {
    title: string = "Configurations";

    result: any | undefined;

    constructor(
        private http: HttpClient
        , private fb: FormBuilder
        , private router: Router
        , @Inject('BASE_URL') private baseUrl: string
        , private defaults: DefaultValuesService
    ) { }

    ngOnInit() {
        //var url = this.baseUrl + "api/generic/configuration";
        //var tthis = this;
        //this.http
        //    .get(url)
        //    .subscribe(
        //        res => {
        //            this.result = res;
        //        }
        //        , error => Utils.writeLog(error)
        //    );
    }

}