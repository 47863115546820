<div class="custom-mat-dialog-form non-overflow">
    <h3 *ngIf="newRow" mat-dialog-title>{{title}}</h3>
    <h3 *ngIf="!newRow" mat-dialog-title>Editing {{selected.Name}} - {{selected.Text}}</h3>
    <div>
        <form [formGroup]="form">

            <div class="mt-2" *ngIf="newRow">
                <label>Event Type</label>
                <div class="input">
                    <mat-select placeholder="EventType" name="EventType" required formControlName="EventType"
                                title="Operation Mode"
                                panelClass="testcase-class"
                                class="form-control"
                                (selectionChange)="onEventTypeChange($event)">
                        <mat-option *ngFor="let e of Events" [value]="e.EventTypeId">
                            {{e.Description}}
                        </mat-option>
                    </mat-select>
                </div>
            </div>

            <!--Text-->
            <div class="mt-2" *ngIf="messageType === BLUEBOX">
                <label>BlueBox Event</label>
                <div class="input">
                    <mat-select placeholder="Value" name="Value" required formControlName="Value"
                                title="BlueBox Event"
                                panelClass="testcase-class"
                                class="form-control"
                                (selectionChange)="onValueChange($event)">
                        <mat-option *ngFor="let value of blueBoxEventList | async" [value]="value.BK">
                            ({{value.BK}}) - {{value.Description}}
                        </mat-option>
                    </mat-select>
                </div>
            </div>
            <div class="mt-2" *ngIf="messageType === OPERATION_MODE">
                <label>Operation Mode</label>
                <div class="input">
                    <mat-select placeholder="Value" name="Value" required formControlName="Value"
                                title="Operation Mode"
                                panelClass="testcase-class"
                                class="form-control"
                                (selectionChange)="onValueChange($event)">
                        <mat-option *ngFor="let om of operationModes; index as i" [value]="om.Value">
                            {{om.ModeReference}} ({{om.Value}})
                        </mat-option>
                    </mat-select>
                </div>
            </div>
            <div class="mt-2" *ngIf="messageType === ERROR_CODE">
                <label>Error Code</label>
                <div class="input">
                    <mat-select placeholder="Value" name="Value" required formControlName="Value"
                                title="Error Code"
                                panelClass="testcase-class"
                                class="form-control"
                                (selectionChange)="onValueChange($event)">
                        <mat-option *ngFor="let ec of errorCodes; index as i" [value]="ec.ErrorCode">
                            {{ec.ErrorCode}} - {{ec.Description}}
                        </mat-option>
                    </mat-select>
                </div>
            </div>
            <div class="mt-2" *ngIf="messageType === TRIP">
                <label>Start Floor</label>
                <div class="input">
                    <input type="number" name="SFloor" required formControlName="SFloor"
                           min="0"
                           title="enter the number of start floor..."
                           [ngClass]="classname[TEXT]"
                           class="form-control"
                           (change)="onFloorChange($event)" />
                </div>

                <label>End Floor</label>
                <div class="input">
                    <input type="number" name="EFloor" required formControlName="EFloor"
                           min="1"
                           [ngClass]="classname[TEXT]"
                           class="form-control"
                           title="enter the number of end floor..."
                           (change)="onFloorChange($event)" />
                </div>
            </div>
            <div class="mt-2" *ngIf="messageType === DOOR_MOVEMENT">
                <label>Start State</label>
                <div class="input">

                    <mat-select placeholder="SState" name="SState" required formControlName="SState"
                                title="Start State"
                                panelClass="testcase-class"
                                class="form-control"
                                (selectionChange)="onStartStateChange($event)">
                        <mat-option *ngFor="let value of doorStateList | async" [value]="value.BK">
                            ({{value.BK}}) - {{value.Description}}
                        </mat-option>
                    </mat-select>
                </div>

                <label>End State</label>
                <div class="input">
                    <mat-select placeholder="EState" name="EState" required formControlName="EState"
                                title="Start State"
                                panelClass="testcase-class"
                                class="form-control"
                                (selectionChange)="onEndStateChange($event)">
                        <mat-option *ngFor="let value of doorStateList | async" [value]="value.BK">
                            ({{value.BK}}) - {{value.Description}}
                        </mat-option>
                    </mat-select>
                </div>
                <label>Floor</label>
                <div class="input">
                    <input type="number" name="Floor" required formControlName="Floor"
                           min="0"
                           title="enter the number of the floor where the door movement happened"
                           [ngClass]="classname[TEXT]"
                           class="form-control"
                           (change)="onDoorFloorChange()" />
                </div>
            </div>
            <div class="mt-2">
                <label>{{header[MILISECONDS].label}}</label>
                <div class="input">
                    <input type="number" name="Miliseconds" required formControlName="Miliseconds"
                           title="{{header[MILISECONDS].label}}"
                           class="form-control"
                           (change)="onMilisecondsChange($event)">
                </div>
            </div>

            <!--Output-->
            <div class="mt-2" *ngIf="messageType !== OPERATION_MODE">&nbsp;</div>
            <div class="mt-2" *ngIf="messageType === OPERATION_MODE && !newRow">
                <div class="input">

                    <mat-checkbox name="Output"
                                  title="{{header[OUTPUT].label}}"
                                  (change)="onOutputChange($event)">
                        {{header[OUTPUT].label}}
                    </mat-checkbox>
                </div>
            </div>


            <div class="mt-2 right" *ngIf="!newRow">
                <button matTooltip="Save" [disabled]="!form.valid" (click)="onSubmit()"
                        class="btn btn-primary">
                    Save
                </button>
                <button matTooltip="Cancel" (click)="onCancelForm()"
                        class="btn btn-danger">
                    Back
                </button>
            </div>

            <div *ngIf="newRow" class="mt-2 right">
                <button [disabled]="!form.valid" (click)="onSubmit()" class="btn btn-primary">Save</button> <!--[mat-dialog-close]="1"-->
                <button (click)="onCancelForm()" tabindex="-1" class="btn btn-danger">Cancel</button>
            </div>

        </form>
    </div>
</div>