import { Component, OnInit, OnDestroy, Inject, ViewChild } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { DefaultValuesService } from '../../../../services/defaultvalues.service';
import { Utils } from '../../../../utils/utils';
import { SaveService } from '../../../../services/save.service';
import { EscalatorListComponent } from './escalator-list.component';
import { EscalatorFilterComponent } from './escalator-filter.component';
import { Subscription } from "rxjs";

@Component({
    selector: "escalator",
    templateUrl: 'escalator.component.html',
})

export class EscalatorComponent implements OnInit, OnDestroy {

    list: Escalator[] = [];

    title = "Escalators";
    showLocalFilter = false;

    subscriptionStage: any;
    subscriptionTenant: any;
    subscriptions: Subscription[] = [];

    @ViewChild(EscalatorListComponent, { static: true }) listComponent!: EscalatorListComponent;
    @ViewChild(EscalatorFilterComponent, { static: true }) filterComponent!: EscalatorFilterComponent;

    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string
        , private defaults: DefaultValuesService
        , private saveService: SaveService
    ) {

    }

    ngOnInit(): void {
        this.subscriptionStage = this.defaults.stageMessageChanges$.subscribe(() => {
            this.getFilteredEscalatorsByTenant()
            this.listComponent.clonedList = {}
        });
        this.subscriptionTenant = this.defaults.tenantMessageChanges$.subscribe(() => {
            this.getFilteredEscalatorsByTenant()
            this.listComponent.clonedList = {}
        });
        if (this.defaults.tenant)
            this.getFilteredEscalatorsByTenant()
    }


    ngOnDestroy() {
        this.subscriptionStage.unsubscribe();
        this.subscriptionTenant.unsubscribe();
        this.subscriptions.forEach(x => x.unsubscribe())
    }

    onReload() {
        this.getFilteredEscalatorsByTenant();
        this.listComponent.clonedList = {}
        this.saveService.showSaveButton(false)
    }

    getFilteredEscalatorsByTenant() {
        const filter: EscalatorFilter = {
            'UnitIds': this.filterComponent.unitIds,
            'BranchNumbers': this.filterComponent.branchNumbers,
            'ControllerTypes': this.filterComponent.controllerTypes,
            'EscalatorControllerTypes': this.filterComponent.escalatorControllerTypes
        }
        Utils.httpGetEscalators(
            this.http
            , this.baseUrl
            , this.defaults.stage
            , filter
            , this
            , function (tthis: any, list: Escalator[]) {
                tthis.list = list;
                tthis.listComponent.clonedList = {}
                tthis.listComponent.expandedElements = new Set();
                tthis.saveService.showSaveButton(false)
                if (filter.UnitIds.length < 1 && filter.BranchNumbers.length < 1 && filter.ControllerTypes.length < 1 && filter.EscalatorControllerTypes.length < 1)
                    tthis.showLocalFilter = false;
                else
                    tthis.showLocalFilter = true;
                tthis.subscriptions.pop();

            }
        );
    }

    downloadEscalatorFiltered() {
        const filter: EscalatorFilter = {
            'UnitIds': this.filterComponent.unitIds,
            'BranchNumbers': this.filterComponent.branchNumbers,
            'ControllerTypes': this.filterComponent.controllerTypes,
            'EscalatorControllerTypes': this.filterComponent.escalatorControllerTypes
        }
        Utils.httpDownloadEscalators(
            this.http
            , this.baseUrl
            , this.defaults.tenant
            , filter
            , this
            , null)
    }
}
