import { Component, Inject, AfterViewInit, ViewChild } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DefaultValuesService } from "../../../services/defaultvalues.service";
import { MatTabGroup } from "@angular/material/tabs";

@Component({
    selector: "controllertypes",
    templateUrl: './controllertypes.component.html',
    styleUrls: ['../../../style/container-tab-list.component.less']
})

export class ControllerTypesComponent {
    @ViewChild('tabGroup') tabGroup!: MatTabGroup;
    title: string;

    constructor(
        private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , public defaults: DefaultValuesService
    ) {
        this.title = "ControllerType Definitions";
    }

    ngAfterViewInit() {
        this.defaults.defaultComponentTab(this)
    }
}