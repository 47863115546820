<div class="example-container">
    <table mat-table
           [dataSource]="list" multiTemplateDataRows
           class="mat-elevation-z8 mat-table-custom" id="errorcodemltable">

        <!-- Do not use loop because we need to add something like checkbox in the middle of the list. -->
        <!--<ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay.slice(0,columnsToDisplay.length-1)">
            <th mat-header-cell *matHeaderCellDef> {{column | titlecase}}  </th>
            <td mat-cell *matCellDef="let element"> {{element[column]}} </td>-->
        <!--<td mat-cell *matCellDef="let element"> {{element[column]}} </td>-->
        <!--</ng-container>-->

        <ng-container matColumnDef="arrow">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <span class="material-icons" *ngIf="checkExpanded(element); else show_expand_more">
                    expand_less
                </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="errorcode">
            <th mat-header-cell *matHeaderCellDef> Error Code </th>
            <td mat-cell *matCellDef="let element"> {{element.ErrorCode}} </td>
        </ng-container>

        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let element"> {{element.Description}} </td>
        </ng-container>

        <ng-container matColumnDef="errorweight">
            <th mat-header-cell *matHeaderCellDef> Weight </th>
            <td mat-cell *matCellDef="let element"> {{element.ErrorWeight}} </td>
        </ng-container>

        <ng-container matColumnDef="controllerblocked">
            <th mat-header-cell *matHeaderCellDef> Controller Blocked </th>
            <!--<td mat-cell *matCellDef="let element"> {{element2.MaintenanceFailure}} </td>-->
            <td mat-cell *matCellDef="let element">
                <mat-checkbox disabled [checked]="element.ControllerBlocked"></mat-checkbox>
            </td>
        </ng-container>

        <ng-container matColumnDef="suggestedchanges">
            <th mat-header-cell *matHeaderCellDef> Suggested Changes </th>
            <td mat-cell *matCellDef="let element"> {{element.SuggestedChanges}} </td>
        </ng-container>

        <ng-container matColumnDef="suggestednew">
            <th mat-header-cell *matHeaderCellDef> No. of New Suggestions </th>
            <td mat-cell *matCellDef="let element"> {{element.SuggestedNew}} </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Actions  </th>
            <td mat-cell *matCellDef="let element">
                <button class="mlbuttons" mat-raised-button (click)="$event.stopPropagation(); consolidateErrorCode(element)">Consolidate</button>
                <button class="mlbuttons" mat-raised-button color="warn" (click)="$event.stopPropagation(); ignoreErrorCode(element)">Ignore</button>
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                <div class="example-element-detail"
                     [@detailExpand]="checkExpanded(element) ? 'expanded' : 'collapsed'">
                    <!--[@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">-->
                    <table mat-table [dataSource]="element.PossibleFailureML" id="possiblefailuremltable">

                        <!-- Position Column -->
                        <ng-container matColumnDef="maintenancefailure">
                            <th mat-header-cell *matHeaderCellDef> Maintenance Failure </th>
                            <td mat-cell *matCellDef="let element2"> {{element2.MaintenanceFailure}} </td>
                        </ng-container>

                        <ng-container matColumnDef="troubleshootingaction">
                            <th mat-header-cell *matHeaderCellDef> Troubleshooting Action </th>
                            <td mat-cell *matCellDef="let element2"> {{element2.TroubleShootingAction}} </td>
                        </ng-container>

                        <ng-container matColumnDef="troubleshootingweight">
                            <th mat-header-cell *matHeaderCellDef> Weight </th>
                            <td mat-cell *matCellDef="let element2"> {{element2.TroubleShootingActionWeight}} </td>
                        </ng-container>

                        <ng-container matColumnDef="subsystemgroup">
                            <th mat-header-cell *matHeaderCellDef> Location </th>
                            <td mat-cell *matCellDef="let element2"> {{element2.SubSystemGroup}} </td>
                        </ng-container>

                        <ng-container matColumnDef="componentitem">
                            <th mat-header-cell *matHeaderCellDef> Component </th>
                            <td mat-cell *matCellDef="let element2"> {{element2.ComponentItem}} </td>
                        </ng-container>

                        <ng-container matColumnDef="cause">
                            <th mat-header-cell *matHeaderCellDef> Cause </th>
                            <td mat-cell *matCellDef="let element2"> {{element2.Cause}} </td>
                        </ng-container>

                        <ng-container matColumnDef="resolution">
                            <th mat-header-cell *matHeaderCellDef> Resolution </th>
                            <td mat-cell *matCellDef="let element2"> {{element2.Resolution}} </td>
                        </ng-container>

                        <ng-container matColumnDef="partsrequired">
                            <th mat-header-cell *matHeaderCellDef> Parts Required? </th>
                            <td mat-cell *matCellDef="let element2">
                                <mat-checkbox disabled [checked]="element2.PartsRequired"></mat-checkbox>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="highvaluepart">
                            <th mat-header-cell *matHeaderCellDef> High Value Part? </th>
                            <td mat-cell *matCellDef="let element2">
                                <mat-checkbox disabled [checked]="element2.HighValuePart"></mat-checkbox>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="suggestedweight">
                            <th mat-header-cell *matHeaderCellDef> Suggested Weight </th>
                            <td mat-cell *matCellDef="let element2"> {{element2.NewTroubleshootingActionWeight}} </td>
                        </ng-container>

                        <ng-container matColumnDef="isnew">
                            <th mat-header-cell *matHeaderCellDef> New Suggestion? </th>
                            <td mat-cell *matCellDef="let element2">
                                <mat-checkbox disabled [checked]="element2.IsNew"></mat-checkbox>
                            </td>
                        </ng-container>

                        <!-- Consilidate button -->
                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef>Actions</th>
                            <td mat-cell *matCellDef="let element2">
                                <button class="mlbuttons" mat-raised-button (click)="consolidateResolution(element2)">Consolidate</button>
                                <button class="mlbuttons" mat-raised-button color="warn" (click)="ignoreResolution(element2)">Ignore</button>
                            </td>
                        </ng-container>

                        <tr mat-header-row class="subtableheader" *matHeaderRowDef="columnsToDisplay2"></tr>
                        <tr mat-row *matRowDef="let row2; let i2 = index; columns: columnsToDisplay2;" [class.alternative]="i2 % 2 != 0"></tr>
                    </table>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; let i = dataIndex; columns: columnsToDisplay;"
            class="example-element-row"
            [class.example-expanded-row]="checkExpanded(element)"
            (click)="pushPopElement(element)">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>
</div>

<ng-template #show_expand_more>
    <span class="material-icons">
        expand_more
    </span>
</ng-template>
