import { HttpClient } from "@angular/common/http";
import { Component, Inject, OnInit } from "@angular/core";
import { DialogService } from "../../../../services/dialog.service";
import { Utils } from "../../../../utils/utils";

@Component({
    selector: "sectionscheduler",
    templateUrl: './sectionscheduler.component.html',
    styleUrls: ['../../../../style/container-tab-list.component.less', '../../../../style/new-generic-styles.component.less']
})

export class SectionSchedulerComponent implements OnInit {

    title: string;
    list: SectionScheduler[] = [];
    pattern = /^(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/

    constructor(
        private http: HttpClient
        , private dialog: DialogService
        , @Inject('BASE_URL') private baseUrl: string
    ) {
        this.title = "Reporting Admin Pane (Pane to define when sections will be available during year)";
        this.dialog.tthis = this;
    }

    ngOnInit() {
        this.loadSectionSchedulerInformation();
    }

    private loadSectionSchedulerInformation() {
        Utils.httpGetSectionSchedulerInformation(
            this.http
            , this.baseUrl
            , this
            , function (tthis: SectionSchedulerComponent, result: SectionScheduler[]) {
                tthis.list = result
            }
        );
    }

    validate(evt: any) {
        const isValid = this.pattern.test(evt);
        return isValid;
    }


    save(): void {
        let check;

        //Validate whole array
        this.list.forEach(x => {
            if ((!this.validate(x.EndPeriod) || !this.validate(x.StartPeriod) || new Date(x.EndPeriod) < new Date(x.StartPeriod)) ||
                (this.validate(x.EndPeriod2) && this.validate(x.StartPeriod2) && new Date(x.EndPeriod2) < new Date(x.StartPeriod2)) ||
                (x.EndPeriod2 !== '' && x.StartPeriod2 === '' || x.EndPeriod2 === '' && x.StartPeriod2 !== '')
            ) {
                check = false;
            }
        })
        if (check === false)
            this.dialog.showErrorDialog({ 'error': { 'message': "Check provided periods. One of them are bad populated" } })
        else {
            Utils.httpUpdateSectionSchedulerInformation(
                this.http
                , this.baseUrl
                , this.list
                , this
                , function (tthis: SectionSchedulerComponent, result: any) {
                    if (result === true)
                        tthis.dialog.showSuccessDialog("Periods saved successfully")
                }
            );
        }
    }

    onBack() {
    }
}