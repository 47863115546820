<div class="container-tab-lists">
    <div class="inner-tcontainer-tab">
        <div class="tab-body">
            <div class="tab-content">
                <mat-tab-group #tabGroup (selectedTabChange)="defaults.updateComponentTab($event.tab.textLabel)">

                    <mat-tab label="JCoB Rules Masterdata" *ngIf="defaults.isMFGAdminPDCSUser">
                        <filemasterdata [originalMfgCenterProduct]="originalMfgCenterProduct"
                                        [originalSoftwareReleases]="originalSoftwareReleases"></filemasterdata>
                    </mat-tab>

                    <mat-tab label="NVStruct Masterdata" *ngIf="defaults.isMFGAdminPDCSUser">
                        <nvstructmasterdata [originalSoftwareReleases]="originalSoftwareReleases"></nvstructmasterdata>
                    </mat-tab>

                    <mat-tab label="Commands Masterdata" *ngIf="defaults.isMFGAdminPDCSUser">
                        <commandsmasterdata [originalSoftwareReleases]="originalSoftwareReleases"></commandsmasterdata>
                    </mat-tab>

                </mat-tab-group>
            </div>
        </div>
    </div>
</div>
