import { Component, Inject, OnInit, EventEmitter, Output, ViewEncapsulation } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatChipInputEvent } from "@angular/material/chips";
import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
    selector: "otherasset-filter",
    templateUrl: './otherasset-filter.component.html',
    styleUrls: ['../../../../style/form.component.less', './otherasset-filter.component.less', '../../../../style/expand-collapse-panel.component.less'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*', })),
            transition('expanded => collapsed', [animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
        ]),
    ],
    encapsulation: ViewEncapsulation.None
})

export class OtherAssetFilterComponent implements OnInit {

    form!: FormGroup;
    @Output() outputFilter = new EventEmitter<OtherAssetFilter>();

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [COMMA, TAB, ENTER];

    nicknames: string[] = [];
    buildingAddr1: string[] = [];
    buildingCities: string[] = [];
    unitIds: string[] = [];
    branchNumbers: string[] = [];
    controllerTypes: string[] = [];
    assetTypes: string[] = [];
    unitTypes: string[] = [];

    constructor(
        private http: HttpClient
        , private fb: FormBuilder
        , @Inject('BASE_URL') private baseUrl: string
    ) {
    }

    ngOnInit() {
        this.createForm();
    }

    createForm() {
        this.form = this.fb.group({
        });
    }

    changeTypeButton() {
        return this.unitIds.length < 1 && this.controllerTypes.length < 1
            && this.branchNumbers.length < 1 && this.nicknames.length < 1
            && this.buildingAddr1.length < 1 && this.buildingCities.length < 1
            && this.assetTypes.length < 1 && this.unitTypes.length < 1
    }

    applyFilter(event: MatChipInputEvent, list: string): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            switch (list) {
                case 'branchnumbers':
                    this.branchNumbers.push(value.replace('+', '').trim()); break;
                case 'unitids':
                    this.unitIds.push(value.replace('+', '').trim()); break;
                case 'controllertypes':
                    this.controllerTypes.push(value.replace('+', '').trim());break;
                case 'nicknames':
                    this.nicknames.push(value.replace('+', '').trim()); break;
                case 'buildingaddr1':
                    this.buildingAddr1.push(value.replace('+', '').trim()); break;
                case 'buildingcities':
                    this.buildingCities.push(value.replace('+', '').trim()); break;
                case 'unitypes':
                    this.unitTypes.push(value.replace('+', '').trim()); break;
                case 'assettypes':
                    this.assetTypes.push(value.replace('+', '').trim()); break;
            }
        }
        // Reset the input value
        if (input) {
            input.value = '';
        }
    }

    remove(item: string, list: string): void {
        let index = -1
        switch (list) {
            case 'branchnumbers':
                index = this.branchNumbers.indexOf(item);
                if (index >= 0)
                    this.branchNumbers.splice(index, 1);
                break;
            case 'unitids':
                index = this.unitIds.indexOf(item);
                if (index >= 0)
                    this.unitIds.splice(index, 1);
                break;
            case 'controllertypes':
                index = this.controllerTypes.indexOf(item);
                if (index >= 0)
                    this.controllerTypes.splice(index, 1);
                break;
            case 'nicknames':
                index = this.nicknames.indexOf(item);
                if (index >= 0)
                    this.nicknames.splice(index, 1);
                break;
            case 'buildingaddr1':
                index = this.buildingAddr1.indexOf(item);
                if (index >= 0)
                    this.buildingAddr1.splice(index, 1);
                break;
            case 'buildingcities':
                index = this.buildingCities.indexOf(item);
                if (index >= 0)
                    this.buildingCities.splice(index, 1);
                break;   
            case 'assettypes':
                index = this.assetTypes.indexOf(item);
                if (index >= 0)
                    this.assetTypes.splice(index, 1);
                break;
            case 'unittypes':
                index = this.unitTypes.indexOf(item);
                if (index >= 0)
                    this.unitTypes.splice(index, 1);
                break;   
        }
    }

    onFilter() {
        const filter: OtherAssetFilter = {
            'Nicknames': this.nicknames,
            'BranchNumbers': this.branchNumbers,
            'BuildingAddr1': this.buildingAddr1,
            'UnitIds': this.unitIds,
            'BuildingCities': this.buildingCities,
            'ControllerTypes': this.controllerTypes,
            'AssetTypes': this.assetTypes,
            'UnitTypes': this.unitTypes
        }
        this.outputFilter.emit(filter);
    }
}