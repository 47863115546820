import { Component, Inject, Output, EventEmitter, Input } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { SaveService } from '../../../../services/save.service';
import { DefaultValuesService } from '../../../../services/defaultvalues.service';
import { MatDialog } from "@angular/material/dialog";
import { Utils } from '../../../../utils/utils';
import { ControllerTypeByTenantFormComponent } from './controllertypebytenant-form.component';

@Component({
    selector: 'controllertypebytenant-menu',
    templateUrl: './controllertypebytenant-menu.component.html',
    styleUrls: ['../../../../style/menu.component.less']
})

export class ControllerTypeByTenantMenuComponent {

    @Output() onRefresh = new EventEmitter<{}>();

    languageList: Language[] | undefined = [];

    constructor(private activatedRoute: ActivatedRoute,
        private router: Router
        , private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , private saveService: SaveService
        , public defaults: DefaultValuesService
        , private dialogForm: MatDialog

    ) {
    }

    onSave() {
        this.saveService.fireSaveAll();
    }

    openNewConfigurationPopup() {
        const dialog = this.dialogForm.open(ControllerTypeByTenantFormComponent, {
            panelClass: ['custom-mat-dialog'],
            disableClose: true,
            data: { "country": this.defaults.tenant }
        })
        dialog.afterClosed().subscribe(() => {
            this.onRefresh.emit({})
        })
    }
}
