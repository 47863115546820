import { Component, Output, Inject, EventEmitter, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Utils } from '../../utils/utils';
import { DefaultValuesService } from '../../services/defaultvalues.service';
import { DialogService } from '../../services/dialog.service';
import { SaveService } from '../../services/save.service';
import { FilterService } from '../../services/filter.service';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    selector: 'population',
    template: `Population:<br />
                    <ng-select  style="width:230px; border: 1px white solid" class="top-main-select"
                    (change)="onPopulationChange($event)"
                    [items]="populationList"
                    bindLabel="PopulationName"
                     bindValue="PopulationName"
                     [closeOnSelect]="true"
                   [searchable]="true"
                   [(ngModel)]="selectedPopoulation"
                  >
                 </ng-select>`,
    styleUrls: ['../../style/mat-select.component.less']
})
export class PopulationComponent implements OnInit {

    protected _onDestroy = new Subject<void>();
    populationList: Population[] = []
    selectedPopoulation = ''
    constructor(
        private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , private defaults: DefaultValuesService
        , private routeService: Router
    ) {
    }

    ngOnInit() {
        this.defaults.currentTenantObs$.subscribe((value) => value !== undefined ? this.funcGetHealthScorePop(value):null);
    }

    ngOnChanges() {
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }
    ngAfterViewInit() {

    }

    onPopulationChange(value: any) {
        this.defaults.updatePopulation(value._PopulationId);
    }

    funcGetHealthScorePop(controller) {

        Utils.httpGetAllPopulationforController(
            this.http
            , this.baseUrl
            , controller
            , this
            , function (tthis: PopulationComponent, response: Population[]) {
                tthis.populationList = response;
                tthis.selectedPopoulation = tthis.populationList[0]?.PopulationName;
                tthis.defaults.updatePopulation(tthis.populationList[0]?._PopulationId);
                //    console.log("tthis.populationList,tthis.selectedPopoulation", response, tthis.selectedPopoulation)
            }
        )
    }

}