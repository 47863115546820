<div #alertConfirm [ngClass]="classname" [scrollTop]="-alertConfirm.scrollHeight">
    <img *ngIf="type==SUCCESS && showTypeIcon===true" alt="logo" src="../../assets/success.png" />
    <img *ngIf="type==ERROR && showTypeIcon===true" alt="logo" src="../../assets/error.png" />
    <img *ngIf="(type==WARNING || type==INFO_BACKGROUND) && showTypeIcon===true" alt="logo" src="../../assets/warning.png" />
    <h4>{{title}}</h4>
    <p *ngFor="let item of items">{{item}}</p>
    <p>{{message}}</p>
    <div *ngIf="textIconItems.length > 0">
        <div *ngFor="let item of textIconItems">
            <span>{{item.text}} </span>
            <mat-icon class="{{item.iconClass}}">{{item.matIcon}}</mat-icon>
        </div>
    </div>
    <mat-progress-bar
                      *ngIf ="progressValue > -1"
                      class="custom-progress-bar-blue"
                      [color]="color"
                      [mode]="mode"
                      [value]="progressValue"
                      style="height: 14px; border-radius: 3px; margin-bottom: 14px;">
    </mat-progress-bar>   
    <br>
    <button type="button" *ngIf="type==CONFIRM" class="btn btn-success" (click)="onYes()">Yes</button>
    <button type="button" *ngIf="type==CONFIRM" class="btn btn-warning" (click)="onNo()">No</button>
    <button type="button" *ngIf="type!=CONFIRM && type != INFO_BACKGROUND" class="btn btn-info" (click)="onOk()">Ok</button>
</div>
