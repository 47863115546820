<div class="panel-body zoomIn">
    <div class="row heading-background mt-5">

        <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
            <h4>{{componentName}}s</h4>
        </div>

        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 search-container">

            <div (change)="updateRolesResults()" (keyup)="updateRolesResults()">
                <input class="form-control" type="text" placeholder="Search" [(ngModel)]="roleSearchValue">
            </div>
            <button mat-raised-button (click)="funcGetDirIndirUserRoles()" aria-label="Reset" class="btn btn-sm button-color-fix ">
                <mat-icon>cached</mat-icon>
            </button>
        </div>

    </div>

    <!--The Table-->
    <div class="custom_small_panelbody_fix_max">
        <div *ngIf='Response_State===1'>
            <div class="row ">
                <!--Direct Items-->
                <div [ngClass]="{'col-lg-6 col-md-6 col-sm-6 col-xs-6': showIndirectRole(),
                     'col-lg-12': !showIndirectRole()}" style=" padding-inline:0 !important">
                    <div *ngIf="showIndirectRole()" class="row heading-background" style="padding-inline: 15px !important">
                        <h4>Direct {{componentName}} Member</h4>
                    </div>
                    <div class="custom_panelbody_fix_max" style="overflow: auto !important;padding-inline:15px !important">
                        <mat-list>
                            <mat-list-item *ngFor="let item of filteredDirectRoleItems; let i = index">
                                <p matLine>
                                    {{ item['persona'] }}
                                </p>
                                <button *ngIf="[null,true].includes(item['isUnAssignable']) " mat-raised-button (click)="handleDeleteButton(item)" aria-label="Reset" class="btn custom_cross pull-right button-color-fix_red" color="warn">
                                    <mat-icon>close</mat-icon>
                                </button>
                            </mat-list-item>
                        </mat-list>
                    </div>
                </div>
                <!--InDirect Items-->
                <div *ngIf="showIndirectRole()" class="col-lg-6 col-md-6 col-sm-6 col-xs-6 " style=" padding-inline:0 !important">
                    <div class="row heading-background" style="padding-inline: 15px">
                        <h4>Indirect {{componentName}} Member</h4>
                    </div>
                    <div class="custom_panelbody_fix_max" style="overflow: auto !important;padding-inline:15px !important">
                        <mat-list>
                            <mat-list-item *ngFor="let item of filteredInDirRoleItems; let i = index">
                                <p matLine>
                                    {{ item['persona'] }}
                                </p>
                            </mat-list-item>
                        </mat-list>
                    </div>

                </div>
            </div>
        </div>

        <div *ngIf='Response_State===0'>
            <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; height: 190px;">
                <svg xmlns="http://www.w3.org/2000/svg" height="70" viewBox="0 -960 960 960" width="70" style="fill: #9e9e9e ;"><path d="M833-41 678-196v36H38v-94q0-35 18-63.5t50-42.5q73-32 131.5-46T358-420q28 0 54.5 3t52.5 8l-75-75q-8 2-16 2.5t-16 .5q-66 0-108-42t-42-108q0-8 .5-16t2.5-16L26-848l43-43L876-84l-43 43ZM624-422q69 8 129 23t98 35q34 18 52.5 46.5T922-254v94h-36L727-319q-14-35-40.5-60.5T624-422Zm-266 62q-57 0-106.5 11T130-306q-14 6-23 21t-9 31v34h520v-36l-63-63q-59-24-102.5-32.5T358-360Zm178-150q16-23 24-54t8-67q0-45-12.5-79.5T519-774q11-3 24.5-5t24.5-2q66 0 108 42t42 108q0 66-43.5 108T565-481l-29-29Zm-46-46-46-46q2-7 3-14t1-15q0-39-25.5-64.5T358-721q-8 0-15 1t-14 3l-46-46q16-9 35-13.5t40-4.5q66 0 108 42t42 108q0 21-4.5 40T490-556ZM364-220Zm23-440Z" /></svg>
                <p style="color: #9e9e9e; font-weight: 600;">No {{componentName}} Assigned </p>
            </div>
        </div>
        <div *ngIf='Response_State===-1'>
            <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; height: 190px;">
                <p style="color: #ff3232; font-weight: 600;">ERROR: {{errorResponse}}</p>
            </div>
        </div>
        <div *ngIf='Response_State===-2' style=" padding: 20px !important;">
            <div class="blackbg card__desc">
                <div *ngFor="let _ of [].constructor(6)" class="card__desc-text"></div>
            </div>
        </div>
    </div>
    <div class="button">
        <button (click)="addUserTrigger()" type="submit" class="btn button-color-fix pull-right buttonfix">
            Add {{componentName}}s
            <mat-icon class="icon-color-fix">add</mat-icon>
        </button>
    </div>
</div>