import { Component, Injectable, Input, Output, EventEmitter, OnChanges, OnInit, SimpleChanges, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Utils } from "../../utils/utils";
import { DialogService } from "../../services/dialog.service";
import { ProgressBarMode } from "@angular/material/progress-bar";
import { ThemePalette } from "@angular/material/core";
//import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';

@Injectable()
//@Injectable({
//    // we declare that this service should be created
//    // by the root application injector.
//    providedIn: 'root'
//})

@Component({
    selector: "alert-confirm",
    templateUrl: './alert-confirm.component.html',
    styleUrls: ['../../style/form.component.less']
})

export class AlertConfirmComponent implements OnChanges {
    SUCCESS: number = Utils.getSuccessValue();
    ERROR: number = Utils.getErrorValue();
    WARNING: number = Utils.getWarningValue();
    INFO_BACKGROUND: number = Utils.getInfoBackgroundValue();
    CONFIRM: number = Utils.getConfirmValue();

    @Input('title') title: string = "";
    @Input('message') message: string = "";
    @Input('type') type: number = this.SUCCESS;
    @Input('className') classname: string = "";
    @Input('items') items: string[] = [];
    @Input('progressValue') progressValue: number = -1;
    @Input('showTypeIcon') showTypeIcon: boolean = true;
    @Input('text_icon_items') textIconItems: TextIconItem[] = [];


    mode: ProgressBarMode = "determinate"
    color: ThemePalette = "primary"
    //title: string | undefined = "";
    //message: string | undefined = "";
    //type: number = this.SUCCESS;
    //classname: string | undefined;

    @Output() onClickOk = new EventEmitter<any>();
    @Output() onClickYes = new EventEmitter<any>();
    @Output() onClickNo = new EventEmitter<any>();

    //classdialog: string = Utils.getDialogHideClassName();

    constructor(
        private activatedRoute: ActivatedRoute
        , private router: Router
        , private http: HttpClient
        , private dialog: DialogService
        , private cdRef: ChangeDetectorRef
    ) {

    }

    ngOnInit() {
        //this.classname = this.dialog.classdialog;
        ////this.classdialog = this.dialog.classdialog;

        ////[message] = "dialog.message"[title] = "dialog.title"[className] = "dialog.classdialog"[type] = "dialog.type"
        //this.message = this.dialog.message;
        //this.title = this.dialog.title;
        //this.type = this.dialog.type;


        //this.onClickOk. this.dialog.onOk;
        //this.dialog
    }
    ngOnChanges(changes: SimpleChanges) {

        if (changes['type']) {
            const previousValue = changes['type'].previousValue;
            const currentValue = changes['type'].currentValue;
            if (previousValue === 4 && currentValue !== 4) {
                this.progressValue = -1;
            }
        }
    }
    ngAfterViewChecked() {
        this.cdRef.detectChanges();
    }

    onNo() {
        this.onClickNo.emit();
    }

    onOk() {
        this.onClickOk.emit();
    }

    onYes() {
        this.onClickYes.emit();
    }

    //setTitle(value: string) {
    //    this.title = value;
    //}

    //setClassDialog(value: string) {
    //    this.classdialog = value;
    //}

    //setClassName(value: string) {
    //    this.classname = value;
    //}
}