<div class='left-menu'>
    <ul class='nav navbar-nav'>
        <li [routerLinkActive]="['link-active']" *ngIf="!defaults.isReadonlyUser && (defaults.isDomainExpertUser || defaults.isadminuser || defaults.isExternalUser) && showMenuViewValue === 0">
            <a class='menu-callback' (click)="onNewTestCase()" title="Create a new Test Case">
                <span class='glyphicon glyphicon-plus-sign'></span> New Test Case
            </a>
        </li>
        <li [routerLinkActive]="['link-active']" *ngIf="!defaults.isReadonlyUser && (defaults.isDomainExpertUser || defaults.isadminuser || defaults.isExternalUser) && showMenuViewValue === 1">
            <a class='menu-callback' (click)="onNewDatabricksTrigger()" title="Create a new Databricks Trigger">
                <span class='glyphicon glyphicon-plus-sign'></span> New Databricks Trigger
            </a>
        </li>
        <li class="separator" *ngIf="!defaults.isReadonlyUser && (defaults.isDomainExpertUser || defaults.isadminuser || defaults.isExternalUser)"></li>
        <li [routerLinkActive]="['link-active']">
            <a class='menu-callback' target="_blank" href="https://tkebbxdev.visualstudio.com/E2E%20MAX/_wiki/wikis/E2E-MAX.wiki/229/Rulebook-Editor?anchor=2.1.3-test-cases-definition" title="How to create a Test Case?">
                <span class='glyphicon glyphicon-info-sign'></span> Documentation
            </a>
        </li>
        <li [routerLinkActive]="['link-active']">
            <a class='menu-callback' (click)="onRefresh.emit()" title="Refresh">
                <span class='glyphicon glyphicon-refresh'></span> Refresh
            </a>
        </li>
        <li class="separator" [ngClass]="saveService.classSaveButton" *ngIf="!defaults.isReadonlyUser && (defaults.isDomainExpertUser || defaults.isadminuser || defaults.isExternalUser)"></li>
        <li [routerLinkActive]="['link-active']" [ngClass]="saveService.classSaveButton" *ngIf="!defaults.isReadonlyUser">
            <a class='menu-callback' (click)="saveService.fireSave()" title="Save all Error Code(s) and Resolution(s)">
                <span class='glyphicon glyphicon-floppy-disk'></span> Save
            </a>
        </li>
    </ul>
</div>