<div style="display:flow-root">
    <div style="float:left">
        <h5>This is the EMT Product Usage management screen. </h5>
    </div>
</div>
<div class="flex-center">
    <div class="contentWidth">
        <div style="height: calc(90vh - 100px); overflow-y: auto;">
            <form [formGroup]="PUForm" autocomplete="off">
                <ng-container formArrayName="PURows">
                    <table #objTable
                           mat-table
                           matSort
                           [dataSource]="dataSource"
                           class="table-add-butt-header-orange mat-elevation-z8 mat-table-custom">

                        <ng-container matColumnDef="headertitle">
                            <th class="title-col" mat-header-cell *matHeaderCellDef colspan="1">
                                <div class="pull-left">
                                    <h4>Product Usage</h4>
                                </div>
                            </th>
                        </ng-container>
                        <ng-container matColumnDef="headeraddbutton">
                            <th class="butt-col" mat-header-cell *matHeaderCellDef>
                                <div class="OrangeButton">
                                    <button mat-raised-button (click)="addNewRow()"
                                            matTooltip="Add Usage" class="button-color-fix pull-right">
                                        <mat-icon class="icon-color-fix icon-padding-fix">add_box</mat-icon>
                                    </button>
                                </div>
                            </th>
                        </ng-container>

                        <!-- Name Column -->
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef style="width:60%">Name</th>
                            <td mat-cell *matCellDef="let element; let i = index" style="width: 60%; height: 25px !important">
                                <div [innerHTML]="PUForm?.get('PURows')?.value[i].name" *ngIf="!PUForm?.get('PURows')?.value[i]?.isNewRow"></div>
                                <mat-form-field *ngIf="PUForm?.get('PURows')?.value[i]?.isNewRow">
                                    <input matInput type="text"
                                           [ngModelOptions]="{standalone: true}"
                                           [(ngModel)]="PUForm?.get('PURows')?.value[i].name"
                                           maxlength="30"
                                           matTooltip="Max 30 characters" />
                                </mat-form-field>
                            </td>
                        </ng-container>
                        <!-- Editable Column -->
                        <ng-container matColumnDef="editable">
                            <th mat-header-cell *matHeaderCellDef style="width:20%"> Is Configurable </th>
                            <td mat-cell *matCellDef="let element; let i = index" style="width: 20%; height: 25px !important">
                                <mat-checkbox [checked]="PUForm?.get('PURows')?.value[i].configurable"
                                              (change)="changeIsConfigurable(i, $event.checked)"
                                              [ngClass]="!PUForm.get('PURows')?.value[i]?.isEditable ? 'black' :''"
                                              [disabled]="PUForm.get('PURows')?.value[i]?.isEditable"></mat-checkbox>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef style="width:10%"></th>
                            <td mat-cell
                                *matCellDef="let element; let i = index" style="width: 10%; height: 25px !important">

                                <button mat-icon-button
                                        *ngIf="!PUForm.get('PURows')?.value[i]?.isEditable"
                                        matTooltip="Cancel Changes"
                                        color="warn"
                                        (click)="resetRow(i)">
                                    <mat-icon class="actionButtons">rotate_left</mat-icon>
                                </button>

                                <button mat-icon-button
                                        *ngIf="false"
                                        matTooltip="Edit"
                                        color="primary"
                                        (click)="editRow(i)">
                                    <mat-icon class="actionButtons">edit</mat-icon>
                                </button>

                                <button mat-icon-button
                                        *ngIf="false"
                                        matTooltip="Delete"
                                        color="warn"
                                        (click)="deleteRow(i)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="savefooter">
                            <th mat-footer-cell *matFooterCellDef colspan="2">
                                <div class="OrangeButton">
                                    <button mat-raised-button type="submit" (click)="onSave()"
                                            [disabled]="!anyChange()"
                                            [matTooltip]="anyChange()? 'Save changes' : 'Save disabled'"
                                            class="button-color-fix pull-right">
                                        Save
                                        <mat-icon style="color:white !important" class="icon-color-fix">save</mat-icon>
                                    </button>
                                </div>
                            </th>

                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="['headertitle', 'headeraddbutton']; sticky: true" class="stickyTopz100"></tr>
                        <tr class="col-headers" mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; let i = index; columns: columnsToDisplay;" [class.alternative2]="!PUForm.get('PURows')?.value[i]?.isEditable"></tr>
                        <tr mat-footer-row *matFooterRowDef="['savefooter']; sticky: true"></tr>
                    </table>
                </ng-container>
            </form>
        </div>
    </div>
</div>