import { Component, Inject, OnInit, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Utils } from "../../utils/utils";
import { DefaultValuesService } from "../../services/defaultvalues.service";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SaveService } from "../../services/save.service";
import { DialogService } from "../../services/dialog.service";

@Component({
    selector: "testcase-form-copy",
    templateUrl: './testcase-form-copy.component.html',
    styleUrls: ['../../style/form.component.less', './testcase-list.component.less','../../style/custom-mat-dialog.component.less',]
})

export class TestCaseFormCopyComponent implements OnInit {
        
    copytescase = {} as TestCase;

    title: string;
    
    result = "";

    form: FormGroup = new FormGroup({});

    constructor(
        private activatedRoute: ActivatedRoute
        , private dialog: DialogService
        , private router: Router
        , public defaults: DefaultValuesService
        , private http: HttpClient
        , private saveService: SaveService
        , public matDialog: MatDialog
        , @Inject(MAT_DIALOG_DATA) public data: TestCase
        , @Inject('BASE_URL') private baseUrl: string)
    {

        this.title = "Copy Test Case '" + data.Name + "' to the ControllerType that you select";
        this.copytescase.TestCaseID = data.TestCaseID;
        this.createForm();
    }

    ngOnInit() {
        this.createForm();
        this.saveService.addListener(this);
    }

    onCancelForm($event: any) {
        $event.preventDefault()
        this.saveService.removeListener(this);
        this.saveService.fireCancel();
    }

    onSubmit() {
        if (this.form && this.form.valid) {

            this.copytescase.ControllerTypeID = this.form.controls['CT'].value;
            const tthis = this;
            const url = this.baseUrl + Utils.getTestCaseAPI() + "copy";
            this.http
                .put<TestCase>(url, this.copytescase)
                .subscribe(
                    res => {
                        tthis.matDialog.closeAll();
                    }
                    , error => {
                        tthis.matDialog.closeAll();
                        tthis.dialog.showErrorDialog(error);
                    }
                );
        }
    }

    private createForm() {
        this.form = new FormGroup({
            CT: new FormControl('', [Validators.required]),
        });
    }

    onNoClick(): void {
        this.matDialog.closeAll();
    }
    onCancel() {
        this.matDialog.closeAll();
    }

}