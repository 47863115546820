import { Component, Inject, OnInit, Input } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Utils } from "../../utils/utils";
import { DialogService } from "../../services/dialog.service";
import { SaveService } from "../../services/save.service";

//const WEIGHT = [{ value: 0, key: '0' }, { value: 1, key: '1' }, { value: 2, key: '2' }, { value: 3, key: '3' }];

@Component({
    selector: "maintenance-control-plan-group-new",
    templateUrl: './mcp-new-group.component.html',
    styleUrls: ['../../style/form.component.less']
})

export class MaintenanceControlPlanGroupNewComponent implements OnInit {
    @Input() header: any[] = [];
    @Input() mcp: MaintenanceControlPlan = <MaintenanceControlPlan>{};
    @Input() list: MaintenanceControlPlanGroup[] = [];
    @Input() rowIndex: number = -1;
    @Input() newRow: boolean = true;

    TITLE: number = 0;
    ISLOBBY: number = 1;

    title: string | undefined;

    selected: MaintenanceControlPlanGroup = <MaintenanceControlPlanGroup>{};

    classname: String[] = [];
    isundodisabled: boolean[] = [];

    form!: FormGroup;

    constructor(
        private http: HttpClient
        , private fb: FormBuilder
        , @Inject('BASE_URL') private baseUrl: string
        , private dialog: DialogService
        , private saveService: SaveService
    ) {
    }

    private onFieldChange(index: number, field1: any, field2: any): boolean {
        return this.saveService.onFieldChange(index, field1, field2, this);
    }

    private onFieldUndo(index: number) {
        this.saveService.onFieldUndo(index, this);
    }

    ngOnInit() {
        this.dialog.tthis = this;
        this.saveService.addListener(this);
        this.saveService.initUndo(this); //it calls the method this.createForm()

        if (!this.newRow) {
            this.selected.MCPGroup = this.list[this.rowIndex].MCPGroup;
            this.selected.IsLobby = this.list[this.rowIndex].IsLobby;

            this.updateForm();
        }

    }

    createForm() {
        this.form = this.fb.group({
            Title: ['', Validators.required]
            , Lobby: ['']
        });
    }

    onTitleChange() {
        if (this.form && this.onFieldChange(this.TITLE, this.form.value.Title, this.selected.MCPGroup) && this.selected)
            this.list[this.rowIndex].MCPGroup = this.form.value.Title;
    }

    onTitleUndo() {
        this.list[this.rowIndex].MCPGroup = this.selected.MCPGroup;
        this.onFieldUndo(this.TITLE);
    }

    onIsLobbyChange() {
        if (this.form && this.onFieldChange(this.ISLOBBY, this.form.value.Lobby, this.selected.IsLobby) && this.selected)
            this.list[this.rowIndex].IsLobby = this.form.value.Lobby;
    }

    onIsLobbyUndo() {
        this.list[this.rowIndex].IsLobby = this.selected.IsLobby;
        this.onFieldUndo(this.ISLOBBY);
    }

    onRowCancel() {
        this.saveService.fireCancel(this.rowIndex, this);
    }

    onSave() {
        if (this.form && this.form.valid) {
            if (this.newRow) {
                this.selected.MCPID = this.mcp.MCPID;
                this.selected.MCPGroup = this.form.value.Title;
                this.selected.IsLobby = this.form.value.Lobby != "" ? true : false;

                this.http
                    .put<MaintenanceControlPlanGroup>(
                        this.baseUrl + Utils.getMaintenanceControlPlanAPI() + "addgroup"
                        , this.selected
                    )
                    .subscribe(
                        res => this.list.unshift(res)
                        , error => this.dialog.showErrorDialog(error)
                    );
            }
            else {
                this.http
                    .post<MaintenanceControlPlanGroup>(
                        this.baseUrl + Utils.getMaintenanceControlPlanAPI() + "editgroup"
                        , this.list[this.rowIndex]
                    )
                    .subscribe(
                        res => this.list[this.rowIndex] = res
                        , error => this.dialog.showErrorDialog(error)
                    );
            }
        }
        else
            this.dialog.showErrorStringDialog("Error Saving data!");
    }

    updateForm() {
        if (this.form) {
            var e = {
                Title: this.list[this.rowIndex].MCPGroup
                , Lobby: this.list[this.rowIndex].IsLobby
            };
            this.form.setValue(e);
        }
    }
}