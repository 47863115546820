<form [formGroup]="form">
    <table>
        <tbody class="inner">
            <tr>
                <td>&nbsp;</td>
                
                <td colspan="2" style="width:250px;">
                    <strong>{{list[rowIndex].MaintenanceFailure_Ref}}</strong><br />
                    <span><small>{{selected.MaintenanceFailure}}</small></span><br />
                    <textarea id="MaintenanceFailure"
                              formControlName="MaintenanceFailure" required
                              title="{{header[MAINTENANCEFAILURE].label}}"
                              [ngClass]="classname[MAINTENANCEFAILURE]"
                              placeholder="enter the maintenance failure..."
                              (change)="onMaintenanceFailureChange($event)"
                              class="form-control">
                    </textarea>
                    <button type="button" class="btn btn-default" title="Undo changes" [disabled]="isundodisabled[MAINTENANCEFAILURE] == true" (click)="onMaintenanceFailureUndo()">
                        <span class='glyphicon glyphicon-arrow-left'></span>
                    </button>
                </td>
                
                <td colspan="2" style="width:250px;">
                    <strong>{{list[rowIndex].TroubleShootingAction_Ref}}</strong><br />
                    <span><small>{{selected.TroubleShootingAction}}</small></span><br />
                    <textarea id="TroubleshootingAction"
                              formControlName="TroubleshootingAction" required
                              placeholder="enter the troubleshooting action..."
                              title="{{header[TROUBLESHOOTINGACTION].label}}"
                              [ngClass]="classname[TROUBLESHOOTINGACTION]"
                              (change)="onTroubleShootingActionChange($event)"
                              class="form-control">
                    </textarea>
                    <button  type="button" class="btn btn-default" title="Undo changes" [disabled]="isundodisabled[TROUBLESHOOTINGACTION] == true" (click)="onTroubleShootingActionUndo()">
                        <span class='glyphicon glyphicon-arrow-left'></span>
                    </button>
                </td>
                <td>
                    <button type="button"
                            class="btn btn-warning"
                            (click)="onRowCancel()">
                        Cancel
                    </button>
                </td>

            </tr>
            </tbody>
        </table>
    </form>






















                    <!--<div class="container-tab-lists" *ngIf="form">
            <div class="form-edit" [ngClass]="classname">
                <h2>{{title}} in Error Code for {{defaults.controllertype.Description}} in {{defaults.language.Name}}</h2>
                <p *ngIf="editMode">{{errorcode.Description}} (Error Code: {{errorcode.ErrorCode}})</p>
                <form [formGroup]="form" (ngSubmit)="onSubmit()">

                    <div class="form-group">
    </textarea>
                        <label for="TroubleshootingAction">Troubleshooting Action:</label>
                    </div>

                    <div class="form-group commands">
                        <button type="submit"
                                [disabled]="form.invalid"
                                class="btn btn-success">
                            Update Translation!
                        </button>
                        <button type="submit"
                                title="Back"
                                (click)="onBack()"
                                class="btn btn-default">
                            Cancel
                        </button>
                    </div> <!--form-group commands-->
                    <!--                             </form> -->
                    <!--</div>--> <!--form-edit-->
                    <!--</div>-->
