<div class="container-tab-lists">
    <div class="inner-tcontainer-tab">
        <h2>{{title}}</h2>

        <mat-tab-group>
            <!--Messages-->
            <mat-tab>
                <ng-template mat-tab-label>
                    Controller type
                </ng-template>
                <div>
                    <div class="tab-body">
                        <div class="tab-content">
                            <controllertypeharmonization-list></controllertypeharmonization-list>
                        </div>
                    </div>
                </div>
            </mat-tab>

            <mat-tab>
                <ng-template mat-tab-label>
                    Asset type
                </ng-template>
                <div>
                </div>
            </mat-tab>

            <mat-tab>
                <ng-template mat-tab-label>
                    Equipment type
                </ng-template>
                <div>
                </div>
            </mat-tab>

        </mat-tab-group>


    </div> 
</div> 