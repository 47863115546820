import { Component, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DefaultValuesService } from "../../services/defaultvalues.service";
import { Router } from "@angular/router";

@Component({
    selector: "digitalservices",
    templateUrl: './digitalservices.component.html'
})

export class DigitalServicesComponent {
    title = "Error Codes";

    constructor(public defaults: DefaultValuesService
    ) { }
}
