import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from '../../../../../../services/dialog.service';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup } from '@angular/forms';
import { Utils } from '../../../../../../utils/utils';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'importexcellencelevers-popup',
    templateUrl: './importexcellencelevers-popup.component.html',
    styleUrls: ['./importexcellencelevers-popup.component.less',
        '../../../../../../style/new-generic-styles.component.less'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*', })),
            transition('expanded => collapsed', [animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
        ]),
    ]
})
export class ImportExcellenceLeversPopup implements OnInit {

    form = new FormControl();
    list: Wave[] = []
    constructor(
        private http: HttpClient
        , public dialogRef: MatDialogRef<ImportExcellenceLeversPopup>
        , private confimDialog: DialogService
        , private toastrService: ToastrService,
        @Inject('BASE_URL') private baseUrl: string,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit(): void {
        this.getWaves()
    }

    getWaves() {
        Utils.httpGetWaves(
            this.http,
            this.baseUrl,
            this,
            function (tthis: ImportExcellenceLeversPopup, list: any) {                
                tthis.list = list.filter((wave: Wave) => wave.WaveId !== tthis.data.WaveId)
            },
            null)
    }

    backButtonHandler(event: any) {
        event.preventDefault();
        this.dialogRef.close();

    }
    addButtonHandler(waveId) {
        Utils.httpImportExcellenceLevers(
            this.http,
            this.baseUrl,
            waveId,
            this.data.WaveId,
            this,
            function (tthis: ImportExcellenceLeversPopup, list: any) {
                tthis.toastrService.success('Excellence Levers imported successfully');
                tthis.dialogRef.close();
            },
            function (tthis: ImportExcellenceLeversPopup, error: any) {
                tthis.toastrService.error('Error importing Excellence Levers');
            }
        )
    }
}
