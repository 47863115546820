
<mat-form-field class="search-input">
    <input placeholder="Filter"
           matInput
           [matChipInputFor]="chipList"
           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
           [matChipInputAddOnBlur]="addOnBlur"
           (matChipInputTokenEnd)="applyFilter($event)">
    <mat-chip-list #chipList>
    </mat-chip-list>
</mat-form-field>

<div style="clear:both;float:left;">
    <mat-chip *ngFor="let item of searchItems" [selectable]="selectable"
              [removable]="removable" (removed)="remove(item)">
        {{item.name}}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
</div>
<div style="clear:both;float:right;">
    <!--<FPErrorCodeMenu (newEvent)="onNew()" (onReload)="onReload()"></FPErrorCodeMenu>-->

    <div class='left-menu'>
        <ul class='nav navbar-nav'>
            <li >
                <a class='menu-callback'  (click)="onAdd()" title="Add New Rule">
                    <span class='glyphicon glyphicon-plus-sign'></span> Add New Rule
                </a>
            </li>
        </ul>
    </div>


    <!--<div class='left-menu'>
        <ul class='nav navbar-nav'>
            <a class='menu-callback' title="Add new fingerprint rule definition" (click)="onAdd()">
                <span class='glyphicon glyphicon-plus-sign'></span> Add new Rule
            </a>
        </ul>
    </div>-->
            <!--<button mat-icon-button matTooltip="Add new"
                (click)="onAdd()">
            <mat-icon>add</mat-icon>
        </button>-->

    </div>
<div class="example-container">
    <table mat-table
           [dataSource]="dataSource" multiTemplateDataRows id="fpruleserrorcodeproperties"
           class="mat-elevation-z8 mat-table-custom">
        <ng-container matColumnDef="arrow">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element"
                (click)="pushPopElement(element, false)">
                <span class="material-icons" *ngIf="checkExpanded(element, false); else show_expand_more">
                    expand_less
                </span>
            </td>

        </ng-container>
        <ng-container matColumnDef="arrow-device">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <div [hidden]="this.defaults.controllertype.BK !== 'CT1001'"
                     (click)="pushPopElement(element, true)">
                    <span class="material-icons" *ngIf="checkExpanded(element, true); else show_devices_expand_more">
                        expand_less
                    </span>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="Mode">
            <th mat-header-cell *matHeaderCellDef> Operation Mode </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.Mode"></p>
            </td>
        </ng-container>


        <ng-container matColumnDef="BuildingType">
            <th mat-header-cell *matHeaderCellDef> Building Type </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.BuildingType"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="TrafficPattern">
            <th mat-header-cell *matHeaderCellDef> Traffic Pattern </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.TrafficPattern"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="IsWarning">
            <th mat-header-cell *matHeaderCellDef> Is Warning </th>
            <td mat-cell *matCellDef="let element">
                <!--<p [innerHTML]="element.IsWarning"></p>-->
                <mat-checkbox [checked]="element.IsWarning" [disabled]="true"></mat-checkbox>
            </td>
        </ng-container>


        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let element">
                <ng-container>
                    <button mat-icon-button matTooltip="Edit Row" (click)="onRowEditInit(element)" *ngIf="!isEditingRow(element)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="Delete" *ngIf="!isEditingRow(element)"
                            (click)="onDelete(element)">
                        <mat-icon>delete</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="Cancel Row" *ngIf="isEditingRow(element)" (click)="onRowEditCancel(element)">
                        <mat-icon>rotate_left</mat-icon>
                    </button>
                    <!--*ngIf="checkExpanded(element, false)"-->
                    <button mat-icon-button matTooltip="Add Trigger Condition" (click)="onCreateNewTrigger(element)">
                        <mat-icon>library_add</mat-icon>
                    </button>
                </ng-container>
            </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                <div [@detailExpand]="checkExpanded(element, false) ? 'expanded' : 'collapsed'" *ngIf="element.ErrorCodeResolutions">
                    <table mat-table [dataSource]="element.ErrorCodeResolutions" class="mat-subtable-custom" id="errorcoderesolutions">

                        <ng-container matColumnDef="FingerprintFeature">
                            <th mat-header-cell *matHeaderCellDef> Fingerprint Feature </th>
                            <td mat-cell *matCellDef="let element2">
                                <p [innerHTML]="element2.FingerprintFeature"></p>
                            </td>
                        </ng-container>


                        <ng-container matColumnDef="ThresholdType">
                            <th mat-header-cell *matHeaderCellDef> Threshold Type </th>
                            <td mat-cell *matCellDef="let element2">
                                <p [innerHTML]="element2.ThresholdType"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="ThresholdValue">
                            <th mat-header-cell *matHeaderCellDef> Threshold Value </th>
                            <td mat-cell *matCellDef="let element2">
                                <p [innerHTML]="element2.ThresholdValue"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Environment">
                            <th mat-header-cell *matHeaderCellDef> Environment </th>
                            <td mat-cell *matCellDef="let element2">
                                <p [innerHTML]="element2.EnvironmentType"></p>
                            </td>
                        </ng-container>


                        <ng-container matColumnDef="ChangedAtUtc">
                            <th mat-header-cell *matHeaderCellDef> Changed at UTC </th>
                            <td mat-cell *matCellDef="let element2">
                                <p [innerHTML]="element2._ChangedAtUtc"></p>
                            </td>
                        </ng-container>


                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element2">
                                <button mat-icon-button matTooltip="Edit Row" (click)="onRowResolEditInit(element,element2)" *ngIf="!isEditingResol(element2) && !isCreatingResol(element2)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                                <button mat-icon-button matTooltip="Delete" (click)="onDeleteResol(element,element2)" *ngIf="!isCreatingResol(element2)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                                <button mat-icon-button matTooltip="Cancel Row" (click)="onResolEditCancel(element2)" *ngIf="isEditingResol(element2) || isCreatingResol(element2)">
                                    <mat-icon>rotate_left</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row class="subtableheader" *matHeaderRowDef="subcolumnsToDisplay"></tr>
                        <tr mat-row *matRowDef="let element2; let i2 = index; columns: subcolumnsToDisplay;"
                            [class.alternative2]="isEditingResol(element2)"
                            [class.alternative3]="isCreatingResol(element2)"
                            class="subitem"></tr>
                    </table>
                </div>
            </td>
        </ng-container>

        <!--<ng-container matColumnDef="expandedDetail2">
            <td mat-cell *matCellDef="let element" [attr.colspan]="subcolumnsToDisplay.length">
                <div [@detailExpand]="checkExpanded(element, true) ? 'expanded' : 'collapsed'" [hidden]="this.defaults.controllertype.BK !== 'CT1001'">
                    <table mat-table [dataSource]="element.DESDevices" class="mat-subtable-custom" id="errorcoderesolutions">

                        <ng-container matColumnDef="deviceName">
                            <th mat-header-cell *matHeaderCellDef> DeviceName </th>
                            <td mat-cell *matCellDef="let element2">
                                <p [innerHTML]="element2.DeviceName"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="sentByDevice">
                            <th mat-header-cell *matHeaderCellDef> Is Sent By Device? </th>
                            <td mat-cell *matCellDef="let element2">
                                <mat-checkbox [checked]="element2.SentByDevice" [disabled]="true"></mat-checkbox>
                            </td>
                        </ng-container>

                        <tr mat-header-row class="subtableheader" *matHeaderRowDef="desSubcolumns"></tr>
                        <tr mat-row *matRowDef="let element2; let i2 = index; columns: desSubcolumns;"
                            class="subitem"></tr>
                    </table>
                </div>
            </td>
        </ng-container>-->


        <tr mat-header-row class="tableheader" *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; let i = dataIndex; columns: columnsToDisplay;"
            class="item"
            [class.alternative2]="isEditingRow(element)"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="row-subitem"></tr>
        <!--<tr mat-row *matRowDef="let row; columns: ['expandedDetail2']" class="row-subitem"></tr>-->
    </table>
</div>

<ng-template #show_expand_more>
    <span class="material-icons">
        expand_more
    </span>
</ng-template>


<ng-template #show_devices_expand_more>
    <span class="material-icons" matTooltip="Devices">
        build
    </span>
</ng-template>
