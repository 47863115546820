import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { SaveService } from "../../../services/save.service";
import { DefaultValuesService } from "../../../services/defaultvalues.service";
import { CustomDialogService } from "../../../services/customdialog.service";
import { MatDialog } from "@angular/material/dialog";
import { AddParameterKeyPopupComponent } from "./add-parameterkeypopup/add-parameterkeypopup.component";
import { ParametersConfigPopupComponent } from "./parametersconfigpopup/parametersconfigpopup.component";
import { Utils } from "../../../utils/utils";

@Component({
    selector: "parametersconfig-list",
    templateUrl: './parametersconfig-list.component.html',
    styleUrls: ['./parametersconfig-list.component.less']
})

export class ParametersConfigListComponent implements OnInit {
    @Input('list') list: any[] = [];
    @Input('assetParameterList') assetParameterList: any[] = [];
    @Input('keywords') keywords: string[] = [];

    @Output() onReload = new EventEmitter<any>();
    @Output() onGlobalReload = new EventEmitter<any>();


    columnsToDisplay = ['ParameterConfigKey', 'AssetParameterFunctionality', 'ControllerParameterId', 'Unit', 'ShowCustomer', 'Enabled'];
    notEditableColumns = ['ParameterConfigKey']

    tooltips = {
        'ParameterConfigKey': 'Harmonized key in the MAX project for an asset parameter.',
        'AssetParameterFunctionality': 'Grouping category for a set of existing Parameters.',
        'ControllerParameterId': 'Technical parameter ID in the specific controller.',
        'Unit': 'Measure unit of the parameter value.'
    }

    @ViewChild(MatTable, { static: true }) table!: MatTable<any>;

    public dataSource = new MatTableDataSource<ParameterConfigController>();

    clonedList: { [s: string]: ParameterConfigController } = {};


    constructor(private activatedRoute: ActivatedRoute
        , private router: Router
        , private http: HttpClient
        , private saveService: SaveService
        , public defaults: DefaultValuesService
        , private customDialog: CustomDialogService
        , @Inject('BASE_URL') private baseUrl: string
        , private changeDetectorRefs: ChangeDetectorRef
        , private dialogForm: MatDialog

    ) {
    }

    ngOnChanges() {
        this.dataSource.data = [];
        this.dataSource.data = this.list
    }

    ngOnInit() {
        this.saveService.addListener(this);
    }

    getInput(data: ParameterConfigController, property: string): any {
        return data[property];
    }

    getDataType(property: string) {
        return 'string'
    }

    openCreatePopup() {
        const dialog = this.dialogForm.open(AddParameterKeyPopupComponent, {
            panelClass: 'custom-mat-dialog',
            disableClose: true,
            data: {}
        });
        dialog.afterClosed().subscribe(() => {
            this.onGlobalReload.emit({});
        });
    }

    onRowEditInit(parameter: ParameterConfigController, enable: boolean) {
        const dialog = this.dialogForm.open(ParametersConfigPopupComponent, {
            panelClass: 'custom-mat-dialog',
            disableClose: true,
            data: {
                'parameterKey': parameter.ParameterConfigKey,
                'controllerParameterId': parameter.ControllerParameterId,
                'unit': parameter.Unit,
                'assetParameterFunctionalityId': parameter.AssetParameterFunctionalityId,
                'assetParameters': this.assetParameterList,
                'enabled': enable
            }
        });
        dialog.afterClosed().subscribe(() => {
            this.onGlobalReload.emit({});
        });

    }

    changeElement(object: any, type: string, value: boolean) {
        console.log(object)
        object[type] = value
        this.http
            .post<any>(
                this.baseUrl + Utils.getRemoteActionAPI() + 'parametersconfig', object
            ).subscribe((res) => {
                this.onGlobalReload.emit({});
            }, (error: {
                error: { message: any }
            }) => {
                object.Enabled = !value
                this.customDialog.openDisplayInfoDialog({ title: 'Error', message: error.error.message });
            });
    }

    highlightWithKeywords(text: string) {
        if (text) {
            const matches = text.split(' ')
            const tokens: string[] = []
            matches.forEach(sr => {
                if (this.keywords.findIndex(x => x === sr) > -1) {
                    tokens.push('<span class="orange-keyword"> ' + sr.replace(/</g, '').replace(/>/g, '') + '</span>')
                }
                else
                    tokens.push(sr);
            })
            return tokens.join(' ');
        }
        return null
    }
}