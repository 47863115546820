import { Component, Output, OnInit, OnChanges, Inject, EventEmitter, Input, SimpleChanges, TemplateRef, ElementRef, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Utils } from '../../utils/utils';
import { WaitingService } from '../../services/waiting.service';
import { PagerService } from '../../services/pager.service';
import { SaveService } from '../../services/save.service';
import { DefaultValuesService } from '../../services/defaultvalues.service';
import { FilterService } from '../../services/filter.service';

const UNCLICK = "unclick";
const CLICKED = "clicked";
const BUTTON_SEARCH = "button-search";

@Component({
    selector: 'table-fixed-header-search-collapse',
    templateUrl: './table-fixed-header-search-collapse.component.html',
    styleUrls: ['../../style/list-fixed-header.component.less']
})

export class TableFixedHeaderSearchCollapseComponent implements OnInit, OnChanges, OnDestroy {
    /**
     * html id for table <div>
     */ 
    @Input('id-table') idtable: string = "";

    /**
     * header object to design table controll
     *
     * signature:
     *   {label: string, show: boolean}
     */   
    @Input('header') header: any[] = [];

    /**
     * list of data to fill into the table
     */ 
    @Input('data') data: any[] = [];

    /**
     * selected language
     */ 
    //@Input('language') language: Language = <Language>{};

    /**
     * callback to get value in row, column
     *
     * signature:
     *   function(data:any[], row:number, column:number){}
     */
    @Input('getValue') getDataValue: any | undefined;

    /**
     * callback to get class for cell, column
     * 
     * signature:
     *   function(row:number, column:number, text:string){}
     */
    @Input('getClass') getClassStyle: any | undefined;
    /**
     * edit button label
     * 
     * signature:
     *   function(text:string, column:number){}
     */
    @Input('edit-button-label') editLabel: string = "Edit";

    /**
     * collapsible
     * 
     * true if table is to collapsible
     */
    @Input('selectable') selectable: boolean = true;

    /**
     * input to header search
     * */
    @Input() headerSearch!: TemplateRef<any> | null;

    /**
     * input to external info
     * */
    @Input() rowExpandTemplate!: TemplateRef<any> | null;

    /**
     * input to edit info
     * */
    @Input() rowEditTemplate!: TemplateRef<any> | null;

    /**
     * input to new form
     * */
    @Input() rowNewTemplate!: TemplateRef<any> | null;

    /**
     * index of page to reload
     * */
    @Input() isToPaged: boolean = false;

    /**
     * input to set if row is in edit mode
     * */
    @Input() isRowEditMode: boolean = false;

    /**
     * input for two-way data binding of the expanded array
     * using [()]
     * */
    @Input() expanded: boolean[] = [];

    /**
     * input for two-way data binding of the expanded array
     * using [()]
     * */
    @Input() checked: boolean[] = [];

    @Input() checkedObjects: any[] = [];

    @Input() newService: SaveService | undefined;

    /**
     * events
     */ 
    //@Output() onSearch = new EventEmitter < { ShowRef: boolean, Index: number, Value: any }>();
    //@Output() onReset = new EventEmitter<{}>();
    @Output() onSelect = new EventEmitter<any>();
    //@Output() onEdit = new EventEmitter<any>();
    @Output() onExpand = new EventEmitter<{ index: number, value: any, expanded: boolean, list: boolean[] }>();
    @Output() onChecked = new EventEmitter < { Index: number, Value: any, Checked: boolean, List: boolean[], NumberOfChecks: number, FirstCheckedIndex: number }>();
    @Output() onInitCompleted = new EventEmitter<TableFixedHeaderSearchCollapseComponent>();
    //@Output() onSaveRowEvent = new EventEmitter<{ RowIndex: number, Value: any }>();
    @Output() onDelete = new EventEmitter<{ index: number, value: any }>();
    @Output() onNewChild = new EventEmitter<{ index: number, value: any }>();
    @Output() onRowClick = new EventEmitter<{ index: number, value: any }>();


    filters: any[] = [];
    isToExecuteFilters: boolean = false;
    //headerclass: string [] = [];
    showSearch: boolean = false;
    editrowclass: string[] = [];
    //numberOfColumns: number = 0;

    newRow: boolean = false;

    editing: boolean[] = [];
    
    expandrowclass: string[] = [];
    classform: string = "";

//    filtertoshow: string = "";
    rowIndexInEditMode: number | undefined;

    // pager object
    pager: any = {};
    allItems: any[] = [];

    /**
     * collapsible
     * 
     * true if table is to collapsible
     */
    collapsible: boolean = false;

    constructor(
        private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , private waiting: WaitingService
        , private pagerservice: PagerService
        , private saveService: SaveService
        , public defaults: DefaultValuesService
        , private filtersservice: FilterService 
    ) {        
    }

    /**
     * angular event onInit
     * 
     * */
    ngOnInit() {

        //this.numberOfColumns = this.header.length;

        //if (this.newService)
        //    this.newService.addListener(this);

        //if ()
        this.classform = (this.headerSearch ? Utils.getDialogHideClassName() : "");

        this.collapsible = this.rowExpandTemplate != undefined;

        /**
         * do not show waiting in the beginning, it will work bad
         * //this.waiting.toWait(true);
         * */
        for (var i = 0; i < this.header.length; ++i) {
            //this.headerclass.push(Utils.getDialogHideClassName());
            this.filters.push(""); //this.filters.push([]);
        }

        ///**
        // * fill the edit rows
        // * */
        //for (var i = 0; i < this.data.length; ++i) {
        //    this.isRowInEditMode.push(false);
        //}
        this.onInitCompleted.emit(this);
    }

    /**
     * angular event on data changes
     * 
     * */
    ngOnChanges(changes: SimpleChanges) {
        if (changes['data'] == undefined) return false;
        if (changes['data'] != undefined && changes['data'].currentValue != changes['data'].previousValue) {
            if (this.isToExecuteFilters) {
                //this.onSearchColumns();
                this.isToExecuteFilters = false;
            }

            this.allItems = [];
            this.data.forEach(x => {
                this.editrowclass.push(Utils.getDialogHideClassName());
                this.expandrowclass.push(Utils.getDialogHideClassName());
                this.expanded.push(false);
                this.editing.push(false);
                this.checked.push(false);
                this.allItems.push(x);
            });
            if (this.isToPaged)
                this.setPage(1);//this.setPage(this.pageIndex);
            this.filtersservice.fireInit();
            this.waiting.toWait(false);
        }

        if (this.newService) {
            this.newService.addListener(this);
        }
        return null;
    }

    ngOnDestroy() {
        this.saveService.fireCancelAll();
        this.saveService.classSaveButton = Utils.getDialogHideClassName();
    }

    ///**
    // * private method to create the filter string
    // *   eg: {label} is true; {label} contains {value};
    // * @param value - of the html input tag
    // * @param columnIndex - column index to filter
    // * 
    // * @returns the filter result string for this column index
    // */
    //private createFilterStr(value: any, columnIndex: number): string{
    //    let str = "";
    //    if (value == true && this.header[columnIndex].searchtype == "checkbox")
    //        str = str + this.header[columnIndex].label + " is true; ";
    //    else if (value == false && this.header[columnIndex].searchtype == "checkbox")
    //        str = str + this.header[columnIndex].label + " is false; ";
    //    else
    //        str = str + this.header[columnIndex].label + " contains " + value + "; ";
    //    return str;
    //}

    /**
     * if the method getClassStyle is defined, then call it with row and column indexes, and the current list for that cell
     * 
     * @param row - index of the row to get the class
     * @param column - index of the column to get the class
     * 
     * @returns the class string
     */
    getClass(row: number, column: number): string {
        if (this.getClassStyle)
            return this.getClassStyle(row, column, this.data);
        return "";
    }

    /**
     * check if it is to show the search control in header, return hide class name if not
     * 
     * @param column - index column to get the class
     * 
     * @returns the hide class name, or empty if it is to show the control
     */
    getClassSearchHeader(column: number): string {
        if (this.header && !this.header[column].show)
            return Utils.getDialogHideClassName();
        return "";
    }

    getData(row: number, column: number): string {
        if (this.getDataValue)
            return this.getDataValue(this.data, row, column);
        else
            return "";
    }

    private getFirstIndexChecks(): number {
        var num = this.checked.findIndex(
            function (value: boolean, index: number, obj: boolean[]) {
                return value;
            }
        );
        
        return num;
    }

    private getNumberOfChecks(): number {
        var num = 0;

        this.checked.forEach(ck => {
            if (ck)++num;
        });
        return num;
    }

    //hideAllSearchColumns() {
    //    for (var i = 0; i < this.header.length; ++i)
    //        this.headerclass[i] = Utils.getDialogHideClassName();
    //}

    isRowChecked(row: number): boolean {
        return this.checked[row];
    }

    isToShowReference(): boolean {
        return this.defaults.language.LanguageId != Utils.getDefaultLaguangeID();
    }

    isToShowDeleteButton(): boolean {
        return this.onDelete.observers.length != 0;
    }

    //isToShowEditButton(): boolean {
    //    return this.onEdit.observers.length != 0;
    //}

    isToShowSearchButton(): boolean {
        return this.headerSearch != undefined;
        //return this.onSearch.observers.length != 0;
    }

    isInputChecked(row: number, column: number): boolean {
        var value = this.getData(row, column);
        return value == "1" ? true : false;
    }

    onCancel(index: number) {
        if (index != -1) {
            this.editrowclass[index] = Utils.getDialogHideClassName();
            this.editing[index] = false;            
        }
        else
            this.newRow = false;

        var hideSave = this.editing.findIndex(x => { return x == true; });

        if (hideSave == -1)
            this.saveService.showSaveButton(false); //this.saveService.classSaveButton = Utils.getDialogHideClassName();
    }

    /**
     * called by save service, close all edit dialogs and collapse all rows
     * */
    onCancelAll() {
        this.newRow = false;
        this.editing.fill(false);// forEach(x => { x = false; });
        this.editrowclass.fill(Utils.getDialogHideClassName());// forEach(x => { x = Utils.getDialogHideClassName() });
        this.expanded.fill(false); // forEach(x => { x = false });
        this.expandrowclass.fill(Utils.getDialogHideClassName());
    }

    onCellClick(row: number, column: number) {
        this.onRowClick.emit({ index: row, value: this.data[row] });
    }

    /**
     * event click for check in a cell
     * 
     * @param obj - input checkbox
     * @param row - index of the row
     */
    onCheck(obj: any, row: number) {
        this.checked[row] = obj.target.checked;
        var tthis = this;
        if (this.checked[row]) {
            this.saveService.addCheckedListener(this);
            this.checkedObjects.push(this.data[row]);
        }
        else {
            this.saveService.removeCheckedListener(this);
            var num = this.checkedObjects.findIndex(
                function (value: any, index: number, obj: any[]) {
                    return value == tthis.data[row];
                }
            );
            if (num != -1)
                this.checkedObjects.splice(num, 1);

        }
        this.onChecked.emit({ Index: row, Value: this.data[row], Checked: this.checked[row], List: this.checked, NumberOfChecks: this.getNumberOfChecks(), FirstCheckedIndex: this.getFirstIndexChecks() });
    }

    /**
     * event click, to check in the head, to select all check boxes
     * 
     * @param obj
     */
    onCheckedAll(obj: any) {
        var tthis = this;
        this.checked.forEach(function (value: boolean, index: number, array: boolean[]) {
            tthis.onCheck(obj, index);
            //tthis.checked[index] = obj.target.checked;
        });
    }

    /**
     * event click on expand/collapse button
     * 
     * @param value - index of the clicked row
     */
    onExpandRow(event:any, value: number) {

        //@ViewChild('vertex') element: ElementRef;
        //let rowElement = document.getElementById("row-" + this.idtable + "-" + value.toString());
        //rowElement.append
        
        this.expandrowclass[value] = this.expandrowclass[value] == "" ? Utils.getDialogHideClassName() : "";
        this.expanded[value] = this.expanded[value] == false ? true : false;
        this.onExpand.emit({ index: value, value: this.data[value], expanded: this.expanded[value], list: this.expanded });
    }

    onNew() {
        this.newRow = true;
        this.saveService.addListener(this);
    }

    /**
     * event click on reset column filter button, to reset the filter
     * 
     * @param column - index of the clicked column
     */
    //onResetColumn(column: number) {
    //    this.filters[column] = [];
    //    //this.hideAllSearchColumns();
    //    this.isToExecuteFilters = true; //this.onSearchColumns();
    //    this.onResetList();        
    //}

    //onResetList() {
    //    this.data = this.allItems.slice(0, this.allItems.length);
    //    //this.waiting.toWait(true);
    //    //this.filtertoshow = "";

    //    //this.filters = [];
    //    //for (var i = 0; i < this.header.length; ++i)
    //    //    this.filters.push([]);

    //    this.onReset.emit({});
    //}

    onRowDelete(obj: any, rowindex: number) {
        this.onDelete.emit({ index: rowindex, value:obj });
    }

    onRowEdit(obj: any, rowindex: number) {
        //if (this.onEdit) {
            this.rowIndexInEditMode = rowindex;
            this.editrowclass[rowindex] = "";
            this.editing[rowindex] = true;
            //this.isShowingForm = true;

            this.saveService.addListener(this);

            //this.onEdit.emit(obj);
        //}
        return false;
    }

    onRowNewChild(obj: any, rowindex: number) {
        //this.onNewChild.isEmpty()
        this.onNewChild.emit({ index: rowindex, value: obj });
    }

    //onRowSelect(obj: any) {
    //    if (this.onSelect)
    //        this.onSelect.emit(obj);
    //    return false;
    //}

    //onChangeColumn(event:any, column: number) {
    //    //var elem = <HTMLInputElement>document.getElementById(this.idtable + "-search-header-" + column.toString());
    //    var elem = event.currentTarget;
    //    if (elem.type == "select-one") {
    //        switch (elem.value) {
    //            case "Yes":
    //                this.filters[column] = true; // .push(true);
    //                break;
    //            case "No":
    //                this.filters[column] = false; //.push(false);
    //                break;
    //            default:
    //                break;
    //        }
    //    }
    //    else if (elem.value != "") {
    //        this.filters[column] = elem.value; //.push(elem.value);
    //    }
    //    //this.onSearchColumns();
    //    //this.waiting.toWait(false);
    //}

    //onSave(tthis: any) {
    onSave() {
        var tthis = this;
        this.editing.forEach(
            function (value: boolean, index: number, array: boolean[]) {
                tthis.editrowclass[index] = Utils.getDialogHideClassName();
                tthis.editing[index] = false;
            });
        if (this.newRow) {//if it is a new row, add it to the begining
            this.expanded.unshift(false);
            this.expandrowclass.unshift(Utils.getDialogHideClassName());
        }
        this.newRow = false;
    }

    //onSearchColumns() {
    //    //this.waiting.toWait(true);
    //    var show = this.isToShowReference();
    //    //this.filtertoshow = "";
    //    for (var i = 0; i < this.filters.length; ++i) {
    //        if (this.filters[i] != undefined && this.filters[i] != "") {
    //                //this.filtertoshow = this.filtertoshow + this.createFilterStr(this.filters[i], i);
    //            this.onSearch.emit({ ShowRef: show, Index: i, Value: this.filters[i] });
    //        }
    //    }
    //    //this.hideAllSearchColumns();
    //}

    setPage(page: number) {
        // get pager object from service
        this.pager = this.pagerservice.getPager(this.allItems.length, page);

        // get current page of items
        this.data = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);

        //this.pageIndex = page;
    }

    //showSearchColumn(column: number) {
    //    this.headerclass[column] = "search-div";
    //}

    showSearchForm() {
        this.showSearch = !this.showSearch;
        //if (this.showSearch)
        //    this.data.
    }
}