<div class='left-menu'>
    <ul class='nav navbar-nav mtop-2'>
        <li class="submenu-title">
            <h4>Links</h4>
        </li>
        <li [routerLinkActive]="['link-active']">
            <a class='menu-callback' (click)="openDocumentationModal()" title="Wiki">
                <span class='glyphicon glyphicon-info-sign'></span> Documentation
            </a>
        </li>
        <li class="separator"></li>
    </ul>
</div>