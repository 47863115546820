<assets-menu (onChangeAssetType)="changeAssetType($event)" (outputDownload)="downloadAssetFiltered()" (onReload)="onReload()" [currentType]="currentType"></assets-menu>
<div class="container-tab-lists">
    <div class="inner-tcontainer-tab">
        <div class="tab-body">
            <div class="tab-content">
                <mat-tab-group (selectedTabChange)="changeAssetType($event); defaults.updateComponentTab($event.tab.textLabel)" #tabGroup >
                    <!--Messages-->
                    <mat-tab label="Elevators">
                        <div>
                            <elevator #elevatorComponent></elevator>
                        </div>
                    </mat-tab>
                    <mat-tab label="Escalators">
                        <div>
                            <escalator #escalatorComponent></escalator>
                        </div>
                    </mat-tab>
                    <mat-tab label="Other Assets">
                        <div>
                            <otherasset #otherAssetComponent></otherasset>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>
</div>
