import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { HttpClient } from '@angular/common/http';
import "rxjs/operators";
import { Router } from '@angular/router';
import { DialogService } from '../../../../services/dialog.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DefaultValuesService } from '../../../../services/defaultvalues.service';
import { Utils } from '../../../../utils/utils';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'azdatafactoryList',
    templateUrl: './azdatafactorylist.component.html',
    styleUrls: ['../../../../style/azdatafactory.component.less'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*', })),
            transition('expanded => collapsed', [animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
        ]),
    ]
})

export class AzDataFactoryListComponent implements OnInit {

    @Input('list') list: AzDataFactoryRun[] = [];

    columnsToDisplay = ['index', 'arrow', 'runId', 'startDate', 'endDate', 'status', 'parameters', 'message'];
    subcolumnsToDisplay = ['activityName', 'status', 'output'];

    public dataSource = new MatTableDataSource<AzDataFactoryRun>();
    expandedElements = new Set();

    body: any;
    minDate = new Date();
    maxDate = new Date();
    form!: FormGroup;

    constructor(private http: HttpClient
        , private confimDialog: DialogService
        , private router: Router
        , @Inject(MAT_DIALOG_DATA) public data: any
        , @Inject('BASE_URL') private baseUrl: string
        , public dialogRef: MatDialogRef<AzDataFactoryListComponent>
        , private fb: FormBuilder
        , private defaults: DefaultValuesService) {

        this.confimDialog.tthis = this;
        this.minDate.setDate(this.maxDate.getDate() - 30);
        this.body = { 'startDate': this.minDate, 'endDate': this.maxDate }

    }

    ngOnInit(): void {
        this.dataSource.data = this.data.list
        this.form = this.fb.group({
            pickerFilterStart: [this.minDate],
            pickerEndDate: [this.maxDate]
        });
    }

    onStartChanged($event) {
        this.body.startDate = $event.target.value.toDate()
        let temp = new Date(this.body.startDate.getTime());
        temp.setDate(this.body.startDate.getDate() + 30)
        if (temp < this.body.endDate) {
            this.body.endDate = temp
        }
        if (this.body.startDate > this.body.endDate)
            this.body.endDate = this.body.startDate
    }

    onEndChanged($event) {
        this.body.endDate = $event.target.value.toDate()
        let temp = new Date(this.body.endDate.getTime());
        temp.setDate(this.body.endDate.getDate() - 30)
        if (temp > this.body.startDate) {
            this.body.startDate = temp
        }
        if (this.body.startDate > this.body.endDate)
            this.body.startDate = this.body.endDate
    }

    onFilter() {
        Utils.httpGetLastPipelineRunsAzdf(
            this.http,
            this.baseUrl,
            this.data.entity,
            this.data.type,
            this.body.startDate,
            this.body.endDate,
            this,
            function (tthis: any, list: AzDataFactoryRun[]) {
                tthis.dataSource.data = list
            })
    }

    ngOnChanges() {
        this.expandedElements.clear();
    }

    checkExpanded(element: AzDataFactoryRun) {
        return this.expandedElements.has(element);
    }

    pushPopElement(element: AzDataFactoryRun) {
        if (this.expandedElements.has(element)) {
            this.expandedElements.delete(element);
        }
        else {
            this.expandedElements.add(element);
            this.onExpand(element)
        }
    }

    onExpand(element: AzDataFactoryRun) {
        Utils.httpGetActivitiesByRunId(
            this.http
            , this.baseUrl
            , this.data.entity
            , element.RunId
            , this
            , function (tthis: any, list: AzDataFactoryActivity[]) {
                element.Activities = list;
                tthis.expandedElements.add(element);
            }
        );
    }

    onBack() {
    }
    onNoClick(): void {
        this.dialogRef.close();
    }
}


