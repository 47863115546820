import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { SaveService } from '../../../../services/save.service';
import { Utils } from '../../../../utils/utils';
import { DefaultValuesService } from '../../../../services/defaultvalues.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Subject } from "rxjs";

@Component({
    selector: "contractbundlelist",
    templateUrl: './contractbundlelist.component.html',
    styleUrls: ['./contractbundlelist.component.less', '../../../../style/mat-table.component.less'],

    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*', })),
            transition('expanded => collapsed', [animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
        ]),
    ]
})

export class ContractBundleListComponent implements OnInit {
    @Input('list') list: ContractBundle[] = [];
    @Output() onReload = new EventEmitter<{}>();

    columnsToDisplay = ['arrow','bundle', 'actions'];
    subcolumnsToDisplay = ['feature'];

    @ViewChild(MatTable, { static: true }) table!: MatTable<any>;

    public dataSource = new MatTableDataSource<ContractBundle>();

    filter: string = '';

    subscriptionLanguage: any;
    subscriptionTenant: any;

    isPrimaryContract = '-1'
    expandedElements = new Set();

    protected _onDestroy = new Subject<void>();
    constructor(private http: HttpClient
        , private saveService: SaveService
        , private defaults: DefaultValuesService
        , @Inject('BASE_URL') private baseUrl: string
    ) {
    }

    ngOnChanges() {
        this.dataSource.data = this.list
        this.saveService.showSaveButton(false)
        this.saveService.addListener(this)
    }

    ngOnInit() {
        this.getHeader();
        this.subscriptionTenant = this.defaults.tenantMessageChanges$.subscribe(() => {
            this.getHeader()
        });
        this.saveService.addListener(this);
    }

    ngOnDestroy() {
        this.saveService.removeListener(this);
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    getHeader() {
        if (!this.defaults.isReadonlyUser)
            this.columnsToDisplay = ['arrow', 'bundle', 'actions'];
        else
            this.columnsToDisplay = ['bundle'];
    }

    pushPopElement(element: ContractBundle) {
        if (this.expandedElements.has(element.Bundle)) {
            this.expandedElements.delete(element.Bundle);
        }
        else {
            this.expandedElements.add(element.Bundle);             
        }
    }

    checkExpanded(element: ContractBundle) {
        return this.expandedElements.has(element.Bundle);
    }

    deleteBundle(bundleId: number) {
        Utils.httpDeleteContractBundleByTenant(
            this.http
            , this.baseUrl
            , bundleId
            , this
            , function (tthis: ContractBundleListComponent) {
                tthis.onReload.emit({})
            }
        );
    }
}