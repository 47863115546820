import { Component, Inject, EventEmitter, Output, OnInit, OnDestroy, Input } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Utils } from "../../../utils/utils";
import { SaveService } from "../../../services/save.service";
import { MatDialog } from "@angular/material/dialog";
import { DialogService } from "../../../services/dialog.service";
import { DefaultValuesService } from "../../../services/defaultvalues.service";
import { ConnectionTypeFormComponent } from "./connectiontype-form.component";

@Component({
    selector: "connectiontype-list",
    templateUrl: './connectiontype-list.component.html',
    styleUrls: ['../../../style/list.component.less', 'controllertypes-list.component.less']
})

export class ConnectionTypeListComponent implements OnInit, OnDestroy {
    @Input() selected: ControllerConfig = {} as ControllerConfig;

    list: ControllerTypeToConnectionType[] = [];


    header: any[] = [
        { label: 'Connection Type', show: true, title: "Connection Type attached to the selected Controller Type" }
    ];

    subscriptionControllerType: any;

    constructor(
        private dialogService: DialogService
        , private defaults: DefaultValuesService
        , private saveService: SaveService
        , private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , private dialog: MatDialog
    ) {
    }

    ngOnInit() {
        this.subscriptionControllerType = this.defaults.controllerTypeMessageChanges$.subscribe((item: ControllerType) => this.onControllerChanged(item));
        this.refresh();
    }

    ngOnDestroy() {
        this.subscriptionControllerType.unsubscribe();
    }

    /**
     * called by the SaveService
     * */
    onCancel() {
        this.dialog.closeAll();
    }

    /**
     * called by the DefaultValuesService
     * */
    onControllerChanged(ct: ControllerType) {
        this.refresh();
    }

    /**
     * called by the table list
     * */
    onDelete(value: { index: number, value: ControllerTypeToConnectionType }) {
        this.dialogService.showConfirmDialog("Delete " + value.value.PredefinedItem + " from the " + this.defaults.controllertype.Description + "?", "Delete Connection Type", this, value);
    }

    onNewConnectionType() {
        /**
         * set the tthis on dialgService to the child forms call the getHeader
         **/
        this.dialogService.tthis = this;
        this.saveService.addListener(this);
        const dialogRef = this.dialog.open(ConnectionTypeFormComponent, { panelClass: 'screen-dialog', data: this.selected }); 
        dialogRef.afterClosed().subscribe(result => {
            this.refresh();
        });
    }

    /**
     * called by the SaveService
     * */
    onSave() {
        this.dialog.closeAll();
    }

    /**
     * called by the DialogService
     * */
    onYes(value: { index: number, value: ControllerTypeToConnectionType }) {
        this.http
            .delete<ControllerTypeToConnectionType>(
                this.baseUrl + Utils.getControllerTypeAPI() + 'controllertypetoconnectiontype/' + value.value.ControllerTypeToConnectionTypeID
            )
            .subscribe(
                res => this.list.splice(value.index, 1)
                , error => this.dialogService.showErrorDialog(error)
            );
    }

    /**
     * this is from who calls (inner component)
     * @param list
     * @param row
     * @param column
     */
    public getData(list: ControllerTypeToConnectionType[], row: number, column: number): string {
        if (list) {
            let e = list[row];
            return e.PredefinedItem;
        }
        else
            return "";
    }

    public refresh() {
        //this.onRefresh.emit({});
        /**
         * load all ControllerTypeToConnectionType
         * */
        Utils.httpGetAllControllerTypeToConnectionType(
            this.http
            , this.baseUrl
            , this
            , function (tthis: ConnectionTypeListComponent, values: ControllerTypeToConnectionType[]) {
                tthis.list = values;
            }
        );
    }
}