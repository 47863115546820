import { Component, Inject, OnInit } from "@angular/core";
import { Location } from '@angular/common';
import { HttpClient } from "@angular/common/http";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DialogService } from "../../services/dialog.service";
import { DefaultValuesService } from "../../services/defaultvalues.service";
import { WaitingService } from "../../services/waiting.service";
import { Utils } from "../../utils/utils";

@Component({
    selector: "new-language",
    templateUrl: './new-language.component.html',
    styleUrls: ['../../style/form.component.less']
})

export class NewLanguageComponent implements OnInit {
    form!: FormGroup;

    list: Language[] = [];
    language: Language = <Language>{};

    back: string = "opmodes";

    constructor(
        private location: Location
        , private http: HttpClient
        , private fb: FormBuilder
        , @Inject('BASE_URL') private baseUrl: string
        , public defaults: DefaultValuesService
        , public dialog: DialogService
        , private waiting: WaitingService
    ) {
    }

    ngOnInit() {

        this.dialog.tthis = this;
        /**
         * load all languages to the language controll
         * */
        Utils.httpGetAllLanguages(
            this.http
            , this.baseUrl
            , this
            , function (tthis: any, values: Language[]) {
                tthis.list = values;
                tthis.removeExistingLanguages();
                tthis.createForm();
                tthis.language = tthis.list[0];
                tthis.updateForm();

            }
        );
    }

    createForm() {
        this.form = this.fb.group({
            Language: ['', Validators.required]
        });
    }

    onBack() {
        this.location.back(); //this.router.navigate([this.back]);
    }

    onLanguageChanged(language: Language) {
        this.language = language;
        this.updateForm();
        this.waiting.toWait(false);
    }

    onSubmit() {
        if (this.form && this.form.valid) {
            this.http
                .put<Language[]>(
                    this.baseUrl + Utils.getLanguageAPI() + "add"
                    , this.language
                )
                .subscribe(
                    res => {
                        this.defaults.languages = res;
                        this.defaults.language = this.language
                        this.defaults.notifyLanguageChange(this.defaults.language);
                        this.http
                            .post<Language>(this.baseUrl + Utils.getLanguageAPI() , this.defaults.language)
                            .subscribe(res => {
                                this.defaults.onLanguageChanged.emit(this.defaults.language);
                                this.defaults.notifyLanguageChange(this.defaults.language);
                            }, error => Utils.writeLog(this.http, this.baseUrl, error)); //error => console.log(error));
                        this.onBack();
                    }
                    , error => this.dialog.showErrorDialog(error)
                );
        }
    }



    removeExistingLanguages() {
        var tthis = this;
        this.defaults.languages.forEach(x => {
            var index = tthis.list.findIndex(
                function (value: Language, index: number, obj: Language[]) {
                    return value.LanguageId == x.LanguageId;
                }
            );
            if (index != -1) {
                tthis.list.splice(index, 1);
            }
        });
    }

    updateForm() {
        if (this.form)
            this.form.setValue({
                Language: this.language.LanguageId
            });
    }

}