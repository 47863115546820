<div class="userCacheDialogue">
    <mat-toolbar class="header heading-background">
        <div class="col-lg-6">
            <h4>
                Check Cache Information
            </h4>
        </div>
    </mat-toolbar>
    <div class="container-tab-lists">
        <mat-tab-group animationDuration="500ms">
            <mat-tab *ngFor="let env of cacheInfoList" [label]="env.environment">
                <div class="col-lg-3 ">
                    <h3>Branches</h3>
                </div>
                <div class="col-lg-9 search-container">
                    <div (change)="filterBranches()" (keyup)="filterBranches()" *ngIf="env.BranchesDataSource.data.length > 0">
                        <input class="form-control" type="text" placeholder="Search" [(ngModel)]="searchStringBranches">
                    </div>
                </div>
                <div class="col-lg-12">
                    <div *ngIf="env.BranchesDataSource.data.length > 0">
                        <table mat-table
                               [dataSource]="env.BranchesDataSource"
                               multiTemplateDataRows
                               class="mat-elevation-z8 mat-table-custom">

                            <ng-container matColumnDef="branch">
                                <th mat-header-cell *matHeaderCellDef> Branch </th>
                                <td mat-cell *matCellDef="let element">
                                    <p [innerHTML]="element.BranchNumber"></p>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="tenant">
                                <th mat-header-cell *matHeaderCellDef> Tenant </th>
                                <td mat-cell *matCellDef="let element">
                                    <p [innerHTML]="element.Tenant"></p>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="columnsBranchesToDisplay"></tr>
                            <tr mat-row *matRowDef="let element; let i = dataIndex;columns: columnsBranchesToDisplay;" class="opmode-item">
                            </tr>
                        </table>
                    </div>
                    <div *ngIf='env.BranchesDataSource.data.length === 0'>
                        <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; height: 150px;">
                            <svg xmlns="http://www.w3.org/2000/svg" height="70" viewBox="0 -960 960 960" width="70" style="fill: #9e9e9e ;"><path d="M833-41 678-196v36H38v-94q0-35 18-63.5t50-42.5q73-32 131.5-46T358-420q28 0 54.5 3t52.5 8l-75-75q-8 2-16 2.5t-16 .5q-66 0-108-42t-42-108q0-8 .5-16t2.5-16L26-848l43-43L876-84l-43 43ZM624-422q69 8 129 23t98 35q34 18 52.5 46.5T922-254v94h-36L727-319q-14-35-40.5-60.5T624-422Zm-266 62q-57 0-106.5 11T130-306q-14 6-23 21t-9 31v34h520v-36l-63-63q-59-24-102.5-32.5T358-360Zm178-150q16-23 24-54t8-67q0-45-12.5-79.5T519-774q11-3 24.5-5t24.5-2q66 0 108 42t42 108q0 66-43.5 108T565-481l-29-29Zm-46-46-46-46q2-7 3-14t1-15q0-39-25.5-64.5T358-721q-8 0-15 1t-14 3l-46-46q16-9 35-13.5t40-4.5q66 0 108 42t42 108q0 21-4.5 40T490-556ZM364-220Zm23-440Z" /></svg>
                            <p style="color: #9e9e9e; font-weight: 600;">
                                No Branch info in cache
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 ">
                    <h3>Roles</h3>
                </div>

                <div class="col-lg-9 search-container">
                    <div (change)="filterRoles()" (keyup)="filterRoles()" *ngIf="env.UserRolesDataSource.data.length > 0">
                        <input class="form-control" type="text" placeholder="Search" [(ngModel)]="searchStringRoles">
                    </div>
                </div>
                <div class="col-lg-12">
                    <div *ngIf="env.UserRolesDataSource.data.length > 0">
                        <table mat-table
                               [dataSource]="env.UserRolesDataSource"
                               multiTemplateDataRows
                               *ngIf="env.UserRolesDataSource.data.length > 0"
                               class="mat-elevation-z8 mat-table-custom">

                            <ng-container matColumnDef="item">
                                <th mat-header-cell *matHeaderCellDef> </th>
                                <td mat-cell *matCellDef="let element">
                                    <p [innerHTML]="element.Role"></p>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="columnsRolesToDisplay"></tr>
                            <tr mat-row *matRowDef="let element; let i = dataIndex;columns: columnsRolesToDisplay;" class="opmode-item">
                            </tr>
                        </table>
                    </div>
                    <div *ngIf="env.UserRolesDataSource.data.length === 0">
                        <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; height: 150px;">
                            <svg xmlns="http://www.w3.org/2000/svg" height="70" viewBox="0 -960 960 960" width="70" style="fill: #9e9e9e ;"><path d="M833-41 678-196v36H38v-94q0-35 18-63.5t50-42.5q73-32 131.5-46T358-420q28 0 54.5 3t52.5 8l-75-75q-8 2-16 2.5t-16 .5q-66 0-108-42t-42-108q0-8 .5-16t2.5-16L26-848l43-43L876-84l-43 43ZM624-422q69 8 129 23t98 35q34 18 52.5 46.5T922-254v94h-36L727-319q-14-35-40.5-60.5T624-422Zm-266 62q-57 0-106.5 11T130-306q-14 6-23 21t-9 31v34h520v-36l-63-63q-59-24-102.5-32.5T358-360Zm178-150q16-23 24-54t8-67q0-45-12.5-79.5T519-774q11-3 24.5-5t24.5-2q66 0 108 42t42 108q0 66-43.5 108T565-481l-29-29Zm-46-46-46-46q2-7 3-14t1-15q0-39-25.5-64.5T358-721q-8 0-15 1t-14 3l-46-46q16-9 35-13.5t40-4.5q66 0 108 42t42 108q0 21-4.5 40T490-556ZM364-220Zm23-440Z" /></svg>
                            <p style="color: #9e9e9e; font-weight: 600;">
                                No Role info in cache
                            </p>
                        </div>
                    </div>
                </div>
            </mat-tab>

        </mat-tab-group>

        <br/>
        <br/>
        <!--<div class="panel-body">

            <button (click)="backButtonHandler()" mat-raised-button class="btn  button-color-fix_red ">
                <mat-icon class=" icon-color-fix ">arrow_back_ios</mat-icon>
                Back
            </button>

        </div>-->
    </div>
</div>

