import { Component, Inject, OnInit, OnDestroy, Input, ViewChild, Output, EventEmitter, OnChanges } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Utils } from "../../../../utils/utils";
import { DefaultValuesService } from "../../../../services/defaultvalues.service";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { DialogService } from "../../../../services/dialog.service";
import { SaveService } from "../../../../services/save.service";
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ControllerConfigPredefinedItemsDescriptionFormComponent } from "./controllerconfigpredefineditemsdescription-form.component";

@Component({
    selector: "controllerconfig-list",
    templateUrl: './controllerconfig-list.component.html',
    styleUrls: ['../../../../style/container-tab-list.component.less'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*', })),
            transition('expanded => collapsed', [animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
        ]),
    ]

})

export class ControllerConfigListComponent implements OnInit, OnChanges {
    @Input('list') list: ControllerConfig[] = [];
    @Input('descriptions') descriptions: ControllerConfigDescription[] = [];
    @Input('datatypes') datatypes: ControllerConfigDataType[] = [];

    @Input() isRowEditMode = false;
    @Output() onRefresh = new EventEmitter<{}>();

    selected: ControllerConfig = <ControllerConfig>{};

    clonedList: { [s: string]: ControllerConfig; } = {};

    columnsToDisplay = ['arrow', 'desc', 'customerDesc', 'actions'];
    subcolumnsToDisplay = ['bk', 'predefinedItemBK', 'description'];

    public dataSource = new MatTableDataSource<ControllerConfig>();
    controllerConfigDescription: ControllerConfigDescription[];
    controllerConfigDataType: ControllerConfigDataType[];

    @ViewChild(MatTable, { static: true }) table!: MatTable<any>;

    expandedElements = new Set();

    subscriptionLanguage: any;
    subscriptionTenant: any;
    constructor(
        private defaults: DefaultValuesService
        , private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , private dialog: DialogService
        , private saveService: SaveService
        , private dialogForm: MatDialog
    ) {
        this.dialog.tthis = this;
        this.controllerConfigDescription = []
        this.controllerConfigDataType = []
    }

    ngOnInit() {
        this.dialog.tthis = this;
        this.getHeader()
        this.subscriptionLanguage = this.defaults.languageMessageChanges$.subscribe(() => {
            this.getHeader()
        });
        this.subscriptionTenant = this.defaults.tenantMessageChanges$.subscribe(() => {
            this.getHeader()
        });
    }
    ngOnChanges() {
        this.dataSource.data = this.list;
    }

    getHeader() {
        if (this.defaults.isadminuser || !this.defaults.isReadonlyUser)
            this.columnsToDisplay = ['arrow', 'desc', 'bk', 'datatype', 'actions'];
        else
            this.columnsToDisplay = ['arrow', 'desc', 'bk', 'datatype'];
    }

    onRowEditInit(ControllerConfig: ControllerConfig) {
        this.saveService.addListener(this);
        this.clonedList[ControllerConfig.ControllerConfigID] = { ...ControllerConfig };
        if (Object.keys(this.clonedList).length !== 0) this.onShowSavebutton()
    }

    onShowSavebutton() {
        this.saveService.showSaveButton();
    }

    notTranslatedClass(text: string): string {
        return Utils.notTranslatedClass(text);
    }

    isToShowReference(): boolean {
        return this.defaults.language.LanguageId != Utils.getDefaultLaguangeID();
    }

    isEditingRow(element: ControllerConfig) {
        return this.clonedList[element.ControllerConfigID]
    }


    onRowEditCancel(ControllerConfig: ControllerConfig) {
        this.dataSource.data[this.dataSource.data.findIndex(x => x.ControllerConfigID === ControllerConfig.ControllerConfigID)] = this.clonedList[ControllerConfig.ControllerConfigID];
        delete this.clonedList[ControllerConfig.ControllerConfigID];

        if (Object.keys(this.clonedList).length === 0) this.saveService.showSaveButton(false)
        this.table.renderRows();
    }

    onSave() {
        for (let i in this.clonedList) {
            const index = this.dataSource.data.findIndex(x => x.ControllerConfigID === this.clonedList[i].ControllerConfigID)
            if (Object.entries(this.clonedList[i]).toString() !== Object.entries(this.dataSource.data[index]).toString()) {
                this.http
                    .post<ControllerConfig>(
                        this.baseUrl + Utils.getControllerTypeAPI() + "mergecontrollerconfig"
                        , this.dataSource.data[index]
                    )
                    .subscribe(
                        () => {
                            this.onRowEditCancel(this.clonedList[i])
                            this.onRefresh.emit({});
                        }
                        , error => {
                            this.dialog.showErrorDialog(error)
                            this.onRowEditCancel(this.clonedList[i])
                            delete this.clonedList[this.clonedList[i].ControllerConfigID];
                            this.table.renderRows();
                        }
                    );
            }
            else {
                delete this.clonedList[this.clonedList[i].ControllerConfigID];
            }
        }
    }

    /**
     * called by the table list
     * */
    onDelete(value: ControllerConfig) {
        this.dialog.showConfirmDialog("Delete Controller Config - " + value.DescriptionBK + ", as an " + value.DataTypeBK + "?", "Delete Controller Config", this, value);
    }

    /**
     * called by the DialogService
     * */
    onYes(value: ControllerConfig) {
        this.http
            .delete<ControllerConfig>(
                this.baseUrl + Utils.getControllerTypeAPI() + 'controllerconfig/' + value.ControllerConfigID
            )
            .subscribe(
                () => this.onRefresh.emit({})
                , error => this.dialog.showErrorDialog(error)
            );
    }

    ///////////////////
    // Predefined items
    pushPopElement(element: ControllerConfig) {
        if (this.expandedElements.has(element)) {
            this.expandedElements.delete(element);
        }
        else {
            this.expandedElements.add(element);
            this.onExpand(element)
        }
    }

    checkExpanded(element: ControllerConfig) {
        return this.expandedElements.has(element);
    }

    onExpand(element: ControllerConfig) {
        const foundIndex = this.dataSource.data.findIndex((x: ControllerConfig) => x.ControllerConfigID === element.ControllerConfigID);
        Utils.httpGetAllControllerConfigPredefinedItemsDescription(
            this.http
            , this.baseUrl
            , element.DescriptionBK
            , this
            , function (tthis: ControllerConfigListComponent, list: ControllerConfigPredefinedItemsDescription[]) {
                tthis.dataSource.data[foundIndex].PredefinedItemsDescription = list;
            }
        );
    }

    openNewPredefinedItemPopup(ControllerConfigBK: string) {
        
        const dialog = this.dialogForm.open(ControllerConfigPredefinedItemsDescriptionFormComponent, {
            panelClass: ['custom-mat-dialog'],
            disableClose: true,
            data: { "ControllerConfigBK": ControllerConfigBK }
        })
        dialog.afterClosed().subscribe(() => {
            this.onRefresh.emit({})
        })
    }
}