<table-fixed-header-search-collapse
    *ngIf="selected.ErrorCodeResolutions"
    id-table="error-code-resolution-id-table"
    [header]="header"
    [data]="selected.ErrorCodeResolutions"
    [getValue]="getData"
    [getClass]="notInView"

    [selectable]="false"

    [rowEditTemplate]="editErrorcodeDetailDescription"
    >
</table-fixed-header-search-collapse>

<ng-template #editErrorcodeDetailDescription let-index="index">
    <errorcode-edit-detail-newlanguage [header]="header" [list]="selected.ErrorCodeResolutions" [rowIndex]="index"></errorcode-edit-detail-newlanguage>
</ng-template>
