<div class="custom-mat-dialog-form">
    <div>
        <h3>Select source Wave</h3>
    </div>
    <div class="custom_small_panelbody_fix">

        <mat-selection-list [formControl]="form" [multiple]="false">
            <mat-list-option *ngFor="let item of list" [value]="item.WaveId" checkboxPosition="before">
                {{item.Name}}
            </mat-list-option>
        </mat-selection-list>

    </div>

    <div class="panel-body">

        <button (click)="backButtonHandler($event)" mat-raised-button class="btn  button-color-fix_red ">
            <mat-icon class=" icon-color-fix ">arrow_back_ios</mat-icon>
            Back
        </button>
        <button mat-raised-button type="submit" (click)="addButtonHandler(form.value)" class="btn button-color-fix pull-right ">
            Confirm
            <mat-icon class="icon-color-fix">save</mat-icon>
        </button>

    </div>
</div>