import { Component, Inject, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { DialogService } from "../../../services/dialog.service";
import { Utils } from "../../../utils/utils";
import { HttpClient } from "@angular/common/http";
import { DefaultValuesService } from "../../../services/defaultvalues.service";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
//import { ControllerTypeByTenantFormComponent } from "./controllertypebytenant-form.component";

@Component({
    selector: "controllertypetomasterdatarequired-list",
    templateUrl: './controllertypetomasterdatarequired-list.component.html',
    styleUrls: ['../../../style/list.component.less', './controllertypetomasterdatarequired-list.component.less']
})

export class ControllerTypeToMasterdataRequiredListComponent implements OnInit {
    @Input('list') list: ControllerTypeToMasterdataRequired[] = [];
    @Output() onRefresh = new EventEmitter<{}>();
    selected = {} as ControllerTypeToMasterdataRequired; 

    expanded: boolean[] = [];
    subscriptionControllerType: any;

    columnsToDisplay = ['masterdata', 'value', 'actions'];
    public dataSource = new MatTableDataSource<ControllerTypeToMasterdataRequired>();

    constructor(
        private http: HttpClient
        , private dialogService: DialogService
        , public defaults: DefaultValuesService
        , @Inject('BASE_URL') private baseUrl: string
        , private dialog: MatDialog
    ) {
    }

    ngOnInit() {     
        this.dialogService.tthis = this;
    }

    ngOnChanges() {
        this.dataSource.data = this.list;
    }

    ngOnDestroy() {
    }

    /**
     * called by the SaveService
     * */
    onCancel() {
        this.dialog.closeAll();
    }

    onToggle(value: ControllerTypeToMasterdataRequired ) {
        this.dialogService.showConfirmDialog((value.Value ? "Reset " : "Set ") + value.MasterDataName + " for " + value.Description + " in " + value.CountryCode + "?", "", this, value);
    }

    /**
     * called by the DialogService
     * */
    onYes(value: ControllerTypeToMasterdataRequired ) {
        value.Value = !value.Value;
        this.http
            .put<ControllerTypeToMasterdataRequired>(
                this.baseUrl + Utils.getControllerTypeAPI() + 'controllertypetomasterdatarequired'
                ,  value
            )
            .subscribe(
                res => this.onRefresh.emit({})
                , error => this.dialogService.showErrorDialog(error)
            );
    }
}