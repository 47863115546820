import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, OnInit, Self, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { Utils } from '../../../utils/utils';
import { DefaultValuesService } from '../../../services/defaultvalues.service';
import { DestroyService } from '../../../services/destroyservice';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'sfwversionconfiggeneration',
  templateUrl: './sfwversionconfiggeneration.component.html',
    styleUrls: ['../../../style/new-generic-styles.component.less',
        '../../../style/tables/table-add-butt-header-orange.less',
        '../emtcontrollersoftware.component.less',
        './sfwversionconfiggeneration.component.less'],
    providers: [DestroyService]
})
export class SfwVersionConfigGenerationComponent implements OnInit {
    @Input() availableCenterProducts: MFGCenterProductItem[] = [];
    @Input() availableControllerSoftwareReleases: EMTControllerSoftwareReleaseItem[] = [];
    @Input() availableCenters: MfgCenter[] = [];
    public dataSource = new MatTableDataSource<any>();
    SVCGForm!: FormGroup;
    @ViewChild(MatTable) objTable!: MatTable<any>;
    originalSfwVersionConfigGen: SoftwareVersionConfiggenerationItem[] = [];
    modifiedSfwVersionConfigGen: SoftwareVersionConfiggenerationItem[] = [];
    newSfwVersionConfigGen: SoftwareVersionConfiggenerationItem[] = [];
    isCallSucceed: number = 0;
    subscriptionStage: any;

    constructor(private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string,
        private _formBuilder: FormBuilder,
        private toastrService: ToastrService,
        public defaults: DefaultValuesService,
        @Self() private readonly destroy$: DestroyService)
    {
    }

    columnsToDisplay = ["centerproduct", "controllersoftwareversion", "action"];

    ngOnInit(): void {
        this.subscriptionStage = this.defaults.stageMessageChanges$.pipe(takeUntil(this.destroy$)).subscribe(() => { this.getSoftwareVersion4ConfigGeneration() });
        this.SVCGForm = this._formBuilder.group({
            SVCGRows: this._formBuilder.array([])
        });
        this.getSoftwareVersion4ConfigGeneration();
    }

    getCenterName(centerId: number) {
        return this.availableCenters.find(x => x.centerId === centerId)?.centerName ?? "---";
    }

    getCenterProductText(centerProductId: number) {
        let centerProd = this.availableCenterProducts.find(x => x.manufacturingCenterProductID === centerProductId);
        if (centerProd)
            return `${this.getCenterName(centerProd.mfgCenterId)} - ${centerProd.productName} - ${centerProd.productCode}`;
        else
            return "---";
    }

    getControllerSoftwareText(sfwVerId: number) {
        let sfwVer = this.availableControllerSoftwareReleases.find(x => x.controllerSoftwareVersionID === sfwVerId);
        if (sfwVer)
            return `${sfwVer.controllerSoftwareVersion}`;
        else
            return "---";
    }

    getSoftwareVersion4ConfigGeneration() {
        Utils.httpGetControllerSoftwareForConfigGeneration(
            this.http,
            this.baseUrl,
            this,
            function (tthis: SfwVersionConfigGenerationComponent, result: SoftwareVersionConfigGeneration) {
                tthis.originalSfwVersionConfigGen = result.softwareVersionConfigGenerationList;
                tthis.mapDataToForm(tthis.originalSfwVersionConfigGen);
            }
        );
    }

    mapDataToForm(data) {
        this.SVCGForm = this._formBuilder.group({
            SVCGRows: this._formBuilder.array([])
        });
        this.SVCGForm = this._formBuilder.group({
            SVCGRows: this._formBuilder.array(data.map(val => this._formBuilder.group({
                softwareVersionForConfigGenerationID: new FormControl(val.softwareVersionForConfigGenerationID),
                manufacturingCenterProductId: new FormControl(val.manufacturingCenterProductId ?? -1),
                controllerSoftwareVersionId: new FormControl(val.controllerSoftwareVersionId ?? -1),              
                isEditable: new FormControl(true),
                isNewRow: new FormControl(false),
                action: new FormControl('existingRecord'),
            })
            )) //end of fb array
        }); // end of form group cretation
        this.dataSource.data = (this.SVCGForm.get('SVCGRows') as FormArray).value;
    }

    initSVCGForm(): FormGroup {
        return this._formBuilder.group({
            manufacturingCenterProductId: new FormControl(-1),
            controllerSoftwareVersionId: new FormControl(-1),
            action: new FormControl('newRecord'),
            isEditable: new FormControl(false),
            isNewRow: new FormControl(true),
        });
    }

    addNewRow() {
        const control = this.SVCGForm.get('SVCGRows') as FormArray;
        control.insert(0, this.initSVCGForm());
        this.dataSource = new MatTableDataSource(control.controls)
    }

    editRow(index) {
        this.SVCGForm.value.SVCGRows[index].isEditable = false;
    }

    resetRow(i) {
        if (this.SVCGForm.value.SVCGRows[i]?.isNewRow)
            this.deleteRow(i);
        else {
            this.updateFormFeature(i);
            this.SVCGForm.value.SVCGRows[i].isEditable = true;
        }
    }

    deleteRow(i) {
        const control = this.SVCGForm.get('SVCGRows') as FormArray;
        control.removeAt(i);
        this.dataSource.data = control.value;
        this.refreshTable();
    }

    updateFormFeature(index) {
        var obj = this.SVCGForm.value.SVCGRows;
        var updated = this.originalSfwVersionConfigGen.find(s => s.softwareVersionForConfigGenerationID == this.SVCGForm.value.SVCGRows[index]?.softwareVersionForConfigGenerationID);
        obj[index].manufacturingCenterProductId = updated?.manufacturingCenterProductId ?? -1;
        obj[index].controllerSoftwareVersionId = updated?.controllerSoftwareVersionId ?? -1;
    }

    anyChange() {
        this.mapFormToData();
        return this.modifiedSfwVersionConfigGen?.length > 0 || this.newSfwVersionConfigGen?.length > 0;
    }

    mapFormToData() {
        this.modifiedSfwVersionConfigGen = this.SVCGForm.get('SVCGRows')?.value.filter(row => !row.isNewRow
            && !row.isEditable
            && row.manufacturingCenterProductId !== -1
            && row.controllerSoftwareVersionId !== -1
        );
        this.newSfwVersionConfigGen = this.SVCGForm.get('SVCGRows')?.value.filter(row => row.isNewRow
            && row.manufacturingCenterProductId !== -1
            && row.controllerSoftwareVersionId !== -1
        );
    }

    onSave() {
        if (this.anyChange()) {
            var configChanges = { 'modifiedConfig': this.modifiedSfwVersionConfigGen, 'newConfig': this.newSfwVersionConfigGen }
            Utils.httpUpdateControllerSoftwareConfigGeneration(
                this.http
                , this.baseUrl
                , configChanges
                , this
                , function (tthis: SfwVersionConfigGenerationComponent, result: any) {
                    tthis.getSoftwareVersion4ConfigGeneration();
                    tthis.funcNotify();
                }
            )
        }
        
    }

    funcNotify() {
        if (this.isCallSucceed === 1) {
            this.toastrService.success('Save request successfully sent!');
        }
        else if (this.isCallSucceed === -1) {
            this.toastrService.error('Save action could not be performed. Please, contact with administrator');
        }
    }

    refreshTable() {
        this.objTable.renderRows();
    }
}
