import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Params } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Utils } from '../../../../../../utils/utils';

interface MGMTBranchNode {
    groupId: string;
    groupName: string;
    subGroupsAllowed: boolean,
    selected?: boolean;
    disabled?: boolean;
    indeterminate?: boolean;
    parent?: MGMTBranchNode;
    groups: MGMTBranchNode[];
    groupFunction: string;
}

@Component({
    selector: 'assessmentassignmentpopup',
    templateUrl: './assessmentassignmentpopup.component.html',
    styleUrls: ['./assessmentassignmentpopup.component.less',
        '../../../../../../style/new-generic-styles.component.less']
})
export class AssessmentAssignmentPopupComponent implements OnInit {

    public TREE_DATA: MGMTBranchNode[] = [];/*** */

    public treeControl: NestedTreeControl<MGMTBranchNode>;
    public dataSource = new MatTreeNestedDataSource<MGMTBranchNode>();

    public showOnlySelected = false;
    Tree_Response_State: number = -2; //0 -> empty , 1 -> correct and not empty ,-1 -> error, -2 -> Calling API
    public form: FormGroup;

    isCallSucceed: number = 0;
    isMerged = false;
    ngSelectItems: any;
    selectedBranches: string[] = [];
    selectAllCheckbox = false;
    manualItemSelected = false;
    public searchString: string = '';
    constructor(fb: FormBuilder,
        private route: ActivatedRoute,
        private http: HttpClient,
        public dialogRef: MatDialogRef<AssessmentAssignmentPopupComponent>,
        @Inject('BASE_URL') private baseUrl: string,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private toastrService: ToastrService,

    ) {
        this.route.params.subscribe(
            (params: Params) => {
                this.resetAll();
            });
        this.treeControl = new NestedTreeControl<MGMTBranchNode>((node) => node.groups)
        this.form = fb.group(
            {
                mgmtId: [data.content?.MgmtId, Validators.required]
            }, {
        });
    }
    ngOnInit() {
    }
    resetAll() {
        this.treeControl = new NestedTreeControl<MGMTBranchNode>((node) => node.groups);
        this.dataSource = new MatTreeNestedDataSource<MGMTBranchNode>();
        this.funcGetManagementIdSelector();
    }

    public hasChild = (_: number, node: MGMTBranchNode) =>
        !!node.groups && node.groups.length > 0;


    public itemToggle(checked: boolean, node: MGMTBranchNode) {
        if (!this.manualItemSelected)
            this.manualItemSelected = true
        if (checked) {
            this.selectedBranches.push(this.getFieldBranchId(node));
        } else {
            const index = this.selectedBranches.indexOf(this.getFieldBranchId(node));
            this.selectedBranches.splice(index, 1);
        }
    }

    public selectAll(selected: boolean) {
        this.manualItemSelected = false
        this.selectAllCheckbox = selected
        this.selectedBranches = [];
        this.treeControl.dataNodes.forEach((branchNode) => {
            branchNode.selected = undefined;
            if (branchNode.groups) {
                const alldecendents = this.treeControl.getDescendants(branchNode)
                alldecendents.forEach((node) => {
                    if (node.groups === null || node.groups === undefined) {
                        node.selected = this.selectAllCheckbox
                        if (this.selectAllCheckbox) {
                            this.selectedBranches.push(this.getFieldBranchId(node));
                        }
                    }
                })
            }
        });
    }

    private getFieldBranchId(node: MGMTBranchNode) {
        var tokens = node.groupName.split(' - ');
        return tokens[tokens.length - 1];
    }

    funcGetManagementIdSelector() {
        Utils.httpGetFieldManagementIdsFromBranchExcellence(
            this.http
            , this.baseUrl
            , 'FIELDBRANCH'
            , this.data.WaveId
            , this
            , (tthis: AssessmentAssignmentPopupComponent, data: any) => {
                tthis.TREE_DATA = data; ///******** */
                tthis.treeControl.dataNodes = tthis.TREE_DATA;
                tthis.dataSource.data = tthis.TREE_DATA;
                Object.keys(this.dataSource.data).forEach((key) => {
                    this.setParent(this.dataSource.data[key], undefined);
                })
                if (data)
                    tthis.selectByDefault(tthis.dataSource.data, this.data.MgmtId)

            });
    }

    selectByDefault(objects: MGMTBranchNode[], mgmtId: string): void {
        for (const object of objects) {
            if (object.groupName.includes(mgmtId)) {
                object.selected = true;
                this.expandParentNode(object)
            }
            if (object.groups) {
                this.selectByDefault(object.groups, mgmtId);
            }
        }
    }

    private setParent(node: MGMTBranchNode, Parent: MGMTBranchNode | undefined) {
        if (node)
            node.parent = Parent;
        if (node.groups) {
            node.groups.forEach((childNode) => {
                this.setParent(childNode, node);
            });
        }
    }
    expandParentNode(node: MGMTBranchNode) {
        if (node.parent) {
            this.treeControl.expand(node.parent);
            this.expandParentNode(node.parent);
        }
    }

    public hideLeafNode(node: MGMTBranchNode): boolean {
        return new RegExp(this.searchString, 'i').test(node.groupName) === false;
    }


    public hideParentNode(node: MGMTBranchNode): boolean {
        if (
            !this.searchString || node.groupName.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1) {
            return false
        }
        const descendants = this.treeControl.getDescendants(node)

        if (
            descendants.some(
                (descendantNode) =>
                    descendantNode.groupName
                        .toLowerCase()
                        .indexOf(this.searchString.toLowerCase()) !== -1
            )
        ) {
            return false
        }

        return true
    }

    onSubmit() {
        Utils.httpAssignBranchesToWave(
            this.http
            , this.baseUrl
            , this.data.WaveId
            , this.selectedBranches
            , this
            , (tthis: AssessmentAssignmentPopupComponent, data: any) => {
                if (data)
                    tthis.toastrService.success('Branches were assigned successfully!');
                else
                    tthis.toastrService.error('Branches could not be assigned. Please, contact with administrator');
                tthis.dialogRef.close();
            }
            , null);
    }

    responseHandler(response, operation) {
        response === true ? this.toastrService.success(`Hierarchy key ${operation}ed successfully!`, `${operation} Hierarchy Key!`) : this.toastrService.error(response, `${operation} Hierarchy Key Failed!`)
    }

    onCancelClick(): void {
        this.dialogRef.close();
    }

    customSearchFn(term: string, item: any) {
        term = term.toLocaleLowerCase();
        return item.Address.toLocaleLowerCase().indexOf(term) > -1 ||
            item.BranchName.toLocaleLowerCase().indexOf(term) > -1 ||
            item.Country.toLocaleLowerCase().indexOf(term) > -1 ||
            item.Key.toLocaleLowerCase().indexOf(term) > -1 ||
            item.LocationForMap.toLocaleLowerCase().indexOf(term) > -1 ||
            item.MgmtId.toLocaleLowerCase().indexOf(term) > -1
    }

}
