import { Component, ElementRef, Inject, OnInit, Renderer2, HostListener, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { DefaultValuesService } from '../../services/defaultvalues.service';
import { ComponentTool } from '../../interfaces/componenttool'
import { AfterViewChecked } from '@angular/core';

@Component({
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.less', '../../style/new-generic-styles.component.less']
})
export class HomeComponent implements OnInit, AfterViewChecked {
    version: string | undefined = '0.0.0.0';

    //showBanner: boolean = false;

    rulebookUrl: string = '';
    componentToolEnum: typeof ComponentTool = ComponentTool;
    components: any[]
    elementRef: ElementRef | null | undefined
    constructor(
        public defaults: DefaultValuesService,
        @Inject('BASE_URL') private baseUrl: string) {
        this.components = []
    }

    ngOnInit() {
    }

    ngAfterViewChecked() {
        if (this.defaults.components.length > 6) { // With more than 6 elements we need to allow horizontal scroll
            const element = document.getElementById("landingpage-columns");
            if (element)
                this.elementRef = new ElementRef<HTMLElement>(element)
        }
    }

    @HostListener('wheel', ['$event'])
    onWheel(event: any) {
        if (event.type === 'wheel') {
            event.preventDefault();
            if (this.elementRef) {
                this.elementRef.nativeElement.scrollLeft = this.elementRef.nativeElement.scrollLeft + event.deltaY
            }
        }
    }

    setSelectedItem(selectedItemNumb: number | undefined) {
        this.defaults.selectedItem = -1;
        this.defaults.selectedItem = selectedItemNumb ?? -1;
        localStorage.setItem('currentSelectedItem', this.defaults.selectedItem.toString());
    }

    getComponentClass(index: number, clusterId: number) {
        if (this.defaults.componentTool === this.componentToolEnum.Rulebook) {
            if (clusterId % 3 === 0) return 'tke-orange'
            if (clusterId % 3 === 1) return 'tke-intermediate-color'
            if (clusterId % 3 === 2) return 'tke-purple'
        }
        else {
            if (index % 3 === 0) return 'tke-orange'
            if (index % 3 === 1) return 'tke-intermediate-color'
            if (index % 3 === 2) return 'tke-purple'
        }
        return '';
    }

    isExternalLink(link:any) {
        const httpRegex = /^http:\/\//i; // Match "http://" with case-insensitivity
        const httpsRegex = /^https:\/\//i; // Match "https://" with case-insensitivity
        return httpRegex.test(link) || httpsRegex.test(link);
    }
    externalRedirect(link: any) {
        window.open(link, "_blank");
    }
}
