import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Utils } from "../../../utils/utils";
import { DefaultValuesService } from "../../../services/defaultvalues.service";
import { SaveService } from "../../../services/save.service";
import { BranchHierarchyListComponent } from "./branchhierarchy-list.component";
import { CustomDialogService } from "../../../services/customdialog.service";

@Component({
    selector: "branchhierarchy",
    templateUrl: './branchhierarchy.component.html',
    styleUrls: ['../../../style/container-tab-list.component.less']
})

export class BranchHierarchyComponent implements OnInit {

    listRef: BranchHierarchyRef[] = [];
    listKey: BranchHierarchyKey[] = [];
    list: BranchHierarchyView[] = [];

    @ViewChild(BranchHierarchyListComponent, { static: true }) itemsList!: BranchHierarchyListComponent;

    subscriptionTenant: any;

    newBranch: SaveService = {} as SaveService;

    constructor(
        private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , private defaults: DefaultValuesService
        , private saveService: SaveService
        , private customDialog: CustomDialogService
    ) {

    }

    ngOnInit() {
        this.newBranch = new SaveService();
        this.subscriptionTenant = this.defaults.tenantMessageChanges$.subscribe(() => {
            this.getAll()
        });

        if (this.defaults.tenant)
            this.getAll()
    }

    ngOnDestroy() {
        this.subscriptionTenant.unsubscribe();
    }
    onNewBranch() {
        this.newBranch.fireNew();
    }

    getAll() {
        Utils.httpGetBranchHierarchy(
            this.http
            , this.baseUrl
            , this
            , function (tthis: any, list: BranchHierarchyView[]) {
                tthis.list = list;
            }
        );
        Utils.httpGetBranchHierarchyRef(
            this.http
            , this.baseUrl
            , this
            , function (tthis: any, listRef: BranchHierarchyRef[]) {
                tthis.listRef = listRef;
            }
        );

        Utils.httpGetBranchHierarchyKey(
            this.http
            , this.baseUrl
            , this
            , function (tthis: BranchHierarchyComponent, listKey: BranchHierarchyKey[]) {
                tthis.listKey = listKey;
            }
        );
    }

    onReload() {
        this.getAll();
        this.itemsList.clonedList = {}
        this.saveService.showSaveButton(false)
    }
    downloadBranchHierarchy() {
        Utils.httpDownloadBranchHierarchy(
            this.http
            , this.baseUrl
            , this.defaults.stage
            , this.defaults.tenant
            , this
            , null)
    }

    onDelete($event: any) {
        let element = $event.element
        this.customDialog.openConfirm({
            title: "Delete Branch Hierarchy",
            message: `${element.Number}(${element.Branch})?`,
            caller: this
        });
        this.customDialog.confirmed().subscribe(res => {
            if (res.confirmed) {
                this.http
                    .delete<BranchHierarchyView>(
                        this.baseUrl + Utils.getBranchHierarchyAPI() + '/' + element.Branch + '/' + element.Number
                    ).subscribe(() => {
                        let foundIndex = this.list.findIndex((x: BranchHierarchyView) => x.Number === element.Number);
                        if (foundIndex > -1) {
                            this.list.splice(foundIndex, 1);
                            this.itemsList.dataSource.data = this.list
                        }
                    }, (error: { error: { message: any } }) => { this.customDialog.openDisplayInfoDialog({ title: 'Error', message: error.error.message }); });
            }
        });
    }
}