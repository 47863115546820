<div class="example-container">
    <table mat-table #itemsList
           [dataSource]="dataSource"
           multiTemplateDataRows
           class="mat-elevation-z8 mat-table-custom" id="hierarchysetup">

        <ng-container matColumnDef="Key">
            <th mat-header-cell *matHeaderCellDef> Field Branch Key </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.Key"></p>
            </td>
        </ng-container>
        <ng-container matColumnDef="BranchName">
            <th mat-header-cell *matHeaderCellDef> Field Branch Name </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.BranchName"></p>
            </td>
        </ng-container>
        <ng-container matColumnDef="LocationForMap">
            <th mat-header-cell *matHeaderCellDef> Location For Map </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.LocationForMap"></p>
            </td>
        </ng-container>
        <ng-container matColumnDef="Address">
            <th mat-header-cell *matHeaderCellDef> Address </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.Address"></p>
            </td>
        </ng-container>
        <ng-container matColumnDef="MgmtId">
            <th mat-header-cell *matHeaderCellDef> MgmtId </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.MgmtId"></p>
            </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let element; let i = dataIndex">
                <button mat-icon-button matTooltip="Edit Hierarchy Key" (click)="onRowEditInit(element)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Delete Hierarchy Key" (click)="onDeleteKey(element)">
                    <mat-icon>delete</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Get Hierarchy Key History" (click)="onGetHistory(element)">
                    <mat-icon>history</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row class="tableheader" *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; let i = dataIndex; columns: columnsToDisplay;"
            class="item">
        </tr>
    </table>
</div>

