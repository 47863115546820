import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SaveService } from '../../../../../services/save.service';
import { CreateMeasurePopupComponent } from '../create-measure/create-measure-popup.component';
import { CreateActionItemPopupComponent } from '../create-actionitem/create-action-item-popup.component';
import { DialogService } from '../../../../../services/dialog.service';
import { HttpClient } from '@angular/common/http';
import { Utils } from '../../../../../utils/utils';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'focusplan-list',
    templateUrl: './focusplan-list.component.html',
    styleUrls: ['./focusplan-list.component.less',
        '../../../../../style/new-generic-styles.component.less'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*', })),
            transition('expanded => collapsed', [animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
        ]),
    ]
})
export class FocusPlanListComponent implements OnInit {

    @Input('measuresInfo') measuresInfo!: MeasureInfo[];
    @Input('waveId') waveId!: number;
    @Output() refresh = new EventEmitter<string>();
    updatedItems: number[] = []

    columnsToDisplay = ['arrow','Name', 'Description', 'Owner','Kpi', 'actions'];
    subcolumnsToDisplay = ['ItemNo', 'Name', 'Description', 'Owner', 'Comments', 'Deadline', 'actions'];

    public dataSource = new MatTableDataSource<MeasureInfo>();

    expandedElements = new Set();
    clonedList: { [s: string]: MeasureInfo } = {};

    dialogRef: any;

    availableKpis: any[] = []
    onRefresh: any;
    constructor(
        private saveService: SaveService
        , private dialogForm: MatDialog
        , private dialog: DialogService
        , private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , private toastrService: ToastrService
    ) { }

    ngOnInit(): void {
    }

    ngOnChanges(changes: any) {
        this.dataSource.data = this.measuresInfo
    }

    pushPopElement(measureId: number) {
        if (this.expandedElements.has(measureId)) {
            this.expandedElements.delete(measureId);
        }
        else {
            this.expandedElements.add(measureId);
        }
    }
    checkExpanded(measureId: number): boolean {
        return this.expandedElements.has(measureId);
    }
    onDeleteMeasure(measure: MeasureInfo) {
        this.dialog.showConfirmDialog(`Do you want to delete Measure ${measure.Name}? All information related (including Action Items will be removed)`, "Delete Measure", this, measure);
    }

    onDeleteActionItem(actionItem: MeasureActionItem) {
        this.dialog.showConfirmDialog(`Do you want to delete Action Item ${actionItem.Description}?`, "Delete Action Item", this, actionItem);
    }

    onYes(value: any) {
        if ("ActionItems" in (value as MeasureInfo)) {
            Utils.httpDeleteMeasure(
                this.http
                , this.baseUrl
                , value.MeasureId
                , this
                , function (tthis: FocusPlanListComponent, result: any) {
                    tthis.refresh.emit('');
                    if (result) {
                        tthis.toastrService.success(`Measure was deleted successfully!`);
                    }
                    else
                        tthis.toastrService.error(`Measure could not be deleted. Please, contact with administrator`);
                },
                null
            );
        }
        else {
            Utils.httpDeleteActionItem(
                this.http
                , this.baseUrl
                , value.ActionItemId
                , this
                , function (tthis: FocusPlanListComponent, result: any) {
                    tthis.refresh.emit('');
                    if (result) {
                        tthis.toastrService.success(`Action Item was deleted successfully!`);
                    }
                    else
                        tthis.toastrService.error(`Action Item could not be deleted. Please, contact with administrator`);
                },
                null
            );
        }
    }

    onEditMeasure(measure: MeasureInfo) {
        const dialog = this.dialogForm.open(CreateMeasurePopupComponent, {
            panelClass: ['custom-mat-dialog_v2'],
            disableClose: true,
            data: { 'waveId': this.waveId, ...measure }
        })

        dialog.afterClosed().subscribe(() => {
            this.refresh.emit('')
        })
    }
    onRowEditMeasureCancel(measureId: number) {
        this.dataSource.data[this.dataSource.data.findIndex(x => x.MeasureId === measureId)] = this.clonedList[measureId];
        delete this.clonedList[measureId];
    }

    onUpsertActionItem(measure: MeasureInfo, actionItem: MeasureActionItem | undefined) {
        const dialog = this.dialogForm.open(CreateActionItemPopupComponent, {
            panelClass: ['custom-mat-dialog_v2'],
            disableClose: true,
            data: { 'measureId': measure.MeasureId, 'measureDescription': measure.Name, ...actionItem },
        })

        dialog.afterClosed().subscribe(() => {
            this.refresh.emit('')
        })
    }

    isButtonDisabled(actionItems: MeasureActionItem[]) {
        return actionItems?.length > 9
    }
}