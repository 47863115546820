<form [formGroup]="form">
    <table>
        <tbody class="inner">
            <tr>
                <td>&nbsp;</td>
                <td>
                    <span *ngIf="!newRow">{{selected.MCPGroup}}<br /></span>
                    <input type="text" id="Title"
                           formControlName="Title" required
                           placeholder="enter the title..."
                           title="{{header[TITLE].label}}"
                           [ngClass]="classname[TITLE]"
                           (change)="onTitleChange()"
                           class="form-control" />
                    <button *ngIf="!newRow" type="button" class="btn btn-default" title="Undo changes" [disabled]="isundodisabled[TITLE] == true" (click)="onTitleUndo()">
                        <span class='glyphicon glyphicon-arrow-left'></span>
                    </button>
                    <br />
                </td>
                <td>
                    <span *ngIf="!newRow">{{selected.IsLobby}}<br /></span>
                    <input id="Lobby"
                           type="checkbox"
                           formControlName="Lobby"
                           title="{{header[ISLOBBY].label}}"
                           [ngClass]="classname[ISLOBBY]"
                           (change)="onIsLobbyChange()"
                           class="form-control" />
                    <button *ngIf="!newRow" type="button" class="btn btn-default" title="Undo changes" [disabled]="isundodisabled[ISLOBBY] == true" (click)="onIsLobbyUndo()">
                        <span class='glyphicon glyphicon-arrow-left'></span>
                    </button>
                </td>
                <td>
                    <button type="button"
                            class="btn btn-warning"
                            (click)="onRowCancel()">
                        Cancel
                    </button>
                </td>

            </tr>
        </tbody>
    </table>
</form>