<div class="container-tab-lists" id="remoteactions">
    <div class="inner-tcontainer-tab">
        <div class="tab-body">
            <div class="tab-content">
                <mat-tab-group #tabGroup (selectedTabChange)="defaults.updateComponentTab($event.tab.textLabel)">
                    <mat-tab label="Tenant Configuration">
                        <allowedactions #allowedActionsComponent 
                        [commands]="commandList" 
                        [isEditableMethod]="isEditable"
                        (onReloadTemplate)="onReloadCommandTemplate()"></allowedactions>
                    </mat-tab>
                    <mat-tab label="Controller Type Configuration">
                        <allowedactionscontrollertype #allowedActionsControllerTypeComponent
                                                      [commands]="commandList"
                                                      [isEditableMethod]="isEditable"
                                                      (onReloadTemplate)="onReloadCommandTemplate()"></allowedactionscontrollertype>
                    </mat-tab>
                    <mat-tab label="Provisioning Configuration">
                        <provisioningtemplate #provisioningTemplateComponent
                                              [commands]="commandList"
                                              [isEditableMethod]="isEditable"></provisioningtemplate>
                    </mat-tab>

                    <mat-tab *ngIf="showTabsByAllowedActionCT('RemoteAdjustParameter')" label="Parameters Configuration">
                        <parametersconfig></parametersconfig>
                    </mat-tab>

                    <mat-tab *ngIf="showTabsByAllowedActionCT('CallFunction')" label="Functions Configuration">
                        <functionsconfig></functionsconfig>
                    </mat-tab>
                    <mat-tab *ngIf="showTabsByAllowedActionCT('RemoteReset')" label="Remote Reset Configuration">
                        <remoteresetcommand></remoteresetcommand>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>
</div>