import { Component, OnInit, OnDestroy, Inject, Input } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { DefaultValuesService } from "../../../services/defaultvalues.service";
import { Utils } from "../../../utils/utils";
@Component({
    selector: "provisioningtemplate",
    templateUrl: 'provisioningratemplate.component.html',
})

export class ProvisioningTemplateComponent implements OnInit, OnDestroy {

    @Input('commands') commandList: Command[] = [];
    @Input('isEditableMethod') isEditable: any

    list: CommandConfigTemplate[] = [];
    mergedList: any[] = [];

    showLocalFilter = false;

    subscriptionControllerType: any;

    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string,
        public defaults: DefaultValuesService
    ) { }

    ngOnInit(): void {
        this.subscriptionControllerType = this.defaults.controllerTypeMessageChanges$.subscribe(() => { this.getCommandConfigTemplate() });
        if (this.defaults.tenant && this.commandList.length > 0) {
            this.getCommandConfigTemplate()
        }
    }
    ngOnChanges() {
        this.getCommandConfigTemplate()
    }

    ngOnDestroy() {
        this.subscriptionControllerType.unsubscribe();
    }

    onReload($event: CommandConfigTemplate) {
        const local = this.mergedList
        const local2: any[] = []
        this.mergedList = []
        local.forEach(x => {
            if (x.CommandId === $event.CommandId) {
                x.IsEnabled = $event.IsEnabled;
                x.FirstActivationUtc = $event.FirstActivationUtc;
                x.LastChangeUtc = $event.LastChangeUtc;
            }
            local2.push(x)
        })
        this.mergedList = local2
    }

    getCommandConfigTemplate() {
        Utils.httpGetCommandConfigTemplate(
            this.http
            , this.baseUrl
            , this
            , function (tthis: ProvisioningTemplateComponent, list: CommandConfigTemplate[]) {
                tthis.list = list;
                const tempList: any[] = []
                tthis.list.forEach(x => {
                    const item = tthis.commandList.find(y => y.CommandId === x.CommandId);
                    tempList.push({
                        'CommandName': item ? item.CommandName : '',
                        'Description': item ? item.Description : '',
                        'Category': item ? item.Category : '',
                        ...x
                    })
                });
                tthis.mergedList = tempList

            }
        );
    }
}
