<div style="display: flex">

    <div class="defaultSelectProp">
        Dialer:
        <br />
        <ng-select style="width: 230px; border: 1px white solid; margin: 0px;" class=" defaultSelectProp top-main-select"
                   [items]="allDialers"
                   [closeOnSelect]="true"
                   [searchable]="true"
                   (change)="getRegCodeForDialer($event)"
                   [(ngModel)]="selectedDialer">
        </ng-select>
    </div>

    <div class="defaultSelectProp">
        Regulatory Code:
        <br />
        <ng-select style="width: 230px; border: 1px white solid; margin: 0px;" class=" defaultSelectProp top-main-select"
                   [items]="relatedRegualatoryCodes"
                    [closeOnSelect]="true"
                   [searchable]="true"
                   (change)="onRegCodeChange()"
                   [(ngModel)]="selectedRegCode">
        </ng-select>
    </div>

</div>
