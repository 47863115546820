<form [formGroup]="form">
    <table>
        <tbody class="inner">
            <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td colspan="2" style="width: 10%; padding: 2%">
                    <label>{{header[0].label}}:</label>
                    <input type="number"
                           formControlName="ErrorCode"
                           placeholder="enter the error code..."
                           title="enter the error code..."
                           (change)="onErrorCodeChange()"
                           class="form-control" />
                </td>

                <td colspan="2" style="width:20%; padding:2%">
                    <label>{{header[1].label}}:</label>
                    <textarea id="description"
                              formControlName="Description" required
                              placeholder="enter the description..."
                              class="form-control"
                              (change)="onDescriptionChange()">
                    </textarea>
                </td>

                <td colspan="2" style="width: 10%; padding: 2%">
                    <label>{{header[3].label}}:</label>
                    <select id="Weight"
                            formControlName="Weight"
                            class="form-control"
                            (change)="onWeightChange()">
                        <option *ngFor="let ans of weights; index as i" value="{{ans}}">{{ans}}</option>
                    </select>
                </td>

                <td colspan="2" style="width: 10%; padding: 2%">
                    <label>{{header[4].label}}:</label>
                    <input type="checkbox" id="ControllerBlocked"
                           formControlName="ControllerBlocked"
                           title="check if you controller blocked on this error..."
                           class="form-control"
                           (change)="onControllerBlockedChange()" />
                    <label>{{header[5].label}}:</label>
                    <input type="checkbox" id="PotentialRemoteResetFix"
                           formControlName="PotentialRemoteResetFix"
                           title="Remote Reset potentially could fix the issue..."
                           class="form-control"
                           (change)="onPotentialRemoteResetFixChange()" />
                </td>
                <!--<td colspan="2" style="width: 10%; padding: 2%" *ngIf="defaultService.controllertype.BK === 'CT1001'">
                </td>-->
                <td colspan="2" style="width: 20%; padding: 2%" *ngIf="defaultService.controllertype.BK === 'CT1001'">
                    <label>{{header[6].label}}:</label>
                    <input type="checkbox" id="IsGoodEvent"
                           formControlName="IsGoodEvent"
                           title="Is Good event..."
                           class="form-control"
                           (change)="onIsGoodEventChange()" />
                    <label>{{header[7].label}}:</label>
                    <textarea id="component"
                              formControlName="Component" required
                              placeholder="enter the component..."
                              class="form-control"
                              (change)="onComponentChange()">
                    </textarea>
                </td>
                <td colspan="2" style="width: 20%; padding: 2%">
                    <label>{{header[8].label}}:</label>
                    <select name="ct"
                            (change)="onStableAlertRuleChange()"
                            title="{{header[8].label}}: {{header[8].title}}"
                            class="form-control"
                            formControlName="StableAlertRule">
                        <option *ngFor="let v of alertRules; index as i" value="{{v.Id}}" title="{{ v.Description }}">{{ v.Description }}</option>
                    </select>
                    <label>{{header[9].label}}:</label>
                    <select name="ct"
                            (change)="onProdAlertRuleChange()"
                            title="{{header[9].label}}: {{header[9].title}}"
                            class="form-control"
                            formControlName="ProdAlertRule">
                        <option *ngFor="let v of alertRules; index as i" value="{{v.Id}}" title="{{ v.Description }}">{{ v.Description }}</option>
                    </select>
                </td>
                <td colspan="2" style="width:30%;">
                    <button type="button"
                            class="btn btn-warning"
                            (click)="onBack()">
                        Cancel
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</form>
