import { Component, Input } from '@angular/core';

@Component({
    selector: 'deploybusinesskeys'
    , templateUrl: './deploybusinesskeys-form.component.html'
    , styleUrls: ['./deploy-form.less']
})
export class DeployBusinessKeysFormComponent {

    @Input() deployment = {} as Deployment;

    title: string;

    constructor() {
        this.title = "Deployment of BusinessKeys in all languages";
    }

    //onChange(evt: any) {
    //    this.deployment.BusinessKeys = evt.checked;
    //}

    //onDebriefingItemChange(evt: any) {
    //    this.deployment.DebriefingItems = evt.checked;
    //}
}


