import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { UMPAction, UMPCategory, Utils } from '../../../../../utils/utils';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { CustomDialogService } from '../../../../../services/customdialog.service';
import { AddGroupDialogue } from '../addgroupdialogue/addgroupdialogue.component';
import { SpinnerOverlayService } from '../../../../../services/spinner-overlay.service';

@Component({
    selector: "azdevproj",
    templateUrl: './azdevproj.component.html',
    styleUrls: ['./azdevproj.component.less', '../../../../../style/custom-mat-dialog.component.less', '../../../../../style/new-generic-styles.component.less'],

})

export class AzDevProj implements OnInit {
    @Input() public groupmgmtcallback: Function | undefined; 
    public userName: String = '';
    userId = ''


    searchString: string = '';
    filteredAzDevProj: any[] = [];

    AzDevProj = [];
    Response_State: number = -2; //0 -> empty , 1 -> correct and not empty ,-1 -> error, -2 -> Calling API
    errorResponse: any;
    isCallSucceed: number = 0;

    dialogRef: MatDialogRef<AddGroupDialogue, any> | undefined

    AllAzDevProj = [];
    UserAzDevProj = [];
    RemainAzDevProj = [];



    constructor(
        private route: ActivatedRoute,
        private http: HttpClient,
        private toastrService: ToastrService,
        private spinnerOverlayService: SpinnerOverlayService,


        @Inject('BASE_URL') private baseUrl: string
        , private dialogForm: MatDialog
        , private customDialog: CustomDialogService
    ) {
        this.route.params.subscribe(
            (params: Params) => {
                this.resetAll();
            });
      

    }
    resetAll() {
        this.userName = '';
        this.userId = ''


        this.searchString = '';
        this.filteredAzDevProj = [];

        this.AzDevProj = [];
        this.Response_State = -2; //0 -> empty , 1 -> correct and not empty ,-1 -> error, -2 -> Calling API
        this.isCallSucceed = 0;

        this.AllAzDevProj = [];
        this.UserAzDevProj = [];
        this.RemainAzDevProj = [];
        this.updateRolesResults();

        this.userId = this.route.snapshot.paramMap.get('userId') || '';

        this.funcGetUserAzDevProj();
    }

    ngOnInit() {

    }
    async updateRolesResults() {
        this.filteredAzDevProj = this.searchByValue(this.AzDevProj, "persona", this.searchString);
    }

    searchByValue(items: any, filterProperty: string, query: string) {

        return items.filter(item => {
            if (query.trim() === '') {
                return true;
            } else {
                return item[filterProperty].toLowerCase().includes(query.trim().toLocaleLowerCase())
            }
        })
    }
    handleDeleteButton(selected: any) {

        
        this.isCallSucceed = 0;
        Utils.httpRevokeGroupPersonae(
            this.http
            , this.baseUrl
            , {
                "Personae": [selected.id],
                "UmpAction": UMPAction.Unassign,
                "UmpCategory": UMPCategory.DevOpsProjectAreas,
                "Type": 0
            }
            , this.userId
            , this
            , function (tthis: AzDevProj) {
                tthis.funcGetUserAzDevProj();
                tthis.funcNotify(selected.persona);
            }
        )

        


    }
    funcGetUserAzDevProj() {
        this.Response_State = -2;
        Utils.httpGetUserAzDevProj(
            this.http
            , this.baseUrl
            , this.userId
            , this
            , function (tthis: AzDevProj, data: any) {
                tthis.AzDevProj = data.personae
                tthis.UserAzDevProj = data.personae;
                tthis.filteredAzDevProj = tthis.AzDevProj
                if (tthis.AzDevProj?.length) {
                    tthis.Response_State = 1;
                }
                else {
                    tthis.Response_State = 0;
                }
            }
        )
    }
    funcNotify(persona) {
        if (this.isCallSucceed === 1) {
            this.toastrService.success('Delete "' + persona + '"  request sent successfully !', 'Azure DevOps Project Delete Request!');
        }
        else if (this.isCallSucceed === -1) {
            this.toastrService.error('Delete "' + persona + '"  request not sent successfully !', 'Azure DevOps Project Delete Request!');
        }
    }



    //DialogueForm generalised  
    funcGetAzDevProj() {
        Utils.httpGetAzDevProj(
            this.http
            , this.baseUrl
            , this
            , function (tthis: AzDevProj, data: any) {
                tthis.AllAzDevProj = data.personae
                //tthis.funcGetUserAzDevProj_seq();
                tthis.getUnselectedItems(tthis.AllAzDevProj, tthis.UserAzDevProj);


            }
        )
    }

    //funcGetUserAzDevProj_seq() {
    //    Utils.httpGetUserAzDevProj(
    //        this.http
    //        , this.baseUrl
    //        , this.userId
    //        , this
    //        , function (tthis: AzDevProj, data: any) {
    //            tthis.UserAzDevProj = data.personae;
    //            tthis.getUnselectedItems(tthis.AllAzDevProj, tthis.UserAzDevProj);

    //        }
    //    )
    //}



    getUnselectedItems(allList: any, selectedList: any) {

        this.RemainAzDevProj = allList.filter(item => !selectedList.some(selectedItem => selectedItem.id === item.id));
        this.opnedialoguebox()
    }

    addUserTrigger() {
        

        this.funcGetAzDevProj();
    };

    opnedialoguebox() {
        this.dialogRef = this.dialogForm.open(AddGroupDialogue, {
            height: '540px',
            width: '600px',
            data: { title: "Azure DevOps", umpCategory: UMPCategory.DevOpsProjectAreas, userId: this.userId, AllGroups: this.AllAzDevProj, UserAssignedGroups: this.UserAzDevProj, UserUnAssignedGroups: this.RemainAzDevProj, callback: this.groupmgmtcallback },           
        });
        this.dialogRef.afterClosed().subscribe(() => {
            
            this.funcGetUserAzDevProj();           
        });
    }

    hasAdditionalCosts(persona: string) {
        return Utils.hasAdditionalLicenseCost(persona)
    }

}
