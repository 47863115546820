import { Component, OnInit, OnDestroy, Inject, Input, Output, EventEmitter } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { DefaultValuesService } from "../../../services/defaultvalues.service";
import { Utils } from "../../../utils/utils";
@Component({
    selector: "remoteresetcommand",
    templateUrl: 'remoteresetcommand.component.html',
})

export class RemoteResetCommandComponent implements OnInit, OnDestroy {

    @Output() onReloadTemplate = new EventEmitter<any>();

    list: RemoteResetCommand[] = [];

    mergedList: any[] = [];

    showLocalFilter = false;

    subscriptionTenant: any;
    subscriptionControllerType: any;

    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string,
        public defaults: DefaultValuesService
    ) { }

    ngOnInit(): void {
        this.subscriptionTenant = this.defaults.tenantMessageChanges$.subscribe(() => {
            this.getRemoteResetCommandsByTenant()
        });
        this.subscriptionControllerType = this.defaults.controllerTypeMessageChanges$.subscribe(() => { this.getRemoteResetCommandsByTenant() });

        if (this.defaults.tenant) {
            this.getRemoteResetCommandsByTenant()
        }
    }
    ngOnChanges() {
        this.getRemoteResetCommandsByTenant()
    }

    ngOnDestroy() {
        this.subscriptionTenant.unsubscribe();
        this.subscriptionControllerType.unsubscribe();
    }

    onReload($event: RemoteResetCommand) {
        //this.onReloadTemplate.emit({});
        //const local = this.mergedList
        //const local2: any[] = []
        //this.mergedList = []
        //local.forEach(x => {
        //    if (x.CommandId === $event.CommandId) {
        //        x.Active = $event.Active;
        //        x.FirstActivationUtc = $event.FirstActivationUtc;
        //        x.LastChangeUtc = $event.LastChangeUtc;
        //    }
        //    local2.push(x)
        //})
        //this.mergedList = local2
    }

    getRemoteResetCommandsByTenant() {
        Utils.httpGetRemoteResetCommandConfig(
            this.http
            , this.baseUrl
            , this
            , function (tthis: RemoteResetCommandComponent, list: RemoteResetCommand[]) {
                tthis.list = list;
                //const tempList: any[] = []
                ////tthis.commandList.forEach(x => {
                ////    const item = tthis.list.find(y => y.CommandId === x.CommandId);
                ////    tempList.push({
                ////        'Active': item ? item.Active : false,
                ////        'FirstActivationUtc': item ? item.FirstActivationUtc : '',
                ////        'LastChangeUtc': item ? item.LastChangeUtc : '',
                ////        ...x
                ////    })
                ////});
                //tthis.mergedList = tempList
            }
        );
    }
}
