import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'bold'
})

export class MakeBold implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) { }
    transform(value: any, args: string): any {
        let filters = args.split('+')
        if (!value)
            return value;
        {
            value = value.toString()
        }
        if (!args) {
            return value;
        }

        let sanitizeValue = value
        // Match in a case insensitive maneer
        filters.forEach(filter => {
            const re = new RegExp(filter, 'gi');
            const match = value.match(re);
            // If there's no match, just return the original value.
            if (!match) {
                return value;
            }
            sanitizeValue = sanitizeValue.replace(re, "<b style='color:#ff8708'>$&</b>")
        })
        return this.sanitizer.bypassSecurityTrustHtml(sanitizeValue)
    }
}