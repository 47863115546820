<br />
<div class="flex-center">
    <div class="contentWidth">
        <div style="height: calc(90vh - 100px); overflow-y: auto;">
            <form [formGroup]="CSRForm" autocomplete="off">
                <ng-container formArrayName="CSRRows">
                    <table #objTable
                           mat-table
                           matSort
                           [dataSource]="dataSource"
                           class="table-add-butt-header-orange mat-elevation-z8 mat-table-custom">

                        <ng-container matColumnDef="headertitle">
                            <th class="title-col" mat-header-cell *matHeaderCellDef colspan="5">
                                <div class="pull-left">
                                    <h4>NVStruct - Masterdata</h4>
                                </div>
                            </th>
                        </ng-container>
                        <ng-container matColumnDef="headeraddbutton">
                            <th class="butt-col" mat-header-cell *matHeaderCellDef>
                                <div class="OrangeButton">
                                    <button mat-raised-button (click)="addNewRow()"
                                            matTooltip="Add Release" class="button-color-fix pull-right">
                                        <mat-icon class="icon-color-fix icon-padding-fix">add_box</mat-icon>
                                    </button>
                                </div>
                            </th>
                        </ng-container>

                        <!-- RuleFileVersion Column -->
                        <ng-container matColumnDef="NVStructSchemaVersion">
                            <th mat-header-cell *matHeaderCellDef style="width:15%">NVStruct Schema Version</th>
                            <td mat-cell *matCellDef="let element; let i = index" style="width:15%">
                                <div [innerHTML]="CSRForm?.get('CSRRows')?.value[i].nvStructSchemaVersion" *ngIf="CSRForm?.get('CSRRows')?.value[i]?.isEditable"></div>
                                <mat-form-field style="width: 100%" *ngIf="!CSRForm?.get('CSRRows')?.value[i]?.isEditable">
                                    <input matInput type="text"
                                           [ngModelOptions]="{standalone: true}"
                                           [(ngModel)]="CSRForm?.get('CSRRows')?.value[i].nvStructSchemaVersion"
                                           maxlength="30"
                                           matTooltip="Max 30 characters"
                                           (keydown)="checkInput($event)" />
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <!--ControllerSoftwareVersion Column-->
                        <ng-container matColumnDef="ControllerSoftwareVersion">
                            <th mat-header-cell *matHeaderCellDef style="width:25%">Controller Software Version </th>
                            <td mat-cell *matCellDef="let element; let i = index" style="width:25%">
                                <div [innerHTML]="getControllerSoftwareVersionbyId(CSRForm?.get('CSRRows')?.value[i].controllerSoftwareVersionID)" *ngIf="CSRForm?.get('CSRRows')?.value[i]?.isEditable"></div>
                                <mat-form-field floatLabel="never" *ngIf="!CSRForm?.get('CSRRows')?.value[i]?.isEditable">
                                    <mat-select style="min-width: 200px; color:red" [(ngModel)]="CSRForm?.get('CSRRows')?.value[i].controllerSoftwareVersionID"
                                                [ngModelOptions]="{standalone: true}">
                                        <mat-option [value]="-1" [disabled]="true">Select</mat-option>
                                        <mat-option *ngFor="let fc of originalSoftwareReleases" [value]="fc.controllerSoftwareVersionID">
                                            {{fc.controllerSoftwareVersion}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <!-- NVStruct file Column -->
                        <ng-container matColumnDef="file">
                            <th mat-header-cell *matHeaderCellDef style="width:30%">NVStruct File</th>
                            <td mat-cell *matCellDef="let element; let i = index" style="width:30%">
                                <div matTooltip={{getRuleFile(i)}} *ngIf="CSRForm?.get('CSRRows')?.value[i]?.isEditable">
                                    {{truncateFile(CSRForm?.get('CSRRows')?.value[i]?.nvStructFile,20)}}
                                </div>
                                <input type="file" accept=".xml" *ngIf="!CSRForm?.get('CSRRows')?.value[i]?.isEditable"
                                       class="form-control"
                                       (change)="onFileChange($event, i)" />
                            </td>
                        </ng-container>

                        <!-- UseforConfigGen Column -->
                        <ng-container matColumnDef="UseforConfigGen">
                            <th mat-header-cell *matHeaderCellDef>Use for ConfigGeneration</th>
                            <td mat-cell *matCellDef="let element; let i = index">
                                <mat-checkbox (change)="onCheckboxChange($event,i)"
                                              [checked]="CSRForm.get('CSRRows')?.value[i]?.useForConfigGeneration"
                                              [disabled]="CSRForm.get('CSRRows')?.value[i]?.isEditable">
                                </mat-checkbox>
                                <!-- </span> -->
                            </td>
                        </ng-container>

                        <!-- Release Date Column -->
                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef style="width:20%">UTC Date</th>
                            <td mat-cell *matCellDef="let element; let i = index" style="width:20%">
                                <div [innerHTML]="datePipe.transform(CSRForm?.get('CSRRows')?.value[i]?.date,'yyyy-MM-dd')" *ngIf="CSRForm?.get('CSRRows')?.value[i]?.isEditable"></div>
                                <mat-form-field *ngIf="!CSRForm?.get('CSRRows')?.value[i]?.isEditable">
                                    <input matInput [matDatepicker]="picker"
                                           [ngModelOptions]="{standalone: true}"
                                           [(ngModel)]="CSRForm.get('CSRRows')?.value[i].date"
                                           (dateChange)="getDateUTCChange($event, i)" />
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                            </td>
                        </ng-container>


                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef style="width:5%"></th>
                            <td mat-cell
                                *matCellDef="let element; let i = index" style="width:5%">

                                <button mat-icon-button
                                        *ngIf="!CSRForm.get('CSRRows')?.value[i]?.isEditable"
                                        matTooltip="Cancel Changes"
                                        color="warn"
                                        (click)="resetRow(i)">
                                    <mat-icon class="actionButtons">rotate_left</mat-icon>
                                </button>

                                <button mat-icon-button
                                        *ngIf="CSRForm.get('CSRRows')?.value[i]?.isEditable"
                                        matTooltip="Edit"
                                        color="primary"
                                        (click)="editRow(i)">
                                    <mat-icon class="actionButtons">edit</mat-icon>
                                </button>

                                <button mat-icon-button
                                        *ngIf="false"
                                        matTooltip="Delete"
                                        color="warn"
                                        (click)="deleteRow(i)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="savefooter">
                            <th mat-footer-cell *matFooterCellDef colspan="6">
                                <div class="OrangeButton">
                                    <button mat-raised-button type="submit" (click)="onSave()"
                                            [disabled]="!anyChange()"
                                            [matTooltip]="anyChange()? 'Save changes' : 'Save disabled'"
                                            class="button-color-fix pull-right">
                                        Save
                                        <mat-icon style="color:white !important" class="icon-color-fix">save</mat-icon>
                                    </button>
                                </div>
                            </th>

                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="['headertitle', 'headeraddbutton']; sticky: true" class="stickyTopz100"></tr>
                        <tr class="col-headers" mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; let i = index; columns: columnsToDisplay;" [class.alternative2]="!CSRForm.get('CSRRows')?.value[i]?.isEditable"></tr>
                        <tr mat-footer-row *matFooterRowDef="['savefooter']; sticky: true"></tr>
                    </table>
                </ng-container>
            </form>
        </div>
    </div>
</div>