import { Inject, Injectable } from '@angular/core';
import * as signalR from "@aspnet/signalr";  // or from "@microsoft/signalr" if you are using a new library
import { Subscription } from 'rxjs';
import { Utils } from '../utils/utils';
import { DialogService } from './dialog.service';
import { WaitingService } from './waiting.service';
import { MsalService } from '@azure/msal-angular';
import { protectedResources } from '../auth-config';

@Injectable({
    providedIn: 'root'
})
export class SignalRService {

    private hubConnection: signalR.HubConnection | undefined
    private spinnerSubscription: Subscription | undefined;
    filename: string = "";

    constructor(
        private confirmDialog: DialogService
        , private waiting: WaitingService
        , @Inject('BASE_URL') private baseUrl: string,
        private msalService: MsalService
    ) {
        this.confirmDialog.tthis = this;
    }

    onBack() { }

    public startConnection = (hub: number) => {
        switch (hub) {
            case 0:
                this.hubConnection = new signalR.HubConnectionBuilder()
                    .withUrl(this.baseUrl + 'realtimedownloadfile', {
                        accessTokenFactory: () => {
                            return this.getAccessToken();
                        }
                    })
                    .build(); break;
            default:
                this.hubConnection = new signalR.HubConnectionBuilder()
                    .withUrl(this.baseUrl + 'virtualprovisioning/provision', {
                        accessTokenFactory: () => {
                            return this.getAccessToken();
                        }
                    }).build(); break;
            //default: break;
        }
        return this.hubConnection.start()
    }

    public closeConnection = () => {
        this.confirmDialog.tthis = this;
        this.confirmDialog.onBack()
        if (this.hubConnection)
            this.hubConnection
                .stop()
                .then(() => { console.log('Connection finished'); })
                .catch((err: any) => console.log('Error while closing connection: ' + err))
    }

    // Download elevators methods
    public initDownloadFile = (filter: ElevatorFilter, stage: number, tenantId: string, filename: string) => {
        if (this.hubConnection)

            this.hubConnection.invoke('downloadelevators', filter, stage, tenantId, filename).catch((e: any) => { });
        //this.spinnerSubscription = this.spinnerOverlayService.spinner$.subscribe();
    }

    public addFileNameListener = () => {
        if (this.hubConnection)
            this.hubConnection.on('filename', (data: any) => {
                this.filename = data
            })
    }

    public addStatusTracesListener = () => {
        if (this.hubConnection)
            this.hubConnection.on('statustraces', (data: DownloadElevatorStatus) => {
                this.confirmDialog.showInfoBackgroundDialog(`Getting data from '${data.currentBranchName}' branch.`, undefined, `${data.currentBranch} / ${data.totalBranches}`)
                if (data.currentBranch === data.totalBranches) {
                    this.confirmDialog.showInfoBackgroundDialog(`Downloading file ... This could take a few minutes `)
                }
            })
    }

    public addDownloadDataListener = () => {
        if (this.hubConnection)
            this.hubConnection.on('downloadfile', (data: any) => {
                var test = Utils.base64ToBlob(data, 'application/vnd.openxmlformats');
                var anchor = document.createElement('a');
                anchor.download = this.filename;
                anchor.href = (window.webkitURL || window.URL).createObjectURL(test);
                anchor.dataset['downloadurl'] = ['application/vnd.openxmlformats', anchor.download, anchor.href].join(':');
                anchor.click();
                if (this.spinnerSubscription)
                    this.spinnerSubscription.unsubscribe();
                this.confirmDialog.showSuccessDialog(`File downloaded!`)
                this.closeConnection()
            })
    }


    public addExceptionListener = () => {
        if (this.hubConnection)
            this.hubConnection.on('exception', (message: string) => {
                this.confirmDialog.showErrorDialog({ 'error': { 'message': 'Error downloading file - ' + message + 'Please, contact admin for more information.' } })
                if (this.spinnerSubscription)
                    this.spinnerSubscription.unsubscribe();
                this.closeConnection()
            })
    }


    // Provision new unit

    public initNewProvision = (body: any, stage: number, manyToOne: boolean) => {
        if (this.hubConnection)
            this.hubConnection.invoke('ExecuteProvisionProcess', body, stage, manyToOne).catch((e: any) => { });
        //this.spinnerSubscription = this.spinnerOverlayService.spinner$.subscribe();
    }

    public getTraceListener = () => {
        if (this.hubConnection)
            this.hubConnection.on('statustraces', (data: any) => {
                this.confirmDialog.showInfoBackgroundDialog(data)
            })
    }
    public finishConnection = (tthis: any | undefined = undefined, callback: any | undefined = undefined) => {
        if (this.hubConnection)
            this.hubConnection.on('provisionfinish', (data: any) => {
                if (this.spinnerSubscription)
                    this.spinnerSubscription.unsubscribe();
                this.closeConnection();
                if (tthis && callback)
                    callback(tthis, data);
            })
    }

    private getAccessToken(): Promise<string> {
        let activeAccount = this.msalService.instance.getActiveAccount();

        if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
            let accounts = this.msalService.instance.getAllAccounts();
            // add your code for handling multiple accounts here
            this.msalService.instance.setActiveAccount(accounts[0]);
        }
        return this.msalService.acquireTokenSilent({
            scopes: [protectedResources.api.scopes.read[0]],
        }).toPromise()
            .then((response: any) => {
                return response.accessToken;
            })
            .catch(error => {
                console.error('Error al obtener el token:', error);
                throw error;
            });
    }
}