import { Component, Inject, Input, OnInit, OnChanges, OnDestroy, EventEmitter, Output } from "@angular/core";
import { DialogService } from "../../../../services/dialog.service";
import { HttpClient } from "@angular/common/http";
import { DefaultValuesService } from "../../../../services/defaultvalues.service";
import { MatDialog } from "@angular/material/dialog";
import { CustomDialogService } from '../../../../services/customdialog.service';
import { animate, state, style, transition, trigger } from "@angular/animations";
import { KpiDetailFormComponent } from "../kpidetail-form.component";
import { MatTableDataSource } from "@angular/material/table";
import { Subject } from "rxjs";
import { Utils } from "../../../../utils/utils";
import { SelectionModel } from "@angular/cdk/collections";



@Component({
    selector: "oplkpimanagement-list",
    templateUrl: './oplkpimanagement-list.component.html',
    styleUrls: ['./oplkpimanagement-list.component.less',
        '../../../../style/new-generic-styles.component.less'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*', })),
            transition('expanded => collapsed', [animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
        ]),
    ]

})

export class OPLKPIManagementListComponent implements OnInit, OnChanges, OnDestroy {
    @Input('list') list: KpiManagement[] = [];
    @Input('filter') filter: string = ''
    @Input('inclusiveMode') inclusiveMode: boolean = false
    @Input('selectorFilter') selectorFilter: string = ''

    @Input('isEditModeEnabled') isEditModeEnabled: boolean = false;
    @Input('isApprovalModeEnabled') isApprovalModeEnabled: boolean = false;

    @Input('auxFilterPredicate') auxFilterPredicate!: (filters: string, data: any, parentLevel: boolean, showAllEnabled: boolean) => boolean;
    @Input('isShowAllEnabled') isShowAllEnabled: boolean = false;

    @Input('isRevokeActive') isRevokeActive: boolean = false;
    @Input('isReleaseActive') isReleaseActive: boolean = false;
    @Output() onClickReleaseMode: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onClickRevokeMode: EventEmitter<boolean> = new EventEmitter<boolean>();
    
    currentFY: string | undefined;
    FY1: string | undefined;
    FY2: string | undefined;
    FY3: string | undefined;

    columnsToDisplay = ['arrow', 'Account', 'KpiName', 'KpiOwner', 'KpiUnit', 'LocalCurrency', 'AvailabilityTime', 'ReportingType', 'actions'];

    public dataSource: MatTableDataSource<KpiManagement>;

    expandedElements = new Set();
    isAllCollapsed = false;
    Filter = 0;

    protected _onDestroy = new Subject<void>();

    updatedItems: KpiManagementUpdate[] = []

    releaseList: selectedReleaseRow[] = []
    selection = new SelectionModel<UserSummary>(true, []);
    constructor(
        private http: HttpClient
        , private dialogService: DialogService
        , private defaults: DefaultValuesService
        , @Inject('BASE_URL') private baseUrl: string
        , private customDialog: CustomDialogService
        , private dialogForm: MatDialog

    ) {
        this.dataSource = new MatTableDataSource<KpiManagement>();
    }
    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    ngOnChanges(changes: any) {
        if (changes.list) {
            this.getFiscalYearHeaders();
            this.dataSource.data = Utils.deepCopyArray(this.list)
            this.expandedElements.clear()
            this.updatedItems = [];
        }

        if (changes.filter || changes.inclusiveFilter || changes.selectorFilter) {
            this.dataSource.filter = this.filter;
        }

        if (changes.isApprovalModeEnabled) {
            this.expandedElements.clear()
            this.updatedItems = []
        }
    }

    ngOnInit() {
        this.dialogService.tthis = this;
        this.dataSource.filterPredicate = (data: KpiManagement, filters: string) => {
            if (this.selectorFilter === '0') {  // Just applying filter on first level
                if (data.subtable)
                    data.subtable.filter = this.filter
                return this.auxFilterPredicate(filters, data, true, this.isShowAllEnabled)
            }
            else {
                if (!data.subtable)
                    this.createMatSubtable(data)
                data.subtable.filter = this.filter
                if (data.subtable.filteredData.length > 0 && this.filter[0] !== '+')
                    this.expandElement(data)
                return this.auxFilterPredicate(filters, data, true, this.isShowAllEnabled) || data.subtable.filteredData.length > 0
            }
        };
        this.dataSource.data = Utils.deepCopyArray(this.list)
        this.dataSource.filter = this.filter;
    }

    checkExpanded(element) {
        return this.expandedElements.has(element.AccountID);
    }

    pushPopElement(element) {
        if (this.expandedElements.has(element.AccountID)) {
            this.expandedElements.delete(element.AccountID);
        }
        else {
            this.expandElement(element.AccountID)
            if (!element.subtable) {
                this.createMatSubtable(element)
                element.subtable.filter = this.filter
            }
        }
    }

    collapseOrExpandAll() {
        if (this.isAllCollapsed === false) {
            this.isAllCollapsed = true;
            this.dataSource.data.forEach((el: any) => {
                if (!this.expandedElements.has(el)) {
                    if (!el.subtable) {
                        this.createMatSubtable(el)
                    }
                    this.expandElement(el.AccountID)
                }
            })
        }
        else {
            this.isAllCollapsed = false;
            this.expandedElements.clear()
        }
    }

    private createMatSubtable(el: any) {
        let dataSource = new MatTableDataSource<any>();
        dataSource.data = el.KpiDetail
        dataSource.filterPredicate = (data2: any, filters: string) => {
            return this.auxFilterPredicate(filters, data2, false, this.isShowAllEnabled)
        }
        el.subtable = dataSource
    }

    private expandElement(el: any) {
        this.expandedElements.add(el);
    }

    onChange(account: any, breakdown: any, $e: any, field: string) {
        let tempObject: KpiManagementUpdate = {
            'KpiId': breakdown.KpiId,
            'KpiMovementTypeId': breakdown.KpiMovementTypeId,
            'KpiProductHierarchyId': breakdown.KpiProductHierarchyId,
            'KpiVersion': 'BUDGET',
            'KpiDefinitionId': account.KpiDefinitionId,
            'KpiBreakdown1Id': breakdown.KpiBreakdown1Id,
            'KpiBreakdown2Id': breakdown.KpiBreakdown2Id,
            'KpiValue': parseFloat($e).toFixed(account.DecimalDigits),
            'Field': field,
            'prev_FYFC': breakdown.prev_FYFC !== null && breakdown.prev_FYFC !== undefined ? breakdown.prev_FYFC : 0,
            FY1BUD: "",
            ReportingType: "",
            SumMonthlyValues: 0,
            ApprovalStatus: field !== 'FYFC' ? breakdown.ApprovalStatus : 'NEW',
            KpiUnit: account.KpiUnit,
            DecimalDigits: account.DecimalDigits,
            auxFYFC : breakdown.FYFC,
            IsAccumulative: breakdown.IsAccumulative,
            AccountId: account.AccountID

        }
        this.addUpdatedItem(tempObject);

        if (field === 'FYFC') {
            this.addUpdatedItem({
                'KpiId': breakdown.KpiId,
                'KpiMovementTypeId': breakdown.KpiMovementTypeId,
                'KpiProductHierarchyId': breakdown.KpiProductHierarchyId,
                'KpiVersion': 'BUDGET',
                'KpiDefinitionId': account.KpiDefinitionId,
                'KpiBreakdown1Id': breakdown.KpiBreakdown1Id,
                'KpiBreakdown2Id': breakdown.KpiBreakdown2Id,
                'KpiValue': parseFloat(breakdown.FY1BUD).toFixed(account.DecimalDigits),
                'Field': 'FY1BUD',
                'prev_FYFC': breakdown.prev_FYFC !== null && breakdown.prev_FYFC !== undefined ? breakdown.prev_FYFC : 0,
                FY1BUD: "",
                ReportingType: "",
                SumMonthlyValues: 0,
                ApprovalStatus: field !== 'FYFC' ? breakdown.ApprovalStatus : 'NEW',
                KpiUnit: account.KpiUnit,
                DecimalDigits: account.DecimalDigits,
                auxFYFC: breakdown.FYFC,
                IsAccumulative: breakdown.IsAccumulative,
                AccountId: account.AccountID
            });
        }
    }

    private addUpdatedItem(newObject: KpiManagementUpdate) {
        const index = this.updatedItems.findIndex(
            (obj) =>
                obj.KpiDefinitionId === newObject.KpiDefinitionId &&
                obj.KpiBreakdown1Id === newObject.KpiBreakdown1Id &&
                obj.KpiBreakdown2Id === newObject.KpiBreakdown2Id &&
                obj.KpiMovementTypeId === newObject.KpiMovementTypeId &&
                obj.KpiProductHierarchyId === newObject.KpiProductHierarchyId &&
                obj.KpiId === newObject.KpiId &&
                obj.KpiVersion === newObject.KpiVersion &&
                obj.Field === newObject.Field
        );

        if (index !== -1) {
            if (newObject.KpiValue === 'NaN')
                this.updatedItems.splice(index, 1)
            else
                this.updatedItems[index] = newObject;
        } else {
            this.updatedItems.push(newObject);
        }
    }

    formatCurrency(value, decimalDigits): string {
        if (value == undefined)
            return '';
        else
            return parseFloat(value).toLocaleString('de', { maximumFractionDigits: decimalDigits });
    }

    //onDetail(element: any) {

    //    const Dialog = this.dialogForm.open(KpiDetailFormComponent, {
    //        panelClass: 'custom-mat-dialog',
    //        disableClose: false,
    //        data: { "KpiDefinitionId": element.KpiDefinitionId }
    //    });
    //    Dialog.afterClosed().subscribe(result => {
    //    });
    //}

    onDetail(element: any) {

        var KpiId = "null";

        // Truko
        //KpiId = '206288';
        

        if (element.KpiId != undefined)
            KpiId = element.KpiId;

        const Dialog = this.dialogForm.open(KpiDetailFormComponent, {
            panelClass: 'custom-mat-dialog',
            disableClose: false,
            data: {
                "KpiDefinitionId": element.KpiDefinitionId,
                "KpiId": KpiId,
                "KpiBreakDown1Id": element.KpiBreakDown1Id,
                "KpiBreakDown2Id": element.KpiBreakDown2Id,
                "KpiMovementTypeId": element.KpiBreakDown2Id,
                "KpiPorductHierarchyId": element.KpiPorductHierarchyId,
                "DataType": 'BUDY'
            }
        });
        Dialog.afterClosed().subscribe(() => {
        });

    }



    private getFiscalYearHeaders() {
        this.FY3 = (this.defaults.fiscalYear + 2).toString() + '-' + (this.defaults.fiscalYear + 3).toString();
        this.FY2 = (this.defaults.fiscalYear + 1).toString() + '-' + (this.defaults.fiscalYear + 2).toString();
        this.FY1 = (this.defaults.fiscalYear).toString() + '-' + (this.defaults.fiscalYear + 1).toString();
        this.currentFY = (this.defaults.fiscalYear - 1).toString() + '-' + (this.defaults.fiscalYear).toString();
    }


    disableArrowKeys(event: KeyboardEvent) {
        if (
            event.key === 'ArrowUp' ||
            event.key === 'ArrowDown'
        ) {
            event.preventDefault();
        }
    }

    public onBack() {
    }

    mapReleaseRow(row) {
        var selectedReleaseRow: selectedReleaseRow = {
            KpiDefinitionId: row.KpiDefinitionId,
            KpiMovementTypeId: row.KpiMovementTypeId,
            KpiProductHierarchyId: row.KpiProductHierarchyId,
            KpiBreakdown1Id: row.KpiBreakdown1Id,
            KpiBreakdown2Id: row.KpiBreakdown2Id

        };
        return selectedReleaseRow;
    }
  
    accountCheckboxCheck(row) {
        var Checked = row.KpiDetail.every((row) => {
            var mappedRow = this.mapReleaseRow(row);
            return this.releaseList.some((object) => this.objectsEqual(mappedRow, object));
        })
        return Checked
    }

    accountItemsIndetermine(row) {
        var Indeterminate = this.releaseList.some((selected) => (selected.KpiDefinitionId === row.KpiDefinitionId))
        return Indeterminate;
    }
    accountItemsDisable(row) {
        //console.log(row)
        var Disable = !row.KpiDetail.some((item) =>
        {
            return (this.isReleaseActive ? (item.ApprovalStatus == "NEW") :
        (item.ApprovalStatus == "PRE-RELEASED"))
        }
        )
        return Disable;
    }

    accountItemsSelected(ischecked, row) {
        row.KpiDetail.forEach((item) => {
            this.isReleaseActive ? (item.ApprovalStatus == "NEW" ? this.expandedItemSelected(ischecked, item) : null ):
                (item.ApprovalStatus == "PRE-RELEASED" ? this.expandedItemSelected(ischecked, item) : null)
        });

    }
    expandedItemSelected(ischecked, row) {
        if ((this.isReleaseActive || this.isRevokeActive) && (this.isReleaseActive ? (row.ApprovalStatus == 'NEW') : (row.ApprovalStatus == 'PRE-RELEASED'))) {
            var selectedReleaseRow = this.mapReleaseRow(row);
            if (ischecked) {
                this.selection.select(row)
                this.onSelect(selectedReleaseRow);
            } else {
                this.selection.deselect(row)
                this.onDeselect(selectedReleaseRow);
            } }
       
    }
    onSelect(selectedReleaseRow) {
        //An extra layer of protection to not to add already selected rows (Implemented but not active) - I'll kill you :)'
        if (!this.releaseList.some((object) => this.objectsEqual(object, selectedReleaseRow))){
            this.releaseList.push(selectedReleaseRow)
        }

    }
    onDeselect(selectedReleaseRow) {
        var mappedRow = this.mapReleaseRow(selectedReleaseRow)
        const index = this.releaseList.findIndex((object) => this.objectsEqual(mappedRow, object));
        if (index !== -1) {
            this.releaseList.splice(index, 1);
        }
    }

    objectsEqual(object1, object2) {
        if (object1 === object2) {
            return true;
        }
        if (Object.keys(object1).length !== Object.keys(object2).length) {
            return false;
        }
        for (const property in object1) {
            if (object1[property] !== object2[property]) {
                return false;
            }
        }
        return true;
    }
    getSelectionList() {
        return this.releaseList;
    }
    clearSelectionList() {
        this.releaseList = [];
        this.selection?.clear();
    }
    onClickReleaseMode_emitter(yearly: boolean) {
        this.onClickReleaseMode.emit(yearly);
    }
    onClickRevokeMode_emitter(yearly: boolean) {
        this.onClickRevokeMode.emit(yearly);
    }
    anyActionableItemExist() {
        //console.log(this.list);
        return this.isReleaseActive ?
            this.list.some((kpi) => kpi.KpiDetail.some((kpidef) => (kpidef.ApprovalStatus == "NEW"))) :
            this.list.some((kpi) => kpi.KpiDetail.some((kpidef) => (kpidef.ApprovalStatus == "PRE-RELEASED")))
    }
    textRelRevbuttonState() {
        return this.anyActionableItemExist() ?
            (((this.isReleaseActive || this.isRevokeActive && this.isReleaseActive) ? 'Release ' : 'Revoke ') + (!this.selection.hasValue() ? 'All' : 'Selected')) :
            (this.isReleaseActive ? 'Nothing to Release' :'Nothing to Revoke')
    }


}
export interface SearchItem {
    name: string;
}