import { Component, Inject, OnChanges, Input, Output, EventEmitter } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from "@angular/material/chips";

/************************************************
 *      API Url
 * */
const API_CHANGELOG = "api/changelog/all";

@Component({
    selector: "changelog-list",
    templateUrl: './changelog-list.component.html',
    styleUrls: ['../../style/list.component.less', '../../style/mat-table.component.less', './changelog-list.component.less']
})

export class ChangeLogListComponent{
    title: string;
    @Input() list: ChangeLog[] = [];

    columnsToDisplay = ['Description', 'Action', 'Date', 'UserUPN'];
    public dataSource = new MatTableDataSource<ChangeLog>();

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [COMMA, TAB, ENTER];
    searchItems: SearchItem[] = [];

    constructor() {

        this.title = "Changelog";
        this.dataSource.filterPredicate = (data: ChangeLog, filters: string) => {
            const matchFilter: any[] = [];
            const filterArray = filters.split('+').filter(x => x.indexOf('notextfilters') < 0)
            if (filterArray.length > 0) {
                filterArray.forEach((filter) => {
                    let result = false;
                    const desc = data.Description ? data.Description : ''
                    const action = data.Action ? data.Action : ''
                    const upn = data.UserUPN ? data.UserUPN : ''
                    result = desc.toString().toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
                        action.toString().toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
                        upn.toString().toLowerCase().indexOf(filter.toLowerCase()) > -1

                    return matchFilter.push(result);
                });
            }
            else {
                matchFilter.push(true)
            }
            return matchFilter.some(Boolean);
        };
    }

    ngOnChanges() {
        this.dataSource.data = this.list;
    }

    applyFilter(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            this.searchItems.push({ name: value.replace('+', '').trim() });
        }
        // Reset the input value
        if (input) {
            input.value = '';
        }
        this.updatefilter()
    }
    remove(item: SearchItem): void {
        const index = this.searchItems.indexOf(item);
        if (index >= 0) {
            this.searchItems.splice(index, 1);
        }
        this.updatefilter()
    }
    updatefilter() {
        let filterString: string
        if (this.searchItems.length === 0) {
            this.dataSource.filter = 'notextfilters'
        }
        else {
            filterString = this.searchItems.map(e => { return e.name }).join('+')
            this.dataSource.filter = filterString
        }
    }
}
export interface SearchItem {
    name: string;
}