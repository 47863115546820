<h2>{{title}}</h2>
<div style="display:flow-root">
    <div style="float:left; width:65%">
        <mat-form-field class="search-input">
            <input placeholder="Filter"
                   matInput
                   [matChipInputFor]="chipList"
                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                   [matChipInputAddOnBlur]="addOnBlur"
                   (matChipInputTokenEnd)="applyFilter($event)">
            <mat-chip-list #chipList>
            </mat-chip-list>
        </mat-form-field>
        <mat-chip *ngFor="let item of searchItems" [selectable]="selectable"
                  [removable]="removable" (removed)="remove(item)">
            {{item.name}}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>

    </div>
</div>
<div class="example-container new-table-style">

    <table mat-table
           [dataSource]="dataSource"
           multiTemplateDataRows
           matSort
           class="mat-elevation-z8 mat-table-custom" id="ChangeLog">


        <ng-container matColumnDef="Description">
            <th mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let log">
                <p [innerHTML]="log.Description | bold: dataSource.filter"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="Action">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let log">
                <p [innerHTML]="log.Action | bold: dataSource.filter"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="Date">
            <th mat-header-cell *matHeaderCellDef> Date </th>
            <td mat-cell *matCellDef="let log">
                <p [innerHTML]="log.Date | date:'yyyy-MM-dd HH:mm'"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="UserUPN">
            <th mat-header-cell *matHeaderCellDef> UserUPN </th>
            <td mat-cell *matCellDef="let log">
                <p [innerHTML]="log.UserUPN | bold: dataSource.filter"></p>
            </td>
        </ng-container>

        <tr mat-header-row class="tableheader" *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let account; let i = dataIndex; columns: columnsToDisplay;" class="item"></tr>
    </table>
</div>