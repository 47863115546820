<div class="custom-mat-dialog-form">
    <button mat-icon-button class="right" [mat-dialog-close]="true">
        <mat-icon>close</mat-icon>
    </button>
    <div>
        <h3>Contract Line Harmonization</h3>
    </div>
    <div class="example-container-controllerharmonization example-container">
        <!--<button mat-icon-button (click)="btn_refresh()" matTooltip="Refresh the current table">
            <mat-icon>refresh</mat-icon>
        </button>-->
        <!--<button mat-icon-button (click)="btn_new()" matTooltip="New Row" >
            <mat-icon aria-label="Add new element to the current table">add_circle</mat-icon>
        </button>-->

        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>

        <table mat-table
               matSort
               [dataSource]="dataSource"
               class="mat-elevation-z8 mat-table-custom"
               id="contractline-harmonization">

            <ng-container matColumnDef="ItemNo">
                <th mat-header-cell *matHeaderCellDef> Item no</th>
                <td mat-cell *matCellDef="let element;index as i;">
                    <p [innerHTML]="(i+1)"></p>
                </td>
            </ng-container>
            <ng-container matColumnDef="TenantId">
                <th mat-header-cell *matHeaderCellDef> MAPPING ID </th>
                <td mat-cell *matCellDef="let element">
                    <p [innerHTML]="element.TenantId"></p>
                </td>
            </ng-container>
            <ng-container matColumnDef="ContractLineId">
                <th mat-header-cell *matHeaderCellDef> MAPPING ID </th>
                <td mat-cell *matCellDef="let element">
                    <p [innerHTML]="element.ContractLineId"></p>
                </td>
            </ng-container>
            <ng-container matColumnDef="LineLocale">
                <th mat-header-cell style="text-align:left" *matHeaderCellDef> Contract Line (LOCAL NAME) </th>
                <td mat-cell *matCellDef="let element">
                    <p [innerHTML]="element.LineLocale"></p>
                </td>
            </ng-container>
            <ng-container matColumnDef="ContractLine">
                <th mat-header-cell style="text-align:left" *matHeaderCellDef> Contract Line (<b>HARMONIZED</b> NAME) </th>
                <td mat-cell *matCellDef="let element;index as i;">
                    <p [innerHTML]="element.ContractLine"></p>
                </td>
            </ng-container>
            <ng-container matColumnDef="isNew">
                <th mat-header-cell *matHeaderCellDef>
                    Harmonization Status<br />
                    <mat-select id="DDFilter" class='form-control filter' placeholder='ALL' (selectionChange)="filterColumn($event, 'status')">
                        <mat-option [value]="0">ALL</mat-option>
                        <mat-option value="1">Pending</mat-option>
                        <mat-option value="2">Harmonized</mat-option>
                    </mat-select>
                </th>
                <td class="statuscolumn" mat-cell *matCellDef="let element;index as i;">
                    <span *ngIf="element.ContractLineId == null">
                        <mat-icon>fiber_new</mat-icon>
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> Actions </th>
                <td class="actionscolumn th-left" mat-cell *matCellDef="let element">
                    <!--<button mat-icon-button matTooltip="More info"
                            (click)="onMoreInfo(element)">
                        <mat-icon>info</mat-icon>
                    </button>-->
                    <button mat-icon-button matTooltip="Edit Row"
                            (click)="onRowEditInit(element)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button *ngIf="element.ContractLineId != null" mat-icon-button matTooltip="Delete harmonized correspondence"
                            (click)="onDelete(element)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </td>
            </ng-container>



            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let element; index as i;
            columns: columnsToDisplay;"
                class="opmode-item">
            </tr>
        </table>
    </div>
</div>