import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import "rxjs/operators";
import { Data, Router } from '@angular/router';
import { DialogService } from '../../../../services/dialog.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Utils } from '../../../../utils/utils';
import { DefaultValuesService } from '../../../../services/defaultvalues.service';
import * as moment from 'moment';
import { DatePipe } from '@angular/common'

@Component({
    selector: "transmissionmode-form",
    templateUrl: './transmissionmode-form.component.html',
    styleUrls: ['../../../../style/azdatafactory.component.less', '../../../../style/custom-mat-dialog.component.less']

})

export class TransmissionModeFormComponent implements OnInit {

    selected = {} as ControllerTypeByTenant;

    controllerTypes: ControllerType[] = [];

    transmissionModes: string[] = ["Batch", "Real-Time", "Live"];

    isBatch: boolean;

    saveReady: boolean;
    errorTM: boolean;
    errorBI: boolean;
    errorBIvalue: boolean;

    body: any;
    title: string;
    submitted = false;
    form: FormGroup = new FormGroup({});



    constructor(private http: HttpClient
        , private confimDialog: DialogService
        , private router: Router
        , @Inject(MAT_DIALOG_DATA) public data: any
        , @Inject('BASE_URL') private baseUrl: string
        , public dialogRef: MatDialogRef<TransmissionModeFormComponent>
        , private fb: FormBuilder
        , private defaults: DefaultValuesService
        , private formBuilder: FormBuilder
        , private datepipe: DatePipe) {

        this.confimDialog.tthis = this;
        this.title = "";
        this.isBatch = false;
        this.saveReady = false;
        this.errorBI = false;
        this.errorBIvalue = false;
        this.errorTM = false;
    }

    ngOnInit(): void {
        //this.body = {
        //    SessionId: ''
        //}

        const tthis = this;

        Utils.httpGetAllControllerTypes(
            this.http
            , this.baseUrl
            , this
            , function (tthis: any, list: ControllerType[]) {

                list.unshift({
                    Description: "ALL CONTROLLERS ( * )",
                    BK: "*",
                    ControllerTypeID: 0,
                    DeployErrorCodes: false,
                    DeployOpModes: false,
                    IsUsingErrorCodes: false,
                    CountryOwnerName: "",
                    CountryOwnerCode: ""
                });

                tthis.controllerTypes = list;

            }
        );



        this.body = {
            ControllerType: this.data.ControllerType,
            DDTransmissionMode: this.data.TransmissionMode,
            TxtBatchInterval: this.data.BatchInterval,

        }


        this.form = this.formBuilder.group({
            ControllerType: [this.data.ControllerType],
            DDTransmissionMode: [this.data.TransmissionMode],
            TxtBatchInterval: [this.data.BatchInterval]
        });

        this.CheckSaveReady();
    }


    onTransmissionModeChange(evt: any): void {

        this.CheckSaveReady();

    }

    public CheckSaveReady() {
        if (this.f['DDTransmissionMode'].value == "BATCH")
            this.isBatch = true;
        else
            this.isBatch = false;

        this.saveReady = false;

        if (this.f['DDTransmissionMode'].value != "") {
            if (this.f['DDTransmissionMode'].value == "BATCH") {
                if (this.f['TxtBatchInterval'].value !== "" && this.f['TxtBatchInterval'].value !== null && this.f['TxtBatchInterval'].value >= 0 && this.f['TxtBatchInterval'].value <= 86400) {
                    this.saveReady = true;
                }
            }
            else
                this.saveReady = true;
        }

    }

    public CheckFields() {
        this.errorBI = false;
        this.errorBIvalue = false;
        this.errorTM = false;


        if (this.f['DDTransmissionMode'].value != "")
            this.errorTM = false;
        else
            this.errorTM = true;

        if (this.f['TxtBatchInterval'].value !== "" && this.f['TxtBatchInterval'].value !== null) {
            this.errorBI = false;

            if (this.f['TxtBatchInterval'].value >= 0 && this.f['TxtBatchInterval'].value <= 86400)
                this.errorBIvalue = false;
            else
                this.errorBIvalue = true;
        }
        else
            this.errorBI = true;


        return !this.errorBI && !this.errorBIvalue && !this.errorTM;
    }


    // convenience getter for easy access to form fields
    get f() {
        return this.form.controls;
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    onSubmit() {

        if (this.CheckFields()) {
            /*
             * If control exist already for the tenant data will be updated
             */
            this.submitted = true;

            let url = this.baseUrl + Utils.getTransmissionModeConfigurationAPI() + "/add";

            this.http
                .post<boolean>(url, { ControllerType: this.f['ControllerType'].value, TransmissionMode: this.f['DDTransmissionMode'].value, BatchInterval: this.f['TxtBatchInterval'].value })
                .subscribe(res => {
                    this.dialogRef.close(true);
                }, error => {
                    Utils.writeLog(this.http, this.baseUrl, error); //Utils.showErrorDialog(null, error);
                }); //console.error(error));
        }

    }

    onBack() {

    }



}

