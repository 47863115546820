import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { DialogService } from '../../services/dialog.service';
import { DefaultValuesService } from '../../services/defaultvalues.service';
import { SaveService } from '../../services/save.service';
import { Utils } from '../../utils/utils';
import { Router } from '@angular/router';

@Component({
    selector: 'oplversion',
    template: `OPL version:<br />
                <div style="width:120px; border: 1px white solid; text-align:center;">
                   <span>{{getLabel()}}</span>
                </div>`
})

export class OPLVersionComponent implements OnInit {
    protected _onDestroy = new Subject<void>();

    constructor(public defaults: DefaultValuesService

    ) {
    }

    async ngOnInit() {
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    getLabel() {
        return `PL${((this.defaults.fiscalYear - 1) % 10)}`
    }
}