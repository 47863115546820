import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Utils } from 'src/app/utils/utils';

@Component({
    selector: 'createexcellencelever-popup',
    templateUrl: './createexcellencelever-popup.component.html',
    styleUrls: ['./createexcellencelever-popup.component.less']
})
export class CreateExcellenceLeverPopupComponent implements OnInit {
    form!: FormGroup
    linesOfBusiness: any[] = []

    constructor(
        public dialogRef: MatDialogRef<CreateExcellenceLeverPopupComponent>,
        private formBuilder: FormBuilder,
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private toastrService: ToastrService
    ) { }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            excellenceLeverId: [this.data?.ExcellenceLeverId],
            name: [this.data?.Name, Validators.required],
            lineOfBusinessId: [this.data?.LineOfBusinessId, Validators.required],
            function: [this.data?.Function],
        });

        Utils.httpGetLinesOfBusiness(
            this.http
            , this.baseUrl
            , this
            , function (tthis: CreateExcellenceLeverPopupComponent, data: any[]) {
                tthis.linesOfBusiness = data;
            },
            null
        )
    }
    onSubmit() {
        // stop here if form is invalid
        if (this.form)
            if (this.form.invalid) {
                return;
            }

        Utils.httpUpsertExcellenceLever(
            this.http
            , this.baseUrl
            , this.form.value
            , this
            , function (tthis: CreateExcellenceLeverPopupComponent, result: any) {
                tthis.dialogRef.close({ event: 'submit' });
                if (result) {
                    tthis.toastrService.success('Excellence Lever was assigned successfully!');
                }
                else
                    tthis.toastrService.error('Excellence Lever could not be assigned. Please, contact with administrator');
            },
            null
        );
    }

    backButtonHandler(event: any) {
        event.preventDefault();
        this.dialogRef.close();
    }
}
