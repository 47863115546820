import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DefaultValuesService } from "../../services/defaultvalues.service";
import { SaveService } from "../../services/save.service";
import { HierarchySetupListComponent } from "./hierarchysetup-list.component";
import { CustomDialogService } from "../../services/customdialog.service";
import { Utils } from "../../utils/utils";

@Component({
    selector: "hierarchysetup",
    templateUrl: './hierarchysetup.component.html',
    styleUrls: ['../../style/container-tab-list.component.less']
})

export class HierarchySetupComponent implements OnInit {

    @ViewChild(HierarchySetupListComponent, { static: true }) itemsList!: HierarchySetupListComponent;

    alllist: BranchHierarchyKey[] = [];
    list: BranchHierarchyKey[] = [];
    countries: Country[] = [];
    timeoutFunction: any;
    subscriptionCountry: any;
    constructor(
        private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , public defaults: DefaultValuesService
        , private saveService: SaveService
        , private customDialog: CustomDialogService
    ) {

    }

    ngOnInit() {
        if (this.alllist.length < 1 && this.defaults.country)
            this.GetBranchHierarchyKeys(this.defaults.country)
        this.subscriptionCountry = this.defaults.countryMessageChanges$.subscribe(() => {
            this.GetBranchHierarchyKeys(this.defaults.country)
        });
    }
    GetBranchHierarchyKeys(country: string) {
        Utils.httpGetBranchHierarchyKeyByCountry(
            this.http
            , this.baseUrl
            , country
            , this
            , function (tthis: HierarchySetupComponent, listKey: BranchHierarchyKey[]) {
                tthis.alllist = tthis.list = listKey;
            }
        );
    }

    filter($event: any) {
        if (this.timeoutFunction) {
            clearTimeout(this.timeoutFunction);
            this.timeoutFunction = null;
        }
        this.timeoutFunction = setTimeout(() => {
            const filter = $event.target.value.trim()
            this.list = this.alllist.filter(x => x.Key.toLowerCase().indexOf(filter.toLowerCase()) > -1
                || x.LocationForMap?.toLowerCase().indexOf(filter.toLowerCase()) > -1
                || x.BranchName?.toLowerCase().indexOf(filter.toLowerCase()) > -1
                || x.Address?.toLowerCase().indexOf(filter.toLowerCase()) > -1)
        }, 300)
    }
}