import { Component, Inject, OnInit, ElementRef, ViewChild, Output, EventEmitter, Input } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Utils } from "../../utils/utils";
import { DialogService } from "../../services/dialog.service";
import { SaveService } from "../../services/save.service";
import { DefaultValuesService } from "../../services/defaultvalues.service";

@Component({
    selector: "operationmode-new",
    templateUrl: './operationmode-new.component.html',
    styleUrls: ['../../style/form.component.less']
})

export class OperationModeNewComponent implements OnInit {

    @Input() header: any[] = [];
    @Input() list: OperationMode[] = [];
    @Input() rowIndex: number = -1;
    newRow = true;

    MODE: number = 0;
    VALUE: number = 1;
    UNITAVAILABLE = 2;
    GROUPEVENT = 3;
    SAFETYRELATED = 4;
    POTENTIALMISUSE = 5;
    ISGOODEVENT = 6;
    COMPONENT = 7;
    STABLEALERTRULE = 6;
    PRODALERTRULE = 7;
    EQUIPMENTSTATUS = 8;
    DESCRIPTION: number = 9;

    selected: OperationMode = <OperationMode>{};

    harmonizedopmodes: HarmonizedOperationMode[] = [];
    alertRules: AlertRule[] = [];

    classname: String[] = [];
    isundodisabled: boolean[] = [];

    form!: FormGroup;

    constructor(
        private http: HttpClient
        , private fb: FormBuilder
        , @Inject('BASE_URL') private baseUrl: string
        , private dialog: DialogService
        , public saveService: SaveService
        , public defaultService: DefaultValuesService
    ) {
    }

    private onFieldChange(index: number, field1: any, field2: any): boolean {
        return this.saveService.onFieldChange(index, field1, field2, this);
    }

    private onFieldUndo(index: number) {
        this.saveService.onFieldUndo(index, this);
    }

    ngOnInit() {
        if(this.defaultService.controllertype.BK === 'CT1001') {
            this.STABLEALERTRULE = 7;
            this.PRODALERTRULE = 8;
        }
        this.dialog.tthis = this;
        this.saveService.addListener(this);
        this.saveService.initUndo(this); //it calls the method this.createForm()
        Utils.httpGetAllOperationModesHarmonized(
            this.http
            , this.baseUrl
            , this
            , function (tthis: OperationModeNewComponent, list: HarmonizedOperationMode[]) {
                tthis.harmonizedopmodes = list;
            }
        );
        Utils.httpGetAllAlertRules(
            this.http
            , this.baseUrl
            , this
            , function (tthis: OperationModeNewComponent, list: AlertRule[]) {
                tthis.alertRules = list;
            }
        );
    }

    createForm() {
        if (this.newRow)
            this.form = this.fb.group({
                Value: ['', Validators.required],
                Mode: ['', Validators.required],
                Description: ['', Validators.required],
                UnitAvailable: [''],
                GroupEvent: [''],
                SafetyRelated: [''],
                PotentialMisuse: [''],
                IsGoodEvent: [''],
                Component: [],
                StableAlertRule: ['0'],
                ProdAlertRule: ['0'],
                SpecificEquipmentStatus: ['']
            });
    }

    onCancel(index: number) {
    }

    onDescriptionChange() {
        if (this.form && this.onFieldChange(this.DESCRIPTION, this.form.value.Description, this.selected.Description) && this.selected)
            this.list[this.rowIndex].Description = this.form.value.Description;
    }

    onDescriptionUndo() {
        this.list[this.rowIndex].Description = this.selected.Description;
        this.onFieldUndo(this.DESCRIPTION);
    }

    onIsUnitAvailableChange() {
        if (this.form && this.onFieldChange(this.UNITAVAILABLE, this.form.value.UnitAvailable, this.selected.isUnitAvailable) && this.selected)
            this.list[this.rowIndex].isUnitAvailable = this.form.value.UnitAvailable;
    }

    onIsUnitAvailableUndo() {
        this.list[this.rowIndex].isUnitAvailable = this.selected.isUnitAvailable;
        this.onFieldUndo(this.UNITAVAILABLE);
    }

    onIsGroupEventChange() {
        if (this.form && this.onFieldChange(this.GROUPEVENT, this.form.value.GroupEvent, this.selected.isGroupEvent) && this.selected)
            this.list[this.rowIndex].isGroupEvent = this.form.value.GroupEvent;
    }
    onIsSafetyRelatedChange() {
        if (this.form && this.onFieldChange(this.SAFETYRELATED, this.form.value.SafetyRelated, this.selected.isSafetyRelated) && this.selected)
            this.list[this.rowIndex].isSafetyRelated = this.form.value.SafetyRelated;
    }
    onIsPotentialMisuseChange() {
        if (this.form && this.onFieldChange(this.POTENTIALMISUSE, this.form.value.PotentialMisuse, this.selected.isPotentialMisuse) && this.selected)
            this.list[this.rowIndex].isPotentialMisuse = this.form.value.PotentialMisuse;
    }
    onIsGoodEventChange() {
        if (this.form && this.onFieldChange(this.ISGOODEVENT, this.form.value.IsGoodEvent, this.selected.IsGoodEvent) && this.selected)
            this.list[this.rowIndex].IsGoodEvent = this.form.value.IsGoodEvent;
    }

    onComponentChange() {
        if (this.form && this.onFieldChange(this.COMPONENT, this.form.value.Component, this.selected.Component) && this.selected)
            this.list[this.rowIndex].Component = this.form.value.Component;
    }

    onStableAlertRuleChange() {
        if (this.form && this.onFieldChange(this.STABLEALERTRULE, this.form.controls['StableAlertRule'].value, this.selected.StableAlertRuleCatId) && this.selected !== undefined)
            this.list[this.rowIndex].StableAlertRuleCatId = this.form.controls['StableAlertRule'].value;
    }
    onProdAlertRuleChange() {
        if (this.form && this.onFieldChange(this.PRODALERTRULE, this.form.controls['ProdAlertRule'].value, this.selected.ProdAlertRuleCatId) && this.selected !== undefined)
            this.list[this.rowIndex].ProdAlertRuleCatId = this.form.controls['ProdAlertRule'].value;
    }

    onSpecificEquipmentStatusChange() {
        if (this.form && this.onFieldChange(this.EQUIPMENTSTATUS, this.form.controls['SpecificEquipmentStatus'].value, this.selected.HarmonizedOperationModeID) && this.selected !== undefined)
            this.list[this.rowIndex].HarmonizedOperationModeID = this.form.controls['SpecificEquipmentStatus'].value;
    }

    onSpecificEquipmentStatusUndo() {
        this.list[this.rowIndex].HarmonizedOperationModeID = this.selected.HarmonizedOperationModeID;
        this.onFieldUndo(this.EQUIPMENTSTATUS);
    }

    onModeChange() {
        if (this.form && this.onFieldChange(this.MODE, this.form.value.Mode, this.selected.Mode) && this.selected) {
            this.list[this.rowIndex].Mode = this.form.value.Mode;
        }
    }

    onModeUndo() {
        this.list[this.rowIndex].Mode = this.selected.Mode;
        this.onFieldUndo(this.MODE);
    }
    onRowCancel() {
        this.saveService.fireNewRowEvent();
    }

    onSave() {
        if (this.form && this.form.valid) {
            if (this.newRow) {
                this.selected.Value = this.form.value.Value;
                this.selected.Mode = this.form.value.Mode;
                this.selected.Description = this.form.value.Description;
                this.selected.isUnitAvailable = this.form.value.UnitAvailable !== "" ? true : false;
                this.selected.isGroupEvent = this.form.value.GroupEvent !== "" ? true : false;
                this.selected.isSafetyRelated = this.form.value.SafetyRelated !== "" ? true : false;
                this.selected.isPotentialMisuse = this.form.value.PotentialMisuse !== "" ? true : false;
                this.selected.IsGoodEvent = this.form.value.IsGoodEvent !== "" ? true : false;
                this.selected.Component = this.form.value.Component
                this.selected.StableAlertRuleCatId = this.form.controls["StableAlertRule"].value;
                this.selected.ProdAlertRuleCatId = this.form.controls["ProdAlertRule"].value;
                this.selected.HarmonizedOperationModeID = this.form.controls["SpecificEquipmentStatus"].value;
                this.http
                    .post<OperationMode>(
                        this.baseUrl + Utils.getOperationModeAPI() + "add"
                        , this.selected
                    )
                    .subscribe(
                        res => {
                            this.list.unshift(res);
                        }
                        , error => this.dialog.showErrorDialog(error)
                    );
            }
            else
                this.http
                    .post<OperationMode>(
                        this.baseUrl + Utils.getOperationModeAPI() + "edit"
                        , this.list[this.rowIndex]
                    )
                    .subscribe(
                        res => this.list[this.rowIndex] = res
                        , error => {
                            Utils.httpGetOperationMode(
                                this.http
                                , this.baseUrl
                                , this.list[this.rowIndex].Value
                                , this
                                , function (tthis: OperationModeNewComponent, value: OperationMode) {
                                    tthis.list[tthis.rowIndex] = value;
                                }
                            );
                            this.dialog.showErrorDialog(error);
                        }
                    );
        }
        else
            this.dialog.showErrorStringDialog("Error Saving data!");
    }


    updateForm() {
        if (this.form && this.selected) {
            const e_new = {
                Value: this.list[this.rowIndex].Value
                , Description: this.list[this.rowIndex].Description
                , Mode: this.list[this.rowIndex].Mode
                , UnitAvailable: this.list[this.rowIndex].isUnitAvailable
                , GroupEvent: this.list[this.rowIndex].isGroupEvent
                , SafetyRelated: this.list[this.rowIndex].isSafetyRelated
                , PotentialMisuse: this.list[this.rowIndex].isPotentialMisuse
                , StableAlertRule: this.list[this.rowIndex].StableAlertRuleCatId
                , ProdAlertRule: this.list[this.rowIndex].ProdAlertRuleCatId
                , SpecificEquipmentStatus: this.list[this.rowIndex].HarmonizedOperationModeID
            };
            const e = {
                Mode: this.list[this.rowIndex].Mode
                , Description: this.list[this.rowIndex].Description
                , UnitAvailable: this.list[this.rowIndex].isUnitAvailable
                , GroupEvent: this.list[this.rowIndex].isGroupEvent
                , SafetyRelated: this.list[this.rowIndex].isSafetyRelated
                , PotentialMisuse: this.list[this.rowIndex].isPotentialMisuse
                , StableAlertRule: this.list[this.rowIndex].StableAlertRuleCatId
                , ProdAlertRule: this.list[this.rowIndex].ProdAlertRuleCatId
                , SpecificEquipmentStatus: this.list[this.rowIndex].HarmonizedOperationModeID
            };

            this.form.setValue(this.newRow ? e_new : e);
        }
    }
}