
<iframe title="CDP MERSY Direct-Report"
        *ngIf='this.ReportType === "DEV"'
        style="height: 90vh; display: block; width: -webkit-fill-available"
        src="https://app.powerbi.com/reportEmbed?reportId=7f13f01e-26fa-4ee7-8a6c-78d7788d95eb&autoAuth=true&ctid=84d9a216-e285-4aac-b163-0dfd0c074546"
        frameborder="0"
        allowFullScreen="true">
</iframe>


<iframe title="CDP MERSY Direct-Report"
        *ngIf='this.ReportType === "PROD"'
        style="height: 90vh; display: block; width: -webkit-fill-available"
        src="https://app.powerbi.com/reportEmbed?reportId=15c350ee-88f8-4ce0-a490-34360cb9b4ad&autoAuth=true&ctid=84d9a216-e285-4aac-b163-0dfd0c074546"
        frameborder="0"
        allowFullScreen="true">
</iframe>


