<div class="custom-mat-dialog-form non-overflow">
    <h4 mat-dialog-title>{{title}}</h4>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div>
            <label>Version</label>
            <div class="mt-2">
                <mat-select placeholder="Version" name="Version" formControlName="Version"
                            panelClass="testcase-class"
                            class="form-control">
                    <ng-container *ngFor="let value of schemaVersionList">
                        <mat-option *ngIf="value.label" [value]="value.value">
                            {{value.label}}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </div>
        </div>

        <div>

            <div class="mt-2">
                <div class="input">
                    <mat-checkbox formControlName="RT"
                                  title="check if you want to show the regression test...">
                        To Regression Test?
                    </mat-checkbox>
                </div>
            </div>

            <div class="mt-2 right">
                <button mat-raised-button
                        class="btn btn-primary" [disabled]="!form.valid">
                    Generate JSON
                </button>
                <button mat-button
                        class="btn btn-danger" *ngIf="isGenerated" (click)="onDownload()">
                    Download
                </button>

            </div>
            <br />
            <div class="form-group">
                <label for="text">Json Results:</label>
                <br />
                <textarea class="form-control" rows="25" cols="100" readonly [value]="result"></textarea>
            </div>
        </div>
    </form>
</div>