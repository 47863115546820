import { Component, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import { Subject } from "rxjs";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { DefaultValuesService } from "../../../../services/defaultvalues.service";
import { DialogService } from "../../../../services/dialog.service";
import { Utils } from "../../../../utils/utils";
import { HttpClient } from "@angular/common/http";
import { FormControl } from "@angular/forms";

@Component({
    selector: 'hierarchysetuppopup-list',
    templateUrl: './hierarchysetuppopup-list.component.html',
    styleUrls: ['./hierarchysetuppopup-list.component.less']
})
export class HierarchysetuppopupListComponent implements OnInit {

    @Input() hkey: string = '';
    @Output() onBackEvent = new EventEmitter();

    fish = new FormControl('');

    minDate :any;
    maxDate: any;
    searchString = '';


    protected _onDestroy = new Subject<void>();
    public dataSource = new MatTableDataSource<BranchHierarchyKeyHistory>();

    public fullData: BranchHierarchyKeyHistory[] = [];
    columnsToDisplay = ['Source', 'Target', 'Properties', 'Action', 'TimeStamp', 'User'];
    timeoutFunction: any;

    constructor(private defaults: DefaultValuesService
        , private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
    ) {
    }

    ngOnInit() {
        //this.funcGetBranchHierarchyKeyHistory(")
        this.funcGetBranchHierarchyKeyHistory(this.hkey)


    }

    ngOnChanges() {
        //    this.funcGetBranchHierarchyKeyHistory(this.hkey)
    }

    funcGetBranchHierarchyKeyHistory(hkey) {
        Utils.GetBranchHierarchyKeyHistory(
            this.http
            , this.baseUrl
            , hkey
            , this
            , (tthis: HierarchysetuppopupListComponent, data: BranchHierarchyKeyHistory[]) => {
                tthis.dataSource = new MatTableDataSource(data);
                tthis.fullData = data;

            });
    }

    applyFilter(filterValues) {
        var filterValue = filterValues.target.value;
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }

    filterTable() {
        this.dataSource = new MatTableDataSource<BranchHierarchyKeyHistory>(
            this.fullData.filter((x) => {
                const operationDate = new Date(x.OperationTimeStamp);
                const minDateTime = this.minDate ? new Date(this.minDate) : null;
                const maxDateTime = this.maxDate ? new Date(this.maxDate) : null;

                if (minDateTime && maxDateTime) {
                    // Both minDate and maxDate are defined, filter within the range
                    return operationDate >= minDateTime && operationDate <= maxDateTime;
                } else if (minDateTime) {
                    // Only minDate is defined, filter for dates greater than or equal to minDate
                    return operationDate >= minDateTime;
                } else if (maxDateTime) {
                    // Only maxDate is defined, filter for dates less than or equal to maxDate
                    return operationDate <= maxDateTime;
                } else {
                    // Neither minDate nor maxDate are defined, return true for all entries
                    return true;
                }
            })
        );
    }
    onBack() {
        this.onBackEvent.emit();
    }

    

}
