<div class="example-container">
    <table mat-table
           [dataSource]="dataSource"
           multiTemplateDataRows
           class="mat-elevation-z8 mat-table-custom" id="maintenancewarninglist">

        <ng-container matColumnDef="arrow">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element"
                (click)="pushPopElement(element)">
                <span class="material-icons" *ngIf="checkExpanded(element); else show_expand_more">
                    expand_less
                </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="index">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element; let i = dataIndex">
                <p>{{i+1}}</p>
            </td>
        </ng-container>

        <ng-container matColumnDef="warningtype">
            <th mat-header-cell *matHeaderCellDef> Warning Type </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.Type" *ngIf="!isEditingRow(element)"></p>
                <mat-form-field floatLabel="never" *ngIf="isEditingRow(element)">
                    <input matInput [value]="element.Type" [(ngModel)]="element.Type">
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.Description" *ngIf="!isEditingRow(element)"></p>
                <mat-form-field floatLabel="never" *ngIf="isEditingRow(element)">
                    <input matInput [value]="element.Description" [(ngModel)]="element.Description">
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button matTooltip="Edit Row" (click)="onRowEditInit(element)" *ngIf="!isEditingRow(element)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Cancel Row" *ngIf="isEditingRow(element)" (click)="onRowEditCancel(element)">
                    <mat-icon>rotate_left</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Add Event" *ngIf="checkExpanded(element)" (click)="onCreateNewResolution(element)">
                    <mat-icon>library_add</mat-icon>
                </button>
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                <div [@detailExpand]="checkExpanded(element) ? 'expanded' : 'collapsed'" *ngIf="element.MaintenanceWarningResolution"
                     [class.expandedDetail]="checkExpanded(element)">
                    <table mat-table [dataSource]="element.MaintenanceWarningResolution" class="mat-subtable-custom" id="maintenancewarningDetails"
                           multiTemplateDataRows>

                        <ng-container matColumnDef="index">
                            <th mat-header-cell *matHeaderCellDef> </th>
                            <td mat-cell *matCellDef="let element; let i = dataIndex">
                                <p>{{i+1}}</p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="possiblefailure">
                            <th mat-header-cell *matHeaderCellDef> Possible Failure </th>
                            <td mat-cell *matCellDef="let element2">
                                <p [innerHTML]="element2.PossibleFailure"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="location">
                            <th mat-header-cell *matHeaderCellDef> Location </th>
                            <td mat-cell *matCellDef="let element">
                                <p [innerHTML]="element.Location"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="component">
                            <th mat-header-cell *matHeaderCellDef> Component </th>
                            <td mat-cell *matCellDef="let element">
                                <p [innerHTML]="element.Component"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="resolution">
                            <th mat-header-cell *matHeaderCellDef> Resolution </th>
                            <td mat-cell *matCellDef="let element">
                                <p [innerHTML]="element.Resolution"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element2">
                                <button mat-icon-button matTooltip="Edit Row" (click)="onResolutionEditInit(element2)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                                <button mat-icon-button matTooltip="Delete" (click)="onDelete(element2)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row class="subtableheader" *matHeaderRowDef="subcolumnsToDisplay"></tr>
                        <tr mat-row *matRowDef="let element2; let i2 = index; columns: subcolumnsToDisplay;"
                            class="subitem"></tr>
                    </table>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row class="tableheader" *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; let i = dataIndex; columns: columnsToDisplay;" class="item"
            [class.alternative2]="isEditingRow(element)">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="row-subitem"></tr>
    </table>
</div>

<ng-template #show_expand_more>
    <span class="material-icons">
        expand_more
    </span>
</ng-template>
