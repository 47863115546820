import { Component, Inject, OnInit, OnDestroy, AfterViewInit, ViewChild } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Utils } from "../../utils/utils";
import { DefaultValuesService } from "../../services/defaultvalues.service";
import { DialogService } from "../../services/dialog.service";
import { MatDialog } from "@angular/material/dialog";
import { SaveService } from "../../services/save.service";
import { MatTabGroup } from "@angular/material/tabs";

@Component({
    selector: "testcase",
    templateUrl: './testcase.component.html',
    styleUrls: ['../../style/container-tab-list.component.less']
})

export class TestCaseComponent implements OnInit, OnDestroy {
    title: string;
    //controllerType: ControllerType = <ControllerType>{};
    list: TestCase[] = [];
    listDataBricks: TestCaseDatabricksTrigger[] = [];
    showMenuViewValue = 1;

    subscriptionControllerType: any;

    @ViewChild('tabGroup') tabGroup!: MatTabGroup;

    constructor(
        private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , public defaults: DefaultValuesService
        , public dialog: DialogService
        , public saveService: SaveService
        , private matDialog: MatDialog
    ) {
        this.title = "Test Cases";
    }

    ngOnInit() {
        this.subscriptionControllerType = this.defaults.controllerTypeMessageChanges$.subscribe((item: ControllerType) => this.refresh());
        this.refresh();
        this.showMenuViewValue = 0;
    }

    ngOnDestroy() {
        this.subscriptionControllerType.unsubscribe();
    }

    ngAfterViewInit() {
        this.defaults.defaultComponentTab(this)
    }

    onControllerChanged(ct: ControllerType) {
        this.refresh();
    }

    onResetList() {
        this.refresh();
    }

    public refresh() {
        if (this.defaults.isadminuser || this.defaults.isDomainExpertUser || this.defaults.isExternalUser) {
            Utils.httpGetAllTestCases(
                this.http
                , this.baseUrl
                , this
                , function (tthis: TestCaseComponent, list: TestCase[]) {
                    tthis.list = list;
                }
            );
            Utils.httpGetAllTestCasesDatabricksTrigger(this.http
                , this.baseUrl
                , this
                , function (tthis: TestCaseComponent, list: TestCaseDatabricksTrigger[]) {
                    tthis.listDataBricks = list;
                }
            );
        }
        else {
            Utils.httpGetAllTestCasesFilteredByITRelevant(
                this.http
                , this.baseUrl
                , this
                , function (tthis: TestCaseComponent, list: TestCase[]) {
                    tthis.list = list;
                }
            );
        }
    }

    showMenuView($event) {
        this.showMenuViewValue = $event.index;
    }
}