<div class="custom-mat-dialog-form non-overflow">
    <h3 mat-dialog-title>{{title}}</h3>
    <div>
        <p>
            Check the OperationModes and ErrorCodes values, they could <strong>NOT</strong> match with the new controllertype.<br />
            If it is an Alert TestCase, the Output flag will <strong>NOT</strong> be copied.
        </p>

        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="mt-2">
                <label>Controller Type to Copy to</label>
                <div class="input">
                    <mat-select class="form-control" required formControlName="CT">
                        <mat-option *ngFor="let c of defaults.controllertypes; index as i" value="{{c.ControllerTypeID}}">
                            {{c.Description}} ({{c.BK}})
                        </mat-option>
                    </mat-select>
                </div>
            </div>

            <div class="mt-2 right">
                <button [disabled]="!form.valid" class="btn btn-primary">Save</button>
                <button (click)="onCancel()" tabindex="-1" class="btn btn-danger">Cancel</button>
            </div>
        </form>
    </div>
</div>
