<div class="custom-mat-dialog-form">
        <div>
            <div>
                {{ MersyId }}<br />
                {{ KpiName }}
                <br />
                <br />
                <span>
                </span>
            </div>
        </div>
        <div>

            <span>Please select available Group Functions to be added </span>
            <div class="mt-2">
                <select class="list_detail" 
                        [(ngModel)]="SelectedGF" 
                        multiple 
                        style="height:150px; width:300px; color:black;">
                    <option *ngFor="let option of GroupFunctions"
                            [value]="option" 
                            [selected]="SelectedGF.includes(option)"
                            [disabled]="SelectedGF.includes(option)"
                            >
                        {{ option }}
                    </option>
                </select>

            </div>
            <br />
            <div class="mt-2 right">
                <button type="button"
                        class="btn btn-danger"
                        (click)="onNoClick()">
                    Cancel
                </button>
                <button type="button"
                        class="btn btn-primary"
                        (click)="onSubmit()">
                    CONFIRM
                </button>
            </div>
        </div>
</div>
