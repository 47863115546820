import { Injectable, EventEmitter } from '@angular/core';
import { Utils } from '../utils/utils';

@Injectable()
export class SaveService {

    CLASSNAMESAVEBUTTON: string = "save-button";

    classSaveButton: string = Utils.getDialogHideClassName();
    classInlineButtons: string = Utils.getDialogHideClassName();

    listeners: { tthis: any, callback: any }[] = [];

    checkedListeners: { tthis: any }[] = [];

    //constructor(
    //) {
    //}

    public addListener(tthis: any, callback: any = undefined) {
        let obj = { tthis: tthis, callback: callback };
        let exists = false;
        //this.listeners.
        this.listeners.forEach(x => {
            if (x.tthis == tthis)
                exists = true;
        });

        if (!exists)
            this.listeners.push(obj);
    }

    public addCheckedListener(tthis: any) {
        let obj = { tthis: tthis };
        let exists = false;
        //this.listeners.
        this.checkedListeners.forEach(x => {
            if (x.tthis == tthis)
                exists = true;
        });

        if (!exists)
            this.checkedListeners.push(obj);
    }

    public fireCancelAll() {
        this.classSaveButton = Utils.getDialogHideClassName();
        this.listeners.forEach(x => {
            if (x.tthis.onCancelAll)
                x.tthis.onCancelAll();
        });
        this.listeners = [];
    }

    public fireCancel(index: number = -1, tthis: any = undefined) {
        //var tthisCancel = undefined;
        this.listeners.forEach(x => {
            if (x.tthis.onCancel)
                x.tthis.onCancel(index);
        });

        if (tthis)
            this.removeListener(tthis);
        //this.classSaveButton = Utils.getDialogHideClassName();
    }

    public fireNewRowEvent() {
        this.listeners.forEach(x => {
            if (x.tthis.onNewRowEvent)
                x.tthis.onNewRowEvent();
        });
    }

    public fireDelete() {

        this.checkedListeners.forEach(x => {
            if (x.tthis != undefined)
                if (x.tthis.onDelete)
                    x.tthis.onDelete();
        });

        this.classInlineButtons = Utils.getDialogHideClassName();
        this.checkedListeners = [];
    }

    public fireNew() {
        this.listeners.forEach(x => {
            if (x.tthis.onNew)
                x.tthis.onNew();
        });
    }

    /**
     * fire the save event to all listeners
     * */
    public fireSaveAll() {
        this.fireSave();
    }

    /**
     * fire all the save events and remove the tthis listener
     * and remove all if is the last form. the additional listener is the menu with the saveAllButton
     * @param index
     * @param tthis
     */
    public fireSave(index: number = -1, tthis: any = undefined) {
        this.listeners.forEach(x => {
            if (x.tthis.onSave) {
                x.tthis.onSave(index);
                if (x.tthis.onNewRowEvent) {
                    setTimeout(() => { x.tthis.onNewRowEvent() }, 1500);
                }
            }
            else if (x.callback) {
                x.callback(x.tthis);
            }
        });

        if (tthis)
            this.removeListener(tthis);

        //if it is the SaveAllButton or is the last row to edit, hide the saveAllButton and remove all the listeners
        if (tthis === undefined || this.listeners.length === 1) { 
            this.classSaveButton = Utils.getDialogHideClassName();
            this.listeners = [];
        }
    }

    /**
     * method called by a form on ngOnInit, to initiate the undo feature
     * must have the attributes 'classname' and 'isundodisabled' arrays and the parameter header: any[]
     * and it calls the method createForm
     * 
     * @argument tthis for the form with the attributes
     * 
     * */
    public initUndo(tthis: any) {
        tthis.header.forEach(
            function (value: any, index: number, array: any[]) {
                tthis.classname[index] = tthis.CLASSNAMESAVEBUTTON;
                tthis.isundodisabled[index] = true;
            }
        );
        tthis.createForm();
    }

    /**
     * method to check if the save button is shown
     * */
    public isSaveButtonShown(): boolean {
        return this.classSaveButton == this.CLASSNAMESAVEBUTTON;
    }

    /**
     * method called by a form on every field changed
     * must have the attributes 'classname' and 'isundodisabled' arrays and the parameter newRow: boolean
     * and it calls the method setFieldUndoDisable to enable undo button
     * 
     * @argument index of the column(field) to disable
     * @argument field1 value to compare with field2
     * @argument field2 value to compare with field1
     * @argument tthis for the form with the attributes
     * 
     * @returns true if values from field1 and field2 are diferents
     * 
     * */
    public onFieldChange(index: number, field1: any, field2: any, tthis: any): boolean {
        var ret = false;
        if (field1 != field2 && !tthis.newRow) {
            ret = true;
            this.setFieldUndoDisable(index, false, tthis);
        }
        this.showSaveButton();
        return ret;
    }
    
    /**
     * method called by a form on undo a field
     * must have the attributes 'classname' and 'isundodisabled' arrays
     * and it calls the methods setFieldUndoDisable to disable undo button and updateForm
     * 
     * @argument index of the column(field) to disable
     * @argument tthis for the form with the attributes
     * 
     * */
    public onFieldUndo(index: number, tthis:any) {
        this.setFieldUndoDisable(index, true, tthis);
        tthis.updateForm();
    }

    public removeCheckedListener(tthis: any) {
        const num = this.checkedListeners.findIndex(
            function (value: any, index: number, obj: any[]) {
                return value.tthis === tthis;
            }
        );
        if(num != -1)
            this.checkedListeners.splice(num, 1);
        //this.checkedListeners[num].tthis = undefined;
    }

    public removeListener(tthis: any) {
        const num = this.listeners.findIndex(
            function (value: any, index: number, obj: any[]) {
                return value.tthis === tthis;
            }
        );
        if (num > -1)
            this.listeners.splice(num, 1);
        //this.checkedListeners[num].tthis = undefined;
    }


    /**
     * method to setUndoDisable on a form
     * must have the attributes 'classname' and 'isundodisabled' arrays
     * 
     * @argument index of the column(field) to disable
     * @argument value true to disable and false to enable
     * @argument tthis for the form with the attributes
     * 
     * */
    public setFieldUndoDisable(index: number, value: boolean, tthis: any) {
        if (tthis.classname && tthis.isundodisabled) {
            tthis.classname[index] = value ? tthis.CLASSNAMESAVEBUTTON : Utils.getEditingInputClassName();
            tthis.isundodisabled[index] = value;
        }
    }

    public showInlineButtons() {
        this.classInlineButtons = "";
    }

    /**
     * method to change the class style that show the save button
     * default value is true
     * 
     * @argument value true to show save button, false to hide
     * */
    public showSaveButton(value: boolean = true) {
        this.classSaveButton = value ? this.CLASSNAMESAVEBUTTON : Utils.getDialogHideClassName();
    }
}