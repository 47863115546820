
<div class="example-container new-table-style">
    <table mat-table
           [dataSource]="dataSource"
           class="mat-elevation-z8 mat-table-custom"
           id="controllertype-masterdatarequired ">

        <ng-container matColumnDef="country">
            <th mat-header-cell *matHeaderCellDef> Country </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.CountryCode"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="masterdata">
            <th mat-header-cell *matHeaderCellDef> MasterData Name</th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.MasterDataName"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef> Value </th>
            <td mat-cell *matCellDef="let element">
                <mat-checkbox [checked]="element.Value" [disabled]="true"></mat-checkbox>
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="defaults.isReadonlyUser === false && element.CountryCode !== 'ZZ'">
                    <button mat-icon-button (click)="onToggle(element)" matTooltip="Set Value" *ngIf="!element.Value">
                        <mat-icon class="mat-icon-grey">toggle_off</mat-icon>
                    </button>
                    <button mat-icon-button (click)="onToggle(element)" matTooltip="Reset Value" *ngIf="element.Value">
                        <mat-icon class="mat-icon-green">toggle_on</mat-icon>
                    </button>
                </ng-container>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; let i = dataIndex;
            columns: columnsToDisplay;"
            class="item">
        </tr>

    </table>
</div>


