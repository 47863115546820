<form [formGroup]="form">
    <table>
        <tbody class="inner">
            <tr>
                <!--<td>&nbsp;</td>-->
                <td>&nbsp;</td>
                <td>
                    <span *ngIf="!newRow">{{list[rowIndex].ErrorCode}}</span>
                    <input *ngIf="newRow" type="number"
                           formControlName="ErrorCode"
                           placeholder="enter the error code..."
                           title="enter the error code..."
                           class="form-control"
                           (change)="onInputChange($event)"
                           />

                </td>

                <td>
                    <span *ngIf="!newRow">{{list[rowIndex].Description_ref}}<br /></span>
                </td>

                <td>
                    <span *ngIf="!newRow">{{errorcode.Description}}<br /></span>
                    <textarea id="description"
                              formControlName="Description" required
                              placeholder="enter the description..."
                              class="form-control"
                              [ngClass]="classname[DESCRIPTION]"
                              (change)="onInputChange($event)">
                    </textarea>
                    <button *ngIf="!newRow" type="button" [disabled]="isundodisabled[DESCRIPTION] == true" (click)="onUndo()" class="btn btn-default" title="Undo changes">
                        <span class='glyphicon glyphicon-arrow-left'></span>
                    </button>
                </td>

                <td>
                    <button type="button"
                            class="btn btn-warning"
                            (click)="onRowCancel()">
                        Cancel
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</form>
