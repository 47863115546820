<table-material *ngIf="list"
        id-table="controller-config-description-id"
        [header]="header"
        [data]="list"
        [getValue]="getData"
        [selectable]="false"
        (onNewElement)="onNewForm()"
        (onRefresh)="refresh()"
                
        >
</table-material>
