import { Component, Inject, Input, OnChanges, EventEmitter, Output, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Utils } from "../../utils/utils";
import { TableFixedHeaderSearchComponent } from "../table/table-fixed-header-search.component";
import { DefaultValuesService } from "../../services/defaultvalues.service";
import { SaveService } from "../../services/save.service";
import { DialogService } from "../../services/dialog.service";

@Component({
    selector: "maintenance-control-plan-group-list",
    templateUrl: './mcp-list-group.component.html',
    styleUrls: ['../../style/list.component.less', './mcp-list.component.less']
})

export class MaintenanceControlPlanGroupListComponent implements OnInit {
    @Input('list') list: MaintenanceControlPlanGroup[] = [];
    @Input() mcp: MaintenanceControlPlan = <MaintenanceControlPlan>{};
    @Input() newService: SaveService | undefined;


    header: any[] = [
        { label: 'Group', show: true, searchtype: 'text' }
        , { label: 'Is Lobby?', show: true, searchtype: 'checkbox', checkbox: true }
    ];

    constructor(private activatedRoute: ActivatedRoute
        , private router: Router
        , private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , private dialog: DialogService
        , public defaults: DefaultValuesService
    ) {
    }

    ngOnInit() {
        this.dialog.tthis = this;
    }

    /**
     * this is from who calls (inner component)
     * @param list
     * @param row
     * @param column
     */
    getData(list: MaintenanceControlPlanGroup[], row: number, column: number): string {
        if (list) {
            var e = list[row];
            switch (column) {
                case 0:
                    return e.MCPGroup;
                default:
                    return e.IsLobby ? "1" : "0"; 
            }
        }
        else
            return "";
    }

    onDelete(value: { index: number, value: MaintenanceControlPlanGroup }) {
        this.dialog.showConfirmDialog(value.value.MCPGroup + " in " + value.value.MCPTitle + "?", "Delete MCP Group", this, value);
    }

    onRowClick(value: { index: number, value: MaintenanceControlPlanGroup }) {
        this.router.navigate(["maintenancecontrolplangroupaction", value.value.MCPGroupID]);
    }

    onYes(value: { index: number, value: MaintenanceControlPlanGroup }) {
        this.http
            .delete<MaintenanceControlPlanGroup>(
                this.baseUrl + Utils.getMaintenanceControlPlanAPI() + "group/" + value.value.MCPGroupID
            )
            .subscribe(
                res => this.list.splice(value.index, 1)
                , error => this.dialog.showErrorDialog(error)
            );
    }
}