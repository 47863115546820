import { HttpClient } from "@angular/common/http";
import { Component, Inject, EventEmitter, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { CountryBackendEntity } from "../../../interfaces/countrybackendentity";
import { DefaultValuesService } from "../../../services/defaultvalues.service";
import { DialogService } from '../../../services/dialog.service';
import { SaveService } from '../../../services/save.service';
import { Utils } from '../../../utils/utils';
import { CdpWikiListComponent } from "../cdpwiki/cdpwiki-list.component";



@Component({
    selector: 'chairlift-menu',
    templateUrl: './chairlift-menu.component.html',
    styleUrls: ['../../../style/menu.component.less']
})

export class ChairliftMenuComponent {

    @Output() outputDownload = new EventEmitter<{}>();
    @Output() onReload = new EventEmitter<{}>();

    constructor(private http: HttpClient,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        @Inject('BASE_URL') private baseUrl: string,
        private dialogForm: MatDialog,
        public saveService: SaveService,
        private dialog: DialogService,
        public defaults: DefaultValuesService
    ) {
    }

    onUpload() {
        this.router.navigate(["file/" + Utils.getFileUploadChairlifts()]);
    }

    openDocumentationModal() {
        this.dialogForm.open(CdpWikiListComponent, {
            panelClass: 'custom-mat-dialog',
            data: { "entity": CountryBackendEntity.Chairlift }
        });
    }
}
