import { Component, Inject, OnInit, ElementRef, ViewChild, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Utils } from "../../utils/utils";
import { DialogService } from "../../services/dialog.service";
import { DefaultValuesService } from "../../services/defaultvalues.service";
import { WaitingService } from "../../services/waiting.service";
import { SaveService } from "../../services/save.service";

@Component({
    selector: "maintenance-control-plan-group-action-component-error-new",
    templateUrl: './mcp-new-group-action-component-error.component.html',
    styleUrls: ['../../style/form.component.less']
})

export class MaintenanceControlPlanGroupActionComponentErrorNewComponent implements OnInit {
    @Input() component: MaintenanceControlPlanGroupActionComponent = <MaintenanceControlPlanGroupActionComponent>{};
    @Input() list: MaintenanceControlPlanGroupActionComponentError[] = [];

    @Input() header: any[] = [];
    @Input() rowIndex: number = -1;
    @Input() newRow: boolean = true;

    CONTROLLERTYPE: number = 0;
    ERRORCODE: number = 1;
    DESCRIPTION: number = 2;

    classname: String[] = [];
    isundodisabled: boolean[] = [];

    errors: ErrorCode[] = [];
    controllerTypes: ControllerType[] = [];

    selected: MaintenanceControlPlanGroupActionComponentError = <MaintenanceControlPlanGroupActionComponentError>{};

    form!: FormGroup;

    constructor(
        private activatedRoute: ActivatedRoute
        , private router: Router
        , private http: HttpClient
        , private fb: FormBuilder
        , @Inject('BASE_URL') private baseUrl: string
        , public defaults: DefaultValuesService
        , private waiting: WaitingService
        , private dialog: DialogService
        , private saveService: SaveService
    ) {
    }

    private onFieldChange(index: number, field1: any, field2: any): boolean {
        return this.saveService.onFieldChange(index, field1, field2, this);
    }

    private onFieldUndo(index: number) {
        this.saveService.onFieldUndo(index, this);
    }

    ngOnInit() {

        this.dialog.tthis = this;
        this.saveService.addListener(this);
        this.saveService.initUndo(this); //it calls the method this.createForm()
        this.getErrors(this.defaults.controllertype.ControllerTypeID);
    }

    createForm() {
        this.form = this.fb.group({
            ControllerType: ['']
            , Error: ['', Validators.required]
        });
    }

    getErrors(id: number) {
        Utils.httpGetAllErrorCodesForControllerType(
            this.http
            , this.baseUrl
            , id
            , this
            , function (tthis: any, values: ErrorCode[]) {
                tthis.errors = values;
                tthis.waiting.toWait(false);
            }
        );
    }

    isSelected(ct: ControllerType): boolean {
        return ct.ControllerTypeID == this.defaults.controllertype.ControllerTypeID;
    }

    onControllerChanged(select: any) {
        if (!select.target) return;
        this.errors = [];
        this.getErrors(this.defaults.controllertypes[select.target.value].ControllerTypeID);
    }

    onErrorChange(select: any) {
        if (!select.target) return;
        //this.selected.ErrorCodeID = select.target.value;
        if (this.form && this.onFieldChange(this.ERRORCODE, this.form.value.Component, this.selected.ErrorCodeID) && this.selected) { 
            this.list[this.rowIndex].ErrorCodeID = this.form.value.Error;
            //Utils.httpGetErrorCode(
            //    this.http
            //    , this.baseUrl
            //    , this.list[this.rowIndex].ErrorCodeID
            //    , this
            //    , function (tthis: any, value: ErrorCode) {
            //        tthis.selected = value;
            //    });
        }
    }

    onRowCancel() {
        this.saveService.fireCancel(this.rowIndex, this);
    }

    onSave() {
        if (this.form && this.form.valid) {
            if (this.newRow) {
                this.selected.MCPActionID = this.component.MCPActionID;
                this.selected.ComponentItemID = this.component.ComponentItemID;
                this.selected.ErrorCodeID = this.form.value.Error;

                this.http
                    .put<MaintenanceControlPlanGroupActionComponentError>(
                        this.baseUrl + Utils.getMaintenanceControlPlanAPI() + "addgroupactioncomponenterror"
                        , this.selected
                    )
                    .subscribe(
                        res => this.list.unshift(res)
                        , error => this.dialog.showErrorDialog(error)
                    );
            }
        }
        else
            this.dialog.showErrorStringDialog("Error Saving data!");
    }

    //onSubmit() {
    //    if (this.form && this.selected) {

    //        this.selected.MCPActionID = this.action.MCPActionID;
    //        this.selected.ErrorCodeID = this.form.value.Error;             

    //        //if (this.editMode)
    //        //    //this.dialog.showSuccessDialog( "Future Implementation!");
    //        //    this.http
    //        //        .post<MaintenanceControlPlanGroupActionComponent>(
    //        //        this.baseUrl + Utils.getMaintenanceControlPlanAPI() + "editgroupactioncomponent"
    //        //            , this.selected
    //        //        )
    //        //        .subscribe(res => {
    //        //            var v = res;
    //        //            this.dialog.showSuccessDialog( res.Component + " successfully updated", this.title);
    //        //        }, error => this.dialog.showErrorDialog( error));
    //        //else
    //            this.http
    //                .put<MaintenanceControlPlanGroupActionComponent>(
    //                this.baseUrl + Utils.getMaintenanceControlPlanAPI() + "addgroupactionerror"
    //                    , this.selected
    //                )
    //                .subscribe(res => {
    //                    var v = res;
    //                    this.onBack();
    //                    //this.dialog.showSuccessDialog( "successfully created", this.title);
    //                }, error => this.dialog.showErrorDialog( error));
    //    }
    //}
}