<!--The Heading sections (Heading + Serach )-->
<div class="deleteUser">
    <mat-toolbar class="header heading-background" style="margin-bottom:18px">

        <h5>
            Delete User
        </h5>

    </mat-toolbar>
    <div class="row">
        <div class="col-lg-1">
        </div>
        <div class="col-lg-10" >
            <h5>Are you sure you want to delete {{data.useremail}}?</h5>
            <div class="panel-body">

                <button (click)="backButtonHandler($event)" mat-raised-button class="btn  button-color-fix_red ">
                    <mat-icon class=" icon-color-fix ">arrow_back_ios</mat-icon>
                    <!--<mat-icon class=" icon-color-fix ">close</mat-icon>-->
                    Cancel
                </button>
                <button mat-raised-button routerLink="/UmpHome" (click)="handleDeleteButton()" type="submit" class="btn button-color-fix_red pull-right">
                    Delete
                    <mat-icon class=" icon-color-fix">delete</mat-icon>
                </button>

            </div>

        </div>
        <div class="col-lg-1">
        </div>
    </div>
    

</div>

