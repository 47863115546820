<div class="example-container">
    <table mat-table
           [dataSource]="dataSource"
           multiTemplateDataRows
           matSort
           class="mat-elevation-z8 mat-table-custom" id="provisioningratemplate">
        <ng-container *ngFor="let item of columnsToDisplay" matColumnDef="{{item}}">
            <th mat-header-cell *matHeaderCellDef>
                {{item === 'actions' ? '' : item}}
            </th>
            <ng-container *ngIf="item==='actions'">
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button (click)="changeElement(element, 'IsEnabled', true)" matTooltip="Allow Remote Action Value" *ngIf="!getInput(element,'IsEnabled') && isEditable(element.Category)">
                        <mat-icon class="mat-icon-grey">toggle_off</mat-icon>
                    </button>
                    <button mat-icon-button (click)="changeElement(element, 'IsEnabled', false)" matTooltip="Disallow Remote Action" *ngIf="getInput(element,'IsEnabled') && isEditable(element.Category)">
                        <mat-icon class="mat-icon-green">toggle_on</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="{{element.Category}} Remote Action - Cannot be enabled/disabled" *ngIf="!getInput(element,'IsEnabled') && !isEditable(element.Category)">
                        <mat-icon class="mat-icon-grey">toggle_off</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="{{element.Category}} Remote Action - Cannot be enabled/disabled" *ngIf="getInput(element,'IsEnabled') && !isEditable(element.Category)">
                        <mat-icon class="mat-icon-grey">toggle_on</mat-icon>
                    </button>
                </td>
            </ng-container>
            <ng-container *ngIf="item!=='actions'">
                <ng-container *matCellDef="let element" [ngSwitch]="getDataType(item)">
                    <td mat-cell *ngSwitchCase="'string'">
                        <p [innerHTML]="getInput(element,item)"></p>
                    </td>
                    <td mat-cell *ngSwitchCase="'date'" class="datepicker">
                        <p [innerHTML]="getInput(element,item) | date:'yyyy-MM-dd HH:mm'"></p>
                    </td>
                </ng-container>
            </ng-container>
        </ng-container>

        <tr mat-header-row class="tableheader" *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; let i = dataIndex; columns: columnsToDisplay;" class="item">
    </table>
</div>
