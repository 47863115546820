import { Component, Inject, Input, EventEmitter, Output, OnInit, OnDestroy } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Utils } from "../../../utils/utils";
import { SaveService } from "../../../services/save.service";
//import { ControllerConfigFormComponent } from "./controllerconfig-form.component";
import { MatDialog } from "@angular/material/dialog";
import { DialogService } from "../../../services/dialog.service";
import { DefaultValuesService } from "../../../services/defaultvalues.service";
import { ControllerTypeFormComponent } from "./controllertype-form.component";

@Component({
    selector: "controllertypes-devops-list",
    templateUrl: './controllertypes-devops-list.component.html',
    styleUrls: ['../../../style/list.component.less', 'controllertypes-list.component.less']
})

export class ControllerTypesDevOpsListComponent {

    header: any[] = [
        { label: 'BK', show: true, title: "Description of the ControllerConfig" }
        , { label: 'Description', show: true, title: "Description of the ControllerConfig" }
    ];

    constructor(
        private dialogService: DialogService
        , public defaults: DefaultValuesService
        , private saveService: SaveService
        , private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , private dialog: MatDialog
    ) {
    }

    /**
     * called by the SaveService
     * */
    onCancel() {
        this.dialog.closeAll();
        
        Utils.httpGetAllControllerTypes(
            this.http
            , this.baseUrl
            , this
            , function (tthis: any, list: ControllerType[]) {
                tthis.defaults.controllertypes = list;
            }
        );
    }

    onNewControllerType() {
        /**
         * set the tthis on dialgService to the child forms call the getHeader
         **/
        this.dialogService.tthis = this;
        this.saveService.addListener(this);
        this.dialog.open(ControllerTypeFormComponent, { panelClass: 'custom-mat-dialog' });
    }

    /**
     * called by the SaveService
     * */
    onSave() {
        this.dialog.closeAll();
    }

    /**
     * this is from who calls (inner component)
     * @param list
     * @param row
     * @param column
     */
    public getData(list: ControllerType[], row: number, column: number): string {
        if (list) {
            let e = list[row];
            switch (column) {
                case 0:
                    return e.BK;
                default:
                    return e.Description;
            }
        }
        else
            return "";
    }
}