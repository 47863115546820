<div style="display:flow-root">
    <div style="float:left; width:65%">
        <mat-form-field class="search-input">
            <input placeholder="Filter"
                   matInput
                   [matChipInputFor]="chipList"
                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                   [matChipInputAddOnBlur]="addOnBlur"
                   (matChipInputTokenEnd)="applyFilter($event)">
            <mat-chip-list #chipList>
            </mat-chip-list>
        </mat-form-field>
        <mat-chip *ngFor="let item of searchItems" [selectable]="selectable"
                  [removable]="removable" (removed)="remove(item)">
            {{item.name}}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>

    </div>
    <div style="text-align:right; float:right; width:35%">
        <h2 style="cursor:pointer; display:block;" class="flex-aligned-div" (click)="openDeploymentInformationPopup()">
            Deployment information
            <mat-icon data-html="true" style="color:white">info</mat-icon>
        </h2>
        
    </div>
</div>
<div class="example-container">
    <table mat-table
           [dataSource]="dataSourceDescription" multiTemplateDataRows
           class="mat-elevation-z8 mat-table-custom" id="errorcodedescriptions">
        <ng-container matColumnDef="arrow">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element"
                (click)="pushPopElement(element)">
                <span class="material-icons" *ngIf="checkExpanded(element); else show_expand_more">
                    expand_less
                </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="errorcode">
            <th mat-header-cell *matHeaderCellDef> Error Code </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.ErrorCode"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="descref">
            <th mat-header-cell *matHeaderCellDef> Description Ref</th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.Description_ref"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="desc">
            <th mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.Description" *ngIf="!isEditingRow(element)" [ngClass]="notTranslatedClass(element.Description)"></p>
                <mat-form-field floatLabel="never" *ngIf="isEditingRow(element)">
                    <input matInput [value]="element.Description" [(ngModel)]="element.Description">
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button matTooltip="Edit Row" *ngIf="!isEditingRow(element)" (click)="onRowEditInit(element)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Cancel Row" *ngIf="isEditingRow(element)" (click)="onRowEditCancel(element)">
                    <mat-icon>rotate_left</mat-icon>
                </button>
            </td>
        </ng-container>

        Expanded Content Column - The detail row is made up of this one column that spans across all columns
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                <div [@detailExpand]="checkExpanded(element) ? 'expanded' : 'collapsed'">
                    <table mat-table [dataSource]="element.ErrorCodeResolutions" *ngIf="element.ErrorCodeResolutions" class="mat-subtable-custom" id="errorcodedescriptionsresol">

                        Controller Types
                        <ng-container matColumnDef="failure_ref">
                            <th mat-header-cell *matHeaderCellDef> Maintenace Failure Ref </th>
                            <td mat-cell *matCellDef="let element2">
                                <p [innerHTML]="element2.MaintenanceFailure_Ref"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="failure">
                            <th mat-header-cell *matHeaderCellDef> Maintenace Failure  </th>
                            <td mat-cell *matCellDef="let element2">
                                <p [innerHTML]="element2.MaintenanceFailure" *ngIf="!isEditingResol(element2)" [ngClass]="notTranslatedClass(element2.MaintenanceFailure)"></p>
                                <mat-form-field floatLabel="never" *ngIf="isEditingResol(element2)">
                                    <input matInput [value]="element2.MaintenanceFailure" [(ngModel)]="element2.MaintenanceFailure">
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="troubleshooting_ref">
                            <th mat-header-cell *matHeaderCellDef> Troubleshooting Action Ref </th>
                            <td mat-cell *matCellDef="let element2">
                                <p [innerHTML]="element2.TroubleShootingAction_Ref"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="troubleshooting">
                            <th mat-header-cell *matHeaderCellDef>
                                Troubleshooting Action
                                <mat-icon matTooltipClass='opmodes-custom-tooltip'
                                          (click)="onShowKeywords()"
                                          *ngIf="showKeywordsTooltip(element)"
                                          matTooltip="Add keyword">star</mat-icon>
                            </th>
                            <td mat-cell *matCellDef="let element2">
                                <div [innerHTML]="highlightTroubleshootingWithKeywords(element2.TroubleShootingAction)" *ngIf="!isEditingResol(element2)"
                                     [ngClass]="notTranslatedClass(element2.TroubleShootingAction)"></div>
                                <mat-form-field floatLabel="never" *ngIf="isEditingResol(element2) && (!isShowingKeywordsSelector || element2.PossibleFailureID !== selectedTroubleshooting.PossibleFailureID) ">
                                    <input matInput [value]="element2.TroubleShootingAction" [(ngModel)]="element2.TroubleShootingAction"
                                           id="troubleshooting_{{element2.PossibleFailureID}}"
                                           (click)="selectTroubleshootingInput(element2)">
                                </mat-form-field>
                                <mat-select style="max-width:100px" *ngIf="element2.PossibleFailureID === selectedTroubleshooting.PossibleFailureID && isShowingKeywordsSelector && isEditingResol(element2)"
                                            id="keywords_{{element2.PossibleFailureID}}"
                                            (selectionChange)="onSelectItem($event.value)"
                                            (openedChange)="openedChange($event)">
                                    <mat-option *ngFor="let keyword of keywords" [value]="keyword">
                                        {{ keyword }}
                                    </mat-option>
                                </mat-select>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element2">
                                <button mat-icon-button matTooltip="Edit Row" (click)="onRowResolEditInit(element2)" *ngIf="!isEditingResol(element2)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                                <button mat-icon-button matTooltip="Cancel Row" (click)="onResolEditCancel(element2)" *ngIf="isEditingResol(element2)">
                                    <mat-icon>rotate_left</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row class="subtableheader" *matHeaderRowDef="subcolumnsToDisplay"></tr>
                        <tr mat-row *matRowDef="let element2; let i2 = index; columns: subcolumnsToDisplay;"
                            [class.alternative2]="isEditingResol(element2)"
                            class="subitem"></tr>
                    </table>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row class="tableheader" *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; let i = dataIndex; columns: columnsToDisplay;"
            class="item"
            [class.alternative2]="isEditingRow(element)"></tr>
        <!--[class.example-expanded-row]="checkExpanded(element)"-->
        <!--</tr>-->
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="row-subitem"></tr>
    </table>
</div>

<ng-template #show_expand_more>
    <span class="material-icons">
        expand_more
    </span>
</ng-template>

