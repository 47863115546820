<mat-dialog-content id="form-json-ingress">
    <h2 mat-dialog-title>{{title}}</h2>

    <form [formGroup]="form" (ngSubmit)="onSubmit()">

        <mat-form-field>
            <mat-label>BK</mat-label>
            <input matInput type="text" name="BK" required formControlName="BK"
                   title="Business Key">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Description</mat-label>
            <input matInput type="text" name="Description" required formControlName="Description"
                   title="Description">
        </mat-form-field>

        <mat-form-field>
            <mat-label *ngIf="newRow">AssetType</mat-label>
            <mat-select placeholder="AssetType" name="AssetType" required formControlName="AssetType"
                        title="AssetType">
                <mat-option *ngFor="let a of assettypes; index as i" value="{{a._AssetTypeID}}">
                    {{a.Description}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label *ngIf="newRow">ControllerDrive</mat-label>
            <mat-select placeholder="ControllerDrive" name="ControllerDrive" required formControlName="ControllerDrive"
                        title="ControllerDrive">
                <mat-option *ngFor="let c of controllerdrives; index as i" value="{{c._ControllerDriveId}}">
                    {{c.DriveName}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label *ngIf="newRow">Country Owner</mat-label>
            <mat-select placeholder="Country" name="Country" required formControlName="Country"
                        title="Country">
                <mat-option *ngFor="let c of countries; index as i" value="{{c._CountryID}}">
                    {{c.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label *ngIf="newRow">Many To One Provisioning</mat-label>
            <mat-select placeholder="Many To One Provisioning" name="ManyToOneProvisioning" required formControlName="ManyToOneProvisioning">
                <ng-container *ngFor="let value of yesNoList">
                    <mat-option *ngIf="value.label" [value]="value.value">
                        {{value.label}}
                    </mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>

        <div mat-dialog-actions>
            <button mat-raised-button [disabled]="!form.valid">Save</button>
            <button mat-button (click)="onCancelForm()" tabindex="-1">Cancel</button>
        </div>
    </form>
</mat-dialog-content>