import { Component, EventEmitter, Inject, Input, Output } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DefaultValuesService } from "../../../services/defaultvalues.service";

@Component({
    selector: "controllerconfig-controllertype-connectiontype",
    templateUrl: './controllerconfig-controllertype2connectiontype.component.html',
    styleUrls: ['../../../style/container-tab-list.component.less']
})

export class ControllerConfigControllerTypeToConnectionTypeComponent {

    title: string;

    @Input() rowIndex: number | undefined;
    @Input() selected: ControllerConfig = {} as ControllerConfig;
    @Output() onRefresh = new EventEmitter<{}>();

    constructor(
        private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , public defaults: DefaultValuesService
    ) {
        this.title = "Connection Type";        
    }
}