<div class="flex-aligned-div">
    <div style="float: left; line-height: 2rem; margin-right:0.7rem;">
        <span class="navbar-display" [ngStyle]="{'display': userUPN === '' ? 'none': 'block'}">Welcome: {{userUPN}}</span>
        <span class="navbar-display" *ngIf="!hideOwnerMenuItem" [ngStyle]="{'display': isControllerOwner === undefined ? 'none': 'block', 'color': getColor(isControllerOwner)}">{{ControllerOwnerMessage}}</span>
    </div>

    <div style="float:right; line-height: 2rem;">
        <span>
            <a *ngIf="defaults.componentTool === componentToolEnum.Rulebook" target="_blank" rel="noopener noreferrer" href="https://tkebbxdev.visualstudio.com/E2E%20MAX/_wiki/wikis/E2E-MAX.wiki?wikiVersion=GBwikiMaster&pagePath=%2FW3%20Frontline%20Tools%2FBackOffice%20Application&pageId=229" matTooltip="Open the Wiki page">
                <mat-icon style="color: #ffffff; margin-top:0.5rem;">help_outline</mat-icon>
            </a>
        </span>
        <span>
            <a href="#" matTooltip="Logout" (click)="onLogout()">
                <mat-icon style="color: #ffffff; margin-top: 0.5rem;">lock_open</mat-icon>
            </a>
        </span>
    </div>
</div>


