<div class="container-tab-lists" *ngIf="form">
    <h2>Errors for All Action Components for {{selected.MCPGroup}} in {{selected.MCPTitle}}</h2>
    <maintenance-control-plan-group-action-component-proposed-errors-list [list]="proposed" [checked]="checked"></maintenance-control-plan-group-action-component-proposed-errors-list>

    <div class="form-edit" [ngClass]="dialog.classname!">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">

            <div class="form-group commands">
                <button type="submit"
                        [disabled]="form.invalid"
                        title="Save all selected Proposed Errors"
                        class="btn btn-success">
                    Save
                </button>
                <button type="button"
                        (click)="onBack()"
                        title="Cancel all and Close"
                        class="btn btn-warning">
                    Cancel
                </button>
            </div> <!--form-group commands-->
        </form>
    </div>
</div>