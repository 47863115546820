<h2>Tenant Configuration for {{defaults.tenant}}</h2>
<div style="display:flow-root">
    <div style="float:left; width:40vw">
        <h5>The Remote actions configured in this screen will be the ones allowed for the specified tenant. </h5>
    </div>
    <div style="text-align:right; float:right">
        <h5>**A PBI must be created and assigned to anyone from DevOps Team for deploying new changes.**</h5>
    </div>
</div>
<allowedactions-list [list]="mergedList" [isEditableMethod]="isEditable" (onReload)="onReload($event)"></allowedactions-list>
