<div class="custom-mat-dialog-form">
    <div>
        <div>
            Service ticket category local name
            <br />
            <h4 class="not-translated">{{this.data.CategoryLocale}}</h4>
            <br />
            Correspond to service ticket type harmonized value: <br />
            <br />
        </div>
    </div>
    <div>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">

            <div class="mt-2">

                <mat-select placeholder="Please select" style="width:300px;" class='form-control'
                            name="Controller Type" required formControlName="ServiceTicketCategory">
                    <mat-option *ngFor="let c of ServiceTicketCategoryHarmonizedValues; index as i" value="{{c.ServiceTicketCategoryId}}">
                        {{c.ServiceTicketCategory}}
                    </mat-option>
                </mat-select>
            </div>

            <br />


            <div class="mt-2 right">
                <button type="button"
                        class="btn btn-danger"
                        (click)="onNoClick()">
                    Cancel
                </button>
                <button type="button"
                        class="btn btn-primary"
                        (click)="onSubmit()">
                    SAVE
                </button>
            </div>
        </form>
    </div>
</div>
