<div class="example-container-wsconfig">
    <h2>EndPoints</h2>
    <table mat-table
           [dataSource]="dataSource"
           multiTemplateDataRows
           matSort
           class="mat-elevation-z8 mat-table-custom" id="wsconfiguration-list">

        <ng-container matColumnDef="servicename">
            <th mat-header-cell *matHeaderCellDef> Service Name </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.ServiceName"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="url">
            <th mat-header-cell *matHeaderCellDef> Url </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.Url"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="certificatename">
            <th mat-header-cell *matHeaderCellDef> Certificate Name </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.CertificateName"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="branchnumber">
            <th mat-header-cell *matHeaderCellDef> Branch Number </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.BranchNumber"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="targetsystem">
            <th mat-header-cell *matHeaderCellDef> Target System </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.TargetSystem"></p>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; let i = dataIndex;
            columns: columnsToDisplay;"
            class="opmode-item">
        </tr>
    </table>
</div>