import { Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { EmailSearchfor, Utils } from "../../../../utils/utils";
import { MatDialogRef } from "@angular/material/dialog";
import { DialogService } from "../../../../services/dialog.service";
import { DefaultValuesService } from "../../../../services/defaultvalues.service";
import { Router } from "@angular/router";
import { InternalMaxUserComponent } from "./internalmaxuser/internalmaxuser-form.component";
import { TKEUserComponent } from "./tkeuser/tkeuser-form.component";

@Component({
    selector: "adduser-tab",
    templateUrl: 'adduser-tab.component.html',
    encapsulation: ViewEncapsulation.None

})

export class AddUserTabComponent implements OnInit {

    userId = '';
    isBulk = false;
    createUserPopupInfo: UserCreatePopupInformation =
        {
            domains: [],
            isInternalAddAllowedForAll: true,
            secondaryTenants: [],
            tenants: []

        }
    constructor(
        public defaults: DefaultValuesService
        ,private http: HttpClient
        , public dialogRef: MatDialogRef<AddUserTabComponent>
        , private confimDialog: DialogService
        , @Inject('BASE_URL') private baseUrl: string
        , private router: Router
    ) {

        this.confimDialog.tthis = this;
    }

    ngOnInit() {
        Utils.httpGetCreateUserPopupInformation(
            this.http
            , this.baseUrl
            , this
            , function (tthis: AddUserTabComponent, data: UserCreatePopupInformation) {
                tthis.createUserPopupInfo = data;
            }
        )
    }

    onCloseFunction(data: string) {
        this.dialogRef.close();
        if (data === '')
            this.confimDialog.showErrorDialog({
                'error':
                {
                    'message': 'User could not be created. Please, retry in a couple of minutes.'
                }
            })
        else {
            this.userId = data;
            this.confimDialog.showSuccessDialog("User has been created with user id - " + this.userId + ". Click OK button to add Personas and Branches.")
        }
    }

    onCloseBulkFunction(data: boolean) {
        this.isBulk = true;
        this.dialogRef.close();
        if (data !== true)
            this.confimDialog.showErrorDialog({
                'error':
                {
                    'message': 'Users could not be created. Please, retry in a couple of minutes.'
                }
            })
        else
            this.confimDialog.showSuccessDialog("Users have been created")
    }

    onBack() {
        if (!this.isBulk) {
            this.router.navigate(["EditUser/" + this.userId]);

        }
        else {
            this.isBulk = false;
        }
    }
    onCloseDialogueFunction() {
        this.dialogRef.close();
    }

    checkTypedInEmails(childComponent: InternalMaxUserComponent | TKEUserComponent, email: string) {
        if (email !== '') {
            Utils.httpGetUsersGroupListByFilter(
                this.http
                , this.baseUrl
                , {
                    "Page": 0,
                    "PageSize": 5,
                    "UserPrincipalName": "",
                    "GivenName": "",
                    "CdpGroup": [""],
                    "NodeId": [""],
                    "Tenant": this.defaults.umpFilters.tenants.map(tenant => tenant.value),
                    "MailO356Account": email.toLocaleLowerCase(),
                     "EmailSearchFor": EmailSearchfor.UserCreation
                }, this
                , function (tthis: AddUserTabComponent, data: UserList) {
                    if (childComponent.showEmailError != null) {
                        if (data.allowedNewUserType?.length > 0) {
                            let showError = true
                            data?.allowedNewUserType.forEach(type => {
                                var typedot = type == '' ? type: '.'+type
                                email.toLocaleLowerCase().includes(`${typedot}@tkelevator.com`) ? showError = false : null
                            })
                            if (showError) {
                                var externaldet = data.allowedNewUserType.includes('external') ?
                                                    ' (external: .external@tkelevator.com)': '';
                                childComponent.showEmailError(`Can only invite user from ${data?.allowedNewUserType.join('/')} domains ${externaldet}`)
                            }
                            else {
                                tthis.userAlreadyExistHandler(data, childComponent)
                            }
                        }
                        else {
                            tthis.userAlreadyExistHandler(data, childComponent)
                        }
                        
                    }
                }
                , this.defaults.isDataAnalytStage()
            )

        }
        else
            childComponent.showEmailError(null);
    }
    userAlreadyExistHandler(data, childComponent) {
        if (data?.totalResultsCount > 0)
            childComponent.showEmailError("User already exists");
        else
            childComponent.showEmailError(null);
    }
}