import { Component, Inject, OnInit, Input } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { DialogService } from "../../services/dialog.service";
import { SaveService } from "../../services/save.service";
import { Utils } from "../../utils/utils";

@Component({
    selector: "businesskey-form",
    templateUrl: './businesskey-form.component.html',
    styleUrls: ['../../style/form.component.less']
})

export class BusinessKeyFormComponent implements OnInit {


    @Input() list: BusinessKey[] = [];
    @Input() isToShowColumn2 = false;
    @Input() header: any[] = [];
    @Input() rowIndex!: number;

    title: string;

    selected: BusinessKey = <BusinessKey>{};

    classname: String[] = [];
    isundodisabled: boolean[] = [];

    newRow = true;
    DESCRIPTION = 1;

    form: FormGroup = new FormGroup({});

    constructor(
        private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , private dialog: DialogService
        , private saveService: SaveService
    ) {        
        this.title = "Manage BusinessKeys";
    }

    ngOnInit() {

        if (this.header.length === 0) //this is a new form
            this.header = this.dialog.getHeader();

        this.DESCRIPTION = this.header.length - 1; // if isAdminUser, the description is on the columnindex = 2


        this.newRow = (this.rowIndex === undefined);
        this.saveService.addListener(this);
        this.saveService.initUndo(this);

        this.createForm();
        if (!this.newRow && this.rowIndex !== undefined) {
            this.selected.Description = this.list[this.rowIndex].Description;
            this.selected.Description_REF = this.list[this.rowIndex].Description_REF;
            this.selected.BusinessKeyID = this.list[this.rowIndex].BusinessKeyID;
            this.selected.BK = this.list[this.rowIndex].BK;
            this.updateForm();
        }
    }

    onDescriptionChange() {
        if (this.form && this.rowIndex !== undefined && this.form.controls['Description'].value !== this.selected.Description) {
            this.list[this.rowIndex].Description = this.form.controls['Description'].value;
            this.saveService.setFieldUndoDisable(this.DESCRIPTION, false, this);
        }
        this.saveService.showSaveButton();
    }

    onDescriptionUndo() {
        if (this.rowIndex !== undefined) {
            this.list[this.rowIndex].Description = this.selected.Description;
            this.saveService.setFieldUndoDisable(this.DESCRIPTION, true, this);
            this.updateForm();
        }
    }

    onCancelForm() {
        this.saveService.fireCancel(this.rowIndex, this);
    }

    onCancel(index: number) {
        if (!this.newRow && index === this.rowIndex) {
            this.onDescriptionUndo();
        }
    }

    /**
     * called by the SaveService
     * */
    onSave() {
        if (this.newRow)
            this.createObject();
        else if (this.rowIndex !== undefined)
            this.selected = this.list[this.rowIndex];

        //const tthis: this;
        this.http
            .post<BusinessKey>(
                this.baseUrl + Utils.getBusinessKeyAPI() + "translate"
                , this.selected
            )
            .subscribe(
                res => {
                    if (this.rowIndex !== undefined)
                        this.list[this.rowIndex] = res;
                    else if (this.newRow)
                        this.list.unshift(res);
                }
                , error => {
                    this.dialog.showErrorDialog(error);
                }
            );

        //if (this.form) {
        //    this.http
        //        .post<BusinessKey>(
        //            this.baseUrl + Utils.getBusinessKeyAPI() + "translate"
        //            , this.list[this.rowIndex]
        //        )
        //        .subscribe(
        //            res => this.list[this.rowIndex] = res
        //            , error => this.dialog.showErrorDialog(error)
        //        );
        //}
    }

    /**
     * called by the save button on this form
     * 
     * */
    onSubmit() {
        const value = this.rowIndex === undefined ? -1 : this.rowIndex;
        this.saveService.fireSave(value, this);
    }

    private createForm() {
        this.form = new FormGroup({
            Description: new FormControl('', [Validators.required]),
        });
    }

    private createObject() {
        this.selected.Description = this.form.controls['Description'].value;
    }

    private updateForm() {
        if (this.form && this.rowIndex !== undefined) {
            const e = {
                Description: this.list[this.rowIndex].Description
            };
            this.form.setValue(e);
        }
    }

}