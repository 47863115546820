<div class="container-tab-lists new-table-style">
    <div class="inner-tcontainer-tab">

        <h2>{{title}}</h2>

        <br />

        <div style="text-align:right; float:right">

            <a class='kpi-save-menu save-button' title="Save" (click)="save()">
                <span class='glyphicon glyphicon-floppy-disk'></span> Save
            </a>
        </div>
        <sectionscheduler-list [list]="list"></sectionscheduler-list>
    </div> <!--inner-tcontainer-tab-->
</div> <!--container-tab-lists-->
