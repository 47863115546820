 <!--The Heading sections (Heading + Serach )-->
<div class="row header-section mt-5">

    <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
        <h4>
            Excellence Lever KPI
        </h4>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 flex-right">
        <div class="OrangeButton" style="margin-right: 50px;">
            <button mat-raised-button (click)="openCreateExcellenceLeverKPIPopup()" title="Create new Excellence Factor KPI" class="btn btn-sm button-color-fix">
                Add KPI <mat-icon class="add-button icon-color-fix">add_box</mat-icon>
            </button>
        </div>
    </div>

</div>

<div class="">
    <table mat-table #itemsList
           [dataSource]="dataSource"
           multiTemplateDataRows
           class="mat-elevation-z8 mat-table-custom" id="excellenceleverkpi">

        <ng-container matColumnDef="ExcellenceLever">
            <th mat-header-cell *matHeaderCellDef> Excellence Lever </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.ExcellenceLever"></p>
            </td>
        </ng-container>
        <ng-container matColumnDef="AccountId">
            <th mat-header-cell *matHeaderCellDef> Account Id </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.AccountId"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="ChangedBy">
            <th mat-header-cell *matHeaderCellDef> Changed By </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.ChangedBy"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="ChangedAtUtc">
            <th mat-header-cell *matHeaderCellDef> Changed At Utc </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.ChangedAtUtc | date:'yyyy-MM-dd HH:mm'"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let element; let i = dataIndex">
                <button mat-icon-button matTooltip="Edit Excellence Lever KPI" (click)="onEditExcellenceLeverKPI(element)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Remove Excellence Lever KPI" (click)="onRemoveExcellenceLeverKPI(element)">
                    <mat-icon>delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row class="tableheader" *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; let i = dataIndex; columns: columnsToDisplay;"
            class="item">
        </tr>
    </table>
</div>
