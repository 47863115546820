import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { Utils } from '../../../../../utils/utils';

@Component({
    selector: 'create-action-item-popup',
    templateUrl: './create-action-item-popup.component.html',
    styleUrls: ['./create-action-item-popup.component.less']
})
export class CreateActionItemPopupComponent implements OnInit {

    form!: FormGroup
    availableKpis: any[] = []
    subscriptions: any[] = []
    minDate: Date = new Date()
    buttonLabel: string = 'Create';
    buttonIcon: string = 'add';
    constructor(
        public dialogRef: MatDialogRef<CreateActionItemPopupComponent>,
        private formBuilder: FormBuilder,
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private toastrService: ToastrService) { }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            measureId: [this.data?.measureId],
            actionItemId: [this.data?.ActionItemId],
            description: [this.data?.Description, Validators.required],
            name: [this.data?.Name, Validators.required],
            owner: [this.data?.Owner, Validators.required],
            comments: [this.data?.Comments, Validators.required],
            deadline: [this.data?.Deadline, Validators.required]
        });
        this.buttonLabel = this.data?.ActionItemId ? 'Update' : 'Create';
        this.buttonIcon = this.data?.ActionItemId ? 'save' : 'add';
    }

    onSubmit() {
        // stop here if form is invalid
        if (this.form)
            if (this.form.invalid) {
                return;
            }

        Utils.httpUpsertActionItem(
            this.http
            , this.baseUrl
            , this.form.value
            , this
            , function (tthis: CreateActionItemPopupComponent, result: any) {
                tthis.dialogRef.close({ event: 'submit' });
                if (result) {
                    tthis.toastrService.success(`Action Item was ${tthis.data.actionItemId ? 'updated' : 'created'} successfully!`);
                }
                else
                    tthis.toastrService.error(`Action Item could not be ${tthis.data.actionItemId ? 'updated' : 'created'}. Please, contact with administrator`);
            },
            null
        );
    }

    backButtonHandler(event: any) {
        event.preventDefault();
        this.dialogRef.close();
    }
}
