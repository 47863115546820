<div class="custom-mat-dialog-form">
    <div>
        <h3>Add Controller Type to {{data.country}}</h3>
    </div>
    <div>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="mt-2">
                <label>Controller Type *</label>
                <div class="input">
                    <div class="input">
                        <mat-select [(ngModel)]="body.ControllerTypeID" class="form-control" formControlName="ct" readonly>
                            <mat-option *ngFor="let ct of controllerTypes" [value]="ct.ControllerTypeID">
                                {{ ct.Description }}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>
            </div>

            <div class="mt-2 right">
                <button type="button"
                        class="btn btn-danger"
                        (click)="onBack()">
                    Close
                </button>
                <button type="button"
                        class="btn btn-primary"
                        (click)="onSubmit()">
                    Send
                </button>
            </div>
        </form>
    </div>
</div>
