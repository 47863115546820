import { Component, Inject, OnInit, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Utils } from "../../utils/utils";
import { DefaultValuesService } from "../../services/defaultvalues.service";
import { DialogService } from "../../services/dialog.service";
import { SaveService } from "../../services/save.service";

//const WEIGHT = [{ value: 0, key: '0' }, { value: 1, key: '1' }, { value: 2, key: '2' }, { value: 3, key: '3' }];

@Component({
    selector: "maintenance-control-plan-new",
    templateUrl: './mcp-new.component.html',
    styleUrls: ['../../style/form.component.less']
})

export class MaintenanceControlPlanNewComponent implements OnInit {
    @Input() header: any[] = [];
    @Input() list: MaintenanceControlPlan[] = [];
    @Input() rowIndex: number = -1;
    @Input() newRow: boolean = true;

    TITLE: number = 0;
    ASSET: number = 1;
    ISHYDRAULIC: number = 2;

    title: string | undefined;

    selected: MaintenanceControlPlan = <MaintenanceControlPlan>{};

    classname: String[] = [];
    isundodisabled: boolean[] = [];

    form!: FormGroup;

    assets: string[] = ['Elevator', 'Escalator'];

    constructor(
        private http: HttpClient
        , private fb: FormBuilder
        , @Inject('BASE_URL') private baseUrl: string
        , private dialog: DialogService
        , private saveService: SaveService
    ) {
    }

    private onFieldChange(index: number, field1: any, field2: any): boolean {
        return this.saveService.onFieldChange(index, field1, field2, this);
    }

    private onFieldUndo(index: number) {
        this.saveService.onFieldUndo(index, this);
    }

    ngOnInit() {

        this.dialog.tthis = this;

        this.saveService.addListener(this);
        this.saveService.initUndo(this); //it calls the method this.createForm()

        if (!this.newRow) {
            this.selected.Title = this.list[this.rowIndex].Title;
            this.selected.AssetType = this.list[this.rowIndex].AssetType;
            this.selected.IsHydraulic = this.list[this.rowIndex].IsHydraulic;

            this.updateForm();
        }
    }

    createForm() {
        this.form = this.fb.group({
            Title: ['', Validators.required]
            , Asset: ['', Validators.required]
            , Hydraulic: ['']
        });
    }

    onAssetChange() {
        if (this.form && this.onFieldChange(this.ASSET, this.form.value.Asset, this.selected.AssetType) && this.selected)
            this.list[this.rowIndex].AssetType = this.form.value.Asset;
    }

    onAssetUndo() {
        this.list[this.rowIndex].AssetType = this.selected.AssetType;
        this.onFieldUndo(this.ASSET);
    }

    onRowCancel() {
        this.saveService.fireCancel(this.rowIndex, this);
    }

    onTitleChange() {
        if (this.form && this.onFieldChange(this.TITLE, this.form.value.Title, this.selected.Title) && this.selected)
            this.list[this.rowIndex].Title = this.form.value.Title;
    }

    onTitleUndo() {
        this.list[this.rowIndex].Title = this.selected.Title;
        this.onFieldUndo(this.TITLE);
    }

    onIsHydraulicChange() {
        if (this.form && this.onFieldChange(this.ISHYDRAULIC, this.form.value.Hydraulic, this.selected.IsHydraulic) && this.selected)
            this.list[this.rowIndex].IsHydraulic = this.form.value.Hydraulic;
    }

    onIsHydraulicUndo() {
        this.list[this.rowIndex].IsHydraulic = this.selected.IsHydraulic;
        this.onFieldUndo(this.ISHYDRAULIC);
    }

    onSave() {
        if (this.form && this.form.valid) {
            if (this.newRow) {
                this.selected.Title = this.form.value.Title;
                this.selected.AssetType = this.form.value.Asset;
                this.selected.IsHydraulic = this.form.value.Hydraulic != "" ? true : false;

                this.http
                    .put<MaintenanceControlPlan>(
                        this.baseUrl + Utils.getMaintenanceControlPlanAPI() + "add"
                        , this.selected
                    )
                    .subscribe(
                        res => this.list.unshift(res)
                        , error => this.dialog.showErrorDialog(error)
                    );
            }
            else {
                this.http
                    .post<MaintenanceControlPlan>(
                        this.baseUrl + Utils.getMaintenanceControlPlanAPI() + "edit"
                        , this.list[this.rowIndex]
                    )
                    .subscribe(
                        res => this.list[this.rowIndex] = res
                        , error => this.dialog.showErrorDialog(error)
                    );
            }
        }
        else
            this.dialog.showErrorStringDialog("Error Saving data!");
    }

    updateForm() {
        if (this.form) {
            var e = {
                Title: this.list[this.rowIndex].Title
                , Asset: this.list[this.rowIndex].AssetType
                , Hydraulic: this.list[this.rowIndex].IsHydraulic
            };
            this.form.setValue(e);
        }
    }
}