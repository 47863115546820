import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogService } from '../../../../../../services/dialog.service';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Utils } from '../../../../../../utils/utils';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-assigncontact-popup',
    templateUrl: './assigncontact-popup.component.html',
    styleUrls: ['./assigncontact-popup.component.less']
})
export class AssignContactPopupComponent implements OnInit {
    form!: FormGroup
    availableContacts: any[] = []
    constructor(private http: HttpClient
        , private confimDialog: DialogService
        , private router: Router
        , @Inject(MAT_DIALOG_DATA) public data: any
        , @Inject('BASE_URL') private baseUrl: string
        , public dialogRef: MatDialogRef<AssignContactPopupComponent>
        , private formBuilder: FormBuilder
        , private toastrService: ToastrService
    ) { }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            contact: ['', Validators.required],
        });
        Utils.httpGetAvailableContact(
            this.http
            , this.baseUrl
            , this
            , function (tthis: AssignContactPopupComponent, data: any[]) {
                tthis.availableContacts = data;
            },
            null
        )
    }

    onSubmit() {
        // stop here if form is invalid
        if (this.form)
            if (this.form.invalid) {
                return;
            }

        Utils.httpAssignContact(
            this.http
            , this.baseUrl
            , this.form.value['contact']
            , this
            , function (tthis: AssignContactPopupComponent, result: any) {
                tthis.dialogRef.close({ event: 'submit' });
                if (result)
                    tthis.toastrService.success('Contact was assigned successfully!');
                else
                    tthis.toastrService.error('Contact could not be assigned. Please, contact with administrator');

            },
            null
        );
    }
    backButtonHandler(event: any) {
        event.preventDefault();
        this.dialogRef.close();

    }

}
