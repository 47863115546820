import { Component, Inject, Input, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { MatChipInputEvent } from "@angular/material/chips";
import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';
import { SaveService } from '../../../../services/save.service';
import { Utils } from '../../../../utils/utils';
import { DefaultValuesService } from '../../../../services/defaultvalues.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Subject } from "rxjs";
import { DialogService } from "../../../../services/dialog.service";
@Component({
    selector: "customeritem-list",
    templateUrl: './customeritem-list.component.html',
    styleUrls: ['./customeritem-list.component.less', '../../../../style/mat-table.component.less'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*', })),
            transition('expanded => collapsed', [animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
        ]),
    ]
})

export class CustomerItemListComponent implements OnInit {
    @Input('list') list: Customer[] = [];
    @Input('showLocalFilter') showLocalFilter = false;

    columnsToDisplay = ['arrow', 'index', 'CustomerId', 'CustomerName', '_ChangedAtUtc', 'actions'];

    subcolumnsToDisplay = ['CustomerAddress', 'CustomerZipCode',
        'CustomerCity', 'CustomerVAT', 'CustomerRegion', 'CustomerCountry',
        'CustomerPhone', 'CustomerEMail', 'ParentCustomerId', 'IsPreliminaryData',
        'CustomerSince', 'IsDeleted', 'IsKeyAccount', 'IsEInvoicing', 'Source',

        'CustomerErpCode', 'ErpType', 'CustomerType', 'CustomerAccountType',
        'CustomerBusinessType', 'CustomerCategoryType', 'CustomerMarketSegment',
        'UnionLocal', 'Suburb', 'CustomerWebSite', 'TaxNumber', 'CrmType', 'AbbreviateName', 'NationalAccount'
    ];

    booleanColumns = ['IsPreliminaryData', 'IsDeleted', 'IsKeyAccount', 'IsEInvoicing']

    dateColumns = ['CustomerSince', '_ChangedAtUtc']

    @ViewChild(MatTable, { static: true }) table!: MatTable<any>;

    public dataSource = new MatTableDataSource<Customer>();
    selected: Customer | undefined;
    expanded: boolean[] = [];
    expandedElements = new Set();
    clonedList: { [s: string]: Customer; } = {};

    filter: string = '';

    subscriptionTenant: any;

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [COMMA, TAB, ENTER];
    searchItems: SearchItem[] = [];

    protected _onDestroy = new Subject<void>();

    constructor(private activatedRoute: ActivatedRoute
        , private router: Router
        , private http: HttpClient
        , private saveService: SaveService
        , private defaults: DefaultValuesService
        , private confimDialog: DialogService
        , @Inject('BASE_URL') private baseUrl: string

    ) {
    }

    ngOnChanges() {
        this.dataSource.data = this.list
        this.clonedList = {}
        this.saveService.showSaveButton(false)
        this.expandedElements.clear();
        this.saveService.addListener(this)
    }

    ngOnInit() {
        this.getHeader();
        this.subscriptionTenant = this.defaults.tenantMessageChanges$.subscribe(() => {
            this.getHeader()
        });
        this.saveService.addListener(this);
        this.dataSource.filterPredicate = (data: Customer, filters: string) => {
            const matchFilter: any[] = [];
            const filterArray = filters.split('+').filter(x => x.indexOf('notextfilters') < 0)
            if (filterArray.length > 0) {
                filterArray.forEach((filter) => {
                    let result = false;
                    this.columnsToDisplay.forEach((column) => {
                        const value = data[column]
                        if (value)
                            result = result || value.toString().toLowerCase().indexOf(filter.toLowerCase()) > -1
                    })
                    return matchFilter.push(result);
                });
            }
            else {
                matchFilter.push(true)
            }
            return matchFilter.some(Boolean);
        };
    }

    ngOnDestroy() {
        this.saveService.removeListener(this);
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    getHeader() {
        if (!this.defaults.isReadonlyUser)
            this.columnsToDisplay = ['arrow', 'index', 'CustomerId', 'CustomerName', '_ChangedAtUtc', 'actions'];
        else
            this.columnsToDisplay = ['arrow', 'index', 'CustomerId', 'CustomerName', '_ChangedAtUtc'];
        this.subcolumnsToDisplay.sort((x, y) => (x > y ? 1 : -1));
    }

    checkExpanded(element: Customer) {
        return this.expandedElements.has(element);
    }

    changeElement(contract: Customer, type: string, value: boolean) {
        let index = this.dataSource.data.findIndex(x => x.CustomerName === contract.CustomerName
            && x.CustomerId === contract.CustomerId)
        if (this.columnsToDisplay.find(x => x === type)) {
            this.dataSource.data[index][type] = value
        } else {
            this.dataSource.data[index].Details[0][type] = value
        }
        this.table.renderRows();
    }

    pushPopElement(element: Customer) {
        if (this.expandedElements.has(element)) {
            this.expandedElements.delete(element);
        }
        else {
            this.expandedElements.add(element);
            this.onExpand(element)
        }
    }
    onExpand(element: Customer) {
        Utils.httpGetDetailedCustomer(
            this.http
            , this.baseUrl
            , this.defaults.stage
            , element
            , this
            , function (tthis: any, list: CustomerDetails[]) {
                element.Details = list;
                tthis.expandedElements.add(element);
            }
        );
    }

    getInput(data: Customer, property: string): any {
        return data[property];
    }

    getDataType(data: Customer, property: string) {
        let result = ''
        if (this.booleanColumns.indexOf(property) > -1) result = 'boolean'
        else if (this.dateColumns.indexOf(property) > -1) result = 'date'
        else result = 'string'
        return result
    }

    onRowEditInit(customer: Customer) {
        this.saveService.addListener(this);
        this.clonedList[customer.CustomerId] = { ...customer };
        if (Object.keys(this.clonedList).length !== 0) this.onShowSavebutton()
        this.onExpand(customer);
    }

    onRowEditCancel(customer: Customer) {
        this.dataSource.data[this.dataSource.data.findIndex(x => x.CustomerId === customer.CustomerId)] = this.clonedList[customer.CustomerId];
        delete this.clonedList[customer.CustomerId];
        if (Object.keys(this.clonedList).length === 0) this.saveService.showSaveButton(false)
        this.table.renderRows();
    }

    isEditingRow(customer: Customer, property: string) {
        return this.clonedList[customer.CustomerId] &&
            (property !== 'CustomerId' && property !== 'CustomerName' && property !== '_ChangedAtUtc'
                && property !== 'Source')
    }

    onShowSavebutton() {
        this.saveService.showSaveButton();
    }

    onSave() {
        let updatedList: any[] = []
        for (let i in this.clonedList) {
            let index = this.dataSource.data.findIndex(x => x.CustomerId === this.clonedList[i].CustomerId &&
                x.CustomerName === this.clonedList[i].CustomerName)
            updatedList.push({ ...this.dataSource.data[index], ...this.dataSource.data[index].Details[0] })
        }
        if (updatedList.length > 0)
            Utils.httpUpdateCustomers(
                this.http
                , this.baseUrl
                , this.defaults.stage
                , updatedList
                , this
                , function (tthis: any, list: Customer[]) {
                    tthis.clonedList = {}
                    tthis.saveService.showSaveButton(false)
                    tthis.expandedElements.clear();
                    tthis.table.renderRows();
                }, function (tthis: CustomerItemListComponent, message: string) {
                    tthis.saveService.addListener(tthis)
                    tthis.saveService.showSaveButton(true)
                    tthis.confimDialog.showErrorDialog(message);
                }
            );
    }

    applyFilter(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            this.searchItems.push({ name: value.replace('+', '').trim() });
        }
        // Reset the input value
        if (input) {
            input.value = '';
        }
        this.updatefilter()
    }

    remove(item: SearchItem): void {
        const index = this.searchItems.indexOf(item);
        if (index >= 0) {
            this.searchItems.splice(index, 1);
        }
        this.updatefilter()
    }

    updatefilter() {
        let filterString: string
        if (this.searchItems.length === 0) {
            this.dataSource.filter = 'notextfilters'
        }
        else {
            filterString = this.searchItems.map(e => { return e.name }).join('+') + '+checkboxes'
            this.dataSource.filter = filterString
        }
    }
}
export interface SearchItem {
    name: string;
}