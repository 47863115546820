import { Component, Inject, OnInit, ElementRef, ViewChild, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Utils } from "../../utils/utils";
import { DialogService } from "../../services/dialog.service";
import { DefaultValuesService } from "../../services/defaultvalues.service";
import { SaveService } from "../../services/save.service";

@Component({
    selector: "maintenance-control-plan-group-action-component-new",
    templateUrl: './mcp-new-group-action-component.component.html',
    styleUrls: ['../../style/form.component.less']
})

export class MaintenanceControlPlanGroupActionComponentNewComponent implements OnInit {
    @Input() action: MaintenanceControlPlanGroupAction = <MaintenanceControlPlanGroupAction>{};
    @Input() list: MaintenanceControlPlanGroupActionComponent[] = [];

    @Input() header: any[] = [];
    @Input() rowIndex: number = -1;
    @Input() newRow: boolean = true;

    LOCATION: number = 0;
    COMPONENT: number = 1;

    title: string | undefined;

    classname: String[] = [];
    isundodisabled: boolean[] = [];

    selected: MaintenanceControlPlanGroupActionComponent = <MaintenanceControlPlanGroupActionComponent>{};

    locations: ComponentSubSystem[] = [];
    components: ComponentItem[] = [];

    form!: FormGroup;

    constructor(
        private activatedRoute: ActivatedRoute
        , private router: Router
        , private http: HttpClient
        , private fb: FormBuilder
        , @Inject('BASE_URL') private baseUrl: string
        , private dialog: DialogService
        , private saveService: SaveService
    ) {
    }

    private onFieldChange(index: number, field1: any, field2: any): boolean {
        return this.saveService.onFieldChange(index, field1, field2, this);
    }

    private onFieldUndo(index: number) {
        this.saveService.onFieldUndo(index, this);
    }

    ngOnInit() {

        this.dialog.tthis = this;

        this.saveService.addListener(this);

        this.title = "Create a new MCP Group Action Component";
        this.saveService.initUndo(this); //it calls the method this.createForm()

        Utils.httpGetAllLocations(
            this.http
            , this.baseUrl
            , this
            , function (tthis: any, list: ComponentSubSystem[]) {
                tthis.locations = list;
            }
        );
    }

    createForm() {
        this.form = this.fb.group({
            Location: ['', Validators.required],
            Component: ['', Validators.required],
        });
    }

    //onBack() {
    //    this.router.navigate(["maintenancecontrolplangroupactiondetail", this.action.MCPActionID]);
    //}

    onCancel(index: number) {
        if (!this.newRow && index == this.rowIndex) {
            this.onLocationUndo();
            this.onComponentUndo();
        }
    }

    onComponentChange() {
        if (this.form && this.onFieldChange(this.COMPONENT, this.form.value.Component, this.selected.ComponentItemID) && this.selected)
            this.list[this.rowIndex].ComponentItemID = this.form.value.Component;

    }

    onComponentUndo() {
        if (this.rowIndex != -1)
            this.list[this.rowIndex].Location = this.selected.Location;
        this.onFieldUndo(this.LOCATION);
    }

    onLocationChange(select: any) {
        if (!select.target) return;
        Utils.httpGetAllComponents(
            this.http
            , this.baseUrl
            , this
            , select.target.value
            , function (tthis: any, list: ComponentItem[]) {
                tthis.components = list;
            }
        );
    }

    onLocationUndo() {
        if (this.rowIndex != -1)
            this.list[this.rowIndex].Location = this.selected.Location;
        this.onFieldUndo(this.LOCATION);
    }

    onRowCancel() {
        this.saveService.fireCancel(this.rowIndex, this);
    }

    onSave() {
        if (this.form && this.form.valid) {
            if (this.newRow) {
                this.selected.MCPActionID = this.action.MCPActionID;
                this.selected.ComponentItemID = this.form.value.Component;             

                this.http
                    .put<MaintenanceControlPlanGroupActionComponent>(
                        this.baseUrl + Utils.getMaintenanceControlPlanAPI() + "addgroupactioncomponent"
                        , this.selected
                    )
                    .subscribe(
                        res => this.list.unshift(res)
                        , error => this.dialog.showErrorDialog(error)
                    );
            }
        }
        else
            this.dialog.showErrorStringDialog("Error Saving data!");
    }
}