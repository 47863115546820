<mat-accordion multi class="expand-collapse-panel">
    <mat-expansion-panel>
        <mat-expansion-panel-header>
        </mat-expansion-panel-header>
        <form autocomplete="off" [formGroup]="form" id="filter">
        <tr>
            <td>
                <mat-form-field class="search-input">
                        <mat-label>Customer Id</mat-label>
                        <input matInput placeholder="Customer Id" id="CustomerId" type="text" formControlName="CustomerId" class="mat-chip-input" />
                </mat-form-field>
            </td>
            <td style="width:48%;">
                <mat-form-field class="search-input">
                    <mat-label> Customer Name </mat-label>
                    <input matInput placeholder="Customer Name" id="Customer Name" type="text" formControlName="CustomerName" class="mat-chip-input" />
                </mat-form-field>
            </td>
            <td style="width:20%" class="middle-align">
                <button type="button" class="btn mat-flat-button btn-primary" (click)="onFilter()">
                    {{ changeTypeButton() ? 'Reset' : 'Filter' }}
                </button>
            </td>
        </tr>
        </form>
    </mat-expansion-panel>
</mat-accordion>