<div class="license_mgmt">
    <!--The Heading sections (Heading + Serach )-->
    <div class="row heading-background mt-5">

        <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9">
            <h4>
                License Management
            </h4>
        </div>

        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 search-container">

            <button mat-raised-button (click)="funcGetLicenseMgmt()" aria-label="Reset" class="btn btn-sm button-color-fix ">
                <mat-icon>cached</mat-icon>
            </button>
        </div>
    </div>



    <!--List section-->

    <div class="custom_small_panelbody_fix">


        <div *ngIf='Response_State===1'>
            <div class="row license_progress">
                <mat-selection-list (selectionChange)="itemSelected($event)">
                    <mat-list-option [value]="entity.id"
                                     *ngFor="let entity of entities"
                                     checkboxPosition="before"
                                     [selected]="entity.isSelected"
                                     [disabled]=" entity.isInherited"
                                     [ngStyle]="{'color': entity.isInherited === true ? 'grey' : '', 'background-color': entity.isInherited === true ? 'inherit' : 'inherit'}">

                        <div class="col-lg-9">
                            {{ entity.persona }}{{ entity.isInherited === true ? '(INHERITED)' : ''}}
                            <span *ngIf="hasAdditionalCosts(entity.persona) === 1" style="color:greenyellow !important">$$</span>
                            <span *ngIf="hasAdditionalCosts(entity.persona) === 0" style="color:greenyellow !important">$</span>
                        </div>
                        <div class="col-lg-3">

                            <div [matTooltip]="progressBarStats(entity)" matTooltipClass="my-tooltip" class="progress dark">
                                <div class="progress-bar">
                                    <div class="progress-value" [ngStyle]="{ 'width.%': licenseAsgndPercent(entity)}">{{entity.consumedLicenses}}</div>
                                    <div class="Available" [ngStyle]="{ 'width.%': licenseAvlblPercent(entity)}">{{entity.totalLicenses - entity.consumedLicenses }}</div>
                                </div>
                            </div>

                        </div>

                    </mat-list-option>

                </mat-selection-list>

            </div>
        </div>


        <div *ngIf='Response_State===-1'>
            <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; height: 150px;">
                <p style="color: #ff3232; font-weight: 600;">ERROR: {{errorResponse}}</p>
            </div>
        </div>
        <div *ngIf='Response_State===-2' style=" padding: 20px !important;">
            <div class="blackbg card__desc">
                <div *ngFor="let _ of [].constructor(6)" class="card__desc-text"></div>
            </div>
        </div>


    </div>


</div>



