<div class='left-menu'>
    <ul class='nav navbar-nav'>
        <li [routerLinkActive]="['link-active']" *ngIf="!defaults.isReadonlyUser">
            <a class='menu-callback' (click)="newEvent.emit({})" title="Create a new Maintenance Control Plan">
                <span class='glyphicon glyphicon-plus-sign'></span> New MCP
            </a>
        </li>

        <li class="separator" [ngClass]="saveService.classSaveButton" *ngIf="!defaults.isReadonlyUser"></li>
        <li [routerLinkActive]="['link-active']" [ngClass]="saveService.classSaveButton" *ngIf="!defaults.isReadonlyUser">
            <a class='menu-callback' (click)="saveService.fireSave()" title="Save all Error Code(s) and Resolution(s)">
                <span class='glyphicon glyphicon-floppy-disk'></span> Save
            </a>
        </li>

    </ul>
</div>