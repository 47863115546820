import { Component, Output, EventEmitter } from '@angular/core';
import { DefaultValuesService } from '../../services/defaultvalues.service';
import { SaveService } from '../../services/save.service';

@Component({
    selector: 'maintenance-control-plan-menu',
    templateUrl: './mcp-menu.component.html',
    styleUrls: ['../../style/menu.component.less']
})

export class MaintenanceControlPlanMenuComponent {
    @Output() newEvent = new EventEmitter<{}>();

    constructor(
        public saveService: SaveService
        , public defaults: DefaultValuesService
    ) {
    }
}
