import { Component, Inject, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Utils } from "../../utils/utils";
import { ActivatedRoute } from "@angular/router";
import { SaveService } from "../../services/save.service";
import { DialogService } from "../../services/dialog.service";

@Component({
    selector: "maintenancecontrolplangroupaction",
    templateUrl: './mcp-group-action.component.html',
    styleUrls: ['../../style/container-tab-list.component.less']
})

export class MaintenanceControlPlanGroupActionVisualComponent implements OnInit {
    title: string = "Maintenance Control Program Group Actions";
    selected: MaintenanceControlPlanGroup = <MaintenanceControlPlanGroup>{};
    list: MaintenanceControlPlanGroupAction[] = [];
    //tenant: Country

    newMode: SaveService = <SaveService>{};

    constructor(
        private activatedRoute: ActivatedRoute
        , private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , private dialog: DialogService
    ) {
        //this.selected.MCPGroup
    }

    ngOnInit() {
        this.dialog.tthis = this;
        this.newMode = new SaveService();
        this.getAll();
    }

    getAll() {
        
        var id = +this.activatedRoute.snapshot.params["id"];
        if(id)
            Utils.httpGetMaintenanceControlPlanGroup(
                this.http
                , this.baseUrl
                , id
                , this
                , function (tthis: any, value: MaintenanceControlPlanGroup) {
                    tthis.selected = value;

                    Utils.httpGetAllMaintenanceControlPlanGroupActions(
                        tthis.http
                        , tthis.baseUrl
                        , value.MCPGroupID
                        , tthis
                        , function (tthis: any, list: MaintenanceControlPlanGroupAction[]) {
                            tthis.list = list;
                        }
                    );
                }
            );

    }

    onNew() {
        this.newMode.fireNew();
    }

    onResetList() {
        this.getAll();
    }

    onSearchList(obj: any) {
        ////{ ShowRef: boolean, Index: number, Value: string }
        var l = <MaintenanceControlPlanGroupAction[]>[];
        var value = obj.Value;
        for (var i = 0; i < this.list.length; ++i) {
            
            switch (obj.Index) {
                case 0:
                    if (this.list[i].ActionDescription)
                        if (this.list[i].ActionDescription.toLowerCase().indexOf(obj.Value.toLowerCase()) != -1)
                            l.push(this.list[i]);
                    break;
                default:
                    if (this.list[i].NUMBER_COMPONENTS)
                        if (this.list[i].NUMBER_COMPONENTS.toString().indexOf(obj.Value.toString()) != -1)
                            l.push(this.list[i]);
                    break;
            }
        }
        this.list = l;
    }
}