<div class='left-menu'>
    <ul class='nav navbar-nav'>
        <li>
            <translation-area (translationChanged)="onTranslationAreaChanged($event)"></translation-area>
        </li>
        <li class="separator"></li>
        <li class="submenu-title">
            <h4>Excel</h4>
        </li>
        <li [routerLinkActive]="['link-active']">
            <a class='menu-callback' target="_blank" (click)="outputDownload.emit()" title="Dowload">
                <span class='glyphicon glyphicon-download'></span> Download
            </a>
        </li>
        <li class="separator" *ngIf="!defaults.isReadonlyUser" [ngClass]="saveService.classSaveButton"></li>
        <li [routerLinkActive]="['link-active']" [ngClass]="saveService.classSaveButton" *ngIf="!defaults.isReadonlyUser">
            <a class='menu-callback' (click)="onSave()" title="Save all Business Keys">
                <span class='glyphicon glyphicon-floppy-disk'></span> Save All
            </a>
        </li>
    </ul>
</div>