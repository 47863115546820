import { Component, Inject, OnInit, ElementRef, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Utils } from "../../utils/utils";
import { DialogService } from "../../services/dialog.service";
import { DefaultValuesService } from "../../services/defaultvalues.service";
import { SaveService } from "../../services/save.service";

@Component({
    selector: "errorcode-new-detail",
    templateUrl: './errorcode-new-detail.component.html',
    styleUrls: ['../../style/form.component.less']
})

export class ErrorCodeNewDetailComponent implements OnInit {
    @Input() errorcode: ErrorCode = <ErrorCode>{};
    
    @Input() header: any[] = [];
    @Input() rowIndex: number = -1;
    @Input() newRow: boolean = true;


    MAINTENANCEFAILURE: number = 0;
    TROUBLESHOOTINGACTION: number = 1;
    WEIGHT: number = 2;
    LOCATION: number = 3; 
    COMPONENT: number = 4;
    CAUSE: number = 5;
    RESOLUTION: number = 6;
    PARTSREQUIRED: number = 7;
    HIGHVALUEPART: number = 8;

    title: string | undefined;
    selected: ErrorCodeResolution = <ErrorCodeResolution>{};
    list: ErrorCodeResolution[] = [];

    classname: String[] = [];
    isundodisabled: boolean[] = [];

    weights: number[] = [0.00, 0.25, 0.50, 0.75, 1.00, 2.00, 3.00];
    locations: ComponentSubSystem[] = [];
    components: ComponentItem[] = [];
    causes: BusinessKeySon[] = [];
    resolutions: BusinessKeySon[] = [];

    form!: FormGroup;
    //language: Language = <Language>{};
    //controllertype: ControllerType = <ControllerType>{};
    

    //classname: string | undefined;
    //classdialog: string = Utils.getDialogHideClassName();
    //type: number = Utils.getSuccessValue();
    //message: string | undefined;

    constructor(
        private activatedRoute: ActivatedRoute
        , private router: Router
        , private http: HttpClient
        , private fb: FormBuilder
        , @Inject('BASE_URL') private baseUrl: string
        , public defaults: DefaultValuesService
        , private dialog: DialogService
        , public saveService: SaveService
    ) {
    }

    ngOnInit() {
        this.dialog.tthis = this;
        this.saveService.addListener(this);

        this.list = this.errorcode.ErrorCodeResolutions;

        let tthis = this;
        this.header.forEach(
            function (value: any, index: number, array: any[]) {
                tthis.classname[index] = "";
                tthis.isundodisabled[index] = true;
            }
        );

        //if (this.newRow) {
        //    this.createFormToInsert();
        //}
        //else {
        //    this.errorcode.Description = this.list[this.rowIndex].Description;
        //    this.errorcode.ErrorWeight = this.list[this.rowIndex].ErrorWeight;
        //    this.errorcode.ControllerBlocked = this.list[this.rowIndex].ControllerBlocked;
        //    this.createForm();
        //}


        Utils.httpGetAllLocations(
            this.http
            , this.baseUrl
            , this
            , function (tthis: any, list: ComponentSubSystem[]) {
                tthis.locations = list;
                Utils.httpGetAllCauses(
                    tthis.http
                    , tthis.baseUrl
                    , tthis
                    , function (tthis: any, list: ComponentSubSystem[]) {
                        tthis.causes = list;
                        Utils.httpGetAllResolutions(
                            tthis.http
                            , tthis.baseUrl
                            , tthis
                            , function (tthis: any, list: ComponentSubSystem[]) {
                                tthis.resolutions = list;
                            }
                        );
                    }
                );
            }
        );

        this.title = "Create a new Possible Failure & Resolution";
        this.createForm();

        if (!this.newRow) {
            this.selected.ErrorCode = this.list[this.rowIndex].ErrorCode;
            this.selected.ErrorCodeID = this.list[this.rowIndex].ErrorCodeID;
            this.selected.MaintenanceFailure = this.list[this.rowIndex].MaintenanceFailure === null ? "" : this.list[this.rowIndex].MaintenanceFailure;
            this.selected.TroubleShootingAction = this.list[this.rowIndex].TroubleShootingAction === null ? "" : this.list[this.rowIndex].TroubleShootingAction;
            this.selected.TroubleShootingActionWeight = this.list[this.rowIndex].TroubleShootingActionWeight;
            this.selected.SubSystemGroup = this.list[this.rowIndex].SubSystemGroup;
            this.selected.SubSystemGroupID = this.list[this.rowIndex].SubSystemGroupID;
            this.selected.ComponentItem = this.list[this.rowIndex].ComponentItem;
            this.selected.ComponentItemID = this.list[this.rowIndex].ComponentItemID;
            this.selected.Cause = this.list[this.rowIndex].Cause;
            this.selected.CauseID = this.list[this.rowIndex].CauseID;
            this.selected.Resolution = this.list[this.rowIndex].Resolution;
            this.selected.ResolutionID = this.list[this.rowIndex].ResolutionID;
            this.selected.PartsRequired = this.list[this.rowIndex].PartsRequired;
            this.selected.HighValuePart = this.list[this.rowIndex].HighValuePart;
            this.updateForm();
        }


    }

    createForm() {
        this.form = this.fb.group({
            MaintenanceFailure: ['', Validators.required],
            TroubleshootingAction: ['', Validators.required],
            Weight: ['', Validators.required],
            Location: ['', Validators.required],
            Component: ['', Validators.required],
            Cause: ['', Validators.required],
            Resolution: ['', Validators.required],
            PartsRequired: [''],
            HighValuePart: [''],
        });
    }

    /**
     * called by save service
     * */
    onCancel(index: number) {
        if (this.form) {
        //    if (this.newRow || index == this.rowIndex)
        //        this.form.setValue({
        //            MaintenanceFailure: ""
        //            , TroubleshootingAction: ""
        //            , Weight: ""
        //            , Location: ""
        //            , Component: ""
        //            , Cause: ""
        //            , Resolution: ""
        //            , PartsRequired: ""
        //            , HighValuePart: ""
        //        });
            if (index == this.rowIndex && !this.newRow) {

                this.onMaintenanceFailureUndo();
                this.onTroubleShootingActionUndo();
                this.onPartsUndo();
                this.onHighValuePartUndo();
                this.onWeightUndo();
                this.onLocationUndo();
                this.onComponentUndo();
            }
        }
    }

    onCauseChange(select: any) {
        if (this.form && this.selected
            && this.form.value.Cause != this.selected.CauseID) {
            this.list[this.rowIndex].CauseID = this.form.value.Cause;
            this.setUndoDisable(this.CAUSE, false);
        }
        this.saveService.showSaveButton();
    }

    onCauseUndo() {
        this.list[this.rowIndex].CauseID = this.selected.CauseID;
        this.setUndoDisable(this.CAUSE, true);
        this.updateForm();
    }

    onComponentChange(select: any) {
        if (this.form && this.selected
            && this.form.value.Component != this.selected.ComponentItemID) {
            this.list[this.rowIndex].ComponentItemID = this.form.value.Component;
            this.setUndoDisable(this.COMPONENT, false);
        }
        this.saveService.showSaveButton();
    }

    onComponentUndo() {
        this.list[this.rowIndex].ComponentItemID = this.selected.ComponentItemID;
        this.setUndoDisable(this.COMPONENT, true);
        this.updateForm();
    }

    onHighValuePartChange(evt: any) {
        if (this.form && this.selected
            && this.form.value.HighValuePart != this.selected.HighValuePart) {
            this.list[this.rowIndex].HighValuePart = this.form.value.HighValuePart;
            this.setUndoDisable(this.HIGHVALUEPART, false);
        }
        this.saveService.showSaveButton();
    }

    onHighValuePartUndo() {
        this.list[this.rowIndex].HighValuePart = this.selected.HighValuePart;
        this.setUndoDisable(this.HIGHVALUEPART, true);
        this.updateForm();
    }

    onLocationChange(select: any) {
        if (!select.target) return;
        Utils.httpGetAllComponents(
            this.http
            , this.baseUrl
            , this
            , select.target.value
            , function (tthis: any, list: ComponentItem[]) {
                tthis.components = list;
            }
        );
        if (this.form && this.selected
            && this.form.value.Location != this.selected.SubSystemGroupID) {
            this.list[this.rowIndex].SubSystemGroupID = this.form.value.SubSystemGroupID;
            this.setUndoDisable(this.LOCATION, false);
        }
        this.saveService.showSaveButton();
    }

    onLocationUndo() {
        this.list[this.rowIndex].SubSystemGroupID = this.selected.SubSystemGroupID;
        this.setUndoDisable(this.LOCATION, true);
        this.updateForm();
    }

    onMaintenanceFailureChange(evt: any) {
        if (this.form && this.selected
            && this.form.value.MaintenanceFailure !== this.selected.MaintenanceFailure) {
            this.list[this.rowIndex].MaintenanceFailure = this.form.value.MaintenanceFailure;
            this.setUndoDisable(this.MAINTENANCEFAILURE, false);
        }
        this.saveService.showSaveButton();
    }

    onMaintenanceFailureUndo() {
        this.list[this.rowIndex].MaintenanceFailure = this.selected.MaintenanceFailure;
        this.setUndoDisable(this.MAINTENANCEFAILURE, true);
        this.updateForm();
    }

    onPartsChange(evt: any) {
        if (this.form && this.selected
            && this.form.value.PartsRequired !== this.selected.PartsRequired) {
            this.list[this.rowIndex].PartsRequired = this.form.value.PartsRequired;
            this.setUndoDisable(this.PARTSREQUIRED, false);
        }
        this.saveService.showSaveButton();
    }

    onPartsUndo() {
        this.list[this.rowIndex].PartsRequired = this.selected.PartsRequired;
        this.setUndoDisable(this.PARTSREQUIRED, true);
        this.updateForm();
    }

    onResolutionChange(evt: any) {
        if (this.form && this.selected
            && this.form.value.Resolution !== this.selected.ResolutionID) {
            this.list[this.rowIndex].ResolutionID = this.form.value.Resolution;
            this.setUndoDisable(this.RESOLUTION, false);
        }
        this.saveService.showSaveButton();
    }

    onResolutionUndo() {
        this.list[this.rowIndex].ResolutionID = this.selected.ResolutionID;
        this.setUndoDisable(this.RESOLUTION, true);
        this.updateForm();
    }

    onRowCancel() {
        this.saveService.fireCancel(this.rowIndex, this);
    }

    /**
     * called by save service
     * */
    onSave() {
        if (this.form && this.selected && this.form.valid) {

            this.selected.ErrorCode = this.errorcode.ErrorCode;
            this.selected.ErrorCodeID = this.errorcode.ErrorCodeID;
            this.selected.MaintenanceFailure = this.form.value.MaintenanceFailure;
            this.selected.TroubleShootingAction = this.form.value.TroubleshootingAction;
            this.selected.TroubleShootingActionWeight = this.form.value.Weight; 
            this.selected.SubSystemGroupID = this.form.value.Location; 
            this.selected.ComponentItemID = this.form.value.Component; 
            this.selected.CauseID = this.form.value.Cause;
            this.selected.ResolutionID = this.form.value.Resolution; 
            this.selected.PartsRequired = this.form.value.PartsRequired !== "" ? true : false;;
            this.selected.HighValuePart = this.form.value.HighValuePart !== "" ? true : false;;

            if (this.newRow)
                this.http
                    .put<ErrorCodeResolution>(
                        this.baseUrl + Utils.getErrorCodeAPI() + "addpossiblefailure"
                        , this.selected
                    )
                    .subscribe(
                        res => this.list.unshift(res)
                        , error => this.dialog.showErrorDialog(error)
                    );
            else
                this.http
                    .post<ErrorCodeResolution>(
                        this.baseUrl + Utils.getErrorCodeAPI() + "editpossiblefailure"
                        , this.list[this.rowIndex]
                    )
                    .subscribe(
                        res => this.list[this.rowIndex] = res
                        , error => {
                            Utils.httpGetPossibleFailure(
                                this.http
                                , this.baseUrl
                                , this.list[this.rowIndex].PossibleFailureID
                                , this
                                , function (tthis: ErrorCodeNewDetailComponent, value: ErrorCodeResolution) {
                                    tthis.list[tthis.rowIndex] = value;
                                }
                            );

                            this.dialog.showErrorDialog(error);
                        } 
                    );
        }
    }

    onTroubleShootingActionChange(evt: any) {
        if (this.form && this.selected
            && this.form.value.TroubleShootingAction !== this.selected.TroubleShootingAction) {
            this.list[this.rowIndex].TroubleShootingAction = this.form.value.TroubleshootingAction;
            this.setUndoDisable(this.TROUBLESHOOTINGACTION, false);
        }
        this.saveService.showSaveButton();
    }

    onTroubleShootingActionUndo() {
        this.list[this.rowIndex].TroubleShootingAction = this.selected.TroubleShootingAction;
        this.setUndoDisable(this.TROUBLESHOOTINGACTION, true);
        this.updateForm();
    }
    
    onWeightChange(evt: any) {
        if (this.form && this.selected.TroubleShootingActionWeight
            && this.form.value.Weight != this.selected.TroubleShootingActionWeight) {
            this.list[this.rowIndex].TroubleShootingActionWeight = this.form.value.Weight;
            this.setUndoDisable(this.WEIGHT, false);
        }
        this.saveService.showSaveButton();
    }

    onWeightUndo() {
        this.list[this.rowIndex].TroubleShootingActionWeight = this.selected.TroubleShootingActionWeight;
        this.setUndoDisable(this.WEIGHT, true);
        this.updateForm();
    }

    setUndoDisable(index: number, value: boolean) {
        this.classname[index] =  value ? "" : Utils.getEditingInputClassName();
        this.isundodisabled[index] = value;
    }

    updateForm() {
        if (this.form) {
            Utils.httpGetAllComponents(
                this.http
                , this.baseUrl
                , this
                , this.list[this.rowIndex].SubSystemGroupID
                , function (tthis: any, list: ComponentItem[]) {
                    tthis.components = list;
                }
            );

            var e = {
                MaintenanceFailure: this.list[this.rowIndex].MaintenanceFailure
                , TroubleshootingAction: this.list[this.rowIndex].TroubleShootingAction
                , Weight: this.list[this.rowIndex].TroubleShootingActionWeight
                , Location: this.list[this.rowIndex].SubSystemGroupID
                , Component: this.list[this.rowIndex].ComponentItemID
                , Cause: this.list[this.rowIndex].CauseID
                , Resolution: this.list[this.rowIndex].ResolutionID
                , PartsRequired: this.list[this.rowIndex].PartsRequired
                , HighValuePart: this.list[this.rowIndex].HighValuePart
            };
            this.form.setValue(e);
        }
    }
}