import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import "rxjs/operators";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogService } from '../../../../../services/dialog.service';
import { DefaultValuesService } from '../../../../../services/defaultvalues.service';
import { Utils } from '../../../../../utils/utils';
import { SpinnerOverlayService } from '../../../../../services/spinner-overlay.service';


@Component({
    selector: 'createdaworkspace',
    templateUrl: './createdaworkspace.component.html',
    styleUrls: ['../../../../../style/custom-mat-dialog.component.less',]
})


export class CreateDaworkspaceComponent implements OnInit {

    capacities: PowerBiCapacity[] = [];
    parentnodes: SelectList[] = [];
    referenceData: PowerBiReferenceData | undefined;
    upperNodeCandidatePrefixes: string[] = []
    workspaceCandidatePrefixes: string[] = []

    public form: FormGroup;
    submitted = false;
    errorMessage = '';
    futureWorkspaceName = '';

    constructor(private http: HttpClient
        , private confimDialog: DialogService
        , @Inject(MAT_DIALOG_DATA) public data: any
        , @Inject('BASE_URL') private baseUrl: string
        , public dialogRef: MatDialogRef<CreateDaworkspaceComponent>
        , private formBuilder: FormBuilder
        , private defaults: DefaultValuesService
        , private spinnerOverlayService: SpinnerOverlayService


    ) {

        this.confimDialog.tthis = this;

        this.form = this.formBuilder.group({
            name: ['', [Validators.required]],
            capacity: ['', [Validators.required]],
            uppernode: ['', [Validators.required]]
        });
    }

    ngOnInit(): void {
        
        Utils.httpGetPowerbiCapacities(
            this.http
            , this.baseUrl
            , this
            , function (tthis: CreateDaworkspaceComponent, result: PowerBiCapacity[]) {
                tthis.capacities = result
            }
        );
        Utils.httpGetPowerbiReferenceData(
            this.http
            , this.baseUrl
            , this
            , function (tthis: CreateDaworkspaceComponent, result: PowerBiReferenceData) {
                tthis.referenceData = result

                // Filtering data analytics nodes based on reference data
                // Cross join GF and BU
                tthis.upperNodeCandidatePrefixes = tthis.getUpperNodeCandidates(tthis.referenceData.groupFunction, tthis.referenceData.businessUnit);
                tthis.workspaceCandidatePrefixes = tthis.getWorkspaceCandidatePrefixes(tthis.referenceData.businessUnit,
                    tthis.defaults.umpFilters.tenants.map(x => x.value),
                    tthis.referenceData.groupFunction,);
                var tempUpperNodes: SelectList[] = []
                tthis.defaults.umpFilters.cdpList.forEach(x => {
                    if (tthis.ValidUpperNode(x.text)) {
                        tempUpperNodes.push({
                            text: x.text,
                            value: x.value
                        } as SelectList)
                    }
                })
                tthis.parentnodes = tempUpperNodes;
            }
        );
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        Utils.httpPostPowerbiWorkspace(
            this.http
            , this.baseUrl
            , {
                ...this.form.value, 'name': this.futureWorkspaceName
            }
            , this
            , function (tthis: CreateDaworkspaceComponent, result: boolean) {
                if (result) {
                    tthis.confimDialog.showSuccessDialog(`Workspace ${tthis.futureWorkspaceName} created successfully. Please, wait up to a maximum of 20 minutes to see new groups in the tree.`)
                }
                else {
                    tthis.confimDialog.showErrorDialog({ 'error': { 'message': 'Error creating DataAnalytics workspace. Please, contact administrator.' } })
                }
                tthis.dialogRef.close({ event: 'submit' });
            },
            function (tthis: any) {
                tthis.confimDialog.showErrorDialog({ 'error': { 'message': 'Error creating DataAnalytics workspace. Please, contact administrator.' } })
                tthis.dialogRef.close({ event: 'submit' });
            }
        );
    }

    onBack() { }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onWorkspaceChange() {
        if (this.defaults.umpFilters.cdpList.filter(x => x.text.toUpperCase() === this.form.get('name')?.value.toUpperCase()).length > 0) {
            this.errorMessage = `Workspace ${this.form.get('name')?.value.toUpperCase()} already exists.`
            return;
        }
        this.GetFutureWorkspaceNode()
        this.errorMessage = ''
    }

    private getUpperNodeCandidates(array1: string[], array2: string[]): string[] {
        const result: string[] = [];

        for (const item1 of array1) {
            for (const item2 of array2) {
                result.push(`${item1}_${item2}`);
            }
        }
        return result;
    }

    private getWorkspaceCandidatePrefixes(array1: string[], array2: string[], array3: string[]): string[] {
        const result: string[] = [];

        for (const item1 of array1) {
            for (const item2 of array2) {
                for (const item3 of array3) {
                    result.push(`${item1}_${item2}_${item3}`);
                }
            }
        }
        return result;
    }

    private ValidUpperNode(upperNode: string) {
        return this.upperNodeCandidatePrefixes.some((object) => upperNode.toUpperCase().startsWith(object.toUpperCase())
            && upperNode.toUpperCase().indexOf('ROLE_') < 0) || upperNode.toUpperCase().indexOf('DATAANALYTICS') > -1
    }

    public GetFutureWorkspaceNode() {
        if (!this.form.get('name')?.value) {
            this.futureWorkspaceName = ''
            return;
        }
        var upperNodeTokens = (this.form.get('uppernode')?.value as string).split('_');
        if (upperNodeTokens.length === 2) {
            this.futureWorkspaceName = `${upperNodeTokens[1]}_${upperNodeTokens[0]} ${this.form.get('name')?.value}`
        }
        else if (upperNodeTokens.length === 3) {
            this.futureWorkspaceName = `${upperNodeTokens[1]}_${upperNodeTokens[2]}_${upperNodeTokens[0]} ${this.form.get('name')?.value}`
        }
    }
}


