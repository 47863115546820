import { Inject } from "@angular/core";
import { Component, Injectable, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: 'dialog-confirmation',
    templateUrl: "./dialog-confirmation.component.html",
    styleUrls: ["./dialog-confirmation.component.less"]
})
export class ConfirmationDialogComponent {
    static AlertDialogComponent: any[] | any;
    constructor(
        public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    onYes() {
        // Note:
        // Now for this feature, we only need to get whether user clicked Yes or No,
        // But later we can pass data and do some modification and then pass back
        // to the component that called the dialog.
        this.dialogRef.close({ caller: this.data.caller, confirmed: true });
    }

    onNo() {
        this.dialogRef.close({ caller: this.data.caller, confirmed: false });
    }
}