
<ng-container *ngIf="showLocalFilter">
    <mat-form-field class="search-input">
        <input placeholder="Filter"
               matInput
               [matChipInputFor]="chipList"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
               [matChipInputAddOnBlur]="addOnBlur"
               (matChipInputTokenEnd)="applyFilter($event)">
        <mat-chip-list #chipList>
        </mat-chip-list>
    </mat-form-field>
</ng-container>
<mat-chip *ngFor="let item of searchItems" [selectable]="selectable"
          [removable]="removable" (removed)="remove(item)">
    {{item.name}}
    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
</mat-chip>
<div class="example-container">
    <table mat-table
           [dataSource]="dataSource"
           multiTemplateDataRows
           matSort
           class="mat-elevation-z8 mat-table-custom" id="contractlist">
        <ng-container *ngFor="let item of columnsToDisplay" matColumnDef="{{item}}">
            <th mat-header-cell *matHeaderCellDef>
                {{item === 'actions' || item === 'status' || item === 'index' || item === 'arrow' ? '' : item}}
                <ng-container *ngIf="item === 'IsPrimaryContract'">
                    <mat-select class='form-control filter' placeholder='Select' [(ngModel)]="isPrimaryContract"
                                (selectionChange)="filterCheckbox($event, 'isprimarycontract')" *ngIf="showLocalFilter">
                        <mat-option [value]="undefined">Select</mat-option>
                        <mat-option value="1">True</mat-option>
                        <mat-option value="0">False</mat-option>
                    </mat-select>
                </ng-container>
            </th>

            <ng-container matColumnDef="index-table" *ngIf="item === 'index'">
                <td mat-cell *matCellDef="let element; let i = dataIndex">
                    <p>{{i+1}}</p>
                </td>
            </ng-container>

            <ng-container *ngIf="item==='arrow'">
                <td mat-cell *matCellDef="let element"
                    (click)="pushPopElement(element)">
                    <span class="material-icons" *ngIf="checkExpanded(element); else show_expand_more" matTooltip="Collapse">
                        expand_less
                    </span>
                </td>
            </ng-container>
            <ng-container *ngIf="item==='actions'">
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button *ngIf="!isEditingRow(element,item)" matTooltip="Edit Row"
                            (click)="onRowEditInit(element)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button *ngIf="isEditingRow(element,item)" matTooltip="Cancel Row"
                            (click)="onRowEditCancel(element)">
                        <mat-icon>rotate_left</mat-icon>
                    </button>
                </td>
            </ng-container>
            <ng-container *ngIf="item === 'status'">
                <td mat-cell *matCellDef="let element" class="status-class">
                    <button mat-icon-button>
                        <mat-icon *ngIf="!isActive(element)" matTooltipClass='contracts-custom-tooltip' matTooltip="Start Date: {{element.ContractStartDate | date:'yyyy-MM-dd HH:mm'}}
                                                                End Date: {{element.ContractEndDate | date:'yyyy-MM-dd HH:mm'}}
                                                                Cancellation Date: {{element.ContractCancelationDate | date:'yyyy-MM-dd HH:mm'}}">cancel</mat-icon>
                    </button>
                    <button mat-icon-button>
                        <mat-icon *ngIf="isDeleted(element)" matTooltipClass='contracts-custom-tooltip' matTooltip="Deleted">delete_forever</mat-icon>
                    </button>
                </td>
            </ng-container>
            <ng-container *ngIf="item!=='actions'">
                <ng-container *matCellDef="let element" [ngSwitch]="getDataType(item)">
                    <td mat-cell *ngSwitchCase="'boolean'">
                        <mat-checkbox *ngSwitchCase="'boolean'" (change)="changeElement(element, item, $event.checked)" [checked]="getInput(element,item)" [ngClass]="isEditingRow(element,item) ? 'black' :''" [disabled]="!isEditingRow(element,item)"></mat-checkbox>
                    </td>
                    <td mat-cell *ngSwitchCase="'string'">
                        <p *ngIf="!isEditingRow(element,item)" [innerHTML]="getInput(element,item)"></p>
                        <mat-form-field floatLabel="never" *ngIf="isEditingRow(element,item)">
                            <input matInput [value]="element[item]" [(ngModel)]="element[item]">
                        </mat-form-field>
                    </td>
                    <td mat-cell *ngSwitchCase="'number'">
                        <p *ngIf="!isEditingRow(element,item)" [innerHTML]="getInput(element,item)"></p>
                        <mat-form-field floatLabel="never" *ngIf="isEditingRow(element,item)">
                            <input matInput type="number" [value]="element[item]" [(ngModel)]="element[item]">
                        </mat-form-field>
                    </td>
                    <td mat-cell *ngSwitchCase="'date'" class="datepicker">
                        <p *ngIf="!isEditingRow(element,item)" [innerHTML]="getInput(element,item) | date:'yyyy-MM-dd HH:mm'"></p>
                        <mat-form-field *ngIf="isEditingRow(element,item)">
                            <input matInput [matDatepicker]="picker" [(ngModel)]="element[item]">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </td>
                </ng-container>
            </ng-container>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                <div [@detailExpand]="checkExpanded(element) ? 'expanded' : 'collapsed'" *ngIf="element.Details"
                     [class.expandedDetail]="checkExpanded(element)">
                    <table mat-table [dataSource]="element.Details" class="mat-subtable-custom" id="contractdetails">
                        <ng-container *ngFor="let item of subcolumnsToDisplay" matColumnDef="{{item}}">
                            <th mat-header-cell *matHeaderCellDef>
                                {{item}}
                            </th>
                            <ng-container *matCellDef="let subelement" [ngSwitch]="getDataType(item)">
                                <td mat-cell *ngSwitchCase="'boolean'">
                                    <mat-checkbox *ngSwitchCase="'boolean'" (change)="changeElement(element, item, $event.checked)" [checked]="getInput(subelement,item)" [ngClass]="isEditingRow(element,item) ? 'black' :''" [disabled]="!isEditingRow(element,item)"></mat-checkbox>
                                </td>
                                <td mat-cell *ngSwitchCase="'string'">
                                    <p *ngIf="!isEditingRow(element, item)" [innerHTML]="getInput(subelement,item)"></p>
                                    <mat-form-field floatLabel="never" *ngIf="isEditingRow(element,item)">
                                        <input matInput [value]="subelement[item]" [(ngModel)]="subelement[item]">
                                    </mat-form-field>
                                </td>
                                <td mat-cell *ngSwitchCase="'number'">
                                    <p *ngIf="!isEditingRow(element,item)" [innerHTML]="getInput(subelement,item)"></p>
                                    <mat-form-field floatLabel="never" *ngIf="isEditingRow(element,item)">
                                        <input matInput type="number" step="0.01" min="0" [value]="subelement[item]" [(ngModel)]="subelement[item]">
                                    </mat-form-field>
                                </td>
                                <td mat-cell *ngSwitchCase="'date'" class="datepicker">
                                    <p *ngIf="!isEditingRow(element,item)" [innerHTML]="getInput(subelement,item) | date:'yyyy-MM-dd HH:mm'"></p>
                                    <mat-form-field *ngIf="isEditingRow(element,item)">
                                        <input matInput [matDatepicker]="picker" [(ngModel)]="subelement[item]">
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </td>
                            </ng-container>
                        </ng-container>

                        <tr mat-header-row class="subtableheader" *matHeaderRowDef="subcolumnsToDisplay"></tr>
                        <tr mat-row *matRowDef="let i2 = index; columns: subcolumnsToDisplay;"
                            [class.alternative2]="isEditingRow(element,'')"
                            class="subitem"></tr>
                    </table>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row class="tableheader" *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; let i = dataIndex; columns: columnsToDisplay;"
            class="item"
            [class.alternative2]="isEditingRow(element,'')">
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="row-subitem"></tr>
    </table>
</div>


<ng-template #show_expand_more>
    <span class="material-icons" matTooltip="More Details">
        expand_more
    </span>
</ng-template>
