import { AbstractControl, ValidationErrors, FormGroup } from "@angular/forms"


export const PasswordStrengthValidator = function (control: AbstractControl): ValidationErrors | null {

    let value: string = control.value || '';

    if (!value) {
        return null
    }

    let upperCaseCharacters = /[A-Z]+/g
    if (upperCaseCharacters.test(value) === false) {
        return { passwordStrength: `Upper case required` };
    }

    let lowerCaseCharacters = /[a-z]+/g
    if (lowerCaseCharacters.test(value) === false) {
        return { passwordStrength: `lower case required` };
    }


    let numberCharacters = /[0-9]+/g
    if (numberCharacters.test(value) === false) {
        return { passwordStrength: `number required` };
    }

    let specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/
    if (specialCharacters.test(value) === false) {
        return { passwordStrength: `Special char required` };
    }
    if (value.length < 8) {
        return {
            passwordStrength: `Use at least 8 characters` };

    }
    return null;
}

export function ConfirmPasswordValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        let control = formGroup.controls[controlName];
        let matchingControl = formGroup.controls[matchingControlName]
        if (
            matchingControl.errors &&
            !matchingControl.errors['confirmPasswordValidator']
        ) {
            return;
        }
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ confirmPasswordValidator: true });
        } else {
            matchingControl.setErrors(null);
        }
    };
}

// Define the custom validator
export function CheckContainedPassword(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        let control = formGroup.controls[controlName];
        let matchingControl = formGroup.controls[matchingControlName]
        if (
            control.errors &&
            !control.errors['passwordContained']
        ) {
            return;
        }
        if (matchingControl.value?.toUpperCase().match(control.value?.toUpperCase())) {
            control.setErrors({ passwordContained: true });
        } else {
            control.setErrors(null);
        }
    };
}


export const isAlphanumeric= function (control: AbstractControl): ValidationErrors | null {
    // Get the value from the control
    const value = control.value;

    // Define a regular expression pattern for alphanumeric characters
    const alphanumericPattern = /^[a-zA-Z0-9]+$/;

    // Test the value against the pattern
    if (!alphanumericPattern.test(value)) {
        // Return an error object if the value is not alphanumeric
        return {
            alphanumericError: `Username should contain alphanumerics only`
        };
    }

    // Return null if the value is alphanumeric (no errors)
    return null;
}

export function GeneratePassword(): string {
    const a_z = "abcdefghijklmnopqrstuvwxyz".split("");
    const A_Z = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
    const digits = "0123456789".split("");
    const specialChars = "!@#$%^&*()_+-=[]{}|;:,.<>?".split("");
    const passwordChars = a_z.concat(A_Z, digits, specialChars);

    let password = "";

    for (let i = 0; i < 2; i++) {
        password += A_Z[Math.floor(Math.random() * A_Z.length)];
        password += a_z[Math.floor(Math.random() * a_z.length)];
        password += digits[Math.floor(Math.random() * digits.length)];
        password += specialChars[Math.floor(Math.random() * specialChars.length)];
    }

    for (let i = 0; i < 2; i++) {
        password += passwordChars[Math.floor(Math.random() * passwordChars.length)];
    }

    password = password.split("").sort(() => Math.random() - 0.5).join("");
    return password;
}