<table-fixed-header-search-collapse 
    *ngIf="selected.ErrorCodeResolutions"
    id-table="error-code-resolution-properties-id-table"
    [data]="selected.ErrorCodeResolutions"
    [getValue]="getData"
    [getClass]="notInView"

    [rowNewTemplate]="newErrorcodeDetailProp"
    [newService]="newService"

    (onDelete)="onDelete($event)"

    >
</table-fixed-header-search-collapse>

<!--<ng-template #editErrorcodeDetailProp let-index="index">
    <errorcode-new-detail [header]="header" [errorcode]="selected" [rowIndex]="index" [newRow]="false"></errorcode-new-detail>
</ng-template>-->

<ng-template #newErrorcodeDetailProp let-index="index">
    <errorcode-new-detail [header]="header" [errorcode]="selected" [rowIndex]="index"></errorcode-new-detail>
</ng-template>