import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import "rxjs/operators";
import { Data, Router } from '@angular/router';
import { DialogService } from '../../services/dialog.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Utils } from '../../utils/utils';
import { DefaultValuesService } from '../../services/defaultvalues.service';
import * as moment from 'moment';
import { DatePipe } from '@angular/common'

@Component({
    selector: "fp_rule_definition-form",
    templateUrl: './fp_rule_definition-form.component.html',
    styleUrls: ['../../style/azdatafactory.component.less', '../../style/custom-mat-dialog.component.less']

})

export class FPRuleDefinitionFormComponent implements OnInit {


    selected = {} as ControllerTypeByTenant;

    operationModes: any[] = [];
    features: any[] = [];
    trafficpattern: any[] = [];

    buildingTypes: any;

    populationsetupid: number;
    triggerconditionid: number;
    body: any;
    title: string;
    action: string;
    submitted = false;
    form: FormGroup = new FormGroup({});

    constructor(private http: HttpClient
        , private confimDialog: DialogService
        , private router: Router
        , @Inject(MAT_DIALOG_DATA) public data: any
        , @Inject('BASE_URL') private baseUrl: string
        , public dialogRef: MatDialogRef<FPRuleDefinitionFormComponent>
        , private fb: FormBuilder
        , private defaults: DefaultValuesService
        , private formBuilder: FormBuilder
        , private datepipe: DatePipe) {

        this.confimDialog.tthis = this;
        this.title = "";
        this.populationsetupid = -1;
        this.triggerconditionid = -1;
        this.action = "";
    }

    ngOnInit(): void {

        console.log(this.data);

        //this.body = {
        //    SessionId: ''
        //}

        const tthis = this;
        //Utils.httpGetAllControllerTypesAvailable(
        //    this.http
        //    , this.baseUrl
        //    , this
        //    , function (tthis: any, list: ControllerType[]) {
        //        tthis.controllerTypes = list;
        //    }
        //);

        let url = this.baseUrl + Utils.getAPIFingerPrint() + 'errorcodes';
        this.http.get<ErrorCode[]>(url).subscribe(result => {
            var p = result;
            
        }); //console.error(error));

        Utils.httpFPModesAvailable(
            this.http
            , this.baseUrl
            , this
            , function (tthis: any, values: any) {
                tthis.operationModes = values;

            }
        );

        Utils.httpGetAllBusinessKeys(
            this.http
            , this.baseUrl
            , 3 /*BuildingType*/
            ,this
            , function (tthis: any, values: any) {
                tthis.buildingTypes = values.filter(obj => {
                    return obj.LanguageCultureName = "en-US";
                });
            }
        )

        url = this.baseUrl + Utils.getAPIFingerPrint() + "fp_params/TrafficPattern";
        this.http
            .get<any[]>(url, {})
            .subscribe(res => {
                tthis.trafficpattern = res;
            }, error => {
                Utils.writeLog(this.http, this.baseUrl, error); //Utils.showErrorDialog(null, error);
            }); //console.error(error));


        url = this.baseUrl + Utils.getAPIFingerPrint() + "fp_params/Feature";
        this.http
            .get<any[]>(url, {})
            .subscribe(res => {
                tthis.features = res;
            }, error => {
                Utils.writeLog(this.http, this.baseUrl, error); //Utils.showErrorDialog(null, error);
            }); //console.error(error));




        this.form = this.formBuilder.group({
            OperationMode: "",
            BuildingType: "",
            TrafficPattern: "",
            IsWarning: "false",
            Feature:"",
            ThresholdType:"",
            ThresholdValue: "",
            Environment:""
        });

        this.action = this.data.action;
        this.triggerconditionid = this.data.triggerconditionid;
        this.populationsetupid = this.data.populationsetupid;


        if (this.action === "OM")
        {
            //FP OM
            if (typeof this.data.element.ErrorControllerId !== 'undefined') {
                //this.body = {
                //    OperationMode: this.data.element.ErrorControllerId,
                //    BuildingType: this.data.element.BuildingType,
                //}

                this.form = this.formBuilder.group({
                    OperationMode: [this.data.element.ErrorControllerId.toString()],
                    BuildingType: [this.data.element.BuildingType],
                    TrafficPattern: [this.data.element.TrafficPattern],
                    IsWarning: [this.data.element.IsWarning]
                });

            }
        }
        else
        {
            //FP Feature condition
            if (this.triggerconditionid !== -1) {
                //this.body = {
                //    OperationMode: this.data.element.ErrorControllerId,
                //    BuildingType: this.data.element.BuildingType,
                //}

                this.form = this.formBuilder.group({
                    Feature: [this.data.element2.FingerprintFeature.toString()],
                    ThresholdType: [this.data.element2.ThresholdType],
                    ThresholdValue: [this.data.element2.ThresholdValue],
                    EnvironmentType: [this.data.element2.EnvironmentType]
                });

            }
        }

    }


    // convenience getter for easy access to form fields
    get f() {
        return this.form.controls;
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    onSubmit() {
        this.submitted = true;
        //alert(this.data._OperationModeMappingID);

        if (this.action == "OM")
        {
            let url = this.baseUrl + Utils.getAPIFingerPrint() + "fp_mergepopulationsetup/";

	        //ErrorControllerId
	        //TenantId			

            this.http
                .post<boolean>(url, {
                    _PopulationSetupId: this.populationsetupid,
                    ErrorControllerId   : this.form.value.OperationMode,
	                BuildingType	    : this.form.value.BuildingType,
	                TrafficPattern		: this.form.value.TrafficPattern,
	                IsWarning			: this.form.value.IsWarning
                })
                .subscribe(res => {
                    this.dialogRef.close(true);
                }, error => {
                    Utils.writeLog(this.http, this.baseUrl, error); //Utils.showErrorDialog(null, error);
                }); //console.error(error));
        }
        else
        {
            let url = this.baseUrl + Utils.getAPIFingerPrint() + "fp_mergetriggercondition/";

            this.http
                .post<boolean>(url, {
                    _TriggerConditionId: this.triggerconditionid,
                    PopulationSetupId: this.populationsetupid,
                    FingerprintFeature: this.form.value.Feature,
                    ThresholdType: this.form.value.ThresholdType,
                    ThresholdValue: this.form.value.ThresholdValue,
                    EnvironmentType: this.form.value.EnvironmentType
                })
                .subscribe(res => {
                    this.dialogRef.close(true);
                }, error => {
                    Utils.writeLog(this.http, this.baseUrl, error); //Utils.showErrorDialog(null, error);
                }); //console.error(error));
        }


    }

    onBack() {

    }



}


