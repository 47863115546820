import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DefaultValuesService } from "../../services/defaultvalues.service";
import { MatDialog } from "@angular/material/dialog";
import { CreateAssetComponent } from "./createasset/createasset.component";
import { Utils } from "../../utils/utils";

@Component({
    selector: "virtualprovisioning-list",
    templateUrl: './virtualprovisioning-list.component.html',
    styleUrls: ['./virtualprovisioning-list.component.less', '../../style/mat-table.component.less'],

    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' })),
            transition('expanded => collapsed', [animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
        ]),
    ]
})

export class VirtualProvisioningListComponent implements OnInit {
    @Input('unitlist') unitlist: UnitIdToDeviceMapping[] = [];
    @Output() filter = new EventEmitter<{}>();
    @Output() selectedUnitId = new EventEmitter<{}>();
    @Output() unitListReload = new EventEmitter<{}>();

    columnsToDisplay = ['UnitId', 'DeviceId', 'AssetType', 'isMAXCompatible', 'ControllerNumber', 'actions'];
    booleanColumns = ['isMAXCompatible']

    @ViewChild(MatTable, { static: true }) table!: MatTable<any>;

    public dataSource = new MatTableDataSource<UnitIdToDeviceMapping>();

    timeoutFunction: any;

    subscriptionLanguage: any;
    subscriptionTenant: any;

    constructor(private activatedRoute: ActivatedRoute
        , private router: Router
        , private http: HttpClient
        , public defaults: DefaultValuesService
        , @Inject('BASE_URL') private baseUrl: string
        , private dialogForm: MatDialog

    ) {
    }

    ngOnChanges() {
        this.dataSource.data = this.unitlist
    }

    ngOnInit() {
    }

    getInput(data: UnitIdToDeviceMapping, property: string): any {
        return data[property];
    }

    getDataType(property: string) {
        let result = 'string'
        if (this.booleanColumns.indexOf(property) > -1) result = 'boolean'
        return result
    }

    changeElement(object: any, type: string, value: boolean) {
        object[type] = value;
        this.table.renderRows();
    }

    applyFilter($event: any) {
        if (this.timeoutFunction) {
            clearTimeout(this.timeoutFunction);
            this.timeoutFunction = null;
        }
        this.timeoutFunction = setTimeout(() => {
            this.filter.emit($event.target.value.trim())
        }, 300)
    }

    reloadUnitList() {
        this.unitListReload.emit()
    }

    onSelectUnitId(element: UnitIdToDeviceMapping) {
        this.selectedUnitId.emit(element.UnitId)
    }

    openCreateAssetPopup() {
        const dialog = this.dialogForm.open(CreateAssetComponent, {
            panelClass: ['custom-mat-dialog'],
            disableClose: true,
            data: {},
        })
        dialog.afterClosed().subscribe(() => {
            this.unitListReload.emit();
        })
    }
}