<div class="custom-mat-dialog-form">
    <div>
        <h3>Preregister new MacAddress</h3>
    </div>
    <div>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="mt-2">
                <label>MacAddress [00:00:00:00:00:00 - 00:00:00:11:00:00] *</label>
                <div class="input">
                    <input [(ngModel)]="body.macaddress" class="form-control" formControlName="macaddress"
                           required [ngClass]="{ 'is-invalid': submitted && f['macaddress']?.errors }" />
                    <div *ngIf="submitted && f['macaddress']?.errors" class="invalid-feedback">
                        <div class="error-div">Incorrect MacAddress</div>
                    </div>
                </div>
            </div>
            <div class="mt-2">
                <label>Dial Up Number</label>
                <div class="input">
                    <input [(ngModel)]="body.dialupnumber" class="form-control" formControlName="dialupnumber" />
                </div>
            </div>
            <div class="mt-2">
                <label>Board Serial Number</label>
                <div class="input">
                    <input [(ngModel)]="body.boardserialnumber" class="form-control" formControlName="boardserialnumber" />
                </div>
            </div>
            <div class="mt-2">
                <label>ICCID</label>
                <div class="input">
                    <input [(ngModel)]="body.iccid" class="form-control" formControlName="iccid" />
                </div>
            </div>

            <div class="mt-2 right">
                <button type="button"
                        class="btn btn-danger"
                        (click)="onNoClick()">
                    Close
                </button>
                <button type="button"
                        class="btn btn-primary"
                        (click)="onSubmit()">
                    Send
                </button>
            </div>
        </form>
    </div>
</div>
