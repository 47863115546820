import { Component, Inject, Input, OnInit, Output, EventEmitter, TemplateRef, OnChanges, OnDestroy, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Utils } from "../../utils/utils";
import { DefaultValuesService } from "../../services/defaultvalues.service";
import { DialogService } from "../../services/dialog.service";
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
// import { MatTableModule } from '@angular/material/table';

import { animate, state, style, transition, trigger } from '@angular/animations';
import { ConfirmationDialogComponent } from "../dialogbox/dialog-confirmation.component";
import { CustomDialogService } from "../../services/customdialog.service";
import { MatTable } from "@angular/material/table";
import { DataTransferService } from "../../services/data-transfer.service";

@Component({
    selector: "errorcode-list-mlsuggestions-undo",
    templateUrl: "./errorcode-list-mlsuggestions-undo.component.html",
    // We use the same style file as ml suggestions.
    styleUrls: ["./errorcode-list-mlsuggestions.component.less"],
})
export class ErrorCodeListMLSuggestionsUndoHistoryComponent implements OnInit, OnDestroy {
    @Input('list') list: PossibleFailureMLUndoHistory[] = [];

    columnsToDisplay = ['errorcode', 'faultdescription', 'maintenancefailure', 'troubleshootingaction', 'troubleshootingactionweight', 'previousweight', 'isnew', 'changedate', 'actions'];

    subscriptionControllerType: any;

    confirmationDialogRef!: MatDialogRef<ConfirmationDialogComponent>;

    @ViewChild(MatTable, { static: true }) table!: MatTable<any>;
    /*@ViewChild(MatTable) table2!: MatTable<PossibleFailureML[]>;*/

    constructor(
        private http: HttpClient
        , private router: Router
        , @Inject('BASE_URL') private baseUrl: string
        , public defaults: DefaultValuesService
        , private customDialog: CustomDialogService
        , private dataTransferService: DataTransferService
        // , private filtersservice: FilterService
    ) { }

    ngOnInit() {
        // this.customDialog.tthis = this;
        /*console.log('ngInitCalled.');*/
        this.subscriptionControllerType = this.defaults.controllerTypeMessageChanges$.subscribe(async (ct: ControllerType) => { await this.refreshPossibleFailureMLUndoHistories() });
    }

    ngOnDestroy() {
        /*console.log('ngDestroyCalled.');*/
        this.subscriptionControllerType.unsubscribe();
    }


    async refreshPossibleFailureMLUndoHistories() {
        await Utils.httpGetPossibleFailureMLUndoHistories(
            this.http
            , this.baseUrl
            , this
            , function (tthis: any, list: PossibleFailureMLUndoHistory[]) {
                tthis.list = list;
            }
        );
    }

    performUndo(element: PossibleFailureMLUndoHistory) {
        // console.log(element);

        this.customDialog.openConfirm({
            title: "Undo Possible Failure ML",
            message: `Are you sure you want to undo the suggested possible failures for this error code: ${element.FaultDescription}(${element.ErrorCode})?`,
            caller: this
        });

        this.customDialog.confirmed().subscribe(res => {
            if (res.confirmed) {
                // Call API to ignore all the possible failure ml for this errorcode.
                const url = this.baseUrl + Utils.getErrorCodeAPI() + "undopossiblefailureml";
                this.http.post(url, element.PossibleFailureMLID).subscribe(result => {
                    // UI manipulation
                    let foundIndex = res.caller.list.findIndex((x: { PossibleFailureMLID: number; }) => x.PossibleFailureMLID === element.PossibleFailureMLID);
                    if (foundIndex > -1) {
                        res.caller.list.splice(foundIndex, 1);
                    }

                    res.caller.table.renderRows();
                    this.dataTransferService.sendConsolidatedPFMLMessage(element);
                }, error => { this.customDialog.openDisplayInfoDialog({ title: 'Error', message: error.error.message }); });
            }
            //else {
            //    // Test purpose
            //    this.dataTransferService.sendConsolidatedPFMLMessage(element);
            //}
        });

    }
}



