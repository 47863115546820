import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, OnInit, Self, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Utils } from '../../../utils/utils';
import { ToastrService } from 'ngx-toastr';
import { DefaultValuesService } from '../../../services/defaultvalues.service';
import { DestroyService } from '../../../services/destroyservice';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'tdcbomcodemapping',
  templateUrl: './tdcbomcodemapping.component.html',
    styleUrls: ['../../../style/new-generic-styles.component.less',
        '../../../style/tables/table-add-butt-header-orange.less',
        './tdcbomcodemapping.component.less', '../tdccomponents.component.less'],
    providers: [DestroyService]
})
export class TDCBOMCodeMapping implements OnInit {
    public dataSource = new MatTableDataSource<any>();
    BCMForm!: FormGroup;
    genType: number = 0;
    @ViewChild(MatTable) objTable!: MatTable<any>;

    originalBomCodes: BomCodeMappingItem[] = [];
    modifiedBomCodes: BomCodeMappingItem[] = [];
    newBomCodes: BomCodeMappingItem[] = [];
    isCallSucceed: number = 0;
    subscriptionStage: any;
    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string,
        public datePipe: DatePipe,
        private _formBuilder: FormBuilder,
        private fb: FormBuilder,
        private toastrService: ToastrService,
        public defaults: DefaultValuesService,
        @Self() private readonly destroy$: DestroyService) { }

    availableCenters: MfgCenter[] = [];
    @Input() availableFamilyCodes: FamilyCode[] = [];
    
    columnsToDisplay = ["bomcode", "familycode", "center", "date", "user", "action"];

    getCenterName(centerId: number) {
        return this.availableCenters.find(x => x.centerId === centerId)?.centerName ?? "---";
    }

    getFamilyCodeName(familyCodeId: number) {
        return this.availableFamilyCodes.find(x => x.familyCodeId === familyCodeId)?.familyCodeName ?? "---";
    }

    ngOnInit(): void {
        this.subscriptionStage = this.defaults.stageMessageChanges$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.getCenters();
            this.getBomCodes();
        });
        this.genType = 0;
        this.BCMForm = this._formBuilder.group({
            BCMRows: this._formBuilder.array([])
        });
        this.getCenters();
        this.getBomCodes();
    }

    getCenters() {
        Utils.httpGetMfgcenters(this.http,
            this.baseUrl,
            this,
            function (tthis: TDCBOMCodeMapping, result: MfgCenters) {
                tthis.availableCenters = result.centersList;
            }
        );
    }

    getBomCodes() {      
        Utils.httpGetBomCodes(
            this.http,
            this.baseUrl,
            this,
            function (tthis: TDCBOMCodeMapping, result: BomCodeMapping) {

                tthis.originalBomCodes = result.bomCodesList;
                tthis.mapDataToForm(tthis.originalBomCodes);
            }
        );  
    }

    mapDataToForm(data) {
        this.BCMForm = this._formBuilder.group({
            BCMRows: this._formBuilder.array([])
        });
        this.BCMForm = this.fb.group({
            BCMRows: this.fb.array(data.map(val => this.fb.group({
                bomFamilyCodeMappingId: new FormControl(val.bomFamilyCodeMappingId),
                bomCode: new FormControl(val.bomCode),
                familyCodeId: new FormControl(val.familyCodeId ?? -1),
                mfgCenterId: new FormControl(val.mfgCenterId ?? -1),
                utcModifDate: new FormControl(val.utcModifDate),
                modifUser: new FormControl(val.modifUser),
                isEditable: new FormControl(true),
                isNewRow: new FormControl(false),
                action: new FormControl('existingRecord'),
            })
            )) //end of fb array
        }); // end of form group cretation
        this.dataSource.data = (this.BCMForm.get('BCMRows') as FormArray).value;
    }

    initBCMForm(): FormGroup {
        return this.fb.group({
            bomCode: new FormControl(`New_BOMCode_${this.genType++}`),
            familyCodeId: new FormControl(-1),
            mfgCenterId: new FormControl(-1),
            action: new FormControl('newRecord'),
            isEditable: new FormControl(false),
            isNewRow: new FormControl(true),
        });
    }

    addNewRow() {
        const control = this.BCMForm.get('BCMRows') as FormArray;
        control.insert(0, this.initBCMForm());
        this.dataSource = new MatTableDataSource(control.controls)
    }

    editRow(BCMFormElement, i) {
        BCMFormElement.get('BCMRows').at(i).get('isEditable').patchValue(false);
    }

    updateFormFeature(newSelectedObject, index) {
        var obj = this.BCMForm.value.BCMRows;
        obj[index].bomCode = newSelectedObject.bomCode;
        obj[index].familyCodeId = newSelectedObject.familyCodeId ?? -1;
        obj[index].mfgCenterId = newSelectedObject.mfgCenterId ?? -1;
    }

    resetRow(i) {
        if (this.BCMForm.value.BCMRows[i]?.isNewRow)
            this.deleteRow(i);
        else {
            var defaultObjectValues = this.originalBomCodes.find(ft => ft.bomFamilyCodeMappingId == this.BCMForm.value.BCMRows[i]?.bomFamilyCodeMappingId)
            this.updateFormFeature(defaultObjectValues, i)
            this.BCMForm.value.BCMRows[i].isEditable = true;
        }
    }

    deleteRow(i) {
        const control = this.BCMForm.get('BCMRows') as FormArray;
        control.removeAt(i);
        this.dataSource.data = control.value;
        this.refreshTable();
    }

    mapFormToData() {
        this.modifiedBomCodes = this.BCMForm.get('BCMRows')?.value.filter(row => !row.isNewRow
            && !row.isEditable
            && row.familyCodeId !== -1
            && row.mfgCenterId !== -1
            && row.bomCode !== ""
            && row.bomCode.length <= 128);
        this.newBomCodes = this.BCMForm.get('BCMRows')?.value.filter(row => row.isNewRow
            && row.familyCodeId !== -1
            && row.mfgCenterId !== -1
            && row.bomCode !== ""
            && row.bomCode.length <= 128);
    }

    anyChange() {
        this.mapFormToData();
        return this.modifiedBomCodes?.length > 0 || this.newBomCodes?.length > 0;
    }

    onSave() {
        if (this.anyChange()) {
            var bomCodeChanges = { 'modifiedBomCodes': this.modifiedBomCodes, 'newBomCodes': this.newBomCodes }
            Utils.httpUpdateBOMCodes(
                this.http
                , this.baseUrl
                , bomCodeChanges
                , this
                , function (tthis: TDCBOMCodeMapping, result: any) {
                    tthis.getBomCodes();
                    tthis.funcNotify();
                }
            )
        }
    }

    refreshTable() {
        this.objTable.renderRows();
    }

    funcNotify() {
        if (this.isCallSucceed === 1) {
            this.toastrService.success('Save request successfully sent!');
        }
        else if (this.isCallSucceed === -1) {
            this.toastrService.error('Save action could not be performed. Please, contact with administrator');
        }
    }
}
