import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Utils } from '../../../../../utils/utils';
import { MatTableDataSource } from '@angular/material/table';
import { CreateExcellenceLeverKpiPopupComponent } from './createexcellenceleverkpi-popup/createexcellenceleverkpi-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { CustomDialogService } from '../../../../../services/customdialog.service';

@Component({
    selector: 'excellence-lever-kpi',
    templateUrl: './excellence-lever-kpi.component.html',
    styleUrls: ['./excellence-lever-kpi.component.less',
        '../branchexcellenceadmin.component.less',
        '../../../../../style/new-generic-styles.component.less']
})
export class ExcellenceLeverKpiComponent implements OnInit {

    subscriptions: any[] = [];
    public dataSource = new MatTableDataSource<ExcellenceLeverKpi>();
    columnsToDisplay = ['ExcellenceLever', 'AccountId', 'ChangedAtUtc', 'ChangedBy', 'actions'];

    constructor(
        private http: HttpClient
        , private dialogForm: MatDialog
        , @Inject('BASE_URL') private baseUrl: string
        , private toastrService: ToastrService
        , private customDialog: CustomDialogService
    ) { }

    ngOnInit(): void {
        this.getExcellenceLeversKpis();
    }

    getExcellenceLeversKpis() {
        Utils.httpGetExcellenceLeversKpis(
            this.http,
            this.baseUrl,
            this,
            function (tthis: ExcellenceLeverKpiComponent, list: any) {
                tthis.dataSource.data = list
            },
            null

        )
    }
    onRemoveExcellenceLeverKPI(element: ExcellenceLeverKpi) {
        this.customDialog.openConfirm({
            title: "Delete Excellence Factor",
            message: `Do you want to delete ${element.ExcellenceLever} - (${element.AccountId})?`,
            caller: this
        });
        this.customDialog.confirmed().subscribe(res => {
            if (res.confirmed) {
                Utils.httpDeleteExcellenceLeverKpi(
                    this.http
                    , this.baseUrl
                    , element.ExcellenceLeverKpiId
                    , this
                    , function (tthis: ExcellenceLeverKpiComponent, result: any) {
                        if (result) {
                            tthis.getExcellenceLeversKpis()
                            tthis.toastrService.success(`Excellence Lever KPI was deleted successfully!`);
                        }
                        else
                            tthis.toastrService.error(`Excellence Lever KPI could not be deleted. Please, contact with administrator`);
                    },
                    null
                );
            }
        });
    }

    onEditExcellenceLeverKPI(element: ExcellenceLeverKpi) {
        const dialog = this.dialogForm.open(CreateExcellenceLeverKpiPopupComponent, {
            panelClass: ['custom-mat-dialog_v2'],
            disableClose: true,
            data: element,
        })

        dialog.afterClosed().subscribe(() => {
            this.getExcellenceLeversKpis()
        })
    }

    openCreateExcellenceLeverKPIPopup() {
        const dialog = this.dialogForm.open(CreateExcellenceLeverKpiPopupComponent, {
            panelClass: ['custom-mat-dialog_v2'],
            disableClose: true,
            data: {},
        })

        dialog.afterClosed().subscribe(() => {
            this.getExcellenceLeversKpis()
        })
    }

}
