<div class="custom-mat-dialog-form non-overflow">
    <h3 mat-dialog-title>{{title}}</h3>
    <div>
        <form [formGroup]="form">
            <div class="mt-2">
                <label>{{header[NAME].label}}</label>
                <div class="input">
                    <input type="text" name="Name" required
                           class="form-control color-white-i bg-transparent"
                           formControlName="Name"
                           (change)="onNameChange($event)">
                </div>
            </div>

            <div class="mt-2">
                <label>{{header[DESCRIPTION].label}}</label>
                <div class="input">
                    <input type="text" name="Description" required
                           class="form-control color-white-i bg-transparent"
                           formControlName="Description"
                           (change)="onNameChange($event)">
                </div>
            </div>
            <div class="mt-2">
                <label>{{header[MINUTESTOWAIT].label}}</label>
                <div class="input">
                    <input type="number" name="Minutes" required min="0"
                           class="form-control color-white-i bg-transparent"
                           formControlName="Minutes"
                           (change)="onMinutesChange($event)">
                </div>
            </div>
            <div class="mt-2">
                <label>{{header[AREA].label}}</label>
                <div class="input">
                    <mat-select class="form-control" formControlName="Area" 
                                readonly (selectionChange)="onAreaChange($event)">
                        <mat-option *ngFor="let a of listTA; index as i" value="{{a.TestAreaID}}">
                            {{a.Description}}
                        </mat-option>
                    </mat-select>
                </div>
            </div>
            <div class="mt-2">
                <div class="input">
                    <mat-checkbox formControlName="IsITProcessRelevant"
                                  (change)="onIsItProcessRelevantChange($event)">
                        {{header[ISITPROCESSRELEVANT].label}}
                    </mat-checkbox>
                </div>
            </div>
            <div class="mt-2 right">
                <button [disabled]="!form.valid" (click)="onSubmit()" class="btn btn-primary">Save</button> <!--[mat-dialog-close]="1"-->
                <button (click)="onCancelForm()" tabindex="-1" class="btn btn-danger">Cancel</button>
            </div>
        </form>
    </div>
</div>