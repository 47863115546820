import { Component, Inject, Output, EventEmitter, Input } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { SaveService } from '../../../services/save.service';
import { DefaultValuesService } from '../../../services/defaultvalues.service';

@Component({
    selector: 'controllertypeharmonization-menu',
    templateUrl: './controllertypeharmonization-menu.component.html',
    styleUrls: ['../../../style/menu.component.less']
})

export class ControllerTypeHarmonizationMenuComponent {
    @Output() translationAreaChanged = new EventEmitter<TranslationArea>();
    @Output() languageChanged = new EventEmitter<Language>();

    languageList: Language[] | undefined = [];
    @Input() language: Language | undefined;

    constructor(private activatedRoute: ActivatedRoute,
        private router: Router
        , private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , public saveService: SaveService
        , public defaults: DefaultValuesService
    ) {
    }

    onLanguageChanged(l: Language) {
        this.languageChanged.emit(l);
    }

    onSave() {
        this.saveService.fireSaveAll();
    }

    onTranslationAreaChanged(ta: TranslationArea) {
        this.translationAreaChanged.emit(ta);
    }
}
