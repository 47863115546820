import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import "rxjs/operators";
import { DialogService } from '../../../../services/dialog.service';
import { FormGroup } from '@angular/forms';
import { DefaultValuesService } from '../../../../services/defaultvalues.service';
import { MatTableDataSource } from '@angular/material/table';
import { Utils } from '../../../../utils/utils';


@Component({
    selector: 'contractbundlepopup',
    templateUrl: './contractbundlepopup.component.html',
    styleUrls: ['../../../../style/custom-mat-dialog.component.less']
})


export class ContractBundlePopupComponent implements OnInit {

    bundleId = -1

    form: FormGroup | undefined
    submitted = false;

    public dataSource = new MatTableDataSource<string>();
    columnsToDisplay = ['feature'];

    constructor(private confimDialog: DialogService
        , @Inject(MAT_DIALOG_DATA) public data: any
        , @Inject('BASE_URL') private baseUrl: string
        , public dialogRef: MatDialogRef<ContractBundlePopupComponent>
        , public defaults: DefaultValuesService
        , private http: HttpClient
    ) {

        this.confimDialog.tthis = this;
    }

    ngOnInit(): void {
    }

    loadFeatures() {
        this.dataSource.data = this.data.list.filter(x => x.BundleId === this.bundleId)[0].BundleList
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onSubmit() {
        Utils.httpAddContractBundleByTenant(
            this.http
            , this.baseUrl
            , this.bundleId
            , this
            , function (tthis: ContractBundlePopupComponent) {
                tthis.dialogRef.close({ event: 'submit' });
            }
        );
    }

    onBack() {

    }
}


