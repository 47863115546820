import { Component, Inject, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";

@Component({
    selector: 'changelog-menu',
    templateUrl: './changelog-menu.component.html',
    styleUrls: ['../../style/menu.component.less']
})

export class ChangeLogMenuComponent {

    @Output() controllerChanged = new EventEmitter<ControllerType>();

    constructor(
        private activatedRoute: ActivatedRoute
        , private router: Router
        , private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
    ) {

    }

    onControllerChanged(ct: ControllerType) {
        this.controllerChanged.emit(ct);
    }
}
