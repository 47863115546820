import { Component, AfterViewInit, ViewChild } from "@angular/core";
import { MatTabGroup } from "@angular/material/tabs";
import { DefaultValuesService } from "../../../services/defaultvalues.service";

@Component({
    selector: "controllertypes-deploymentstatus",
    templateUrl: './controllertypes-deploymentstatus.component.html',
    styleUrls: ['../../../style/container-tab-list.component.less', '../../../style/custom-mat-dialog.component.less', '../../../style/mat-table.component.less']
})

export class ControllerTypesDeploymentStatus {
    @ViewChild('tabGroup') tabGroup!: MatTabGroup;
    title: string;

    constructor(
        public defaults: DefaultValuesService
    ) {
        this.title = "Deployment status";
    }

    ngAfterViewInit() {
        this.defaults.defaultComponentTab(this)
    }

}