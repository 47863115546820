import { Component, Input } from '@angular/core';

@Component({
    selector: 'deploycontrollertypes'
    , templateUrl: './deploycontrollertypes-form.component.html'
    , styleUrls: ['./deploy-form.less']
})
export class DeployControllerTypesFormComponent {

    @Input() deployment = {} as Deployment;
    title: string;

    constructor( ) {
        this.title = "Deployment of ControllerTypes Options";
    }

    onControllerConfigChange(evt: any) {
        this.deployment.ControllerConfig = evt.checked;
    }

    onControllerTypesByTenantChange(evt: any) {
        this.deployment.ControllerTypesByTenant = evt.checked;
    }

    onControllerTypeToMasterdataRequiredChange(evt: any) {
        this.deployment.ControllerTypeToMasterDataRequired = evt.checked;
    }

    onDefaultTransmissionModes(evt: any) {
        this.deployment.DefaultTransmissionModes = evt.checked;
    }

    onAlertWindowConfiguration
        (evt: any) {
        this.deployment.AlertWindowConfiguration = evt.checked;
    }

}


