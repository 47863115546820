import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from
    "@angular/forms"
import { ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { CustomDialogService } from '../../../../../services/customdialog.service';
import { AddGroupDialogue } from '../addgroupdialogue/addgroupdialogue.component';
import { SpinnerOverlayService } from '../../../../../services/spinner-overlay.service';
import { MatSelectionList } from '@angular/material/list';
import { UMPAction, UMPCategory, Utils } from '../../../../../utils/utils';





@Component({
    selector: "licensemgmt",
    templateUrl: './licensemgmt.component.html',
    styleUrls: ['./licensemgmt.component.less', '../../../../../style/new-generic-styles.component.less'],

})

export class LicenseManagementComponent  {

    public entities: any;
    public userName: String = '';
    userId = ''



    Response_State: number = -2; //0 -> empty , 1 -> correct and not empty ,-1 -> error, -2 -> Calling API
    errorResponse: any;
    isCallSucceed: number = 0;


    constructor(
        private route: ActivatedRoute,
        private http: HttpClient,
        private toastrService: ToastrService,
        private dialogForm: MatDialog,
        private spinnerOverlayService: SpinnerOverlayService,


        @Inject('BASE_URL') private baseUrl: string
    ) {
        this.route.params.subscribe(
            (params: Params) => {
                this.resetAll();
            });
        

    }
    resetAll() {
        this.userName = '';
        this.userId = ''



        this.Response_State = -2; //0 -> empty , 1 -> correct and not empty ,-1 -> error, -2 -> Calling API
        this.isCallSucceed = 0;
        this.userId = this.route.snapshot.paramMap.get('userId') || '';
        this.funcGetLicenseMgmt();
    }
    funcGetLicenseMgmt() {
        this.Response_State = -2;
        Utils.httpGetLicenseMgmt(
            this.http
            , this.baseUrl
            , this.userId
            , this
            , function (tthis: LicenseManagementComponent, data: any) {
                tthis.entities = data;
                tthis.Response_State = 1;

            }
        )
    }
    funcNotifyDeselect() {
        if (this.isCallSucceed === 1) {
            this.toastrService.success('License Management request sent successfully !', 'License Management Permission Request!');
        }
        else if (this.isCallSucceed === -1) {
            this.toastrService.error('License Management request not sent successfully !', 'License Management Permission Request!');
        }
    }
    funcNotify() {
        if (this.isCallSucceed === 1) {
            this.toastrService.success('License Management request sent successfully !', 'Add License Management Request!');
        }
        else if (this.isCallSucceed === -1) {
            this.toastrService.error('License Management request not sent successfully !', 'Add License Management Request!');
        }
    }
    onSelect(id) {
        this.isCallSucceed = 0;

        

        Utils.httpPostGroupsByType(
            this.http
            , this.baseUrl
            //, { "Personae": id }
            , {
                "Personae": [id],
                "UmpAction": UMPAction.Assign,
                "UmpCategory": UMPCategory.AssignedLicenses,
                "Type": 0
            }
            , this.userId
            , this
            , function (tthis: LicenseManagementComponent, data: any) {
                tthis.funcGetLicenseMgmt();

                tthis.funcNotify();

            }
        )
        

    }
    onDeselect(id) {

        
        this.isCallSucceed = 0;
        Utils.httpRevokeGroupPersonae(
            this.http
            , this.baseUrl
            , {
                "Personae": [id],
                "UmpAction": UMPAction.Unassign,
                "UmpCategory": UMPCategory.AssignedLicenses,
                "Type": 0
            }
            , this.userId
            , this
            , function (tthis: LicenseManagementComponent) {
                tthis.funcGetLicenseMgmt();
                tthis.funcNotifyDeselect();
            }
        )
        

    }
    itemSelected(event) {
        if (event.options[0]._selected) {
            this.onSelect(event.options[0]._value);
        } else {
            this.onDeselect(event.options[0]._value);
        }
    }
   
    hasAdditionalCosts(persona: string) {
        return Utils.hasAdditionalLicenseCost(persona)
    }

    licenseAsgndPercent(entity) {
        let result = 0
        result = entity?.totalLicenses == 0 ? 0 : (entity.consumedLicenses / entity.totalLicenses) * 100;
        return result;
    }

    licenseAvlblPercent(entity) {
        let result = 0
        result = entity?.totalLicenses == 0 ? 0 : ((entity.totalLicenses - entity.consumedLicenses) / entity.totalLicenses) * 100;
        return result;
    }
    progressBarStats(entity) {
        let result = `${entity.persona} \n  Total: ${entity.totalLicenses} \n Consumed: ${entity.consumedLicenses} \n Available: ${entity.totalLicenses - entity.consumedLicenses}`
        return result
    }

}
