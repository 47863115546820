import { Component, Inject, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { DialogService } from "../../../../services/dialog.service";
import { SaveService } from '../../../../services/save.service';
import { Utils } from "../../../../utils/utils";
import { HttpClient } from "@angular/common/http";
import { DefaultValuesService } from "../../../../services/defaultvalues.service";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { TransmissionModeFormComponent } from "./transmissionmode-form.component";
import { CustomDialogService } from '../../../../services/customdialog.service';

@Component({
    selector: "defaulttransmissionmode-list",
    templateUrl: './defaulttransmissionmode-list.component.html',
    styleUrls: ['./defaulttransmissionmode-list.component.less']
})

export class DefaultTransmissionModeListComponent implements OnInit {
    columnsToDisplay = ['ItemNo', 'Description', 'TransmissionMode', 'BatchInterval', 'actions'];
    list: TransmissionModeConfiguration[] = [];

    public dataSource = new MatTableDataSource<TransmissionModeConfiguration>();
    subscriptionControllerType: any;

    Filter = 0;


    constructor(
        private http: HttpClient
        , private dialogService: DialogService
        , private defaults: DefaultValuesService
        , @Inject('BASE_URL') private baseUrl: string
        , private dialog: MatDialog
        , private dialogForm: MatDialog
        , private customDialog: CustomDialogService
        , private saveService: SaveService

    ) {

    }


    ngOnInit() {
        this.dialogService.tthis = this;
        this.subscriptionControllerType = this.defaults.controllerTypeMessageChanges$.subscribe((item: ControllerType) => this.onControllerChanged(item));
        this.refresh();
    }


    ngOnDestroy() {
        this.subscriptionControllerType.unsubscribe();
    }

    ngOnChanges() {

        this.dataSource.data = this.list;
    }





    onControllerChanged(ct: ControllerType) {
        this.refresh();
    }

    onRowEditInit(element) {


        const Dialog = this.dialogForm.open(TransmissionModeFormComponent, {
            panelClass: 'custom-mat-dialog',
            disableClose: false,
            data: { "ControllerType": element.ControllerType, "TransmissionMode": element.TransmissionMode, "BatchInterval": element.BatchInterval }
        });
        Dialog.afterClosed().subscribe(result => {
            if (result) this.refresh();
        });

        //const Dialog = this.dialogForm.open(ControllerTypeHarmonizationFormComponent, {
        //    panelClass: 'custom-mat-dialog',
        //    disableClose: false,
        //    data: { "ControllerTypeLocal": element.ControllerTypeLocal, "ControllerTypeBK": element.ControllerTypeBK, "_ControllerTypeMappingID": element._ControllerTypeMappingID, "TenantID": "" }
        //});
        //Dialog.afterClosed().subscribe(result => {
        //    if (result) this.refresh();
        //});



    }

    onDelete(element) {
        const tthis = this;

        this.customDialog.openConfirm({
            title: "DELETE DEFAULT TRANSMISSION MODE CONFIG",
            message: 'Default transmission mode for controller "' + element.Description + '" will be deleted. Continue ?',
            caller: this
        });

        this.customDialog.confirmed().subscribe(res => {

            if (res.confirmed) {
                let url = this.baseUrl + Utils.getTransmissionModeConfigurationAPI() + "/delete";

                this.http
                    .post<boolean>(url, { ControllerType: element.ControllerType, TransmissionMode: element.TransmissionMode, BatchInterval: element.BatchInterval })
                    .subscribe(res => {
                        this.refresh();
                    }, error => {
                        Utils.writeLog(this.http, this.baseUrl, error); //Utils.showErrorDialog(null, error);
                    }); //console.error(error));
            }
        })

    }

    public btn_new() {

        const Dialog = this.dialogForm.open(TransmissionModeFormComponent, {
            panelClass: 'custom-mat-dialog',
            disableClose: false,
            data: { "ControllerType": "*", "TransmissionMode": "", "BatchInterval": ""}
        });
        Dialog.afterClosed().subscribe(result => {
            if (result) this.refresh();
        });




    }


    private filterColumn(value: any, name: string) {
        switch (name) {
            case 'status':
                this.Filter = value.value;
        }
        this.refresh()
    }

    public btn_refresh() {
        this.refresh();

        //this.dialogForm.open(AzDataFactoryFormComponent, {
        //    panelClass: 'custom-mat-dialog',
        //    disableClose: true,
        //    data: { "isCustomerPortal": 0, "type": "", "entity": "Customer Portal" }
        //});


    }

    private refresh() {
        const tthis = this;
        ///**
        //    * load all the data
        //    * */

        let url = this.baseUrl + Utils.getTransmissionModeConfigurationAPI() + "/all" ;

        this.http
            .get<vTransmissionModeConfiguration[]>(url, {})
            .subscribe(res => {
                tthis.list = res;
                tthis.dataSource.data = tthis.list;
            }, error => {
                Utils.writeLog(this.http, this.baseUrl, error); //Utils.showErrorDialog(null, error);

            }); //console.error(error));


        //Utils.httpGetAllControllerTypeLocal(
        //    this.http
        //    , this.baseUrl
        //    , this.Filter
        //    , this
//            , function (tthis: any, values: ControllerTypeVMapping[]) {
        //        tthis.list = values;
        //        tthis.dataSource.data = tthis.list
        //    }
        //);
    }

    private sinceDays(date: Date) {
        let currentDate = new Date();

        let dateDeployment = new Date(date);
        return Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())
            - Date.UTC(dateDeployment.getFullYear(), dateDeployment.getMonth(), dateDeployment.getDate())) / (1000 * 60 * 60 * 24));
    }
}