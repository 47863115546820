import { Component, Inject, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Utils } from "../../utils/utils";
import { DialogService } from "../../services/dialog.service";
import { DefaultValuesService } from "../../services/defaultvalues.service";
import { SaveService } from "../../services/save.service";

@Component({
    selector: "errorcode-edit-newlanguage",
    templateUrl: './errorcode-edit-newlanguage.component.html',
    styleUrls: ['../../style/form.component.less']
})

export class ErrorCodeEditNewLanguageComponent implements OnInit {
    form!: FormGroup;
    errorcode: ErrorCode = <ErrorCode>{};
    weights: number[] = [0, 1, 2, 3];

    DESCRIPTION: number = 1;
    ERRORW: number = 3;
    CONTROLBLK: number = 4;

    classname: String[] = [];
    isundodisabled: boolean[] = [];

    @Input() header: any[] = [];
    @Input() list: ErrorCode[] = [];
    //@Input() businessKey: BusinessKey | undefined;
    @Input() rowIndex: number = -1;
    @Input() newRow: boolean = false;

    @Output() undoEvent = new EventEmitter<{ rowIndex: number, businessKey: BusinessKey }>();

    constructor(
        private activatedRoute: ActivatedRoute
        , private router: Router
        , private http: HttpClient
        , private fb: FormBuilder
        , @Inject('BASE_URL') private baseUrl: string
        , public defaults: DefaultValuesService
        , private dialog: DialogService
        , public saveService: SaveService
    ) {        
    }

    ngOnInit() {

        this.dialog.tthis = this;
        this.saveService.addListener(this);

        //if (this.newRow) {
        //    this.createFormToInsert();
        //}
//        else {
            this.errorcode.Description = this.list[this.rowIndex].Description;
            this.errorcode.ErrorWeight = this.list[this.rowIndex].ErrorWeight;
            this.createForm();
//        }

        var tthis = this;
        this.header.forEach(
            function(value:any, index:number, array:any[]) {
                tthis.classname[index] = "";
                tthis.isundodisabled[index] = true;
            }
        );
    }

    createForm() {
        this.form = this.fb.group({
            Description: [this.errorcode.Description, Validators.required],
        });
    }


    /**
     * called by save service
     * */
    onCancel(index: number) {
        if (this.form) {
            if (index == this.rowIndex) {

                this.form.setValue({
                    Description: ""
                });
                this.onUndo();
            }
        }
    }

    onInputChange(evt: any) {
        if (!this.newRow && this.form && this.errorcode
            && this.form.value.Description != this.errorcode.Description) {
            this.classname[this.DESCRIPTION] = Utils.getEditingInputClassName();
            this.isundodisabled[this.DESCRIPTION] = false;
            this.list[this.rowIndex].Description = this.form.value.Description;
        }
        this.saveService.showSaveButton();
    }

    onRowCancel() {
        this.saveService.fireCancel(this.rowIndex, this);
    }

    /**
     * called by save service
     * */
    onSave() {
        if (this.form && this.form.valid) {
            this.http
                .post<ErrorCode>(
                    this.baseUrl + Utils.getErrorCodeAPI() + "translate"
                    , this.list[this.rowIndex]
                )
                .subscribe(
                    res => this.updateRowObjectWithResolutions(res)
                    , error => {
                        Utils.httpGetErrorCode(
                            this.http
                            , this.baseUrl
                            , this.list[this.rowIndex].ErrorCodeID
                            , this
                            , function (tthis: any, value: ErrorCode) {
                                tthis.updateRowObjectWithResolutions(value);
                            }
                        );
                        this.dialog.showErrorDialog(error);
                    }
                );
        }
    }

    onUndo() {
        this.list[this.rowIndex].Description = this.errorcode.Description;
        this.classname[this.DESCRIPTION] = "";
        this.isundodisabled[this.DESCRIPTION] = true;
        this.updateForm();
    }

    updateForm() {
        if (this.form) {
            this.form.setValue({
                Description: this.list[this.rowIndex].Description
            });
        }
    }

    updateRowObjectWithResolutions(ec: ErrorCode) {
        this.list[this.rowIndex] = ec;
        Utils.httpGetAllErrorCodeResolutions(
            this.http
            , this.baseUrl
            , ec.ErrorCodeID
            , this
            , function (tthis: any, list: ErrorCodeResolution[]) {
                tthis.list[tthis.rowIndex].ErrorCodeResolutions = list;
            }
        );
    }

}