import { Component, Inject, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Utils } from "../../../utils/utils";
import { DefaultValuesService } from "../../../services/defaultvalues.service";

@Component({
    selector: "nonrecoverableerrors",
    templateUrl: './nonrecoverableerrors.component.html',
    styleUrls: ['../../../style/container-tab-list.component.less']
})




export class NonRecoverableErrorsComponent {

    title: string;

    subscriptionStage: any;
    subscriptionTenant: any;

    ReportType: string; // "ST" => Stable , "EA" "AMS" "AP"

    constructor(
        private http: HttpClient,
        private defaults: DefaultValuesService
        , @Inject('BASE_URL') private baseUrl: string
    ) {
        this.title = "Non Recoverable Errors";
        this.ReportType = "";
    }

    ngOnInit() {
        this.title = "Services data check 2";

        this.subscriptionStage = this.defaults.stageMessageChanges$.subscribe(() => {
            this.setURL();
        });
        this.subscriptionTenant = this.defaults.tenantMessageChanges$.subscribe(() => {
            this.setURL();
        });

        this.setURL();
    }

    setURL() {

        if (this.defaults.stage === 0) // Stable
            this.ReportType = "ST";
        else {
            let url = this.baseUrl + Utils.getCDPControllerTypeMappingAPI() + "getenvfromtenant/" + this.defaults.tenant;

            this.http
                .get<string>(url, {})
                .subscribe(res => {

                    this.ReportType = res;

                }, error => {
                    Utils.writeLog(this.http, this.baseUrl, error); //Utils.showErrorDialog(null, error);

                }); //console.error(error));
        }

        //    if (this.defaults.stage === false) // Stable
        //        this.ReportType = "ST";
        //    else
        //    {
        //        this.ReportType = "AP";
        //    }

        //    alert(this.ReportType);
    }



}





