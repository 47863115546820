import { Component, Output, OnInit, Inject, EventEmitter, Input, SimpleChanges, TemplateRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Utils } from '../../utils/utils';
import { WaitingService } from '../../services/waiting.service';
import { PagerService } from '../../services/pager.service';
import { SaveService } from '../../services/save.service';
import { DefaultValuesService } from '../../services/defaultvalues.service';
import { FilterService } from '../../services/filter.service';

@Component({
    selector: 'select-checkbox-search',
    templateUrl: './select-checkbox-search.component.html',
    styleUrls: ['../../style/select-checkbox-search.component.less']
})

export class SelectCheckBoxSearchComponent implements OnInit {

    @Input() idcontainer: string = "";
    //@Input() title: string = "";
    //@Input() type: string = "";
    //@Input() index: number = -1;
    @Input() header: any = {};
    @Input() data: any[] = [];
    @Input() filterdata: any[] = [];
    @Input() getValue: any | undefined;
    @Input() equals: any;

    @Output() eventFilter = new EventEmitter<any>();

    /**
     * all data to run the reset
     * 
     * */
    toSelectableAll: { show: string, value: any } [] = [];
    toSelectable: { show: string, value: any }[] = [];
    selectable: { show: string, value: any }[] = [];

    isToInitiate: boolean = true;

    constructor(
        @Inject('BASE_URL') private baseUrl: string
        , private filtersservice: FilterService
    ) {
    }

    private isSelected(element: any): boolean {
        var tthis = this;
        var felem = this.selectable.find(
            function (value: { show: string, value: any }, index: number, obj: { show: string, value: any }[]) {
                if (tthis.equals)
                    return tthis.equals(element.value, value.value);
                return false;
            }
        );
        return felem != undefined;
    }

    //private hasElement(): boolean {
    //    return false;
    //}

    ngOnInit() {
        this.filtersservice.addListener(this);
        this.onInit();
    }

    /**
     * called by filter service
     * */
    onInit() {
        //this.ngOnInit();
        //if (this.isToInitiate) {
            this.toSelectable = [];
            this.selectable = [];
            for (var i = 0; i < this.data.length; ++i) {
                var v = this.getValue(this.data, i, this.header.index);
                this.toSelectableAll.push({ show: v, value: this.data[i] });
                this.toSelectable.push({ show: v, value: this.data[i] });
            }
            this.isToInitiate = false;
        //}
    }

    //ngOnChanges() {
    //    if (this.controllerType != this.defaults.controllertype || this.languge != this.defaults.language)
    //        this.ngOnInit();
    //}

    getClassName(): string {
        return this.selectable.length != 0 ? "hasElements" : "";
    }

    onChange(event: any) {
        var elem = event.currentTarget;
        var tthis = this;
        this.toSelectable = this.toSelectableAll.filter(
            function (value: any, index: number, array: any[]) {
                if (tthis.isSelected(value))
                    return false;
                return value.show.indexOf(elem.value) != -1 ;
            }
        );
    }

    onRemoveFilter(event: any, element: any, elementindex: number) {
        var elems = this.selectable.splice(elementindex, 1);
        var elem = elems[0]; //element to remove from the main list
        var tthis = this;
        var felemIndex = -1;

        this.toSelectable.push(elem);

        var felem = this.filterdata.find(
            function (value: { show: string, value: any }, index: number, obj: { show: string, value: any }[]) {
                if (tthis.equals)
                    if (tthis.equals(element.value, value)) {
                        felemIndex = index;
                        return true;
                    }
                return false;
            }
        );

        if (felemIndex != -1) {
            this.filterdata.splice(felemIndex, 1);
            this.eventFilter.emit(this.filterdata);
        }
    }

    onSelectToFilter(event: any, value: any, index: number) {
        var elems = this.toSelectable.splice(index, 1);
        var elem = elems[0];
        this.selectable.push(elem);
        this.filterdata.push(elem.value);
        this.eventFilter.emit(this.filterdata);
    }

    showCheckboxes(event: any) {
        this.header.expanded = !this.header.expanded;
    }

}