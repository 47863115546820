<mat-form-field class="search-input">
    <input placeholder="Filter"
           matInput
           [matChipInputFor]="chipList"
           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
           [matChipInputAddOnBlur]="addOnBlur"
           (matChipInputTokenEnd)="applyFilter($event)">
    <mat-chip-list #chipList>
    </mat-chip-list>
</mat-form-field>
<mat-chip *ngFor="let item of searchItems" [selectable]="selectable"
          [removable]="removable" (removed)="remove(item)">
    {{item.name}}
    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
</mat-chip>
<div class="example-container">
    <table mat-table #itemsList
           [dataSource]="dataSource"
           multiTemplateDataRows
           class="mat-elevation-z8 mat-table-custom" id="branchhierarchy">

        <ng-container matColumnDef="BA">
            <th mat-header-cell *matHeaderCellDef> BA </th>
            <td mat-cell *matCellDef="let element;let i = dataIndex">
                <p [innerHTML]="element.BA"></p>
            </td>
        </ng-container>
        <ng-container matColumnDef="BU">
            <th mat-header-cell *matHeaderCellDef> BU </th>
            <td mat-cell *matCellDef="let element;let i = dataIndex">
                <p [innerHTML]="element.BU"></p>
            </td>
        </ng-container>
        <ng-container matColumnDef="OU">
            <th mat-header-cell *matHeaderCellDef> OU </th>
            <td mat-cell *matCellDef="let element;let i = dataIndex">
                <p [innerHTML]="element.OU"></p>
            </td>
        </ng-container>
        <ng-container matColumnDef="OSU">
            <th mat-header-cell *matHeaderCellDef> OSU </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.OSU"></p>
            </td>
        </ng-container>
        <ng-container matColumnDef="Country">
            <th mat-header-cell *matHeaderCellDef> Country </th>
            <td mat-cell *matCellDef="let element;let i = dataIndex">
                <p [innerHTML]="element.Country"></p>
            </td>
        </ng-container>
        <ng-container matColumnDef="MgmtBranch">
            <th mat-header-cell *matHeaderCellDef> MgmtBranch </th>
            <td mat-cell *matCellDef="let element;let i = dataIndex">
                <p [innerHTML]="element.MgmtBranch" *ngIf="!isEditingRow(element) && !isNewRow(i)"></p>
                <mat-form-field floatLabel="never" *ngIf="isEditingRow(element) || isNewRow(i)">
                    <mat-select [(ngModel)]="element.MgmtBranch" (selectionChange)="changeRef($event, element)">
                        <mat-option *ngFor="let dep of listRef" [value]="dep.MgmtBranch">
                            {{ dep.MgmtBranch }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </ng-container>
        <ng-container matColumnDef="Region">
            <th mat-header-cell *matHeaderCellDef> Region </th>
            <td mat-cell *matCellDef="let element;let i = dataIndex">
                <p [innerHTML]="element.Region" *ngIf="!isEditingRow(element) && !isNewRow(i)"></p>
                <mat-form-field floatLabel="never" *ngIf="isEditingRow(element) || isNewRow(i)">
                    <input matInput [value]="element.Region" [(ngModel)]="element.Region">
                </mat-form-field>
            </td>
        </ng-container>
        <ng-container matColumnDef="District">
            <th mat-header-cell *matHeaderCellDef> District </th>
            <td mat-cell *matCellDef="let element;let i = dataIndex">
                <p [innerHTML]="element.District" *ngIf="!isEditingRow(element) && !isNewRow(i)"></p>
                <mat-form-field floatLabel="never" *ngIf="isEditingRow(element) || isNewRow(i)">
                    <input matInput [value]="element.District" [(ngModel)]="element.District">
                </mat-form-field>
            </td>
        </ng-container>
        <ng-container matColumnDef="Branch">
            <th mat-header-cell *matHeaderCellDef> Branch <mat-icon matTooltipClass='opmodes-custom-tooltip' data-html="true" matTooltip="Branch name cannot contain hyphen symbol.">info</mat-icon> </th>
            <td mat-cell *matCellDef="let element;let i = dataIndex">
                <p [innerHTML]="element.Branch" *ngIf="!isEditingRow(element) && !isNewRow(i)"></p>
                <mat-form-field floatLabel="never" *ngIf="isEditingRow(element) || isNewRow(i)">
                    <input matInput [value]="element.Branch" [(ngModel)]="element.Branch" (keydown)="onKeyDownBranchName($event)">
                </mat-form-field>
            </td>
        </ng-container>
        <ng-container matColumnDef="Number">
            <th mat-header-cell *matHeaderCellDef> Number </th>
            <td mat-cell *matCellDef="let element;let i = dataIndex">
                <p [innerHTML]="element.Number" *ngIf="!isNewRow(i)"></p>
                <mat-form-field floatLabel="never" *ngIf="isNewRow(i)">
                    <input matInput [value]="element.Number" [(ngModel)]="element.Number">
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container matColumnDef="Key">
            <th mat-header-cell *matHeaderCellDef> Field Branch Key </th>
            <td mat-cell *matCellDef="let element;let i = dataIndex">
                <p [innerHTML]="element.Key" *ngIf="!isEditingRow(element) && !isNewRow(i)"></p>
                <mat-form-field floatLabel="never" *ngIf="isEditingRow(element) || isNewRow(i)">
                    <mat-select [(ngModel)]="element.Key" (selectionChange)="changeKey($event, element)" class="key-branch-select">
                        <mat-option *ngFor="let dep of listKey" [value]="dep.Key">
                            {{ dep.Key }} - {{dep.BranchName}} - {{ dep.LocationForMap }} - {{ dep.Address }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let element; let i = dataIndex">
                <button mat-icon-button *ngIf="!isEditingRow(element) && !isNewRow(i)" matTooltip="Edit Row"
                        (click)="onRowEditInit(element)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button *ngIf="isEditingRow(element) || isNewRow(i)" matTooltip="Cancel Row"
                        (click)="onRowEditCancel(element, i)">
                    <mat-icon>rotate_left</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Delete Row" *ngIf="!isEditingRow(element) && !isNewRow(i)"
                        (click)="onDeleteFunc(element)">
                    <mat-icon>delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row class="tableheader" *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; let i = dataIndex; columns: columnsToDisplay;"
            class="item"
            [class.alternative2]="isEditingRow(element)"
            [class.alternative3]="i === 0 && addingNewBranch">
        </tr>
    </table>
</div>

