<div class="example-container">
    <mat-form-field class="search-input max-30w">
        <input placeholder="Filter"
               matInput
               (keyup)="applyFilter($event)">
    </mat-form-field>
    <table mat-table
           [dataSource]="dataSource"
           multiTemplateDataRows
           matSort
           class="mat-elevation-z8 mat-table-custom" id="FPControllerConfig">
        <ng-container *ngFor="let item of columnsToDisplay" matColumnDef="{{item}}">
            <th mat-header-cell *matHeaderCellDef>
                {{item === 'actions' || item === 'seeactions' ? 'Enabled / Disabled' : item}}
            </th>
            <ng-container *ngIf="item==='actions' && 1==1">
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button (click)="changeElement(element, true)" matTooltip="ENABLE mode for current controller" *ngIf="!getInput(element,'isEnabled')">
                        <mat-icon class="mat-icon-grey">toggle_off</mat-icon>
                    </button>
                    <button mat-icon-button (click)="changeElement(element, false)" matTooltip="DISABLE mode for current controller" *ngIf="getInput(element,'isEnabled') === true">
                        <mat-icon class="mat-icon-green">toggle_on</mat-icon>
                    </button>
                </td>
            </ng-container>
            <ng-container *ngIf="item==='seeactions' ">
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="getInput(element,'isEnabled')">
                        ENABLED
                    </div>
                </td>
            </ng-container>
            <ng-container *ngIf="item!=='actions'">
                <ng-container *matCellDef="let element" [ngSwitch]="getDataType(item)">
                    <td mat-cell *ngSwitchCase="'string'">
                        <p [innerHTML]="getInput(element,item)"></p>
                    </td>
                    <td mat-cell *ngSwitchCase="'date'">
                        <p [innerHTML]="getInput(element,item) | date:'yyyy-MM-dd HH:mm'"></p>
                    </td>
                </ng-container>
            </ng-container>
        </ng-container>

        <tr mat-header-row class="tableheader" *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; let i = dataIndex; columns: columnsToDisplay;" class="item">
    </table>
</div>
