import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import "rxjs/operators";
import { Data, Router } from '@angular/router';
import { DialogService } from '../../../../services/dialog.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Utils } from '../../../../utils/utils';
import { DefaultValuesService } from '../../../../services/defaultvalues.service';
import * as moment from 'moment';
import { DatePipe } from '@angular/common'

@Component({
    selector: "alertmergeconfiguration-form",
    templateUrl: './alertmergeconfiguration-form.component.html',
    styleUrls: ['../../../../style/azdatafactory.component.less', '../../../../style/custom-mat-dialog.component.less']

})

export class AlertMergeConfigurationFormComponent implements OnInit {

    selected = {} as ControllerTypeByTenant;

    controllerTypes: ControllerType[] = [];

    isBatch: boolean;

    saveReady: boolean;
    errorCT: boolean;
    errorTW: boolean;
    errorTWvalue: boolean;

    body: any;
    title: string;
    submitted = false;
    form: FormGroup = new FormGroup({});



    constructor(private http: HttpClient
        , private confimDialog: DialogService
        , private router: Router
        , @Inject(MAT_DIALOG_DATA) public data: any
        , @Inject('BASE_URL') private baseUrl: string
        , public dialogRef: MatDialogRef<AlertMergeConfigurationFormComponent>
        , private fb: FormBuilder
        , private defaults: DefaultValuesService
        , private formBuilder: FormBuilder
        , private datepipe: DatePipe) {

        this.confimDialog.tthis = this;
        this.title = "";
        this.isBatch = false;
        this.saveReady = false;
        this.errorTW = false;
        this.errorTWvalue = false;
        this.errorCT = false;
    }

    ngOnInit(): void {
        //this.body = {
        //    SessionId: ''
        //}

        const tthis = this;

        Utils.httpGetAllControllerTypes(
            this.http
            , this.baseUrl
            , this
            , function (tthis: any, list: ControllerType[]) {

                //list.unshift({
                //    Description: "ALL CONTROLLERS ( * )",
                //    BK: "*",
                //    ControllerTypeID: 0,
                //    DeployErrorCodes: false,
                //    DeployOpModes: false,
                //    IsUsingErrorCodes: false,
                //    CountryOwnerName: "",
                //    CountryOwnerCode: ""
                //});

                tthis.controllerTypes = list;

            }
        );



        this.body = {
            ControllerType: this.data.ControllerType,
            TxtTimeWindowInSeconds: this.data.BatchInterval,

        }


        this.form = this.formBuilder.group({
            ControllerType: [this.data.ControllerType],
            TxtTimeWindowInSeconds: [this.data.TimeWindowInSeconds]
        });

        this.CheckSaveReady();
    }


    onTimeWindowInSecondsChange(evt: any): void {

        this.CheckSaveReady();

    }

    public CheckSaveReady() {
        

    }

    public CheckFields() {
        this.errorTW = false;
        this.errorTWvalue = false;
        this.errorCT = false;

        if (this.f['ControllerType'].value == null || this.f['ControllerType'].value == "")
            this.errorCT = true;

        if (this.f['TxtTimeWindowInSeconds'].value !== "" && this.f['TxtTimeWindowInSeconds'].value !== null)
        {
            this.errorTW = false;

            if (this.f['TxtTimeWindowInSeconds'].value >= 0 && this.f['TxtTimeWindowInSeconds'].value <= 86400)
                this.errorTWvalue = false;
            else
                this.errorTWvalue = true;
        }
        else
            this.errorTW = true;


        return !this.errorTW && !this.errorTWvalue && !this.errorCT;
    }


    // convenience getter for easy access to form fields
    get f() {
        return this.form.controls;
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    onSubmit() {

        if (this.CheckFields()) {
            /*
             * If control exist already for the tenant data will be updated
             */
            this.submitted = true;

            let url = this.baseUrl + Utils.getMasterAlertWindowAPI() + "/add";

            this.http
                .post<boolean>(url, { ControllerType: this.f['ControllerType'].value, TimeWindowInSeconds: this.f['TxtTimeWindowInSeconds'].value })
                .subscribe(res => {
                    this.dialogRef.close(true);
                }, error => {
                    Utils.writeLog(this.http, this.baseUrl, error); //Utils.showErrorDialog(null, error);
                }); //console.error(error));
        }

    }

    onBack() {

    }



}

