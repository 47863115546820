import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from
    "@angular/forms";
import { ActivatedRoute } from '@angular/router';






@Component({
    selector: "umpanalytics",
    templateUrl: './umpanalytics.component.html',
    styleUrls: ['./umpanalytics.component.less'],

})

export class UMPAnalyticsComponent implements OnInit {
  


    constructor(
        private http: HttpClient,
        private route: ActivatedRoute,
        @Inject('BASE_URL') private baseUrl: string,

    ) {
       
    }

    ngOnInit() {

    }
   
}
