import { Component, OnInit, AfterViewInit, ViewChild } from "@angular/core";
import { MatTabGroup } from "@angular/material/tabs";
import { DefaultValuesService } from "../../../../services/defaultvalues.service";

@Component({
    selector: "adminsection",
    templateUrl: './adminsection.component.html',
    styleUrls: ['../../../../style/container-tab-list.component.less']
})

export class AdminSectionComponent implements OnInit {
    @ViewChild('tabGroup') tabGroup!: MatTabGroup;
    
    
    constructor(
        public defaults: DefaultValuesService,
    ) {


    }


    ngOnInit(): void {


    }
    ngAfterViewInit() {
        this.defaults.defaultComponentTab(this)
    }

}