import { Component, Inject, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Utils } from "../../../utils/utils";
import { WaitingService } from "../../../services/waiting.service";

@Component({
    selector: "exceptionlog",
    templateUrl: './exceptionlog.component.html',
    styleUrls: ['../../../style/container-tab-list.component.less']
})

export class ExeceptionLogComponent implements OnInit {

    title = "Exceptions";
    list: ExceptionLog[] = [];

    constructor(
        private http: HttpClient
        , private waiting: WaitingService
        , @Inject('BASE_URL') private baseUrl: string
    ) {
    }

    ngOnInit() {
        Utils.httpGetAllExceptionLog(
            this.http
            , this.baseUrl
            , this
            , function (tthis: any, list: ExceptionLog) {
                tthis.list = list;
            }
        );
    }

}