import { Component, Input } from "@angular/core";

@Component({
    selector: "exceptionlog-list",
    templateUrl: './exceptionlog-list.component.html',
    styleUrls: ['../../../style/list.component.less', './exceptionlog-list.component.less']
})

export class ExceptionLogListComponent{
    @Input() list: ExceptionLog[] = [];

    header: any[] = [
        { label: 'Created', show: true }
        , { label: 'Exception', show: true }
    ];

    constructor() { }

    /**
     * this is from who calls (inner component)
     * @param list
     * @param row
     * @param column
     */
    getData(list: ExceptionLog[], row: number, column: number): string {
        if (list) {
            let e = list[row];
            switch (column) {
                case 0:
                    return e.Created;
                default:
                    return e.Exception;
            }
        }
        else
            return "";
    }
}