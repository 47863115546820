<contracts-menu (onChangeAssetType)="changeAssetType($event)" 
                (outputDownload)="downloadExcelFile()"
                (onReload)="onReload()"
                (onOpenContractBundlePopup)="openContractBundlePopup()"
                [currentType]="currentType"></contracts-menu>
<div class="container-tab-lists">
    <div class="inner-tcontainer-tab">
        <div class="tab-body">
            <div class="tab-content">
                <mat-tab-group (selectedTabChange)="changeAssetType($event); defaults.updateComponentTab($event.tab.textLabel)" #tabGroup >
                    <!--Messages-->
                    <mat-tab label="Contracts">
                        <div>
                            <contractitems #contractItemComponent></contractitems>
                        </div>
                    </mat-tab>
                    <mat-tab *ngIf="defaults.stage !== 3" label="Facilities Managers">
                        <div>
                            <facilitymanager #managerComponent></facilitymanager>
                        </div>
                    </mat-tab>
                    <mat-tab *ngIf="defaults.stage !== 3" label="Feature Groups">
                        <div>
                            <contractbundle #contractBundle (onReload)="onReload()"></contractbundle>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>
</div>
