<div class="HS zoomIn">

    <br />
    <!--<mat-form-field style="width: 40%">
        <mat-label>Filter</mat-label>
        <input matInput
               (keyup)="applyFilter($event)"
               placeholder="Ex. ium"
               #input />
    </mat-form-field>
    <button>
        <mat-icon (click)="AddNewRow()" class="add-button">add_box</mat-icon>
    </button>-->
    <div class="zoomIn heading-wrapper ">
        <div class="contentWidth heading-background " style=" width: 92%;">

            <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                <h4>Health Score</h4>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 search-container">
                <!--<input (keyup)="applyFilter($event.target.value)"
                       class="form-control"
                       #input />-->

                <div *ngIf="defaults.isControllerOwner" class="OrangeButton" style="margin-right: 50px;">
                    <button mat-raised-button (click)="AddNewRow()" [disabled]=" !(assignableFeatures.length > 0) " [matTooltip]="getTooltipText()" aria-label="Reset" class="  btn btn-sm button-color-fix ">
                        <mat-icon class="add-button icon-color-fix">add_box</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="zoomIn weightageTable_wrapper ">
        <div class="contentWidth">
            <div style="height: calc(90vh - 100px); overflow-y: auto;">
                <form [formGroup]="VOForm" autocomplete="off" class="box weightageTable ">
                    <ng-container formArrayName="VORows">

                        <table #table
                               mat-table
                               [dataSource]="dataSource"
                               class="mat-elevation-z8 mat-table-custom">


                            <!-- Name Column -->
                            <ng-container matColumnDef="FeatureName">
                                <th mat-header-cell *matHeaderCellDef>FeatureName</th>
                                <td mat-cell
                                    *matCellDef="let element; let i = index"
                                    [formGroup]="element">
                                    <div>
                                        <ng-select [items]="assignableFeatures"
                                                   formControlName="FeatureName"
                                                   bindLabel="FeatureName"
                                                   bindValue="FeatureName"
                                                   [readonly]="VOForm.get('VORows')?.value[i]?.isEditable"
                                                   class="factoryDefaultOrng dropdown-iconHS"
                                                   [class.dropdown-iconHS]="!(VOForm.get('VORows')?.value[i]?.isEditable)"
                                                   (change)="UpdateFormFeature($event,i)"
                                                   [clearable]="false"
                                                   [(ngModel)]="VOForm.get('VORows')?.value[i].FeatureName"
                                                   [clearOnBackspace]="false">
                                            <ng-template ng-option-tmp let-item="item">
                                                <span class="ng-option-label " [matTooltip]="item.FeatureDescription">{{ item.FeatureName }}</span>
                                            </ng-template>
                                        </ng-select>
                                    </div>
                                </td>
                            </ng-container>

                            <!-- Weight Column -->
                            <ng-container matColumnDef="weight">
                                <th mat-header-cell *matHeaderCellDef>Weight</th>
                                <td mat-cell
                                    *matCellDef="let element; let i = index"
                                    [formGroup]="element">
                                    <mat-form-field style="width: 70px">
                                        <input matInput type="number" min="0"
                                               pattern="\d*\.?\d+"
                                               formControlName="Weight"
                                               [readonly]="VOForm?.get('VORows')?.value[i].isEditable" />

                                    </mat-form-field>
                                    <!-- </span> -->
                                </td>
                            </ng-container>

                            <ng-container *ngIf="defaults.isControllerOwner" matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef>Action</th>
                                <td mat-cell
                                    *matCellDef="let element; let i = index"
                                    [formGroup]="element">

                                    <button mat-icon-button
                                            *ngIf="!VOForm.get('VORows')?.value[i].isEditable"
                                            matTooltip="Cancel Changes"
                                            color="warn"
                                            (click)="ResetSVO(i)">
                                        <mat-icon class="actionButtons">rotate_left</mat-icon>
                                    </button>

                                    <button mat-icon-button
                                            *ngIf="VOForm.get('VORows')?.value[i].isEditable"
                                            matTooltip="Edit"
                                            color="primary"
                                            (click)="EditSVO(VOForm,i)">
                                        <mat-icon class="actionButtons">edit</mat-icon>
                                    </button>

                                    <button mat-icon-button
                                            *ngIf="VOForm.get('VORows')?.value[i].isEditable"
                                            matTooltip="Delete"
                                            color="warn"
                                            (click)="DeleteSVO(VOForm.get('VORows')?.value[i])">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="actionFooter">
                                <th mat-footer-cell *matFooterCellDef colspan="3">
                                    <div *ngIf="defaults.isControllerOwner" class="OrangeButton " style="margin-right: 50px;">
                                        <button mat-raised-button type="submit" (click)="onSave()" class="btn button-color-fix pull-right">
                                            Save
                                            <mat-icon style="color:white !important" class="icon-color-fix">save</mat-icon>
                                        </button>
                                    </div>
                                </th>

                            </ng-container>



                            <tr mat-header-row class="tableheader" *matHeaderRowDef="defaults.isControllerOwner ? displayedColumns : displayedColumns2"></tr>
                            <tr mat-row *matRowDef="let row; columns: defaults.isControllerOwner ? displayedColumns : displayedColumns2;" [class.selectedItemsHS]="!row.value.isEditable"></tr>
                            <tr mat-footer-row *matFooterRowDef="['actionFooter']; sticky: true"></tr>


                            <!-- Row shown when there is no matching data. -->
                            <tr class="zoomIn mat-row" *matNoDataRow>
                                <td *ngIf='Response_State===0 && populationid != undefined' class="mat-cell" colspan="3">
                                    No Data to Display for this Population
                                </td>
                                <td *ngIf='populationid == undefined' class="mat-cell" colspan="3">
                                    The Controller has no Population Assigned



                                <td *ngIf='Response_State===-2 && populationid != undefined' class="mat-cell" colspan="1">
                                    <div class="whitebg card__desc">
                                        <div *ngFor="let _ of [].constructor(4)" class="card__desc-text"></div>
                                    </div>
                                </td>
                                <td *ngIf='Response_State===-2 && populationid != undefined' class="mat-cell" colspan="1">
                                    <div class="whitebg card__desc">
                                        <div *ngFor="let _ of [].constructor(4)" class="card__desc-text"></div>
                                    </div>
                                </td>
                                <td *ngIf='Response_State===-2 && populationid != undefined' class="mat-cell" colspan="1">
                                    <div class="whitebg card__desc">
                                        <div *ngFor="let _ of [].constructor(4)" class="card__desc-text"></div>
                                    </div>
                                </td>

                            </tr>
                        </table>
                    </ng-container>

                </form>
            </div>
            <!--<div class="OrangeButton box " style="padding-top:7px">
                <button mat-raised-button type="submit" (click)="onSave()" class="btn button-color-fix pull-right">
                    Save
                    <mat-icon class="icon-color-fix">save</mat-icon>
                </button>
            </div>-->
        </div>

    </div>
</div>


