import { Component, Inject, Input, EventEmitter, Output, ViewChild, OnChanges, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Utils } from "../../utils/utils";
import { DialogService } from "../../services/dialog.service";
import { DefaultValuesService } from "../../services/defaultvalues.service";

import { SaveService } from "../../services/save.service";
import { MatTable, MatTableDataSource } from "@angular/material/table";

@Component({
    selector: "businesskey-list",
    templateUrl: './businesskey-list.component.html',
    styleUrls: ['../../style/list.component.less', './businesskey-list.component.less']
})

export class BusinessKeyListComponent implements OnInit, OnChanges {
    @Input('list') list: BusinessKey[] = [];
    @Input('language') language: Language = <Language>{};
    @Input() isRowEditMode = false;

    selected: BusinessKey = <BusinessKey>{};

    @Output() onRefresh = new EventEmitter<{}>();
    clonedList: { [s: string]: BusinessKey; } = {};

    columnsToDisplay = ['bk', 'desc', 'descref', 'actions'];

    public dataSource = new MatTableDataSource<BusinessKey>();
    @ViewChild(MatTable, { static: true }) table!: MatTable<any>;


    subscriptionLanguage: any;
    subscriptionTenant: any;
    constructor(
        private defaults: DefaultValuesService
        , private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , private dialog: DialogService
        , private saveService: SaveService
    ) {
        this.dialog.tthis = this;


    }

    ngOnInit() {

        this.dialog.tthis = this;
        this.getHeader()
        this.subscriptionLanguage = this.defaults.languageMessageChanges$.subscribe(() => {
            this.getHeader()
        });
        this.subscriptionTenant = this.defaults.tenantMessageChanges$.subscribe(() => {
            this.getHeader()
        });
    }

    ngOnChanges() {
        this.dataSource.data = this.list;
    }

    getHeader() {
        if (this.isToShowReference()) {
            if (this.defaults.isadminuser || !this.defaults.isReadonlyUser)
                this.columnsToDisplay = ['bk', 'desc', 'descref', 'actions'];
            else
                this.columnsToDisplay = ['bk', 'desc', 'descref'];
        }
        else {
            if (this.defaults.isadminuser || !this.defaults.isReadonlyUser)
                this.columnsToDisplay = ['bk', 'desc', 'actions'];
            else
                this.columnsToDisplay = ['bk', 'desc'];
        }
    }

    onRowEditInit(businessKey: BusinessKey) {
        this.saveService.addListener(this);
        this.clonedList[businessKey.BK] = { ...businessKey };
        if (Object.keys(this.clonedList).length !== 0) this.onShowSavebutton()
    }

    onShowSavebutton() {
        this.saveService.showSaveButton();
    }

    notTranslatedClass(text: string): string {
        return Utils.notTranslatedClass(text);
    }

    isToShowReference(): boolean {
        return this.defaults.language.LanguageId != Utils.getDefaultLaguangeID();
    }

    isEditingRow(element: BusinessKey) {
        return this.clonedList[element.BK]
    }


    onRowEditCancel(businessKey: BusinessKey) {
        this.dataSource.data[this.dataSource.data.findIndex(x => x.BK === businessKey.BK)] = this.clonedList[businessKey.BK];
        delete this.clonedList[businessKey.BK];

        if (Object.keys(this.clonedList).length === 0) this.saveService.showSaveButton(false)
        this.table.renderRows();
    }


    onSave() {
        for (let i in this.clonedList) {
            const index = this.dataSource.data.findIndex(x => x.BK === this.clonedList[i].BK)
            if (Object.entries(this.clonedList[i]).toString() !== Object.entries(this.dataSource.data[index]).toString()) {
                this.http
                    .post<BusinessKey>(
                        this.baseUrl + Utils.getBusinessKeyAPI() + "translate"
                        , this.dataSource.data[index]
                    )
                    .subscribe(
                        () => {
                            this.onRowEditCancel(this.clonedList[i])
                            this.onRefresh.emit({});
                        }
                        , error => {
                            this.dialog.showErrorDialog(error)
                            this.onRowEditCancel(this.clonedList[i])
                            delete this.clonedList[this.clonedList[i].BK];
                            this.table.renderRows();
                        }
                    );
            }
            else {
                delete this.clonedList[this.clonedList[i].BK];
            }
        }
    }

}