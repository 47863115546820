<div style="display:flow-root">
    <div style="float:left; width:65%">
        <mat-form-field class="search-input">
            <input placeholder="Filter"
                   matInput
                   [matChipInputFor]="chipList"
                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                   [matChipInputAddOnBlur]="addOnBlur"
                   (matChipInputTokenEnd)="applyFilter($event)">
            <mat-chip-list #chipList>
            </mat-chip-list>
        </mat-form-field>
        <mat-chip *ngFor="let item of searchItems" [selectable]="selectable"
                  [removable]="removable" (removed)="remove(item)">
            {{item.name}}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>

    </div>
    <div style="text-align:right; float:right; width:35%">
        <h2 style="cursor:pointer; display:block;" class="flex-aligned-div" (click)="openDeploymentInformationPopup()">
            Deployment information
            <mat-icon data-html="true" style="color:white">info</mat-icon>
        </h2>
        
    </div>
</div>
<div class="example-container">
    <table mat-table
           [dataSource]="dataSource"
           multiTemplateDataRows
           matSort
           (matSortChange)="sortData($event)"
           class="mat-elevation-z8 mat-table-custom" id="operationmodetabledesc">

        <ng-container matColumnDef="offset">
            <th mat-header-cell *matHeaderCellDef> Offset </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.Offset"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="value"> Value </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.Value | bold: dataSource.filter"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="moderef">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="moderef"> Mode Ref </th>
            <td mat-cell *matCellDef="let element" pEditableColumn>
                <p [innerHTML]="element.ModeReference | bold: dataSource.filter" [ngClass]="notTranslatedClass(element.ModeReference)"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="mode">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="mode"> Mode </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.Mode | bold: dataSource.filter" [ngClass]="notTranslatedClass(element.Mode)" *ngIf="!isEditingRow(element)"></p>
                <mat-form-field floatLabel="never" [ngClass]="notTranslatedClass(element.Mode)" *ngIf="isEditingRow(element)">
                    <input matInput [value]="element.Mode" [(ngModel)]="element.Mode">
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container matColumnDef="descref">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="descref"> Description Ref </th>
            <td mat-cell *matCellDef="let element" pEditableColumn>
                <p [innerHTML]="element.DescriptionReference | bold: dataSource.filter" [ngClass]="notTranslatedClass(element.ModeReference)"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="desc"> Description </th>
            <td mat-cell *matCellDef="let element" pEditableColumn>
                <p [innerHTML]="element.Description | bold: dataSource.filter" [ngClass]="notTranslatedClass(element.Mode)" *ngIf="!isEditingRow(element)"></p>
                <mat-form-field floatLabel="never" [ngClass]="notTranslatedClass(element.Description)" *ngIf="isEditingRow(element)">
                    <input matInput [value]="element.Description" [(ngModel)]="element.Description">
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button *ngIf="!isEditingRow(element)" matTooltip="Edit Row"
                        (click)="onRowEditInit(element)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button *ngIf="isEditingRow(element)" matTooltip="Cancel Row"
                        (click)="onRowEditCancel(element)">
                    <mat-icon>rotate_left</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; let i = dataIndex;
            columns: columnsToDisplay;"
            class="item"
            [class.alternative2]="isEditingRow(element)">
        </tr>
    </table>
</div>
