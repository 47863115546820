import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, NgModule, OnChanges } from '@angular/core';
import { DefaultValuesService } from '../../services/defaultvalues.service';
import { NavItem } from './navitem';
const API_VERSION = "api/generic/version";

@Component({
    selector: 'sidenav-menu',
    templateUrl: 'sidenav.component.html',
    styleUrls: ['sidenav.component.less','../../style/generic.component.less'],
})
export class SidenavComponent implements OnChanges {
    @Input('selectedItem') selectedItem: number = 0

    isExpanded = false;
    subscriptionTenant: any;

    menu: NavItem[] | undefined
    version: string | undefined = '000.00';

    constructor(public defaults: DefaultValuesService,
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string
    ) {
        const url = this.baseUrl + API_VERSION;

        this.http.get<string>(url).subscribe(result => {
            this.version = result;
        }, error => console.error(error));
    }
    ngOnChanges() {
        if (this.selectedItem === -1)
            this.selectedItem = Number(localStorage.getItem('currentSelectedItem'));
    }
    async ngOnInit() {

    }

    setSelectedItem(selectedItemNumb: number) {
        this.selectedItem = selectedItemNumb;
        this.defaults.selectedItem = this.selectedItem
        localStorage.setItem('currentSelectedItem', this.selectedItem.toString());
    }

    isExternalLink(link) {
        const httpRegex = /^http:\/\//i; // Match "http://" with case-insensitivity
        const httpsRegex = /^https:\/\//i; // Match "https://" with case-insensitivity
        return httpRegex.test(link) || httpsRegex.test(link);
    }
    externalRedirect(link) {
        window.open(link, "_blank");
    }
}