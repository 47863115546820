import { Component, Inject, OnInit } from '@angular/core';
import { DefaultValuesService } from '../../../../../services/defaultvalues.service';
import { Subject } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { Utils } from '../../../../../utils/utils';
import { HttpClient } from '@angular/common/http';
import { AssignContactPopupComponent } from './assigncontact-popup/assigncontact-popup.component';
import { CreateContactPopupComponent } from './createcontact-popup/createcontact-popup.component';
import { ToastrService } from 'ngx-toastr';


@Component({
    selector: 'branchcontact',
    templateUrl: './branchcontact.component.html',
    styleUrls: ['./branchcontact.component.less',
        '../branchexcellenceadmin.component.less',
        '../../../../../style/new-generic-styles.component.less']
})
export class BranchContactComponent implements OnInit {

    protected _onDestroy = new Subject<void>();
    public dataSource = new MatTableDataSource<BranchContact>();
    columnsToDisplay = ['ContactName', 'ContactEmail', 'ChangedAtUtc', 'ChangedBy', 'actions'];
    timeoutFunction: any;
    subscriptionManagementId: any;
    subscriptions: any[] = [];

    list: BranchContact[] = [];

    constructor(
        private defaults: DefaultValuesService
        , private dialogForm: MatDialog
        , private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , private toastrService: ToastrService
    ) {
    }

    ngOnInit() {
        this.subscriptionManagementId = this.defaults.managementIdMessageChanges$.subscribe(() => {
            this.getBranchContacts()
        });
        this.getBranchContacts();
    }

    // unsubscribe to events when component is destroyed
    ngOnDestroy(): void {
        this.subscriptionManagementId.unsubscribe();
    }

    getBranchContacts() {
        Utils.httpGetAdminBranchContacts(
            this.http,
            this.baseUrl,
            this,
            function (tthis: BranchContactComponent, list: any) {
                tthis.dataSource.data = list
            },
            null

        )
    }

    onRemoveContact(id: number) {
        Utils.httpUnassignContact(
            this.http,
            this.baseUrl,
            id,
            this,
            function (tthis: BranchContactComponent, result: any) {
                tthis.getBranchContacts();
                if (result)
                    tthis.toastrService.success('Contact was unassigned successfully!');
                else
                    tthis.toastrService.error('Contact could not be unassigned. Please, contact with administrator');
            }, null
        )
    }

    openCreateContactPopup() {
        const dialog = this.dialogForm.open(CreateContactPopupComponent, {
            panelClass: ['custom-mat-dialog_v2'],
            disableClose: true,
            data: {},
        })
    }

    openAddContactPopup() {
        const dialog = this.dialogForm.open(AssignContactPopupComponent, {
            panelClass: ['custom-mat-dialog_v2'],
            disableClose: true,
            data: {},
        })
        dialog.afterClosed().subscribe(() => {
            this.getBranchContacts()
        })
    }


}
