<!--
 ######################################################################
    table
  -- thead
  ---- th(expand-icon); th(row with the headers); th(editButton)
  ---------------------- tr
  ------------------------ th* (headers)
  ---------------------- tr
  ------------------------ th* (forms)
  -- tbody
  -- tr*
  ---- td(expand-icon); td(tr row with the data); td(editButton)
  ---------------------- tr
  ------------------------ th* (data)
  ---------------------- tr
  ------------------------ th* (expand template)
 ######################################################################
 -->

<button mat-icon-button (click)="onNewElement.emit({})" matTooltip="New Row" *ngIf="onNewElement.observers.length!=0">
    <mat-icon aria-label="Add new element to the current table">add_circle</mat-icon>
</button>
<button mat-icon-button (click)="onRefresh.emit({})" matTooltip="Refresh the current table" *ngIf="onRefresh.observers.length!=0">
    <mat-icon>refresh</mat-icon>
</button>
<ng-container *ngTemplateOutlet="additionalToolbarActionsTemplate!"></ng-container>
<table style="width: auto;" class="mat-table-custom">
    <thead>
        <tr *ngIf="showSearch">
            <!-- search input boxes -->
            <th>&nbsp;</th>
            <th *ngIf="selectable">&nbsp;</th>
            <th *ngIf="rowEditTemplate||additionalRowActionsTemplate||onDelete.observers.length!=0">&nbsp;</th> <!-- to edit button -->
        </tr>
    </thead>
</table>




<!--<div id="{{idtable}}" class="divTable">-->
<table id="{{idtable}}" class="fixed_headers" style="width: auto;">
    <!--<div class="divTableHeading">-->
    <thead>
        <tr>
            <th>
                <table>
                    <thead>
                        <tr>
                            <th *ngIf="!isToShowSearchButton()">&nbsp;</th>
                            <th *ngIf="isToShowSearchButton()">
                                <button class="button-filter" type="button" (click)="showSearchForm()">&nbsp;</button>
                            </th>
                            <th *ngIf="selectable"><input type="checkbox" (click)="onCheckedAll($event)" /></th> <!-- to select -->
                            <th *ngFor="let l of header; index as i" title="{{l.title}}">
                                <!--<ng-container *ngTemplateOutlet="headerdata"></ng-container>-->
                                <span *ngIf="!l.show">&nbsp;</span>
                                <div class="search" *ngIf="l.show">{{l.label}}</div>
                            </th>
                            <th *ngIf="rowEditTemplate||additionalRowActionsTemplate||onDelete.observers.length!=0">&nbsp;</th> <!-- to edit button -->
                        </tr> <!--divTableRow-->
                    </thead>
                </table>
            </th>
        </tr>
    </thead> <!--divTableHeading-->

    <tbody>
        <!--class="divTableBody"-->
        <tr *ngIf="newRow">
            <ng-container *ngTemplateOutlet="rowNewTemplate!"></ng-container>
        </tr>

        <tr *ngFor="let d of data; index as r" id="row-{{idtable}}-{{r}}">
            <!--class="divTableRow"-->
            <!-- table data -->
            <td>
                <table>
                    <tbody class="inner">
                        <!-- row to show the form to create a record -->
                        <tr *ngIf="editing[r]">
                            <td [attr.colspan]="header.length" style="width:auto;">
                                <ng-container *ngTemplateOutlet="rowEditTemplate!; context:{value:d, index:r}"></ng-container>
                            </td>
                        </tr>

                        <tr *ngIf="!editing[r]">
                            <td *ngIf="collapsible" (click)="onExpandRow($event, r)">
                                <span *ngIf="!expanded[r]"><strong style="font-size:large;">+</strong></span>
                                <span *ngIf="expanded[r]"><strong style="font-size:large;">-</strong></span>
                            </td>
                            <td *ngIf="!collapsible">&nbsp;</td> <!-- to expand -->
                            <td *ngIf="selectable"><input type="checkbox" [checked]="isRowChecked(r)" (click)="onCheck($event, r)" /></td> <!-- to select -->

                            <td *ngFor="let l of header; index as c" [ngClass]="getClass(r, c)" title="{{l.label}}: {{l.title}}" (click)="onCellClick(r, c)">
                                <span *ngIf="!l.show">&nbsp;</span>
                                <span *ngIf="!l.checkbox&&l.show">{{getData(r, c)}}</span>
                                <span *ngIf="l.checkbox&&l.show"><input type="checkbox" [checked]="isInputChecked(r, c)" value="1" onclick="return false;" /></span>
                            </td>

                            <td *ngIf="rowEditTemplate||additionalRowActionsTemplate||onDelete.observers.length!=0" class="button-actions">
                                <ng-container matColumnDef="Actions">
                                    <ng-container *ngTemplateOutlet="additionalRowActionsTemplate!; context:{value:d, index:r}"></ng-container>
                                    <button mat-icon-button (click)="onRowNewChild(d,r)" matTooltip="new Child" *ngIf="collapsible&&expanded[r]&&onNewChild.observers.length!=0">
                                        <mat-icon aria-label="Add new Child">add</mat-icon>
                                    </button>
                                    <button mat-icon-button (click)="onRowEdit(d,r)" matTooltip="Edit Row" [disabled]="editing[r]" *ngIf="rowEditTemplate">
                                        <mat-icon aria-label="Edit">edit</mat-icon>
                                    </button>
                                    <button mat-icon-button #deleteRowButton id="deleteRowButton-{{r}}" (click)="onRowDelete(d,r)" matTooltip="Delete Row" *ngIf="onDelete.observers.length!=0">
                                        <mat-icon aria-label="Delete">delete</mat-icon>
                                    </button>
                                </ng-container>

                                <!--<button *ngIf="collapsible&&expanded[r]&&onNewChild.observers.length!=0"
                                        type="button"
                                        title="new Child"
                                        class="btn btn-default"
                                        (click)="onRowNewChild(d,r)">
                                    +
                                </button>-->
                                <!--<button *ngIf="rowEditTemplate"
                                        type="button"
                                        class="btn btn-default"
                                        [disabled]="editing[r]"
                                        (click)="onRowEdit(d,r)">
                                    {{editLabel}}
                                </button>-->

                                <!--<button *ngIf="isToShowDeleteButton()"
                                        type="button"
                                        title="Delete Record"
                                        class="btn btn-danger"
                                        (click)="onRowDelete(d,r)">
                                    Delete
                                </button>-->
                            </td>
                        </tr>
                        <tr [ngClass]="expandrowclass[r]">
                            <td>&nbsp;</td> <!-- collapsible-->
                            <td *ngIf="selectable">&nbsp;</td> <!-- selectable-->

                            <td [attr.colspan]="header.length" class="expand-container" style="width:100%; border-color: black; border-style:solid; border-width:1px;">
                                <ng-container *ngTemplateOutlet="rowExpandTemplate!; context:{value:d, index:r}"></ng-container>
                            </td>

                            <td *ngIf="rowEditTemplate||additionalRowActionsTemplate||onDelete.observers.length!=0">&nbsp;</td> <!-- buttons -->

                        </tr>
                    </tbody>
                </table>
            </td>





            <!-- divTable data -->
            <!--<div class="divTableRow" id="rowdata-{{idtable}}" *ngIf="editing[r]">

            </div>

            <div class="divTableRow" id="rowdata-{{idtable}}" *ngIf="!editing[r]">
                <div class="divTableCell" *ngFor="let l of header; index as c" [ngClass]="getClass(r, c)" title="{{l.label}}">
                    <span *ngIf="!l.checkbox">{{getData(r, c)}}</span>
                    <span *ngIf="l.checkbox"><input type="checkbox" [checked]="isInputChecked(r, c)" value="1" onclick="return false;" /></span>
                </div>
            </div>-->
            <!--divTableRow-->
            <!--<div class="divTableRow" [ngClass]="expandrowclass[r]">

            </div>-->

        </tr> <!-- divTableRow -->
    </tbody> <!--divTableBody-->
</table> <!--divTable-->
