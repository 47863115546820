import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Component, ElementRef, ViewChild, Inject, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Injectable, AfterViewInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';


import { HttpClient } from '@angular/common/http';

import { ActivatedRoute, Params } from '@angular/router';
import { UMPCategory, Utils } from '../../../../../utils/utils';
import { SpinnerOverlayService } from '../../../../../services/spinner-overlay.service';
import { SaveService } from '../../../../../services/save.service';



interface UMPBranchNode {
    selected?: boolean;
    disabled?: boolean;
    indeterminate?: boolean;
    parent?: UMPBranchNode;


    id: string,
    text: string,
    type: string,
    icon: string,
    state: any,
    securityStatus: number,
    isAssigned: boolean,
    isTenantNode: boolean,
    canAssign: boolean,
    canUnassign: string,
    children: UMPBranchNode[]

}




@Component({
    selector: "umpbranchhierarchy",
    templateUrl: './umpbranchhierarchy.component.html',
    styleUrls: ['./umpbranchhierarchy.component.less', '../../../../../style/new-generic-styles.component.less'],

})

export class UMPBranchHierarchy implements OnInit {
    @Input() public groupmgmtcallback: Function | undefined;
    @Input() reload: number = -1;
    @Output() secondaryTenUpdate = new EventEmitter<{ selTen: string[], checked: boolean }>();

    userId = '';
    public TREE_DATA: UMPBranchNode[] = [];/*** */

    public selectedNodes: string[] = [];
    public unSelectedNodes: string[] = [];

    public treeControl: NestedTreeControl<UMPBranchNode>;
    public dataSource = new MatTreeNestedDataSource<UMPBranchNode>();

    public searchString = '';
    public showOnlySelected = false;

    Tree_Response_State: number = -2; //0 -> empty , 1 -> correct and not empty ,-1 -> error, -2 -> Calling API

    isCallSucceed: number = 0;

    data: TreeManagement<UMPCategory> = {
        SelectedBranches: [],
        UnselectedBranches: [],
        UmpCategory: UMPCategory.BranchHierarchy
    }
    constructor(
        private toastrService: ToastrService,
        private route: ActivatedRoute,
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string,
        private spinnerOverlayService: SpinnerOverlayService,
        private saveService: SaveService) {

        this.treeControl = new NestedTreeControl<UMPBranchNode>((node) => node.children)


    }
    ngOnInit() {
        this.route.params.subscribe(
            (params: Params) => {
                this.resetAll();
            });

    }

    ngOnChanges() {
        if (this.userId !== '')
            this.funcGetUserBranches();
    }
    resetAll() {
        this.userId = '';
        this.treeControl = new NestedTreeControl<UMPBranchNode>((node) => node.children);
        this.dataSource = new MatTreeNestedDataSource<UMPBranchNode>();
        this.userId = this.route.snapshot.paramMap.get('userId') || '';
        this.funcGetUserBranches();
    }

    private preselect() {
        this.unSelectedNodes = [] as string[];
        for (let i = 0; i < this.treeControl.dataNodes.length; i++) {

            if (this.treeControl.dataNodes[i].canUnassign) {
                this.itemToggle(true, this.treeControl.dataNodes[i]);
            }
            else
                this.unSelectedNodes.push(this.treeControl.dataNodes[i].id);
            if (this.treeControl.dataNodes[i].children) {
                const alldecendents = this.treeControl.getDescendants(this.treeControl.dataNodes[i]);
                for (let j = 0; j < alldecendents.length; j++) {
                    if (alldecendents[j].canUnassign) {
                        this.itemToggle(true, alldecendents[j]);
                    }
                    else
                        this.unSelectedNodes.push(alldecendents[j].id);
                }
            }

        }
    }
    public hasChild = (_: number, node: UMPBranchNode) =>
        !!node.children && node.children.length > 0;

    private setParent(node: UMPBranchNode, parent: UMPBranchNode | undefined) {
        node.parent = parent;
        if (node.children) {
            node.children.forEach((childNode) => {
                this.setParent(childNode, node);
            });
        }
    }

    private checkAllParents(node: UMPBranchNode) {
        if (node.parent) {
            const descendants = this.treeControl.getDescendants(node.parent);
            node.parent.indeterminate = descendants.some((child) => child.selected);
            this.checkAllParents(node.parent);
        }
    }

    public itemToggle(checked: boolean, node: UMPBranchNode, manualUpdate: boolean = false) {
       
        if (manualUpdate) {
            this.saveService.addListener(this)
            this.saveService.showSaveButton();
            this.searchTenant(checked,node);
        }
        node.selected = checked;
        if (node.children && checked) {
            node.indeterminate = false;
            this.itemUnselect(node);

        }
        if (node.children) {
            this.itemDisable(checked, node);
        }
        if (manualUpdate) {
            if (checked) {
                this.data.UnselectedBranches = this.data.UnselectedBranches.filter(x => x !== node.id)
                if (this.unSelectedNodes.find(e => e === node.id))
                    this.data.SelectedBranches.push(node.id)
            }
            else {
                this.data.SelectedBranches = this.data.SelectedBranches.filter(x => x !== node.id)
                if (node.canUnassign)
                    this.data.UnselectedBranches.push(node.id)
            }
        }
        this.checkAllParents(node);
    }

    private searchTenant(checked: boolean, node: UMPBranchNode) {
        if (node.isTenantNode) {
            this.secondaryTenUpdate.emit({ selTen: [this.extractText(node.text)], checked });
        }
        else {
            this.searchTenantInDesc(checked,node);
        }
    }
    private searchTenantInDesc(checked: boolean, node: UMPBranchNode) {
        const descendants = this.treeControl.getDescendants(node);
        var tenNode=descendants.filter(node => node.isTenantNode);
        if (tenNode.length > 0) {
            var selTen = tenNode.map((node) =>  this.extractText(node.text))
            this.secondaryTenUpdate.emit({ selTen, checked });
        }
        else {
            this.searchTenantInParent(checked,node);
        }
    }
    private searchTenantInParent(checked: boolean, node: UMPBranchNode) {
        if (node.parent) {
            node.parent.isTenantNode ? (this.secondaryTenUpdate.emit({ selTen: [this.extractText(node.parent.text)], checked })): null;
            this.searchTenantInParent(checked,node.parent);
        }
    }

    extractText = (input: string) =>
        input.replace(/\s+/g, '').split('(')[0];

    private itemUnselect(node: UMPBranchNode) {
        const descendants = this.treeControl.getDescendants(node);
        descendants.forEach(node => { node.selected ? this.data.UnselectedBranches.push(node.id):null; node.indeterminate = false; node.selected = false; });
    }
   

    private itemDisable(checked: boolean, node: UMPBranchNode) {
        const descendants = this.treeControl.getDescendants(node);
        descendants.forEach(node => node.disabled = checked);
    }

    onSave() {
        if (this.data.SelectedBranches.length > 0 || this.data.UnselectedBranches.length > 0) {
            this.spinnerOverlayService.setFalseskipInterceptor()
            Utils.httpPostTreeGroupsByType(
                this.http
                , this.baseUrl
                , this.data
                , this.userId
                , this
                , function (tthis: UMPBranchHierarchy, data: any) {
                    tthis.data.SelectedBranches = []
                    tthis.data.UnselectedBranches = []
                    tthis.spinnerOverlayService.setTrueskipInterceptor()
                    tthis.funcNotify();
                    tthis.funcGetUserBranches();
                    if (tthis.groupmgmtcallback)
                        tthis.groupmgmtcallback(data);
                }
            )
        }
    }

    public hideLeafNode(node: UMPBranchNode): boolean {
        return new RegExp(this.searchString, 'i').test(node.text) === false;
    }


    public hideParentNode(node: UMPBranchNode): boolean {
        if (
            !this.searchString ||
            node.text.toLowerCase().indexOf(this.searchString.toLowerCase()) !==
            -1
        ) {
            return false
        }
        const descendants = this.treeControl.getDescendants(node)

        if (
            descendants.some(
                (descendantNode) =>
                    descendantNode.text
                        .toLowerCase()
                        .indexOf(this.searchString.toLowerCase()) !== -1
            )
        ) {
            return false
        }

        return true
    }
    funcGetUserBranches() {
        this.Tree_Response_State = -2;
        this.TREE_DATA.splice(0, this.TREE_DATA.length);
        this.treeControl.dataNodes = [];
        Utils.httpGetUserBranches(
            this.http
            , this.baseUrl
            , this.userId
            , this
            , (tthis: UMPBranchHierarchy, data: any) => {


                tthis.TREE_DATA = [data.rootTenantNodes];

                tthis.treeControl.dataNodes = tthis.TREE_DATA;
                tthis.dataSource.data = tthis.TREE_DATA;
                //tthis.selectedNodes = data.rootTenantNodes;
                Object.keys(tthis.dataSource.data).forEach((key) => {
                    tthis.setParent(tthis.dataSource.data[key], undefined);

                }
                )
                tthis.preselect();


            });
    }
    funcNotify() {
        if (this.isCallSucceed === 1) {
            this.toastrService.success('Update Branch Hierarchy request sent successfully !', 'Update Branch Hierarchy Request!');
        }
        else if (this.isCallSucceed === -1) {
            this.toastrService.error('Update Branch Hierarchy request not sent successfully !', 'Update Branch Hierarchy Request!');
        }
    }
}