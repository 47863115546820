import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Utils } from '../../../utils/utils';
import { SaveService } from '../../../services/save.service';
import { DialogService } from '../../../services/dialog.service';

@Component({
    selector: 'fingerprintrules-menu',
    templateUrl: './fingerprintrules-menu.component.html',
    styleUrls: ['../../../style/menu.component.less']
})

export class FingerprintRulesMenuComponent {
    //title: string;

    @Output() onReload = new EventEmitter<{}>();

    constructor(private activatedRoute: ActivatedRoute,
        private router: Router
        , @Inject('BASE_URL') private baseUrl: string
        , public saveService: SaveService
        , private dialog: DialogService
    ) {

    }

    onUpload() {
        this.router.navigate(["file/" + Utils.getFileUploadOpModeValue()]);
    }

}
