import { Component } from "@angular/core";
import { DefaultValuesService } from "../../../services/defaultvalues.service";

@Component({
    selector: "controllertypes-field",
    templateUrl: './controllertypes-field.component.html',
    styleUrls: ['../../../style/container-tab-list.component.less']
})

export class ControllerTypesFieldComponent {

    title: string;

    constructor(
        public defaults: DefaultValuesService
    ) {
        this.title = "ControllerTypes Options";
    }

}