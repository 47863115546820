import { HttpClient } from "@angular/common/http";
import { Component, Inject, EventEmitter, Output, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { CountryBackendEntity } from "../../../interfaces/countrybackendentity";
import { DefaultValuesService } from "../../../services/defaultvalues.service";
import { DialogService } from '../../../services/dialog.service';
import { SaveService } from '../../../services/save.service';
import { Utils } from '../../../utils/utils';
import { AzDataFactoryFormComponent } from "../azdatafactory/azdatafactoryform/azdatafactoryform.component";
import { AzDataFactoryListComponent } from "../azdatafactory/azdatafactorylist/azdatafactorylist.component";
import { CdpWikiListComponent } from "../cdpwiki/cdpwiki-list.component";


@Component({
    selector: 'assets-menu',
    templateUrl: './assets-menu.component.html',
    styleUrls: ['../../../style/menu.component.less']
})

export class AssetsMenuComponent {

    @Output() outputDownload = new EventEmitter<{}>();
    @Output() onReload = new EventEmitter<{}>();
    @Output() onChangeAssetType = new EventEmitter<{}>();

    @Input('currentType') currentType: CountryBackendEntity = CountryBackendEntity.Elevator

    constructor(private http: HttpClient
        , private activatedRoute: ActivatedRoute
        , private router: Router
        , @Inject('BASE_URL') private baseUrl: string
        , private dialogForm: MatDialog
        , public saveService: SaveService
        , private dialog: DialogService
        , public defaults: DefaultValuesService
    ) {
    }


    showMenuView($event) {
        this.currentType = $event.index;
        this.onChangeAssetType.emit(this.currentType)
    }

    ngOnChanges() {
    }

    public get CountryBackendEntity(): typeof CountryBackendEntity {
        return CountryBackendEntity;
    }

    onUpload() {
        switch (this.currentType) {
            case CountryBackendEntity.Elevator:
                this.router.navigate(["file/" + Utils.getFileUploadElevators()]);
                break;
            case CountryBackendEntity.Escalator:
                this.router.navigate(["file/" + Utils.getFileUploadEscalators()]);
                break;
            case CountryBackendEntity.OtherAsset:
                this.router.navigate(["file/" + Utils.getFileUploadOtherAssets()]);
                break;
            default: break;
        }
    }

    onUploadConfigIt() {
        switch (this.currentType) {
            case CountryBackendEntity.Elevator:
                this.router.navigate(["file/" + Utils.getFileUploadConfigIt()]);
                break;
            default: break;
        }
    }

    openAzureDataFactoryTriggerModal(isCustomerPortal: boolean) {
        this.dialogForm.open(AzDataFactoryFormComponent, {
            panelClass: 'custom-mat-dialog',
            disableClose: true,
            data: { "isCustomerPortal": isCustomerPortal, "type": CountryBackendEntity[this.currentType], "entity": isCustomerPortal ? "Customer Portal" : "MasterData" }
        });
    }

    openDocumentationModal() {
        this.dialogForm.open(CdpWikiListComponent, {
            panelClass: 'custom-mat-dialog',
            data: { "entity": this.currentType }
        });
    }

    showLastAssetsMasterDataRuns() {
        const end = new Date();
        const start = new Date(new Date().setDate(end.getDate() - 30));
        Utils.httpGetLastPipelineRunsAzdf(
            this.http,
            this.baseUrl,
            'MasterData',
            CountryBackendEntity[this.currentType],
            start,
            end,
            this,
            function (tthis: AssetsMenuComponent, list: AzDataFactoryRun[]) {
                tthis.dialogForm.open(AzDataFactoryListComponent, {
                    panelClass: 'custom-mat-dialog',
                    disableClose: true,
                    data: { "type": CountryBackendEntity[tthis.currentType], "list": list, "entity": "MasterData" }

                })
            })
    }
}
