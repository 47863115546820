import { Component, Output, OnInit, OnChanges, Inject, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Utils } from '../../utils/utils';
import { WaitingService } from '../../services/waiting.service';

const UNCLICK = "unclick";
const CLICKED = "clicked";
const BUTTON_SEARCH = "button-search";



//[ngStyle] = "{ width: getStyle(i) }"
@Component({
    selector: 'table-fixed-header-search',
    templateUrl: './table-fixed-header-search.component.html',
    styleUrls: ['../../style/list-fixed-header.component.less']
})

export class TableFixedHeaderSearchComponent implements OnInit, OnChanges {
    @Input('id-table') idtable: string = "";
    /**
     * header object to design table controll
     *
     * signature:
     *   {label: string, show: boolean}
     */   
    @Input('header') header: any[] = [];
    @Input('data') data: any[] = [];
    @Input('language') language: Language = <Language>{};

    /**
     * callback to get value in row, column
     *
     * signature:
     *   function(data:any[], row:number, column:number){}
     */
    @Input('getValue') getDataValue: any | undefined;
    /**
     * callback to get class for cell, column
     * 
     * signature:
     *   function(row:number, column:number, text:string){}
     */
    @Input('getClass') getClassStyle: any | undefined;
    /**
     * edit button label
     * 
     * signature:
     *   function(text:string, column:number){}
     */
    @Input('edit-button-label') editLabel: string = "Edit";

    /**
     * collapsible
     * 
     * true if table is to collapsible
     */
    @Input('collapsible') collapsible: boolean = false;


    /**
     * events
     */ 
    @Output() onSearch = new EventEmitter < { ShowRef: boolean, Index: number, Value: any }>();
    @Output() onReset = new EventEmitter<{}>();
    @Output() onSelect = new EventEmitter<any>();
    @Output() onEdit = new EventEmitter<any>();
    @Output() onInitCompleted = new EventEmitter<TableFixedHeaderSearchComponent>();

    //searchvalues: string[] = [];

    //searchtype: string = "number";
    classform: string = "";
    //classwaiting: string = "";
    //classlist: string = "";

    filter: string = "";

    //classname: string = "";
    //classdialog: string = Utils.getDialogHideClassName();
    //type: number = Utils.getSuccessValue();
    //message: string | undefined;

    constructor(
        private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , private waiting: WaitingService
    ) {
    }

    ngOnInit() {
        /**
         * do not show waiting in the beginning, it will work bad
         * //this.waiting.toWait(true);
         * */

        this.onInitCompleted.emit(this);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.onSearch)
            this.classform = (this.onSearch.observers.length == 0 ? Utils.getDialogHideClassName() : "");

        if (changes['data'] != undefined && changes['data'].currentValue != changes['data'].previousValue)
            this.waiting.toWait(false);
    }

    getClass(row: number, column: number): string {
        if (this.getClassStyle)
            return this.getClassStyle(row, column, this.data);
        return "";
    }

    getClassSearchHeader(column: number): string {
        if (this.header && !this.header[column].show)
            return Utils.getDialogHideClassName();
        return "";
    }

    getData(row: number, column: number): string {
        if (this.getDataValue)
            return this.getDataValue(this.data, row, column);
        else
            return "";
    }

    isToShowReference(): boolean {
        return this.language.LanguageId != Utils.getDefaultLaguangeID();
    }

    isToShowEditButton(): boolean {
        return this.onEdit.observers.length != 0;
    }

    isInputChecked(row: number, column: number): boolean {
        var value = this.getData(row, column);
        return value == "1" ? true : false;
    }



    //getColumnWidth(column: number): string {
    //    return this.header[column].width;
    //}

    //isToShowColumn(column: number): boolean {
    //    return this.header[column].show;
    //}

    //onOk() {
    //    Utils.hideDialog(this);
    //}


    //onSearchColumn(elem: any, column: number) {
    //    if (elem.stopPropagation) {
    //        elem.stopPropagation();
    //    }
    //    elem.preventDefault();

    //    //var elem = document.getElementById("search-header-" + column.toString());

    //    if (elem && elem.keyCode == 13) {
    //        //    this.onSearch.emit({ Index: column, Value: elem.target.value });
    //        console.log("Enter pressed!!!!");
    //    }
    //    //else
    //    //    return true;
    //}
    //storeDataSearch() {
    //    for (var i = 0; i < this.header.length; ++i) {
    //        var elem = <HTMLInputElement>document.getElementById("search-header-" + i.toString());
    //        this.searchvalues[i]=elem.value
    //    }
    //}

    onResetList() {
        // Allen: Need to be modified
        this.waiting.toWait(true);
        this.filter = "";
        this.onReset.emit({});
        this.waiting.toWait(false);
    }

    onRowEdit(obj: any) {
        if (this.onEdit)
            this.onEdit.emit(obj);
        return false;
    }

    onRowSelect(obj: any) {
        if (this.onSelect)
            this.onSelect.emit(obj);
        return false;
    }

    onSearchColumns(column: number) {
        // this.waiting.toWait(true);
        var show = this.isToShowReference();
        for (var i = 0; i < this.header.length; ++i) {
            var elem = <HTMLInputElement>document.getElementById(this.idtable + "-search-header-" + i.toString());
            if (elem.type == "select-one") {
                switch (elem.value) {
                    case "Yes":
                        this.filter = this.filter + this.header[i].label + " is true; ";
                        this.onSearch.emit({ ShowRef: show, Index: i, Value: true });
                        break;
                    case "No":
                        this.filter = this.filter + this.header[i].label + " is false; ";
                        this.onSearch.emit({ ShowRef: show, Index: i, Value: false });
                        break;
                    default:
                        //this.filter = this.filter + this.header[i].label + " contains all; ";
                        //this.onSearch.emit({ ShowRef: show, Index: i, Value: undefined });
                        break;
                }
                //if (elem.checked) {
                //    this.filter = this.filter + this.header[i].label + " contains true; ";
                //    this.onSearch.emit({ ShowRef: show, Index: i, Value: true });
                //}
            }
            else if (elem.value != "") {
                this.filter = this.filter + this.header[i].label + " contains " + elem.value +"; ";
                this.onSearch.emit({ ShowRef: show, Index: i, Value: elem.value });
            }
        }
        this.waiting.toWait(false);
    }

}