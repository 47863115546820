<div class="example-container">
    <table mat-table
           [dataSource]="dataSource"
           multiTemplateDataRows
           class="mat-elevation-z8 mat-table-custom" id="testcaselist">

        <ng-container matColumnDef="arrow">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element"
                (click)="pushPopElement(element)">
                <span class="material-icons" *ngIf="checkExpanded(element); else show_expand_more">
                    expand_less
                </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="index">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element; let i = dataIndex">
                <p>{{i+1}}</p>
            </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.Name" *ngIf="!isEditingRow(element)"></p>
                <mat-form-field floatLabel="never" *ngIf="isEditingRow(element)">
                    <input matInput [value]="element.Name" [(ngModel)]="element.Name">
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.Description" *ngIf="!isEditingRow(element)"></p>
                <mat-form-field floatLabel="never" *ngIf="isEditingRow(element)">
                    <input matInput [value]="element.Description" [(ngModel)]="element.Description">
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container matColumnDef="minutes">
            <th mat-header-cell *matHeaderCellDef> Minutes To Wait Response </th>
            <td mat-cell *matCellDef="let element">
                <p *ngIf="!isEditingRow(element)" [innerHTML]="element.MinutesToWait"></p>
                <mat-form-field floatLabel="never" *ngIf="isEditingRow(element)">
                    <input matInput type="number" [min]="0" [value]="element.MinutesToWait" [(ngModel)]="element.MinutesToWait">
                </mat-form-field>
            </td>
        </ng-container>
        <ng-container matColumnDef="area">
            <th mat-header-cell *matHeaderCellDef> Area </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.Area"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> Id </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.TestCaseID"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="isITProcessRelevant">
            <th mat-header-cell *matHeaderCellDef> Is IT Process Relevant? </th>
            <td mat-cell *matCellDef="let element">
                <mat-checkbox [checked]="element.IsITProcessRelevant" (change)="changeIsITProcessRelevant(element, $event.checked)" [ngClass]="isEditingRow(element) ? 'black' :''" [disabled]="!isEditingRow(element)"></mat-checkbox>
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button matTooltip="Simulate Test Case" (click)="openExecuteTestCaseModal(element)">
                    <mat-icon>play_circle_filled</mat-icon>
                </button>
                <ng-container *ngIf="defaults.isDomainExpertUser || defaults.isadminuser || defaults.isExternalUser">
                    <button mat-icon-button matTooltip="Copy this TestCase to another ControllerType" (click)="onCopyTestcase(element)">
                        <mat-icon>content_copy</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="Edit Row" (click)="onRowEditInit(element)" *ngIf="!isEditingRow(element)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="Delete Row" *ngIf="!isEditingRow(element)" (click)="onDelete(element)">
                        <mat-icon>delete</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="Cancel Row" *ngIf="isEditingRow(element)" (click)="onRowEditCancel(element)">
                        <mat-icon>rotate_left</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="Add Event" *ngIf="!isEditingRow(element)" (click)="onCreateNewEvent(element)">
                        <mat-icon>library_add</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="JSON Ingress" *ngIf="checkExpanded(element) && defaults.isadminuser" (click)="openJsonIngressPopup(element)">
                        <mat-icon>code</mat-icon>
                    </button>
                </ng-container>
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                <div [@detailExpand]="checkExpanded(element) ? 'expanded' : 'collapsed'" *ngIf="element.Messages"
                     [class.expandedDetail]="checkExpanded(element)">
                    <table mat-table [dataSource]="element.Messages" class="mat-subtable-custom" id="testcaseDetails"
                           multiTemplateDataRows>

                        <ng-container matColumnDef="index">
                            <th mat-header-cell *matHeaderCellDef> </th>
                            <td mat-cell *matCellDef="let element; let i = dataIndex">
                                <p>{{i+1}}</p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef> Name </th>
                            <td mat-cell *matCellDef="let element2">
                                <p [innerHTML]="element2.Name"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="text">
                            <th mat-header-cell *matHeaderCellDef> Text </th>
                            <td mat-cell *matCellDef="let element">
                                <p [innerHTML]="element.Text"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="miliseconds">
                            <th mat-header-cell *matHeaderCellDef> Miliseconds in Event </th>
                            <td mat-cell *matCellDef="let element">
                                <p [innerHTML]="element.MiliSecondsToWait"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="output">
                            <th mat-header-cell *matHeaderCellDef> Output </th>
                            <td mat-cell *matCellDef="let element">
                                <mat-checkbox [checked]="element.Output" [ngClass]="'black'" [disabled]="true"></mat-checkbox>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element2">

                                <ng-container *ngIf="defaults.isDomainExpertUser || defaults.isadminuser || defaults.isExternalUser">
                                    <button mat-icon-button matTooltip="Move Message Event Up" (click)="onUp(element2)">
                                        <mat-icon>keyboard_arrow_up</mat-icon>
                                    </button>
                                    <button mat-icon-button matTooltip="Move Message Event Down" (click)="onDown(element2)">
                                        <mat-icon>keyboard_arrow_down</mat-icon>
                                    </button>
                                    <button mat-icon-button matTooltip="Edit Row" (click)="onRowEventEditInit(element2)">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                    <button mat-icon-button matTooltip="Delete" (click)="onDeleteEvent(element2)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </ng-container>
                            </td>
                        </ng-container>

                        <tr mat-header-row class="subtableheader" *matHeaderRowDef="subcolumnsToDisplay"></tr>
                        <tr mat-row *matRowDef="let element2; let i2 = index; columns: subcolumnsToDisplay;"
                            class="subitem"></tr>

                    </table>
                </div>
            </td>
        </ng-container>


        <tr mat-header-row class="tableheader" *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; let i = dataIndex; columns: columnsToDisplay;" class="item"
            [class.alternative2]="isEditingRow(element)">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="row-subitem"></tr>
    </table>
</div>

<ng-template #show_expand_more>
    <span class="material-icons">
        expand_more
    </span>
</ng-template>
