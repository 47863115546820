<div class="example-container-kpimanagement_opl black-checkbox new-table-style">

    <table mat-table
           [dataSource]="dataSource"
           multiTemplateDataRows
           matSort
           class="mat-elevation-z8 mat-table-custom" id="KpiOPLYearly">

        <!--</ng-container>-->
        <ng-container matColumnDef="arrow">
            <th mat-header-cell *matHeaderCellDef>
                <mat-icon *ngIf="!isAllCollapsed" (click)="collapseOrExpandAll()">expand_more</mat-icon>
                <mat-icon *ngIf="isAllCollapsed" (click)="collapseOrExpandAll()">expand_less</mat-icon>
            </th>
            <td mat-cell *matCellDef="let account"
                (click)="pushPopElement(account)">
                <ng-container>
                    <span class="material-icons"
                          *ngIf="checkExpanded(account); else show_expand_more">
                        expand_less
                    </span>
                </ng-container>
            </td>
        </ng-container>


        <!--<ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>

            </th>
            <td mat-cell *matCellDef="let account">
                <mat-checkbox class="checkbox-custom" (click)="$event.stopPropagation()">-->
        <!--(change)="itemSelected($event.checked,row.userPrincipalName,row.userId,row);"-->
        <!--</mat-checkbox>
            </td>
        </ng-container>-->

        <ng-container matColumnDef="Account">
            <th mat-header-cell *matHeaderCellDef>
                Account
            </th>
            <td mat-cell *matCellDef="let account">
                <mat-checkbox *ngIf="isReleaseActive || isRevokeActive" (click)="$event.stopPropagation()"
                              (change)="accountItemsSelected($event.checked,account);"
                              [checked]="accountCheckboxCheck(account)"
                              [indeterminate]="accountItemsIndetermine(account) && !accountCheckboxCheck(account)"
                              [disabled]="accountItemsDisable(account)">
                    <p [innerHTML]="account.AccountID | bold: dataSource.filter"></p>
                </mat-checkbox>
                <p *ngIf="!isReleaseActive && !isRevokeActive" [innerHTML]="account.AccountID | bold: dataSource.filter"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="KpiName">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let account">
                <p [innerHTML]="account.KpiName | bold: dataSource.filter"></p>
            </td>
        </ng-container>

        <!--<ng-container matColumnDef="KpiPlan">
            <th mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let account">
                <p [innerHTML]="account.KpiDescription | bold: dataSource.filter"></p>
            </td>
        </ng-container>-->

        <ng-container matColumnDef="KpiOwner">
            <th mat-header-cell *matHeaderCellDef> Owner </th>
            <td mat-cell *matCellDef="let account">
                <p [innerHTML]="account.KpiOwner | bold: dataSource.filter"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="KpiUnit">
            <th mat-header-cell *matHeaderCellDef> KpiUnit </th>
            <td mat-cell *matCellDef="let account">
                <p [innerHTML]="account.KpiUnit  | bold: dataSource.filter"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="LocalCurrency">
            <th mat-header-cell *matHeaderCellDef> Local Currency </th>
            <td mat-cell *matCellDef="let account">
                <p *ngIf="account.KpiUnit === 'Values' || account.KpiUnit === 'Value LC'" [innerHTML]="account.LocalCurrency"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="AvailabilityTime">
            <th mat-header-cell *matHeaderCellDef> Availability Time </th>
            <td mat-cell *matCellDef="let account">
                <p [innerHTML]="account.AvailabilityTime"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="ReportingType">
            <th mat-header-cell *matHeaderCellDef> Reporting Type </th>
            <td mat-cell *matCellDef="let account">
                <p [innerHTML]="account.ReportingType"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let account">
                <button mat-icon-button matTooltip="KPI definition" (click)="onDetail(account)">
                    <mat-icon>search</mat-icon> <span>KPI definition</span>
                </button>
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let account" [attr.colspan]="columnsToDisplay.length">
                <div [@detailExpand]="checkExpanded(account) ? 'expanded' : 'collapsed'"
                     id="KPIManagementOPLTableDetail">
                    <table mat-table
                           [dataSource]="account.subtable"
                           class="mat-subtable-custom"
                           [ngClass]="account.maxBreakdown === 'COL4' ? 'details_four_breakdown' :
                                            account.maxBreakdown === 'COL3' ? 'details_three_breakdown' :
                                                    account.maxBreakdown === 'COL2' ? 'details_two_breakdown' :
                                                        account.maxBreakdown === 'COL1' ? 'details_one_breakdown' : 'details_no_breakdowns'">

                        <ng-container matColumnDef="ItemNo">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let account;index as i;">
                                <p [innerHTML]="(i+1)"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="MersyId">
                            <th mat-header-cell *matHeaderCellDef> KPI Code </th>
                            <td mat-cell *matCellDef="let account">
                                <p [innerHTML]="account.MersyId"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="KpiName">
                            <th mat-header-cell *matHeaderCellDef> KPI Name </th>
                            <td mat-cell *matCellDef="let account">
                                <p [innerHTML]="account.KpiName"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="COL1">
                            <th mat-header-cell *matHeaderCellDef> <p [innerHTML]="account.COL1_NAME"></p></th>
                            <td mat-cell (click)="expandedItemSelected(!selection.isSelected(account),account)" *matCellDef="let account" [ngClass]="{'clickable-cursor': isReleaseActive || isRevokeActive}">
                                <div class="defCheckbox">
                                    <mat-checkbox *ngIf="isReleaseActive || isRevokeActive" (click)="$event.stopPropagation();" (change)="expandedItemSelected($event.checked,account)"
                                                  [checked]="selection.isSelected(account)"
                                                  [disabled]="!(isReleaseActive ? (account.ApprovalStatus == 'NEW') : (account.ApprovalStatus == 'PRE-RELEASED'))">
                                        <!--(change)="expandedItemSelected($event.checked,account);"-->
                                        <!--<p [innerHTML]="account.COL1| bold: dataSource.filter"></p>-->
                                    </mat-checkbox>
                                    <p [innerHTML]="account.COL1| bold: dataSource.filter"></p>
                                </div>
                            </td>

                        </ng-container>
                        <ng-container matColumnDef="COL2">
                            <th mat-header-cell *matHeaderCellDef> <p [innerHTML]="account.COL2_NAME"></p></th>
                            <td mat-cell *matCellDef="let account">
                                <p [innerHTML]="account.COL2| bold: dataSource.filter"></p>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="COL3">
                            <th mat-header-cell *matHeaderCellDef> <p [innerHTML]="account.COL3_NAME"></p></th>
                            <td mat-cell *matCellDef="let account">
                                <p [innerHTML]="account.COL3| bold: dataSource.filter"></p>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="COL4">
                            <th mat-header-cell *matHeaderCellDef> <p [innerHTML]="account.COL4_NAME"></p></th>
                            <td mat-cell *matCellDef="let account">
                                <p [innerHTML]="account.COL4| bold: dataSource.filter"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="BreakdownDescription1">
                            <th mat-header-cell *matHeaderCellDef>  </th>
                            <td mat-cell *matCellDef="let account">
                                <p [innerHTML]="account.BreakdownDescription1"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="BreakdownDescription2">
                            <th mat-header-cell *matHeaderCellDef>  </th>
                            <td mat-cell *matCellDef="let account">
                                <p [innerHTML]="account.BreakdownDescription2"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="MovementTypeDescription">
                            <th mat-header-cell *matHeaderCellDef>  </th>
                            <td mat-cell *matCellDef="let account">
                                <p [innerHTML]="account.MovementTypeDescription"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="ProductHierarchyDescription">
                            <th mat-header-cell *matHeaderCellDef>  </th>
                            <td mat-cell *matCellDef="let account">
                                <p [innerHTML]="account.ProductHierarchyDescription"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Granularity">
                            <th mat-header-cell *matHeaderCellDef> <p [innerHTML]="account.DetDescription"></p> </th>
                            <td mat-cell *matCellDef="let account">
                                <p [innerHTML]="account.Granularity | bold: dataSource.filter"></p>
                            </td>
                        </ng-container>


                        <!--<ng-container matColumnDef="ACTPY">
                            <th mat-header-cell *matHeaderCellDef> Δ ACT/{{currentFY}} </th>
                            <td mat-cell *matCellDef="let account">
                                <p [innerHTML]="formatCurrency(account.ACTPY, account.DecimalDigits)"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="ACTBUD">
                            <th mat-header-cell *matHeaderCellDef> Δ ACT/BUD </th>
                            <td mat-cell *matCellDef="let account">
                                <p [innerHTML]="formatCurrency(account.ACTBUD, account.DecimalDigits)"></p>
                            </td>
                        </ng-container>-->

                        <ng-container matColumnDef="YTDACT">
                            <th mat-header-cell *matHeaderCellDef> YTD/ACT </th>
                            <td mat-cell *matCellDef="let account">
                                <p [innerHTML]="formatCurrency(account.YTDACT, account.DecimalDigits)"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="PYBUD">
                            <th mat-header-cell *matHeaderCellDef> {{currentFY}}/BUD </th>
                            <td mat-cell *matCellDef="let breakdown">
                                <p [innerHTML]="formatCurrency(breakdown.PYBUD, account.DecimalDigits)"></p>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="FYFC">
                            <th mat-header-cell *matHeaderCellDef> {{currentFY}}/FC </th>
                            <td mat-cell *matCellDef="let breakdown" [ngClass]="{'notEditable': isEditModeEnabled === true && (breakdown.ApprovalStatus === 'PRE-RELEASED' || breakdown.ApprovalStatus === 'RELEASED') }">
                                <p [innerHTML]="formatCurrency(breakdown.FYFC, account.DecimalDigits)" [style.display]="isEditModeEnabled === false || (breakdown.ApprovalStatus === 'PRE-RELEASED' || breakdown.ApprovalStatus === 'RELEASED')? 'block' : 'none'"></p>
                                <input matInput name="FYFC" [ngClass]="{'hidden': isEditModeEnabled === false || (breakdown.ApprovalStatus === 'PRE-RELEASED' || breakdown.ApprovalStatus === 'RELEASED')}" type="number" min="0" (keydown)="disableArrowKeys($event)"
                                       pattern="\d+(\.\d{1,2})?" [value]="breakdown.FYFC" [(ngModel)]="breakdown.FYFC"
                                       (ngModelChange)="onChange(account,breakdown,$event,'FYFC')">
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="FY1BUD">
                            <th mat-header-cell *matHeaderCellDef> {{FY1}}/BUD </th>
                            <td mat-cell *matCellDef="let breakdown" [ngClass]="{'released': breakdown.ApprovalStatus === 'PRE-RELEASED' || breakdown.ApprovalStatus === 'RELEASED' }">
                                <p [innerHTML]="formatCurrency(breakdown.FY1BUD, account.DecimalDigits)" [style.display]="isEditModeEnabled === false || breakdown.ApprovalStatus === 'PRE-RELEASED' || breakdown.ApprovalStatus === 'RELEASED' ? 'block' : 'none'"></p>
                                <input matInput name="FY1BUD" [ngClass]="{'hidden': isEditModeEnabled === false || breakdown.ApprovalStatus === 'PRE-RELEASED' || breakdown.ApprovalStatus === 'RELEASED'}" type="number" min="0" (keydown)="disableArrowKeys($event)"
                                       pattern="\d+(\.\d{1,2})?" [value]="breakdown.FY1BUD" [(ngModel)]="breakdown.FY1BUD"
                                       (ngModelChange)="onChange(account,breakdown,$event,'FY1BUD')">
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="FY2BUD">
                            <th mat-header-cell *matHeaderCellDef> {{FY2}}/P1 </th>
                            <td mat-cell *matCellDef="let breakdown" [ngClass]="{'released': breakdown.ApprovalStatus === 'PRE-RELEASED' || breakdown.ApprovalStatus === 'RELEASED' }">
                                <!--<td mat-cell *matCellDef="let breakdown" [ngClass]="{'released': breakdown.ApprovalStatus === 'PRE-RELEASED' || breakdown.ApprovalStatus === 'RELEASED',
                                                                                'notEditable': isEditModeEnabled === true && (breakdown.ApprovalStatus === 'PRE-RELEASED' || breakdown.ApprovalStatus === 'RELEASED') }">-->
                                <p [innerHTML]="formatCurrency(breakdown.FY2BUD, account.DecimalDigits)" [style.display]="(isEditModeEnabled === false || breakdown.ApprovalStatus_FY2 === 'PRE-RELEASED' || breakdown.ApprovalStatus_FY2 === 'RELEASED') || (breakdown.ApprovalStatus === 'PRE-RELEASED' || breakdown.ApprovalStatus === 'RELEASED') ? 'block' : 'none'"></p>
                                <input matInput name="FY2BUD" [ngClass]="{'hidden': isEditModeEnabled === false || breakdown.ApprovalStatus_FY2 === 'PRE-RELEASED' || breakdown.ApprovalStatus_FY2 === 'RELEASED' || (breakdown.ApprovalStatus === 'PRE-RELEASED' || breakdown.ApprovalStatus === 'RELEASED')}" type="number" min="0" (keydown)="disableArrowKeys($event)"
                                       pattern="\d+(\.\d{1,2})?" [value]="breakdown.FY2BUD" [(ngModel)]="breakdown.FY2BUD"
                                       (ngModelChange)="onChange(account,breakdown,$event,'FY2BUD')">
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="FY3BUD">
                            <th mat-header-cell *matHeaderCellDef> {{FY3}}/P2 </th>
                            <td mat-cell *matCellDef="let breakdown" [ngClass]="{'released': breakdown.ApprovalStatus === 'PRE-RELEASED' || breakdown.ApprovalStatus === 'RELEASED' }">
                                <!--<td mat-cell *matCellDef="let breakdown" [ngClass]="{'released': breakdown.ApprovalStatus === 'PRE-RELEASED' || breakdown.ApprovalStatus === 'RELEASED',
                                                        'notEditable': isEditModeEnabled === true && (breakdown.ApprovalStatus === 'PRE-RELEASED' || breakdown.ApprovalStatus === 'RELEASED') }">-->
                                <p [innerHTML]="formatCurrency(breakdown.FY3BUD, account.DecimalDigits)" [style.display]="(isEditModeEnabled === false || breakdown.ApprovalStatus_FY3 === 'PRE-RELEASED' || breakdown.ApprovalStatus_FY3 === 'RELEASED' || (breakdown.ApprovalStatus === 'PRE-RELEASED' || breakdown.ApprovalStatus === 'RELEASED'))? 'block' : 'none'"></p>
                                <input matInput name="FY3BUD" [ngClass]="{'hidden': isEditModeEnabled === false || breakdown.ApprovalStatus_FY3 === 'PRE-RELEASED' || breakdown.ApprovalStatus_FY3 === 'RELEASED' || (breakdown.ApprovalStatus === 'PRE-RELEASED' || breakdown.ApprovalStatus === 'RELEASED')}" type="number" min="0" (keydown)="disableArrowKeys($event)"
                                       pattern="\d+(\.\d{1,2})?" [value]="breakdown.FY3BUD" [(ngModel)]="breakdown.FY3BUD"
                                       (ngModelChange)="onChange(account,breakdown,$event,'FY3BUD')">
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let account" class="notEditable">
                                <button mat-icon-button matTooltip="Get History" (click)="onDetail(account)">
                                    <mat-icon>history</mat-icon> <span>Get History</span>
                                </button>
                            </td>
                        </ng-container>


                        <tr mat-header-row class="subtableheader" *matHeaderRowDef="account.yearlyColumns"></tr>
                        <tr mat-row *matRowDef="let row2; let i2 = index; columns: account.yearlyColumns;"
                            class="subitem"
                            [class.alternative2]="isEditModeEnabled === true"></tr>
                    </table>
                </div>
            </td>
        </ng-container>


        <tr mat-header-row class="tableheader" *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let account; let i = dataIndex; columns: columnsToDisplay;"
            class="item"
            [class.example-expanded-row]="checkExpanded(account)">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="row-subitem"></tr>

    </table>
</div>

<ng-template #show_expand_more>
    <span class="material-icons">
        expand_more
    </span>
</ng-template>
<div *ngIf="isReleaseActive || isRevokeActive" class="stickypanel_KPI ">
    <div *ngIf="selection.hasValue()" class="col-lg-6" style="width:50%">
        Total selected breakdowns : {{selection.selected.length}}
    </div>
    <div *ngIf="!selection.hasValue()" class="col-lg-6" style="width:50%">
        Please select at least one Kpi / breakdown to {{isReleaseActive? 'Release' : 'Revoke'}} Selective or click {{isReleaseActive? 'Release' : 'Revoke'}} All
    </div>
    <div class="col-lg-6 ">
        <!--<button class="btn btn-realease btn-sm pull-right button-margin" (click)="isReleaseActive ? onClickReleaseMode_emitter(true):onClickRevokeMode_emitter(true) ">-->
        <!--<button class="btn btn-realease btn-sm pull-right button-margin" (click)="isReleaseActive ? onClickReleaseMode_emitter(true):onClickRevokeMode_emitter(true) ">
            {{ (isReleaseActive || isRevokeActive && isReleaseActive) ? 'Release ' : 'Revoke ' }} {{ !selection.hasValue() ? 'All' : 'Selected' }}
        </button>-->
        <!--<button class="btn btn-realease btn-sm pull-right button-margin" (click)="onClickReleaseMode_emitter(true)" [disabled]="!selection.hasValue()">
            {{ (isReleaseActive || isRevokeActive && isReleaseActive) ? 'Release Selected' : 'Revoke Selected' }}
        </button>-->
        <!--<div class="new-table-style">
            <a class='kpi-button-menu pull-right customchangesbutton' [disabled]="!anyActionableItemExist()" (click)="isReleaseActive ? onClickReleaseMode_emitter(true):onClickRevokeMode_emitter(true)">
                {{ textRelRevbuttonState() }}
            </a>
        </div>-->
        <button class="btn btn-realease btn-sm pull-right button-margin" [disabled]="!anyActionableItemExist()" (click)="isReleaseActive ? onClickReleaseMode_emitter(true):onClickRevokeMode_emitter(true)">
            {{ textRelRevbuttonState() }}
        </button>
    </div>
</div>
