import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Utils } from "../../../../utils/utils";
import { DefaultValuesService } from "../../../../services/defaultvalues.service";
import { ActualReportingListComponent, SearchItem } from "./actualreporting-list.component";
import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';
import { Router } from "@angular/router";
import { DialogService } from "../../../../services/dialog.service";
import { CustomDialogService } from "../../../../services/customdialog.service";
import { MatDialog } from "@angular/material/dialog";
import { MatChipInputEvent } from "@angular/material/chips";
import { ToastrService } from "ngx-toastr";
import { FilterPopupComponent } from "../../shared-components/filterpopup/filterpopup.component";

@Component({
    selector: "actualreporting",
    templateUrl: './actualreporting.component.html',
    styleUrls: ['../../../../style/container-tab-list.component.less', '../../../../style/new-generic-styles.component.less']
})

export class ActualReportingComponent implements OnInit, OnDestroy {


    @ViewChild(ActualReportingListComponent) actualRepSelList: ActualReportingListComponent = {} as ActualReportingListComponent;
    isReleaseActive: boolean = false;
    releaseButtonText: string = 'Initiate Release';

    isRevokeActive: boolean = false;
    revokeButtonText: string = 'Initiate Revoke';




    title: string;
    isEditModeEnabled = false;
    isExcelModeEnabled = false;
    isApprovalModeEnabled = false;
    isShowAllEnabled = true;

    subscriptionManagementId: any;
    subscriptionFiscalYear: any;
    subscriptionPeriod: any;
    subscriptions: any[] = [];

    list: KpiManagement[] = [];
    @ViewChild(ActualReportingListComponent, { static: false }) listComponent!: ActualReportingListComponent;

    // Filter section
    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [COMMA, TAB, ENTER];
    searchItems: SearchItem[] = [];

    filter: string = ''
    selectorFilter = '0';
    inclusiveMode = false;
    isAllLoaded = false;
    waitHeader = `   -  **Please, wait until all breakdowns are properly loaded**`;
    columnsToDisplay2 = ['ItemNo', 'COL1', 'COL2', 'COL3', 'COL4', 'ValueM3', 'ValueM2', 'ValueM1', 'Value', 'Budget', 'actions'];

    showReleaseLabel = false;

    constructor(
        public defaults: DefaultValuesService
        , private http: HttpClient
        , private dialogForm: MatDialog
        , private dialog: DialogService
        , @Inject('BASE_URL') private baseUrl: string
        , private router: Router
        , private customDialog: CustomDialogService
        , private toastrService: ToastrService

    ) {
        this.title = `Actual ${this.isBranchReporting() ? `Branch Reporting ` : `Reporting`}(${this.defaults.managementName} - ${this.defaults.fiscalYear - 1}/${this.defaults.fiscalYear} - Period ${this.defaults.period})`;
    }


    ngOnInit() {
        this.subscriptionManagementId = this.defaults.managementIdMessageChanges$.subscribe(() => { this.loadInformation() });
        this.subscriptionFiscalYear = this.defaults.fiscalYearMessageChanges$.subscribe(() => { this.loadInformation() });
        this.subscriptionPeriod = this.defaults.periodMessageChanges$.subscribe(() => { this.loadInformation() });
        if (this.defaults.managementids.length !== 0) {
            this.loadInformation();
        }
    }

    ngOnDestroy() {
        this.subscriptionManagementId.unsubscribe();
        this.subscriptionFiscalYear.unsubscribe();
        this.subscriptionPeriod.unsubscribe();
        this.subscriptions.forEach(x => x.unsubscribe())
    }

    //////////////////
    //OnClick Methods

    onClickSave() {
        // Check for incorrect hours or units values
        if (this.listComponent.updatedItems.filter(x =>
            (x.KpiUnit?.toUpperCase() === 'UNITS' || x.KpiUnit?.toUpperCase() === 'HOURS')
            && x.KpiValue.indexOf('.00') < 0).length > 0) {
            this.dialog.showErrorDialog({ 'error': { 'message': 'Incorrect value provided with decimals for a Units or Hours KPI.' } });
            return;
        }
        // Check for incorrect decimal value
        if (this.listComponent.updatedItems.filter(x =>
            (x.KpiUnit?.toUpperCase() === 'DECIMAL')
            && this.isCorrectCurrencyValue(x.KpiValue, x.DecimalDigits) === false).length > 0) {
            this.dialog.showErrorDialog({ 'error': { 'message': 'Incorrect decimal value provided' } });
            return;
        }
        // Check for incorrect currency values based on local currency
        if (this.listComponent.updatedItems.filter(x =>
            (x.KpiUnit?.toUpperCase() === 'VALUES' || x.KpiUnit?.toUpperCase() === 'VALUE LC')
            && this.isCorrectCurrencyValue(x.KpiValue, x.DecimalDigits) === false).length > 0) {
            this.dialog.showErrorDialog({ 'error': { 'message': 'Incorrect currency value provided.' } });
            return;
        }

        var wasAnyUpdate = this.listComponent.updatedItems.length > 0
        this.listComponent.updatedItems = this.listComponent.updatedItems.filter(x => x.KpiValue !== 'NaN')

        if (this.listComponent.updatedItems.length > 0) {
            const url = this.baseUrl + "api/KPIManagement";
            this.http.post<any[]>(url, this.listComponent.updatedItems).subscribe(() => {
                this.loadInformation()
                this.toastrService.success('Kpi values were updated successfully!');
                this.isEditModeEnabled = false
            }, error => {
                this.dialog.showErrorDialog(error);
            });
        }
        else {
            this.isEditModeEnabled = false;
            if (wasAnyUpdate)
                this.loadInformation()
        }
    }

    isCorrectCurrencyValue(value: string, decimalDigits: number) {
        const dotIndex = value.indexOf('.');
        const colonIndex = value.indexOf(',');
        if (dotIndex === -1 && colonIndex === -1) {
            return true;
        }

        const maxSymbol = Math.max(dotIndex, colonIndex);
        if (maxSymbol === -1 || maxSymbol === value.length - 1) {
            return true;
        }
        return value.length - maxSymbol - 1 <= decimalDigits;
    }

    onClickEdit() {
        if (this.isEditModeEnabled === true) {
            this.isEditModeEnabled = false
            if (this.listComponent.updatedItems.length > 0) {
                this.loadInformation()
            }
        }
        else {
            this.isEditModeEnabled = true
        }
    }

    onClickShowAllButton() {
        if (this.isShowAllEnabled === false) {
            this.isShowAllEnabled = true
        }
        else {
            this.isShowAllEnabled = false
        }
        this.updatefilter()
    }

    onClickReleaseMode() {
        var selectedRows = this.actualRepSelList?.getSelectionList();
        if (!selectedRows)
            selectedRows = []

        this.customDialog.openConfirm({
            title: "Release Kpi values",
            message: `Do you want to release all populated values which are still pending? Please note that you cannot edit any values after the release!. `,
            caller: this
        });
        this.customDialog.confirmed().subscribe(res => {
            if (res.confirmed === true) {
                Utils.httpReleaseKpiDefinitions(
                    this.http
                    , this.baseUrl
                    , selectedRows
                    , true
                    , false
                    , this.isBranchReporting()
                    , this
                    , function (tthis: ActualReportingComponent) {
                        tthis.loadInformation();
                        tthis.toastrService.success('Kpi values were released successfully!');
                        if (!tthis.hideSections())
                            tthis.toggleRelease();
                    }
                );
            }
        })
    }

    onClickRevoke() {
        var selectedRows = this.actualRepSelList?.getSelectionList();
        if (!selectedRows)
            selectedRows = []
        this.customDialog.openConfirm({
            title: "Revoke Kpi values",
            message: `Do you want to revoke all populated values for selected period?`,
            caller: this
        });
        this.customDialog.confirmed().subscribe(res => {
            if (res.confirmed === true) {
                Utils.httpRevokeKpiValues(
                    this.http
                    , this.baseUrl
                    , selectedRows
                    , true
                    , false
                    , this.isBranchReporting()
                    , this
                    , function (tthis: ActualReportingComponent) {
                        tthis.loadInformation();
                        tthis.toastrService.success('Kpi values were revoked successfully!');
                        if (!tthis.hideSections())
                            tthis.toggleRevoke();
                    }
                );
            }
        })
    }

    onClickExcelButton() {
        this.isExcelModeEnabled = true;
    }

    onClickCancelButton() {
        this.isEditModeEnabled = this.isExcelModeEnabled = false;
    }       


    downloadOplInformationFromLastYear() {
        let date = new Date()
        const year = date.getFullYear().toString(); // año
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // mes con dos dígitos
        const day = date.getDate().toString().padStart(2, '0'); // día con dos dígitos
        Utils.httpDownloadMonthlyOPLInformation(
            this.http
            , this.baseUrl
            , `${year}${month}${day}_${this.defaults.managementid}_${this.defaults.groupFunction}_${this.defaults.fiscalYear - 2}-${this.defaults.fiscalYear - 1}_OPL`
            , this.isShowAllEnabled
            , this.isBranchReporting()
            , true
            , this                                                                                               
            , function (tthis: ActualReportingComponent, error: string) {
                tthis.dialog.showErrorDialog(error);
            });

    }

    downloadExcelInformation() {

        let date = new Date()
        const year = date.getFullYear().toString(); // año
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // mes con dos dígitos
        const day = date.getDate().toString().padStart(2, '0'); // día con dos dígitos

        Utils.httpDownloadActualReportingInformation(
            this.http
            , this.baseUrl
            , `${year}${month}${day}_${this.defaults.managementid}_${this.defaults.groupFunction}_${this.defaults.fiscalYear - 1}-${this.defaults.fiscalYear}_P${this.defaults.period}_ACT`
            , this.isShowAllEnabled
            , this.isBranchReporting()
            , this
            , null
            , function (tthis: ActualReportingComponent, error: string) {
                tthis.dialog.showErrorDialog(error);
            });
    }

    goUploadFileView() {
        if (!this.isBranchReporting())
            this.router.navigate(["file/" + Utils.getFileUploadActualReportingInformation()]);
        else
            this.router.navigate(["file/" + Utils.getFileUploadActualBranchReportingInformation()]);
    }


    // Open filter Popup
    openFilterPopup() {
        const dialog = this.dialogForm.open(FilterPopupComponent, {
            panelClass: ['custom-mat-dialog', 'popup-dialog'],
            disableClose: true,
            data: { "selectorFilter": this.selectorFilter, "inclusiveMode": this.inclusiveMode }
        })
        dialog.afterClosed().subscribe((result) => {
            if (result) {
                this.selectorFilter = result.selectorFilter;
                this.inclusiveMode = result.inclusiveMode
            }
        })
    }


    onSelectorChange($event: string) {
        this.selectorFilter = $event
    }

    private loadInformation() {
        this.subscriptions.forEach(x => x.unsubscribe())
        this.showReleaseLabel = false;
        this.isAllLoaded = false;
        this.title = `Actual ${this.isBranchReporting() ? `Branch Reporting ` : `Reporting `}(${this.defaults.managementName} - ${this.defaults.fiscalYear - 1}/${this.defaults.fiscalYear} - Period ${this.defaults.period})`;
        if (this.hideSections())
            return;
        Utils.httpGetActualReportingInformation(
            this.http
            , this.baseUrl
            , this.isBranchReporting()
            , this
            , function (tthis: ActualReportingComponent, result: KpiManagement[]) {
                setTimeout(() => {
                    result.forEach(x => {
                        if (!x.subtable) {
                            const row = x.KpiDetail[0]
                            let maxBreakdown = ''
                            let columns = Object.assign([], tthis.columnsToDisplay2);

                            if (row.COL4 === '') {
                                columns.splice(columns.indexOf('COL4'), 1)
                                maxBreakdown = 'COL3'
                            }
                            if (row.COL3 === '') {
                                columns.splice(columns.indexOf('COL3'), 1)
                                maxBreakdown = 'COL2'
                            }
                            if (row.COL2 === '') {
                                columns.splice(columns.indexOf('COL2'), 1)
                                maxBreakdown = 'COL1'
                            }
                            if (row.COL1 === '') {
                                columns.splice(columns.indexOf('COL1'), 1)
                                maxBreakdown = 'COL0'
                            }
                            x.columns = columns
                            x.maxBreakdown = maxBreakdown
                            tthis.showReleaseLabel = tthis.showReleaseLabel || x.ApprovalStatus === 'RELEASED'

                        }
                    })
                    tthis.list = result
                    tthis.isAllLoaded = true;

                }, 50)
            }
            , function (tthis: ActualReportingComponent, error: string) {
                tthis.dialog.showErrorDialog(error);
                tthis.list = [];
            }
        );
    }

    ////////////////
    //Filter section
    auxFilterPredicate(filters: string, data: any, parentLevel: boolean, showAllEnabled: boolean) {
        const matchFilter: any[] = [];
        const filterArray = filters.split('+').filter(x => x)
        filterArray.pop() // Removing last item for showing or not all kpi items
        filterArray.pop() // Removing last item for showing or not all kpi items
        filterArray.pop() // Removing last item for showing or not all kpi items

        if (filterArray.length > 0) {
            filterArray.forEach((filter) => {
                let result = false;
                if (parentLevel === true) {
                    const kpiname = data.KpiName ? data.KpiName : ''
                    const id = data.AccountID ? data.AccountID : ''
                    //const plan = data.KpiPlan ? data.KpiPlan : ''
                    result = kpiname.toString().toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
                        id.toString().toLowerCase().indexOf(filter.toLowerCase()) > -1
                    //|| plan.toString().toLowerCase().indexOf(filter.toLowerCase()) > -1
                }
                else {
                    if (this.selectorFilter == '1') {
                        const granularity = data.Granularity ? data.Granularity : ''
                        result = granularity.toString().toLowerCase().indexOf(filter.toLowerCase()) > -1
                    }
                    else {
                        result = true;
                    }
                    if (!showAllEnabled) {
                        result = result && (data.ValueM1 || data.ValueM2 || data.ValueM3 || data.ValueLocal || data.Budget)
                    }
                }
                matchFilter.push(result);
            });
        }
        else {
            let result = true
            if (parentLevel === true) {
                result = true
            }
            else {
                if (!showAllEnabled) {
                    result = result && (data.ValueM1 || data.ValueM2 || data.ValueM3 || data.ValueLocal || data.Budget)
                }
            }
            matchFilter.push(result);
        }
        if (!this.inclusiveMode)
            return matchFilter.some(Boolean);
        else
            return matchFilter.every(Boolean);

    }

    applyFilter(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            this.searchItems.push({ name: value.replace('+', '').trim() });
        }
        // Reset the input value
        if (input) {
            input.value = '';
        }
        this.updatefilter()
    }

    remove(item: SearchItem): void {
        const index = this.searchItems.indexOf(item);
        if (index >= 0) {
            this.searchItems.splice(index, 1);
        }
        this.updatefilter()
    }
    updatefilter() {
        let filterString: string
        if (this.searchItems.length === 0) {
            this.filter = ''
                .concat(`+${(this.isShowAllEnabled === true) ? 'ShowAllKpiItems' : 'HideEmptyKpiItems'}`)
                .concat(`+${(this.selectorFilter === '1') ? 'FilterAllLevels' : 'FilterParentLevel'}`)
                .concat(`+${(this.inclusiveMode === true) ? 'InclusiveMode' : 'NoInclusiveMode'}`)
        }
        else {
            filterString = this.searchItems.map(e => { return e.name }).join('+')
                .concat(`+${(this.isShowAllEnabled === true) ? 'ShowAllKpiItems' : 'HideEmptyKpiItems'}`)
                .concat(`+${(this.selectorFilter === '1') ? 'FilterAllLevels' : 'FilterParentLevel'}`)
                .concat(`+${(this.inclusiveMode === true) ? 'InclusiveMode' : 'NoInclusiveMode'}`)
            this.filter = filterString
        }
    }

    public toggleRelease() {
        this.isReleaseActive = !this.isReleaseActive;
        this.releaseButtonText = this.isReleaseActive ? 'Abort Release' : 'Initiate Release', this.actualRepSelList.clearSelectionList()
    }

    public toggleRevoke() {
        this.isRevokeActive = !this.isRevokeActive;
        this.revokeButtonText = this.isRevokeActive ? 'Abort Revoke' : 'Initiate Revoke', this.actualRepSelList.clearSelectionList()
    }
    isBranchReporting() {
        const management = Utils.GetManagementChildrenByParent(this.defaults.managementids[0], this.defaults.managementid)
        return (this.router.url.toLowerCase() === '/actualbranchreporting' && (management?.BusinessLevel === 'MANAGEMENT' && management?.Groups?.length >= 1) || management?.BusinessLevel === 'FIELDBRANCH') as boolean
    }

    hideSections() {
        const management = Utils.GetManagementChildrenByParent(this.defaults.managementids[0], this.defaults.managementid)
        return (this.router.url.toLowerCase() === '/actualbranchreporting' && management?.BusinessLevel === 'MANAGEMENT' && management?.Groups?.length >= 1) as boolean
    }

    onBack() { }

}
