import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ElementRef, Injectable, Renderer2, RendererFactory2, ViewChild } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { defer, NEVER } from 'rxjs';
import { finalize, share } from 'rxjs/operators';
import { SpinnerOverlayComponent } from '../components/spinner-overlay/spinner-overlay.component';

@Injectable({
    providedIn: 'root',
})
export class SpinnerOverlayService {
    private overlayRef: OverlayRef | undefined = undefined;
    @ViewChild('main-content', { static: true, read: ElementRef })
    private divElementRef: ElementRef | undefined;
    private renderer: Renderer2;
    public skipInterceptor = false;

    constructor(
        private readonly overlay: Overlay,
        private dialogForm: MatDialog,
        rendererFactory: RendererFactory2
    ) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    public readonly spinner$ = defer(() => {
        this.show();
        return NEVER.pipe(
            finalize(() => {
                this.hide();
            })
        );
    }).pipe(share());


    setTrueskipInterceptor() {
        this.skipInterceptor = true;
    }
    setFalseskipInterceptor() {
        this.skipInterceptor = false;
    }

    private show(): void {

        console.log('SpinnerOverlayService ~ show spinner');
        this.divElementRef = new ElementRef(document.getElementById("main-content"))
        if (this.dialogForm.openDialogs.length === 0 && this.divElementRef.nativeElement !== null) {
            this.renderer.setStyle(document.getElementById("main-content") as HTMLElement, 'transform', 'translateZ(0)');
            Promise.resolve(null).then(() => {
                this.overlayRef = this.overlay.create({
                    positionStrategy: this.overlay
                        .position()
                        .flexibleConnectedTo(this.divElementRef ?? new ElementRef(''))
                        .withPositions([
                            {
                                originX: 'center',
                                originY: 'center',
                                overlayX: 'center',
                                overlayY: 'center'
                            }
                        ]) ,                               
                    backdropClass: 'backdropClass',
                    panelClass: 'backdropClass'
                });                           
                this.overlayRef.attach(new ComponentPortal(SpinnerOverlayComponent));
            });
        }
        else {
            Promise.resolve(null).then(() => {
                this.overlayRef = this.overlay.create({
                    positionStrategy: this.overlay
                        .position()
                        .global()
                        .centerHorizontally()
                        .centerVertically(),
                    hasBackdrop: true,
                });
                this.overlayRef.attach(new ComponentPortal(SpinnerOverlayComponent));
            })

        }
    }
    //}

    public hide(): void {
        console.log('SpinnerOverlayService ~ hide spinner');
        this.overlayRef!.detach();
        this.overlayRef = undefined;
    }
}
