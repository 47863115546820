<div class="" style="background:none">
    <div class="rulebook-container" *ngIf="defaults.componentTool !== componentToolEnum.Undefined">
        <img class="top-margin" src="../../assets/border.svg" />
        <img class="bottom-margin" src="../../assets/border.svg" />
        <h3>
            {{defaults.componentTool === componentToolEnum.Rulebook ? 'RULEBOOK EDITOR' : ''}}
            {{defaults.componentTool === componentToolEnum.Mersy ? 'MERSY' : ''}}
        </h3>
    </div>
    <div class="tab-body">
        <div class="landingpage landingpage-nocolumns" id="landingpage-nocolumns" *ngIf="defaults.components.length < 4">
            <div *ngFor="let component of defaults.components;let index=index"
                 class="bordered-div"
                 [ngClass]="index === 0 ? 'tke-orange' :
                                index === 1 ? 'tke-intermediate-color' : 'tke-purple' ">
                <label class="top-label">{{component.DisplayName}} </label>
                <span class="description">
                    {{component.Description}}
                </span>
                <button class="button" (click)="setSelectedItem(component.ClusterId!)" routerLinkActive="active" routerLink="/{{component.Route}}">Click here<mat-icon>event</mat-icon></button>
                <button *ngIf="isExternalLink(component.Route)" (click)="externalRedirect(component.Route)" class="button">
                    Click here
                    <mat-icon> event</mat-icon>
                </button>
            </div>
        </div>
        <div (mouseenter)="onWheel($event)" id="landingpage-columns" class="landingpage landingpage-columns" *ngIf="defaults.components.length >= 4" [ngStyle]="{'overflow-x' : defaults.components.length <= 6 ? 'hidden' : 'auto'}">
            <div *ngFor="let column of defaults.components | chunk: 2; let i=index" class="column"
                 [ngClass]="defaults.components.length < 6 ? 'col-md-6' : 'col-md-4'">
                <div *ngFor="let component of column ; let j = index" class="bordered-div item"
                     [ngClass]="getComponentClass(i*2 + j, component.ClusterId) ">
                    <label class="top-label">
                        {{defaults.componentTool === componentToolEnum.Rulebook ? component.ClusterName : ''}}
                        {{defaults.componentTool === componentToolEnum.Rulebook && component.ClusterName ? '-' : ''}} {{component.DisplayName}}
                    </label>
                    <span class="description">{{component.Description}}</span>
                    <button class="button" (click)="setSelectedItem(component.ClusterId!)" routerLinkActive="active" routerLink="/{{component.Route}}">
                        Click here
                        <mat-icon class='sidenav-icon'> settings_application</mat-icon>
                    </button>
                    <button *ngIf="isExternalLink(component.Route)" (click)="externalRedirect(component.Route)" class="button">
                        Click here
                        <mat-icon class='sidenav-icon'> settings_application</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div>
        <img class="tke_footer" src="../../assets/tke_footer.png" />
    </div>
</div>
