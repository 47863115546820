<maintenancewarning-menu (onRefresh)="refresh()" [list]="list"> </maintenancewarning-menu>
<div class="container-tab-lists dialog.classname">
    <h2>{{title}}</h2>
    <div class="inner-tcontainer-tab">
        <div class="tab-body">
            <div class="tab-content">
                <maintenancewarning-list [list]="list" (onRefresh)="refresh()"></maintenancewarning-list>
            </div> <!--tab-content-->
        </div> <!--tab-body-->
    </div> <!--inner-tcontainer-tab-->
</div> <!--container-tab-lists-->
