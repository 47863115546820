import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { DialogService } from '../../services/dialog.service';
import { Subject } from "rxjs";
import { DefaultValuesService } from "../../services/defaultvalues.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { HierarchySetupPopupComponent } from "./hierarchysetuppopup/hierarchysetuppopup.component";

@Component({
    selector: "hierarchysetup-list",
    templateUrl: './hierarchysetup-list.component.html',
    styleUrls: ['../../style/mat-table.component.less', '../../style/dialogue-box.less', 'hierarchysetup-list.component.less'],
    encapsulation: ViewEncapsulation.None
})

export class HierarchySetupListComponent implements OnInit {
    @Input() list: BranchHierarchyKey[] = [];
    @Input() alllist: BranchHierarchyKey[] = [];
    @Output() rldBranchHierarchyKey = new EventEmitter();


    protected _onDestroy = new Subject<void>();
    public dataSource = new MatTableDataSource<BranchHierarchyKey>();
    columnsToDisplay = ['Key', 'BranchName', 'LocationForMap', 'Address', 'MgmtId', 'actions'];
    timeoutFunction: any;

    constructor(
        private dialog: DialogService
        , private defaults: DefaultValuesService
        , private dialogForm: MatDialog
    ) {
    }

    ngOnInit() { }

    ngOnChanges() {
        this.dataSource.data = this.list
        this.getHeader()
    }

    getHeader() {
        if (!this.defaults.isReadonlyUser)
            this.columnsToDisplay = ['Key', 'BranchName', 'LocationForMap', 'Address', 'MgmtId', 'actions'];
        else
            this.columnsToDisplay = ['Key', 'BranchName', 'LocationForMap', 'Address', 'MgmtId'];
    }

    openMgmtIdPopup() {
    }

    onRowEditInit(element: BranchHierarchyKey) {
        const dialogRef = this.dialogForm.open(HierarchySetupPopupComponent, {
            panelClass: ["custom-mat-dialog_v2"],
            "minWidth": "40vw",
            data: { action: "edit", content: element }
        })
        dialogRef.afterClosed().subscribe(() => this.rldBranchHierarchyKey.emit());
    }

    onDeleteKey(element: BranchHierarchyKey[]) {
        const dialogRef = this.dialogForm.open(HierarchySetupPopupComponent, {
            "height": "240px",
            "width": "550px",
            data: { action: "delete", content: this.alllist, selected: element },
            panelClass: 'deleteDialogue'
        })
        dialogRef.afterClosed().subscribe(() => this.rldBranchHierarchyKey.emit());

    }

    onGetHistory(element) {
        const dialogRef = this.dialogForm.open(HierarchySetupPopupComponent, {
            panelClass: ["branch-hierarchy-dialogue"],
            data: { action: "history", hkSelected: element.Key }
        })
    //    dialogRef.afterClosed().subscribe(() => this.rldBranchHierarchyKey.emit());
    }
}