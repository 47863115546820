<form [formGroup]="form" style="padding-bottom:2%;">
    <table>
        <tbody class="inner">
            <tr>
                <td>&nbsp;</td>
                <td colspan="2" style="width:25%;">
                    <label>{{header[1].label}}: (insert -1 to calculate the new value automatically, for the operation mode)</label>
                    <input id="Value" required
                           type="number"
                           formControlName="Value"
                           placeholder="enter the value..."
                           title="{{header[1].label}}: insert -1 to calculate the new value automatically, for the operation mode"
                           (change)="saveService.showSaveButton()"
                           class="form-control" />

                    <label>{{header[0].label}}:</label>
                    <input id="Mode" required
                           formControlName="Mode"
                           placeholder="enter the mode..."
                           title="{{header[0].label}}"
                           [ngClass]="classname[MODE]"
                           (change)="onModeChange()"
                           class="form-control" />

                    <label>{{header[11].label}}:</label>
                    <textarea id="Description"
                              formControlName="Description" required
                              placeholder="enter the description..."
                              title="{{header[10].label}}"
                              [ngClass]="classname[DESCRIPTION]"
                              class="form-control"
                              (change)="onDescriptionChange()">
                    </textarea>
                </td>

                <td colspan="2" style="width:10%;">
                    <label>{{header[2].label}}:</label>
                    <input id="OutOfService"
                           type="checkbox"
                           formControlName="UnitAvailable"
                           title="{{header[2].label}}: {{header[2].title}}"
                           [ngClass]="classname[UNITAVAILABLE]"
                           (change)="onIsUnitAvailableChange()"
                           class="form-control" />
                    <label>{{header[3].label}}:</label>
                    <input id="GroupEvent"
                           type="checkbox"
                           formControlName="GroupEvent"
                           title="{{header[3].label}}: {{header[3].title}}"
                           [ngClass]="classname[GROUPEVENT]"
                           (change)="onIsGroupEventChange()"
                           class="form-control" />
                </td>
                <td colspan="2" style="width:10%;">
                    <label>{{header[4].label}}:</label>
                    <input id="SafetyRelated"
                           type="checkbox"
                           formControlName="SafetyRelated"
                           title="{{header[4].label}}: {{header[4].title}}"
                           [ngClass]="classname[SAFETYRELATED]"
                           (change)="onIsSafetyRelatedChange()"
                           class="form-control" />
                    <label>{{header[5].label}}:</label>
                    <input id="PotentialMisuse"
                           type="checkbox"
                           formControlName="PotentialMisuse"
                           title="{{header[5].label}}: {{header[5].title}}"
                           [ngClass]="classname[POTENTIALMISUSE]"
                           (change)="onIsPotentialMisuseChange()"
                           class="form-control" />
                </td>
                <td colspan="2" style="width: 5%; padding: 2%" *ngIf="defaultService.controllertype.BK === 'CT1000'">
                    <label>{{header[6].label}}:</label>
                    <input type="checkbox" id="IsGoodEvent"
                           formControlName="IsGoodEvent"
                           title="Is Good event..."
                           class="form-control"
                           (change)="onIsGoodEventChange()" />
                </td>
                <td colspan="2" style="width: 20%; padding: 2%" *ngIf="defaultService.controllertype.BK === 'CT1000'">
                    <label>{{header[7].label}}:</label>
                    <textarea id="component"
                              formControlName="Component"
                              placeholder="enter the component..."
                              class="form-control"
                              (change)="onComponentChange()">
                    </textarea>
                </td>
                <td colspan="2" style="width:30%;">

                    <label>{{header[10].label}}:</label>
                    <select name="ct"
                            (change)="onSpecificEquipmentStatusChange()"
                            title="{{header[10].label}}: {{header[10].title}}"
                            [ngClass]="classname[EQUIPMENTSTATUS]"
                            class="form-control"
                            formControlName="SpecificEquipmentStatus">
                        <option *ngFor="let v of harmonizedopmodes; index as i" value="{{v.HarmonizedOperationModeID}}" title="{{v.HarmonizedDescription}}">{{v.HarmonizedDescription}}</option>
                    </select>
                    <label>{{header[8].label}}:</label>
                    <select name="ct"
                            (change)="onStableAlertRuleChange()"
                            title="{{header[8].label}}: {{header[8].title}}"
                            [ngClass]="classname[STABLEALERTRULE]"
                            class="form-control"
                            formControlName="StableAlertRule">
                        <option *ngFor="let v of alertRules; index as i" value="{{v.Id}}" title="{{ v.Description }}">{{ v.Description }}</option>
                    </select>
                    <label>{{header[9].label}}:</label>
                    <select name="ct"
                            (change)="onProdAlertRuleChange()"
                            title="{{header[9].label}}: {{header[9].title}}"
                            [ngClass]="classname[PRODALERTRULE]"
                            class="form-control"
                            formControlName="ProdAlertRule">
                        <option *ngFor="let v of alertRules; index as i" value="{{v.Id}}" title="{{ v.Description }}">{{ v.Description }}</option>
                    </select>
                </td>
                <td colspan="2" style="width:5%;">
                </td>

                <td>
                    <button type="button"
                            class="btn btn-warning"
                            (click)="onRowCancel()">
                        Cancel
                    </button>
                </td>
                <br />
            </tr>
        </tbody>
    </table>
</form>