<div class="container-tab-lists">
    <maintenance-control-plan-group-action-menu [selected]="selected" (newEvent)="onNew()"></maintenance-control-plan-group-action-menu>

    <div class="inner-tcontainer-tab">
        <h2>{{title}}</h2>
        <div class="tab-body">
            <p>{{selected.MCPGroup}} (Maintenance Control Plan: {{selected.MCPTitle}})</p>

            <div class="tab-content">
                <maintenance-control-plan-group-action-list [list]="list" [mcpgroup]="selected" [newService]="newMode"></maintenance-control-plan-group-action-list>
            </div><!--tab-content-->

        </div> <!--tab-body-->
    </div> <!--inner-tcontainer-tab-->
</div> <!--container-tab-lists-->
