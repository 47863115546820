import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { startWith, tap } from 'rxjs/operators';
import { DefaultValuesService } from '../../services/defaultvalues.service';
import { SpinnerOverlayService } from '../../services/spinner-overlay.service';
import { Utils } from '../../utils/utils';

@Component({
    selector: 'healthscore',
    styleUrls: ['healthscore.component.less', '../../style/new-generic-styles.component.less'],
    templateUrl: 'healthscore.component.html',
    encapsulation: ViewEncapsulation.None
})
export class HealthScoreComponent implements OnInit {
    displayedColumns: string[] = ['FeatureName', 'weight', 'action'];
    displayedColumns2: string[] = ['FeatureName', 'weight'];
    dataSource = new MatTableDataSource<any>();
    responseScoreFactorTable: ScoreFactor[] = [];
    allFeatures: Feature[] = [];
    assignableFeatures: Feature[] = [];
    isLoading = true;
    populationid: undefined | number = undefined;
    modifiedSF: ScoreFactor[] = []
    newAddedSF: ScoreFactor[] = []

    pageNumber: number = 1;
    VOForm!: FormGroup;
    isEditableNew: boolean = true;
    Response_State: number = -2; //0 -> empty , 1 -> correct and not empty ,-1 -> error, -2 -> Calling API

    constructor(
        private fb: FormBuilder,
        public defaults: DefaultValuesService,
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string,
        private routeService: Router,
        private spinnerOverlayService: SpinnerOverlayService,
        private _formBuilder: FormBuilder) { }

    ngOnInit(): void {
        this.VOForm = this._formBuilder.group({
            VORows: this._formBuilder.array([])
        });
        this.funcGetAllScoreFactorFeatures()
    }



    ngAfterContentInit() {
        this.defaults.healthScorePopObs$.subscribe(popId => { this.populationid = popId, popId === undefined ? this.resetData() : (this.funcGetAllScoreFactorforPopulation()) })
    }
    resetData() {

        this.VOForm = this._formBuilder.group({
            VORows: this._formBuilder.array([])
        });
        this.VOForm.reset()
        this.dataSource.data = []
    }

    applyFilter(filterValue) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }


    AddNewRow() {
        const control = this.VOForm.get('VORows') as FormArray;
        control.insert(0, this.initiateVOForm());
        this.dataSource = new MatTableDataSource(control.controls)

    }

    // this function will enabled the select field for editd
    EditSVO(VOFormElement, i) {
        VOFormElement.get('VORows').at(i).get('isEditable').patchValue(false);
    }


    // On click of correct button in table (after click on reset) this method will call
    ResetSVO(index) {
        var defaultObjectValues = this.responseScoreFactorTable.find(sft => sft._ScoreFactorId == this.VOForm.value.VORows[index]?._ScoreFactorId)
        this.UpdateFormFeature(defaultObjectValues, index)
        this.VOForm.value.VORows[index].isEditable = true;
    }

    DeleteSVO(addressedObject) {
        Utils.httpDeleteScoreFactor(
            this.http
            , this.baseUrl
            , addressedObject._ScoreFactorId
            , this
            , function (tthis: HealthScoreComponent, response: ScoreFactor[]) {
                tthis.funcGetAllScoreFactorforPopulation();

            }
        )
    }



    initiateVOForm(): FormGroup {
        var firstFtr = this.assignableFeatures[0]
        return this.fb.group({
            _ScoreFactorId: new FormControl(-99),
            FeatureName: new FormControl(""),
            FeatureId: new FormControl(-99),
            FeatureDescription: new FormControl(""),
            Weight: new FormControl(0),
            action: new FormControl('newRecord'),
            isEditable: new FormControl(false),
            isNewRow: new FormControl(true),
            PopulationId: new FormControl(this.populationid)
        });
    }

    funcGetAllScoreFactorforPopulation() {
        this.resetData()
        this.Response_State = -2;
        
        Utils.httpGetAllScoreFactorforPopulation(
            this.http
            , this.baseUrl
            , this.populationid
            , this
            , function (tthis: HealthScoreComponent, response: ScoreFactor[]) {
                tthis.responseScoreFactorTable = response;
                if (tthis.responseScoreFactorTable?.length) {
                    tthis.Response_State = 1;
                }
                else {
                    tthis.Response_State = 0;

                }
                tthis.funcMaptoForm();
                tthis.getAssignableFeatures();

            }
        )
        
    }

    funcGetAllScoreFactorFeatures() {
        
        Utils.httpGetAllScoreFactorFeatures(
            this.http
            , this.baseUrl
            , this
            , function (tthis: HealthScoreComponent, response: any) {
                tthis.allFeatures = response;
            }
        )
        

    }

    //FIltering all the features that are not assigned yet
    getAssignableFeatures() {
        this.assignableFeatures = this.allFeatures.filter(allFtr => !this.VOForm.get('VORows')?.value.some(resTable => resTable.FeatureId == allFtr._FeatureId))
        //this.allFeatures.forEach(allFtr => { console.log("checking feature by feature", allFtr, this.responseScoreFactorTable.some(resTable =>  resTable.FeatureId == allFtr._FeatureId )) })

    }




    funcMaptoForm() {
        this.VOForm = this._formBuilder.group({
            VORows: this._formBuilder.array([])
        });

        this.VOForm = this.fb.group({
            VORows: this.fb.array(this.responseScoreFactorTable.map(val => this.fb.group({
                _ScoreFactorId: new FormControl(val._ScoreFactorId),
                FeatureName: new FormControl(val.FeatureName),
                FeatureId: new FormControl(val.FeatureId),
                FeatureDescription: new FormControl(val.FeatureDescription),
                Weight: new FormControl(val.Weight),
                action: new FormControl('existingRecord'),
                isEditable: new FormControl(true),
                isNewRow: new FormControl(false),
                PopulationId: new FormControl(this.populationid)
            })
            )) //end of fb array
        }); // end of form group cretation
        this.isLoading = false;
        this.dataSource = new MatTableDataSource((this.VOForm.get('VORows') as FormArray).controls);



        // Define the filter function
        const filterFunction = (data: AbstractControl, filter: string): boolean => {
            var featureName = data.value.FeatureName.toLowerCase();
            return featureName.includes(filter.toLowerCase());
        };

        // Apply the filter function to the dataSource
        this.dataSource.filterPredicate = filterFunction;


    }

    //  (newvalues, currentvalue)
    UpdateFormFeature(newSelectedObject, index) {
        var oldObjFormRef = this.VOForm.get('VORows')?.value[index]

        var temp = this.VOForm.value.VORows;
        temp[index].FeatureName = newSelectedObject.FeatureName
        temp[index].FeatureDescription = newSelectedObject.FeatureDescription
        temp[index].Weight = newSelectedObject.Weight
        temp[index].FeatureId = newSelectedObject.FeatureId ?? newSelectedObject._FeatureId

        this.VOForm.get('VORows')?.setValue(temp);
        this.getAssignableFeatures();


    }

    onSave() {
        this.modifiedSF = this.VOForm.get('VORows')?.value.filter(row => !row.isNewRow && !row.isEditable);
        this.newAddedSF = this.VOForm.get('VORows')?.value.filter(row => row.isNewRow && row.FeatureName != "" && row.Weight != 0);
        var scoreFactorChanges = { 'modifiedSF': this.modifiedSF, 'newAddedSF': this.newAddedSF }
        Utils.httpUpdateScoreFactor(
            this.http
            , this.baseUrl
            , scoreFactorChanges
            , this
            , function (tthis: HealthScoreComponent, response: any) {
                tthis.funcGetAllScoreFactorforPopulation();

            }
        )
        //    this.modifiedSF = this.VOForm.value.
    }

    getTooltipText(): string {
        if (!(this.assignableFeatures.length > 0)) {
            return 'No more features avaialable to be assigned';
        } else {
            return 'Add new row ';
        }
    }

    shouldUseDisplayedColumns(): string[] {
        return this.defaults.isControllerOwner ? this.displayedColumns : this.displayedColumns2;
    }


}