import { Component, Inject, Input, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Utils } from "../../utils/utils";
import { DialogService } from "../../services/dialog.service";
import { SaveService } from "../../services/save.service";
import { DefaultValuesService } from "../../services/defaultvalues.service";

@Component({
    selector: "maintenance-control-plan-group-action-component-list",
    templateUrl: './mcp-list-group-action-component.component.html',
    styleUrls: ['../../style/list.component.less', './mcp-list.component.less']
})

export class MaintenanceControlPlanGroupActionComponentListComponent implements OnInit {
    @Input() action: MaintenanceControlPlanGroupAction = <MaintenanceControlPlanGroupAction>{};
    @Input() newService: SaveService | undefined;

    header: any[] = [
        { label: 'Location', index: 0, show: true }
        , { label: 'Component', index: 1, show: true }
        , { label: 'Errors Count', index: 2, show: true }
    ];

    expanded: boolean[] = [];
    newChildService: SaveService | undefined;

    constructor(
        private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , private dialog: DialogService
        , public defaults: DefaultValuesService
    ) {
    }

    /**
     * this is from who calls (inner component)
     * @param list
     * @param row
     * @param column
     */
    getData(list: MaintenanceControlPlanGroupActionComponent[], row: number, column: number): string {
        if (list) {
            var e = list[row];
            switch (column) {
                case 0:
                    return e.Location;
                case 1:
                    return e.Component;
                default:
                    return e.NUMBER_ERRORS != null ? e.NUMBER_ERRORS.toString() : "";
            }
        }
        else
            return "";
    }

    ngOnInit() {
        this.dialog.tthis = this;
        this.newChildService = new SaveService();
        this.newChildService.addListener(this);
    }

    onDelete(value: { index: number, value: MaintenanceControlPlanGroupActionComponent }) {
        this.dialog.showConfirmDialog(value.value.Component + " in " + value.value.Location + "?", "Delete Action Component", this, value);
    }

    onExpand(value: any) {
        if (value.expanded) {

            Utils.httpAllGetMaintenanceControlPlanGroupActionComponentErrors(
                this.http
                , this.baseUrl
                , value.value.MCPActionID
                , value.value.ComponentItemID
                , this
                , function (tthis: any, list: MaintenanceControlPlanGroupActionComponentError[]) {
                    tthis.action.Components[value.index].Errors = list; //tthis.list[value.index].Components = list;
                }
            )
        }
    }

    onNewChild(value: { index: number, value: MaintenanceControlPlanGroupActionComponent }) {
        if (this.newChildService)
            this.newChildService.fireNew();
    }

    onYes(value: { index: number, value: MaintenanceControlPlanGroupActionComponent }) {
        this.http
            .delete<MaintenanceControlPlanGroupActionComponent>(
                this.baseUrl + Utils.getMaintenanceControlPlanAPI() + "groupactioncomponent/" + value.value.MCPActionID + "/" + value.value.ComponentItemID
            )
            .subscribe(
                res => this.action.Components.splice(value.index, 1)
                , error => this.dialog.showErrorDialog(error)
            );
    }

}