<h3 mat-dialog-title>{{title}}</h3>
<mat-dialog-content>
    <form [formGroup]="form">
        <table>
            <tbody class="inner">
                <tr *ngIf="!newRow">
                    <td>&nbsp;</td>
                    <td style="width:250px;">
                        <span *ngIf="!isToShowRef||list[rowIndex].PossibleFailure" [ngClass]="classname[POSSIBLEFAILURE]">{{selected.PossibleFailure}}</span>
                        <span *ngIf="isToShowRef&&!list[rowIndex].PossibleFailure" [ngClass]="classname[POSSIBLEFAILURE]">{{list[rowIndex].PossibleFailure_ref}}</span>
                    </td>
                    <td style="width:250px;"><span [ngClass]="classname[LOCATION]">{{selected.Location}}</span></td>
                    <td style="width:250px;"><span [ngClass]="classname[COMPONENT]">{{selected.Component}}</span></td>
                    <td style="width:250px;">
                        <span *ngIf="!isToShowRef||list[rowIndex].Resolution" [ngClass]="classname[RESOLUTION]">{{selected.Resolution}}</span>
                        <span *ngIf="isToShowRef&&!list[rowIndex].Resolution" [ngClass]="classname[RESOLUTION]">{{list[rowIndex].Resolution_ref}}</span>
                    </td>
                    <td>&nbsp;</td>
                </tr>

                <tr>
                    <td>&nbsp;</td>
                    <td style="width:250px;">
                        <mat-form-field>
                            <mat-label>{{header[POSSIBLEFAILURE].label}}</mat-label>
                            <textarea matInput type="text" name="PossibleFailure" formControlName="PossibleFailure"
                                   title="{{header[POSSIBLEFAILURE].label}}"
                                   [ngClass]="classname[POSSIBLEFAILURE]">
                            </textarea>
                        </mat-form-field>
                    </td>
                    <td style="width:250px;">
                        <mat-form-field>
                            <mat-label>{{header[LOCATION].label}}</mat-label>
                            <mat-select placeholder="Location" name="Location" required formControlName="Location">
                                <ng-container *ngFor="let v of locations">
                                    <mat-option [value]="v.ComponentSubSystemID">
                                        {{v.Description}} 
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </td>
                    <td style="width:250px;">
                        <mat-form-field>                            
                            <mat-label>{{header[COMPONENT].label}}</mat-label>
                            <mat-select placeholder="Component" name="Component" formControlName="Component">
                                <ng-container *ngFor="let v of components">
                                    <mat-option [value]="v.ComponentItemID">
                                        {{v.Description}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </td>
                    <td style="width:250px;">
                        <mat-form-field>
                            <mat-label *ngIf="!newRow" [ngClass]="classname[RESOLUTION]">{{header[RESOLUTION].label}}: {{selected.Resolution}}</mat-label>
                            <mat-label *ngIf="newRow">{{header[RESOLUTION].label}}</mat-label>
                            <textarea matInput type="text" name="Resolution" required formControlName="Resolution"
                                   title="{{header[RESOLUTION].label}}"
                                   [ngClass]="classname[RESOLUTION]">
                            </textarea>
                        </mat-form-field>
                    </td>
            </tbody>
        </table>

        <div mat-dialog-actions class="mt-2 right">
            <button mat-raised-button [disabled]="!form.valid" (click)="onSubmit()">Save</button> <!--[mat-dialog-close]="1"-->
            <button mat-button (click)="onCancelForm()" tabindex="-1">Cancel</button>
        </div>
    </form>
</mat-dialog-content>
