<form [formGroup]="form">
    <table>
        <tbody class="inner">
            <tr>
                <td>&nbsp;</td>
                <td>
                    <span *ngIf="!newRow">{{selected.Title}}</span>
                    <input type="text" id="Title"
                           formControlName="Title" required
                           placeholder="enter the title..."
                           title="{{header[TITLE].label}}"
                           [ngClass]="classname[TITLE]"
                           (change)="onTitleChange()"
                           class="form-control" />
                    <button *ngIf="!newRow" type="button" class="btn btn-default" title="Undo changes" [disabled]="isundodisabled[TITLE] == true" (click)="onTitleUndo()">
                        <span class='glyphicon glyphicon-arrow-left'></span>
                    </button>
                </td>
                <td>
                    <span *ngIf="!newRow">{{selected.AssetType}}</span>
                    <select name="Asset"
                            formControlName="Asset" required
                           placeholder="enter the asset..."
                           title="{{header[ASSET].label}}"
                           [ngClass]="classname[ASSET]"
                           (change)="onAssetChange()"
                            class="form-control">
                        <option *ngFor="let a of assets; index as i" value="{{assets[i]}}">{{assets[i]}}</option>
                    </select>
                    <button *ngIf="!newRow" type="button" class="btn btn-default" title="Undo changes" [disabled]="isundodisabled[ASSET] == true" (click)="onAssetUndo()">
                        <span class='glyphicon glyphicon-arrow-left'></span>
                    </button>
                </td>

                <td>
                    <span *ngIf="!newRow">{{selected.IsHydraulic}}</span>
                    <input id="Hydraulic"
                           type="checkbox"
                           formControlName="Hydraulic"
                           title="{{header[ISHYDRAULIC].label}}"
                           [ngClass]="classname[ISHYDRAULIC]"
                           (change)="onIsHydraulicChange()"
                           class="form-control" />
                    <button *ngIf="!newRow" type="button" class="btn btn-default" title="Undo changes" [disabled]="isundodisabled[ISHYDRAULIC] == true" (click)="onIsHydraulicUndo()">
                        <span class='glyphicon glyphicon-arrow-left'></span>
                    </button>
                </td>
                <td>
                    <button type="button"
                            class="btn btn-warning"
                            (click)="onRowCancel()">
                        Cancel
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</form>