import { Component, Output, EventEmitter, Input, OnInit, OnChanges } from '@angular/core';
import { DefaultValuesService } from '../../services/defaultvalues.service';
import { DialogService } from '../../services/dialog.service';
import { ReplaySubject, Subject } from 'rxjs';

@Component({
    selector: 'new-language-selector',
    template: `Language:<br />
                <select *ngIf="list" name="lang" (change)="changeLang($event)" title="Change the Language">
                    <option *ngFor="let l of list" value="{{l.LanguageId}}" ng-model="list">{{l.Name}}</option>
               </select>`
})

export class NewLanguageSelectorComponent implements OnInit, OnChanges {
    @Input('languages') list: Language[] = [];

    @Output() languageChanged = new EventEmitter<Language>();

    value: number = 0;

    public filteredLanguages: ReplaySubject<Language[]> = new ReplaySubject<Language[]>(1);

    protected _onDestroy = new Subject<void>();

    constructor(
        public defaults: DefaultValuesService
        , public dialog: DialogService
    ) {
    }
    ngOnInit() {
        this.dialog.tthis = this;
        this.defaults.languageMessageChanges$.subscribe(() => {
            this.filteredLanguages.next(this.defaults.languages)
        }
        );
    }
    ngOnChanges() {
    }


    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    changeLang(l: any) {
        this.languageChanged.emit(this.list.filter(x => x.LanguageId.toString() === l.target.value)[0])
    }
}