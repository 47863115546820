import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from
    "@angular/forms";
import { ActivatedRoute } from '@angular/router';
import { forkJoin, EMPTY } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from "@angular/forms";
import { DialogService } from '../../../../../services/dialog.service';
import { Utils } from '../../../../../utils/utils';





@Component({
    selector: "deleteuserdialogue",
    templateUrl: './deleteuserdialogue.component.html',
    styleUrls: ['./deleteuserdialogue.component.less'],

})

export class DeleteUserDialogue implements OnInit {
    isCallSucceed


    constructor(
        private http: HttpClient
        , public dialogRef: MatDialogRef<DeleteUserDialogue>
        , private confimDialog: DialogService
        , private toastrService: ToastrService,
        private route: ActivatedRoute,
        @Inject('BASE_URL') private baseUrl: string,
        @Inject(MAT_DIALOG_DATA) public data: any

    ) {



    }

    ngOnInit() {

    }



    handleDeleteButton() {
        this.isCallSucceed = 0;
        Utils.httpDeleteUser(
            this.http
            , this.baseUrl
            , this.data.userId
            , this
            , function (tthis: DeleteUserDialogue) {
                tthis.funcNotify();
                tthis.dialogRef.close();

            }
        )


    }
    funcNotify() {
        if (this.isCallSucceed === 1) {
            this.toastrService.success('User update request sent successfully !', 'User Edit Request!');
        }
        else if (this.isCallSucceed === -1) {
            this.toastrService.error('User update request not sent successfully !', 'User Edit Request!');
        }
    }

    backButtonHandler(event: any) {
        event.preventDefault();
        this.dialogRef.close();

    }

}
