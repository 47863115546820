import { Component, Inject, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { DialogService } from '../../../../services/dialog.service';
import { Utils } from '../../../../utils/utils';
import { SaveService } from '../../../../services/save.service';
import { DefaultValuesService } from '../../../../services/defaultvalues.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'controllertypebytenant-form'
    , templateUrl: './controllertypebytenant-form.component.html'
    , styleUrls: ['../../../../style/form.component.less']
})
export class ControllerTypeByTenantFormComponent implements OnInit {

    body = {
        ControllerTypeID: '',
        CountryId: -1,
        Name: ''
    };

    form!: FormGroup
    submitted = false;

    controllerTypes: any[] = []
    constructor(
        private http: HttpClient
        , private dialog: DialogService
        , private defaults: DefaultValuesService
        , private saveService: SaveService
        , @Inject(MAT_DIALOG_DATA) public data: any
        , @Inject('BASE_URL') private baseUrl: string
        , public dialogRef: MatDialogRef<ControllerTypeByTenantFormComponent>
        , private formBuilder: FormBuilder
    ) {

    }

    ngOnInit() {

        this.saveService.addListener(this);
        this.form = this.formBuilder.group({
            ct: ['', [Validators.required]]
        });
        Utils.httpGetAllControllerTypesAvailable(
            this.http
            , this.baseUrl
            , this
            , function (tthis: any, list: ControllerType[]) {
                tthis.controllerTypes = list;
            }
        );

        Utils.httpGetUserTenant(
            this.http
            , this.baseUrl
            , this
            , function (tthis: ControllerTypeByTenantFormComponent, value: Country) {
                tthis.body.CountryId = value._CountryID;
                tthis.body.Name = value.Name;
            }
        );
    }

    onCancelForm() {
        this.saveService.fireCancel(undefined, this);
    }

    /**
     * called by the SaveService
     * */
    onSave() {

        const tthis = this;
        this.http
            .post<ControllerTypeByTenant>(
                this.baseUrl + Utils.getControllerTypeAPI() + "controllertypebytenant-add"
                , this.body
            )
            .subscribe(
                () => {
                    this.onBack()
                }
                , error => {
                    this.onBack()
                    tthis.dialog.showErrorDialog(error);
                }
            );
    }
    onSubmit() {
        this.saveService.fireSave(undefined, this);
    }

    onBack() {
        this.dialogRef.close({ event: 'submit' });
    }
}


