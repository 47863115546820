<table-fixed-header-search-collapse 
    *ngIf="component.Errors && !defaults.isReadonlyUser"
    id-table="maintenance-control-plan-group-action-error-id"
    [header]="header"
    [data]="component.Errors"
    [getValue]="getData"
    [selectable]="false"
    [rowNewTemplate]="newError"
    [newService]="newService"
    (onDelete)="onDelete($event)">
</table-fixed-header-search-collapse>

<table-fixed-header-search-collapse *ngIf="component.Errors && defaults.isReadonlyUser"
                                    id-table="maintenance-control-plan-group-action-error-id"
                                    [header]="header"
                                    [data]="component.Errors"
                                    [getValue]="getData"
                                    [selectable]="false">
</table-fixed-header-search-collapse>

<ng-template #newError let-index="index">
    <maintenance-control-plan-group-action-component-error-new [header]="header" [list]="component.Errors" [component]="component" [rowIndex]="index"></maintenance-control-plan-group-action-component-error-new>
</ng-template>