<div class="historyMainWrapper">

    <div class="  ">
        <div class="contentWidth heading-wrapper heading-background " style=" width: 100%;">

            <div class="col-sm-6 ">
                <h2>{{hkey}} Hierarchy History</h2>
            </div>

            <div class="col-sm-2  search-container">

                <input (keyup)="applyFilter($event)" class="form-control" placeholder="Search"
                       #input />

                <!--<input (keyup)="applyFilter($event.target.value)"
                class="form-control"
                #input /-->
            </div>
            <div class=" col-sm-4 search-container">
                <!--<input class="form-control"
                #input />-->
                <div class="col-sm-6">
                   
                    <div class="cutom-date">
                        <input class="form-control" (click)="pickerFilterStart.open()" readonly [(ngModel)]="minDate" style="width: calc(100% - 45px) !important" placeholder="Date From" matInput (dateChange)="filterTable()" [matDatepicker]="pickerFilterStart">
                        <mat-datepicker-toggle matIconSuffix [for]="pickerFilterStart"></mat-datepicker-toggle>
                        <mat-datepicker #pickerFilterStart></mat-datepicker>
                    </div>
                </div>
                <div class="col-sm-6">
                        <input class="form-control" (click)="pickerFilterEnd.open()" readonly [(ngModel)]="maxDate" style="width: calc(100% - 45px) !important" placeholder="Date To" matInput (dateChange)="filterTable()" [matDatepicker]="pickerFilterEnd">
                        <mat-datepicker-toggle matIconSuffix [for]="pickerFilterEnd">
                        </mat-datepicker-toggle>
                        <mat-datepicker #pickerFilterEnd></mat-datepicker>


                </div>

            </div>

        </div>
    </div>

    <div class="zoomIn example-container_history">
        <table mat-table #itemsList
               [dataSource]="dataSource"
               multiTemplateDataRows
               class="mat-elevation-z8 mat-table-custom" id="hierarchysetup">

            <ng-container matColumnDef="Source">
                <th mat-header-cell *matHeaderCellDef>Source</th>
                <td mat-cell *matCellDef="let element">
                    <!--<p >{{element.SourceName}} | {{element.SourceKey}}</p>-->
                    <p>{{element.SourceName}}</p>
                </td>
            </ng-container>

            <ng-container matColumnDef="Target">
                <th mat-header-cell *matHeaderCellDef>Target</th>
                <td mat-cell *matCellDef="let element">
                    <!--<p >{{element.TargetName}} | {{element.TargetKey}}</p>-->
                    <p>{{element.TargetName}}</p>
                </td>
            </ng-container>

            /n

            <ng-container matColumnDef="Properties">
                <th mat-header-cell *matHeaderCellDef>
                    Properties
                </th>
                <td mat-cell *matCellDef="let element">
                    <p [innerHTML]="element.TargetDetails"></p>
                </td>
            </ng-container>
            <ng-container matColumnDef="Action">
                <th mat-header-cell *matHeaderCellDef> Action </th>
                <td mat-cell *matCellDef="let element">
                    <p [innerHTML]="element.Operation"></p>
                </td>
            </ng-container>
            <ng-container matColumnDef="TimeStamp">
                <th mat-header-cell *matHeaderCellDef> ChangedAt </th>
                <td mat-cell *matCellDef="let element">
                    <p matTooltip="{{element.OperationTimeStamp}}" [innerHTML]="element.OperationTimeStamp | date: 'yyyy-MM-dd HH:mm'"></p>
                </td>
            </ng-container>
            <ng-container matColumnDef="User">
                <th mat-header-cell *matHeaderCellDef> User </th>
                <td mat-cell *matCellDef="let element">
                    <p [innerHTML]="element.UserUPN"></p>
                </td>
            </ng-container>


            <tr mat-header-row class="tableheader" *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let element; let i = dataIndex; columns: columnsToDisplay;" class="item"></tr>
        </table>
    </div>

        <div class="panel-body OrangeButton">
            <button  mat-raised-button class="btn  button-color-fix " (click)="onBack()">
                <mat-icon class=" icon-color-fix ">arrow_back_ios</mat-icon>
                Back
            </button>
        </div>
</div>
