import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Component, ElementRef, ViewChild, Inject, OnInit } from '@angular/core';
import { Injectable, AfterViewInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';


import { HttpClient } from '@angular/common/http';

import { ActivatedRoute } from '@angular/router';
import { SpinnerOverlayService } from '../../../../services/spinner-overlay.service';
import { UMPCategory, Utils } from '../../../../utils/utils';
import { DefaultValuesService } from '../../../../services/defaultvalues.service';


interface MgmtNode {
    groupId: string;
    groupName: string;
    subGroupsAllowed: boolean,
    selected?: boolean;
    disabled?: boolean;
    indeterminate?: boolean;
    parent?: MgmtNode;
    groups: MgmtNode[]
}




@Component({
    selector: "group-mgmtbranch",
    templateUrl: './usermgmtbranch.component.html',
    styleUrls: ['./usermgmtbranch.component.less'],

})

export class GroupMgmtBranch implements OnInit {
    userId = '';
    public TREE_DATA: MgmtNode[] = [];/*** */
    dataCopy: any;

    public selectedNodes: string[] = [];

    public treeControl: NestedTreeControl<MgmtNode>;
    public dataSource = new MatTreeNestedDataSource<MgmtNode>();
    public rootNodes: any;
    public isExpanded = false;

    public searchString = '';
    public showOnlySelected = false;
    Tree_Response_State: number = -2; //0 -> empty , 1 -> correct and not empty ,-1 -> error, -2 -> Calling API
    isCallSucceed: number = 0;

    data: TreeManagement<UMPCategory> = {
        SelectedBranches: [],
        UnselectedBranches: [],
        UmpCategory: UMPCategory.MgmtBranchHierarchy
    }


    constructor(
        private toastrService: ToastrService,
        private route: ActivatedRoute,
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string,
        private spinnerOverlayService: SpinnerOverlayService,
        private defaults: DefaultValuesService

    ) {
        this.treeControl = new NestedTreeControl<MgmtNode>((node) => node.groups)
        defaults.resetBulkGroupTrigger$.subscribe(() => this.resetData())

    }
    ngOnInit() {
        this.userId = this.route.snapshot.paramMap.get('userId') || '';

        this.funcGetUserDataAnlytics();


    }

    public hasChild = (_: number, node: MgmtNode) =>
        !!node.groups && node.groups.length > 0;

    private setParent(node: MgmtNode, parent: MgmtNode | undefined) {
        node.parent = parent;
        if (node.groups) {
            node.groups.forEach((childNode) => {
                this.setParent(childNode, node);
            });
        }
    }

    private checkAllParents(node: MgmtNode) {
        if (node.parent) {
            const descendants = this.treeControl.getDescendants(node.parent);
            node.parent.indeterminate = descendants.some((child) => child.selected);
            this.checkAllParents(node.parent);
        }
    }

    public itemToggle(checked: boolean, node: MgmtNode) {
        node.selected = checked;
        if (node.groups && checked) {
            node.indeterminate = false;
            this.itemUnselect(node);
        }
        if (node.groups) {
            this.itemDisable(checked, node);
        }
        if (checked) {
            this.data.UnselectedBranches = this.data.UnselectedBranches.filter(x => x !== node.groupId)
            this.data.SelectedBranches.push(node.groupId)
        }
        else {
            this.data.SelectedBranches = this.data.SelectedBranches.filter(x => x !== node.groupId)
            this.data.UnselectedBranches.push(node.groupId)
        }
        this.checkAllParents(node);
    }
    private itemUnselect(node: MgmtNode) {
        const descendants = this.treeControl.getDescendants(node);
        descendants.forEach(node => { node.indeterminate = false; node.selected = false });
    }

    private itemDisable(checked: boolean, node: MgmtNode) {
        const descendants = this.treeControl.getDescendants(node);
        descendants.forEach(node => node.disabled = checked);
    }



    public hideLeafNode(node: MgmtNode): boolean {
        return new RegExp(this.searchString, 'i').test(node.groupName) === false;
    }


    public hideParentNode(node: MgmtNode): boolean {
        if (
            !this.searchString ||
            node.groupName.toLowerCase().indexOf(this.searchString.toLowerCase()) !==
            -1
        ) {
            return false
        }
        const descendants = this.treeControl.getDescendants(node)

        if (
            descendants.some(
                (descendantNode) =>
                    descendantNode.groupName
                        .toLowerCase()
                        .indexOf(this.searchString.toLowerCase()) !== -1
            )
        ) {
            return false
        }

        return true
    }
    funcGetUserDataAnlytics() {
        this.Tree_Response_State = -2;
        this.isExpanded = false;

        Utils.httpGetUserMgmtBranches(
            this.http
            , this.baseUrl
            ,""
            , this
            , (tthis: GroupMgmtBranch, data: any) => {
                this.assignValues(data);
                this.dataCopy = structuredClone(data);


            });
    }
    assignValues(data) {
        this.isExpanded = false;

        this.TREE_DATA = data.treeView; ///******** */
        this.selectedNodes = data.excludedPersonae;
        this.rootNodes = structuredClone(data.treeView);
        this.smallerTree(this.rootNodes);
    }
    resetData() {
        this.assignValues(structuredClone(this.dataCopy))
    }
    smallerTree(smallTREE_DATA) {
        smallTREE_DATA.forEach((parent) => {
            parent.groups?.forEach((child) => {
                delete child.groups;
            });
        });
        //smallTREE_DATA=smallTREE_DATA.flatMap((node) => node.children);
        this.treeControl.dataNodes = smallTREE_DATA;
        this.dataSource.data = smallTREE_DATA;
        Object.keys(this.dataSource.data).forEach((key) => {
            this.setParent(this.dataSource.data[key], undefined);

        })

    }
    longTreeExpandAll() {
        if (!this.isExpanded) {
            this.treeControl.dataNodes = this.TREE_DATA;
            this.dataSource.data = this.TREE_DATA;
            Object.keys(this.dataSource.data).forEach((key) => {
                this.setParent(this.dataSource.data[key], undefined);
            }
            )
            this.isExpanded = true;
        }

        
        this.treeControl.expandAll()
    }
   
    expandTriggered(name) {
        this.treeControl.dataNodes = this.TREE_DATA;
        this.dataSource.data = this.TREE_DATA;
        Object.keys(this.dataSource.data).forEach((key) => {
            this.setParent(this.dataSource.data[key], undefined);

        }
        )
        this.expandNodeByName(name);
        this.isExpanded = true;
        
    }
    funcNotify() {
        if (this.isCallSucceed === 1) {
            this.toastrService.success('DataAnalytics & PowerBI request not sent successfully !', 'Data Analytics and PowerBI Workspaces!');
        }
        else if (this.isCallSucceed === -1) {
            this.toastrService.error('DataAnalytics & PowerBI request not sent successfully !', 'Data Analytics and PowerBI Workspaces!');
        }
    }
    expandNodeByName(name: string) {
        const node = this.findNodeByName(this.treeControl.dataNodes, name);
        if (node) {
            this.treeControl.expand(node);
        }
    }

    findNodeByName(nodes: MgmtNode[], name: string): MgmtNode | undefined {
        for (const node of nodes) {
            if (node.groupName === name) {
                return node;
            }

        }
        return undefined;
    }

    itemSelected(ischecked, name, id) {
        if (ischecked) {
            this.onSelect(name, id);
        } else {
            this.onDeselect(name, id);
        }
    }

    onSelect(name, selectedid) {
        this.defaults.addGroupListItem({ Personae: name, Id: selectedid, UmpCategory: UMPCategory.MgmtBranchHierarchy });

    }

    onDeselect(name, selectedid) {
        this.defaults.removeGroupListItem(selectedid);

    }
}