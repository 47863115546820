import { Component, Inject, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { DialogService } from "../../../../services/dialog.service";
import { Utils } from "../../../../utils/utils";
import { HttpClient } from "@angular/common/http";
import { DefaultValuesService } from "../../../../services/defaultvalues.service";
import { SaveService } from "../../../../services/save.service";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { CustomDialogService } from "../../../../services/customdialog.service";

@Component({
    selector: "controllertypebytenant-list",
    templateUrl: './controllertypebytenant-list.component.html',
    styleUrls: ['../../../../style/list.component.less', './controllertypebytenant-list.component.less']
})

export class ControllerTypeByTenantListComponent implements OnInit {
    @Input('list') list: ControllerTypeByTenant[] = [];
    @Output() onRefresh = new EventEmitter<{}>();

    selected = {} as ControllerTypeByTenant; 

    expanded: boolean[] = [];
    subscriptionTenant: any;

    columnsToDisplay = ['index', 'ct', 'actions'];
    public dataSource = new MatTableDataSource<ControllerTypeByTenant>();

    constructor(
        private http: HttpClient
        , private saveService: SaveService
        , private dialogService: DialogService
        , public defaults: DefaultValuesService
        , @Inject('BASE_URL') private baseUrl: string
        , private dialog: MatDialog
        , private customDialog: CustomDialogService
    ) {
    }

    ngOnInit() {     
        this.dialogService.tthis = this;
        this.subscriptionTenant = this.defaults.tenantMessageChanges$.subscribe(() => this.onRefresh.emit({}));
    }

    ngOnChanges() {
        this.dataSource.data = this.list;
    }

    ngOnDestroy() {
        //this.subscriptionControllerType.unsubscribe();
        this.subscriptionTenant.unsubscribe();
    }
    onDown(value: ControllerTypeByTenant) {
        this.move(value, "down")
    }

    onExpand(value: any) {
        if (value.expanded) {
            this.selected = value.value;
        }
    }
    /**
     * called by the table list
     * */
    onDelete(value: ControllerTypeByTenant ) {
        this.dialogService.showConfirmDialog("Delete ControllerType " + value.Description + " for " + value.Name + "?", "Delete ControllerType by Tenant", this, value);
    }
    /**
     * called by the DialogService
     * */
    onYes(value: ControllerTypeByTenant ) {
        this.http
            .delete<ControllerTypeByTenant>(
                this.baseUrl + Utils.getControllerTypeAPI() + 'controllertypebytenant/' + value.ControllerTypesByTenantID
            )
            .subscribe(
                () => this.onRefresh.emit({})
                , error => this.dialogService.showErrorDialog(error)
            );
    }

    onUp(value: ControllerTypeByTenant) {        
        this.move(value, "up")
    }

    private move(value: ControllerTypeByTenant, direction: string) {
        this.http
            .put<ControllerTypeByTenant>(
                this.baseUrl + Utils.getControllerTypeAPI() + 'controllertypebytenant-' + direction
                , value
            )
            .subscribe(
                () => this.onRefresh.emit({})
                , error => this.customDialog.openDisplayInfoDialog({ title: error.error.message.includes('Error') ? 'Error': 'Warning', message: error.error.message })
                // , error => this.dialogService.showErrorDialog(error)
            );
    }
}