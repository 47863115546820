import { Component, Inject, OnInit, Input, OnChanges } from "@angular/core";
import { FormGroup, FormBuilder } from '@angular/forms';

import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Utils } from "../../utils/utils";
// import { VirtualAction } from "rxjs/scheduler/VirtualTimeScheduler";
import { DialogService } from "../../services/dialog.service";

const ACTIVE = 'active';

@Component({
    selector: "errorcode-edit-virtualcoach",
    templateUrl: './errorcode-edit-virtualcoach.component.html',
    styleUrls: ['../../style/form.component.less', 'errorcode-list.component.less']
})

export class ErrorCodeEditVirtualCoachComponent implements OnChanges, OnInit {
    //selected: ErrorCode = <ErrorCode>{};
    @Input() list: ErrorCode[] = [];
    data: ErrorCode[] = [];
    virtualcoach: VirtualCoach[] = [];

    classerrorlist: string = ACTIVE;
    classvirtualcoach: string = '';

    form!: FormGroup;

    constructor(
        private activatedRoute: ActivatedRoute
        , private router: Router
        , private http: HttpClient
        , private fb: FormBuilder
        , @Inject('BASE_URL') private baseUrl: string
        , private dialog: DialogService
    ) {
        this.createForm();
        //create an empty object from OperationMode interface
    }

    ngOnChanges() {
        this.onReset();
    }

    ngOnInit() {
        this.dialog.tthis = this;
    }

    createForm() {
        this.form = this.fb.group({
            ErrorCode: [''],
        });
    }

    getHeader(): any {
        return [
            { label: 'Error Code' }
            , { label: 'Description' }
            , { label: 'Controller Type' }
        ];
    }

    getHeaderVirtualCoach(): any {
        return [
            { label: 'Maintenance Failure' }
            , { label: 'Troubleshooting Action' }
            , { label: 'Score' }
        ];
    }

    /**
     * this is from who calls (inner component)
     * @param list
     * @param row
     * @param column
     */
    getData(list: ErrorCode[], row: number, column: number): string {
        if (list) {
            var e = list[row];
            switch (column) {
                case 0:
                    return e.ErrorCode.toString();
                case 1:
                    return e.Description;
                default:
                    return e.BK;
            }
        }
        else
            return "";
    }

    /**
     * this is from who calls (inner component)
     * @param list
     * @param row
     * @param column
     */
    getDataVirtualCoach(list: VirtualCoach[], row: number, column: number): string {
        if (list) {
            var e = list[row];
            switch (column) {
                case 0:
                    return e.Maintenancefailure;
                case 1:
                    return e.TroubleshootingAction;
                default:
                    return e.Score.toString();
            }
        }
        else
            return "";
    }
    onAdd() {
        if (this.form) {
            var index = this.form.value.ErrorCode;
            this.data.push(this.list[index]);
        }
    }

    onReset() {
        this.data = [];
        this.virtualcoach = [];
        this.classerrorlist = ACTIVE;
        this.classvirtualcoach = "";

        if (this.form) {
            this.form.setValue({
                ErrorCode: ''
            });
        }
    }

    onSimulate() {
        this.classerrorlist = "";
        this.classvirtualcoach = ACTIVE;
        //execute the stored procedure that return the virtual coach
        this.http
            .put<VirtualCoach[]>(
                this.baseUrl + Utils.getErrorCodeAPI() + "addvirtualcoach"
                , this.data
            )
            .subscribe(res => {
                this.virtualcoach = res;
            }, error => this.dialog.showErrorDialog(error));
    }
}