<div class="example-container-backendsetup">
    <h2>Certificates</h2>
    <table mat-table
           [dataSource]="dataSource"
           multiTemplateDataRows
           matSort
           class="mat-elevation-z8 mat-table-custom" id="certificate-list">

        <ng-container matColumnDef="certificate">
            <th mat-header-cell *matHeaderCellDef> Certificate Name </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.Name"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button matTooltip="Download" (click)="downloadCertificates(element.isSSL)">
                    <mat-icon>download</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; let i = dataIndex;
            columns: columnsToDisplay;"
            class="opmode-item">
        </tr>
    </table>
</div>