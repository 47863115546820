import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { API_ROUTES, Utils } from "../utils/utils";
import { Subject } from "rxjs";

@Injectable()
export class EquipmentManagenementService {

    //tenantSubject: Subject<EmtConfiguration[]>;

    constructor(
        private httpClient: HttpClient,
        @Inject('BASE_URL') private baseUrl: string
    ){}

    searchEquipmentConfiguration(
        level: EquipmentConfigurationLevel,
        callback: any | undefined = undefined,
        errorCallback: any | undefined = undefined)
    {
        const url = this.baseUrl + API_ROUTES.API_EMT + "/configuration/search";
        this.httpClient.post<EquipmentConfiguration[]>(url,level)
        .subscribe({next: response => {
            if(callback)
                callback(response);
        }, error: error => {
            Utils.writeLog(this.httpClient,this.baseUrl,error);
            if(errorCallback)
                errorCallback(error);
        }});        
    }

    initEquipmentConfiguration(
        level: EquipmentConfigurationLevel,
        callback: any | undefined = undefined, 
        errorCallback: any | undefined = undefined) 
    {
        const url = this.baseUrl + API_ROUTES.API_EMT + "/configuration";
        this.httpClient.post<EquipmentConfiguration[]>(url,level)
            .subscribe({next: response => {
                if(callback)
                    callback(response);
            }, error: error => {
                Utils.writeLog(this.httpClient,this.baseUrl,error);
                if(errorCallback)
                    errorCallback(error);
            }}
        );       
    }

    updateEquipmentConfiguration(
        updateRequests: UpdateEquipmentConfigurationValueRequest[],
        callback: any | undefined = undefined, 
        errorCallback: any | undefined = undefined)
    {
        const url = this.baseUrl + API_ROUTES.API_EMT + "/configuration";
        this.httpClient.put<EquipmentConfiguration[]>(url,updateRequests)
            .subscribe({next: response => {
                if(callback)
                    callback(response);
            }, error: error => {
                Utils.writeLog(this.httpClient,this.baseUrl,error);
                if(errorCallback)
                    errorCallback(error);
            }}
        );
    }

    getUserLevels(callback: any | undefined = undefined, errorCallback: any | undefined = undefined)
    {
        const url = this.baseUrl + API_ROUTES.API_EMT + "/configuration/levels";
        this.httpClient.get(url).subscribe({
            next: response => {
                if(callback)
                    callback(response);
            }, error: error => {
                Utils.writeLog(this.httpClient,this.baseUrl,error);
                if(errorCallback)
                    errorCallback(error);
            }}
        );
        }

}