<div class=" dialog.classname">
    <!--<controllertypes-menu></controllertypes-menu>--> <!--(newEvent)="onNewControllerConfig()"-->
    <div style="display:flow-root">
        <div style="float:left; width:40vw">
            <h2>{{title}} for {{defaults.controllertype.Description}}</h2>
        </div>
        <div style="text-align:right; float:right">
            <h5>**A PBI must be created and assigned to anyone from DevOps Team for deploying new changes.**</h5>
        </div>
    </div>
    <div class="">
        <div class="tab-body">
            <div class="tab-content">

                <mat-tab-group #tabGroup (selectedTabChange)="defaults.updateComponentTab($event.tab.textLabel)">

                    <!-- ControllerConfig -->
                    <mat-tab label="Controller Configuration">
                        <div>
                            <controllerconfig></controllerconfig>
                        </div>
                    </mat-tab>

                    <!-- ControllerTypeByTenant -->
                    <mat-tab label="ControllerType by Tenant">
                        <div>
                            <controllertypebytenant></controllertypebytenant>
                        </div>
                    </mat-tab>

                    <!-- ControllerTypeToMsterdataRequired -->
                    <mat-tab label="ControllerType to Masterdata Required">
                        <div>
                            <controllertypetomasterdatarequired></controllertypetomasterdatarequired>
                        </div>
                    </mat-tab>

                    <!-- ControllerTypeToMsterdataRequired -->
                    <mat-tab label="Default Transmission Mode">

                        <div>
                            <defaulttransmissionmode-list></defaulttransmissionmode-list>
                        </div>
                    </mat-tab>

                    <mat-tab label="Alert Merge Configuration">
                        <div>
                            <alertmergeconfiguration-list></alertmergeconfiguration-list>
                        </div>
                    </mat-tab>


                </mat-tab-group>

            </div> <!--tab-content-->
        </div> <!--tab-body-->
    </div> <!--inner-tcontainer-tab-->
</div> <!--container-tab-lists-->
