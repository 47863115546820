import { Component, Output, OnInit, Inject, EventEmitter, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Utils } from '../../utils/utils';

@Component({
    selector: 'translation-area',
    template: `TranslationArea:<br />
               <select name="ct" (change)="onSelect($event)" title="Change the Translation Area">
                    <option *ngFor="let ta of list; index as i" value="{{i}}">{{ta.Name}} - {{ta.TranslationAreaID}}</option>
               </select>               
  `
})
export class TranslationAreaComponent implements OnInit {
    list: TranslationArea[] = [];
    @Output() translationChanged = new EventEmitter<TranslationArea>();

    constructor(
        private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
    ) {
    }

    ngOnInit() {
        Utils.httpGetAllTranslationAreas(
            this.http
            , this.baseUrl
            , this
            , function (tthis: any, areas: TranslationArea[]) {
                tthis.list = areas;
                tthis.translationChanged.emit(tthis.list[0]);
            }
        );
    }

    onSelect(ta: any) {
        if (!ta.target || !this.list) return;
        this.translationChanged.emit(this.list[ta.target.value]);
    }
}