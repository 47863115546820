import { Component, OnInit, OnDestroy, Inject, ViewChild, Output, EventEmitter } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { DefaultValuesService } from '../../../../services/defaultvalues.service';
import { Utils } from '../../../../utils/utils';
import { SaveService } from '../../../../services/save.service';
import { Subscription } from "rxjs";
import { ContractBundleListComponent } from "./contractbundlelist.component";
import { group } from "@angular/animations";

@Component({
    selector: "contractbundle",
    templateUrl: 'contractbundle.component.html',
    styleUrls: []
})

export class ContractBundleComponent implements OnInit, OnDestroy {

    list: any[] = [];
    contractBundleOptions: any[] = [];

    showLocalFilter = false;

    subscriptionTenant: any;
    subscriptions: Subscription[] = [];

    @ViewChild(ContractBundleListComponent, { static: true }) listComponent!: ContractBundleListComponent;

    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string
        , private defaults: DefaultValuesService
        , private saveService: SaveService
    ) {
    }

    ngOnInit(): void {
        this.subscriptionTenant = this.defaults.tenantMessageChanges$.subscribe(() => {
            this.getContractBundlesByTenant()
        });
        if (this.defaults.tenant) {
            this.getContractBundlesByTenant()
            this.getContractBundleOptionsByTenant()
        }
    }


    ngOnDestroy() {
        this.subscriptions.forEach(x => x.unsubscribe())
        this.subscriptionTenant.unsubscribe();
    }

    getContractBundlesByTenant() {
        Utils.httpGetContractBundleInformation(
            this.http
            , this.baseUrl
            , this
            , function (tthis: ContractBundleComponent, list: ContractBundle[]) {
                const grouped = {};
                list.forEach((e) => {
                    if (!grouped[e.Bundle]) {
                        grouped[e.Bundle] = [];
                    }
                    grouped[e.Bundle].push(e.Feature);
                });

                const result = Object.keys(grouped).map((bundle) => ({ Bundle: bundle, BundleId: list.filter(x => x.Bundle === bundle)[0].BundleId, BundleList: grouped[bundle] }));
                tthis.list = result;
            }
        );
    }

    getContractBundleOptionsByTenant() {
        Utils.httpGetContractBundleOptions(
            this.http
            , this.baseUrl
            , this
            , function (tthis: any, list: ContractBundle[]) {
                const grouped = {};
                list.forEach((e) => {
                    if (!grouped[e.Bundle]) {
                        grouped[e.Bundle] = [];
                    }
                    grouped[e.Bundle].push(e.Feature);
                });

                const result = Object.keys(grouped).map((bundle) => ({ Bundle: bundle, BundleId: list.filter(x => x.Bundle === bundle)[0].BundleId, BundleList: grouped[bundle] }));
                tthis.contractBundleOptions = result
            }
        );
    }

    onReload() {
        this.getContractBundlesByTenant();
    }
}
