<div class="custom-mat-dialog-form">
    <div>
        <h3>Create new Contact</h3>
    </div>
    <div>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-sm-12 form-group">

                    <!--Email Region-->
                    <div>
                        <mat-form-field appearance="outline">
                            <mat-label>Contact Name</mat-label>
                            <input matInput formControlName="contactName" required>
                        </mat-form-field>

                        <p *ngIf="
                form.get('contactName')?.invalid &&
                  (form.get('contactName')?.dirty ||
                    form.get('contactName')?.touched)">
                            <mat-error *ngIf="form.get('contactName')?.hasError('required')">This field is required</mat-error>
                            <mat-error *ngIf="form.get('contactName')?.hasError('pattern')">
                                Enter valid Contact Name
                            </mat-error>
                        </p>
                    </div>

                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 form-group">

                    <!--Email Region-->
                    <div>
                        <mat-form-field appearance="outline">
                            <mat-label>Contact Email</mat-label>
                            <input matInput placeholder="pat@example.com" formControlName="contactEmail" required>
                        </mat-form-field>

                        <p *ngIf="
                  form.get('contactEmail')?.invalid &&
                    (form.get('contactEmail')?.dirty ||
                      form.get('contactEmail')?.touched)">
                            <mat-error *ngIf="form.get('contactEmail')?.hasError('required')">This field is required</mat-error>
                            <mat-error *ngIf="form.get('contactEmail')?.hasError('pattern')">
                                Enter valid Email
                            </mat-error>
                        </p>
                        <p *ngIf="!(
  form.get('contactEmail')?.invalid &&
    (form.get('contactEmail')?.dirty ||
      form.get('contactEmail')?.touched))"><br> </p>
                    </div>

                </div>
            </div>
            <div class="row">
                <div class="panel-body">
                    <button (click)="backButtonHandler($event)" mat-raised-button class="btn  button-color-fix_red ">
                        <mat-icon class=" icon-color-fix ">arrow_back_ios</mat-icon>
                        Back
                    </button>
                    <button mat-raised-button type="submit" class="btn button-color-fix pull-right" [disabled]="!form.valid">
                        Create
                        <mat-icon class=" icon-color-fix ">person</mat-icon>
                    </button>

                </div>
            </div>
        </form>
    </div>
</div>
