<div style="display:flow-root">
    <div style="float:left; width:65%">
        <mat-form-field class="search-input">
            <input placeholder="Filter"
                   matInput
                   [matChipInputFor]="chipList"
                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                   [matChipInputAddOnBlur]="addOnBlur"
                   (matChipInputTokenEnd)="applyFilter($event)">
            <mat-chip-list #chipList>
            </mat-chip-list>
        </mat-form-field>
        <mat-chip *ngFor="let item of searchItems" [selectable]="selectable"
                  [removable]="removable" (removed)="remove(item)">
            {{item.name}}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
    </div>
    <div style="text-align:right; float:right; width:35%">
        <h2 style="cursor:pointer; display:block;" class="flex-aligned-div" (click)="openDeploymentInformationPopup()">
            Deployment information
            <mat-icon data-html="true" style="color:white">info</mat-icon>
        </h2>
        
    </div>
</div>
<div class="example-container">
    <errorcode-new [header]="header" [list]="list" *ngIf="show" (cancelEvent)="onCancelErrorCodeCreation()"></errorcode-new>
    <table mat-table
           [dataSource]="dataSource" multiTemplateDataRows id="errorcodeproperties"
           class="mat-elevation-z8 mat-table-custom">
        <ng-container matColumnDef="arrow">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element"
                (click)="pushPopElement(element, false)">
                <span class="material-icons" *ngIf="checkExpanded(element, false); else show_expand_more">
                    expand_less
                </span>
            </td>

        </ng-container>
        <ng-container matColumnDef="arrow-device">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <div [hidden]="this.defaults.controllertype.BK !== 'CT1001'"
                     (click)="pushPopElement(element, true)">
                    <span class="material-icons" *ngIf="checkExpanded(element, true); else show_devices_expand_more">
                        expand_less
                    </span>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="errorcode">
            <th mat-header-cell *matHeaderCellDef> Error Code </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.ErrorCode" *ngIf="!isEditingRow(element)"></p>
                <mat-form-field floatLabel="never" *ngIf="isEditingRow(element)">
                    <input matInput [value]="element.ErrorCode" [(ngModel)]="element.ErrorCode">
                </mat-form-field>
            </td>
            <td mat-cell *matCellDef="let element"> {{element.ErrorCode}}</td>
        </ng-container>

        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.Description"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="nresolutions">
            <th mat-header-cell *matHeaderCellDef> Number of Resolutions </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.Resolutions"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="errorweight">
            <th mat-header-cell *matHeaderCellDef>
            <tr>Error Weight </tr>
            <mat-select class='form-control filter' (selectionChange)="filterWeight($event)" [(ngModel)]="weightSelected">
                <mat-option [value]="-1">Select</mat-option>
                <mat-option *ngFor="let weight of errorWeights" [value]="weight">
                    {{ weight }}
                </mat-option>
            </mat-select>
            </th>
            <td mat-cell *matCellDef="let element">
                <p style="font-weight: bold"
                   [innerHTML]="element.ErrorWeight"
                   [class.red]="element.ErrorWeight === 3"
                   [class.orange]="element.ErrorWeight === 2"
                   [class.yellow]="element.ErrorWeight === 1"
                   [class.green]="element.ErrorWeight === 0"
                   *ngIf="!isEditingRow(element)"></p>
                <mat-form-field floatLabel="never" *ngIf="isEditingRow(element)">
                    <mat-select style="min-width: 200px; color:red" [(ngModel)]="element.ErrorWeight">
                        <mat-option [value]="-1">Select</mat-option>
                        <mat-option *ngFor="let weight of errorWeights" [value]="weight">
                            {{ weight }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container matColumnDef="controllerblocked">
            <th mat-header-cell *matHeaderCellDef>
            <tr>Controller Blocked</tr>
            <mat-select class='form-control filter' placeholder='Select' [(ngModel)]="controllerBlockedSelected" (selectionChange)="filterCheckbox($event, 'controllerblocked')">
                <mat-option value="-1">Select</mat-option>
                <mat-option value="Yes">Available</mat-option>
                <mat-option value="No">Unavailable</mat-option>
            </mat-select>
            </th>
            <td mat-cell *matCellDef="let element">
                <mat-checkbox [checked]="element.ControllerBlocked" (change)="changeElement(element, 'controllerblocked', $event.checked)" [ngClass]="isEditingRow(element) ? 'black' :''" [disabled]="!isEditingRow(element)"></mat-checkbox>
            </td>
        </ng-container>

        <ng-container matColumnDef="ispotentialremoteresetfix">
            <th mat-header-cell *matHeaderCellDef>
            <tr>Potential Remote Reset Fix?<mat-icon  matTooltipClass='opmodes-custom-tooltip' data-html="true" matTooltip="Remote Reset potentially could fix the issue">info</mat-icon></tr>
            <mat-select class='form-control filter' placeholder='Select' [(ngModel)]="potentialRRFixSelected" (selectionChange)="filterCheckbox($event, 'ispotentialremoteresetfix')">
                <mat-option value="-1">Select</mat-option>
                <mat-option value="Yes">Yes</mat-option>
                <mat-option value="No">No</mat-option>
            </mat-select>
            </th>
            <td mat-cell *matCellDef="let element">
                <mat-checkbox [checked]="element.IsPotentialRemoteResetFix" (change)="changeElement(element, 'ispotentialremoteresetfix', $event.checked)" [ngClass]="isEditingRow(element) ? 'black' :''" [disabled]="!isEditingRow(element)"></mat-checkbox>
            </td>
        </ng-container>

        <ng-container matColumnDef="isgoodevent">
            <th mat-header-cell *matHeaderCellDef>
            <tr> Is Good Event? </tr>
            <mat-select class='form-control filter' placeholder='Select' (selectionChange)="filterColumn($event, 'isgoodevent')">
                <mat-option [value]="-1">Select</mat-option>
                <mat-option value="true">Yes</mat-option>
                <mat-option value="false">No</mat-option>
            </mat-select>
            </th>
            <td mat-cell *matCellDef="let element">
                <mat-checkbox [checked]="element.IsGoodEvent" [ngClass]="isEditingRow(element) ? 'black' :''" [disabled]="true" *ngIf="!isEditingRow(element)"></mat-checkbox>
                <mat-select [(ngModel)]="element.IsGoodEvent" *ngIf="isEditingRow(element)">
                    <mat-option [value]="undefined">Select</mat-option>
                    <mat-option [value]="true">Yes</mat-option>
                    <mat-option [value]="false">No</mat-option>
                </mat-select>
            </td>
        </ng-container>

        <ng-container matColumnDef="component">
            <th mat-header-cell *matHeaderCellDef> Component </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.Component | bold: dataSource.filter" *ngIf="!isEditingRow(element)"></p>
                <mat-form-field floatLabel="never" *ngIf="isEditingRow(element)">
                    <input matInput [value]="element.Component" [(ngModel)]="element.Component">
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container matColumnDef="stablealertrule">
            <th mat-header-cell *matHeaderCellDef>
            <tr>Stable Alert Rule <mat-icon  matTooltipClass='opmodes-custom-tooltip' data-html="true" matTooltip="Alert rule selector for Stable and Evaluation environments.">info</mat-icon></tr>
            <mat-select style="min-width: 150px;" class='form-control filter' placeholder='Select' (selectionChange)="filterColumn($event, 'stablealertruleid')">
                <mat-option [value]="-1">Select</mat-option>
                <mat-option *ngFor="let dep of alertRules" [value]="dep.Id">
                    {{ dep.Description }}
                </mat-option>
            </mat-select>
            </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.StableAlertRuleCat | bold: dataSource.filter" *ngIf="!isEditingRow(element) && !element.StableAlertRuleCat.startsWith('N/A')"></p>
                <mat-form-field floatLabel="never" *ngIf="isEditingRow(element)">
                    <mat-select style="min-width: 200px; color:red" [(ngModel)]="element.StableAlertRuleCatId">
                        <mat-option *ngFor="let dep of alertRules" [value]="dep.Id">
                            {{ dep.Description }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container matColumnDef="prodalertrule">
            <th mat-header-cell *matHeaderCellDef>
            <tr>Production Alert Rule <mat-icon  matTooltipClass='opmodes-custom-tooltip' data-html="true" matTooltip="Alert rule selector for Production environment.">info</mat-icon></tr>
            <mat-select style="min-width: 150px;" class='form-control filter' placeholder='Select' (selectionChange)="filterColumn($event, 'prodalertruleid')">
                <mat-option [value]="-1">Select</mat-option>
                <mat-option *ngFor="let dep of alertRules" [value]="dep.Id">
                    {{ dep.Description }}
                </mat-option>
            </mat-select>
            </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.ProdAlertRuleCat | bold: dataSource.filter" *ngIf="!isEditingRow(element) && !element.ProdAlertRuleCat.startsWith('N/A')"></p>
                <mat-form-field floatLabel="never" *ngIf="isEditingRow(element)">
                    <mat-select style="min-width: 200px; color:red" [(ngModel)]="element.ProdAlertRuleCatId">
                        <mat-option *ngFor="let dep of alertRules" [value]="dep.Id">
                            {{ dep.Description }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let element">
                <ng-container>
                    <button mat-icon-button matTooltip="Edit Row" (click)="onRowEditInit(element)" *ngIf="!isEditingRow(element)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="Delete" *ngIf="!isEditingRow(element)"
                            (click)="onDelete(element)">
                        <mat-icon>delete</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="Cancel Row" *ngIf="isEditingRow(element)" (click)="onRowEditCancel(element)">
                        <mat-icon>rotate_left</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="Add Resolution" *ngIf="checkExpanded(element, false)" (click)="onCreateNewResol(element)">
                        <mat-icon>library_add</mat-icon>
                    </button>
                </ng-container>
            </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                <div [@detailExpand]="checkExpanded(element, false) ? 'expanded' : 'collapsed'" *ngIf="element.ErrorCodeResolutions">
                    <table mat-table [dataSource]="element.ErrorCodeResolutions" class="mat-subtable-custom" id="errorcoderesolutions">

                        <ng-container matColumnDef="failure">
                            <th mat-header-cell *matHeaderCellDef> Failure </th>
                            <td mat-cell *matCellDef="let element2">
                                <p [innerHTML]="element2.MaintenanceFailure" *ngIf="!isCreatingResol(element2)"></p>
                                <mat-form-field floatLabel="never" *ngIf="isCreatingResol(element2)">
                                    <input matInput [value]="element2.MaintenanceFailure" [(ngModel)]="element2.MaintenanceFailure">
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef>
                                Action
                                <mat-icon matTooltipClass='opmodes-custom-tooltip'
                                          (click)="onShowKeywords()"
                                          *ngIf="showKeywordsTooltip(element)"
                                          matTooltip="Add keyword">star</mat-icon>
                            </th>
                            <td mat-cell *matCellDef="let element2">
                                <div [innerHTML]="highlightTroubleshootingWithKeywords(element2.TroubleShootingAction)" *ngIf="!isCreatingResol(element2)"></div>
                                <mat-form-field floatLabel="never" *ngIf="isCreatingResol(element2)">
                                    <input matInput [value]="element2.TroubleShootingAction" [(ngModel)]="element2.TroubleShootingAction"
                                           *ngIf="!isShowingKeywordsSelector || element2.ErrorCodeID !== selectedErrorCode"
                                           id="errorcode_{{element2.ErrorCodeID}}"
                                           (click)="selectTroubleshootingInput(element2)">
                                    <mat-select style="max-width:100px"
                                                id="keywords_{{element2.ErrorCodeID}}"
                                                (selectionChange)="onSelectItem($event.value)"
                                                (openedChange)="openedChange($event)"
                                                *ngIf="element2.ErrorCodeID === selectedErrorCode && isShowingKeywordsSelector">
                                        <mat-option *ngFor="let keyword of keywords" [value]="keyword">
                                            {{ keyword }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="weight">
                            <th mat-header-cell *matHeaderCellDef> Weight </th>
                            <td mat-cell *matCellDef="let element2">
                                <p [innerHTML]="element2.TroubleShootingActionWeight" *ngIf="!isEditingResol(element2) && !isCreatingResol(element2)"></p>
                                <mat-form-field floatLabel="never" *ngIf="isEditingResol(element2) || isCreatingResol(element2)">
                                    <mat-select style="max-width:100px" [(ngModel)]="element2.TroubleShootingActionWeight">
                                        <mat-option *ngFor="let weight of resolWeights" [value]="weight">
                                            {{ weight }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="location">
                            <th mat-header-cell *matHeaderCellDef> Location </th>
                            <td mat-cell *matCellDef="let element2">
                                <p [innerHTML]="element2.SubSystemGroup" *ngIf="!isEditingResol(element2) && !isCreatingResol(element2)"></p>
                                <mat-form-field floatLabel="never" *ngIf="isEditingResol(element2) || isCreatingResol(element2)">
                                    <mat-select style="min-width: 150px" [(ngModel)]="element2.SubSystemGroupID" (selectionChange)="onLocationChange(element2)">
                                        <mat-option *ngFor="let location of locations" [value]="location.ComponentSubSystemID">
                                            {{ location.Description }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="component">
                            <th mat-header-cell *matHeaderCellDef> Component </th>
                            <td mat-cell *matCellDef="let element2">
                                <p [innerHTML]="element2.ComponentItem" *ngIf="!isEditingResol(element2) && !isCreatingResol(element2)"></p>
                                <mat-form-field floatLabel="never" *ngIf="isEditingResol(element2) || isCreatingResol(element2)">
                                    <mat-select style="min-width: 150px" [(ngModel)]="element2.ComponentItemID">
                                        <mat-option *ngFor="let component of element2.components" [value]="component.ComponentItemID">
                                            {{ component.ComponentKey }} - {{ component.Description }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="cause">
                            <th mat-header-cell *matHeaderCellDef> Cause </th>
                            <td mat-cell *matCellDef="let element2">
                                <p [innerHTML]="element2.Cause" *ngIf="!isEditingResol(element2) && !isCreatingResol(element2)"></p>
                                <mat-form-field floatLabel="never" *ngIf="isEditingResol(element2) || isCreatingResol(element2)">
                                    <mat-select [(ngModel)]="element2.CauseID">
                                        <mat-option *ngFor="let cause of causes" [value]="cause.BusinessKeyID">
                                            {{ cause.BK }} - {{ cause.Description }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="resolution">
                            <th mat-header-cell *matHeaderCellDef> Resolution </th>
                            <td mat-cell *matCellDef="let element2">
                                <p [innerHTML]="element2.Resolution" *ngIf="!isEditingResol(element2) && !isCreatingResol(element2)"></p>
                                <mat-form-field floatLabel="never" *ngIf="isEditingResol(element2) || isCreatingResol(element2)">
                                    <mat-select style="min-width: 200px; color:red" [(ngModel)]="element2.ResolutionID">
                                        <mat-option *ngFor="let resol of resolutions" [value]="resol.BusinessKeyID">
                                            {{ resol.BK }} - {{ resol.Description }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="partsrequired">
                            <th mat-header-cell *matHeaderCellDef> Parts Required ? </th>
                            <td mat-cell *matCellDef="let element2">
                                <mat-checkbox [checked]="element2.PartsRequired" (change)="changeResol(element2, 'partsrequired', $event.checked)" [ngClass]="isEditingResol(element2) ? 'black' :''" [disabled]="!isEditingResol(element2) && !isCreatingResol(element2)"></mat-checkbox>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="highvaluepart">
                            <th mat-header-cell *matHeaderCellDef> High Value Part ? </th>
                            <td mat-cell *matCellDef="let element2">
                                <mat-checkbox [checked]="element2.HighValuePart" (change)="changeResol(element2, 'highvaluepart', $event.checked)" [ngClass]="isEditingResol(element2) ? 'black' :''" [disabled]="!isEditingResol(element2) && !isCreatingResol(element2)"></mat-checkbox>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element2">
                                <button mat-icon-button matTooltip="Edit Row" (click)="onRowResolEditInit(element2)" *ngIf="!isEditingResol(element2) && !isCreatingResol(element2)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                                <button mat-icon-button matTooltip="Delete" (click)="onDeleteResol(element2)" *ngIf="!isCreatingResol(element2)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                                <button mat-icon-button matTooltip="Cancel Row" (click)="onResolEditCancel(element2)" *ngIf="isEditingResol(element2) || isCreatingResol(element2)">
                                    <mat-icon>rotate_left</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row class="subtableheader" *matHeaderRowDef="subcolumnsToDisplay"></tr>
                        <tr mat-row *matRowDef="let element2; let i2 = index; columns: subcolumnsToDisplay;"
                            [class.alternative2]="isEditingResol(element2)"
                            [class.alternative3]="isCreatingResol(element2)"
                            class="subitem"></tr>
                    </table>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail2">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                <div [@detailExpand]="checkExpanded(element, true) ? 'expanded' : 'collapsed'" *ngIf="this.defaults.controllertype.BK === 'CT1001'">
                    <table mat-table [dataSource]="element.DESDevices" class="mat-subtable-custom" id="errorcoderesolutions">

                        <ng-container matColumnDef="deviceName">
                            <th mat-header-cell *matHeaderCellDef> DeviceName </th>
                            <td mat-cell *matCellDef="let element2">
                                <p [innerHTML]="element2.DeviceName"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="sentByDevice">
                            <th mat-header-cell *matHeaderCellDef> Is Sent By Device? </th>
                            <td mat-cell *matCellDef="let element2">
                                <mat-checkbox [checked]="element2.SentByDevice" [disabled]="true"></mat-checkbox>
                            </td>
                        </ng-container>

                        <tr mat-header-row class="subtableheader" *matHeaderRowDef="desSubcolumns"></tr>
                        <tr mat-row *matRowDef="let element2; let i2 = index; columns: desSubcolumns;"
                            class="subitem"></tr>
                    </table>
                </div>
            </td>
        </ng-container>


        <tr mat-header-row class="tableheader" *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; let i = dataIndex; columns: columnsToDisplay;"
            class="item"
            [class.alternative2]="isEditingRow(element)"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="row-subitem"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail2']" class="row-subitem"></tr>
    </table>
</div>

<ng-template #show_expand_more>
    <span class="material-icons">
        expand_more
    </span>
</ng-template>


<ng-template #show_devices_expand_more>
    <span class="material-icons" matTooltip="Devices">
        build
    </span>
</ng-template>
