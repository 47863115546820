import { Component, Inject, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { SaveService } from '../../services/save.service';
import { DialogService } from '../../services/dialog.service';
import { CustomDialogService } from '../../services/customdialog.service';
import { DefaultValuesService } from '../../services/defaultvalues.service';
import { MatDialog } from "@angular/material/dialog";
import { TestCaseFormComponent } from './testcase-form.component';
import { TestCaseFormDatabricksTriggerComponent } from '../testcase/testcase-form-databricks-trigger.component';

@Component({
    selector: 'testcase-menu',
    templateUrl: './testcase-menu.component.html',
    styleUrls: ['../../style/menu.component.less']
})

export class TestCaseMenuComponent {
    @Input('showMenuViewValue') showMenuViewValue = 0;
    @Output() onNew = new EventEmitter<{}>();
    @Output() onRefresh = new EventEmitter<{}>();

    constructor(
        private router: Router
        , @Inject('BASE_URL') private baseUrl: string
        , public saveService: SaveService
        , private dialog: DialogService
        , private customDialog: CustomDialogService
        , private http: HttpClient
        , public defaults: DefaultValuesService
        , private matDialog: MatDialog

    ) {
    }

    onNewTestCase() {
        this.dialog.tthis = this;
        this.saveService.addListener(this);
        const dialogRef = this.matDialog.open(TestCaseFormComponent, { panelClass: ['custom-mat-dialog', 'popup-dialog'] });
        dialogRef.afterClosed().subscribe(() => {
            this.onRefresh.emit({});
        });
    }

    onNewDatabricksTrigger() {
        this.dialog.tthis = this;
        this.saveService.addListener(this);
        const dialogRef = this.matDialog.open(TestCaseFormDatabricksTriggerComponent, { panelClass: 'custom-mat-dialog' });
        dialogRef.afterClosed().subscribe(() => {
            this.onRefresh.emit({});
        });
    }
}
