import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from
    "@angular/forms";
import { ActivatedRoute } from '@angular/router';
import { forkJoin, EMPTY } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from "@angular/forms";
import { DialogService } from '../../../../services/dialog.service';



interface Item {
    id: number;
    persona: string;
    // Add more properties if needed
}



@Component({
    selector: "additionalusersdialogue",
    templateUrl: './additionalusersdialogue.component.html',
    styleUrls: ['./additionalusersdialogue.component.less'],

})

export class AdditionalUsersDialogue {
    additionalUsers: any;
    constructor(
        private http: HttpClient
        , public dialogRef: MatDialogRef<AdditionalUsersDialogue>
        , private confimDialog: DialogService
        , private toastrService: ToastrService,
        private route: ActivatedRoute,
        @Inject(MAT_DIALOG_DATA) public data: any,

    ) {
        this.additionalUsers = data.additionalUsers


    }

   
    backButtonHandler(event: any) {
        event.preventDefault();
        this.dialogRef.close();

    }
    



}
