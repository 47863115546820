import { Component, Inject, OnInit, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Utils } from "../../../utils/utils";
import { DefaultValuesService } from "../../../services/defaultvalues.service";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SaveService } from "../../../services/save.service";
import { DialogService } from "../../../services/dialog.service";

@Component({
    selector: "controllertype-form",
    templateUrl: './controllertype-form.component.html',
    styleUrls: ['../../../style/form.component.less']
})

export class ControllerTypeFormComponent implements OnInit {

    title: string;

    selected = {} as ControllerTypeCreation;
    countries: Country[] = [];
    assettypes: AssetType[] = [];
    controllerdrives: ControllerDrive[] = [];

    yesNoList: any[];

    newRow = true;
    form: FormGroup = new FormGroup({});

    constructor(
        private activatedRoute: ActivatedRoute
        , private dialog: DialogService
        , private router: Router
        , private defaults: DefaultValuesService
        , private http: HttpClient
        , private saveService: SaveService
        , @Inject(MAT_DIALOG_DATA) public data: TestCase
        , @Inject('BASE_URL') private baseUrl: string)
    {

        this.yesNoList = [{ label: "Yes", value: true }, { label: "No", value: false }];
        this.title = "Create new Controller Type";
        this.createForm();
    }

    ngOnInit() {
        this.saveService.addListener(this);

        const tthis = this;
        Utils.httpGetAllCountries(
            this.http
            , this.baseUrl
            , this
            , function (tthis: any, list: Country[]) {
                tthis.countries = list;

                Utils.httpGetAllAssetTypes(
                    tthis.http
                    , tthis.baseUrl
                    , tthis
                    , function (tthis: any, list: AssetType[]) {
                        tthis.assettypes = list;

                        Utils.httpGetAllControllerDrives(
                            tthis.http
                            , tthis.baseUrl
                            , tthis
                            , function (tthis: any, list: ControllerDrive[]) {
                                tthis.controllerdrives = list;
                                tthis.createForm();
                            }
                        );
                    }
                );
            }
        );
    }

    onCancelForm() {
        this.saveService.fireCancel();
    }

    onSubmit() {
        if (this.form && this.form.valid && this.newRow) {
            this.createObject();
            const tthis = this;
            const url = this.baseUrl + Utils.getControllerTypeAPI() + "add";
            this.http
                .put<ControllerTypeCreation>(url, this.selected)
                .subscribe(res => {
                        tthis.saveService.fireCancel();
                    }
                    , error => {
                        tthis.dialog.showErrorDialog(error);
                    }
                );
        }
    }

    private createForm() {
        this.form = new FormGroup({
            BK: new FormControl('', [Validators.required]),
            Description: new FormControl('', [Validators.required]),
            AssetType: new FormControl('', [Validators.required]),
            ControllerDrive: new FormControl('', [Validators.required]),
            Country: new FormControl('', [Validators.required]),
            ManyToOneProvisioning: new FormControl('', [Validators.required])
        });
    }

    private createObject() {

        this.selected.BK = this.form.controls['BK'].value;
        this.selected.Description = this.form.controls['Description'].value;
        this.selected._AssetTypeID = this.form.controls['AssetType'].value;
        this.selected.ControllerDriveID = this.form.controls['ControllerDrive'].value;
        this.selected.CountryOwnerID = this.form.controls['Country'].value;
        this.selected.ManyToOneProvisioning = this.form.controls['ManyToOneProvisioning'].value;
    }
}