<div class="custom-mat-dialog-form">
    <div>
        <h3>{{this.data ? 'Update' : 'Create new'}} Excellence Lever</h3>
    </div>
    <div>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-sm-6 form-group">
                    <div>
                        <mat-form-field appearance="outline">
                            <mat-label>Name</mat-label>
                            <input matInput formControlName="name" required>
                        </mat-form-field>

                        <p *ngIf="
       form.get('name')?.invalid &&
         (form.get('name')?.dirty ||
           form.get('name')?.touched)">
                            <mat-error *ngIf="form.get('name')?.hasError('required')">This field is required</mat-error>
                        </p>
                    </div>

                </div>
                <div class="col-sm-6 form-group">
                    <div>
                        <mat-form-field appearance="outline">
                            <mat-label>Function</mat-label>
                            <input matInput formControlName="function" required>
                        </mat-form-field>

                        <p *ngIf="
                  form.get('function')?.invalid &&
                    (form.get('function')?.dirty ||
                      form.get('function')?.touched)">
                            <mat-error *ngIf="form.get('function')?.hasError('required')">This field is required</mat-error>
                        </p>
                    </div>

                </div>
            </div>
            <div class="row">
                <div class="col-sm-4 form-group">
                    <br />
                    <mat-label> <span>Line of Business</span><span class="orange-asterisk"> *</span></mat-label>
                </div>
                <div class="col-sm-8 form-group">

                    <!--Primary Tenant Drop Down-->
                    <ng-select [items]="linesOfBusiness"
                               [searchable]="true"
                               [clearable]="false"
                               bindLabel="Name"
                               bindValue="LineOfBusinessId"
                               formControlName="lineOfBusinessId"
                               required>
                    </ng-select>
                    <br>

                </div>
            </div>
            <div class="row">
                <div class="panel-body">
                    <button (click)="backButtonHandler($event)" mat-raised-button class="btn  button-color-fix_red ">
                        <mat-icon class=" icon-color-fix ">arrow_back_ios</mat-icon>
                        Back
                    </button>
                    <button mat-raised-button type="submit" class="btn button-color-fix pull-right" [disabled]="!form.valid">
                        {{this.data ? 'Update' : 'Create'}}
                        <mat-icon class=" icon-color-fix ">save</mat-icon>
                    </button>

                </div>
            </div>
        </form>
    </div>
</div>
