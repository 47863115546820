import { Component, OnInit, OnDestroy, Inject, ViewChild } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Utils } from '../../../../utils/utils';
import { SaveService } from '../../../../services/save.service';
import { ElevatorListComponent } from './elevator-list.component';
import { ElevatorFilterComponent } from './elevator-filter.component';
import { SignalRService } from "../../../../services/signal-r";
import { DefaultValuesService } from "../../../../services/defaultvalues.service";

@Component({
    selector: "elevator",
    templateUrl: 'elevator.component.html',
})

export class ElevatorComponent implements OnInit, OnDestroy {

    list: Elevator[] = [];

    title = "Elevators";
    showLocalFilter = false;

    subscriptionStage: any;
    subscriptionTenant: any;
    subscriptions: any[] = [];

    @ViewChild(ElevatorListComponent, { static: true }) listComponent!: ElevatorListComponent;
    @ViewChild(ElevatorFilterComponent, { static: true }) filterComponent!: ElevatorFilterComponent;

    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string
        , private defaults: DefaultValuesService
        , public signalRService: SignalRService
        , private saveService: SaveService
    ) { }

    ngOnInit(): void {
        this.subscriptionStage = this.defaults.stageMessageChanges$.subscribe(() => {
            this.getFilteredElevatorsByTenant()
            this.listComponent.clonedList = {}
        });
        this.subscriptionTenant = this.defaults.tenantMessageChanges$.subscribe(() => {
            this.getFilteredElevatorsByTenant()
            this.listComponent.clonedList = {}
        });
        if (this.defaults.tenant) {
            this.getFilteredElevatorsByTenant()
        }
    }

    ngOnDestroy() {
        this.saveService.removeListener(this);
        this.subscriptionStage.unsubscribe();
        this.subscriptionTenant.unsubscribe();
        this.subscriptions.forEach(x => x.unsubscribe())
    }

    getFilteredElevatorsByTenant() {
        const filter: ElevatorFilter = {
            'UnitIds': this.filterComponent.unitIds,
            'BranchNumbers': this.filterComponent.branchNumbers,
            'ControllerTypes': this.filterComponent.controllerTypes,
            'BuildingAddr1': this.filterComponent.buildingAddr1,
            'BuildingCities': this.filterComponent.buildingCities,
            'ElevatorNicknames': this.filterComponent.elevatorNicknames
        }
        Utils.httpGetElevators(
            this.http
            , this.baseUrl
            , this.defaults.stage
            , filter
            , this
            , function (tthis: ElevatorComponent, list: Elevator[]) {
                tthis.list = list;
                tthis.listComponent.clonedList = {}
                tthis.listComponent.expandedElements = new Set();
                tthis.saveService.showSaveButton(false)
                if (filter.ElevatorNicknames.length < 1 && filter.BranchNumbers.length < 1 && filter.UnitIds.length < 1
                    && filter.BuildingAddr1.length < 1 && filter.ControllerTypes.length < 1 && filter.BranchNumbers.length < 1)
                    tthis.showLocalFilter = false;
                else
                    tthis.showLocalFilter = true;
            }
        );
    }
    onReload() {
        this.getFilteredElevatorsByTenant();
        this.listComponent.clonedList = {}
        this.saveService.showSaveButton(false)
    }
    downloadElevatorFiltered() {
        this.signalRService.startConnection(0).then(() => {
            this.signalRService.addDownloadDataListener();
            this.signalRService.addStatusTracesListener();
            this.signalRService.addFileNameListener();
            this.signalRService.addExceptionListener();
            const filter: ElevatorFilter = {
                'UnitIds': this.filterComponent.unitIds,
                'BranchNumbers': this.filterComponent.branchNumbers,
                'ControllerTypes': this.filterComponent.controllerTypes,
                'BuildingAddr1': this.filterComponent.buildingAddr1,
                'BuildingCities': this.filterComponent.buildingCities,
                'ElevatorNicknames': this.filterComponent.elevatorNicknames
            }
            this.signalRService.initDownloadFile(filter, this.defaults.stage, this.defaults.tenant, this.defaults.tenant + '_elevator')
            return;
        });
    }
}
