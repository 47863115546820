import { Component, OnInit, OnDestroy, Inject, ViewChild } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { DefaultValuesService } from '../../../services/defaultvalues.service';
import { Utils } from '../../../utils/utils';
import { SaveService } from '../../../services/save.service';
import { KpiListComponent } from './kpi-list.component';
import { KpiFilterComponent } from './kpi-filter.component';
import { Subscription } from "rxjs";

@Component({
    selector: "kpi",
    templateUrl: './kpi.component.html',
})

export class KpiComponent implements OnInit, OnDestroy {

    list: KpiUnit[] = [];

    title = "Kpis";
    showLocalFilter = false;

    subscriptionStage: any;
    subscriptionTenant: any;
    subscriptions: Subscription[] = [];

    @ViewChild(KpiListComponent, { static: true }) listComponent!: KpiListComponent;
    @ViewChild(KpiFilterComponent, { static: true }) filterComponent!: KpiFilterComponent;

    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string
        , public defaults: DefaultValuesService
        , private saveService: SaveService
    ) { }

    ngOnInit(): void {
        this.subscriptionStage = this.defaults.stageMessageChanges$.subscribe(() => {
            this.getFilteredKpisByTenant()
            this.listComponent.clonedListItems = {}
        });
        this.subscriptionTenant = this.defaults.tenantMessageChanges$.subscribe(() => {
            this.getFilteredKpisByTenant()
            this.listComponent.clonedListItems = {}
        });
        if (this.defaults.tenant)
            this.getFilteredKpisByTenant()
    }

    ngOnDestroy() {
        this.saveService.removeListener(this);
        this.subscriptionStage.unsubscribe();
        this.subscriptionTenant.unsubscribe();
        this.subscriptions.forEach(x => x.unsubscribe())
    }

    getFilteredKpisByTenant() {
        const filter: KpiFilter = {
            'UnitIds': this.filterComponent.unitIds,
            'CustomerIds': this.filterComponent.customerIds,
            'ContractNumbers': this.filterComponent.contractNumbers,
        }
        Utils.httpGetKpiUnits(
            this.http
            , this.baseUrl
            , this.defaults.stage
            , filter
            , this
            , function (tthis: any, list: Kpi[]) {
                tthis.list = list;
                tthis.listComponent.clonedList = {}
                tthis.listComponent.expandedElements = new Set();
                tthis.saveService.showSaveButton(false)
                if (filter.CustomerIds.length < 1 && filter.ContractNumbers.length < 1 && filter.UnitIds.length < 1)
                    tthis.showLocalFilter = false;
                else
                    tthis.showLocalFilter = true;
                tthis.subscriptions.pop();
            }
        );
    }
    onReload() {
        this.getFilteredKpisByTenant();
        this.listComponent.clonedListItems = {}
        this.saveService.showSaveButton(false)
    }
    downloadKpiFiltered() {
        const filter: KpiFilter = {
            'UnitIds': this.filterComponent.unitIds,
            'ContractNumbers': this.filterComponent.contractNumbers,
            'CustomerIds': this.filterComponent.customerIds,
        }
        Utils.httpDownloadKpis(
            this.http
            , this.baseUrl
            , this.defaults.stage
            , this.defaults.tenant
            , filter
            , this
            , null)
    }
}
