import { Component, Inject, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DefaultValuesService } from "../../../services/defaultvalues.service";
import { CountryBackendEntity } from "../../../interfaces/countrybackendentity";
import { CustomerItemComponent } from "./customeritem/customeritem.component";
import { CustomerFacilityManagerComponent } from "./facilitymanager/customerfacilitymanager.component";
import { MatTabGroup } from "@angular/material/tabs";

@Component({
    selector: "customers",
    templateUrl: './customer.component.html',
    styleUrls: ['../../../style/container-tab-list.component.less']
})

export class CustomerComponent implements OnInit {
    @ViewChild('tabGroup') tabGroup!: MatTabGroup;
    currentType: CountryBackendEntity = CountryBackendEntity.Customer;

    @ViewChild('customerItemComponent', { static: true }) customerItemComponent!: CustomerItemComponent;
    @ViewChild('managerComponent', { static: false }) managerComponent!: CustomerFacilityManagerComponent;


    constructor(
        private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , public defaults: DefaultValuesService

    ) {
    }

    ngOnInit(): void { }
    ngAfterViewInit() {
        this.defaults.defaultComponentTab(this)
    }

    changeCustomerType($event) {
        switch ($event.index) {
            case 0:
                this.currentType = CountryBackendEntity.Customer; break;
            case 1:
                this.currentType = CountryBackendEntity.CustomerFacilityManager; break;
            default:
                break;
        }
    }

    onReload() {
        switch (this.currentType) {
            case CountryBackendEntity.Customer:
                this.customerItemComponent.onReload();
                break;
            case CountryBackendEntity.CustomerFacilityManager:
                this.managerComponent.onReload();
                break;
            default: break;
        }
    }
    downloadExcelFile() {
        switch (this.currentType) {
            case CountryBackendEntity.Customer:
                this.customerItemComponent.downloadCustomersFiltered();
                break;
            case CountryBackendEntity.CustomerFacilityManager:
                this.managerComponent.downloadFacilityManagerFiltered();
                break;
            default: break;
        }
    }
}

