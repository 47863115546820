<div class="container-tab-lists">
    <h2>Create PowerBI Workspace</h2>
    <div class="tab-body">
        <div class="tab-content">
            <div class="custom-mat-dialog_v2">
                <form [formGroup]="form!" (ngSubmit)="onSubmit()">
                    <div class="row">
                        <div class="col-sm-12 form-group">
                            <mat-form-field appearance="outline">
                                <mat-label> Workspace Name</mat-label>
                                <input matInput formControlName="name" (focusout)="onWorkspaceChange()" required>
                            </mat-form-field>
                            <p *ngIf="
                                                  form.get('name')?.invalid &&
                                                    (form.get('name')?.dirty ||
                                                      form.get('name')?.touched)">
                                <mat-error *ngIf="form.get('name')?.hasError('required')">Workspace field is required</mat-error>
                            </p>
                            <p *ngIf="!(
                                                  form.get('name')?.invalid &&
                                                    (form.get('name')?.dirty ||
                                                      form.get('name')?.touched))"><br> </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 form-group">
                            <div class="col-sm-4 form-group">
                                <br />
                                <mat-label>
                                    <span>Capacity</span>
                                    <span class="orange-asterisk"> *</span>
                                </mat-label>
                            </div>
                            <div class="col-sm-8 form-group">
                                <ng-select [items]="capacities"
                                           [searchable]="true"
                                           [clearable]="false"
                                           bindLabel="capacityName"
                                           bindValue="capacityName"
                                           formControlName="capacity"
                                           required>
                                </ng-select>

                                <br>

                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 form-group">

                            <div class="col-sm-4 form-group">
                                <br />
                                <mat-label>
                                    <span>Upper Node</span>
                                    <span class="orange-asterisk"> *</span>
                                </mat-label>
                            </div>
                            <div class="col-sm-8 form-group">
                                <ng-select [items]="parentnodes"
                                           [searchable]="true"
                                           [clearable]="false"
                                           bindLabel="text"
                                           bindValue="text"
                                           formControlName="uppernode"
                                           required
                                           (change)="GetFutureWorkspaceNode()">
                                </ng-select>

                                <br>

                            </div>
                        </div>
                    </div>

                    <div class="row" style="height:100px">
                        <div class="col-sm-12 form-group">
                            <p *ngIf="errorMessage !== ''">
                                <mat-error>{{errorMessage}}</mat-error>
                            </p>
                            <p *ngIf="futureWorkspaceName !== ''">
                                Workspace name will be: <b>{{futureWorkspaceName}}</b>
                            </p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="panel-body">
                            <button type='button' mat-raised-button class="btn button-color-fix_red"
                                    (click)="onNoClick()">
                                Close
                            </button>
                            <button class="btn button-color-fix pull-right" [disabled]="!form.valid && errorMessage !== '' && futureWorkspaceName === ''" type="submit">
                                Send
                            </button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>
