import { Component, Inject, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { DialogService } from '../../../../services/dialog.service';
import { Utils } from '../../../../utils/utils';
import { SaveService } from '../../../../services/save.service';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'controllerconfig-form'
    , templateUrl: './controllerconfig-form.component.html'
    , styleUrls: ['../../../../style/form.component.less']
})
export class ControllerConfigFormComponent implements OnInit {

    body = {
        ControllerConfigDescriptionID: '',
        DataTypeID: '',
        ControllerConfigID: -1
    };

    form!: FormGroup
    submitted = false;

    constructor(private http: HttpClient
        , private confimDialog: DialogService
        , private router: Router
        , @Inject(MAT_DIALOG_DATA) public data: any
        , @Inject('BASE_URL') private baseUrl: string
        , public dialogRef: MatDialogRef<ControllerConfigFormComponent>
        , private formBuilder: FormBuilder

    ) {

        this.confimDialog.tthis = this;
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            ControllerConfigDescriptionID: ['', [Validators.required]],
            DataTypeID: ['', [Validators.required]]
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.form ? this.form.controls : null; }

    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.form)
            if (this.form.invalid) {
                return;
            }

        //const tthis: this;
        this.http
            .post<ControllerConfig>(
                this.baseUrl + Utils.getControllerTypeAPI() + "mergecontrollerconfig"
                , this.body
            )
            .subscribe(
                res => {
                    this.onBack()
                    this.dialogRef.close({ event: 'submit' });
                }
                , error => {
                    this.confimDialog.showErrorDialog(error);
                }
            );
    }

    onBack() { }

    onNoClick(): void {
        this.dialogRef.close();
    }
}


