<form [formGroup]="form">
    <table>
        <tbody class="inner">
            <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td colspan="4" style="width:350px;">
                    <label>{{header[MAINTENANCEFAILURE].label}}:</label>
                    <span *ngIf="!newRow">{{selected.MaintenanceFailure}}<br /></span>
                    <textarea id="MaintenanceFailure"
                              formControlName="MaintenanceFailure"
                              placeholder="enter the maintenance failure..."
                              title="{{header[MAINTENANCEFAILURE].label}}"
                              [ngClass]="classname[MAINTENANCEFAILURE]"
                              (change)="onMaintenanceFailureChange($event)"
                              class="form-control">
                    </textarea>
                    <button *ngIf="!newRow" type="button" class="btn btn-default" title="Undo changes" [disabled]="isundodisabled[MAINTENANCEFAILURE] == true" (click)="onMaintenanceFailureUndo()">
                        <span class='glyphicon glyphicon-arrow-left'></span>
                    </button>

                    <br />
                    <label>{{header[TROUBLESHOOTINGACTION].label}}:</label>
                    <span *ngIf="!newRow">{{selected.TroubleShootingAction}}<br /></span>
                    <textarea id="TroubleshootingAction"
                              formControlName="TroubleshootingAction"
                              placeholder="enter the troubleshooting action..."
                              title="{{header[TROUBLESHOOTINGACTION].label}}"
                              [ngClass]="classname[TROUBLESHOOTINGACTION]"
                              (change)="onTroubleShootingActionChange($event)"
                              class="form-control">
                    </textarea>
                    <button *ngIf="!newRow" type="button" class="btn btn-default" title="Undo changes" [disabled]="isundodisabled[TROUBLESHOOTINGACTION] == true" (click)="onTroubleShootingActionUndo()">
                        <span class='glyphicon glyphicon-arrow-left'></span>
                    </button>

                    <br />
                    <label>{{header[PARTSREQUIRED].label}}:</label>
                    <span *ngIf="!newRow">{{selected.PartsRequired}}<br /></span>
                    <input type="checkbox" id="PartsRequired"
                           formControlName="PartsRequired"
                           title="{{header[PARTSREQUIRED].label}}"
                           [ngClass]="classname[PARTSREQUIRED]"
                           (change)="onPartsChange($event)"
                           class="form-control" />
                    <button *ngIf="!newRow" type="button" class="btn btn-default" title="Undo changes" [disabled]="isundodisabled[PARTSREQUIRED] == true" (click)="onPartsUndo()">
                        <span class='glyphicon glyphicon-arrow-left'></span>
                    </button>

                    <br />

                    <label>{{header[HIGHVALUEPART].label}}:</label>
                    <span *ngIf="!newRow">{{selected.HighValuePart}}<br /></span>
                    <input type="checkbox" id="HighValuePart"
                           formControlName="HighValuePart"
                           title="{{header[HIGHVALUEPART].label}}"
                           [ngClass]="classname[HIGHVALUEPART]"
                           (change)="onHighValuePartChange($event)"
                           class="form-control" />
                    <button *ngIf="!newRow" type="button" class="btn btn-default" title="Undo changes" [disabled]="isundodisabled[HIGHVALUEPART] == true" (click)="onHighValuePartUndo()">
                        <span class='glyphicon glyphicon-arrow-left'></span>
                    </button>

                </td>

                <td colspan="5" style="width:450px;">
                    <label>{{header[WEIGHT].label}}:</label>
                    <span *ngIf="!newRow">{{selected.TroubleShootingActionWeight}}<br /></span>
                    <select id="Weight"
                            formControlName="Weight"
                            title="{{header[WEIGHT].label}}"
                            [ngClass]="classname[WEIGHT]"
                            (change)="onWeightChange($event)"
                            class="form-control">
                        <option *ngFor="let ans of weights; index as i" value="{{ans}}">{{ans}}</option>
                    </select>
                    <button *ngIf="!newRow" type="button" class="btn btn-default" title="Undo changes" [disabled]="isundodisabled[WEIGHT] == true" (click)="onWeightUndo()">
                        <span class='glyphicon glyphicon-arrow-left'></span>
                    </button>
                    <br />
                    <label>{{header[LOCATION].label}}:</label>
                    <span *ngIf="!newRow">{{selected.SubSystemGroup}}<br /></span>
                    <select id="location"
                            formControlName="Location"
                            [ngClass]="classname[LOCATION]"
                            title="{{header[LOCATION].label}}"
                            class="form-control"
                            (change)="onLocationChange($event)">
                        <option *ngFor="let v of locations" value="{{v.ComponentSubSystemID}}">{{v.Description}}</option>
                    </select>
                    <button *ngIf="!newRow" type="button" class="btn btn-default" title="Undo changes" [disabled]="isundodisabled[LOCATION] == true" (click)="onLocationUndo()">
                        <span class='glyphicon glyphicon-arrow-left'></span>
                    </button>

                    <br />
                    <label>{{header[COMPONENT].label}}:</label>
                    <span *ngIf="!newRow">{{selected.ComponentItem}}<br /></span>
                    <select id="component"
                            formControlName="Component"
                            title="{{header[COMPONENT].label}}"
                            [ngClass]="classname[COMPONENT]"
                            (change)="onComponentChange($event)"
                            class="form-control">
                        <option *ngFor="let v of components" value="{{v.ComponentItemID}}">{{v.Description}}</option>
                    </select>
                    <button *ngIf="!newRow" type="button" class="btn btn-default" title="Undo changes" [disabled]="isundodisabled[COMPONENT] == true" (click)="onComponentUndo()">
                        <span class='glyphicon glyphicon-arrow-left'></span>
                    </button>

                    <br />
                    <label>{{header[CAUSE].label}}:</label>
                    <span *ngIf="!newRow">{{selected.Cause}}<br /></span>
                    <select id="Cause"
                            formControlName="Cause"
                            title="{{header[CAUSE].label}}"
                            [ngClass]="classname[CAUSE]"
                            (change)="onCauseChange($event)"
                            class="form-control">
                        <option *ngFor="let v of causes" value="{{v.BusinessKeyID}}">{{v.BK}} - {{v.Description}}</option>
                    </select>
                    <button *ngIf="!newRow" type="button" class="btn btn-default" title="Undo changes" [disabled]="isundodisabled[CAUSE] == true" (click)="onCauseUndo()">
                        <span class='glyphicon glyphicon-arrow-left'></span>
                    </button>

                    <br />
                    <label>{{header[RESOLUTION].label}}:</label>
                    <span *ngIf="!newRow">{{selected.Resolution}}<br /></span>
                    <select id="Resolution"
                            formControlName="Resolution"
                            title="{{header[RESOLUTION].label}}"
                            [ngClass]="classname[RESOLUTION]"
                            (change)="onResolutionChange($event)"
                            class="form-control">
                        <option *ngFor="let v of resolutions" value="{{v.BusinessKeyID}}">{{v.BK}} - {{v.Description}}</option>
                    </select>
                    <button *ngIf="!newRow" type="button" class="btn btn-default" title="Undo changes" [disabled]="isundodisabled[RESOLUTION] == true" (click)="onResolutionUndo()">
                        <span class='glyphicon glyphicon-arrow-left'></span>
                    </button>
                </td>

                <td>
                    <button type="button"
                            class="btn btn-warning"
                            (click)="onRowCancel()">
                        Cancel
                    </button>
                </td>

            </tr>
        </tbody>
    </table>
</form>



<!--<div *ngIf="!editMode">
    <div class="container-tab-lists" *ngIf="form">
        <div class="form-edit">
            <h2>{{title}} in Error Code for {{defaults.controllertype.Description}} in {{defaults.language.Name}}</h2>
            <p>{{errorcode.Description}} (Error Code: {{errorcode.ErrorCode}})</p>
            <form [formGroup]="form" (ngSubmit)="onSubmit()">

                <div class="form-group">
                    <label for="MaintenanceFailure">Maintenance Failure:</label>-->
<!--<span *ngIf="editMode"> {{selected.MaintenanceFailure_Ref}}</span>-->
<!--<br />
    <textarea id="MaintenanceFailure"
              formControlName="MaintenanceFailure" required
              placeholder="enter the maintenance failure..."
              class="form-control">
</textarea>
</div>
<div class="form-group">
    <label for="TroubleshootingAction">Troubleshooting Action:</label>-->
<!--<span *ngIf="editMode"> {{selected.TroubleShootingAction_Ref}}</span>-->
<!--<br />
    <textarea id="TroubleshootingAction"
              formControlName="TroubleshootingAction" required
              placeholder="enter the troubleshooting action..."
              class="form-control">
</textarea>
</div>
<div class="form-group">
    <label for="Weight">Troubleshooting Weight:</label>
    <br />
    <select id="Weight"
            formControlName="Weight"
            class="form-control">
        <option *ngFor="let ans of weights; index as i" value="{{ans}}">{{ans}}</option>
    </select>
</div>
<div class="form-group">
    <label for="location">Location:</label>-->
<!--<span *ngIf="editMode"> {{selected.SubSystemGroup}}</span>-->
<!--<br />
    <select id="location"
            formControlName="Location" required
            class="form-control"
            (change)="onSelectLocation($event)">
        <option *ngFor="let v of locations" value="{{v.ComponentSubSystemID}}">{{v.Description}}</option>
    </select>
</div>
<div class="form-group">
    <label for="component">Component:</label>-->
<!--<span *ngIf="editMode"> {{selected.ComponentItem}}</span>-->
<!--<br />
    <select id="component"
            formControlName="Component" required
            class="form-control">
        <option *ngFor="let v of components" value="{{v.ComponentItemID}}">{{v.Description}}</option>
    </select>
</div>
<div class="form-group">
    <label for="Cause">Cause:</label>-->
<!--<span *ngIf="editMode"> {{selected.Cause}}</span>-->
<!--<br />
    <select id="Cause"
            formControlName="Cause" required
            class="form-control">
        <option *ngFor="let v of causes" value="{{v.BusinessKeyID}}">{{v.BK}} - {{v.Description}}</option>
    </select>
</div>
<div class="form-group">
    <label for="Resolution">Resolution:</label>-->
<!--<span *ngIf="editMode"> {{selected.Resolution}}</span>-->
<!--<br />
    <select id="Resolution"
            formControlName="Resolution" required
            class="form-control">
        <option *ngFor="let v of resolutions" value="{{v.BusinessKeyID}}">{{v.BK}} - {{v.Description}}</option>
    </select>
</div>
<div class="form-group">
    <label for="PartsRequired">Parts Required?:</label>
    <br />
    <input type="checkbox" id="PartsRequired"
           formControlName="PartsRequired"
           title="check if parts required..."
           class="form-control" />
</div>
<div class="form-group">
    <label for="HighValuePart">High Value Part?:</label>
    <br />
    <input type="checkbox" id="HighValuePart"
           formControlName="HighValuePart"
           title="check if high value parts..."
           class="form-control" />
</div>-->
<!--<div class="form-group commands">
    <button type="submit"
            [disabled]="form.invalid"
            class="btn btn-success">
        Create!
    </button>-->
<!--<button
        type="submit"
        [disabled]="form.invalid"
        class="btn btn-success">
    Update!
</button>-->
<!--<button *ngIf="editMode" type="button"
        class="btn btn-danger"
        (click)="onDelete()">
    Delete
</button>-->
<!--<button type="button"
            title="Back"
            (click)="onBack()"
            class="btn btn-default">
        Cancel
    </button>
</div>-->
<!--form-group commands-->
<!--</form>
</div>-->
<!--form-edit new -->
<!--</div>
</div>-->
