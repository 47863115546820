import { DialogService } from "../../../../services/dialog.service";
import { SaveService } from '../../../../services/save.service';
import { Utils } from "../../../../utils/utils";
import { DefaultValuesService } from "../../../../services/defaultvalues.service";
import { AlertMergeConfigurationFormComponent } from "./alertmergeconfiguration-form.component";
import { CustomDialogService } from '../../../../services/customdialog.service';
import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { HttpClient } from "@angular/common/http";
import { MatTableDataSource } from "@angular/material/table";

@Component({
    selector: "alertmergeconfiguration-list",
    templateUrl: './alertmergeconfiguration-list.component.html',
    styleUrls: ['./alertmergeconfiguration-list.component.less']
})

export class AlertMergeConfigurationListComponent implements OnInit {
    columnsToDisplay = ['ItemNo', 'Description', 'TimeWindowInSeconds', 'ChangedAtUtc', 'ChangedByUser', 'CreatedAtUtc', 'actions'];
    list: vMasterAlertWindow[] = [];

    public dataSource = new MatTableDataSource<vMasterAlertWindow>();
    subscriptionControllerType: any;

    Filter = 0;


    constructor(
        private http: HttpClient
        , private dialogService: DialogService
        , private defaults: DefaultValuesService
        , @Inject('BASE_URL') private baseUrl: string
        , private dialog: MatDialog
        , private dialogForm: MatDialog
        , private customDialog: CustomDialogService
        , private saveService: SaveService

    ) {

    }


    ngOnInit() {
        this.dialogService.tthis = this;
        this.subscriptionControllerType = this.defaults.controllerTypeMessageChanges$.subscribe((item: ControllerType) => this.onControllerChanged(item));
        this.refresh();
    }


    ngOnDestroy() {
        this.subscriptionControllerType.unsubscribe();
    }

    ngOnChanges() {

        this.dataSource.data = this.list;
    }





    onControllerChanged(ct: ControllerType) {
        this.refresh();
    }

    onRowEditInit(element) {


        const Dialog = this.dialogForm.open(AlertMergeConfigurationFormComponent, {
            panelClass: 'custom-mat-dialog',
            disableClose: false,
            data: { "ControllerType": element.ControllerType, "TimeWindowInSeconds": element.TimeWindowInSeconds }
        });
        Dialog.afterClosed().subscribe(result => {
            if (result) this.refresh();
        });
    }

    onDelete(element) {
        const tthis = this;

        this.customDialog.openConfirm({
            title: "DELETE ALERT TIMEWINDOW CONFIGURATION",
            message: 'Alert timewindow configuration for controller "' + element.Description + '" will be deleted. Continue ?',
            caller: this
        });

        this.customDialog.confirmed().subscribe(res => {

            if (res.confirmed) {
                let url = this.baseUrl + Utils.getMasterAlertWindowAPI() + "/delete";

                this.http
                    .post<boolean>(url, { ControllerType: element.ControllerType})
                    .subscribe(res => {
                        this.refresh();
                    }, error => {
                        Utils.writeLog(this.http, this.baseUrl, error); //Utils.showErrorDialog(null, error);
                    }); //console.error(error));
            }
        })

    }

    public btn_new() {

        const Dialog = this.dialogForm.open(AlertMergeConfigurationFormComponent, {
            panelClass: 'custom-mat-dialog',
            disableClose: false,
            data: { "ControllerType": "", "TimeWindowInSeconds": "" }
        });
        Dialog.afterClosed().subscribe(result => {
            if (result) this.refresh();
        });




    }


    private filterColumn(value: any, name: string) {
        switch (name) {
            case 'status':
                this.Filter = value.value;
        }
        this.refresh()
    }

    public btn_refresh() {
        this.refresh();

        //this.dialogForm.open(AzDataFactoryFormComponent, {
        //    panelClass: 'custom-mat-dialog',
        //    disableClose: true,
        //    data: { "isCustomerPortal": 0, "type": "", "entity": "Customer Portal" }
        //});


    }

    private refresh() {
        const tthis = this;
        ///**
        //    * load all the data
        //    * */


        let url = this.baseUrl + Utils.getMasterAlertWindowAPI() + "/all";

        this.http
            .get<vMasterAlertWindow[]>(url, {})
            .subscribe(res => {
                tthis.list = res;
                tthis.dataSource.data = tthis.list;
            }, error => {
                Utils.writeLog(this.http, this.baseUrl, error); //Utils.showErrorDialog(null, error);

            }); //console.error(error));



    }

    private sinceDays(date: Date) {
        let currentDate = new Date();

        let dateDeployment = new Date(date);
        return Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())
            - Date.UTC(dateDeployment.getFullYear(), dateDeployment.getMonth(), dateDeployment.getDate())) / (1000 * 60 * 60 * 24));
    }
}