<div class="container-tab-lists">
    <mat-tab-group #tabGroup (selectedTabChange)="defaults.updateComponentTab($event.tab.textLabel)">
        <mat-tab label="Controller Configuration">
            <fingerprintrules_controllerconfig-list [list]="controllerConfigurationList"
                                                    (onUpdateTable)="onUpdateTable()"></fingerprintrules_controllerconfig-list>
        </mat-tab>

        <mat-tab label="Error Code Management">
            <fp_errorcode-list-properties [keywords]="keywords"
                                          [list]="errorCodeList"
                                          (onUpdateTable)="onUpdateTable()"></fp_errorcode-list-properties>
        </mat-tab>

        <mat-tab label="Fingerprint Rule Definition">
            <fp_rule_definition-list [keywords]="keywords" 
                                     [list]="definitionList"
                                     (onUpdateTable)="onUpdateTable()"></fp_rule_definition-list>
        </mat-tab>
        <mat-tab label="FingerPrint Legacy">
            <div>
                <fingerprintrules-menu (onReload)="onReload()"></fingerprintrules-menu>
                <h2>Fingeprint Rules by tenant and Controller Type</h2>
                <fingerprintrules-list [list]="list"></fingerprintrules-list>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>