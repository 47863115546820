import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Utils } from '../../../../../../utils/utils';

@Component({
    selector: 'createwavepopup',
    templateUrl: './createwavepopup.component.html',
    styleUrls: ['./createwavepopup.component.less']
})
export class CreateWavePopupComponent implements OnInit {
    form!: FormGroup
    constructor(
        public dialogRef: MatDialogRef<CreateWavePopupComponent>,
        private formBuilder: FormBuilder,
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private toastrService: ToastrService) { }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            waveId: [this.data?.WaveId],
            name: [this.data?.Name],
            startDate: [this.data?.StartDate],
            endDate: [this.data?.EndDate],
        });
    }
    onSubmit() {
        // stop here if form is invalid
        if (this.form)
            if (this.form.invalid) {
                return;
            }

        Utils.httpUpsertWave(
            this.http
            , this.baseUrl
            , this.form.value
            , this
            , function (tthis: CreateWavePopupComponent, result: any) {
                tthis.dialogRef.close({ event: 'submit' });
                if (result) {
                    tthis.toastrService.success('Wave was created successfully!');
                }
                else
                    tthis.toastrService.error('Wave could not be created. Please, contact with administrator');
            },
            null
        );
    }


    backButtonHandler(event: any) {
        event.preventDefault();
        this.dialogRef.close();
    }
}
