import { Component, Inject, OnInit, ElementRef, ViewChild, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Utils } from "../../utils/utils";
import { DialogService } from "../../services/dialog.service";
import { DefaultValuesService } from "../../services/defaultvalues.service";
import { WaitingService } from "../../services/waiting.service";
import { SaveService } from "../../services/save.service";

@Component({
    selector: "maintenance-control-plan-group-action-component-proposed-errors-new",
    templateUrl: './mcp-new-group-action-component-proposed-errors.component.html',
    styleUrls: ['../../style/form.component.less']
})

export class MaintenanceControlPlanGroupActionComponentProposedErrorsNewComponent implements OnInit {

    selected: MaintenanceControlPlanGroup = <MaintenanceControlPlanGroup>{};
    proposed: MaintenanceControlPlanGroupActionComponentProposedErrors[] = [];

    form!: FormGroup;

    checked: MaintenanceControlPlanGroupActionComponentProposedErrors[] = [];

    constructor(
        private activatedRoute: ActivatedRoute
        , private router: Router
        , private http: HttpClient
        , private fb: FormBuilder
        , @Inject('BASE_URL') private baseUrl: string
        , public dialog: DialogService
    ) {
    }

    ngOnInit() {
        this.dialog.tthis = this;        
        var id = +this.activatedRoute.snapshot.params["id"];
        Utils.httpGetMaintenanceControlPlanGroup(
            this.http
            , this.baseUrl
            , id
            , this
            , function (tthis: any, value: MaintenanceControlPlanGroup) {
                tthis.selected = value;
                tthis.createForm();
            }

        );

        Utils.httpAllGetMaintenanceControlPlanGroupActionComponentProposedErrors(
            this.http
            , this.baseUrl
            , id
            , this
            , function (tthis: any, values: MaintenanceControlPlanGroupActionComponentProposedErrors[]) {
                tthis.proposed = values;
                //tthis.createForm();
            }
        );
    }

    createForm() {
        this.form = this.fb.group({});
    }

    //isSelected(ct: ControllerType): boolean {
    //    return ct.ControllerTypeID == this.defaults.controllertype.ControllerTypeID;
    //}

    onBack() {
        this.router.navigate(["maintenancecontrolplangroupaction", this.selected.MCPGroupID]);
    }

    //onControllerChanged(select: any) {
    //    if (!select.target) return;
    //    this.errors = [];
    //    this.getErrors(this.defaults.controllertypes[select.target.value].ControllerTypeID);
    //}

    //onErrorChange(select: any) {
    //    if (!select.target) return;
    //    //this.selected.ErrorCodeID = select.target.value;
    //    if (this.form && this.onFieldChange(this.ERRORCODE, this.form.value.Component, this.selected.ErrorCodeID) && this.selected) { 
    //        this.list[this.rowIndex].ErrorCodeID = this.form.value.Error;
    //        //Utils.httpGetErrorCode(
    //        //    this.http
    //        //    , this.baseUrl
    //        //    , this.list[this.rowIndex].ErrorCodeID
    //        //    , this
    //        //    , function (tthis: any, value: ErrorCode) {
    //        //        tthis.selected = value;
    //        //    });
    //    }
    //}

    //onRowCancel() {
    //    this.saveService.fireCancel(this.rowIndex, this);
    //}

    //onSave() {
    //    if (this.form && this.form.valid) {
    //        if (this.newRow) {
    //            this.selected.MCPActionID = this.component.MCPActionID;
    //            this.selected.ComponentItemID = this.component.ComponentItemID;
    //            this.selected.ErrorCodeID = this.form.value.Error;

    //            this.http
    //                .put<MaintenanceControlPlanGroupActionComponentError>(
    //                    this.baseUrl + Utils.getMaintenanceControlPlanAPI() + "addgroupactioncomponenterror"
    //                    , this.selected
    //                )
    //                .subscribe(
    //                    res => this.list.unshift(res)
    //                    , error => this.dialog.showErrorDialog(error)
    //                );
    //        }
    //    }
    //    else
    //        this.dialog.showErrorStringDialog("Error Saving data!");
    //}

    onSubmit() {
        if (this.form && this.form.valid) {

            var tthis = this;
            this.checked.forEach(function (value: MaintenanceControlPlanGroupActionComponentProposedErrors, index: number, array: MaintenanceControlPlanGroupActionComponentProposedErrors[]) {
                if (value) {
                    var mcpActionError = <MaintenanceControlPlanGroupActionComponentError>{};
                    mcpActionError.MCPActionID = value.MCPActionId;
                    mcpActionError.ComponentItemID = value.ComponentItemID;
                    mcpActionError.ErrorCodeID = value.ErrorCodeID;

                    tthis.http
                        .put<MaintenanceControlPlanGroupActionComponentError>(
                            tthis.baseUrl + Utils.getMaintenanceControlPlanAPI() + "addgroupactioncomponenterror"
                            , mcpActionError
                        )
                        .subscribe(
                            res => { tthis.onBack(); }
                            , error => tthis.dialog.showErrorDialog(error)
                        );
                }

            });
        }
    }
}