import { Component, Inject, OnInit } from '@angular/core';
import { Utils } from '../../../../../utils/utils';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material/table';
import { CreateExcellenceLeverPopupComponent } from './createexcellencelever-popup/createexcellencelever-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { CustomDialogService } from '../../../../../services/customdialog.service';

@Component({
    selector: 'excellence-lever',
    templateUrl: './excellence-lever.component.html',
    styleUrls: ['./excellence-lever.component.less',
        '../../../../../style/new-generic-styles.component.less']
})
export class ExcellenceLeverComponent implements OnInit {

    subscriptions: any[] = [];
    public dataSource = new MatTableDataSource<ExcellenceLever>();
    columnsToDisplay = ['Name', 'LineOfBusiness', 'Function', 'ChangedAtUtc', 'ChangedBy', 'actions'];


    constructor(
        private http: HttpClient
        , private dialogForm: MatDialog
        , @Inject('BASE_URL') private baseUrl: string
        , private customDialog: CustomDialogService
        , private toastrService: ToastrService) { }

    ngOnInit(): void {
        this.getExcellenceLevers();
    }

    getExcellenceLevers() {
        Utils.httpGetExcellenceLevers(
            this.http,
            this.baseUrl,
            this,
            function (tthis: ExcellenceLeverComponent, list: any) {
                tthis.dataSource.data = list
            },
            null

        )
    }

    onRemoveExcellenceLever(element: ExcellenceLever) {
        this.customDialog.openConfirm({
            title: "Delete Excellence Lever",
            message: `Do you want to ${element.Name} - (${element.Function})?`,
            caller: this
        });
        this.customDialog.confirmed().subscribe(res => {
            if (res.confirmed) {
                Utils.httpDeleteExcellenceLever(
                    this.http
                    , this.baseUrl
                    , element.ExcellenceLeverId
                    , this
                    , function (tthis: ExcellenceLeverComponent, result: any) {
                        if (result) {
                            tthis.getExcellenceLevers();
                            tthis.toastrService.success(`Excellence Lever was deleted successfully!`);
                        }
                        else
                            tthis.toastrService.error(`Excellence Lever could not be deleted. Please, contact with administrator`);
                    },
                    null
                );
            }
        });
    }

    onEditExcellenceLever(element: ExcellenceLever) {
        const dialog = this.dialogForm.open(CreateExcellenceLeverPopupComponent, {
            panelClass: ['custom-mat-dialog_v2'],
            disableClose: true,
            data: element,
        })

        dialog.afterClosed().subscribe(() => {
            this.getExcellenceLevers()
        })
    }

    openCreateExcellenceLeverPopup() {
        const dialog = this.dialogForm.open(CreateExcellenceLeverPopupComponent, {
            panelClass: ['custom-mat-dialog_v2'],
            disableClose: true,
        })

        dialog.afterClosed().subscribe(() => {
            this.getExcellenceLevers()
        })
    }
}