import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import "rxjs/operators";
import { Data, Router } from '@angular/router';
import { DialogService } from '../../../../services/dialog.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DefaultValuesService } from '../../../../services/defaultvalues.service';
import * as moment from 'moment';
import { DatePipe } from '@angular/common'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: "kpiadminggform",
    templateUrl: './kpiadmingf-form.component.html',
    styleUrls: ['../../../../style/azdatafactory.component.less', '../../../../style/custom-mat-dialog.component.less']

})

export class KpiAdminGFFormComponent implements OnInit {



    GroupFunctions: string[] = [];
    SelectedGF: string[] = [];

    MersyId: string = "";
    KpiName: string = "";

    body: any;
    title: string;
    submitted = false;
    form: FormGroup = new FormGroup({});

    constructor(private http: HttpClient
        , private confimDialog: DialogService
        , private router: Router
        , @Inject(MAT_DIALOG_DATA) public data: any
        , @Inject('BASE_URL') private baseUrl: string
        , public dialogRef: MatDialogRef<KpiAdminGFFormComponent>
        , private fb: FormBuilder
        , private defaults: DefaultValuesService
        , private formBuilder: FormBuilder
        , private datepipe: DatePipe) {

        this.confimDialog.tthis = this;
        this.title = "";
    }

    ngOnInit(): void {

        const tthis = this;

        for (let i = 0; i < this.data.GroupFunctions.length; i++)
        {
            if (!this.data.SelectedGF.includes(this.data.GroupFunctions[i].Value))
                this.GroupFunctions.push(this.data.GroupFunctions[i].Value);
        }

        this.MersyId = this.data.MersyId;
        this.KpiName = this.data.KpiName;

    }


    updateSelectedOptions(event: Event) {
        const selectedOption = (event.target as HTMLSelectElement).innerText;
        // Utiliza la opción seleccionada como desees
        this.SelectedGF.push(selectedOption);
    }

    // convenience getter for easy access to form fields
    get f()
    {
        return this.form.controls;
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    onSubmit() {


        this.dialogRef.close(this.SelectedGF);
    }


    onBack() {

    }



}


