import { Component, Inject, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Utils } from "../../utils/utils";
import { ActivatedRoute } from "@angular/router";
import { SaveService } from "../../services/save.service";
import { DefaultValuesService } from "../../services/defaultvalues.service";

@Component({
    selector: "maintenancecontrolplangroup",
    templateUrl: './mcp-group.component.html',
    styleUrls: ['../../style/container-tab-list.component.less']
})

export class MaintenanceControlPlanGroupComponent implements OnInit {
    title: string = "Maintenance Control Program Groups";
    selected: MaintenanceControlPlan = <MaintenanceControlPlan>{};
    list: MaintenanceControlPlanGroup[] = [];
    //tenant: Country

    newMode: SaveService = <SaveService>{};

    constructor(
        private activatedRoute: ActivatedRoute
        , private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , private defaults: DefaultValuesService
    ){
    }

    ngOnInit() {
        this.getAll();
        this.newMode = new SaveService();
    }

    getAll() {

        var id = +this.activatedRoute.snapshot.params["id"];
        Utils.httpGetMaintenanceControlPlan(
            this.http
            , this.baseUrl
            , id
            , this
            , function (tthis: any, value: MaintenanceControlPlan) {
                tthis.selected = value;
            }

        );

        Utils.httpGetAllMaintenanceControlPlanGroups(
            this.http
            , this.baseUrl
            , id
            , this
            , function (tthis: any, list: MaintenanceControlPlanGroup[]) {
                tthis.list = list;
            }
        );
    }

    onNew() {
        this.newMode.fireNew();
    }

    //onResetList() {
    //    this.getAll();
    //}

    //onSearchList(obj: any) {
    //    ////{ ShowRef: boolean, Index: number, Value: string }
    //    var l = <MaintenanceControlPlanGroup[]>[];
    //    var value = obj.Value;
    //    for (var i = 0; i < this.list.length; ++i) {
            
    //        switch (obj.Index) {
    //            case 0:
    //                if (this.list[i].MCPGroup)
    //                    if (this.list[i].MCPGroup.toLowerCase().indexOf(obj.Value.toLowerCase()) != -1)
    //                        l.push(this.list[i]);
    //                break;
    //            default:
    //                if (this.list[i].IsLobby)
    //                    if (this.list[i].IsLobby == obj.Value)
    //                        l.push(this.list[i]);
    //        }
    //    }
    //    this.list = l;
    //}
}