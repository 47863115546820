<div class="container-tab-lists">
    <businesskey-menu (translationAreaChanged)="onTranslationAreaChanged($event)" (languageChanged)="onLanguageChanged($event)" (outputDownload)="downloadBusinessKeys()" [language]="language"></businesskey-menu>

    <div class="inner-tcontainer-tab">
        <div style="display:flow-root">
            <div style="float:left; width:40vw">
                <h2>{{title}}</h2>
            </div>
            <div style="text-align:right; float:right">
                <h5>**A PBI must be created and assigned to anyone from DevOps Team for deploying new changes.**</h5>
            </div>
        </div>
        <div class="tab-body">
            <div class="tab-content">
                <businesskey-list 
                    [list]="businesskeys" 
                    [language]="language" 
                    (onRefresh)="refreshAll()">
                </businesskey-list>
            </div> <!--tab-content-->
        </div> <!--tab-body-->
    </div> <!--inner-tcontainer-tab-->
</div> <!--container-tab-lists-->

<div class="container-tab-lists" *ngIf="editMode">
    <ng-container></ng-container>
</div>