<testcase-menu (onRefresh)="refresh()" [showMenuViewValue]="showMenuViewValue"> </testcase-menu>
<div class="container-tab-lists" [ngClass]="dialog.classname!">
    <h2>{{title}} for {{defaults.controllertype.Description}}</h2>
    <div class="inner-tcontainer-tab">
        <div class="tab-body">
            <div class="tab-content">
                <mat-tab-group #tabGroup (selectedTabChange)="showMenuView($event); defaults.updateComponentTab($event.tab.textLabel)" >
                    <!--Messages-->
                    <mat-tab label="Test Cases">
                        <div>
                            <testcase-list [list]="list" (onRefresh)="refresh()"></testcase-list>
                        </div>
                    </mat-tab>

                    <mat-tab *ngIf="defaults.isDomainExpertUser || defaults.isadminuser" label="Databricks Triggers">
                        <div>
                            <testcase-list-databricks-trigger [list]="listDataBricks" (onRefresh)="refresh()"></testcase-list-databricks-trigger>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>
</div>
