<form [formGroup]="form">
    <table>
        <tbody class="inner">
            <tr>
                <td>&nbsp;</td>
                <td>{{list[rowIndex].ModeReference}}</td> <!--Mode Reference-->
                <td>
                    <span>{{selected.Mode}}</span><br />
                    <input id="Mode" required
                           formControlName="Mode"
                           placeholder="enter the mode..."
                           title="{{header[MODE].label}}"
                           [ngClass]="classname[MODE]"
                           (change)="onModeChange()"
                           class="form-control" />
                    <button *ngIf="!newRow" type="button" class="btn btn-default" title="Undo changes" [disabled]="isundodisabled[MODE] == true" (click)="onModeUndo()">
                        <span class='glyphicon glyphicon-arrow-left'></span>
                    </button>
                    <br />
                </td>

                <td>{{list[rowIndex].DescriptionReference}}</td>  <!--Description Reference-->
                <td>
                    <span>{{selected.Description}}</span><br />
                    <textarea id="Description"
                            formControlName="Description" required
                            placeholder="enter the description..."
                            title="{{header[DESCRIPTION].label}}"
                            [ngClass]="classname[DESCRIPTION]"
                            class="form-control"
                            (change)="onDescriptionChange()">
                    </textarea>
                    <button *ngIf="!newRow" type="button" class="btn btn-default" title="Undo changes" [disabled]="isundodisabled[DESCRIPTION] == true" (click)="onDescriptionUndo()">
                        <span class='glyphicon glyphicon-arrow-left'></span>
                    </button>
                </td>
                <td>
                    <button type="button"
                            class="btn btn-warning"
                            (click)="onRowCancel()">
                        Cancel
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</form>