import { Component, OnInit, OnDestroy, Inject, Input, Output, EventEmitter } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { DefaultValuesService } from "../../../services/defaultvalues.service";
import { Utils } from "../../../utils/utils";
@Component({
    selector: "allowedactions",
    templateUrl: 'allowedactions.component.html',
})

export class AllowedActionComponent implements OnInit, OnDestroy {

    @Input('commands') commandList: Command[] = [];
    @Input('isEditableMethod') isEditable: any
    @Output() onReloadTemplate = new EventEmitter<any>();

    list: AllowedRemoteAction[] = [];

    mergedList: any[] = [];

    showLocalFilter = false;

    subscriptionTenant: any;
    subscriptionControllerType: any;

    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string,
        public defaults: DefaultValuesService
    ) { }

    ngOnInit(): void {
        this.subscriptionTenant = this.defaults.tenantMessageChanges$.subscribe(() => {
            this.getAllowedActionsByTenant()
        });
        this.subscriptionControllerType = this.defaults.controllerTypeMessageChanges$.subscribe(() => { this.getAllowedActionsByTenant() });

        if (this.defaults.tenant && this.commandList.length > 0) {
            this.getAllowedActionsByTenant()
        }
    }
    ngOnChanges() {
        this.getAllowedActionsByTenant()
    }

    ngOnDestroy() {
        this.subscriptionTenant.unsubscribe();
        this.subscriptionControllerType.unsubscribe();
    }


    onReload($event: AllowedRemoteAction) {
        this.onReloadTemplate.emit({});
        const local = this.mergedList
        const local2: any[] = []
        this.mergedList = []
        local.forEach(x => {
            if (x.CommandId === $event.CommandId) {
                x.Active = $event.Active;
                x.FirstActivationUtc = $event.FirstActivationUtc;
                x.LastChangeUtc = $event.LastChangeUtc;
            }
            local2.push(x)
        })
        this.mergedList = local2.sort((a, b) => (+b.Active) - (+a.Active) || a.CommandId - b.CommandId);
    }

    getAllowedActionsByTenant() {
        Utils.httpGetAllowedRemoteActions(
            this.http
            , this.baseUrl
            , this
            , function (tthis: AllowedActionComponent, list: AllowedRemoteAction[]) {
                tthis.list = list;
                const tempList: any[] = []
                tthis.commandList.forEach(x => {
                    const item = tthis.list.find(y => y.CommandId === x.CommandId);
                    tempList.push({
                        'Active': item ? item.Active : false,
                        'FirstActivationUtc': item ? item.FirstActivationUtc : '',
                        'LastChangeUtc': item ? item.LastChangeUtc : '',
                        ...x
                    })
                });
                tthis.mergedList = tempList.sort((a, b) => (+b.Active) - (+a.Active) || a.CommandId - b.CommandId);
            }
        );
    }
}
