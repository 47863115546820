import { Component, EventEmitter, Inject, OnInit, Output, Self, ViewChild } from '@angular/core';
import { Utils } from '../../../utils/utils';
import { HttpClient } from '@angular/common/http';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { DatePipe } from '@angular/common';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DefaultValuesService } from '../../../services/defaultvalues.service';
import { DestroyService } from '../../../services/destroyservice';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'tdcfunctiontype',
  templateUrl: './tdcfunctiontype.component.html',
    styleUrls: ['../../../style/new-generic-styles.component.less',
        '../../../style/tables/table-add-butt-header-orange.less',
        './tdcfunctiontype.component.less', '../tdccomponents.component.less'],
    providers: [DestroyService]
})
export class TDCFunctionType implements OnInit {
    public dataSource = new MatTableDataSource<any>();
    FTForm!: FormGroup;
    originalFuncTypes: ComponentFunction[] = [];
    modifiedFuncTypes: ComponentFunction[] = [];
    newFuncTypes: ComponentFunction[] = [];
    @ViewChild(MatTable) objTable!: MatTable<any>;
    genType: number = 0;
    @Output() compFunctionsEvent = new EventEmitter<ComponentFunction[]>();
    isCallSucceed: number = 0;
    subscriptionStage: any;
    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string,
        public datePipe: DatePipe,
        private _formBuilder: FormBuilder,
        private fb: FormBuilder,
        private toastrService: ToastrService,
        public defaults: DefaultValuesService,
        @Self() private readonly destroy$: DestroyService    ) { }

    columnsToDisplay = ["functiontype", "functiontypename", "date", "user", "action"];

    ngOnInit(): void {
        this.subscriptionStage = this.defaults.stageMessageChanges$.pipe(takeUntil(this.destroy$)).subscribe(() => { this.getFunctionTypes() });
        this.genType = 0;
        this.FTForm = this._formBuilder.group({
            FTRows: this._formBuilder.array([])
        });
        this.getFunctionTypes();
    }

    mapDataToForm(data) {
        this.FTForm = this._formBuilder.group({
            FTRows: this._formBuilder.array([])
        });

        this.FTForm = this.fb.group({
            FTRows: this.fb.array(data.map(val => this.fb.group({
                functionTypeId: new FormControl(val.functionTypeId),
                functionType: new FormControl(val.functionType),
                functionTypeName: new FormControl(val.functionTypeName),
                utcModifDate: new FormControl(val.utcModifDate),
                modifUser: new FormControl(val.modifUser),
                isEditable: new FormControl(true),
                isNewRow: new FormControl(false),
                action: new FormControl('existingRecord'),
            })
            )) //end of fb array
        }); // end of form group cretation
        this.dataSource.data = (this.FTForm.get('FTRows') as FormArray).value;

    }

    initFTForm(): FormGroup {
        return this.fb.group({
            functionTypeName: new FormControl(`New_Type_${this.genType}`),
            functionType: new FormControl(this.genType++),
            action: new FormControl('newRecord'),
            isEditable: new FormControl(false),
            isNewRow: new FormControl(true),
        });
    }

    getFunctionTypes() {
        Utils.httpGetComponentFunctionTypes(
            this.http,
            this.baseUrl,
            this,
            function (tthis: TDCFunctionType, result: ComponentFunctionTypes) {
                tthis.originalFuncTypes = result.typeList;
                tthis.mapDataToForm(result.typeList);
                tthis.compFunctionsEvent.emit(tthis.originalFuncTypes);
            }
        )
    }

    onSave() {
        if (this.anyChange()) {
            var funcTypesChanges = { 'modifiedFunctionTypes': this.modifiedFuncTypes, 'newFunctionTypes': this.newFuncTypes }
            Utils.httpUpdateComponentFunctionTypes(
                this.http
                , this.baseUrl
                , funcTypesChanges
                , this
                , function (tthis: TDCFunctionType, result: any) {
                    tthis.getFunctionTypes();
                    tthis.funcNotify();
                }
            )
        }
    }

    editRow(FTFormElement, i) {
        FTFormElement.get('FTRows').at(i).get('isEditable').patchValue(false);

    }

    addNewRow() {
        const control = this.FTForm.get('FTRows') as FormArray;
        control.insert(0, this.initFTForm());
        this.dataSource = new MatTableDataSource(control.controls)
    }

    resetRow(i) {  
        if (this.FTForm.value.FTRows[i]?.isNewRow)
            this.deleteRow(i);
        else {
            var defaultObjectValues = this.originalFuncTypes.find(ft => ft.functionTypeId == this.FTForm.value.FTRows[i]?.functionTypeId)
            this.updateFormFeature(defaultObjectValues, i)
            this.FTForm.value.FTRows[i].isEditable = true;
        }
    }
  
    updateFormFeature(newSelectedObject, index) {
        var obj = this.FTForm.value.FTRows;
        obj[index].functionTypeName = newSelectedObject.functionTypeName;
        obj[index].functionType = newSelectedObject.functionType;
    }

    deleteRow(i) {
        const control = this.FTForm.get('FTRows') as FormArray;
        control.removeAt(i);
        this.dataSource.data = control.value;
        this.refreshTable();
    }

    refreshTable() {
        this.objTable.renderRows();
    }

    anyChange() {
        this.modifiedFuncTypes = this.FTForm.get('FTRows')?.value.filter(row =>
            !row.isNewRow && !row.isEditable
            && row.functionType !== null
            && row.functionTypeName !== ""
            && row.functionTypeName.length <= 40);
        this.newFuncTypes = this.FTForm.get('FTRows')?.value.filter(row =>
            row.isNewRow
            && row.functionType !== null
            && row.functionTypeName !== ""
            && row.functionTypeName.length <= 40);
        return this.modifiedFuncTypes?.length > 0 || this.newFuncTypes?.length > 0;
    }

    funcNotify() {
        if (this.isCallSucceed === 1) {
            this.toastrService.success('Save request successfully sent!');
        }
        else if (this.isCallSucceed === -1) {
            this.toastrService.error('Save action could not be performed. Please, contact with administrator');
        }
    }
}
