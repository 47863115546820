<!-- IE < 10 does not like giving a tbody a height.  The workaround here applies the scrolling to a wrapped <div>. -->
<!--[if lte IE 9]>
<div class="old_ie_wrapper">
<!--<![endif]-->
<div>
    <div>{{filter}}</div>
    <form (submit)="onSearchColumns(0)" [ngClass]="classform">
        <span *ngFor="let l of header; index as i" [ngClass]="getClassSearchHeader(i)">
            <input *ngIf="l.searchtype != 'checkbox'" id="{{idtable}}-search-header-{{i}}" type="{{l.searchtype}}" placeholder="Search {{l.label}}" />
            <!--<span *ngIf="l.searchtype == 'checkbox'">{{l.label}}:</span>-->
            <select *ngIf="l.searchtype == 'checkbox'" id="{{idtable}}-search-header-{{i}}" title="{{l.label}}">
                <option>&nbsp;</option>
                <option>Yes</option>
                <option>No</option>
            </select>
        </span>
        <button class="button-search" type="submit" title="Search all columns with the content of textboxes">&nbsp;</button>
        <button class="button-reset" type="button" title="Reset all search" (click)="onResetList()">&nbsp;</button>
    </form>
    <table style="width: auto;" id="{{idtable}}" class="fixed_headers">
        <thead>
            <tr>
                <th *ngFor="let l of header; index as i">
                    {{l.label}}
                </th>
                <th *ngIf="isToShowEditButton()">&nbsp;</th>
            </tr>
        </thead>

        <tbody>
            <tr *ngFor="let d of data; index as r" (click)="onRowSelect(d)">
                <td *ngFor="let l of header; index as c" [ngClass]="getClass(r, c)">
                    <span *ngIf="!l.checkbox">{{getData(r, c)}}</span>
                    <span *ngIf="l.checkbox"><input type="checkbox" [checked]="isInputChecked(r, c)" value="1" onclick="return false;" /></span>
                </td>
                <td *ngIf="isToShowEditButton()"><button type="button" class="btn btn-default" (click)="onRowEdit(d)">{{editLabel}}</button></td>
            </tr>
        </tbody>
    </table>
</div>
<!--[if lte IE 9]>
</div>
<!--<![endif]-->
