import { Component, Inject, OnInit, ElementRef, ViewChild, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Utils } from "../../utils/utils";
import { DefaultValuesService } from "../../services/defaultvalues.service";
import { DialogService } from "../../services/dialog.service";
import { WaitingService } from "../../services/waiting.service";
import { SaveService } from "../../services/save.service";

@Component({
    selector: "errorcode-edit-detail-newlanguage",
    templateUrl: './errorcode-edit-detail-newlanguage.component.html',
    styleUrls: ['../../style/form.component.less']
})

export class ErrorCodeEditDetailNewLanguageComponent implements OnInit {
    
    @Input() header: any[] = [];
    @Input() list: ErrorCodeResolution[] = [];
    //@Input() businessKey: BusinessKey | undefined;
    @Input() rowIndex: number = -1;

    MAINTENANCEFAILURE: number = 1;
    TROUBLESHOOTINGACTION: number = 2;

    selected: ErrorCodeResolution = <ErrorCodeResolution>{};
    classname: String[] = [];
    isundodisabled: boolean[] = [];

    //errorcode: ErrorCode = <ErrorCode>{};
    //list: ErrorCodeResolution[] = [];
    form!: FormGroup;
    //languages: Language[] = [];
    ////controllertype: ControllerType = <ControllerType>{};
    //editMode: boolean = false;

    //classname: string | undefined;
    //classdialog: string = Utils.getDialogHideClassName();
    //type: number = Utils.getSuccessValue();
    //message: string | undefined;

    constructor(
        private http: HttpClient
        , private fb: FormBuilder
        , @Inject('BASE_URL') private baseUrl: string
        , private dialog: DialogService
        , public saveService: SaveService
    ) {
    }

    ngOnInit() {

        this.dialog.tthis = this;
        this.saveService.addListener(this);

        this.selected.MaintenanceFailure = this.list[this.rowIndex].MaintenanceFailure;
        this.selected.TroubleShootingAction = this.list[this.rowIndex].TroubleShootingAction;

        this.createForm();
        this.updateForm();

        let tthis = this;
        this.header.forEach(
            function (value: any, index: number, array: any[]) {
                tthis.classname[index] = "";
                tthis.isundodisabled[index] = true;
            }
        );
    }

    createForm() {
        this.form = this.fb.group({
            MaintenanceFailure: [this.selected.MaintenanceFailure, Validators.required],
            TroubleshootingAction: [this.selected.TroubleShootingAction, Validators.required],
        });
    }

    /**
     * called by save service
     * */
    onCancel(index: number) {
        if (this.form && index == this.rowIndex) {
            this.onMaintenanceFailureUndo();
            this.onTroubleShootingActionUndo();
        }
    }

    onMaintenanceFailureChange(evt: any) {
        if (this.form
            && this.form.controls['MaintenanceFailure'].value !== this.selected.MaintenanceFailure) {
            this.list[this.rowIndex].MaintenanceFailure = this.form.controls['MaintenanceFailure'].value;
            this.setUndoDisable(this.MAINTENANCEFAILURE, false);
        }
        this.saveService.showSaveButton();
    }

    onMaintenanceFailureUndo() {
        this.list[this.rowIndex].MaintenanceFailure = this.selected.MaintenanceFailure;
        this.setUndoDisable(this.MAINTENANCEFAILURE, true);
        this.updateForm();
    }

    onRowCancel() {
        this.saveService.fireCancel(this.rowIndex, this);
    }

    onSave() {
        if (this.form && this.selected) {

            this.http
                .post<ErrorCodeResolution>(
                    this.baseUrl + Utils.getErrorCodeAPI() + "translatepossiblefailure"
                    , this.list[this.rowIndex]
                )
                .subscribe(res => 
                    this.list[this.rowIndex] = res
                    , error => {
                        Utils.httpGetPossibleFailure(
                            this.http
                            , this.baseUrl
                            , this.list[this.rowIndex].PossibleFailureID
                            , this
                            , function (tthis: ErrorCodeEditDetailNewLanguageComponent, value: ErrorCodeResolution) {
                                tthis.list[tthis.rowIndex] = value;
                            }
                        );

                        this.dialog.showErrorDialog(error);
                    } 
                );
        }
    }

    onTroubleShootingActionChange(evt: any) {
        if (this.form
            && this.form.controls['TroubleshootingAction'].value !== this.selected.TroubleShootingAction) {
            this.list[this.rowIndex].TroubleShootingAction = this.form.controls['TroubleshootingAction'].value;
            this.setUndoDisable(this.TROUBLESHOOTINGACTION, false);
        }
        this.saveService.showSaveButton();
    }

    onTroubleShootingActionUndo() {
        this.list[this.rowIndex].TroubleShootingAction = this.selected.TroubleShootingAction;
        this.setUndoDisable(this.TROUBLESHOOTINGACTION, true);
        this.updateForm();
    }

    setUndoDisable(index: number, value: boolean) {
        this.classname[index] = value ? "" : Utils.getEditingInputClassName();
        this.isundodisabled[index] = value;
    }

    updateForm() {
        if (this.form) {
            this.form.setValue({
                MaintenanceFailure: this.list[this.rowIndex].MaintenanceFailure
                , TroubleshootingAction: this.list[this.rowIndex].TroubleShootingAction
            });
        }
    }

}