import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import "rxjs/operators";
import { Data, Router } from '@angular/router';
import { DialogService } from '../../../services/dialog.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Utils } from '../../../utils/utils';
import { DefaultValuesService } from '../../../services/defaultvalues.service';
import * as moment from 'moment';
import { DatePipe } from '@angular/common'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: "serviceticketcategoryform",
    templateUrl: './serviceticketcategory-form.component.html',
    styleUrls: ['../../../style/azdatafactory.component.less', '../../../style/custom-mat-dialog.component.less']

})

export class ServiceTicketCategoryFormComponent implements OnInit {


    ServiceTicketCategoryHarmonizedValues: any[] = [];

    body: any;
    title: string;
    submitted = false;
    form: FormGroup = new FormGroup({});

    constructor(private http: HttpClient
        , private confimDialog: DialogService
        , private router: Router
        , @Inject(MAT_DIALOG_DATA) public data: any
        , @Inject('BASE_URL') private baseUrl: string
        , public dialogRef: MatDialogRef<ServiceTicketCategoryFormComponent>
        , private fb: FormBuilder
        , private defaults: DefaultValuesService
        , private formBuilder: FormBuilder
        , private datepipe: DatePipe) {

        this.confimDialog.tthis = this;
        this.title = "";
    }

    ngOnInit(): void {
        //this.body = {
        //    SessionId: ''
        //}

        const tthis = this;


        const url = this.baseUrl + "api/BackendHarmonization/serviceticket_" + "allharmonized/";


        this.http.get<any[]>(url).subscribe(result => {
            tthis.ServiceTicketCategoryHarmonizedValues = result;
        }, error => {
            Utils.writeLog(this.http, this.baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));


        //Utils.httpGetAllControllerTypesAvailable(
        //    this.http
        //    , this.baseUrl
        //    , this
        //    , function (tthis: any, list: ControllerType[]) {
        //        tthis.controllerTypes = list;
        //    }
        //);




        this.body = {
            ServiceTicketCategory: this.data.ServiceTicketCategoryId
        }


        this.form = this.formBuilder.group({
            ServiceTicketCategory: [this.data.ServiceTicketCategoryId],
        });

        //this.form = this.formBuilder.group({
        //    ControllerType: [this.data.ControllerTypeBK, this.sessionIdValidator],
        //});



    }


    // convenience getter for easy access to form fields
    get f() {
        return this.form.controls;
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    onSubmit() {
        this.submitted = true;
        //alert(this.data._ControllerTypeMappingID);

        if (this.form.value.ServiceTicketCategory != "") {
            let CategoryLocale = encodeURIComponent(this.data.CategoryLocale);
            const body = {
                'TenantId': this.data.TenantID,
                'CategoryLocale': CategoryLocale,
                'ServiceTicketCategoryId': this.form.value.ServiceTicketCategory
            }
            let url = this.baseUrl + "api/BackendHarmonization/serviceticket_update" ;

            this.http
                .post<boolean>(url, body)
                .subscribe(() => {
                    this.dialogRef.close(true);
                }, error => {
                    Utils.writeLog(this.http, this.baseUrl, error); //Utils.showErrorDialog(null, error);
                }); //console.error(error));
        }
        else
            this.dialogRef.close(false);


    }

    onBack() {

    }



}


