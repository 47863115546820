import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { CustomDialogService } from '../../../../../services/customdialog.service';
import { ToastrService } from 'ngx-toastr';
import { Utils } from '../../../../../utils/utils';
import { CreateWavePopupComponent } from './createwavepopup/createwavepopup.component';

@Component({
    selector: 'wave',
    templateUrl: './wave.component.html',
    styleUrls: ['./wave.component.less',
        '../branchexcellenceadmin.component.less',
        '../../../../../style/new-generic-styles.component.less']
})
export class WaveComponent implements OnInit {

    @Output() selectedWaveEmit = new EventEmitter<{}>();

    subscriptions: any[] = [];
    public dataSource = new MatTableDataSource<any>();
    columnsToDisplay = ['Select', 'Name', 'StartDate', 'EndDate', 'ChangedAtUtc', 'ChangedBy', 'actions'];

    selectedWave: Wave;

    constructor(
        private http: HttpClient
        , private dialogForm: MatDialog
        , @Inject('BASE_URL') private baseUrl: string
        , private customDialog: CustomDialogService
        , private toastrService: ToastrService) {
        this.selectedWave = {
            WaveId: -1,
            Name: '',
            ChangedBy: ''
        }
    }

    ngOnInit(): void {
        this.getWaves();
    }

    getWaves() {
        Utils.httpGetWaves(
            this.http,
            this.baseUrl,
            this,
            function (tthis: WaveComponent, list: any) {
                tthis.dataSource.data = list
            },
            null

        )
    }

    resetSelectedWave() {
        this.selectedWave = {
            WaveId: -1,
            Name: '',
            ChangedBy: ''
        }
    }

    onEditWave(element: Wave) {
        const dialog = this.dialogForm.open(CreateWavePopupComponent, {
            panelClass: ['custom-mat-dialog_v2'],
            disableClose: true,
            data: element,
        })

        dialog.afterClosed().subscribe(() => {
            this.getWaves()
        })
    }

    openCreateWavePopup() {
        const dialog = this.dialogForm.open(CreateWavePopupComponent, {
            panelClass: ['custom-mat-dialog_v2'],
            disableClose: true,
        })

        dialog.afterClosed().subscribe(() => {
            this.getWaves()
        })
    }

    onSelectWave(element: Wave) {
        if (this.selectedWave?.WaveId === element.WaveId)
            this.resetSelectedWave()
        else
            this.selectedWave = element;
        this.selectedWaveEmit.emit(this.selectedWave);
    }

}
