<div class="container-tab-lists">
    <branchhierarchy-menu (onReload)="onReload()" (newBranch)="onNewBranch()" (outputDownload)="downloadBranchHierarchy()"></branchhierarchy-menu>

    <h2>Branch Hierarchy</h2>
    <div style="display:flow-root">
        <div style="text-align:right; float:right">
            <h5>**A PBI must be created and assigned to anyone from DevOps Team for deploying new changes.**</h5>
        </div>
    </div>
    <branchhierarchy-list [list]="list" [listRef]="listRef" [listKey]="listKey" [newService]="newBranch" (onDelete)="onDelete($event)"></branchhierarchy-list>
</div> 