<h3 *ngIf="newRow" mat-dialog-title>{{title}}</h3>
<mat-dialog-content>
    <form [formGroup]="form">

        <table>
            <tbody class="inner">
                <tr>
                    <td>&nbsp;</td>
                    <td style="width:250px;">
                        <mat-form-field>
                            <mat-label *ngIf="!newRow" [ngClass]="classname[DESCRIPTION]">{{header[DESCRIPTION].label}}: {{selected.BK}}</mat-label>
                            <mat-label *ngIf="newRow">{{header[DESCRIPTION].label}}</mat-label>
                            <input matInput type="text" name="Description" required formControlName="Description"
                                    title="{{header[DESCRIPTION].label}}"
                                    [ngClass]="classname[DESCRIPTION]"
                                    (change)="onDescriptionChange($event)">
                        </mat-form-field>
                        <button *ngIf="!newRow" mat-icon-button color="accent" matTooltip="Undo" [disabled]="isundodisabled[DESCRIPTION]" (click)="onDescriptionUndo()">
                            <mat-icon aria-label="undo">restore</mat-icon>
                        </button>
                    </td>
                    <td *ngIf="!newRow">
                        <button mat-icon-button matTooltip="Save" [disabled]="!form.valid" (click)="onSubmit()">
                            <mat-icon aria-label="Save">save</mat-icon>
                        </button>
                        <button mat-icon-button matTooltip="Cancel" (click)="onCancelForm()">
                            <mat-icon aria-label="Cancel">cancel</mat-icon>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>

        <div mat-dialog-actions *ngIf="newRow">
            <button mat-raised-button [disabled]="!form.valid" (click)="onSubmit()">Save</button> <!--[mat-dialog-close]="1"-->
            <button mat-button (click)="onCancelForm()" tabindex="-1">Cancel</button>
        </div>
    </form>
</mat-dialog-content>
