import { Component, Inject, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DefaultValuesService } from "../../../../services/defaultvalues.service";

@Component({
    selector: "kpimersyreport",
    templateUrl: './kpimersyreport.component.html',
    styleUrls: ['../../../../style/container-tab-list.component.less']
})




export class KpiMersyReportComponent {

    ReportType: string = "";

    constructor(
        private http: HttpClient,
        private defaults: DefaultValuesService
        , @Inject('BASE_URL') private baseUrl: string
    ) {

        if (baseUrl.includes('localhost') || baseUrl.includes("dev-"))
            this.ReportType = "DEV";
        else
            this.ReportType = "PROD";

    }



}





