import { Component, Inject, Input, OnInit, ViewChild, EventEmitter, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { DefaultValuesService } from "../../services/defaultvalues.service";
import { SaveService } from "../../services/save.service";
import { DialogService } from "../../services/dialog.service";
import { CustomDialogService } from "../../services/customdialog.service";
import { Utils } from "../../utils/utils";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';
import { MatDialog } from "@angular/material/dialog";
import { Sort } from "@angular/material/sort";
import { MatChipInputEvent } from "@angular/material/chips";

@Component({
    selector: "operationmode-list-properties",
    templateUrl: './operationmode-list-properties.component.html',
    styleUrls: ['./operationmode-list-properties.component.less', '../../style/mat-table.component.less'],
})

export class OperationModePropertiesListComponent implements OnInit {
    @Input('list') list: OperationMode[] = [];
    @Input('isDigitalServicesView') isDigitalServicesView = false;
    @Input() equals: any | undefined;
    @Input() newService: SaveService | undefined;
    @Output() onUpdateTable = new EventEmitter<{}>();
    @Input('openDeploymentInformationPopup') openDeploymentInformationPopup!: Function

    show = false;

    columnsToDisplay = ['offset', 'mode', 'value', 'available', 'isgroupevent', 'stablealertrule', 'prodalertrule', 'isgoodevent', 'component', 'status', 'actions'];

    @ViewChild(MatTable, { static: true }) table!: MatTable<any>;

    public dataSource = new MatTableDataSource<OperationMode>();
    selected: OperationMode | undefined;
    expanded: boolean[] = [];
    expandedElements = new Set();
    clonedList: { [s: string]: OperationMode; } = {};

    harmonizedopmodes: HarmonizedOperationMode[] = [];
    alertRules: AlertRule[] = [];
    filter = '';

    subscriptionLanguage: any;
    subscriptionTenant: any;
    subscriptionIsControllerOwner: any;

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [COMMA, TAB, ENTER];
    searchItems: SearchItem[] = [];

    filters: ColumnFilter = { 'available': undefined, 'groupevent': undefined, 'safetyrelated': undefined, 'potentialmisuse': undefined, 'stablealertruleid': -1, 'prodalertruleid': -1, 'isgoodevent': undefined };

    header: any[] = [
        { label: 'Mode *', show: true}
        , { label: 'Value *', show: true }
        , { label: 'Operational (Available)', show: true, checkbox: true, title: "Any mode of operation in which the equipment is able to perform its normal function (Elevators: automatically servicing hall calls/ Escalator: running without fault mode)" }
        , { label: 'Group Event', show: true, checkbox: true, title: "This op mode could block Elevator after more than 1 hour without group function" }
        , { label: 'Safety Related', show: true, checkbox: true, title: "Safety Related" }
        , { label: 'Potential Misuse', show: true, checkbox: true, title: "Potential Misuse" }
        , { label: 'Is Good Event', show: true, checkbox: true }
        , { label: 'Component', show: true }
        , { label: 'Stable Alert Rule', show: true, checkbox: true, title: "Stable Alert Rule" }
        , { label: 'Prod Alert Rule', show: true, checkbox: true, title: "Prod Alert Rule" }
        , { label: 'Equipment Specific Status *', show: true, title: "The Equipment Specific Status are an internal mapping among all controller specific op modes to a predefined op modes. The relationship between op modes and harmonized op modes is 1: 1. So every op mode can only be related to one specific equipment status.It will be calculated about them duration and counting." }
        , { label: 'Description', show: false }
    ];

    constructor(private activatedRoute: ActivatedRoute
        , private router: Router
        , private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , public defaults: DefaultValuesService
        , private dialog: DialogService
        , private customDialog: CustomDialogService
        , public saveService: SaveService
        , private matDialog: MatDialog

    ) {
    }

    ngOnChanges() {
        this.dataSource.data = this.list
        if (this.newService) {
            this.newService.addListener(this);
        }
        this.saveService.addListener(this)
    }

    ngOnInit() {
        this.saveService.addListener(this);
        this.dialog.tthis = this;
        this.getHeader()
        this.subscriptionLanguage = this.defaults.languageMessageChanges$.subscribe(() => {
            this.getHeader()
        });
        this.subscriptionTenant = this.defaults.tenantMessageChanges$.subscribe(() => {
            this.getHeader()
        });
        this.subscriptionIsControllerOwner = this.defaults.isControllerOwnerChange$.subscribe(() => {
            this.getHeader()
        });

        if (!this.alertRules || this.alertRules.length === 0) {
            Utils.httpGetAllAlertRules(
                this.http
                , this.baseUrl
                , this
                , function (tthis: OperationModePropertiesListComponent, list: AlertRule[]) {
                    tthis.alertRules = list;
                }
            );
        }
        this.dataSource.filterPredicate = (data: OperationMode, filters: string) => {
            const matchFilter: any[] = [];
            const filterArray = filters.split('+').filter(x => x.indexOf('notextfilters') < 0)
            if (filterArray.length > 0) {
                filterArray.forEach((filter) => {
                    let result = false;
                    const mode = data.Mode ? data.Mode : ''
                    const value = data.Value ? data.Value : ''
                    const status = data.SpecificEquipmentStatus ? data.SpecificEquipmentStatus : ''
                    result = mode.toString().toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
                        value.toString().toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
                        status.toString().toLowerCase().indexOf(filter.toLowerCase()) > -1

                    result = result && this.auxFilterPredicate(data);
                    return matchFilter.push(result);
                });
            }
            else {
                let result = this.auxFilterPredicate(data)
                matchFilter.push(result);
            }
            return matchFilter.some(Boolean);
        };
    }

    ngOnDestroy() {
        this.subscriptionTenant.unsubscribe();
        this.subscriptionLanguage.unsubscribe();
    }

    auxFilterPredicate(data: OperationMode) {
        let result = true;
        const keys = Object.keys(this.filters) // Checkboxes with AND filter
        keys.forEach(key => {
            switch (key) {
                case 'available': if (this.filters.available) result = result && data.isUnitAvailable; else if (this.filters.available === false) result = result && !data.isUnitAvailable; break;
                case 'groupevent': if (this.filters.groupevent) result = result && data.isGroupEvent; else if (this.filters.groupevent === false) result = result && !data.isGroupEvent; break;
                case 'safetyrelated': if (this.filters.safetyrelated) result = result && data.isSafetyRelated; else if (this.filters.safetyrelated === false) result = result && !data.isSafetyRelated; break;
                case 'potentialmisuse': if (this.filters.potentialmisuse) result = result && data.isPotentialMisuse; else if (this.filters.potentialmisuse === false) result = result && !data.isPotentialMisuse; break;
                case 'isgoodevent': if (this.filters.isgoodevent) result = result && data.IsGoodEvent; else if (this.filters.isgoodevent === false) result = result && !data.IsGoodEvent; break;
                case 'stablealertruleid': if (this.filters.stablealertruleid !== -1) result = result && data.StableAlertRuleCatId === this.filters.stablealertruleid; break;
                case 'prodalertruleid': if (this.filters.prodalertruleid !== -1) result = result && data.ProdAlertRuleCatId === this.filters.prodalertruleid; break;
            }
        })
        return result;
    }

    getHeader() {
        if (this.defaults.getIsControllerOwner() && !this.defaults.isReadonlyUser)
            this.columnsToDisplay = ['offset', 'mode', 'value', 'available', 'isgroupevent', 'safetyrelated', 'potentialmisuse', 'stablealertrule', 'prodalertrule', 'isgoodevent', 'component', 'status', 'actions'];
        else
            this.columnsToDisplay = ['offset', 'mode', 'value', 'available', 'isgroupevent', 'safetyrelated', 'potentialmisuse', 'stablealertrule', 'prodalertrule', 'isgoodevent', 'component', 'status'];

        if (!this.isDigitalServicesView) {
            this.columnsToDisplay.splice(this.columnsToDisplay.indexOf('isgoodevent'), 2)
        }
    }

    notIndicated(text: string | number): string {
        var local = ""
        if (typeof (text) === 'string') {
            local = text
        }
        else if (text === null) {
            local = null!
        }
        return Utils.notTranslatedClass(local);
    }

    onRowEditInit(opMode: OperationMode) {
        this.saveService.addListener(this);
        if (!this.harmonizedopmodes || this.harmonizedopmodes.length === 0) {
            Utils.httpGetAllOperationModesHarmonized(
                this.http
                , this.baseUrl
                , this
                , function (tthis: OperationModePropertiesListComponent, list: HarmonizedOperationMode[]) {
                    tthis.harmonizedopmodes = list;
                }
            );
        }
        this.clonedList[opMode.Value] = { ...opMode };
        if (Object.keys(this.clonedList).length !== 0) this.onShowSavebutton()
    }

    onExpand(value: any) {
        this.selected = value.value;
    }

    onDelete(element: OperationMode) {
        this.customDialog.openConfirm({
            title: "Delete Operation Mode",
            message: `${element.Mode}(${element.Value})?`,
            caller: this
        });
        this.customDialog.confirmed().subscribe(res => {
            if (res.confirmed) {
                const foundIndex = res.caller.list.findIndex((x: OperationMode) => x.Value === element.Value && x.Mode === element.Mode);
                if (foundIndex > -1) {
                    res.caller.list.splice(foundIndex, 1);
                }
                this.http
                    .delete<OperationMode>(
                        this.baseUrl + Utils.getOperationModeAPI() + element.Value
                    ).subscribe(() => {
                        // UI manipulation
                        const foundIndex = res.caller.list.findIndex((x: OperationMode) => x.Value === element.Value && x.Mode === element.Mode);
                        if (foundIndex > -1) {
                            res.caller.list.splice(foundIndex, 1);
                        }

                        res.caller.table.renderRows();
                        this.updatefilter()
                        this.onUpdateTable.emit();
                    }, (error: { error: { message: any } }) => { this.customDialog.openDisplayInfoDialog({ title: 'Error', message: error.error.message }); });
            }
        });
    }

    public doFilter = (value: string) => {
        //this.dataSource.filter = value.trim().toLocaleLowerCase();
        this.filter = value
    }

    onNew() {
        this.show = true
        this.saveService.addListener(this);
    }

    public onNewRowEvent() {
        this.table.renderRows();
        this.updatefilter()
        this.show = false
    }

    onShowSavebutton() {
        this.saveService.showSaveButton();
    }

    onRowEditCancel(opMode: OperationMode) {
        this.dataSource.data[this.dataSource.data.findIndex(x => x.Value === opMode.Value)] = this.clonedList[opMode.Value];
        delete this.clonedList[opMode.Value];

        if (Object.keys(this.clonedList).length === 0) this.saveService.showSaveButton(false)
        this.table.renderRows();
    }
    isEditingRow(element: OperationMode) {
        return this.clonedList[element.Value]
    }

    onSave() {
        for (let i in this.clonedList) {
            const index = this.dataSource.data.findIndex(x => x.Value === this.clonedList[i].Value)
            this.dataSource.data[index].isUnitUnavailable = !this.dataSource.data[index].isUnitAvailable;
            if (Object.entries(this.clonedList[i]).toString() !== Object.entries(this.dataSource.data[index]).toString()) {
                this.http
                    .post<OperationMode>(
                        this.baseUrl + Utils.getOperationModeAPI() + "edit"
                        , this.dataSource.data[index]
                    )
                    .subscribe(
                        res => {
                            this.dataSource.data[index] = this.list[index] = res
                            delete this.clonedList[this.clonedList[i].Value];
                            this.table.renderRows();
                            this.updatefilter()
                        }
                        , error => {
                            this.dialog.showErrorDialog(error)
                            this.onRowEditCancel(this.clonedList[i])
                            delete this.clonedList[this.clonedList[i].Value];
                            this.table.renderRows();
                        }
                    );
            }
            else {
                delete this.clonedList[this.clonedList[i].Value];
            }
        }
        this.show = false
    }

    sortData(sort: Sort) {

        const data = this.dataSource.data.slice();
        if (!sort.active || sort.direction === '') {
            this.dataSource.data = data;
            return;
        }

        this.dataSource.data = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'mode':
                    return this.compare(a.Mode ? a.Mode.toUpperCase() : '', b.Mode ? b.Mode.toUpperCase() : '', isAsc);
                case 'value':
                    return this.compare(a.Value, b.Value, isAsc);
                case 'eqStatus':
                    return this.compare(a.SpecificEquipmentStatus ? a.SpecificEquipmentStatus : '', b.SpecificEquipmentStatus ? b.SpecificEquipmentStatus : '', isAsc);
                default:
                    return 0;
            }
        });
    }
    compare(a: number | string, b: number | string, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    applyFilter(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            this.searchItems.push({ name: value.replace('+', '').trim() });
        }
        // Reset the input value
        if (input) {
            input.value = '';
        }
        this.updatefilter()
    }

    filterColumn(value: any, name: string) {
        switch (name) {
            case 'available': this.filters.available = value.value === 'true' ? true : value.value === 'false' ? false : undefined; break;
            case 'groupevent': this.filters.groupevent = value.value === 'true' ? true : value.value === 'false' ? false : undefined; break;
            case 'safetyrelated': this.filters.safetyrelated = value.value === 'true' ? true : value.value === 'false' ? false : undefined; break;
            case 'potentialmisuse': this.filters.potentialmisuse = value.value === 'true' ? true : value.value === 'false' ? false : undefined; break;
            case 'stablealertruleid': this.filters.stablealertruleid = value.value; break;
            case 'prodalertruleid': this.filters.prodalertruleid = value.value; break;
        }
        this.updatefilter()
    }

    remove(item: SearchItem): void {
        const index = this.searchItems.indexOf(item);
        if (index >= 0) {
            this.searchItems.splice(index, 1);
        }
        this.updatefilter()
    }
    updatefilter() {
        let filterString: string
        if (this.searchItems.length === 0) {
            this.dataSource.filter = 'notextfilters'
        }
        else {
            filterString = this.searchItems.map(e => { return e.name }).join('+') + '+checkboxes'
            this.dataSource.filter = filterString
        }
    }
}
export interface SearchItem {
    name: string;
}

interface ColumnFilter {
    available: Boolean | undefined;
    groupevent: Boolean | undefined;
    safetyrelated: Boolean | undefined;
    potentialmisuse: Boolean | undefined;
    isgoodevent: Boolean | undefined;
    stablealertruleid: number | undefined;
    prodalertruleid: number | undefined;
}