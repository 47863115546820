import { AbstractControl, ValidationErrors, FormGroup } from "@angular/forms"
import * as iconv from 'iconv-lite';

export const rangeValidator = function (min: number, max: number) {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;

        if (value !== null && (isNaN(value) || value < min || value > max)) {
            return {
                rangeError: `Value must be between ${min} and ${max}`
            };
        }

        return null;
    };
};

export const lengthValidator = function (minLength: number, maxLength: number) {
    return (control: AbstractControl): ValidationErrors | null => {
        // Get the value from the control
        const value = control.value;
        if (value.toLowerCase() == 'null') { return null };
        // Check the length of the string
        if (value.length < minLength || value.length > maxLength) {
            return {
                lengthError: `Field's' length should be between ${minLength} and ${maxLength} characters`
            };
        }

        // Return null if the value meets both criteria
        return null;
    };
};

export const isValidEncoding = function ( specifiedEncoding: string) {
    return (control: AbstractControl): ValidationErrors | null => {
        try {
            var inputText = control.value;
            const decodedText = iconv.decode(inputText, specifiedEncoding);
            if (decodedText != inputText) {
                return { EncodingError: `Field only accepts ${specifiedEncoding} characters` };
            }
            return null;
        } catch (error) {
            return { EncodingError: `Field only accepts ${specifiedEncoding} characters` };
        }
    }
}