import { Component, Inject, Output, EventEmitter, Input } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { SaveService } from '../../../services/save.service';
import { DefaultValuesService } from '../../../services/defaultvalues.service';
import { MatDialog } from "@angular/material/dialog";
import { Utils } from '../../../utils/utils';

@Component({
    selector: 'controllertypetomasterdatarequired-menu',
    templateUrl: './controllertypetomasterdatarequired-menu.component.html',
    styleUrls: ['../../../style/menu.component.less']
})

export class ControllerTypeToMasterDataRequiredMenuComponent {

    @Output() onRefresh = new EventEmitter<{}>();

    languageList: Language[] | undefined = [];

    constructor(private activatedRoute: ActivatedRoute,
        private router: Router
        , private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , private saveService: SaveService
        , private defaults: DefaultValuesService
        , private dialogForm: MatDialog

    ) {
    }
}
