<div class='left-menu'>
    <ul class='nav navbar-nav'>
        <li [routerLinkActive]="['link-active']">
            <a class='menu-callback' (click)="onRefresh.emit()" title="Refresh">
                <span class='glyphicon glyphicon-refresh'></span> Refresh
            </a>
        </li>
        <li class="separator" *ngIf="!defaults.isReadonlyUser" [ngClass]="saveService.classSaveButton"></li>
        <li [routerLinkActive]="['link-active']" [ngClass]="saveService.classSaveButton" *ngIf="!defaults.isReadonlyUser">
            <a class='menu-callback' (click)="onSave()" title="Save all Harmonized Operation Modes">
                <span class='glyphicon glyphicon-floppy-disk'></span> Save All
            </a>
        </li>
    </ul>
</div>