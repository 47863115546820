<div class="mgmt_branch">
    <div class="row heading-background mt-5">

        <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 search-container-start">
            <h4>Management Branch Hierarchy</h4>
            <div  class="col-sm-12 col-xs-12">
                <mat-button-toggle-group style=" margin-left: 10px;" [(ngModel)]="selectorValue">
                    <mat-button-toggle matTooltip="Show all nodes" value="All">All</mat-button-toggle>
                    <mat-button-toggle *ngFor="let item of allToggelButtonOption" matTooltip="Show only {{item}} nodes" value="{{item}}">{{item}}</mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </div>

        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 search-container">

            <input class="form-control" [(ngModel)]="searchString" type="text" (keyup)="treeControl.expandAll()" placeholder="Search" />
            <mat-slide-toggle matTooltip="Show only selected" class="toggle-show-only-selected" [(ngModel)]="showOnlySelected"></mat-slide-toggle>
            <button aria-label="Collapse All" class="btn btn-sm button-color-fix " (click)="treeControl.collapseAll()">
                <mat-icon>unfold_less</mat-icon>
            </button>
            <button aria-label="Expand All" class="btn btn-sm button-color-fix " (click)="treeControl.expandAll()">
                <mat-icon>unfold_more</mat-icon>
            </button>
        </div>

    </div>
    <div class="custom_small_panelbody_fix">
        <div *ngIf='Tree_Response_State=== 1'>
            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
                <!-- This is the tree node template for leaf nodes -->
                <mat-tree-node *matTreeNodeDef="let node"
                               matTreeNodeToggle
                               [style.display]="showOnlySelected && !node.selected || this.searchString && hideLeafNode(node) || (!(selectorValue === 'All') &&  hideLeafNodeselector(selectorValue,node ))  ? 'none' : 'block'">

                    <li class="mat-tree-node">
                        <mat-checkbox class="checklist-leaf-node" (change)="itemToggle($event.checked,node, true)" [disabled]="node.disabled" [checked]="node.selected">{{node.groupName}} </mat-checkbox>
                    </li>
                </mat-tree-node>
                <!-- This is the tree node template for expandable nodes -->
                <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild"
                                      [style.display]="(showOnlySelected && !(node.selected || node.indeterminate)) || this.searchString && hideParentNode(node) || (!(selectorValue === 'All') &&  hideParentNodeselector(selectorValue,node )) ? 'none' : 'block'">
                    <!--[style.display]="showOnlySelected && !node.selected || this.searchString && hideLeafNode(node) || (!(selectorValue === 'All') &&  hideLeafNodeselector(selectorValue,node ))  ? 'none' : 'block'">-->
                    <li>
                        <div class="mat-tree-node">
                            <button mat-icon-button matTreeNodeToggle
                                    [attr.aria-label]="'toggle ' + node.groupName">
                                <mat-icon class="mat-icon-rtl-mirror">
                                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                </mat-icon>
                            </button>
                            <mat-checkbox [checked]="node.selected" [disabled]="node.disabled"
                                          [indeterminate]="node.indeterminate && !node.selected"
                                          (change)="itemToggle($event.checked,node, true)">{{node.groupName}}</mat-checkbox>
                        </div>
                        <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
                            <ng-container matTreeNodeOutlet></ng-container>
                        </ul>
                    </li>
                </mat-nested-tree-node>
            </mat-tree>
        </div>
        <div *ngIf='Tree_Response_State===-2' style=" padding: 20px !important;">
            <div class="blackbg card__desc">
                <div *ngFor="let _ of [].constructor(4)" class="card__desc-text"></div>
            </div>
        </div>

    </div>
    <!--<div class="panel-body">
        <button (click)="submit()" type="submit" class=" btn button-color-fix pull-right">
            Save
            <mat-icon class="icon-color-fix">add</mat-icon>
        </button>
    </div>-->
</div>

