import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from
    "@angular/forms";
import { ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from "@angular/forms";
import { DialogService } from '../../../../../services/dialog.service';
import { Utils } from '../../../../../utils/utils';
import { SpinnerOverlayService } from '../../../../../services/spinner-overlay.service';
import { MatTableDataSource } from '@angular/material/table';


interface group {
    id: string;
    persona: string;
    isDirect: boolean;
    hidden: boolean;
    parentIds: any;

    // Add more properties if needed
}



@Component({
    selector: "cacheinfodialogue",
    templateUrl: './cacheinfodialogue.component.html',
    styleUrls: ['../edituser.component.less', './cacheinfodialogue.component.less'],

})

export class CacheInfoDialogue implements OnInit {
    public userName: String = '';
    userId = ''


    searchString: string = '';
    filteredSearchResults: any[] = [];

    Response_State: number = 0; //0 -> empty , 1 -> correct and not empty ,-1 -> error
    errorResponse: any;
    isCallSucceed: number = 0;

    formControlGroups = new FormControl();

    cacheInfoList: any[] = []
    columnsBranchesToDisplay = ['branch', 'tenant'];
    columnsRolesToDisplay = ['item'];
    searchStringBranches = '';
    searchStringRoles = '';

    timeoutFunction: any;

    constructor(
        private http: HttpClient
        , public dialogRef: MatDialogRef<CacheInfoDialogue>
        , private confimDialog: DialogService
        , private toastrService: ToastrService,
        private route: ActivatedRoute,
        private spinnerOverlayService: SpinnerOverlayService,
        @Inject('BASE_URL') private baseUrl: string,
        @Inject(MAT_DIALOG_DATA) public data: any

    ) {
        this.route.params.subscribe(
            (params: Params) => {
                this.resetAll();
            });

    }

    ngOnInit() {

    }
    resetAll() {
        this.userName = '';
        this.userId = ''

        this.searchString = '';
        this.filteredSearchResults = [];

        this.Response_State = 0; //0 -> empty , 1 -> correct and not empty ,-1 -> error
        this.isCallSucceed = 0;

        this.confimDialog.tthis = this;

        this.userId = this.data.userId;
        

        Utils.httpGetUserCache(
            this.http
            , this.baseUrl
            , this.data.userId
            , this
            , function (tthis: CacheInfoDialogue, result: any[]) {
                result.forEach(x => {
                    x.BranchesDataSource = new MatTableDataSource<string>();
                    x.BranchesDataSource.data = x.claims.branches ?? []
                    x.UserRolesDataSource = new MatTableDataSource<string>();
                    x.UserRolesDataSource.data = x.claims.roles?.map(x => ({
                        "Role": x
                    })) ?? []
                })
                tthis.cacheInfoList = result
            }
        )
        this.updateRolesResults();
    }
    async updateRolesResults() {
        //this.filteredSearchResults = this.searchByValue(this.UserUnAssignedGroups, "persona", this.searchString);
        this.filteredSearchResults = this.filterAndShowResults(this.filteredSearchResults, "persona", this.searchString);
    }

    searchByValue(items: any, filterProperty: string, query: string) {

        return items.filter(item => {
            if (query.trim() === '') {
                return true;
            } else {
                return item[filterProperty].toLowerCase().includes(query.trim().toLocaleLowerCase())
            }
        })
    }
    filterAndShowResults(list: any, filterProperty: string, searchString: string) {
        // If the search string is empty, show all items
        if (searchString === "") {
            return list.map(item => ({ ...item, hidden: false }));
        }

        // Filter and update hidden property based on search
        const filteredList = list.map(item => ({
            ...item,
            hidden: !item[filterProperty].toLowerCase().includes(searchString.toLowerCase())
        }));

        return filteredList;
    }


    funcNotify() {
        if (this.isCallSucceed === 1) {
            this.toastrService.success(this.data.title + ' request sent succefully !', 'Add ' + this.data.title + ' Request!');
        }
        else if (this.isCallSucceed === -1) {
            this.toastrService.error(this.data.title + ' request not sent succefully !', 'Add ' + this.data.title + ' Request!');
        }
    }

    backButtonHandler() {
        this.dialogRef.close();
    }

    filterBranches() {
        if (this.timeoutFunction) {
            clearTimeout(this.timeoutFunction);
            this.timeoutFunction = null;
        }
        this.timeoutFunction = setTimeout(() => {
            this.cacheInfoList.forEach(x => {
                x.BranchesDataSource.filter = this.searchStringBranches;
            })
        }, 300)
    }

    filterRoles() {
        if (this.timeoutFunction) {
            clearTimeout(this.timeoutFunction);
            this.timeoutFunction = null;
        }
        this.timeoutFunction = setTimeout(() => {
            this.cacheInfoList.forEach(x => {
                x.UserRolesDataSource.filter = this.searchStringRoles;
            })
        }, 300)
    }
}
