<table-material *ngIf="list"
                id-table="error-code-id-notmapped"
                [header]="header"
                [data]="list"
                [getValue]="getData"
                [selectable]="false"
                [additionalRowActionsTemplate]="setValueAction"
                (onRefresh)="refresh()">
</table-material>


<ng-template #setValueAction let-value="value" let-index="index">

    <button mat-icon-button (click)="onCopyErrorCode({index:index, value:value})" matTooltip="Copy this ErrorCode" *ngIf="!value.Value">
        <mat-icon>content_copy</mat-icon>
    </button>

</ng-template>
