export enum CountryBackendEntity {
    Elevator = 0,
    Escalator = 1,
    OtherAsset = 2,
    Chairlift = 3,
    Contract = 4,
    Customer = 5,
    ServiceTicket = 6,
    ContractFacilityManager = 7,
    CustomerFacilityManager = 8,
    Kpi = 9,
    FeatureGroups = 10
}