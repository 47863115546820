<div class="HS DialerTable zoomIn">

    <br />

    <div class="zoomIn heading-wrapper ">
        <div class="contentWidth heading-background " style=" width: 92%;">

            <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                <h3>Tenant Dialer Configuration</h3>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 search-container">
                <!--<input (keyup)="applyFilter($event.target.value)"
                       class="form-control"
                       #input />-->
                <!--<div *ngIf="defaults.isControllerOwner" class="OrangeButton" style="margin-right: 50px;">
                    <button mat-raised-button (click)="AddNewRow()" [disabled]=" !(assignableFeatures.length > 0) " [matTooltip]="getTooltipText()" aria-label="Reset" class="  btn btn-sm button-color-fix ">
                        <mat-icon class="add-button icon-color-fix">add_box</mat-icon>
                    </button>
                </div>-->
            </div>
        </div>
    </div>

    <div class="zoomIn weightageTable_wrapper ">
        <div class="contentWidth">
            <div style="height: calc(90vh - 100px); overflow-y: auto;">
                <form [formGroup]="VOForm" autocomplete="off" class="box weightageTable ">
                    <ng-container formArrayName="VORows">

                        <table #table
                               mat-table
                               [dataSource]="dataSource"
                               class="mat-elevation-z8 mat-table-custom">


                            <!-- Parameter Column -->
                            <ng-container matColumnDef="Parameter">
                                <th mat-header-cell *matHeaderCellDef>Parameter</th>
                                <td mat-cell
                                    *matCellDef="let element; let i = index"
                                    [formGroup]="element">
                                    <div>
                                        <p>{{VOForm?.get('VORows')?.value[i].Parameter}}</p>


                                    </div>
                                </td>
                            </ng-container>



                            <!-- DefaultValues Column -->
                            <ng-container matColumnDef="DefaultValues">
                                <th mat-header-cell *matHeaderCellDef>DefaultValues</th>
                                <td mat-cell
                                    *matCellDef="let element; let i = index"
                                    [formGroup]="element">
                                    <mat-form-field>
                                        <input matInput
                                               [matTooltip]="VOForm?.get('VORows')?.value[i].DefaultValue"
                                               [matTooltipPosition]="'above'"
                                               matTooltipClass="max-width-tooltip"
                                               formControlName="DefaultValue"
                                               [readonly]="true" />

                                    </mat-form-field>
                                    <!-- </span> -->
                                </td>
                            </ng-container>

                            <!-- Condition Column -->
                            <ng-container matColumnDef="Condition">
                                <th mat-header-cell *matHeaderCellDef>Condition</th>
                                <td mat-cell
                                    *matCellDef="let element; let i = index"
                                    [formGroup]="element">
                                    <div *ngIf=" responseDialerPropTable[i].optionsDisplayType == 'int'  ">Valid range: {{responseDialerPropTable[i].min}} to {{responseDialerPropTable[i].max}} </div>
                                    <div *ngIf=" responseDialerPropTable[i].optionsDisplayType == 'string'  ">Valid length: {{responseDialerPropTable[i].min}} to {{responseDialerPropTable[i].max}} </div>
                                    <div *ngIf=" !(['string','int'].includes( responseDialerPropTable[i].optionsDisplayType))  ">Not Applicable</div>
                                    <!-- </span> -->
                                </td>
                            </ng-container>

                            <!-- Value Column -->
                            <ng-container matColumnDef="Value">
                                <th mat-header-cell *matHeaderCellDef>Value</th>
                                <td mat-cell
                                    *matCellDef="let element; let i = index"
                                    [formGroup]="element">
                                    <mat-form-field *ngIf="['string','int'].includes( responseDialerPropTable[i].optionsDisplayType)  ">
                                        <input matInput
                                               [matTooltip]="VOForm?.get('VORows')?.value[i].Value"
                                               [matTooltipPosition]="'above'"
                                               matTooltipClass="max-width-tooltip"
                                               formControlName="Value"
                                               [readonly]="VOForm?.get('VORows')?.value[i].isEditable" />
                                        <mat-icon matTooltipClass="error-tooltip" [matTooltip]="invalidFormControlError(i)" style="color:red!important" *ngIf="isFormControlValueInvalid(i) ">error</mat-icon>
                                    </mat-form-field>

                                    <ng-select *ngIf="responseDialerPropTable[i].optionsDisplayType == 'Enum' "
                                               [items]="responseDialerPropTable[i].optionsDisplayValues"
                                               formControlName="Value"
                                               [readonly]="VOForm.get('VORows')?.value[i]?.isEditable"
                                               [clearable]="false"
                                               [clearOnBackspace]="false">
                                    </ng-select>

                                    <!--<input formControlName="Value"  *ngIf="responseDialerPropTable[i].optionsDisplayType == 'bool' && VOForm?.get('VORows')?.value[i].isEditable " [readonly]="VOForm?.get('VORows')?.value[i].isEditable" />-->
                                    <div *ngIf="responseDialerPropTable[i].optionsDisplayType == 'bool' && VOForm.get('VORows')?.value[i]?.isEditable">{{VOForm?.get('VORows')?.value[i].Value}}</div>
                                    <mat-checkbox *ngIf="responseDialerPropTable[i].optionsDisplayType == 'bool' && !VOForm.get('VORows')?.value[i]?.isEditable" formControlName="Value" [disabled]="VOForm.get('VORows')?.value[i]?.isEditable">
                                        {{VOForm?.get('VORows')?.value[i].Value}}
                                    </mat-checkbox>
                                    <!-- </span> -->
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="Action">
                                <th mat-header-cell *matHeaderCellDef>Action</th>
                                <td mat-cell
                                    *matCellDef="let element; let i = index"
                                    [formGroup]="element">

                                    <button mat-icon-button
                                            *ngIf="!VOForm.get('VORows')?.value[i].isEditable"
                                            matTooltip="Cancel Changes"
                                            color="warn"
                                            (click)="ResetSVO(i)">
                                        <mat-icon class="actionButtons">rotate_left</mat-icon>
                                    </button>

                                    <button mat-icon-button
                                            *ngIf="VOForm.get('VORows')?.value[i].isEditable"
                                            matTooltip="Edit"
                                            color="primary"
                                            (click)="EditSVO(VOForm,i)">
                                        <mat-icon class="actionButtons">edit</mat-icon>
                                    </button>

                                </td>
                            </ng-container>

                            <ng-container matColumnDef="actionFooter">
                                <th mat-footer-cell *matFooterCellDef colspan="5">
                                    <div class="OrangeButton " style="margin-right: 50px;">
                                        <button mat-raised-button type="submit" (click)="onSave()" class="btn button-color-fix pull-right" [disabled]="!VOForm.valid">
                                            Save
                                            <mat-icon style="color:white !important" class="icon-color-fix">save</mat-icon>
                                        </button>
                                    </div>
                                </th>

                            </ng-container>



                            <tr mat-header-row class="tableheader" *matHeaderRowDef=" displayedColumns "></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns " [class.selectedItemsHS]="!row.value.isEditable"></tr>
                            <tr mat-footer-row *matFooterRowDef="['actionFooter']; sticky: true"></tr>


                            <!-- Row shown when there is no matching data. -->
                            <tr class="zoomIn mat-row" *matNoDataRow>
                                <td *ngIf='Response_State===0 && regCode != undefined' class="mat-cell" colspan="4">
                                    No Data to Display for this Dialer and Regulatory Code
                                </td>
                                <td *ngIf='Response_State===-1 && regCode != undefined' class="mat-cell" colspan="4">
                                    An Error Occured Please Contact the developers
                                </td>

                                <td *ngIf='regCode == undefined' class="mat-cell" colspan="4">
                                    No Tenant Dialer or Regulatory Code Selected
                                </td>


                                <td *ngIf='Response_State===-2 && regCode != undefined' class="mat-cell" colspan="1">
                                    <div class="whitebg card__desc">
                                        <div *ngFor="let _ of [].constructor(4)" class="card__desc-text"></div>
                                    </div>
                                </td>
                                <td *ngIf='Response_State===-2 && regCode != undefined' class="mat-cell" colspan="1">
                                    <div class="whitebg card__desc">
                                        <div *ngFor="let _ of [].constructor(4)" class="card__desc-text"></div>
                                    </div>
                                </td>
                                <td *ngIf='Response_State===-2 && regCode != undefined' class="mat-cell" colspan="1">
                                    <div class=" whitebg card__desc">
                                        <div *ngFor="let _ of [].constructor(4)" class="card__desc-text"></div>
                                    </div>
                                </td>
                                <td *ngIf='Response_State===-2 && regCode != undefined' class="mat-cell" colspan="1">
                                    <div class="whitebg card__desc">
                                        <div *ngFor="let _ of [].constructor(4)" class="card__desc-text"></div>
                                    </div>
                                </td>
                                <td *ngIf='Response_State===-2 && regCode != undefined' class="mat-cell" colspan="1">
                                    <div class="whitebg card__desc">
                                        <div *ngFor="let _ of [].constructor(4)" class="card__desc-text"></div>
                                    </div>
                                </td>

                            </tr>
                        </table>
                    </ng-container>

                </form>
            </div>
        </div>

    </div>
</div>


