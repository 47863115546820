<!--<div class="container-tab-lists">

    <div class="inner-tcontainer-tab">-->

<iframe title="DevOpsGlobalMonitoring"
        style="height: 90vh; display: block; width: -webkit-fill-available"
        src="https://app.powerbi.com/reportEmbed?reportId=6b108ddd-d9fa-4afb-b34b-5a6ca12373fd&autoAuth=true&ctid=739976a8-173b-4e4f-ad43-173a2664452a"
        frameborder="0"
        allowFullScreen="true">
</iframe>
<!--</div>

</div>-->
