<table-fixed-header-search-collapse 
    *ngIf="action.Components && !defaults.isReadonlyUser"
    id-table="maintenance-control-plan-group-action-component-id"
    [header]="header"
    [data]="action.Components"
    [getValue]="getData"
    [selectable]="false"

    [rowNewTemplate]="newComponent"
    [rowExpandTemplate]="expandtemplate"
    
    [newService]="newService"
    [expanded]="expanded"

    (onExpand)="onExpand($event)"
    (onNewChild)="onNewChild($event)"
    (onDelete)="onDelete($event)"
>
</table-fixed-header-search-collapse>

<table-fixed-header-search-collapse *ngIf="action.Components && defaults.isReadonlyUser"
                                    id-table="maintenance-control-plan-group-action-component-id"
                                    [header]="header"
                                    [data]="action.Components"
                                    [getValue]="getData"
                                    [selectable]="false"
                                    [rowExpandTemplate]="expandtemplate"
                                    [expanded]="expanded"
                                    (onExpand)="onExpand($event)">
</table-fixed-header-search-collapse>

<ng-template #expandtemplate let-index="index">
    <maintenance-control-plan-group-action-component-error-list *ngIf="expanded[index]==true" [newService]="newChildService" [component]="action.Components[index]"></maintenance-control-plan-group-action-component-error-list>
</ng-template>

<ng-template #newComponent let-index="index">
    <maintenance-control-plan-group-action-component-new [header]="header" [list]="action.Components" [action]="action" [rowIndex]="index"></maintenance-control-plan-group-action-component-new>
</ng-template>