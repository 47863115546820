<div class="example-container-deploymentstatus">
    <button mat-icon-button (click) ="refresh()" matTooltip="Refresh the current table">
        <mat-icon>refresh</mat-icon>
    </button>
    <table mat-table
           matSort
           [dataSource]="dataSource"
           class="mat-elevation-z8 mat-table-custom"
           id="controllertype-deploymentstatus">

        <ng-container matColumnDef="environment">
            <th mat-header-cell *matHeaderCellDef> Environment </th>
            <td mat-cell *matCellDef="let element" pEditableColumn>
                <p [innerHTML]="element.Environment.toUpperCase()"></p>
            </td>
        </ng-container>
        <ng-container matColumnDef="opmodes">
            <th mat-header-cell *matHeaderCellDef> Operation Modes (Last deployment date - UTC) </th>
            <td mat-cell *matCellDef="let element;index as i;" pEditableColumn>
                <mat-icon *ngIf="element.OpModes">check_circle</mat-icon>
                <mat-icon *ngIf="!element.OpModes">error</mat-icon>
                <p *ngIf="element.OpModesDate">({{element.OpModesDate | date:"yyyy-MM-dd HH:mm"}} - {{sinceDays(element.OpModesDate)}} day/s ago)</p>
            </td>
        </ng-container>
        <ng-container matColumnDef="errorcodes">
            <th mat-header-cell *matHeaderCellDef> Error Codes (Last deployment date - UTC) </th>
            <td mat-cell *matCellDef="let element" pEditableColumn>
                <mat-icon *ngIf="element.ErrorCodes && element.ErrorCodesresol">check_circle</mat-icon>
                <mat-icon *ngIf="!element.ErrorCodes || !element.ErrorCodesresol">error</mat-icon> 
                <p *ngIf="element.ErrorCodesDate">
                    ({{element.ErrorCodesDate | date:"yyyy-MM-dd HH:mm"}} - {{sinceDays(element.ErrorCodesDate)}} day/s ago)
                </p>
            </td>
        </ng-container>

        <ng-container matColumnDef="infoupdateddate">
            <th mat-header-cell *matHeaderCellDef> Last daily comparison (UTC) </th>
            <td mat-cell *matCellDef="let element" pEditableColumn>
                <p>{{element.UpdatedDate | date:"yyyy-MM-dd HH:mm"}} - {{sinceDays(element.UpdatedDate)}} day/s ago</p>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; index as i;
            columns: columnsToDisplay;"
            class="opmode-item">
        </tr>
    </table>
</div>