import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { SaveService } from "../../../services/save.service";
import { DefaultValuesService } from "../../../services/defaultvalues.service";
import { CustomDialogService } from "../../../services/customdialog.service";
import { Utils } from "../../../utils/utils";
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatDialog } from "@angular/material/dialog";
import { FunctionsConfigPopupComponent } from "./functionsconfigpopup/functionsconfigpopup.component";
import { AddFunctionKeyPopupComponent } from "./add-functionkeypopup/add-functionkeypopup.component";

@Component({
    selector: "functionsconfig-list",
    templateUrl: './functionsconfig-list.component.html',
    styleUrls: ['./functionsconfig-list.component.less'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*', })),
            transition('expanded => collapsed', [animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
        ]),
    ]
})

export class FunctionsConfigListComponent implements OnInit {
    @Input('list') list: any[] = [];
    @Output() onReload = new EventEmitter<any>();
    @Output() onGlobalReload = new EventEmitter<any>();

    columnsToDisplay = ['arrow', 'index', 'FunctionKey', 'Enabled', 'actions'];
    subcolumnsToDisplay = ['DisplayName', 'Argument', 'actions'];

    notEditableColumns = ['FunctionKey', 'FunctionConfigParameterId']

    tooltips = {
        'FunctionKey': 'Harmonized key in the MAX project for the controller function.',
        'DisplayName': 'Label to display the specific argument for a given function.',
        'Argument': 'Payload required in the controller for a given controller function.',
        'Unit': 'Measure unit of the parameter value.'
    }


    @ViewChild(MatTable, { static: true }) table!: MatTable<any>;

    expandedElements = new Set();

    public dataSource = new MatTableDataSource<FunctionConfig>();

    subscriptionTenant: any;

    constructor(private activatedRoute: ActivatedRoute
        , private router: Router
        , private http: HttpClient
        , private saveService: SaveService
        , public defaults: DefaultValuesService
        , private customDialog: CustomDialogService
        , @Inject('BASE_URL') private baseUrl: string
        , private dialogForm: MatDialog
        , private changeDetectorRefs: ChangeDetectorRef

    ) {
    }

    ngOnChanges() {
        this.dataSource.data = [];
        this.dataSource.data = this.list
        this.expandedElements = new Set()
    }

    ngOnInit() {
        this.expandedElements = new Set()
        this.getHeader();
        this.subscriptionTenant = this.defaults.tenantMessageChanges$.subscribe(() => {
            this.getHeader()
        });
        this.saveService.addListener(this);
    }

    ngOnDestroy() {
        this.subscriptionTenant.unsubscribe();
    }

    getHeader() {
        if (!this.defaults.isReadonlyUser) {
            this.columnsToDisplay = ['arrow', 'index', 'FunctionKey', 'Enabled'];
            this.subcolumnsToDisplay = ['DisplayName', 'Argument', 'actions'];
        }
        else {
            this.columnsToDisplay = ['arrow', 'index', 'FunctionKey', 'Enabled'];
            this.subcolumnsToDisplay = ['DisplayName', 'Argument'];

        }
    }

    getInput(data: FunctionConfig, property: string): any {
        return data[property];
    }

    checkExpanded(element: FunctionConfig) {
        return this.expandedElements.has(element.FunctionKey);
    }

    pushPopElement(element: FunctionConfig) {
        if (this.expandedElements.has(element.FunctionKey)) {
            this.expandedElements.delete(element.FunctionKey);
        }
        else {
            this.expandedElements.add(element.FunctionKey);
            this.onExpand(element.FunctionKey)
        }
    }

    onExpand(functionKey: string) {
        const foundIndex = this.dataSource.data.findIndex((x: FunctionConfig) => x.FunctionKey === functionKey);
        Utils.httpGetFunctionConfigParameters(
            this.http
            , this.baseUrl
            , functionKey
            , this
            , function (tthis: any, list: any) {
                tthis.dataSource.data[foundIndex].Parameters = list;
            }
        );
    }

    onRowEditInit(parameter: FunctionConfigParameter | undefined, element: FunctionConfig) {
        if (!this.expandedElements.has(element.FunctionKey))
            this.expandedElements.add(element.FunctionKey);
        this.onExpand(element.FunctionKey)
        const dialog = this.dialogForm.open(FunctionsConfigPopupComponent, {
            panelClass: 'custom-mat-dialog',
            disableClose: true,
            data: {
                'functionKey': element.FunctionKey,
                'functionParameterId': parameter ? parameter.FunctionConfigControllerParameterId : null,
                'argument': parameter ? parameter.Argument : '',
                'displayName': parameter ? parameter.DisplayName: ''
            }
        });
        dialog.afterClosed().subscribe(() => {
            Utils.httpGetFunctionConfigParameters(
                this.http
                , this.baseUrl
                , element.FunctionKey
                , this
                , function (tthis: any, list: any) {
                    const foundIndex = tthis.dataSource.data.findIndex((x: FunctionConfig) => x.FunctionKey === element.FunctionKey);
                    tthis.dataSource.data[foundIndex].Parameters = list;
                    if (!tthis.expandedElements.has(element.FunctionKey))
                        tthis.expandedElements.add(element.FunctionKey);
                    tthis.onExpand(element.FunctionKey)
                }
            );
        });
    }

    openCreatePopup() {
        const dialog = this.dialogForm.open(AddFunctionKeyPopupComponent, {
            panelClass: 'custom-mat-dialog',
            disableClose: true,
            data: {}
        });
        dialog.afterClosed().subscribe(() => {
            this.onGlobalReload.emit({});
        });
    }

    changeElement(object: FunctionConfig, value: boolean) {
        this.http
            .post<any>(
                this.baseUrl + Utils.getRemoteActionAPI() + 'enablefunctionconfig' + '/' + value, object
            ).subscribe((res: number) => {
                this.onReload.emit(res);
                this.expandedElements = new Set()
                if (value === true && res < 1) {    // Open popup to create new param when function has not anyone
                    this.onRowEditInit(undefined, object);
                }

            }, (error: {
                error: { message: any }
            }) => {
                this.customDialog.openDisplayInfoDialog({ title: 'Error', message: error.error.message });
            });
    }
}