import { HttpClient } from "@angular/common/http";
import { Component, Inject, Input, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { CountryBackendEntity } from "../../../interfaces/countrybackendentity";
import { DefaultValuesService } from "../../../services/defaultvalues.service";
import { Utils } from "../../../utils/utils";
//import { ContractLineListComponent } from "../../commondataplatform/backendharmonization/contractline-list.component";
//import { ContractTypeListComponent } from "../../commondataplatform/backendharmonization/contracttype-list.component";
//import { ServiceTicketCategoryListComponent } from "../../commondataplatform/backendharmonization/serviceticketcategory-list.component";

@Component({
    selector: 'cdpwiki-list',
    templateUrl: './cdpwiki-list.component.html',
    styleUrls: ['./cdpwiki-list.component.less']
})

export class CdpWikiListComponent implements OnInit {

    @Input('entity') entity = 0;
    columnsToDisplay = ['index-table-wiki', 'fieldname', 'ismandatory', 'lov', 'desc', 'type', 'example', 'updatedat'];

    entityName = ''
    soapUrl = ''
    restUrl = ''
    public dataSource = new MatTableDataSource<CdpWikiField>();


    timeoutFunction: any;

    constructor(private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , @Inject(MAT_DIALOG_DATA) public data: any
        , private defaults: DefaultValuesService
        , public dialogRef: MatDialogRef<CdpWikiListComponent>
        , private router: Router
        , private dialogForm: MatDialog
    ) {
    }


    ngOnInit(): void {
        this.entity = this.data.entity
        this.entityName = CountryBackendEntity[this.entity];
        // Just for contracts and service tickets

        Utils.httpGetWikiByEntity(
            this.http
            , this.baseUrl
            , this.defaults.stage
            , CountryBackendEntity[this.entity]
            , this
            , function (tthis: any, result: CdpWiki) {
                tthis.soapUrl = result.SOAPUrl
                tthis.restUrl = result.RESTUrl
                tthis.dataSource.data = result.Fields
            }
        );
    }

    onBack() {

    }
    onNoClick(): void {
        this.dialogRef.close();
    }

    applyFilter($event: any) {
        if (this.timeoutFunction) {
            clearTimeout(this.timeoutFunction);
            this.timeoutFunction = null;
        }
        this.timeoutFunction = setTimeout(() => {
            this.dataSource.filter = $event.target.value.trim()
        }, 300)
    }

    sortData(sort: Sort) {

        const data = this.dataSource.data.slice();
        if (!sort.active || sort.direction === '') {
            this.dataSource.data = data;
            return;
        }

        this.dataSource.data = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'updatedat':
                    return this.compare(a.UpdatedAt, b.UpdatedAt, isAsc);
                default:
                    return 0;
            }
        });
    }
    compare(a: Date, b: Date, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    downloadWsdl($event: any) {
        $event.preventDefault();
        Utils.httpGetWikiByEntity(
            this.http
            , this.baseUrl
            , this.defaults.stage
            , CountryBackendEntity[this.entity]
            , this
            , function (tthis: any, result: CdpWiki) {
                tthis.soapUrl = result.SOAPUrl
                tthis.restUrl = result.RESTUrl
                tthis.dataSource.data = result.Fields
            }
        );
    }

    openRestLink() {
        window.open(this.restUrl, "_blank");
    }

    downloadWsdlFile() {
        Utils.httpDownloadWsdlByEntity(
            this.http
            , this.baseUrl
            , this.defaults.stage
            , CountryBackendEntity[this.entity]
            , this
            , null)
    }

    downloadDocFile() {
        Utils.httpDownloadDocByEntity(
            this.http
            , this.baseUrl
            , this.defaults.stage
            , CountryBackendEntity[this.entity]
            , this
            , null)
    }

    openHarmonizationComponent(component: string) {
        switch (component) {
            //case 'ContractType':
            //    this.dialogForm.open(ContractTypeListComponent, {
            //        panelClass: 'custom-mat-dialog',
            //        disableClose: true,
            //        data: {}
            //    });
            //    break;
            //case 'ContractLine':
            //    this.dialogForm.open(ContractLineListComponent, {
            //        panelClass: 'custom-mat-dialog',
            //        disableClose: true,
            //        data: {}
            //    });
            //    break;
            //case 'ServiceTicket':
            //    this.dialogForm.open(ServiceTicketCategoryListComponent, {
            //        panelClass: 'custom-mat-dialog',
            //        disableClose: true,
            //        data: {}
            //    });
            //    break;
            default: break;
        }
    }
}


