import { Component, Inject, Input, EventEmitter, Output, OnInit } from "@angular/core";
import { DialogService } from "../../../services/dialog.service";
import { SaveService } from "../../../services/save.service";
import { MatDialog } from "@angular/material/dialog";
//import { ControllerConfigPredefinedItemsDescriptionFormComponent } from "./controllerconfigpredefineditemsdescription-form.component";

@Component({
    selector: "controllerconfig-predefineditemsdescriptions-list",
    templateUrl: './controllerconfigpredefineditemsdescription-list.component.html',
    styleUrls: ['../../../style/list.component.less', 'controllertypes-list.component.less']
})

export class ControllerConfigPredefinedItemsDescriptionListComponent implements OnInit {
    @Input() rowIndex: number | undefined;
    @Input() selected: ControllerConfig = {} as ControllerConfig;
    @Output() onRefresh = new EventEmitter<{}>();

    header: any [] = [
        { label: 'BK', show: true }
        , { label: 'PredefinedItemBK', show: true, title: "BK of the ControllerConfig Predefined Items Descriptions " }
        , { label: 'Description', show: true, title: "Description for this ControllerConfig Predefined Items Descriptions" }
    ];

    constructor(
        private dialogService: DialogService
        , private saveService: SaveService
        , @Inject('BASE_URL') private baseUrl: string
        , private dialog: MatDialog
    ) {
    }

    ngOnInit() {
        this.dialogService.tthis = this;
    }

    /**
     * called by the SaveService
     * */
    onCancel() {
        this.dialog.closeAll();
    }

    onNewForm() {
        /**
         * set the tthis on dialgService to the child forms call the getHeader
         **/
        this.dialogService.tthis = this;
        this.saveService.addListener(this);
        //const dialogRef =
        //const dialogRef = this.dialog.open(ControllerConfigPredefinedItemsDescriptionFormComponent, { panelClass: 'screen-dialog' });
        //dialogRef.afterClosed().subscribe(result => {
        //    this.refresh();
        //    console.log(result);
        //});
    }

    /**
     * called by the SaveService
     * */
    onSave() {
        this.dialog.closeAll();
        this.refresh();
    }

    /**
     * this is from who calls (inner component)
     * @param list
     * @param row
     * @param column
     */
    getData(list: ControllerConfigPredefinedItemsDescription[], row: number, column: number): string {
        if (list) {
            const e = list[row];
            switch (column) {
                case 0:
                    return e.BK;
                case 1:
                    return e.PredefinedItemBK;
                default:
                    return e.Description;
            }
        }
        else
            return "";
    }

    public refresh() {
        this.onRefresh.emit({ expanded: true, index: this.rowIndex, value: this.selected });
    }

}