import { Component, Inject, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { SaveService } from '../../../services/save.service';
import { DefaultValuesService } from '../../../services/defaultvalues.service';

@Component({
    selector: 'harmonizedopmode-menu',
    templateUrl: './harmonizedopmode-menu.component.html',
    styleUrls: ['../../../style/menu.component.less']
})

export class HarmonizedOperationModeMenuComponent {
    @Output() onRefresh = new EventEmitter<{}>();

    languageList: Language[] | undefined = [];

    constructor(private activatedRoute: ActivatedRoute,
        private router: Router
        , private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , public saveService: SaveService
        , public defaults: DefaultValuesService
    ) {
    }

    onSave() {
        this.saveService.fireSaveAll();
    }
}
