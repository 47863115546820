import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { SaveService } from "../../../services/save.service";
import { DefaultValuesService } from "../../../services/defaultvalues.service";
import { CustomDialogService } from "../../../services/customdialog.service";
import { Utils } from "../../../utils/utils";

@Component({
    selector: "allowedactions-list",
    templateUrl: './allowedactions-list.component.html',
    styleUrls: ['./allowedactions-list.component.less'],
})

export class AllowedActionListComponent implements OnInit {
    @Input('list') list: any[] = [];
    @Input('isEditableMethod') isEditable: any
    @Output() onReload = new EventEmitter<any>();


    columnsToDisplay = ['CommandId', 'CommandName', 'Category', 'Description','FirstActivationUtc', 'LastChangeUtc', 'actions'];
    dateColumns = ['FirstActivationUtc', 'LastChangeUtc']

    @ViewChild(MatTable, { static: true }) table!: MatTable<any>;

    public dataSource = new MatTableDataSource<any>();

    subscriptionTenant: any;

    timeoutFunction: any;

    constructor(private activatedRoute: ActivatedRoute
        , private router: Router
        , private http: HttpClient
        , private saveService: SaveService
        , public defaults: DefaultValuesService
        , private customDialog: CustomDialogService
        , @Inject('BASE_URL') private baseUrl: string
        ,
        private changeDetectorRefs: ChangeDetectorRef

    ) {
    }

    ngOnChanges() {
        this.dataSource.data = [];
        this.dataSource.data = this.list
    }

    ngOnInit() {
        this.getHeader();
        this.subscriptionTenant = this.defaults.tenantMessageChanges$.subscribe(() => {
            this.getHeader()
        });
        this.saveService.addListener(this);
    }

    ngOnDestroy() {
        this.subscriptionTenant.unsubscribe();
    }

    getHeader() {
        if (!this.defaults.isReadonlyUser)
            this.columnsToDisplay = ['CommandId', 'CommandName', 'Category', 'Description', 'FirstActivationUtc', 'LastChangeUtc', 'actions'];
        else
            this.columnsToDisplay = ['CommandId', 'CommandName', 'Category', 'Description', 'FirstActivationUtc', 'LastChangeUtc'];
    }

    getInput(data: AllowedRemoteAction, property: string): any {
        return data[property];
    }

    getDataType(property: string) {
        let result = ''
        if (this.dateColumns.indexOf(property) > -1) result = 'date'
        else result = 'string'
        return result
    }


    applyFilter($event: any) {
        if (this.timeoutFunction) {
            clearTimeout(this.timeoutFunction);
            this.timeoutFunction = null;
        }
        this.timeoutFunction = setTimeout(() => {
            this.dataSource.filter = $event.target.value.trim()
        }, 300)
    }

    changeElement(object: any, type: string, value: boolean) {
        this.http
            .post<any>(
                this.baseUrl + Utils.getRemoteActionAPI() + 'allowedaction' + '/' + object.CommandId + '/' + value, {}
            ).subscribe((res) => {
                this.onReload.emit(res);
            }, (error: {
                error: { message: any }
            }) => {
                this.customDialog.openDisplayInfoDialog({ title: 'Error', message: error.error.message });
            });
    }
}