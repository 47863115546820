<ng-container class="mersy-mgmt-id">
    Branch Hierarchy:
    <br />

    <div class="form-control-original">
        <div style="display: flex; justify-content: center">
            <div class="dropdown-icon">
                <input style="width: 45vw; color: white; background: black; cursor: pointer;"
                       type="text"
                       aria-label="selectedManagementId"
                       placeholder="{{placeHolder}}"
                       [(ngModel)]="searchString"
                       (keyup)="treeControlFunc()"
                       matInput
                       [matAutocomplete]="mgmtAutocomplete"
                       matTooltip="{{selectedManagementId}}"
                       [ngClass]="placeHolder==='Search'? 'gray-placeholder':'white-placeholder'"
                       class="white-placeholder form-control custom-overflow-text">

            </div>

            <mat-autocomplete autoActiveFirstOption #mgmtAutocomplete="matAutocomplete" (closed)="onAutocompleteClosed()" (opened)="onAutocompleteOpened()">
                <div class="customDropDownTree ">

                    <div class="custom-toggle-button-group">
                        <mat-option style="display:none"></mat-option>
                        <div class="custom-toggle-button-panel">
                            <groupfunction (groupFunctionChanged)="selectFirstMgmtId($event)"></groupfunction>
                        </div>


                        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree mersymgmtTree">
                            <!--This is the tree node template for leaf nodes-->
                            <mat-tree-node *matTreeNodeDef="let node"
                                           matTreeNodeToggle
                                           [style.display]="showOnlySelected && !node.Selected || hideLeafNode(node) || (!(groupfunction === 'All') &&  hideLeafNodeselector(groupfunction,node )) ? 'none' : 'block'">

                                <li class="mat-tree-node">
                                    <mat-checkbox class="checklist-leaf-node"
                                                  (change)="itemToggle($event.checked,node);"
                                                  [disabled]="node.Disabled 
                                                            || (groupfunction =='All'? node.Selected: node.Selected && groupfunction == defaults.groupFunction)"
                                                  [checked]=" (groupfunction =='All'? node.Selected: node.Selected && groupfunction == defaults.groupFunction) ">
                                        {{(node.GroupName.includes('/') && groupfunction == 'All') ? node.GroupName.slice(0, node.GroupName.lastIndexOf('-') + 2) : node.GroupName}}
                                        <span *ngIf="node.GroupName.includes('/')  && groupfunction == 'All' ">
                                            <span *ngFor="let funcName of node.GroupFunction?.split('/'); let i = index">
                                                <span class="grpFuncSeprate" *ngIf="i > 0"> / </span>
                                                <a class="grpFuncCommon" [ngClass]="{'grpFuncSelected': (node.Selected ? funcName == defaults.groupFunction : i == 0) ,'grpFuncOther':(node.Selected ? funcName != defaults.groupFunction : i != 0) }" (click)="updateGrpFunfrmDrpDwn(node,funcName)">
                                                    {{ funcName }}
                                                </a>
                                            </span>
                                        </span>

                                    </mat-checkbox>
                                </li>
                            </mat-tree-node>


                            <!--This is the tree node template for expandable nodes-->
                            <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild"
                                                  [style.display]="(showOnlySelected && !(node.Selected || node.Indeterminate)) 
                                                                    || this.searchString && hideParentNode(node) 
                                                                    || (!(groupfunction === 'All') &&  hideParentNodeselector(groupfunction,node )) 
                                                                    || hideParentManagement(node) ? 'none' : 'block'">
                                <li>
                                    <div class="mat-tree-node">
                                        <button mat-icon-button matTreeNodeToggle
                                                [attr.aria-label]="'toggle ' + node.GroupName">
                                            <mat-icon class="mat-icon-rtl-mirror" *ngIf="showExpandIcon(node)">
                                                {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                            </mat-icon>
                                        </button>
                                        <div [ngClass]="{'placeholdercheck': groupfunction =='All'? 
                                                    !node.Indeterminate: ((!node.Indeterminate && groupfunction == defaults.groupFunction)||(groupfunction != defaults.groupFunction))} ">
                                            <mat-checkbox [checked]="(groupfunction =='All'? node.Selected: node.Selected && groupfunction == defaults.groupFunction)"
                                                          [disabled]="!node.GroupFunction
                                                                    || (groupfunction =='All'? node.Selected: node.Selected && groupfunction == defaults.groupFunction)
                                                                    || mode === modeEnum.BranchExcellence"
                                                          [indeterminate]="true && !node.Selected && showExpandIcon(node)"
                                                          (change)="itemToggle($event.checked,node)">
                                                {{(node.GroupName.includes('/') && groupfunction == 'All') ? node.GroupName.slice(0, node.GroupName.lastIndexOf('-') + 2) : node.GroupName}}
                                                <span *ngIf="node.GroupName.includes('/') && groupfunction == 'All'">
                                                    <span *ngFor="let funcName of node.GroupFunction?.split('/'); let i = index">
                                                        <span class="grpFuncSeprate" *ngIf="i > 0"> / </span>
                                                        <a class="grpFuncCommon" [ngClass]="{'grpFuncSelected': (node.Selected ? funcName == defaults.groupFunction : i == 0) ,'grpFuncOther':(node.Selected ? funcName != defaults.groupFunction : i != 0) }" (click)="updateGrpFunfrmDrpDwn(node,funcName)">
                                                            {{ funcName }}
                                                        </a>
                                                    </span>
                                                </span>
                                            </mat-checkbox>
                                        </div>

                                    </div>
                                    <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
                                        <ng-container matTreeNodeOutlet></ng-container>
                                    </ul>
                                </li>
                            </mat-nested-tree-node>
                        </mat-tree>
                    </div>
                </div>
            </mat-autocomplete>
        </div>
    </div>
</ng-container>

