<div class="container-tab-lists">

    <mat-tab-group>
        <!--<mat-tab>
            <ng-template mat-tab-label>
                Configurations
            </ng-template>
            <div *ngIf="result">
                {{result}}
            </div>
        </mat-tab>-->
        <mat-tab>
            <ng-template mat-tab-label>
                Exceptions
            </ng-template>

            <div>

            </div>
        </mat-tab>
    </mat-tab-group>

</div> <!--container-tab-lists-->