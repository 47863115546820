import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { EmtconfigurationListComponent } from 'src/app/components/emtconfiguration/emtconfiguration-list/emtconfiguration-list.component';
import { LevelHierarchyComponent } from 'src/app/components/emtconfiguration/levelhierarchy/levelhierarchy.component';
import { DefaultValuesService } from 'src/app/services/defaultvalues.service';
import { EquipmentManagenementService } from 'src/app/services/equipmentmanagement.service';

@Component({
  selector: 'emtconfiguration',
  templateUrl: './emtconfiguration.component.html',
    styleUrls: ['./emtconfiguration.component.less', '../../style/new-generic-styles.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class EmtConfigurationComponent implements OnInit {
  title = "Equipment Management Configuration";
  selectedLevel = {} as EquipmentConfigurationLevel
  userLevels: EquipmentConfigurationLevel[] = [];

  @ViewChild(EmtconfigurationListComponent) list! : EmtconfigurationListComponent;
  constructor(
    private emtService: EquipmentManagenementService,
    public defaults: DefaultValuesService
  ) { }

  ngOnInit() {
    // let newLevel : EquipmentConfigurationLevel = {
    //   tenantId : "ES",
    //   branchNumber: "001",
    //   deviceId: '009'
    // };
    // this.selectedLevel = newLevel;
  }

  onNewLevelSelected(level: EquipmentConfigurationLevel){
    this.selectedLevel = level;
    this.list.changeSelectedLevel(level);
  }

}
