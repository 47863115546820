<div class="custom-mat-dialog-form">
    <div>
        <h3>Trigger {{this.data.entity}} to {{this.data.type}} Data Factory</h3>
    </div>
    <div>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="mt-2" *ngIf="(item === 'Elevator' || item === 'Escalator' || item === 'OtherAsset') && !data.isCustomerPortal">
                <label>Target System *</label>
                <div class="input">
                    <mat-select [(ngModel)]="body.TargetSystem" class="form-control" formControlName="targetSystem"
                                [ngClass]="{ 'is-invalid': submitted && f['targetSystem'].errors }" readonly>
                        <mat-option *ngFor="let targetSystem of targetSystemList" [value]="targetSystem">
                            {{ targetSystem }}
                        </mat-option>
                    </mat-select>
                </div>
            </div>
            <div class="mt-2" *ngIf="(item === 'Elevator' || item === 'Escalator' || item === 'OtherAsset') && !data.isCustomerPortal">
                <label>Window Start *</label>
                <div class="input">
                    <input [matDatepicker]="pickerStart" [(ngModel)]="body.WindowStart" class="form-control" formControlName="windowStart"
                           required [ngClass]="{ 'is-invalid': submitted && f['windowStart'].errors }" readonly />
                    <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
                    <mat-datepicker #pickerStart></mat-datepicker>
                    <div *ngIf="submitted && f['windowStart'].errors" class="invalid-feedback">
                        <div class="error-div" *ngIf="f['windowStart'].errors['required']">Incorrect Window Start Date. Pick one correct date or write with yyyy-MM-dd format</div>
                    </div>
                </div>
            </div>
            <div class="mt-2" *ngIf="(item === 'Elevator' || item === 'Escalator') && !data.isCustomerPortal">
                <label>Window End *</label>
                <div class="input">
                    <input [matDatepicker]="pickerEnd" [(ngModel)]="body.WindowEnd" class="form-control" formControlName="windowEnd" 
                           required [ngClass]="{ 'is-invalid': submitted && f['windowEnd'].errors }" readonly />
                    <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
                    <mat-datepicker #pickerEnd></mat-datepicker>
                    <div *ngIf="submitted && f['windowEnd'].errors" class="invalid-feedback">
                        <div class="error-div">Incorrect Window End Date. Pick one correct date or write with yyyy-MM-dd format</div>
                    </div>
                </div>
            </div>

            <div class="mt-2" *ngIf="(item === 'Customer' || item === 'CustomerFacilityManager' || item === 'Contract' || item === 'ContractFacilityManager' || item === 'ServiceTicket' || item === 'Kpi') && !data.isCustomerPortal">
                <label>Selection Date From *</label>
                <div class="input">
                    <input [matDatepicker]="pickerDateFrom" [(ngModel)]="body.SelectionDateFrom" class="form-control" formControlName="selectionDateFrom"
                           required [ngClass]="{ 'is-invalid': submitted && f['selectionDateFrom'].errors }" readonly />
                    <mat-datepicker-toggle matSuffix [for]="pickerDateFrom"></mat-datepicker-toggle>
                    <mat-datepicker #pickerDateFrom></mat-datepicker>
                    <div *ngIf="submitted && f['selectionDateFrom'].errors" class="invalid-feedback">
                        <div class="error-div" *ngIf="f['selectionDateFrom'].errors['required']">Incorrect Selection Date From. Pick one correct date with yyyy-MM-dd format</div>
                    </div>
                </div>
            </div>
            <div class="mt-2" *ngIf="item === 'ServiceTicket' && !data.isCustomerPortal">
                <label>Start Date Time</label>
                <div class="input">
                    <input [matDatepicker]="pickerStartDateTime" [(ngModel)]="body.StartDateTime" class="form-control" formControlName="startDateTime"
                           [ngClass]="{ 'is-invalid': submitted && f['startDateTime'].errors }" readonly />
                    <mat-datepicker-toggle matSuffix [for]="pickerStartDateTime"></mat-datepicker-toggle>
                    <mat-datepicker #pickerStartDateTime></mat-datepicker>
                    <div *ngIf="submitted && f['startDateTime'].errors" class="invalid-feedback">
                        <div class="error-div">Incorrect Start Date time. Pick one correct date with yyyy-MM-dd format</div>
                    </div>
                </div>
            </div>
            <div class="mt-2" *ngIf="item === 'ServiceTicket' && !data.isCustomerPortal">
                <label>End Date Time</label>
                <div class="input">
                    <input [matDatepicker]="pickerEndDateTime" [(ngModel)]="body.EndDateTime" class="form-control" formControlName="endDateTime"
                           [ngClass]="{ 'is-invalid': submitted && f['endDateTime'].errors }" readonly />
                    <mat-datepicker-toggle matSuffix [for]="pickerEndDateTime"></mat-datepicker-toggle>
                    <mat-datepicker #pickerEndDateTime></mat-datepicker>
                    <div *ngIf="submitted && f['endDateTime'].errors" class="invalid-feedback">
                        <div class="error-div">Incorrect End Date time. Pick one correct date with yyyy-MM-dd format</div>
                    </div>
                </div>
            </div>
            <div class="mt-2" *ngIf="(item === 'Elevator' || item === 'Escalator') && !data.isCustomerPortal">
                <label>Session Id</label>
                <div class="input">
                    <input type="text" placeholder="Leave empty to generate it randomly"
                           [(ngModel)]="body.SessionId" class="form-control" formControlName="sessionId"
                           [ngClass]="{ 'is-invalid': submitted && f['sessionId'].errors }" />
                    <div *ngIf="submitted && f['sessionId'].errors" class="invalid-feedback">
                        <div class="error-div">SessionId provided with incorrect format</div>
                    </div>
                </div>
            </div>
            <div class="mt-2" *ngIf="data.isCustomerPortal">
                <label>Load Date *</label>
                <div class="input">
                    <input [matDatepicker]="pickerLoadDate" [(ngModel)]="body.loadDate" [min]="minDate" formControlName="loadDate" class="form-control"
                           required [ngClass]="{ 'is-invalid': submitted && f['loadDate'].errors }" readonly />
                    <mat-datepicker-toggle matSuffix [for]="pickerLoadDate"></mat-datepicker-toggle>
                    <mat-datepicker #pickerLoadDate></mat-datepicker>
                    <div *ngIf="submitted && f['loadDate'].errors" class="invalid-feedback">
                        <div class="error-div" *ngIf="f['loadDate'].errors['required']">Incorrect Load Date. Pick one correct date with yyyy-MM-dd format</div>
                    </div>
                </div>
            </div>

            <div class="mt-2" *ngIf="(item === 'Customer' || item === 'CustomerFacilityManager' || item === 'Contract' || item === 'ContractFacilityManager' || item === 'ServiceTicket' || item === 'Kpi') && !data.isCustomerPortal">
                <label>Paging Count</label>
                <div class="input">
                    <input matInput type="number" step="1" min="0" class="form-control input-background" [value]="body.PagingCount" [(ngModel)]="body.PagingCount" formControlName="pagingCount">
                    <div *ngIf="submitted && f['pagingCount'].errors" class="invalid-feedback">
                        <div class="error-div">Incorrect Paging Count. Must be greater than 0.</div>
                    </div>
                </div>
            </div>

            <div class="mt-2" *ngIf="(item === 'Customer' || item === 'CustomerFacilityManager' || item === 'Contract' || item === 'ContractFacilityManager' || item === 'ServiceTicket' || item === 'Kpi') && !data.isCustomerPortal">
                <label>Customer Ids</label>
                <div class="input">
                    <input type="text" placeholder="Provide CustomerIds separated by ';'"
                           [(ngModel)]="body.CustomerIds" class="form-control" formControlName="customerIds" />
                </div>
            </div>

            <div class="mt-2 right">
                <button type="button"
                        class="btn btn-danger"
                        (click)="onNoClick()">
                    Close
                </button>
                <button type="button"
                        class="btn btn-primary"
                        (click)="onSubmit()">
                    Send
                </button>
            </div>
        </form>
    </div>
</div>
