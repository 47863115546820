import { Component, Inject, Input, OnInit, Output, EventEmitter, TemplateRef, OnChanges, OnDestroy, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Utils } from "../../utils/utils";
import { DefaultValuesService } from "../../services/defaultvalues.service";
import { DialogService } from "../../services/dialog.service";
import { SaveService } from "../../services/save.service";
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
// import { MatTableModule } from '@angular/material/table';

import { animate, state, style, transition, trigger } from '@angular/animations';
import { ConfirmationDialogComponent } from "../dialogbox/dialog-confirmation.component";
import { CustomDialogService } from "../../services/customdialog.service";
import { MatTable } from "@angular/material/table";
import { DataTransferService } from "../../services/data-transfer.service";

@Component({
    selector: "errorcode-list-mlsuggestions",
    templateUrl: "./errorcode-list-mlsuggestions.component.html",
    styleUrls: ["./errorcode-list-mlsuggestions.component.less"],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class ErrorCodeListMLSuggestionsComponent implements OnInit, OnDestroy {
    @Input('list') list: ErrorCodeML[] = [];

    // dataSource:ErrorCodeML[] = this.list;
    /*dataSource2 = this.dataSource;*/

    columnsToDisplay = ['arrow','errorcode', 'description', 'errorweight', 'controllerblocked', 'suggestedchanges', 'suggestednew', 'actions'];
    columnsToDisplay2 = ['maintenancefailure', 'troubleshootingaction', 'troubleshootingweight', 'suggestedweight', 'subsystemgroup', 'componentitem', 'cause', 'resolution', 'partsrequired', 'highvaluepart', 'isnew', 'actions'];

    /*columnsToDisplay = ['ErrorCode', 'Description', 'Resolutions', 'ErrorWeight', 'ControllerBlocked', 'SuggestedChanges', 'SuggestedNew', 'Actions'];*/
    /*columnsToDisplay2 = ['maintenancefailure', 'troubleshootingaction', 'troubleshootingweight', 'subsystemgroup', 'cause', 'actions'];*/

    expandedElement: ErrorCodeML | null = null;

    // Below properties and 2 methods are for multiple expand.
    expandedElements = new Set();
    // expandedElements: ErrorCodeML[] = [];

    subscriptionLanguage: any;

    subscriptionConsolidatedPFML: PossibleFailureMLUndoHistory | undefined = undefined;

    confirmationDialogRef!: MatDialogRef<ConfirmationDialogComponent>;

    @ViewChild(MatTable, { static: true }) table!: MatTable<any>;
    /*@ViewChild(MatTable) table2!: MatTable<PossibleFailureML[]>;*/

    constructor(
        private http: HttpClient
        , private router: Router
        , @Inject('BASE_URL') private baseUrl: string
        , public defaults: DefaultValuesService
        , private customDialog: CustomDialogService
        , private dataTransferService: DataTransferService
        // , private filtersservice: FilterService
    ) { }

    ngOnInit() {
        // this.customDialog.tthis = this;
        /*console.log('ngInitCalled.');*/
        this.subscriptionLanguage = this.defaults.languageMessageChanges$.subscribe(async (language: Language) => { await this.refreshErrorCodeML() });
        this.dataTransferService.consolidatedPFMLMessage.subscribe(message => {
            this.subscriptionConsolidatedPFML = message;
            this.getPossibleFailuresByErrorCodeID(message.ErrorCodeID);
        });
    }

    ngOnDestroy() {
        /*console.log('ngDestroyCalled.');*/
        this.subscriptionLanguage.unsubscribe();
    }


    checkExpanded(element: ErrorCodeML) {
        return this.expandedElements.has(element);
    }

    pushPopElement(element: ErrorCodeML) {
        if (this.expandedElements.has(element)) {
            this.expandedElements.delete(element);
        }
        else {
            this.expandedElements.add(element);

            // Load the possbile failures from ml once expanded.
            // this.list.find(errorcodeml => errorcodeml.ErrorCodeID == element.ErrorCodeID)!.PossibleFailureML = this.getPossibleFailuresByErrorCodeML(element);
            //let expandedErrorCodeML = this.list.find(errorcodeml => errorcodeml.ErrorCodeID === element.ErrorCodeID);
            //if (expandedErrorCodeML) {
            //    expandedErrorCodeML.ErrorCodeResolutions = this.getPossibleFailuresByErrorCodeML(element);

            //    console.log(this.list);
            //}

            this.getPossibleFailuresByErrorCodeID(element.ErrorCodeID);
        }
    }


    getPossibleFailuresByErrorCodeML(errorcodeml: ErrorCodeML) {
        // let possibleFailures: PossibleFailureML[] = [];

        const apiUrl = Utils.getPossibleFailureMLAPIUrl(this.baseUrl, errorcodeml.ErrorCodeID);

        let foundIndex = this.list.findIndex(x => x.ErrorCodeID === errorcodeml.ErrorCodeID);
        if (foundIndex >= 0) {
            this.http.get<PossibleFailureML[]>(apiUrl).subscribe(data => {
                this.list[foundIndex].PossibleFailureML = data;
            });
        }
    }

    getPossibleFailuresByErrorCodeID(errorcodeid: number) {
        // let possibleFailures: PossibleFailureML[] = [];

        const apiUrl = Utils.getPossibleFailureMLAPIUrl(this.baseUrl, errorcodeid);

        let foundIndex = this.list.findIndex(x => x.ErrorCodeID === errorcodeid);
        if (foundIndex >= 0) {
            this.http.get<PossibleFailureML[]>(apiUrl).subscribe(data => {
                this.list[foundIndex].PossibleFailureML = data;
            });
        }
    }

    async refreshErrorCodeML() {
        await Utils.httpGetErrorCodesML(
            this.http
            , this.baseUrl
            , this
            , function (tthis: any, list: ErrorCodeML[]) {
                tthis.list = list;
            }
        );
    }

    //checkExpanded(element: ErrorCodeML): boolean {
    //    let flag = false;
    //    this.expandedElements.forEach(e => {
    //        if (e === element) {
    //            flag = true;

    //        }
    //    });
    //    return flag;
    //}

    //pushPopElement(element: ErrorCodeML) {
    //    const index = this.expandedElements.indexOf(element);
    //    console.log(index);
    //    if (index === -1) {
    //        this.expandedElements.push(element);
    //    } else {
    //        this.expandedElements.splice(index, 1);
    //    }
    //}

    //dataSource = ELEMENT_DATA;
    //dataSource2 = ELEMENT_DATA.slice(0, 4);
    //columnsToDisplay = ['name', 'weight', 'symbol', 'position', 'actions'];
    //columnsToDisplay2 = ['name', 'weight', 'symbol', 'position', 'actions'];
    // expandedElement: PeriodicElement | null = null;

    errorcodesml: ErrorCodeML[] = [];
    //@Input() equals: any | undefined;
    //@Input('list') list: ErrorCodeML[] = [];

    //header: any[] = [
    //    { label: 'Error Code', show: true, searchtype: 'number', expanded: false, index: 0 }
    //    , { label: 'Description', show: true, searchtype: 'text', expanded: false, index: 1 }
    //    , { label: 'Number of Resolutions', show: true, searchtype: 'number', expanded: false, index: 2 }
    //    , { label: 'Error Weight', show: true, searchtype: 'number', expanded: false, index: 3 }
    //    , { label: 'Controller Blocked', show: true, searchtype: 'checkbox', checkbox: true, expanded: false, index: 4 }
    //    , { label: 'Number of Suggested Changes', show: true, searchtype: 'number', expanded: false, index: 5 }
    //    , { label: 'Number of New Suggestions', show: true, searchtype: 'number', expanded: false, index: 6 }
    //];


    //constructor(
    //    private activatedRoute: ActivatedRoute
    //    , private router: Router
    //    , private http: HttpClient
    //    , @Inject('BASE_URL') private baseUrl: string
    //    , public defaults: DefaultValuesService
    //    , private dialog: DialogService
    //) { }

    //ngOnInit() {
    //    // this.dialog.tthis = this;
    //}

    //ngOnChanges() {

    //}

  

    //getFakeErrorCodesML() {
    //    let errorcodemls: ErrorCodeML[] = [];
    //    for (var i = 1; i < 10; i++) {
    //        const ec: ErrorCodeML = {
    //            Description: 'Test Description',
    //            Description_ref: 'Test Description Ref',
    //            ErrorCode: 1000,
    //            BK: 'CT30',
    //            ErrorWeight: 3.00,
    //            Resolutions: i,
    //            ControllerBlocked: true,
    //            ComponentInView: true,
    //            ErrorCodeID: 10000,
    //            ControllerTypeID: 61,
    //            FaultDescriptionID: 1000,
    //            TranslationAreaID: 1000,
    //            LanguageID: 50,
    //            LanguageCultureName: 'en-US',
    //            ErrorCodeResolutions: [],
    //            SuggestedChanges: i,
    //            SuggestedNew: i + 2
    //        };
    //        // this.errorcodesml.push(ec);
    //        errorcodemls.push(ec);
    //    }

    //    return errorcodemls;
    //}

    //getFakeResolutions(parentI: number) {
    //    let errorcoderesolutions: PossibleFailureML[] = [];
    //    for (var i = 1; i < 5; i++) {
    //        const res: PossibleFailureML = {
    //            ErrorCode: 1000 + parentI,
    //            ControllerTypeID: 61,
    //            ErrorCodeID: 1000 + parentI,
    //            PossibleFailureID: 1000 + parentI,
    //            SubSystemGroupID: 1000 + parentI,
    //            ComponentItemID: 1000 + parentI,
    //            MaintenanceFailureID: 1000 + parentI,
    //            MaintenanceFailure_Ref: 'Test Maintenance Ref',
    //            MaintenanceFailure: 'Test Maintenance Failure',
    //            PartsRequired: true,
    //            HighValuePart: true,
    //            TroubleShootingAction_Ref: 'Test Troubleshooting Action Ref',
    //            TroubleShootingActionID: 1000 + parentI,
    //            TroubleShootingAction: 'Test Troubleshooting Action',
    //            TroubleShootingActionWeight: 1000 + parentI,
    //            LanguageID: 1000 + parentI,
    //            LanguageCultureName: 'en-US',
    //            CONTROLLERTYPEBK: 'CT30',
    //            SystemGroup: 'System Group',
    //            SubSystemGroup: 'Sub System Group',
    //            ComponentItem: 'Component Item',
    //            Cause: 'Test Cause',
    //            CauseCode: 'Cause Code',
    //            CauseID: 1000 + parentI,
    //            Resolution: 'Test Resolution',
    //            ResolutionCode: 'Test Resolution Code',
    //            ResolutionID: 1000 + parentI,
    //            FaultDescriptionID: 1000 + parentI,
    //            TranslationAreaID: 1000 + parentI,
    //            ComponentInView: true,
    //            CauseInView: true,
    //            SuggestedWeight: i + parentI,
    //            IsNew: true
    //        };
    //        errorcoderesolutions.push(res);
    //    }
    //    return errorcoderesolutions;
    //}

    consolidateErrorCode(element: ErrorCodeML) {
        console.log(element);

        this.customDialog.openConfirm({
            title: "Consolidate Error Code ML",
            message: `Are you sure you want to consolidate all the suggested possible failures for this error code: ${element.Description}(${element.ErrorCode})?`,
            caller: this
        });

        this.customDialog.confirmed().subscribe(res => {
            if (res.confirmed) {
                // Call API to ignore all the possible failure ml for this errorcode.
                const url = this.baseUrl + Utils.getErrorCodeAPI() + "consolidateerrorcodeml";
                this.http.post(url, element).subscribe(result => {
                    // UI manipulation
                    let foundIndex = res.caller.list.findIndex((x: { ErrorCodeID: number; }) => x.ErrorCodeID === element.ErrorCodeID);
                    if (foundIndex > -1) {
                        res.caller.list.splice(foundIndex, 1);
                    }

                    res.caller.table.renderRows();
                }, error => { this.customDialog.openDisplayInfoDialog({ title: 'Error', message: error.error.message }); });
            }
        });

    }


    consolidateResolution(element: PossibleFailureML) {
        console.log(element);

        this.customDialog.openConfirm({
            title: "Consolidate Possible Failure",
            message: `Are you sure you want to consolidate the suggested possible failures for ${element.MaintenanceFailure}(${element.MaintenanceFailureID})?`,
            caller: this
        });

        /*this.confirmdialog.confirmed().subscribe(confirmed => {*/
        this.customDialog.confirmed().subscribe(res => {
            if (res.confirmed) {
                // Call consolidate the possible failure.
                const url = this.baseUrl + Utils.getErrorCodeAPI() + "consolidatepossiblefailureml";
                this.http
                    .post<PossibleFailureML[]>(url, element.PossibleFailureMLID)
                    .subscribe(result => {
                        let errorcodemlIndex = res.caller.list.findIndex((x: { ErrorCodeID: number; }) => x.ErrorCodeID === element.ErrorCodeID);
                        if (result.length === 0) {
                            // If this is the last possible failure ml that we consolidated.
                            res.caller.list.splice(errorcodemlIndex, 1);
                            res.caller.table.renderRows();
                        } else if (errorcodemlIndex > -1) {
                            // Otherwise, we only delete that row by refreshing the list.
                            res.caller.list[errorcodemlIndex].PossibleFailureML = result;
                        }
                    }
                        , error => {
                            this.customDialog.openDisplayInfoDialog({ title: 'Error', message: error.error.message });
                        }
                    );
            }
        });
    }


    ignoreErrorCode(element: ErrorCodeML) {
        this.customDialog.openConfirm({
            title: "Ignore Error Code ML",
            message: `Are you sure you want to ignore all the suggested possible failures for this error code: ${element.Description}(${element.ErrorCode})?`,
            caller: this
        });

        this.customDialog.confirmed().subscribe(res => {
            if (res.confirmed) {
                // Call API to ignore all the possible failure ml for this errorcode.
                const url = this.baseUrl + Utils.getErrorCodeAPI() + "ignoreerrorcodeml";
                this.http.post(url, element).subscribe(result => {
                    // UI manipulation
                    let foundIndex = res.caller.list.findIndex((x: { ErrorCodeID: number; }) => x.ErrorCodeID === element.ErrorCodeID);
                    if (foundIndex > -1) {
                        res.caller.list.splice(foundIndex, 1);
                    }

                    res.caller.table.renderRows();
                }, error => { this.customDialog.openDisplayInfoDialog({ title: 'Error', message: error.error.message }); });
            }
        });
    }

    ignoreResolution(element: PossibleFailureML) {
        this.customDialog.openConfirm({
            title: "Ignore Possible Failure",
            message: `Are you sure you want to ignore the suggested possible failures for ${element.MaintenanceFailure}(${element.MaintenanceFailureID})?`,
            caller: this
        });

        /*this.confirmdialog.confirmed().subscribe(confirmed => {*/
        this.customDialog.confirmed().subscribe(res => {
            if (res.confirmed) {
                console.log(res.caller.list);
                // Call ignore the possible failure.
                const url = this.baseUrl + Utils.getErrorCodeAPI() + "ignorepossiblefailureml";
                this.http
                    .post<PossibleFailureML[]>(url, element.PossibleFailureMLID)
                    .subscribe(result => {
                        let errorcodemlIndex = res.caller.list.findIndex((x: { ErrorCodeID: number; }) => x.ErrorCodeID === element.ErrorCodeID);
                        if (result.length === 0) {
                            // If this is the last possible failure ml that we ignored.
                            res.caller.list.splice(errorcodemlIndex, 1);
                            res.caller.table.renderRows();
                        } else if (errorcodemlIndex > -1) {
                            // Otherwise, we only delete that row by refreshing the list.
                            res.caller.list[errorcodemlIndex].PossibleFailureML = result;
                        }
                    }
                        , error => {
                            this.customDialog.openDisplayInfoDialog({ title: 'Error', message: error.error.message });
                        }
                    );
            }
        });
    }


    //openDialog(data: any) {
    //    const dialogConfig = new MatDialogConfig();
    //    dialogConfig.data = data;

    //    this.confirmationDialogRef = this.matDialog.open(ConfirmationDialogComponent, dialogConfig);

    //    //this.confirmationDialogRef.afterClosed().subscribe(result => {

    //    //});
    //}
}



//export interface PeriodicElement {
//    name: string;
//    position: number;
//    weight: number;
//    symbol: string;
//    description: string;
//}

//const ELEMENT_DATA: PeriodicElement[] = [
//    {
//        position: 1,
//        name: 'Hydrogen',
//        weight: 1.0079,
//        symbol: 'H',
//        description: `Hydrogen is a chemical element with symbol H and atomic number 1. With a standard
//        atomic weight of 1.008, hydrogen is the lightest element on the periodic table.`
//    }, {
//        position: 2,
//        name: 'Helium',
//        weight: 4.0026,
//        symbol: 'He',
//        description: `Helium is a chemical element with symbol He and atomic number 2. It is a
//        colorless, odorless, tasteless, non-toxic, inert, monatomic gas, the first in the noble gas
//        group in the periodic table. Its boiling point is the lowest among all the elements.`
//    }, {
//        position: 3,
//        name: 'Lithium',
//        weight: 6.941,
//        symbol: 'Li',
//        description: `Lithium is a chemical element with symbol Li and atomic number 3. It is a soft,
//        silvery-white alkali metal. Under standard conditions, it is the lightest metal and the
//        lightest solid element.`
//    }, {
//        position: 4,
//        name: 'Beryllium',
//        weight: 9.0122,
//        symbol: 'Be',
//        description: `Beryllium is a chemical element with symbol Be and atomic number 4. It is a
//        relatively rare element in the universe, usually occurring as a product of the spallation of
//        larger atomic nuclei that have collided with cosmic rays.`
//    }, {
//        position: 5,
//        name: 'Boron',
//        weight: 10.811,
//        symbol: 'B',
//        description: `Boron is a chemical element with symbol B and atomic number 5. Produced entirely
//        by cosmic ray spallation and supernovae and not by stellar nucleosynthesis, it is a
//        low-abundance element in the Solar system and in the Earth's crust.`
//    }, {
//        position: 6,
//        name: 'Carbon',
//        weight: 12.0107,
//        symbol: 'C',
//        description: `Carbon is a chemical element with symbol C and atomic number 6. It is nonmetallic
//        and tetravalent—making four electrons available to form covalent chemical bonds. It belongs
//        to group 14 of the periodic table.`
//    }, {
//        position: 7,
//        name: 'Nitrogen',
//        weight: 14.0067,
//        symbol: 'N',
//        description: `Nitrogen is a chemical element with symbol N and atomic number 7. It was first
//        discovered and isolated by Scottish physician Daniel Rutherford in 1772.`
//    }, {
//        position: 8,
//        name: 'Oxygen',
//        weight: 15.9994,
//        symbol: 'O',
//        description: `Oxygen is a chemical element with symbol O and atomic number 8. It is a member of
//         the chalcogen group on the periodic table, a highly reactive nonmetal, and an oxidizing
//         agent that readily forms oxides with most elements as well as with other compounds.`
//    }, {
//        position: 9,
//        name: 'Fluorine',
//        weight: 18.9984,
//        symbol: 'F',
//        description: `Fluorine is a chemical element with symbol F and atomic number 9. It is the
//        lightest halogen and exists as a highly toxic pale yellow diatomic gas at standard
//        conditions.`
//    }, {
//        position: 10,
//        name: 'Neon',
//        weight: 20.1797,
//        symbol: 'Ne',
//        description: `Neon is a chemical element with symbol Ne and atomic number 10. It is a noble gas.
//        Neon is a colorless, odorless, inert monatomic gas under standard conditions, with about
//        two-thirds the density of air.`
//    },
//];
