import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { DialogService } from '../../services/dialog.service';
import { DefaultValuesService } from '../../services/defaultvalues.service';
import { SaveService } from '../../services/save.service';
import { Utils } from '../../utils/utils';
import { Router } from '@angular/router';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

@Component({
    selector: 'groupfunction',
    //template: `Group Function:<br />
    //            <ng-select (change)="onSelect($event)" style="width:105px; border: 1px white solid; background-color: #191919;" class="top-main-select"
    //                [items]="defaults.groupFunctions"
    //               bindLabel="item"
    //               bindValue="item"
    //               [closeOnSelect]="true"
    //               [searchable]="true"
    //               [(ngModel)]="defaults.groupFunction">
    //            </ng-select>`
    template: `<mat-button-toggle-group (change)="onSelect($event)" style=" margin-left: 10px;" [(ngModel)]="selectedgroupFunction">
                <mat-button-toggle  matTooltip="Show All nodes" value="All">All</mat-button-toggle>
                <mat-button-toggle *ngFor="let item of defaults.groupFunctions" matTooltip="Show only {{item}} nodes" value="{{item}}">{{item}}</mat-button-toggle>
            </mat-button-toggle-group>`
})

export class GroupFunctionComponent implements OnInit, AfterViewInit {
    @Output() groupFunctionChanged = new EventEmitter();
    selectedgroupFunction = "";

    value: string = "";
    public filteredGroupFunctions: string[] = [];

    protected _onDestroy = new Subject<void>();


    constructor(
        private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , public defaults: DefaultValuesService
        , private router: Router
        , private dialog: DialogService
        , public saveService: SaveService
        , private cdr: ChangeDetectorRef
    ) {

    }
    ngAfterViewInit() {
        this.defaults.languageMessageChanges$.subscribe(() => {
            this.selectedgroupFunction = this.defaults.groupFunction;
        });
        this.dialog.tthis = this;




    }

    ngOnInit() {

    }





    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    changeGroupFunction() {
        //    this.sendPost();
    }

    async sendPost() {
        if (!this.value) return
        const url = this.baseUrl + Utils.getGroupFunctionAPI();

        this.value != 'All' ? this.defaults.groupFunction = this.value : null;

        await this.http
            .post<string>(url, { 'groupFunction': this.defaults.groupFunction })
            .toPromise()
            .then(() => { this.defaults.notifyGroupFunctionChange() });
    }

    async onNo() {
        await this.saveService.fireCancelAll();
        await this.sendPost();
    }

    onSelect(groupFunction: MatButtonToggleChange) {
        if (!groupFunction)
            return;
        this.value = groupFunction.value;
        this.groupFunctionChanged.emit(this.selectedgroupFunction);
        //if (this.saveService.isSaveButtonShown())
        //    this.dialog.showConfirmDialog("Do you want to save the changes? If you select No, all changes will be lost!", "Save", this);
        //else
        //    this.onNo();

    }
    resetGrpFunctionDefault() {
        this.selectedgroupFunction = this.defaults.groupFunction;
        this.value = this.selectedgroupFunction;

    }

    onYes() {
        this.saveService.fireSave();
        this.sendPost();
    }
}