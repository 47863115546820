import { Component, Inject, Input, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { MatChipInputEvent } from "@angular/material/chips";
import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';
import { SaveService } from '../../../../services/save.service';
import { Utils } from '../../../../utils/utils';
import { DefaultValuesService } from '../../../../services/defaultvalues.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DialogService } from "../../../../services/dialog.service";

@Component({
    selector: "otherasset-list",
    templateUrl: './otherasset-list.component.html',
    styleUrls: ['./otherasset-list.component.less', '../../../../style/mat-table.component.less'],

    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' })),
            transition('expanded => collapsed', [animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
        ]),
    ]
})

export class OtherAssetListComponent implements OnInit {
    @Input('list') list: OtherAsset[] = [];
    @Input('showLocalFilter') showLocalFilter = false;

    columnsToDisplay = ['arrow-details', 'arrow-attr', 'index', 'UnitID', 'BranchNumber', 'ControllerType', 'BuildingAddr1', 'BuildingCity', 'Nickname', 'AssetType', 'UnitType', '_ChangedAtUtc', 'actions'];

    subcolumnsDetailsToDisplay = ['TechnicianId', 'MaintenanceFrequencyPerYear', 'HasResidentMechanic',
        'CountryCode', 'BuildingAddr2', 'BuildingType', 'BuildingState', 'ZipCode', 'BuildingName',
        'Longitude', 'Latitude', 'Timezone', 'ProvisioningStatusChangeDate', 'ConnectionType',
        'Office', 'SupervisorName', 'Supervisor8id', 'MaintenanceRoute', 'CommercialSector', 'IsMaxcompatible',
        'BuildingId', 'GatewayId', 'IsVip', 'IsLoadValidated', 'Retrofit', 'Gtm', 'HasSensorSolution',
        'GovernmentAssetId', 'PatternNumber', 'ConfigItProjectID', 'ProjectName', 'ProjectType', 'ProjectBranchCode', 'ProjectVersion', 'PM8ID',
        'PMName', 'PMEmail', 'UEID', 'ProductModel', 'PONumber', 'POItemNo', 'ExpectedDeliveryDate', 'TP2',
        'TP2Currency', '_CreatedAtUtc', 'BankLocationReference',
        'ControllerNumber', 'SelectionFromDate', 'UnitGovId', 'IsNIProvisioning', 'TechnicianName', 'AcquiredCompany', 'AcquisitionDate', 'RegulatoryCode',
        'InstalledControllerType', 'ErpControllerType', 'Client', 'CreatorSource', 'ChangedSource', 'OptOut', 'AcceptanceDate', 'CellularNetwork',
        'IsSeasonalUnit', 'SeasonalUnitFromMonth', 'SeasonalUnitToMonth', 'PreferredMaintenanceServiceWeekOfMonth', 'SourceSystemId', '_ChangedByMsgType', '_ChangedByUser'];

    subcolumnsattrToDisplay = ['Name', 'ValueInt', 'ValueDouble', 'ValueString', 'ValueDateTime'];

    booleanColumns = ['HasResidentMechanic', 'IsMaxcompatible', 'HasResidentMechanic', 'IsVip', 'IsLoadValidated', 'HasSensorSolution', 'IsSeasonalUnit']                                                                                    
    dateColumns = ['_ChangedAtUtc', 'ValueDateTime', 'AcquisitionDate', 'AcceptanceDate']
    numberColumns = ['ValueInt', 'ValueDouble', 'ValueInt', 'MaintenanceFrequencyPerYear', '_ChangedByMsgType', 'BuildingId', 'GatewayId', 'PatternNumber',
        'SeasonalUnitFromMonth', 'PreferredMaintenanceServiceWeekOfMonth', 'SeasonalUnitToMonth']

    notEditableColumns = ['UnitID', '_ChangedAtUtc', 'ProvisioningStatusChangeDate', 'Name', 'AssetType', '_ChangedByMsgType',
        '_ChangedByUser', '_CreatedAtUtc', 'BuildingId', 'GatewayId', 'IsVip', 'HasSensorSolution', 'PatternNumber', 'Gtm']

    @ViewChild(MatTable, { static: true }) table!: MatTable<any>;

    public dataSource = new MatTableDataSource<OtherAsset>();
    selected: OtherAsset | undefined;
    expanded: boolean[] = [];
    expandedElements = new Set();
    expandedElementsAttrs = new Set();

    clonedList: { [s: string]: OtherAsset; } = {};
    clonedListAttr: { [s: string]: OtherAssetAttribute; } = {};

    filter: string = '';

    subscriptionTenant: any;

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [COMMA, TAB, ENTER];
    searchItems: SearchItem[] = [];

    isPrimaryOtherAsset = '-1'

    constructor(private activatedRoute: ActivatedRoute
        , private router: Router
        , private http: HttpClient
        , private saveService: SaveService
        , private defaults: DefaultValuesService
        , private confimDialog: DialogService
        , @Inject('BASE_URL') private baseUrl: string

    ) {
    }

    ngOnChanges() {
        this.dataSource.data = this.list
        this.clonedList = {}
        this.saveService.showSaveButton(false)
        this.expandedElements.clear();
        this.expandedElementsAttrs.clear();
        this.saveService.addListener(this)
    }

    ngOnInit() {
        this.getHeader();
        this.subscriptionTenant = this.defaults.tenantMessageChanges$.subscribe(() => {
            this.getHeader()
        });
        this.saveService.addListener(this);
        this.dataSource.filterPredicate = (data: OtherAsset, filters: string) => {
            const matchFilter: any[] = [];
            const filterArray = filters.split('+').filter(x => x.indexOf('notextfilters') < 0)
            if (filterArray.length > 0) {
                filterArray.forEach((filter) => {
                    const fields: string[] = []
                    fields.push(data.UnitID ? data.UnitID : '')
                    fields.push(data.BranchNumber ? data.BranchNumber : '')
                    fields.push(data.ControllerType ? data.ControllerType : '')
                    fields.push(data.BuildingAddr1 ? data.BuildingAddr1 : '')
                    fields.push(data.BuildingCity ? data.BuildingCity : '')
                    fields.push(data.AssetType ? data.AssetType : '')
                    fields.push(data.UnitType ? data.UnitType : '')
                    return matchFilter.push(fields.some(item => item.includes(filter)));
                });
            }
            else {
                let result = true;
                matchFilter.push(result);
            }
            return matchFilter.some(Boolean);
        };
    }

    getHeader() {
        if (!this.defaults.isReadonlyUser) {
            this.columnsToDisplay = ['arrow-details', 'arrow-attr', 'index', 'UnitID', 'BranchNumber', 'ControllerType', 'BuildingAddr1', 'BuildingCity', 'Nickname', 'AssetType', 'UnitType', '_ChangedAtUtc', 'actions'];
            this.subcolumnsattrToDisplay = ['Name', 'ValueInt', 'ValueDouble', 'ValueString', 'ValueDateTime'];
        }
        else {
            this.columnsToDisplay = ['arrow-details', 'arrow-attr', 'index', 'UnitID', 'BranchNumber', 'ControllerType', 'BuildingAddr1', 'BuildingCity', 'Nickname', 'AssetType', 'UnitType', '_ChangedAtUtc'];
            this.subcolumnsattrToDisplay = ['Name', 'ValueInt', 'ValueDouble', 'ValueString', 'ValueDateTime'];
        }
    }

    getInput(data: OtherAsset, property: string): any {
        return data[property];
    }

    getDataType(property: string) {
        let result = ''
        if (this.booleanColumns.indexOf(property) > -1) result = 'boolean'
        else if (this.dateColumns.indexOf(property) > -1) result = 'date'
        else if (this.numberColumns.indexOf(property) > -1) result = 'number'
        else result = 'string'
        return result
    }

    changeElement(object: any, type: string, value: boolean) {
        object[type] = value;
        this.table.renderRows();
    }

    pushPopElement(element: OtherAsset, isAttr: boolean) {
        if (!isAttr) {
            if (this.expandedElements.has(element)) {
                this.expandedElements.delete(element);
            }
            else {
                this.expandedElements.add(element);
                this.onExpand(element, isAttr)
            }
        }
        else {
            if (this.expandedElementsAttrs.has(element)) {
                this.expandedElementsAttrs.delete(element);
            }
            else {
                this.expandedElementsAttrs.add(element);
                this.onExpand(element, isAttr)
            }
        }
    }

    checkExpanded(element: OtherAsset, isAttr: boolean) {
        if (!isAttr)
            return this.expandedElements.has(element);
        else
            return this.expandedElementsAttrs.has(element);
    }

    onExpand(element: OtherAsset, isAttr: boolean) {
        if (!isAttr)
            Utils.httpGetDetailedOtherAsset(
                this.http
                , this.baseUrl
                , this.defaults.stage
                , element
                , this
                , function (tthis: any, list: OtherAssetDetails[]) {
                    element.Details = list;
                    tthis.expandedElements.add(element);
                }
            );
        else
            Utils.httpGetOtherAssetAttributes(
                this.http
                , this.baseUrl
                , this.defaults.stage
                , element
                , this
                , function (tthis: any, list: OtherAssetAttribute[]) {
                    element.Attrs = list;
                    tthis.expandedElementsAttrs.add(element);
                }
            );
    }

    onRowEditInit(otherasset: OtherAsset, attr: OtherAssetAttribute | null, isAttr: boolean) {
        this.saveService.addListener(this);
        if (!isAttr) {
            this.clonedList[this.getOtherAssetDictionaryHash(otherasset)] = { ...otherasset };
            if (Object.keys(this.clonedList).length !== 0) this.onShowSavebutton()
            this.onExpand(otherasset, isAttr)
        }
        else if (attr) {
            this.clonedListAttr[this.getOtherAssetAttrDictionaryHash(otherasset, attr)] = { ...attr };
            if (Object.keys(this.clonedListAttr).length !== 0) this.onShowSavebutton()
        }
    }

    onRowEditCancel(otherasset: OtherAsset, attr: OtherAssetAttribute | null, isAttr: boolean) {
        if (!isAttr) {
            this.dataSource.data[this.dataSource.data.findIndex(x => x.UnitID === otherasset.UnitID)] = this.clonedList[this.getOtherAssetDictionaryHash(otherasset)];
            delete this.clonedList[this.getOtherAssetDictionaryHash(otherasset)];
            if (Object.keys(this.clonedList).length === 0) this.saveService.showSaveButton(false)
            this.table.renderRows();
        }
        else if (attr) {
            otherasset.Attrs[otherasset.Attrs.findIndex(x => x.Name === attr.Name)] = this.clonedListAttr[this.getOtherAssetAttrDictionaryHash(otherasset, attr)];
            delete this.clonedListAttr[this.getOtherAssetAttrDictionaryHash(otherasset, attr)];
        }
        this.table.renderRows();
        if (Object.keys(this.clonedListAttr).length === 0)
            this.saveService.showSaveButton(false)
    }

    isEditing(otherasset: OtherAsset, attr: OtherAssetAttribute | null, property: string | null, isAttr: boolean) {
        let result = true;
        if (property === null) {
            if (!isAttr) {
                result = result && this.clonedList[this.getOtherAssetDictionaryHash(otherasset)] !== undefined
            }
            else {
                result = result && this.clonedListAttr[this.getOtherAssetAttrDictionaryHash(otherasset, attr!)] !== undefined
            }
        }
        else {
            if (!isAttr) {
                result = result && this.clonedList[this.getOtherAssetDictionaryHash(otherasset)] !== undefined && this.notEditableColumns.indexOf(property) < 0
            }
            else {
                result = result && this.clonedListAttr[this.getOtherAssetAttrDictionaryHash(otherasset, attr!)] !== undefined && this.notEditableColumns.indexOf(property) < 0
            }
        }
        return result;
    }

    onShowSavebutton() {
        this.saveService.showSaveButton();
    }

    onSave() {
        let updatedList: any[] = []
        for (let i in this.clonedList) {
            let index = this.dataSource.data.findIndex(x => x.UnitID === this.clonedList[i].UnitID)
            var item = { ...this.dataSource.data[index].Details[0], ...this.dataSource.data[index] }
            updatedList.push(Utils.RemoveBlankFields(item))
        }
        if (updatedList.length > 0)
            Utils.httpUpdateOtherAssets(
                this.http
                , this.baseUrl
                , this.defaults.stage
                , updatedList
                , this
                , function (tthis: any, list: OtherAsset[]) {
                    tthis.clonedList = {}
                    tthis.saveService.showSaveButton(false)
                    tthis.expandedElements.clear();
                    tthis.table.renderRows();
                }, function (tthis: OtherAssetListComponent) {
                    tthis.saveService.addListener(tthis)
                    tthis.saveService.showSaveButton(true)
                    tthis.confimDialog.showErrorDialog({ error: { message: "Incorrect Branch Number. Please, indicate a proper branch for selected country checking Branch Hierarchy section." } });
                }
            );

        updatedList = []
        for (let i in this.clonedListAttr) {
            const tokens = i.split("_");
            let index = this.dataSource.data.findIndex(x => x.UnitID === tokens[0])
            const attr = this.dataSource.data[index].Attrs.find(x => x.Name === tokens[2])
            updatedList.push({ "UnitID": tokens[0], "TenantId": tokens[1], ...attr })
        }

        if (updatedList.length > 0)
            Utils.httpUpdateOtherAssetAttrs(
                this.http
                , this.baseUrl
                , this.defaults.stage
                , updatedList
                , this
                , function (tthis: any, list: OtherAsset[]) {
                    tthis.clonedListAttr = {}
                    tthis.saveService.showSaveButton(false)
                    tthis.expandedElementsAttrs.clear();
                    tthis.table.renderRows();
                }
            );
    }

    applyFilter(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            this.searchItems.push({ name: value.replace('+', '').trim() });
        }
        // Reset the input value
        if (input) {
            input.value = '';
        }
        this.updatefilter()
    }

    remove(item: SearchItem): void {
        const index = this.searchItems.indexOf(item);
        if (index >= 0) {
            this.searchItems.splice(index, 1);
        }
        this.updatefilter()
    }
    updatefilter() {
        let filterString: string
        if (this.searchItems.length === 0) {
            this.dataSource.filter = 'notextfilters'
        }
        else {
            filterString = this.searchItems.map(e => { return e.name }).join('+') + '+checkboxes'
            this.dataSource.filter = filterString
        }
    }

    getOtherAssetDictionaryHash(otherasset: OtherAsset) {
        return otherasset.UnitID + otherasset.TenantID
    }
    getOtherAssetAttrDictionaryHash(otherasset: OtherAsset, otherassetAttr: OtherAssetAttribute) {
        return otherasset.UnitID + "_" + otherasset.TenantID + "_" + otherassetAttr.Name
    }

}
export interface SearchItem {
    name: string;
}