<!--
 ######################################################################
    table
  -- thead
  ---- th(expand-icon); th(row with the headers); th(editButton)
  ---------------------- tr
  ------------------------ th* (headers)
  ---------------------- tr
  ------------------------ th* (forms)
  -- tbody
  -- tr*
  ---- td(expand-icon); td(tr row with the data); td(editButton)
  ---------------------- tr
  ------------------------ th* (data)
  ---------------------- tr
  ------------------------ th* (expand template)
 ######################################################################
 -->

<table style="width: auto;">
    <thead>
        <tr *ngIf="showSearch">
            <!-- search input boxes -->
            <th>&nbsp;</th>
            <th *ngIf="selectable">&nbsp;</th>
            <th [attr.colspan]="header.length">
                <form>
                    <table>
                        <thead>
                            <tr>
                                <th>

                                    <ng-container *ngTemplateOutlet="headerSearch"></ng-container>

                                <!--<th *ngFor="let l of header; index as i">
                                    <select-checkbox-search [header]="l" [idcontainer]="idtable"></select-checkbox-search>-->
                                    <!--<input *ngIf="l.searchtype != 'checkbox'" id="{{idtable}}-search-header-{{i}}" title="Search by {{l.label}}" type="{{l.searchtype}}" placeholder="Search {{l.label}}" (change)="onChangeColumn($event, i)" />
        <select *ngIf="l.searchtype == 'checkbox'" id="{{idtable}}-search-header-{{i}}" title="Search by {{l.label}}" (change)="onChangeColumn($event, i)">
            <option>&nbsp;</option>
            <option typeof="checkbox">Active</option>
            <option>Inactive</option>
        </select>-->
                                <!--</th>-->


                                <!--<th>
                                    <select-checkbox-search></select-checkbox-search>
                                </th>
                                <th>
                                    <select-checkbox-search></select-checkbox-search>
                                </th>-->
                            </tr>
                        </thead>
                    </table>
                </form>
            </th>
            <th *ngIf="rowEditTemplate||isToShowDeleteButton()">&nbsp;</th> <!-- to edit button -->
        </tr>
    </thead>
</table>




<!--<div id="{{idtable}}" class="divTable">-->
<table style="width: auto;" id="{{idtable}}" class="fixed_headers">
    <!--<div class="divTableHeading">-->
    <thead>
        <tr>
            <th>
                <table>
                    <thead>
                        <tr>
                            <th *ngIf="!isToShowSearchButton()">&nbsp;</th>
                            <th *ngIf="isToShowSearchButton()">
                                <button class="button-filter" type="button" (click)="showSearchForm()">&nbsp;</button>
                            </th>
                            <th *ngIf="selectable"><input type="checkbox" (click)="onCheckedAll($event)" /></th> <!-- to select -->
                            <th *ngFor="let l of header; index as i" title="{{l.title}}">
                                <!--<ng-container *ngTemplateOutlet="headerdata"></ng-container>-->
                                <span *ngIf="!l.show">&nbsp;</span>
                                <div class="search" *ngIf="l.show">{{l.label}}</div>
                            </th>
                            <th *ngIf="rowEditTemplate||isToShowDeleteButton()">&nbsp;</th> <!-- to edit button -->
                        </tr> <!--divTableRow-->
                    </thead>
                </table>
            </th>
        </tr>
    </thead> <!--divTableHeading-->

    <tbody>
        <!--class="divTableBody"-->
        <tr *ngIf="newRow">
            <ng-container *ngTemplateOutlet="rowNewTemplate"></ng-container>
        </tr>            

        <tr *ngFor="let d of data; index as r" id="row-{{idtable}}-{{r}}">
            <!--class="divTableRow"-->
            <!-- table data -->
            <td>
                <table>
                    <tbody class="inner">
                        <!-- row to show the form to create a record -->
                        <tr *ngIf="editing[r]">
                            <td [attr.colspan]="header.length" style="width:auto;">
                                <ng-container *ngTemplateOutlet="rowEditTemplate; context:{value:d, index:r}"></ng-container>
                            </td>
                        </tr>

                        <tr *ngIf="!editing[r]">
                            <td *ngIf="collapsible" (click)="onExpandRow($event, r)">
                                <span *ngIf="!expanded[r]"><strong style="font-size:large;">+</strong></span>
                                <span *ngIf="expanded[r]"><strong style="font-size:large;">-</strong></span>
                            </td>
                            <td *ngIf="!collapsible">&nbsp;</td> <!-- to expand -->
                            <td *ngIf="selectable"><input type="checkbox" [checked]="isRowChecked(r)" (click)="onCheck($event, r)" /></td> <!-- to select -->

                            <td *ngFor="let l of header; index as c" [ngClass]="getClass(r, c)" title="{{l.label}}: {{l.title}}" (click)="onCellClick(r, c)">
                                <span *ngIf="!l.show">&nbsp;</span>
                                <span *ngIf="!l.checkbox&&l.show">{{getData(r, c)}}</span>
                                <span *ngIf="l.checkbox&&l.show"><input type="checkbox" [checked]="isInputChecked(r, c)" value="1" onclick="return false;" /></span>
                            </td>

                            <td *ngIf="rowEditTemplate||isToShowDeleteButton()">
                                <!-- class="divTableCell"  -->
                                <button *ngIf="collapsible&&expanded[r]&&onNewChild.observers.length!=0"
                                        type="button"
                                        title="new Child"
                                        class="btn btn-default"
                                        (click)="onRowNewChild(d,r)"
                                        >
                                    +
                                </button>
                                <button *ngIf="rowEditTemplate"
                                        type="button"
                                        class="btn btn-default"
                                        [disabled]="editing[r]"
                                        (click)="onRowEdit(d,r)">
                                    {{editLabel}}
                                </button>

                                <button *ngIf="isToShowDeleteButton()"
                                        type="button"
                                        title="Delete Record"
                                        class="btn btn-danger"
                                        (click)="onRowDelete(d,r)">
                                    Delete
                                </button>
                            </td>
                        </tr>
                        <tr [ngClass]="expandrowclass[r]">
                            <td>&nbsp;</td> <!-- collapsible-->
                            <td *ngIf="selectable">&nbsp;</td> <!-- selectable-->

                            <td [attr.colspan]="header.length" class="expand-container" style="width:100%; border-color: black; border-style:solid; border-width:1px;">
                                <ng-container *ngTemplateOutlet="rowExpandTemplate; context:{value:d, index:r}"></ng-container>
                            </td>

                            <td *ngIf="rowEditTemplate||isToShowDeleteButton()">&nbsp;</td> <!-- buttons -->

                        </tr>
                    </tbody>
                </table>
            </td>


            <!-- divTable data -->
            <!--<div class="divTableRow" id="rowdata-{{idtable}}" *ngIf="editing[r]">

            </div>

            <div class="divTableRow" id="rowdata-{{idtable}}" *ngIf="!editing[r]">
                <div class="divTableCell" *ngFor="let l of header; index as c" [ngClass]="getClass(r, c)" title="{{l.label}}">
                    <span *ngIf="!l.checkbox">{{getData(r, c)}}</span>
                    <span *ngIf="l.checkbox"><input type="checkbox" [checked]="isInputChecked(r, c)" value="1" onclick="return false;" /></span>
                </div>
            </div>-->
            <!--divTableRow-->
            <!--<div class="divTableRow" [ngClass]="expandrowclass[r]">

            </div>-->

        </tr> <!-- divTableRow -->
    </tbody> <!--divTableBody-->
</table> <!--divTable-->
<ng-container *ngTemplateOutlet="pager"></ng-container>





<!--<ng-template #pager>-->
    <!-- pager -->
    <!--<ul *ngIf="pager.pages && pager.pages.length" class="pagination">
        <li [ngClass]="{disabled:pager.currentPage === 1}">
            <a (click)="setPage(1)">First</a>
        </li>
        <li [ngClass]="{disabled:pager.currentPage === 1}">
            <a (click)="setPage(pager.currentPage - 1)">Previous</a>
        </li>
        <li *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
            <a (click)="setPage(page)">{{page}}</a>
        </li>
        <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
            <a (click)="setPage(pager.currentPage + 1)">Next</a>
        </li>
        <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
            <a (click)="setPage(pager.totalPages)">Last</a>
        </li>
    </ul>
</ng-template>-->
