import { Component, OnInit, OnDestroy, Inject, ViewChild } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { DefaultValuesService } from '../../../services/defaultvalues.service';
import { Utils } from '../../../utils/utils';
import { SaveService } from '../../../services/save.service';
import { ChairliftListComponent } from './chairlift-list.component';
import { ChairliftFilterComponent } from './chairlift-filter.component';
import { SignalRService } from "../../../services/signal-r";
import { Subscription } from "rxjs";

@Component({
    selector: "chairlift",
    templateUrl: 'chairlift.component.html',
})

export class ChairliftComponent implements OnInit, OnDestroy {

    list: Chairlift[] = [];

    title = "Chairlifts";
    showLocalFilter = false;

    subscriptionStage: any;
    subscriptionTenant: any;
    subscriptions: Subscription[] = [];

    @ViewChild(ChairliftListComponent, { static: true }) listComponent!: ChairliftListComponent;
    @ViewChild(ChairliftFilterComponent, { static: true }) filterComponent!: ChairliftFilterComponent;

    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string
        , public defaults: DefaultValuesService
        , public signalRService: SignalRService
        , private saveService: SaveService
    ) {  }

    ngOnInit(): void {
        this.subscriptionStage = this.defaults.stageMessageChanges$.subscribe(() => {
            this.getFilteredChairliftsByTenant()
            this.listComponent.clonedList = {}
        });
        this.subscriptionTenant = this.defaults.tenantMessageChanges$.subscribe(() => {
            this.getFilteredChairliftsByTenant()
            this.listComponent.clonedList = {}
        });
        if (this.defaults.tenant)
            this.getFilteredChairliftsByTenant()
    }

    ngOnDestroy() {
        this.saveService.removeListener(this);
        this.subscriptionStage.unsubscribe();
        this.subscriptionTenant.unsubscribe();
        this.subscriptions.forEach(x => x.unsubscribe())
    }

    getFilteredChairliftsByTenant() {
        let filter: ChairliftFilter = {
            'UnitIds': this.filterComponent.unitIds,
            'BranchNumbers': this.filterComponent.branchNumbers,
            'ControllerTypes': this.filterComponent.controllerTypes,
            'BuildingAddr1': this.filterComponent.buildingAddr1,
            'BuildingCities': this.filterComponent.buildingCities,
            'ChairliftNicknames': this.filterComponent.chairliftNicknames
        }
        Utils.httpGetChairlifts(
            this.http
            , this.baseUrl
            , this.defaults.stage
            , filter
            , this
            , function (tthis: any, list: Chairlift[]) {
                tthis.list = list;
                tthis.listComponent.clonedList = {}
                tthis.listComponent.expandedElements = new Set();
                tthis.saveService.showSaveButton(false)
                if (filter.ChairliftNicknames.length < 1 && filter.BranchNumbers.length < 1 && filter.UnitIds.length < 1
                    && filter.BuildingAddr1.length < 1 && filter.ControllerTypes.length < 1 && filter.BranchNumbers.length < 1)
                    tthis.showLocalFilter = false;
                else
                    tthis.showLocalFilter = true;
            }
        );
    }
    onReload() {
        this.getFilteredChairliftsByTenant();
        this.listComponent.clonedList = {}
        this.saveService.showSaveButton(false)
    }
    downloadChairliftFiltered() {
        const filter: ChairliftFilter = {
            'UnitIds': this.filterComponent.unitIds,
            'BranchNumbers': this.filterComponent.branchNumbers,
            'ControllerTypes': this.filterComponent.controllerTypes,
            'BuildingAddr1': this.filterComponent.buildingAddr1,
            'BuildingCities': this.filterComponent.buildingCities,
            'ChairliftNicknames': this.filterComponent.chairliftNicknames
        };
        Utils.httpDownloadChairlifts(
            this.http
            , this.baseUrl
            , this.defaults.stage
            , this.defaults.tenant
            , filter
            , this
            , null)
    }
}
