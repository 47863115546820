import { Component, Inject, OnInit, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Utils } from "../../utils/utils";
import { DialogService } from "../../services/dialog.service";
import { WaitingService } from "../../services/waiting.service";
import { DefaultValuesService } from "../../services/defaultvalues.service";
import { SaveService } from "../../services/save.service";
import { MatDialog } from "@angular/material/dialog";

@Component({
    selector: "testcase-form-databricks-trigger",
    templateUrl: './testcase-form-databricks-trigger.component.html',
    styleUrls: ['../../style/form.component.less']
})

export class TestCaseFormDatabricksTriggerComponent implements OnInit {

    header = [
        { label: 'DeviceId', show: true, searchtype: 'text' }
        , { label: 'DateFrom', show: true, searchtype: 'text' }
        , { label: 'DateTo', show: true, searchtype: 'text' }
        , { label: 'Executed', show: true, searchtype: 'checkbox' }
    ];

    title: string;

    selected: TestCaseDatabricksTrigger = {} as TestCaseDatabricksTrigger;

    form: FormGroup = new FormGroup({});

    DEVICEID = 0;
    DATEFROM = 1;
    DATETO = 2;

    constructor(private http: HttpClient
        , private dialog: DialogService
        , public defaults: DefaultValuesService
        , private saveService: SaveService
        , private matDialog: MatDialog
        , @Inject('BASE_URL') private baseUrl: string
    ) {
        this.title = "Add Databricks Trigger to Create a Test Case";
    }

    ngOnInit() {

        if (this.header.length === 0) { //this is a new form
            this.header = this.dialog.getHeader();
        }
        this.saveService.addListener(this);
        this.createForm();

    }

    onCancelForm() {
        this.saveService.fireCancel(-1, this);
    }

    /**
     * called by the SaveService
     * */
    onSave() {
        this.createObject();

        const tthis = this;
        const url = this.baseUrl + Utils.getTestCaseAPI() + "databrickstrigger";
        this.http
            .put<TestCaseDatabricksTrigger>(url, this.selected)
            .subscribe(
                res => {
                    this.matDialog.closeAll();
                }
                , error => this.dialog.showErrorDialog(error)
            );
    }

    /**
     * called by the save button on this form
     * 
     * */
    onSubmit() {
        this.saveService.fireSave(-1, this);
    }

    private createForm() {
        this.form = new FormGroup({
            DeviceId: new FormControl('', [Validators.required]),
            DateFrom: new FormControl('', [Validators.required]),
            DateTo: new FormControl('', [Validators.required])            
        });
    }

    private createObject() {
        this.selected.DeviceId = this.form.controls['DeviceId'].value;
        this.selected.DateFrom = this.form.controls['DateFrom'].value;
        this.selected.DateTo = this.form.controls['DateTo'].value;
    }


    onCancel() {
        this.matDialog.closeAll();
    }

}