import { Component, OnInit, OnDestroy, Inject, ViewChild } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Utils } from '../../../../utils/utils';
import { SaveService } from '../../../../services/save.service';
import { SignalRService } from "../../../../services/signal-r";
import { DefaultValuesService } from "../../../../services/defaultvalues.service";
import { FacilityManagerListComponent } from "./facilitymanager-list.component";
import { FacilityManagerFilterComponent } from "./facilitymanager-filter.component";
import { Subscription } from "rxjs";

@Component({
    selector: "facilitymanager",
    templateUrl: 'facilitymanager.component.html',
})

export class FacilityManagerComponent implements OnInit, OnDestroy {

    list: FacilityManager[] = [];

    showLocalFilter = false;

    subscriptionStage: any;
    subscriptionTenant: any;
    subscriptions: Subscription[] = [];


    @ViewChild(FacilityManagerListComponent, { static: true }) listComponent!: FacilityManagerListComponent;
    @ViewChild(FacilityManagerFilterComponent, { static: true }) filterComponent!: FacilityManagerFilterComponent;

    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string
        , private defaults: DefaultValuesService
        , public signalRService: SignalRService
        , private saveService: SaveService
    ) {  }

    ngOnInit(): void {
        this.subscriptionStage = this.defaults.stageMessageChanges$.subscribe(() => {
            this.getFilteredItemsByTenant()
            this.listComponent.clonedList = {}
        });
        this.subscriptionTenant = this.defaults.tenantMessageChanges$.subscribe(() => {
            this.getFilteredItemsByTenant()
            this.listComponent.clonedList = {}
        });
        if (this.defaults.tenant)
            this.getFilteredItemsByTenant()
    }

    ngOnDestroy() {
        this.saveService.removeListener(this);
        this.subscriptionStage.unsubscribe();
        this.subscriptionTenant.unsubscribe();
        this.subscriptions.forEach(x => x.unsubscribe())
    }

    getFilteredItemsByTenant() {
        const filter: FacilityManagerFilter = {
            'ContractNumbers': this.filterComponent.contractNumbers,
            'FacilityManagerIds': this.filterComponent.facilityManagerIds
        }
        Utils.httpGetContractFacilityManagers(
            this.http
            , this.baseUrl
            , this.defaults.stage
            , filter
            , this
            , function (tthis: any, list: FacilityManager[]) {
                tthis.list = list;
                tthis.listComponent.clonedList = {}
                tthis.listComponent.expandedElements = new Set();
                tthis.saveService.showSaveButton(false)
                if (filter.ContractNumbers.length < 1 && filter.FacilityManagerIds.length < 1)
                    tthis.showLocalFilter = false;
                else
                    tthis.showLocalFilter = true;
            }
        );
    }
    onReload() {
        this.getFilteredItemsByTenant();
        this.listComponent.clonedList = {}
        this.saveService.showSaveButton(false)
    }

    downloadFacilityManagerFiltered() {
        const filter: FacilityManagerFilter = {
            'ContractNumbers': this.filterComponent.contractNumbers,
            'FacilityManagerIds': this.filterComponent.facilityManagerIds
        }
        Utils.httpDownloadContractFacilityManager(
            this.http
            , this.baseUrl
            , this.defaults.stage
            , this.defaults.tenant
            , filter
            , this
            , null)
    }
}
