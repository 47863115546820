import { Component, OnInit, OnDestroy, Inject, ViewChild } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { DefaultValuesService } from '../../../services/defaultvalues.service';
import { Utils } from '../../../utils/utils';
import { SaveService } from '../../../services/save.service';
import { ServiceTicketListComponent } from './service-ticket-list.component';
import { ServiceTicketFilterComponent } from './service-ticket-filter.component';
import { SignalRService } from "../../../services/signal-r";
import { Subscription } from "rxjs";

@Component({
    selector: "service-ticket",
    templateUrl: 'service-ticket.component.html',
})

export class ServiceTicketComponent implements OnInit, OnDestroy {

    list: ServiceTicket[] = [];

    title = "Service Tickets";
    showLocalFilter = false;

    subscriptionStage: any;
    subscriptionTenant: any;
    subscriptions: Subscription[] = [];

    @ViewChild(ServiceTicketListComponent, { static: true }) listComponent!: ServiceTicketListComponent;
    @ViewChild(ServiceTicketFilterComponent, { static: true }) filterComponent!: ServiceTicketFilterComponent;

    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string
        , public defaults: DefaultValuesService
        , public signalRService: SignalRService
        , private saveService: SaveService
    ) { }

    ngOnInit(): void {
        this.subscriptionStage = this.defaults.stageMessageChanges$.subscribe(() => {
            this.getFilteredServiceTicketsByTenant()
            this.listComponent.clonedList = {}
        });
        this.subscriptionTenant = this.defaults.tenantMessageChanges$.subscribe(() => {
            this.getFilteredServiceTicketsByTenant()
            this.listComponent.clonedList = {}
        });
        if (this.defaults.tenant)
            this.getFilteredServiceTicketsByTenant()
    }

    ngOnDestroy() {
        this.saveService.removeListener(this);
        this.subscriptionStage.unsubscribe();
        this.subscriptionTenant.unsubscribe();
        this.subscriptions.forEach(x => x.unsubscribe())
    }

    getFilteredServiceTicketsByTenant() {
        if (this.subscriptions.length < 1) {
            const filter: ServiceTicketFilter = {
                'UnitIds': this.filterComponent.unitIds,
                'TechnicianNames': this.filterComponent.technicianNames,
                'ServiceRecords': this.filterComponent.serviceRecords
            }
            Utils.httpGetServiceTickets(
                this.http
                , this.baseUrl
                , this.defaults.stage
                , filter
                , this
                , function (tthis: any, list: ServiceTicket[]) {
                    tthis.list = list;
                    tthis.listComponent.clonedList = {}
                    tthis.listComponent.expandedElements = new Set();
                    tthis.saveService.showSaveButton(false)
                    if (filter.UnitIds.length < 1 && filter.TechnicianNames.length < 1 && filter.ServiceRecords.length < 1)
                        tthis.showLocalFilter = false;
                    else
                        tthis.showLocalFilter = true;
                    tthis.subscriptions.pop();
                }
            );
        }
    }
    onReload() {
        this.getFilteredServiceTicketsByTenant();
        this.listComponent.clonedList = {}
        this.saveService.showSaveButton(false)
    }
    downloadServiceTicketFiltered() {
        let filter: ServiceTicketFilter = {
            'UnitIds': this.filterComponent.unitIds,
            'TechnicianNames': this.filterComponent.technicianNames,
            'ServiceRecords': this.filterComponent.serviceRecords
        }
        Utils.httpDownloadServiceTickets(
            this.http
            , this.baseUrl
            , this.defaults.stage
            , this.defaults.tenant
            , filter
            , this
            , null)
    }
}
