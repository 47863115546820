
<div class="example-container new-table-style">
    <table mat-table
           multiTemplateDataRows
           [dataSource]="dataSource"
           class="mat-elevation-z8 mat-table-custom">

        <ng-container matColumnDef="arrow">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element"
                (click)="pushPopElement(element)">
                <span class="material-icons" *ngIf="checkExpanded(element); else show_expand_more">
                    expand_less
                </span>
            </td>

        </ng-container>
        <ng-container matColumnDef="desc">
            <th mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.Description"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="bk">
            <th mat-header-cell *matHeaderCellDef> Business Key</th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.BK" *ngIf="!isEditingRow(element)"></p>
                <mat-form-field floatLabel="never" *ngIf="isEditingRow(element)">
                    <mat-select style="min-width: 200px;" class='form-control filter' placeholder='Select' [(ngModel)]="element.ControllerConfigDescriptionID">
                        <mat-option [value]="-1">Select</mat-option>
                        <mat-option *ngFor="let ccd of descriptions" [value]="ccd.ControllerConfigDescriptionID">
                            {{ ccd.BK }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container matColumnDef="datatype">
            <th mat-header-cell *matHeaderCellDef> DataType </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.DataTypeBK" *ngIf="!isEditingRow(element)"></p>
                <mat-form-field floatLabel="never" *ngIf="isEditingRow(element)">
                    <mat-select style="min-width: 150px;" class='form-control filter' placeholder='Select' [(ngModel)]="element.DataTypeID">
                        <mat-option [value]="-1">Select</mat-option>
                        <mat-option *ngFor="let ccd of datatypes" [value]="ccd.ControllerconfigDataTypeID">
                            {{ ccd.BK }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button *ngIf="!isEditingRow(element)" matTooltip="Edit Row"
                        (click)="onRowEditInit(element)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button *ngIf="isEditingRow(element)" matTooltip="Cancel Row"
                        (click)="onRowEditCancel(element)">
                    <mat-icon>rotate_left</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Delete Row"
                        (click)="onDelete(element)">
                    <mat-icon>delete</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Add New Predefined Item" (click)="openNewPredefinedItemPopup(element.DescriptionBK)">
                    <mat-icon>library_add</mat-icon>
                </button>
            </td>
        </ng-container>


        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                <div [@detailExpand]="checkExpanded(element) ? 'expanded' : 'collapsed'" *ngIf="element.PredefinedItemsDescription">
                    <table mat-table [dataSource]="element.PredefinedItemsDescription" class="mat-subtable-custom" id="predefinedItems">

                        <ng-container matColumnDef="bk">
                            <th mat-header-cell *matHeaderCellDef> BK </th>
                            <td mat-cell *matCellDef="let element2">
                                <p [innerHTML]="element2.BK"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="predefinedItemBK">
                            <th mat-header-cell *matHeaderCellDef> Predefined Item BK </th>
                            <td mat-cell *matCellDef="let element2">
                                <p [innerHTML]="element2.PredefinedItemBK"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="description">
                            <th mat-header-cell *matHeaderCellDef> Description </th>
                            <td mat-cell *matCellDef="let element2">
                                <p [innerHTML]="element2.Description"></p>
                            </td>
                        </ng-container>

                        <tr mat-header-row class="subtableheader" *matHeaderRowDef="subcolumnsToDisplay"></tr>
                        <tr mat-row *matRowDef="let element2; let i2 = index; columns: subcolumnsToDisplay;"
                            class="subitem"></tr>
                    </table>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; let i = dataIndex;
            columns: columnsToDisplay;"
            class="item"
            [class.alternative2]="isEditingRow(element)">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="row-subitem"></tr>

    </table>
</div>

<ng-template #show_expand_more>
    <span class="material-icons">
        expand_more
    </span>
</ng-template>