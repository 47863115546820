import { Component, Inject, OnInit, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Utils } from "../../utils/utils";
import { DialogService } from "../../services/dialog.service";
import { SaveService } from "../../services/save.service";

@Component({
    selector: "operationmode-edit-newlanguage",
    templateUrl: './operationmode-edit-newlanguage.component.html',
    styleUrls: ['../../style/form.component.less']
})

export class OperationModeEditNewLanguageComponent implements OnInit {

    @Input() header: any[] = [];
    @Input() list: OperationMode[] = [];
    @Input() rowIndex: number = -1;
    @Input() newRow: boolean = false;

    MODE: number = 2;
    DESCRIPTION: number = 4;

    //VALUE: number = 1;
    //OUTOFSERVICE: number = 2;
    //MALFUNCTION: number = 3;
    //NOTIFYENTRY: number = 4;
    //NOTIFYEXIT: number = 5;
    //NOTIFYTIME: number = 6;
    

    selected: OperationMode = <OperationMode>{};

    classname: String[] = [];
    isundodisabled: boolean[] = [];


    form!: FormGroup;
    
    constructor(
        private router: Router
        , private http: HttpClient
        , private fb: FormBuilder
        , @Inject('BASE_URL') private baseUrl: string
        , private dialog: DialogService
        , public saveService: SaveService
    ) {
    }

    private onFieldChange(index: number, field1: any, field2: any): boolean {
        return this.saveService.onFieldChange(index, field1, field2, this);
    }

    private onFieldUndo(index: number) {
        this.saveService.onFieldUndo(index, this);
    }

    ngOnInit() {
        this.dialog.tthis = this;
        this.saveService.addListener(this);

        this.saveService.initUndo(this); //it calls the method this.createForm()

        this.selected.Mode = this.list[this.rowIndex].Mode;
        this.selected.Description = this.list[this.rowIndex].Description;
        this.selected.ModeReference = this.list[this.rowIndex].ModeReference;
        this.selected.DescriptionReference = this.list[this.rowIndex].DescriptionReference;
        this.updateForm();

    }

    createForm() {
        this.form = this.fb.group({
            Mode: ["", Validators.required],
            Description: ["", Validators.required],
        });
    }

    onDescriptionChange() {
        if (this.form && this.onFieldChange(this.DESCRIPTION, this.form.value.Description, this.selected.Description) && this.selected)
            this.list[this.rowIndex].Description = this.form.value.Description;
    }

    onDescriptionUndo() {
        this.list[this.rowIndex].Description = this.selected.Description;
        this.onFieldUndo(this.DESCRIPTION);
    }

    onModeChange() {
        if (this.form && this.onFieldChange(this.MODE, this.form.value.Mode, this.selected.Mode) && this.selected)
            this.list[this.rowIndex].Mode = this.form.value.Mode;
    }

    onModeUndo() {
        this.list[this.rowIndex].Mode = this.selected.Mode;
        this.onFieldUndo(this.MODE);
    }

    onRowCancel() {
        this.saveService.fireCancel(this.rowIndex, this);
    }

    onSave() {
        if (this.form && this.form.valid) {
            this.http
                .post<OperationMode>(
                    this.baseUrl + Utils.getOperationModeAPI() + "translate"
                    , this.list[this.rowIndex]
                )
                .subscribe(
                    res => this.list[this.rowIndex] = res
                    , error => {
                        Utils.httpGetOperationMode(
                            this.http
                            , this.baseUrl
                            , this.list[this.rowIndex].Value
                            , this
                            , function (tthis: OperationModeEditNewLanguageComponent, value: OperationMode) {
                                tthis.list[tthis.rowIndex] = value;
                            }
                        );
                        this.dialog.showErrorDialog(error);
                    }
                );
        }
        else
            this.dialog.showErrorStringDialog("Error Saving data!");
    }

    updateForm() {
        if (this.form)
            this.form.setValue({
                Mode: this.list[this.rowIndex].Mode
                , Description: this.list[this.rowIndex].Description
            });
    }

}