import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { DefaultValuesService } from '../../../services/defaultvalues.service';
import { DialogService } from '../../../services/dialog.service';
import { SaveService } from '../../../services/save.service';
import { Utils } from '../../../utils/utils';

@Component({
    selector: 'branchhierarchy-menu',
    templateUrl: './branchhierarchy-menu.component.html',
    styleUrls: ['../../../style/menu.component.less']
})

export class BranchHierarchyMenuComponent {
    //title: string;

    @Output() onReload = new EventEmitter<{}>();
    @Output() newBranch = new EventEmitter<{}>();

    @Output() outputDownload = new EventEmitter<{}>();

    constructor(private activatedRoute: ActivatedRoute,
        private router: Router
        , @Inject('BASE_URL') private baseUrl: string
        , public saveService: SaveService
        , private dialog: DialogService
        , public defaults: DefaultValuesService
    ) {

    }
    onUpload() {
        this.router.navigate(["file/" + Utils.getFileUploadBranchHierarchy()]);
    }
}
