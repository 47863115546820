<div class="custom-mat-dialog-form">
    <button mat-icon-button class="right" [mat-dialog-close]="true">
        <mat-icon>close</mat-icon>
    </button>
    <div>
        <h3>Documentation - {{entityName}}</h3>
    </div>

    <div>
        <mat-form-field class="search-input">
            <input placeholder="Filter"
                   matInput
                   (keyup)="applyFilter($event)">
        </mat-form-field>
        <div class="mt-2 right div-display-flex">
            <button type="button"
                    class="btn btn-primary"
                    (click)="openRestLink()">
                REST
            </button>
            <button type="button"
                    class="btn btn-primary"
                    (click)="downloadWsdlFile()" *ngIf="entity!==3">
                SOAP
            </button>
            <div class="doc-separator"></div>
            <button type="button"
                    class="btn btn-success"
                    (click)="downloadDocFile()">
                Export
            </button>
        </div>
    </div>

    <table mat-table
           [dataSource]="dataSource"
           multiTemplateDataRows
           matSort
           (matSortChange)="sortData($event)"
           class="mat-elevation-z8 mat-table-custom" id="cdpwikilist">

        <ng-container matColumnDef="index-table-wiki">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element; let i = dataIndex">
                <p>{{i+1}}</p>
            </td>
        </ng-container>

        <ng-container matColumnDef="fieldname">
            <th mat-header-cell *matHeaderCellDef> FieldName </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.FieldName"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="ismandatory">
            <th mat-header-cell *matHeaderCellDef> IsMandatory </th>
            <td mat-cell *matCellDef="let element">
                <mat-checkbox [checked]="element.IsMandatory" [disabled]="true"></mat-checkbox>
            </td>
        </ng-container>

        <ng-container matColumnDef="desc">
            <th mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.Description"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef> DataType </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.DataType"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="example">
            <th mat-header-cell *matHeaderCellDef> Example </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.Example"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="updatedat">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="updatedat"> UpdateAt </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.UpdatedAt  | date:'yyyy-MM-dd'"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="lov">
            <th mat-header-cell *matHeaderCellDef> List of Values (LoV) </th>
            <td mat-cell *matCellDef="let element">
                <mat-checkbox [checked]="element.LoV" [disabled]="true">
                    <button mat-icon-button matTooltip="Modify LoV" (click)="openHarmonizationComponent(element.HarmonizationComponent)" *ngIf="element.LoV === true">
                        <mat-icon title="Modify LoV">edit</mat-icon>
                    </button>
                </mat-checkbox>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; let i = dataIndex;
            columns: columnsToDisplay;"
            class="opmode-item">
        </tr>

    </table>
    <div class="mt-2 right">
        <button type="button"
                class="btn btn-danger"
                (click)="onNoClick()">
            Close
        </button>
    </div>
</div>
