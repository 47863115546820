<div class="custom-mat-dialog-form">
    <div>
        <h3>Filter options</h3>
    </div>
    <div>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="mt-2">
                <label>Selector Filter</label>
                <div class="input">
                    <mat-select placeholder="Please select" class='form-control'
                                formControlName="selectorFilter" (selectionChange)="onSelectorChange($event.value)">
                        <mat-option *ngFor="let c of selectors; index as i" value="{{c.value}}">
                            {{c.label}}
                        </mat-option>
                    </mat-select>
                </div>
            </div>
            <div class="mt-2">
                <label>Inclusive filter</label>
                <div class="input">
                    <mat-checkbox formControlName="inclusiveMode">All filters must be present (true) or at least one of them (false)</mat-checkbox>
                </div>
            </div>

            <div class="mt-2 right">
                <button type="button"
                        class="btn btn-danger"
                        (click)="onNoClick()">
                    Cancel
                </button>
                <button type="button"
                        class="btn btn-primary"
                        (click)="onSubmit()">
                    Save
                </button>
            </div>
        </form>
    </div>
</div>
