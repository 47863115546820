<div class="container-tab-lists" *ngIf="form">
    <div class="form-edit" [ngClass]="dialog.classname!">
        <h2>New Language for {{defaults.controllertype.Description}}</h2>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">

            <div class="menu-form">
                <new-language-selector (languageChanged)="onLanguageChanged($event)" [languages]="list"></new-language-selector>
            </div>

            <input id="Language" required
                   formControlName="Language"
                   type="hidden"
                   class="form-control" />


            <div class="form-group commands">
                <button type="submit"
                        [disabled]="form.invalid"
                        class="btn btn-success">
                    New
                </button>
                <button type="button"
                        (click)="onBack()"
                        class="btn btn-warning">
                    Cancel
                </button>
            </div> <!--form-group commands-->
        </form>
    </div>
</div>