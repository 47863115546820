import { Component, OnInit, OnDestroy, Inject, ViewChild } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { DefaultValuesService } from '../../../../services/defaultvalues.service';
import { Utils } from '../../../../utils/utils';
import { SaveService } from '../../../../services/save.service';
import { OtherAssetListComponent } from './otherasset-list.component';
import { OtherAssetFilterComponent } from './otherasset-filter.component';
import { Subscription } from "rxjs";

@Component({
    selector: "otherasset",
    templateUrl: './otherasset.component.html',
})

export class OtherAssetComponent implements OnInit, OnDestroy {

    list: OtherAsset[] = [];

    title = "Other Assets";
    showLocalFilter = false;

    subscriptionStage: any;
    subscriptionTenant: any;
    subscriptions: Subscription[] = [];

    @ViewChild(OtherAssetListComponent, { static: true }) listComponent!: OtherAssetListComponent;
    @ViewChild(OtherAssetFilterComponent, {static:true}) filterComponent!: OtherAssetFilterComponent;

    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string
        , private defaults: DefaultValuesService
        , private saveService: SaveService
    ) { }

    ngOnInit(): void {
        this.subscriptionStage = this.defaults.stageMessageChanges$.subscribe(() => {
            this.getFilteredOtherAssetsByTenant()
            this.listComponent.clonedList = {}
        });
        this.subscriptionTenant = this.defaults.tenantMessageChanges$.subscribe(() => {
            this.getFilteredOtherAssetsByTenant()
            this.listComponent.clonedList = {}
        });
        if (this.defaults.tenant)
            this.getFilteredOtherAssetsByTenant()
    }

    ngOnDestroy() {
        this.saveService.removeListener(this);
        this.subscriptionStage.unsubscribe();
        this.subscriptionTenant.unsubscribe();
        this.subscriptions.forEach(x => x.unsubscribe())
    }

    getFilteredOtherAssetsByTenant() {
        const filter: OtherAssetFilter = {
            'UnitIds': this.filterComponent.unitIds,
            'BranchNumbers': this.filterComponent.branchNumbers,
            'ControllerTypes': this.filterComponent.controllerTypes,
            'BuildingAddr1': this.filterComponent.buildingAddr1,
            'BuildingCities': this.filterComponent.buildingCities,
            'Nicknames': this.filterComponent.nicknames,
            'AssetTypes': [],
            'UnitTypes': []
        }
        Utils.httpGetOtherAssets(
            this.http
            , this.baseUrl
            , this.defaults.stage
            , filter
            , this
            , function (tthis: OtherAssetComponent, list: OtherAsset[]) {
                tthis.list = list;
                tthis.listComponent.clonedList = {}
                tthis.listComponent.expandedElements = new Set();
                tthis.saveService.showSaveButton(false)
                if (filter.Nicknames.length < 1 && filter.BranchNumbers.length < 1 && filter.UnitIds.length < 1
                    && filter.BuildingAddr1.length < 1 && filter.ControllerTypes.length < 1 && filter.BranchNumbers.length < 1)
                    tthis.showLocalFilter = false;
                else
                    tthis.showLocalFilter = true;
                tthis.subscriptions.pop();

            }
        );
    }
    onReload() {
        this.getFilteredOtherAssetsByTenant();
        this.listComponent.clonedList = {}
        this.saveService.showSaveButton(false)
    }
    downloadOtherAssetFiltered() {
        const filter: OtherAssetFilter = {
            'UnitIds': this.filterComponent.unitIds,
            'BranchNumbers': this.filterComponent.branchNumbers,
            'ControllerTypes': this.filterComponent.controllerTypes,
            'BuildingAddr1': this.filterComponent.buildingAddr1,
            'BuildingCities': this.filterComponent.buildingCities,
            'Nicknames': this.filterComponent.nicknames,
            'AssetTypes': [],
            'UnitTypes': []
        }
        Utils.httpDownloadOtherAssets(
            this.http
            , this.baseUrl
            , this.defaults.tenant
            , filter
            , this
            , null)
    }
}
