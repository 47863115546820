import { Component, OnInit, OnDestroy, Inject, ViewEncapsulation } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { DefaultValuesService } from "../../services/defaultvalues.service";
import { Utils } from "../../utils/utils";
import { Subject, Subscription } from "rxjs";
import { SignalRService } from "../../services/signal-r";
import { DialogService } from "../../services/dialog.service";
import { PreregisterMacAddressComponent } from "./preregistermacaddress/preregistermacaddress.component";
import { CustomDialogService } from "../../services/customdialog.service";
import { MatDialog } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "virtualprovisioning",
    templateUrl: 'virtualprovisioning.component.html',
    styleUrls: ['./virtualprovisioning.component.less'],
    encapsulation: ViewEncapsulation.None
})

export class VirtualProvisioningComponent implements OnInit, OnDestroy {

    unitlist: UnitIdToDeviceMapping[] = [];
    alllist: UnitIdToDeviceMapping[] = [];
    macaddressList: string[] = [];
    controllertypesList: string[] = [];
    step: number = -1; // -1 makes all the accordians to be closed. Can replace 0 with -1 to open the required accordion open on page load.
    state: number = -1;
    title = "Virtual Provisioning";
    showLocalFilter = false;

    subscriptionStage: any;
    subscriptionTenant: any;
    subscriptions: Subscription[] = [];

    public filteredMacAddress: string[] = [];

    selectedMacAddress = 'Select'
    messageMacAddress = ''
    unitId = ''
    controllerType = 'Select'

    /** Subject that emits when the component has been destroyed. */
    protected _onDestroy = new Subject<void>();

    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string,
        public defaults: DefaultValuesService,
        public signalRService: SignalRService,
        private confimDialog: DialogService,
        private dialogForm: MatDialog,
        private customDialog: CustomDialogService,
        private toastrService: ToastrService
    ) { }

    ngOnInit(): void {
        this.subscriptionStage = this.defaults.stageMessageChanges$.subscribe(() => {
            this.getFilteredInformation()
        });
        this.subscriptionTenant = this.defaults.tenantMessageChanges$.subscribe(() => {
            this.getFilteredInformation()
        });
        this.getFilteredInformation();
        this.confimDialog.tthis = this;
    }

    ngOnDestroy() {
        this.subscriptions.forEach(x => x.unsubscribe())
        this.subscriptionStage.unsubscribe();
        this.subscriptionTenant.unsubscribe();
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    getFilteredInformation(reload = false) {
        Utils.httpGetAvailableMacAddress(
            this.http
            , this.baseUrl
            , this
            , function (tthis: VirtualProvisioningComponent, list: string[]) {
                list.unshift('Select');
                tthis.macaddressList = list;
                tthis.filteredMacAddress = tthis.macaddressList.slice(0, 200);
            }
        )

        if (reload === false)
            Utils.httpGetControllerTypes(
                this.http
                , this.baseUrl
                , this
                , function (tthis: VirtualProvisioningComponent, list: string[]) {
                    list.unshift('Select');
                    tthis.controllertypesList = list;
                }
            )

        Utils.httpGetUnitIdsToDeviceMapping(
            this.http
            , this.baseUrl
            , this
            , function (tthis: VirtualProvisioningComponent, list: UnitIdToDeviceMapping[]) {
                tthis.alllist = list;
                tthis.unitlist = tthis.alllist.slice(0, 200)
            }
        );
    }

    onUnitIdListReload() {
        Utils.httpGetUnitIdsToDeviceMapping(
            this.http
            , this.baseUrl
            , this
            , function (tthis: VirtualProvisioningComponent, list: UnitIdToDeviceMapping[]) {
                tthis.alllist = list;
                tthis.unitlist = tthis.alllist.slice(0, 200)
            }
        );
    }

    filter(filter: any) {
        this.unitlist = this.alllist.filter(x => x.UnitId.toLowerCase().indexOf(filter.toLowerCase()) > -1
            || x.DeviceId.toLowerCase().indexOf(filter.toLowerCase()) > -1
            || x.AssetType.toLowerCase().indexOf(filter.toLowerCase()) > -1)
            .slice(0, 200)
    }

    loadMacAddresses(filter: any) {
        this.filteredMacAddress = this.macaddressList.filter(item => item.includes(filter.term)).slice(0, 200);
    }

    protected onMacAddressSelect($event) {
        this.selectedMacAddress = $event;
        this.messageMacAddress = `Selected: ${this.selectedMacAddress}`
    }

    protected onControllerTypeSelect($event) {
        this.controllerType = $event;
    }

    protected onUnitIdSelect(unitId) {
        this.unitId = unitId
        this.setState(-1)
    }

    getMessageMacAddress() {
        return "     - Selected: " + this.selectedMacAddress;
    }

    getMessageUnitId() {
        return "     - Selected: " + this.unitId;
    }

    getMessageControllerType() {
        return "     - Selected: " + this.controllerType;
    }

    createProvisioningRequest() {
        window.scroll(0, 0);
        if (this.selectedMacAddress === 'Select' ||
            this.controllerType === 'Select' ||
            this.unitId === '') {
            this.confimDialog.showErrorDialog('Please provide all information before clicking on Provision Unit (Regisration Key, Mac Address, Controller Type and Unit Id are mandatory fields.)')
            return;
        }
        Utils.httpGetIsManyToOneProvisioning(
            this.http
            , this.baseUrl
            , this.selectedMacAddress
            , this
            , function (tthis: VirtualProvisioningComponent, result: boolean) {
                if (result === true) {
                    tthis.customDialog.openConfirm({
                        title: "Provisioning Process",
                        message: `You are going to perform a many to one provision. Do you want to continue?`,
                        caller: tthis
                    });
                    tthis.customDialog.confirmed().subscribe(res => {
                        if (res.confirmed === true) {
                            tthis.fireProvisioningRequest(true)
                        }
                    })
                }
                else {
                    tthis.fireProvisioningRequest(false)
                }
            }
        );
        return;
    }


    fireProvisioningRequest(isManyToOne: boolean) {
        const body = {
            'MacAddress': this.selectedMacAddress,
            'ControllerType': this.controllerType,
            'UnitId': this.unitId
        }
        this.signalRService.startConnection(1).then(() => {
            this.signalRService.getTraceListener()
            this.signalRService.finishConnection(
                this,
                function (tthis: VirtualProvisioningComponent, message: string) {
                    if (message.indexOf('Error') < 0) {
                        tthis.toastrService.success(`Provisioning finished. MacAddress: [${body.MacAddress}], UnitId: [${body.UnitId}], Controller Type: [${body.ControllerType}]`)
                        tthis.getFilteredInformation(true);
                        tthis.unitId = ''
                        tthis.selectedMacAddress = tthis.controllerType = 'Select'
                    }
                    else {
                        tthis.toastrService.error(`${message}. MacAddress: [${body.MacAddress}], UnitId: [${body.UnitId}], Controller Type: [${body.ControllerType}]`)
                    }
                });
            this.signalRService.initNewProvision(body, this.defaults.stage, isManyToOne);
            return;
        });
    }

    openPreregisterMacAddressPopup() {
        const dialog = this.dialogForm.open(PreregisterMacAddressComponent, {
            panelClass: ['custom-mat-dialog'],
            disableClose: true,
            data: {},
        })
        dialog.afterClosed().subscribe(() => {
            Utils.httpGetAvailableMacAddress(
                this.http
                , this.baseUrl
                , this
                , function (tthis: VirtualProvisioningComponent, list: string[]) {
                    list.unshift('Select');
                    tthis.macaddressList = list;
                    tthis.filteredMacAddress = tthis.macaddressList.slice(0, 200);
                }
            )
        })
    }

    setState(stateNumb: number) {
        this.state = stateNumb;
    }
}
