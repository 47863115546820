import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from '../../../../../../services/dialog.service';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup } from '@angular/forms';
import { Utils } from '../../../../../../utils/utils';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'assignwaveexcellencelever-popup',
    templateUrl: './assignwaveexcellencelever-popup.component.html',
    styleUrls: ['./assignwaveexcellencelever-popup.component.less',
        '../../../../../../style/new-generic-styles.component.less'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*', })),
            transition('expanded => collapsed', [animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
        ]),
    ]
})
export class AssignWaveExcellenceLeverPopupComponent implements OnInit {

    public dataSource = new MatTableDataSource<any>();

    excellenceLevers: ExcellenceLever[] = []
    form = new FormControl();

    columnsToDisplay = ['selector', 'Lever', 'Function', 'LineOfBusiness'];
    //subcolumnsToDisplay = ['Lever', 'Description'];
    expandedElements = new Set();

    selectedList: number[] = []
    selection = new SelectionModel<any>(true, []);

    constructor(
        private http: HttpClient
        , public dialogRef: MatDialogRef<AssignWaveExcellenceLeverPopupComponent>
        , private confimDialog: DialogService
        , private toastrService: ToastrService,
        @Inject('BASE_URL') private baseUrl: string,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit(): void {
        this.getUnassignedExcellenceLevers()
    }

    getUnassignedExcellenceLevers() {
        Utils.httpGetUnassignedWaveExcellenceLeverConfigurations(
            this.http,
            this.baseUrl,
            this.data.WaveId,
            this,
            function (tthis: AssignWaveExcellenceLeverPopupComponent, list: any) {
                console.log(list)
                tthis.excellenceLevers = list
                tthis.dataSource.data = list
            },
            null

        )
    }

    backButtonHandler(event: any) {
        event.preventDefault();
        this.dialogRef.close();

    }
    addButtonHandler() {
        if (this.form)
            if (this.form.invalid) {
                return;
            }

        Utils.httpAssignWaveExcellenceLeverConfigurations(
            this.http
            , this.baseUrl
            , this.data.WaveId
            , this.selectedList
            , this
            , function (tthis: AssignWaveExcellenceLeverPopupComponent, result: any) {
                tthis.dialogRef.close({ event: 'submit' });
                if (result) {
                    tthis.toastrService.success('Excellence Levers were assigned successfully!');
                }
                else
                    tthis.toastrService.error('Excellence Levers not be assigned to the Wave. Please, contact with administrator');
            },
            null
        );
    }

    checkExpanded(element) {
        return this.expandedElements.has(element);
    }
    pushPopElement(element) {
        if (this.expandedElements.has(element)) {
            this.expandedElements.delete(element);
        }
        else {
            this.expandElement(element)
            if (!element.subtable) {
                this.createMatSubtable(element)
            }
        }
    }
    private expandElement(el: any) {
        this.expandedElements.add(el);
    }

    private createMatSubtable(el: any) {
        let dataSource = new MatTableDataSource<any>();
        dataSource.data = el.ExcellenceLevers
        el.subtable = dataSource
    }

    expandedItemSelected(ischecked, id: number) {
        if (ischecked) {
            this.selection.select(id)
            this.onSelect(id);
        } else {
            this.selection.deselect(id)
            this.onDeselect(id);
        }
    }

    onSelect(selectedReleaseRow) {
        //this.selectedList.push(selectedReleaseRow)

        this.selectedList.push(selectedReleaseRow)

    }
    onDeselect(selectedReleaseRow) {
        const index = this.selectedList.findIndex((object) => object === selectedReleaseRow);
        if (index !== -1) {
            this.selectedList.splice(index, 1);
        }
    }
    accountItemsSelected(ischecked, row) {
        this.expandedItemSelected(ischecked, row.ExcellenceLeverId)

    }

    objectsEqual(object1, object2) {
        if (object1 === object2) {
            return true;
        }
        if (Object.keys(object1).length !== Object.keys(object2).length) {
            return false;
        }
        for (const property in object1) {
            if (object1[property] !== object2[property]) {
                return false;
            }
        }
        return true;
    }
}
