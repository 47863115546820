import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import "rxjs/operators";
import { Data, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Utils } from '../../utils/utils';
import { DialogService } from '../../services/dialog.service';
import { DefaultValuesService } from '../../services/defaultvalues.service';
import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';


@Component({
    selector: 'testcase-execute',
    templateUrl: './testcase-execute.component.html',
    styleUrls: ['../../style/custom-mat-dialog.component.less', '../../style/mat-select.component.less', './testcase-execute.component.less']
})


export class TestCaseExecuteComponent implements OnInit {
    testCase: TestCase = {
        Name: '',
        Description: '',
        BK: '',
        Area: '',
        MinutesToWait: 0,
        TestCaseID: 0,
        TestAreaID: 0,
        IsITProcessRelevant: false,
        ControllerTypeID: 0,
        Messages: []
    };
    deviceList: Elevator[] = [];
    unitId = ''
    version = 1;
    form: FormGroup
    submitted = false;
    schemaVersionList = [{ label: "Sv1", value: 1 }];
    body!: ExecuteTestCaseBody;
    link = ''
    url = "https://stable-dsc.max.tkelevator.com/twin-indicator?tenantId={{0}}&unitId={{1}}&deviceId={{2}}"

    executed = false;

    timeoutFunction: any;

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [COMMA, TAB, ENTER];

    showFilter = true;

    constructor(private http: HttpClient
        , private router: Router
        , @Inject(MAT_DIALOG_DATA) public data: any
        , @Inject('BASE_URL') private baseUrl: string
        , public dialogRef: MatDialogRef<TestCaseExecuteComponent>
        , private fb: FormBuilder
        , private formBuilder: FormBuilder
        , private confimDialog: DialogService
        , public defaults: DefaultValuesService) {


        this.form = this.formBuilder.group({
            unitId: ['', [Validators.required, Validators.minLength(1)]],
            version: [this.schemaVersionList[0].value, [Validators.required]]
        });
    }

    ngOnInit(): void {
        this.testCase = this.data.testCase as TestCase;
    }
    onShowFilter($event) {
        $event.preventDefault()
        this.showFilter = !this.showFilter
    }
    applyFilter($event) {
        if (this.timeoutFunction) {
            clearTimeout(this.timeoutFunction);
            this.timeoutFunction = null;
        }
        this.timeoutFunction = setTimeout(() => {
            Utils.httpGetAllDevices(
                this.http
                , this.baseUrl
                , $event.target.value.trim()
                , this
                , function (tthis: TestCaseExecuteComponent, list: Elevator[]) {
                    tthis.deviceList = list
                })
        }, 1000)
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.form)
            if (this.form.invalid) {
                return;
            }

        const deviceId = this.deviceList.filter(x => x.UnitID === this.unitId)[0].DeviceId
        this.body = {
            'DeviceId': deviceId,
            'UnitId': this.unitId,
            'TestCaseID': this.testCase.TestCaseID,
            'Version': this.version
        }

        const url = this.baseUrl + Utils.getTestCaseAPI() + "execute";
        this.http.post<boolean>(url, this.body).subscribe(result => {
            if (result) {
                this.executed = true;
                this.link = this.url.replace('{{0}}', this.defaults.tenant).replace('{{1}}', this.unitId).replace('{{2}}', deviceId)
            }
            else {
                this.onNoClick();
                this.confimDialog.showErrorDialog({ 'error': { 'message': "Sorry your device is still running a previous test case. Wait until it finishes." } });
            }
        }, error => {
            this.confimDialog.showErrorDialog({ 'error': { 'message': "Internal Server Error. Check test case contains at least one event. In case of having a event, please contact with administrator." } });
            this.onNoClick();
            Utils.writeLog(this.http, this.baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    onBack() { }

    openDscLink() {
        window.open(this.link, "_blank");
    }
}


