<div class="example-container-kpimanagement black-checkbox new-table-style">

    <table mat-table
           [dataSource]="dataSource"
           multiTemplateDataRows
           matSort
           class="mat-elevation-z8 mat-table-custom" id="MarketSheetList">

        <!--</ng-container>-->
        <ng-container matColumnDef="arrow">
            <th mat-header-cell *matHeaderCellDef>
                <mat-icon *ngIf="!isAllCollapsed" (click)="collapseOrExpandAll()">expand_more</mat-icon>
                <mat-icon *ngIf="isAllCollapsed" (click)="collapseOrExpandAll()">expand_less</mat-icon>
            </th>
            <td mat-cell *matCellDef="let account"
                (click)="pushPopElement(account)">
                <ng-container>
                    <span class="material-icons"
                          *ngIf="checkExpanded(account); else show_expand_more">
                        expand_less
                    </span>
                </ng-container>
            </td>
        </ng-container>

        <ng-container matColumnDef="Account">
            <th mat-header-cell *matHeaderCellDef> Account </th>
            <td mat-cell *matCellDef="let account">
                <mat-checkbox *ngIf="isReleaseActive || isRevokeActive" (click)="$event.stopPropagation()"
                              (change)="accountItemsSelected($event.checked,account);"
                              [checked]="accountCheckboxCheck(account)"
                              [indeterminate]="accountItemsIndetermine(account) && !accountCheckboxCheck(account)"
                              [disabled]="accountItemsDisable(account)">
                    <p [innerHTML]="account.AccountID | bold: dataSource.filter"></p>
                </mat-checkbox>
                <p *ngIf="!isReleaseActive && !isRevokeActive" [innerHTML]="account.AccountID | bold: dataSource.filter"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="KpiName">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let account">
                <p [innerHTML]="account.KpiName | bold: dataSource.filter"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="KpiPlan">
            <th mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let account">
                <p [innerHTML]="account.KpiDescription | bold: dataSource.filter"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="KpiOwner">
            <th mat-header-cell *matHeaderCellDef> Owner </th>
            <td mat-cell *matCellDef="let account">
                <p [innerHTML]="account.KpiOwner | bold: dataSource.filter"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="KpiUnit">
            <th mat-header-cell *matHeaderCellDef>
                KpiUnit
                <mat-select [(value)]="selectorKpiUnitFilter" class='form-control' style="min-width: 40%; max-width: 61%; margin-left: 25px" (selectionChange)="filterKpiUnit($event)">
                    <mat-option value="0">Values (in thousands)</mat-option>
                    <mat-option value="1">Units</mat-option>
                </mat-select>
            </th>
            <td mat-cell *matCellDef="let account">
                <p [innerHTML]="account.KpiUnit  | bold: dataSource.filter"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="LocalCurrency">
            <th mat-header-cell *matHeaderCellDef> Local Currency </th>
            <td mat-cell *matCellDef="let account">
                <p *ngIf="account.KpiUnit === 'Values' || account.KpiUnit === 'Value LC'" [innerHTML]="account.LocalCurrency"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="AvailabilityTime">
            <th mat-header-cell *matHeaderCellDef> Availability Time </th>
            <td mat-cell *matCellDef="let account">
                <p [innerHTML]="account.AvailabilityTime"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="ReportingType">
            <th mat-header-cell *matHeaderCellDef> Reporting Type </th>
            <td mat-cell *matCellDef="let account">
                <p [innerHTML]="account.ReportingType"></p>
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let account" [attr.colspan]="columnsToDisplay.length">
                <div [@detailExpand]="checkExpanded(account) ? 'expanded' : 'collapsed'"
                     id="sublist">
                    <table mat-table
                           [dataSource]="account.subtable"
                           class="mat-subtable-custom"
                           [ngClass]="account.maxBreakdown === 'COL4' ? 'details_four_breakdown' :
                                            account.maxBreakdown === 'COL3' ? 'details_three_breakdown' :
                                                    account.maxBreakdown === 'COL2' ? 'details_two_breakdown' :
                                                        account.maxBreakdown === 'COL1' ? 'details_one_breakdown' : 'details_no_breakdowns'">


                        <ng-container matColumnDef="ItemNo">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let breakdown;index as i;">
                                <p [innerHTML]="(i+1)"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="COL1">
                            <th mat-header-cell *matHeaderCellDef> <p [innerHTML]="account.COL1_NAME"></p></th>
                            <td mat-cell *matCellDef="let breakdown"
                                (click)="expandedItemSelected(!selection.isSelected(breakdown),breakdown)"
                                [ngClass]="{'clickable-cursor': isReleaseActive && canBeReleased(breakdown) || isRevokeActive}">
                                <div class="defCheckbox">

                                    <mat-checkbox *ngIf="isReleaseActive  || isRevokeActive"
                                                  (click)="$event.stopPropagation();"
                                                  (change)="expandedItemSelected($event.checked,breakdown)"
                                                  [checked]="selection.isSelected(breakdown)"
                                                  [disabled]="!(isReleaseActive ? (breakdown.ApprovalStatus == 'NEW' && canBeReleased(breakdown)) : (breakdown.ApprovalStatus == 'RELEASED'))">
                                    </mat-checkbox>
                                    <p [innerHTML]="breakdown.COL1 | bold: dataSource.filter"></p>
                                </div>

                            </td>
                        </ng-container>

                        <ng-container matColumnDef="COL2">
                            <th mat-header-cell *matHeaderCellDef> <p [innerHTML]="account.COL2_NAME"></p></th>
                            <td mat-cell *matCellDef="let breakdown">
                                <p [innerHTML]="breakdown.COL2 | bold: dataSource.filter"></p>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="COL3">
                            <th mat-header-cell *matHeaderCellDef> <p [innerHTML]="account.COL3_NAME"></p></th>
                            <td mat-cell *matCellDef="let breakdown">
                                <p [innerHTML]="breakdown.COL3 | bold: dataSource.filter"></p>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="COL4">
                            <th mat-header-cell *matHeaderCellDef> <p [innerHTML]="account.COL4_NAME"></p></th>
                            <td mat-cell *matCellDef="let breakdown">
                                <p [innerHTML]="breakdown.COL4 | bold: dataSource.filter"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="FY">
                            <th mat-header-cell *matHeaderCellDef> {{currentFY}} {{account.KpiUnit}} </th>
                            <td mat-cell *matCellDef="let breakdown" matTooltip="{{cellTolltipValue(account.KpiUnit)}}" [ngClass]="{'released': breakdown.ApprovalStatus === 'RELEASED' }">
                                <p [innerHTML]="breakdown.PYBUD" [style.display]="isEditModeEnabled === false || breakdown.ApprovalStatus === 'RELEASED' ? 'block' : 'none'"></p>
                                <input matInput #miInput type="number" min="0"
                                       (keydown)="disableArrowKeys($event)"
                                       [ngClass]="{'hidden': isEditModeEnabled === false || breakdown.ApprovalStatus === 'RELEASED' }" [value]="breakdown.PYBUD" [(ngModel)]="breakdown.PYBUD"
                                       (ngModelChange)="onChange(breakdown,account, 'FY', $event)">
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="YoYFY">
                            <th mat-header-cell *matHeaderCellDef> {{currentFY}} YoY Growth</th>
                            <td mat-cell *matCellDef="let breakdown">
                                <p [innerHTML]="getYoYValue(breakdown.PY1BUD, breakdown.PYBUD)"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="%FY">
                            <th mat-header-cell *matHeaderCellDef> {{currentFY}} in %</th>
                            <td mat-cell *matCellDef="let breakdown">
                                <p [innerHTML]="getPercentageValue(account, breakdown, 'PYBUD')"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="FY1">
                            <th mat-header-cell *matHeaderCellDef> {{FY1}} {{account.KpiUnit}} </th>
                            <td mat-cell *matCellDef="let breakdown" matTooltip="{{cellTolltipValue(account.KpiUnit)}}" [ngClass]="{'released': breakdown.ApprovalStatus === 'RELEASED' }">
                                <p [innerHTML]="breakdown.FY1BUD" [style.display]="isEditModeEnabled === false || breakdown.ApprovalStatus === 'RELEASED'  ? 'block' : 'none'"></p>
                                <input matInput #miInput type="number" min="0"
                                       (keydown)="disableArrowKeys($event)"
                                       [ngClass]="{'hidden': isEditModeEnabled === false || breakdown.ApprovalStatus === 'RELEASED' }" [value]="breakdown.FY1BUD" [(ngModel)]="breakdown.FY1BUD"
                                       (ngModelChange)="onChange(breakdown,account, 'FY1', $event)">
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="YoYFY1">
                            <th mat-header-cell *matHeaderCellDef> {{FY1}} YoY Growth </th>
                            <td mat-cell *matCellDef="let breakdown">
                                <p [innerHTML]="getYoYValue(breakdown.PYBUD, breakdown.FY1BUD)"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="%FY1">
                            <th mat-header-cell *matHeaderCellDef> {{FY1}} in % </th>
                            <td mat-cell *matCellDef="let breakdown">
                                <p [innerHTML]="getPercentageValue(account, breakdown, 'FY1BUD')"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="FY2">
                            <th mat-header-cell *matHeaderCellDef> {{FY2}} {{account.KpiUnit}} </th>
                            <td mat-cell *matCellDef="let breakdown" matTooltip="{{cellTolltipValue(account.KpiUnit)}}" [ngClass]="{'released': breakdown.ApprovalStatus === 'RELEASED' }">
                                <p [innerHTML]="breakdown.FY2BUD" [style.display]="isEditModeEnabled === false || breakdown.ApprovalStatus === 'RELEASED' ? 'block' : 'none'"></p>
                                <input matInput #miInput type="number" min="0"
                                       (keydown)="disableArrowKeys($event)"
                                       [ngClass]="{'hidden': isEditModeEnabled === false || breakdown.ApprovalStatus === 'RELEASED' }" [value]="breakdown.FY2BUD" [(ngModel)]="breakdown.FY2BUD"
                                       (ngModelChange)="onChange(breakdown,account, 'FY2', $event)">
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="YoYFY2">
                            <th mat-header-cell *matHeaderCellDef> {{FY2}} YoY Growth </th>
                            <td mat-cell *matCellDef="let breakdown">
                                <p [innerHTML]="getYoYValue(breakdown.FY1BUD, breakdown.FY2BUD)"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="%FY2">
                            <th mat-header-cell *matHeaderCellDef> {{FY2}} in % </th>
                            <td mat-cell *matCellDef="let breakdown">
                                <p [innerHTML]="getPercentageValue(account, breakdown, 'FY2BUD')"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="FY3">
                            <th mat-header-cell *matHeaderCellDef>{{FY3}} {{account.KpiUnit}} </th>
                            <td mat-cell *matCellDef="let breakdown" matTooltip="{{cellTolltipValue(account.KpiUnit)}}" [ngClass]="{'released': breakdown.ApprovalStatus === 'RELEASED' }">
                                <p [innerHTML]="breakdown.FY3BUD" [style.display]="isEditModeEnabled === false || breakdown.ApprovalStatus === 'RELEASED' ? 'block' : 'none'"></p>
                                <input matInput #miInput type="number" min="0"
                                       (keydown)="disableArrowKeys($event)"
                                       [ngClass]="{'hidden': isEditModeEnabled === false || breakdown.ApprovalStatus === 'RELEASED' }" [value]="breakdown.FY3BUD" [(ngModel)]="breakdown.FY3BUD"
                                       (ngModelChange)="onChange(breakdown,account, 'FY3', $event)">
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="YoYFY3">
                            <th mat-header-cell *matHeaderCellDef> {{FY3}} YoY Growth </th>
                            <td mat-cell *matCellDef="let breakdown">
                                <p [innerHTML]="getYoYValue(breakdown.FY2BUD, breakdown.FY3BUD)"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="%FY3">
                            <th mat-header-cell *matHeaderCellDef> {{FY3}} in % </th>
                            <td mat-cell *matCellDef="let breakdown">
                                <p [innerHTML]="getPercentageValue(account, breakdown, 'FY3BUD')"></p>
                            </td>
                        </ng-container>

                        <!-- <ng-container matColumnDef="actions">
                                    <th mat-header-cell *matHeaderCellDef></th>
                                    <td mat-cell *matCellDef="let breakdown">
                                         <button *ngIf="formatCurrency(breakdown.Value, breakdown.DecimalDigits) !== '' && breakdown.ApprovalStatus !== ''" mat-icon-button matTooltip="Get History" (click)="onDetail(breakdown)">
                            <mat-icon>history</mat-icon> <span>Get History</span>
                        </button>
                                    </td>
                                </ng-container> -->
                        <tr mat-header-row class="subtableheader" *matHeaderRowDef="account.columns"></tr>
                        <tr mat-row *matRowDef="let row2; let i2 = index; columns: account.columns;"
                            class="subitem"
                            [class.alternative2]="isEditModeEnabled === true"></tr>
                    </table>
                </div>
            </td>
        </ng-container>


        <tr mat-header-row class="tableheader" *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let account; let i = dataIndex; columns: columnsToDisplay;"
            class="item"
            [class.example-expanded-row]="checkExpanded(account)">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="row-subitem"></tr>

    </table>

</div>

<ng-template #show_expand_more>
    <span class="material-icons">
        expand_more
    </span>
</ng-template>

<div *ngIf="isReleaseActive || isRevokeActive" class="stickypanel_KPI ">
    <div *ngIf="selection.hasValue()" class="col-lg-6" style="width:50%">
        Total selected breakdowns : {{selection.selected.length}}
    </div>
    <div *ngIf="!selection.hasValue()" class="col-lg-6" style="width:50%">
        Please select at least one Kpi / breakdown to {{isReleaseActive? 'Release' : 'Revoke'}} Selective
    </div>
    <div class="col-lg-6">
        <button class="btn btn-realease btn-sm pull-right button-margin" [disabled]="!anyActionableItemExist()" (click)="isReleaseActive ? onClickReleaseMode_emitter():onClickRevokeMode_emitter()">
            {{ textRelRevbuttonState() }}
        </button>
    </div>
</div>
