<div class="custom-mat-dialog-form non-overflow">
    <h3 mat-dialog-title>{{title}}</h3>
    <form [formGroup]="form">

        <div class="mt-2">
            <label>{{header[DEVICEID].label}}</label>
            <div class="input">
                <input type="text" name="DeviceId" class="form-control" required formControlName="DeviceId"
                       title="{{header[DEVICEID].label}}">
            </div>
        </div>
        <div class="mt-2">
            <label>{{header[DATEFROM].label}}:</label>
            <div class="input">
                <input type="datetime-local" class="form-control" formControlName="DateFrom" />
            </div>
        </div>
        <div class="mt-2">
            <label>{{header[DATETO].label}}:</label>
            <div class="input">
                <input type="datetime-local" class="form-control" formControlName="DateTo" />
            </div>
        </div>
        <div>
            <div class="mt-2 right">
                <button class="btn btn-primary" [disabled]="!form.valid" (click)="onSubmit()">Save</button> <!--[mat-dialog-close]="1"-->
                <button class="btn btn-danger" (click)="onCancelForm()" tabindex="-1">Cancel</button>
            </div>
        </div>

    </form>
</div>