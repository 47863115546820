
<ng-container *ngIf="showLocalFilter">
    <mat-form-field class="search-input">
        <input placeholder="Filter"
               matInput
               [matChipInputFor]="chipList"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
               [matChipInputAddOnBlur]="addOnBlur"
               (matChipInputTokenEnd)="applyFilter($event)">
        <mat-chip-list #chipList>
        </mat-chip-list>
    </mat-form-field>
</ng-container>
<mat-chip *ngFor="let item of searchItems" [selectable]="selectable"
          [removable]="removable" (removed)="remove(item)">
    {{item.name}}
    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
</mat-chip>
<div class="example-container">
    <table mat-table
           [dataSource]="dataSource"
           multiTemplateDataRows
           matSort
           class="mat-elevation-z8 mat-table-custom" id="customerfacilitymanagerlist">
        <ng-container *ngFor="let item of columnsToDisplay" matColumnDef="{{item}}">
            <th mat-header-cell *matHeaderCellDef>
                {{item === 'actions' || item === 'status' || item === 'index' ? '' : item}}
            </th>
            <ng-container matColumnDef="index-table" *ngIf="item === 'index'">
                <td mat-cell *matCellDef="let element; let i = dataIndex">
                    <p>{{i+1}}</p>
                </td>
            </ng-container>
            <ng-container *ngIf="item==='actions'">
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button *ngIf="!isEditing(element, '')" matTooltip="Edit Row"
                            (click)="onRowEditInit(element)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button *ngIf="isEditing(element, '')" matTooltip="Cancel Row"
                            (click)="onRowEditCancel(element)">
                        <mat-icon>rotate_left</mat-icon>
                    </button>
                </td>
            </ng-container>
            <ng-container *ngIf="item!=='actions'">
                <ng-container *matCellDef="let element" [ngSwitch]="getDataType(item)">
                    <td mat-cell *ngSwitchCase="'boolean'">
                        <mat-checkbox *ngSwitchCase="'boolean'" (change)="changeElement(element, item, $event.checked)" [checked]="getInput(element,item)" [ngClass]="isEditing(element, item) ? 'black' :''" [disabled]="!isEditing(element, item)"></mat-checkbox>
                    </td>
                    <td mat-cell *ngSwitchCase="'string'">
                        <p *ngIf="!isEditing(element, item)" [innerHTML]="getInput(element,item)"></p>
                        <mat-form-field floatLabel="never" *ngIf="isEditing(element, item)">
                            <input matInput [value]="element[item]" [(ngModel)]="element[item]">
                        </mat-form-field>
                    </td>
                    <td mat-cell *ngSwitchCase="'number'">
                        <p *ngIf="!isEditing(element, item)" [innerHTML]="getInput(element,item)"></p>
                        <mat-form-field floatLabel="never" *ngIf="isEditing(element, item)">
                            <input matInput type="number" [value]="element[item]" [(ngModel)]="element[item]">
                        </mat-form-field>
                    </td>
                    <td mat-cell *ngSwitchCase="'date'" class="datepicker">
                        <p *ngIf="!isEditing(element, item)" [innerHTML]="getInput(element,item) | date:'yyyy-MM-dd HH:mm'"></p>
                        <mat-form-field *ngIf="isEditing(element, item)">
                            <input matInput [matDatepicker]="picker" [(ngModel)]="element[item]">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </td>
                </ng-container>
            </ng-container>
        </ng-container>
        <tr mat-header-row class="tableheader" *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; let i = dataIndex; columns: columnsToDisplay;"
            class="item"
            [class.alternative2]="isEditing(element, '')">
    </table>
</div>


<ng-template #show_expand_more>
    <span class="material-icons" matTooltip="More Details">
        expand_more
    </span>
</ng-template>