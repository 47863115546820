import { Component, Inject, Input, OnInit, Output, EventEmitter, TemplateRef, OnChanges, ViewChild, ViewChildren, QueryList } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Utils } from "../../utils/utils";
import { DialogService } from "../../services/dialog.service";
import { SaveService } from "../../services/save.service";
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CustomDialogService } from '../../services/customdialog.service';
import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';
import { DefaultValuesService } from "../../services/defaultvalues.service";
import { FPRuleDefinitionFormComponent } from "./fp_rule_definition-form.component";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { MatSelect } from "@angular/material/select";
import { MatDialog } from "@angular/material/dialog";
import { MatChipInputEvent } from "@angular/material/chips";


@Component({
    selector: "fp_rule_definition-list",
    templateUrl: './fp_rule_definition-list.component.html',
    styleUrls: ['../../style/mat-table.component.less', 'fp_rule_definition-list.component.less', '../../style/menu.component.less'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*', })),
            transition('expanded => collapsed', [animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
        ]),
    ]
})

export class FPRuleDefinitionComponent implements OnInit, OnChanges {
    @Input() equals: any | undefined;
    @Input() reload: boolean | undefined

    @Input() newService: SaveService | undefined;
    @Input() rowExpandTemplate: TemplateRef<any> | undefined;
    @Input('list') list: ErrorCode[] = [];

    @Input('keywords') keywords: string[] = [];

    @Output() checkedEvent = new EventEmitter<{ Index: number, Value: any, Checked: boolean, List: boolean[], NumberOfChecks: number, FirstCheckedIndex: number }>();
    @Output() eventFilter = new EventEmitter<ErrorCode[]>();
    @Output() onUpdateTable = new EventEmitter<{}>();

    selected: ErrorCode = <ErrorCode>{};

    errorWeights: number[] = [0, 1, 2, 3];
    resolWeights: number[] = [0.00, 0.25, 0.50, 0.75, 1.00, 2.00, 3.00];

    columnsToDisplay = ['arrow', 'arrow-devices', 'errorcode', 'description', 'nresolutions', 'iswarning', 'errorweight', 'controllerblocked', 'stablealertrule', 'prodalertrule', 'actions'];
    subcolumnsToDisplay = ['FingerprintFeature', 'ThresholdType', 'ThresholdValue', 'ChangedAtUtc', 'actions'];

    desSubcolumns = ['deviceName', 'sentByDevice'];
    show = false;
    @ViewChild(MatTable, { static: true }) table!: MatTable<any>;

    public clonedList: { [s: string]: ErrorCode; } = {};
    public clonedResolutionList: { [s: string]: ErrorCodeResolution } = {};
    public newResolutionList: number[] = []

    locations: ComponentSubSystem[] = [];
    components: ComponentItem[] = [];
    causes: BusinessKeySon[] = [];
    resolutions: BusinessKeySon[] = [];

    alertRules: AlertRule[] = [];

    public dataSource = new MatTableDataSource<ErrorCode>();

    expandedElements = new Set();
    expandedDesDevices = new Set();
    expanded: boolean[] = [];

    newChildService: SaveService | undefined;

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [COMMA, TAB, ENTER];
    searchItems: SearchItem[] = [];

    weightSelected = -1;
    controllerBlockedSelected = '-1'

    filters: ColumnFilter = { 'stablealertruleid': -1, 'prodalertruleid': -1 };

    subscriptionLanguage: any;
    subscriptionTenant: any;

    //////////////////
    // Keyword section
    showKeywords = -1;
    selectedErrorCode = -1
    isShowingKeywordsSelector = false;
    @ViewChildren(MatSelect, { read: MatSelect }) items: QueryList<MatSelect> | undefined;
    caret = 0;

    //header: any[] = [
    //    { label: 'Error Code', show: true, searchtype: 'number', expanded: false, index: 0 }
    //    , { label: 'Op Mode', show: true, searchtype: 'text', expanded: false, index: 1 }
    //    , { label: 'Building type', show: true, searchtype: 'number', expanded: false, index: 2 }
    //    , { label: 'Trafic Pattern', show: true, searchtype: 'number', expanded: false, index: 3 }
    //    , { label: 'Is Warning', show: true, searchtype: 'checkbox', checkbox: true, expanded: false, index: 4 }
    //    , { label: 'Stable Alert Rule', show: true, searchtype: 'text', checkbox: true, title: "Stable Alert Rule", index: 5 }
    //    , { label: 'Prod Alert Rule', show: true, searchtype: 'text', checkbox: true, title: "Prod Alert Rule", index: 6 }
    //];

    constructor(
        private activatedRoute: ActivatedRoute
        , private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , private customDialog: CustomDialogService
        , private dialog: DialogService
        , private saveService: SaveService
        , public defaults: DefaultValuesService
        , private dialogForm: MatDialog
    ) {
    }


    ngOnChanges() {
        this.dataSource.data = this.list;
        this.saveService.addListener(this);
    }

    ngOnInit() {
        let url2 = this.baseUrl + Utils.getAPIFingerPrint() + 'fp-ruledefinition';
        this.http.get<ErrorCode[]>(url2).subscribe(result => {
            this.list = result;
            this.ngOnChanges();
        }); 

        this.subscriptionLanguage = this.defaults.languageMessageChanges$.subscribe(() => {
            this.getHeader()
        });
        this.subscriptionTenant = this.defaults.tenantMessageChanges$.subscribe(() => {
            this.getHeader()
        });
        Utils.httpGetAllLocations(
            this.http
            , this.baseUrl
            , this
            , function (tthis: any, list: any[]) {
                tthis.locations = list;
                Utils.httpGetAllCauses(
                    tthis.http
                    , tthis.baseUrl
                    , tthis
                    , function (tthis: any, list: ComponentSubSystem[]) {
                        tthis.causes = list;
                        Utils.httpGetAllResolutions(
                            tthis.http
                            , tthis.baseUrl
                            , tthis
                            , function (tthis: any, list: ComponentSubSystem[]) {
                                tthis.resolutions = list;
                            }
                        );
                    }
                );
            }
        );
        this.getHeader();
        this.saveService.addListener(this);
        this.newChildService = new SaveService();
        this.newChildService.addListener(this);
        if (this.newService) this.newService.addListener(this)
        if (!this.alertRules || this.alertRules.length === 0) {
            Utils.httpGetAllAlertRules(
                this.http
                , this.baseUrl
                , this
                , function (tthis: FPRuleDefinitionComponent, list: AlertRule[]) {
                    tthis.alertRules = list;
                }
            );
        }


        this.dataSource.filterPredicate = (data: any, filters: string) => {
            const matchFilter: any[] = [];
            const filterArray = filters.split('+').filter(x => x.indexOf('notextfilters') < 0)
            if (filterArray.length > 0) {
                filterArray.forEach((filter) => {
                    let result = false;
                    const errorCode = data.ErrorCode ? data.ErrorCode : ''
                    const desc = data.Description ? data.Description : ''
                    result = desc.toString().toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
                        errorCode.toString().toLowerCase().indexOf(filter.toLowerCase()) > -1
                    if (this.weightSelected !== -1)
                        result = result && this.weightSelected === data.ErrorWeight
                    if (this.controllerBlockedSelected === 'Yes')
                        result = result && data.ControllerBlocked
                    if (this.controllerBlockedSelected === 'No')
                        result = result && !data.ControllerBlocked

                    return matchFilter.push(result && this.auxFilterPredicate(data));
                });
            }
            else {
                let result = true;
                if (this.weightSelected !== -1)
                    result = result && this.weightSelected === data.ErrorWeight
                if (this.controllerBlockedSelected === 'Yes')
                    result = result && data.ControllerBlocked
                if (this.controllerBlockedSelected === 'No')
                    result = result && !data.ControllerBlocked
                matchFilter.push(result && this.auxFilterPredicate(data));
            }
            return matchFilter.some(Boolean);
        };
    }

    getHeader() {

        this.columnsToDisplay = ['arrow', 'arrow-device', 'Mode', 'BuildingType', 'TrafficPattern', 'IsWarning', 'actions'];
        this.subcolumnsToDisplay = ['FingerprintFeature', 'ThresholdType', 'ThresholdValue', 'Environment', 'ChangedAtUtc', 'actions'];


    //    if ((this.defaults.getIsControllerOwner() || this.defaults.isadminuser) && !this.defaults.isReadonlyUser) {
    //        this.columnsToDisplay = ['arrow', 'arrow-device', 'opmode', 'description', 'nresolutions', 'iswarning', 'actions'];
    //        this.subcolumnsToDisplay = ['failure', 'action', 'weight', 'location', 'component', 'cause', 'resolution', 'partsrequired', 'highvaluepart', 'actions'];
    //    }
    //    else {
    //        this.columnsToDisplay = ['arrow', 'arrow-device', 'opmode', 'description', 'nresolutions', 'iswarning', 'actions'];
    //        this.subcolumnsToDisplay = ['failure', 'action', 'weight', 'location', 'component', 'cause', 'resolution', 'partsrequired', 'highvaluepart'];
    //    }
    }

    auxFilterPredicate(data: ErrorCode) {
        let result = true;
        const keys = Object.keys(this.filters) // Checkboxes with AND filter
        keys.forEach(key => {
            switch (key) {
                case 'stablealertruleid': if (this.filters.stablealertruleid !== -1) result = result && data.StableAlertRuleCatId === this.filters.stablealertruleid; break;
                case 'prodalertruleid': if (this.filters.prodalertruleid !== -1) result = result && data.ProdAlertRuleCatId === this.filters.prodalertruleid; break;
            }
        })
        return result;
    }

    checkExpanded(element: ErrorCode, isDES: boolean) {
        if (!isDES)
            return this.expandedElements.has(element);
        else
            return this.expandedDesDevices.has(element);
    }

    pushPopElement(element: ErrorCode, isDES: boolean) {
        if (!isDES) {
            if (this.expandedElements.has(element)) {
                this.expandedElements.delete(element);
            }
            else {
                this.expandedElements.add(element);
                this.onExpand(element, isDES)
            }
        }
        else {
            if (this.expandedDesDevices.has(element)) {
                this.expandedDesDevices.delete(element);
            }
            else {
                this.expandedDesDevices.add(element);
                this.onExpand(element, isDES)
            }
        }
    }

    onExpand(element: any, isDES: boolean) {
/*        const foundIndex = this.dataSource.data.findIndex((x: ErrorCode) => x.ErrorCode === element.ErrorCode && x.ErrorCodeID === element.ErrorCodeID);*/
        const foundIndex = this.dataSource.data.findIndex((x: any) => x._PopulationSetupId === element._PopulationSetupId);

        //Utils.httpGetAllFPErrorCodeResolutions(
        //    this.http
        //    , this.baseUrl
        //    , element.ErrorCodeID
        //    , this
        //    , function (tthis: any, list: ErrorCodeResolution[]) {
        //        tthis.dataSource.data[foundIndex].ErrorCodeResolutions = list;
        //    }
        //);

        Utils.httpFPTriggerConditions(
            this.http
            , this.baseUrl
            , element._PopulationSetupId
            , this
            , function (tthis: any, list: any[]) {
                tthis.dataSource.data[foundIndex].ErrorCodeResolutions = list;
            }
        );


    }

    onAdd()
    {
        const Dialog = this.dialogForm.open(FPRuleDefinitionFormComponent, {
            panelClass: 'custom-mat-dialog',
            disableClose: false,
            data: {
                "action": "OM",
                "populationsetupid": -1,
                "triggerconditionid": -1
            }
        });
        Dialog.afterClosed().subscribe(result => {
            if (result) this.ngOnInit();
        });


    }

    isEditingRow(element: ErrorCode) {
        return this.clonedList[element.ErrorCodeID]
    }

    isEditingResol(element: ErrorCodeResolution) {
        return this.clonedResolutionList[element.PossibleFailureID]
    }

    isCreatingResol(element: ErrorCodeResolution) {
        return this.newResolutionList.includes(element.ErrorCodeID) && element.PossibleFailureID === -1
    }

    onRowEditInit(element: any)
    {

    //    this.saveService.addListener(this);
    //    this.clonedList[element.ErrorCodeID] = { ...element }
    //    if (Object.keys(this.clonedList).length !== 0) this.onShowSavebutton()
        const Dialog = this.dialogForm.open(FPRuleDefinitionFormComponent, {
            panelClass: 'custom-mat-dialog',
            disableClose: false,
            data: {
                "action": "OM",
                element,
                "populationsetupid": element._PopulationSetupId,
                "triggerconditionid": -1
            }
        });
        Dialog.afterClosed().subscribe(result => {
            if (result) this.ngOnInit();
        });




        //"PopulationSetupId": element._PopulationSetupId,
        //    "Mode": element.Mode,
        //        "TrafficPattern": element.TrafficPattern,
        //            "IsWarning": element.IsWarning


    }

    onRowResolEditInit(element: any,element2: any) {

        const Dialog = this.dialogForm.open(FPRuleDefinitionFormComponent, {
            panelClass: 'custom-mat-dialog',
            disableClose: false,
            data: {
                "action": "FEATURE",
                "populationsetupid": element2.PopulationSetupId,
                "triggerconditionid": element2._TriggerConditionId,
                element2
            }
        });
        Dialog.afterClosed().subscribe(result => {
            if (result) this.onExpand(element, false);
        });


    //    this.saveService.addListener(this);
    //    Utils.httpGetAllComponents(
    //        this.http
    //        , this.baseUrl
    //        , element.SubSystemGroupID
    //        , this
    //        , function (tthis: any, list: ComponentItem[]) {
    //            element.components = list;
    //        }
    //    );
    //    this.clonedResolutionList[element.PossibleFailureID] = Object.assign({}, element)
    //    if (this.clonedResolutionList !== {}) this.onShowSavebutton()
    }

    onCreateNewTrigger(element: any) {
        //if (this.newResolutionList.findIndex(x => x === element.ErrorCodeID) > -1) // If it's already opened
        //    return;
        //this.saveService.addListener(this);
        //const index = this.dataSource.data.findIndex(x => x.ErrorCodeID === element.ErrorCodeID)
        //const local = this.dataSource.data[index].ErrorCodeResolutions
        //this.dataSource.data[index].ErrorCodeResolutions = []
        //local.forEach(x => {
        //    this.dataSource.data[index].ErrorCodeResolutions.unshift(x)
        //})
        //this.dataSource.data[index].ErrorCodeResolutions.unshift({ 'PossibleFailureID': -1, 'ErrorCodeID': element.ErrorCodeID, 'ErrorCode': element.ErrorCode })
        //this.newResolutionList.push(element.ErrorCodeID)
        //this.table.renderRows()
        //if (this.newResolutionList.length > 0) this.onShowSavebutton()
        //this.selectedErrorCode = element.ErrorCodeID

        const Dialog = this.dialogForm.open(FPRuleDefinitionFormComponent, {
            panelClass: 'custom-mat-dialog',
            disableClose: false,
            data: {
                "action": "FEATURE",
                "populationsetupid": element._PopulationSetupId,
                "triggerconditionid": -1
            }
        });
        Dialog.afterClosed().subscribe(result => {
            if (result) this.onExpand(element, false);
        });

    }

    showKeywordsTooltip(errorCode: ErrorCode) {  // Showing keywords tooltip whenever user is editing a resolution
        return this.newResolutionList.find(x => x === errorCode.ErrorCodeID)
    }

    selectTroubleshootingInput(element: ErrorCodeResolution) {
        this.selectedErrorCode = element.ErrorCodeID
    }

    async onShowKeywords() {
        //const input = document.getElementById('errorcode_' + this.selectedErrorCode)
        //this.caret = input.selectionStart;
        //this.isShowingKeywordsSelector = true;
        //let target: any;
        //do {
        //    target = document.getElementById('keywords_' + this.selectedErrorCode);
        //    await new Promise(r => setTimeout(r, 20));
        //}
        //while (!target)
        //this.items.forEach((div) => {
        //    if (div.id === 'keywords_' + this.selectedErrorCode) {
        //        setTimeout(() => {
        //            div.open()
        //        })
        //    }
        //});
    }

    openedChange($event: boolean) {
        if (!$event)
            this.isShowingKeywordsSelector = false;
    }
    onSelectItem(value: string) {
        // Get resol to be edited
        const index = this.dataSource.data.findIndex(x => x.ErrorCodeID === this.selectedErrorCode)
        // Intersect or append depending on previous user event

        // If field is empty -> add directly
        if (!this.dataSource.data[index].ErrorCodeResolutions[0].TroubleShootingAction || this.dataSource.data[index].ErrorCodeResolutions[0].TroubleShootingAction === '') {
            this.dataSource.data[index].ErrorCodeResolutions[0].TroubleShootingAction =
                value
        }
        else {
            if (this.caret === 0) {
                this.dataSource.data[index].ErrorCodeResolutions[0].TroubleShootingAction =
                    value + ' ' + this.dataSource.data[index].ErrorCodeResolutions[0].TroubleShootingAction
            }
            else if (this.caret === this.dataSource.data[index].ErrorCodeResolutions[0].TroubleShootingAction.length) {
                this.dataSource.data[index].ErrorCodeResolutions[0].TroubleShootingAction =
                    this.dataSource.data[index].ErrorCodeResolutions[0].TroubleShootingAction + ' ' + value
            }
            else {
                this.dataSource.data[index].ErrorCodeResolutions[0].TroubleShootingAction =
                    this.dataSource.data[index].ErrorCodeResolutions[0].TroubleShootingAction.substring(0, this.caret) +
                    ' ' +
                    value +
                    ' ' +
                    this.dataSource.data[index].ErrorCodeResolutions[0].TroubleShootingAction.substring(this.caret);
            }
        }

        // Cleaining variables
        this.isShowingKeywordsSelector = false;
        this.caret = 0;
    }

    highlightTroubleshootingWithKeywords(text: string) {
        if (text) {
            const matches = text.split(' ')
            const tokens: string[] = []
            matches.forEach(sr => {
                if (this.keywords.findIndex(x => x === sr) > -1) {
                    tokens.push('<span class="orange-keyword"> ' + sr.replace(/</g, '').replace(/>/g, '') + '</span>')
                }
                else
                    tokens.push(sr);
            })
            return tokens.join(' ');
        }
        return null;
    }

    onRowEditCancel(element: ErrorCode) {
        const index = this.dataSource.data.findIndex(x => x.ErrorCodeID === element.ErrorCodeID)
        this.dataSource.data[index].ErrorCode = this.clonedList[element.ErrorCodeID].ErrorCode
        this.dataSource.data[index].ErrorWeight = this.clonedList[element.ErrorCodeID].ErrorWeight
        this.dataSource.data[index].Description = this.clonedList[element.ErrorCodeID].Description
        this.dataSource.data[index].ControllerBlocked = this.clonedList[element.ErrorCodeID].ControllerBlocked
        delete this.clonedList[element.ErrorCodeID];
        if (Object.keys(this.clonedList).length === 0 && Object.keys(this.clonedResolutionList).length === 0 && this.newResolutionList.length === 0) this.saveService.showSaveButton(false)
    }

    onResolEditCancel(element: ErrorCodeResolution) {
        const index = this.newResolutionList.findIndex(x => x === element.ErrorCodeID)
        if (index < 0) { // Edit
            const index = this.dataSource.data.findIndex(x => x.ErrorCodeID === element.ErrorCodeID)
            const resolIndex = this.dataSource.data[index].ErrorCodeResolutions.findIndex(x => x.PossibleFailureID === element.PossibleFailureID)
            this.dataSource.data[index].ErrorCodeResolutions[resolIndex].HighValuePart = this.clonedResolutionList[element.PossibleFailureID].HighValuePart
            this.dataSource.data[index].ErrorCodeResolutions[resolIndex].PartsRequired = this.clonedResolutionList[element.PossibleFailureID].PartsRequired
            this.dataSource.data[index].ErrorCodeResolutions[resolIndex].MaintenanceFailure = this.clonedResolutionList[element.PossibleFailureID].MaintenanceFailure;
            this.dataSource.data[index].ErrorCodeResolutions[resolIndex].TroubleShootingAction = this.clonedResolutionList[element.PossibleFailureID].TroubleShootingAction;
            this.dataSource.data[index].ErrorCodeResolutions[resolIndex].TroubleShootingActionWeight = this.clonedResolutionList[element.PossibleFailureID].TroubleShootingActionWeight;
            this.dataSource.data[index].ErrorCodeResolutions[resolIndex].SubSystemGroupID = this.clonedResolutionList[element.PossibleFailureID].SubSystemGroupID;
            this.dataSource.data[index].ErrorCodeResolutions[resolIndex].ComponentItemID = this.clonedResolutionList[element.PossibleFailureID].ComponentItemID;
            this.dataSource.data[index].ErrorCodeResolutions[resolIndex].CauseID = this.clonedResolutionList[element.PossibleFailureID].CauseID;
            this.dataSource.data[index].ErrorCodeResolutions[resolIndex].ResolutionID = this.clonedResolutionList[element.PossibleFailureID].ResolutionID;
            delete this.clonedResolutionList[element.PossibleFailureID];
            if (Object.keys(this.clonedResolutionList).length === 0 && Object.keys(this.clonedList).length === 0 && this.newResolutionList.length === 0) this.saveService.showSaveButton(false)
        }
        else { // New
            const errorIndex = this.dataSource.data.findIndex(x => x.ErrorCodeID === element.ErrorCodeID)
            this.dataSource.data[errorIndex].ErrorCodeResolutions.splice(0, 1)
            const local = this.dataSource.data[errorIndex].ErrorCodeResolutions
            this.dataSource.data[errorIndex].ErrorCodeResolutions = []
            local.forEach(x => {
                this.dataSource.data[errorIndex].ErrorCodeResolutions.unshift(x)
            })
            this.newResolutionList.splice(index, 1)

            const last = this.newResolutionList[this.newResolutionList.length - 1]
            this.isShowingKeywordsSelector = false;
            this.selectedErrorCode = last
            this.caret = 0
        }
        if (Object.keys(this.clonedList).length === 0 && Object.keys(this.clonedResolutionList).length === 0 && this.newResolutionList.length === 0) this.saveService.showSaveButton(false)
    }

    changeElement(element: ErrorCode, type: string, value: boolean) {
        const index = this.dataSource.data.findIndex(x => x.ErrorCodeID === element.ErrorCodeID)
        switch (type) {
            case 'controllerblocked':
                this.dataSource.data[index].ControllerBlocked = value;
                break;
        }
        this.table.renderRows();
    }

    changeResol(element: ErrorCodeResolution, type: string, value: boolean) {
        const index = this.dataSource.data.findIndex(x => x.ErrorCodeID === element.ErrorCodeID)
        const subindex = this.dataSource.data[index].ErrorCodeResolutions.findIndex(x => x.PossibleFailureID === element.PossibleFailureID)
        switch (type) {
            case 'highvaluepart':
                this.dataSource.data[index].ErrorCodeResolutions[subindex].HighValuePart = value;
                break;
            case 'partsrequired':
                this.dataSource.data[index].ErrorCodeResolutions[subindex].PartsRequired = value;
                break;
        }
        this.table.renderRows();
    }

    onLocationChange(element: ErrorCodeResolution) {
        //Utils.httpGetAllComponents(
        //    this.http
        //    , this.baseUrl
        //    , element.SubSystemGroupID
        //    , this
        //    , function (tthis: any, list: ComponentItem[]) {
        //        element.components = list;
        //        element.ComponentItemID = list[0].ComponentItemID
        //    }
        //);
    }

    onShowSavebutton() {
        this.saveService.showSaveButton();
    }

    onDelete(element: any) {
        this.customDialog.openConfirm({
            message: element.Description + "  (" + element.BuildingType + ")?",
            title: "Delete fingerprint Rule Definition and trigger conditions associated",
            caller: this
        });
        this.customDialog.confirmed().subscribe(res => {
            if (res.confirmed) {
                let url = this.baseUrl + Utils.getAPIFingerPrint() + "fp_deletepopulationsetup/" + element._PopulationSetupId.toString();

                this.http
                    .delete<boolean>(url)
                    .subscribe(res => {
                        if (res) this.ngOnInit();
                    }, error => {
                        Utils.writeLog(this.http, this.baseUrl, error); //
                    });

            }
        });
    }

    onDeleteResol(element: any, element2: any) {
        this.customDialog.openConfirm({
            message: element2.FingerprintFeature + "  (" + element2.ThresholdType+ ")?",
            title: "Delete Trigger Condition",
            caller: this
        });
        this.customDialog.confirmed().subscribe(res => {
            if (res.confirmed)
            {
                let url = this.baseUrl + Utils.getAPIFingerPrint() + "fp_deletetriggercondition/" + element2._TriggerConditionId.toString();

                this.http
                    .delete<boolean>(url)
                    .subscribe(res => {
                        if (res) this.onExpand(element, false);
                    }, error =>
                    {
                        Utils.writeLog(this.http, this.baseUrl, error); //
                    }); 

            }
        });
    }

    onNew() {
        this.show = true;
    }
    onCancelErrorCodeCreation() {
        if (Object.keys(this.clonedList).length === 0 && Object.keys(this.clonedResolutionList).length === 0 && this.newResolutionList.length === 0)
            this.saveService.showSaveButton(false)
        this.show = false;
    }

    onNewRowEvent() {
        this.show = false;
        this.table.renderRows();
        this.updatefilter();
        this.onUpdateTable.emit();
        this.saveService.addListener(this)
    }

    onSave() {

        for (let i in this.newResolutionList) {
            const index = this.dataSource.data.findIndex(x => x.ErrorCodeID === this.newResolutionList[i])
            const subindex = this.dataSource.data[index].ErrorCodeResolutions.findIndex(x => x.PossibleFailureID === -1)
            this.http
                .put<ErrorCodeResolution>(
                    this.baseUrl + Utils.getFingerprintAPI() + "addpossiblefailure"
                    , this.dataSource.data[index].ErrorCodeResolutions[subindex]
                )
                .subscribe(
                    res => {
                        Utils.httpGetAllErrorCodeResolutions(
                            this.http
                            , this.baseUrl
                            , this.dataSource.data[index].ErrorCodeID
                            , this
                            , function (tthis: any, list: ErrorCodeResolution[]) {
                                tthis.dataSource.data[index].ErrorCodeResolutions = [];
                                tthis.dataSource.data[index].ErrorCodeResolutions = list;
                                tthis.dataSource.data[index].Resolutions++
                            }
                        );
                        delete this.newResolutionList[i];
                        this.table.renderRows();
                    }
                    , error => {
                        Utils.httpGetAllFPErrorCodeResolutions(
                            this.http
                            , this.baseUrl
                            , this.dataSource.data[index].ErrorCodeID
                            , this
                            , function (tthis: any, list: ErrorCodeResolution[]) {
                                tthis.dataSource.data[index].ErrorCodeResolutions = [];
                                tthis.dataSource.data[index].ErrorCodeResolutions = list;
                            }
                        );
                        delete this.newResolutionList[i];
                        this.table.renderRows();
                        this.dialog.showErrorDialog(error)
                    }

                );
        }

        for (let i in this.clonedResolutionList) {
            const index = this.dataSource.data.findIndex(x => x.ErrorCodeID === this.clonedResolutionList[i].ErrorCodeID)
            const resolIndex = this.dataSource.data[index].ErrorCodeResolutions.findIndex(x => x.PossibleFailureID === this.clonedResolutionList[i].PossibleFailureID)
            if (Object.entries(this.clonedResolutionList[i]).filter(([key]) => key !== 'components').toString() !==
                Object.entries(this.dataSource.data[index].ErrorCodeResolutions[resolIndex]).filter(([key]) => key !== 'components').toString()) {
                this.http
                    .post<ErrorCodeResolution>(
                        this.baseUrl + Utils.getErrorCodeAPI() + "editpossiblefailure"
                        , this.dataSource.data[index].ErrorCodeResolutions[resolIndex]
                    )
                    .subscribe(
                        res => {
                            this.dataSource.data[index].ErrorCodeResolutions[resolIndex].Resolution = res.Resolution
                            this.dataSource.data[index].ErrorCodeResolutions[resolIndex].Cause = res.Cause
                            this.dataSource.data[index].ErrorCodeResolutions[resolIndex].ComponentItem = res.ComponentItem
                            this.dataSource.data[index].ErrorCodeResolutions[resolIndex].SubSystemGroup = res.SubSystemGroup
                            delete this.clonedResolutionList[this.clonedResolutionList[i].PossibleFailureID];
                            this.table.renderRows();
                            this.updatefilter()
                        }
                        , error => {
                            this.dialog.showErrorDialog(error);
                        }
                    );
            }
            else {
                delete this.clonedResolutionList[this.clonedResolutionList[i].PossibleFailureID];
            }
        }


        this.show = false
    }

    applyFilter(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            this.searchItems.push({ name: value.replace('+', '').trim() });
        }
        // Reset the input value
        if (input) {
            input.value = '';
        }
        this.updatefilter()
    }

    filterColumn(value: any, name: string) {
        switch (name) {
            case 'stablealertruleid': this.filters.stablealertruleid = value.value; break;
            case 'prodalertruleid': this.filters.prodalertruleid = value.value; break;
        }
        this.updatefilter()
    }

    filterCheckbox(value: any, name: string) {
        switch (name) {
            case 'controllerblocked': this.controllerBlockedSelected = value.value; break;
        }
        this.updatefilter()
    }

    remove(item: SearchItem): void {
        const index = this.searchItems.indexOf(item);
        if (index >= 0) {
            this.searchItems.splice(index, 1);
        }
        this.updatefilter()
    }
    updatefilter() {
        let filterString: string = ''
        if (this.searchItems.length === 0) {
            filterString = 'notextfilters'
        }
        else {
            filterString = this.searchItems.map(e => { return e.name }).join('+') + '+checkboxes'
        }
        this.dataSource.filter = filterString
    }

    filterWeight(value: any) {
        this.weightSelected = value.value
        this.updatefilter()
    }

        
}

export interface SearchItem {
    name: string;
}

interface ColumnFilter {
    stablealertruleid: number | undefined;
    prodalertruleid: number | undefined;
}
