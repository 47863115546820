import { Component, Inject, Input, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { MatChipInputEvent } from "@angular/material/chips";
import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';
import { SaveService } from '../../../services/save.service';
import { DefaultValuesService } from '../../../services/defaultvalues.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DialogService } from "../../../services/dialog.service";
import { Utils } from "../../../utils/utils";

@Component({
    selector: "kpi-list",
    templateUrl: './kpi-list.component.html',
    styleUrls: ['./kpi-list.component.less', '../../../style/mat-table.component.less'],

    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' })),
            transition('expanded => collapsed', [animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
        ]),
    ]
})

export class KpiListComponent implements OnInit {
    @Input('list') list: KpiUnit[] = [];
    @Input('showLocalFilter') showLocalFilter = false;

    columnsToDisplay = ['arrow-items', 'index', 'UnitId', 'CustomerId', 'ContractNumber'];
    kpiColumnsToDisplay = ['ReportingDate', 'AggregationLevel', 'KpiName', 'KpiValue', 'IsDeleted', '_ChangedAtUtc'];

    booleanColumns = ['IsDeleted']
    dateColumns = ['_ChangedAtUtc', 'ReportingDate']

    notEditableColumns = ['CustomerId', 'ContractNumber', 'UnitId', 'ReportingDate', '_ChangedAtUtc']

    @ViewChild(MatTable, { static: true }) table!: MatTable<any>;

    public dataSource = new MatTableDataSource<KpiUnit>();
    selected: KpiUnit | undefined;
    expanded: boolean[] = [];
    expandedElements = new Set();

    clonedListItems: { [s: string]: Kpi } = {};

    filter = '';

    subscriptionTenant: any;

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [COMMA, TAB, ENTER];
    searchItems: SearchItem[] = [];

    constructor(private activatedRoute: ActivatedRoute
        , private router: Router
        , private http: HttpClient
        , private saveService: SaveService
        , private defaults: DefaultValuesService
        , private confimDialog: DialogService
        , @Inject('BASE_URL') private baseUrl: string

    ) {
    }

    ngOnChanges() {
        this.dataSource.data = this.list
        this.clonedListItems = {}
        this.saveService.showSaveButton(false)
        this.expandedElements.clear();
        this.saveService.addListener(this)
    }

    ngOnInit() {
        this.getHeader();
        this.subscriptionTenant = this.defaults.tenantMessageChanges$.subscribe(() => {
            this.getHeader()
        });
        this.saveService.addListener(this);
        this.dataSource.filterPredicate = (data: KpiUnit, filters: string) => {
            const matchFilter: any[] = [];
            const filterArray = filters.split('+').filter(x => x.indexOf('notextfilters') < 0)
            if (filterArray.length > 0) {
                filterArray.forEach((filter) => {
                    let result = false;
                    this.columnsToDisplay.forEach((column) => {
                        const value = data[column]
                    })
                    return matchFilter.push(result);
                });
            }
            else {
                let result = true;
                matchFilter.push(result);
            }
            return matchFilter.some(Boolean);
        };
    }

    getHeader() {
        if (!this.defaults.isReadonlyUser) {
            this.kpiColumnsToDisplay = ['ReportingDate', 'AggregationLevel', 'KpiName', 'KpiValue', 'IsDeleted', '_ChangedAtUtc'];
        }
        else {
            this.kpiColumnsToDisplay = ['ReportingDate', 'AggregationLevel', 'KpiName', 'KpiValue', 'IsDeleted', '_ChangedAtUtc'];
        }
    }

    getInput(data: KpiUnit, property: string): any {
        return data[property];
    }

    getDataType(property: string) {
        let result = ''
        if (this.booleanColumns.indexOf(property) > -1) result = 'boolean'
        else if (this.dateColumns.indexOf(property) > -1) result = 'date'
        else result = 'string'
        return result
    }

    changeElement(object: any, type: string, value: boolean) {
        object[type] = value;
        this.table.renderRows();
    }

    pushPopElement(element: KpiUnit) {
        if (this.expandedElements.has(element)) {
            this.expandedElements.delete(element);
        }
        else {
            this.expandedElements.add(element);
            this.onExpand(element)
        }
    }

    checkExpanded(element: KpiUnit) {
        return this.expandedElements.has(element);
    }

    onExpand(element: KpiUnit) {
        Utils.httpGetKpis(
            this.http
            , this.baseUrl
            , this.defaults.stage
            , element
            , this
            , function (tthis: any, list: Kpi[]) {
                element.Items = list;
                tthis.expandedElements.add(element);
            }
        );
    }

    onRowEditInit(unit: KpiUnit, kpi: Kpi) {
        this.saveService.addListener(this);
        this.clonedListItems[this.getKpiItemDictionaryHash(unit, kpi)] = { ...kpi };
        if (Object.keys(this.clonedListItems).length === 0) this.onShowSavebutton()
    }

    onRowEditCancel(unit: KpiUnit, kpi: Kpi) {
        const index = this.dataSource.data.findIndex(x => x.UnitId === unit.UnitId && x.ContractNumber === unit.ContractNumber && x.CustomerId === unit.CustomerId)
        const itemIndex = this.dataSource.data[index].Items.findIndex(x => x.ReportingDate === kpi.ReportingDate && x.KpiName === kpi.KpiName)
        this.dataSource.data[index].Items[itemIndex] = this.clonedListItems[this.getKpiItemDictionaryHash(unit, kpi)];

        delete this.clonedListItems[this.getKpiItemDictionaryHash(unit, kpi)];
        if (Object.keys(this.clonedListItems).length === 0)
            this.saveService.showSaveButton(false)
    }

    isEditing(unit: KpiUnit, kpi: Kpi, property: string) {
        let result = true;
        if (property === null) {
            result = result && this.clonedListItems[this.getKpiItemDictionaryHash(unit, kpi)] !== undefined
        }
        else {
            result = result && this.clonedListItems[this.getKpiItemDictionaryHash(unit, kpi)] !== undefined && this.notEditableColumns.indexOf(property) < 0
        }
        return result;
    }

    onShowSavebutton() {
        this.saveService.showSaveButton();
    }

    onSave() {
        let updatedList: any[] = []
        for (let i in this.clonedListItems) {
            const tokens = i.split("_");
            const index = this.dataSource.data.findIndex(x => x.UnitId === tokens[0] && x.ContractNumber === tokens[1] && x.CustomerId === tokens[2])
            const subindex = this.dataSource.data[index].Items.findIndex(x => x.KpiName === tokens[0] && x.ReportingDate.toString() === tokens[1])

            const item = this.dataSource.data[index].Items[subindex]
            updatedList.push({ ...this.dataSource.data[index], ...item })
        }
        if (updatedList.length > 0)
            Utils.httpUpdateKpis(
                this.http
                , this.baseUrl
                , this.defaults.stage
                , updatedList
                , this
                , function (tthis: any, list: Kpi[]) {
                    tthis.clonedList = {}
                    tthis.saveService.showSaveButton(false)
                    tthis.expandedElements.clear();
                    tthis.table.renderRows();
                }, function (tthis: KpiListComponent, message: string) {
                    tthis.saveService.addListener(tthis)
                    tthis.saveService.showSaveButton(true)
                    tthis.confimDialog.showErrorDialog(message);
                }
            );

        updatedList = []
    }

    applyFilter(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            this.searchItems.push({ name: value.replace('+', '').trim() });
        }
        // Reset the input value
        if (input) {
            input.value = '';
        }
        this.updatefilter()
    }

    remove(item: SearchItem): void {
        const index = this.searchItems.indexOf(item);
        if (index >= 0) {
            this.searchItems.splice(index, 1);
        }
        this.updatefilter()
    }
    updatefilter() {
        let filterString: string
        if (this.searchItems.length === 0) {
            this.dataSource.filter = 'notextfilters'
        }
        else {
            filterString = this.searchItems.map(e => { return e.name }).join('+') + '+checkboxes'
            this.dataSource.filter = filterString
        }
    }

    getKpiItemDictionaryHash(unit: KpiUnit, kpi: Kpi) {
        return unit.UnitId + '_' + unit.ContractNumber + '_' + unit.CustomerId + '_' + kpi.KpiName + '_' + kpi.ReportingDate
    }

}
export interface SearchItem {
    name: string;
}