<div class="umphome_list" id="umphome_list">


    <!--<button mat-icon-button (click)="btn_new()" matTooltip="New Row" >
        <mat-icon aria-label="Add new element to the current table">add_circle</mat-icon>
    </button>-->

    <table mat-table
           matSort
           [dataSource]="dataSource"
           multiTemplateDataRows
           class="mat-elevation-z8 mat-table-custom">

        <ng-container matColumnDef="upn">
            <th mat-header-cell *matHeaderCellDef> UPN</th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.userPrincipalName"></p>
            </td>
        </ng-container>
        <ng-container matColumnDef="displayname">
            <th mat-header-cell *matHeaderCellDef> Display Name</th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.displayName"></p>
            </td>
        </ng-container>
        <ng-container matColumnDef="tenant">
            <th mat-header-cell *matHeaderCellDef> Tenant </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.tenant"></p>
            </td>
        </ng-container>
        <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef> Active </th>
            <td mat-cell *matCellDef="let element">
                <mat-checkbox [checked]="element.active" [disabled]="true"></mat-checkbox>
            </td>
        </ng-container>

        <!--<ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td class=" th-right" mat-cell *matCellDef="let element">
                <button mat-icon-button matTooltip="Edit User" routerLink="/EditUser/{{element.userId}}">
                    <mat-icon>edit</mat-icon>
                </button>
            </td>
        </ng-container>-->



        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; index as i;
            columns: columnsToDisplay;"
            style="cursor: pointer;"
            class="opmode-item" routerLink="/EditUser/{{element.userId}}">
        </tr>
    </table>
    <mat-paginator [length]="count"
                   [pageSize]="25"
                   aria-label="Select page">
    </mat-paginator>
</div>