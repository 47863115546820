import { Component, Inject, ElementRef, ViewChild, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Utils } from "../../utils/utils";
import { DefaultValuesService } from "../../services/defaultvalues.service";
import { DialogService } from "../../services/dialog.service";
import { SaveService } from "../../services/save.service";

@Component({
    selector: "operationmode-edit-opcounters",
    templateUrl: './operationmode-edit-opcounters.component.html',
    styleUrls: ['../../style/form.component.less']
})

export class OperationModeEditOpCountersComponent implements OnInit {

    @Input() header: any[] = [];
    @Input() list: OperationMode[] = [];
    @Input() rowIndex: number = -1;

    OPSTATUSOUTSERVICE: number = 0;
    OPHOURS: number = 1;
    BRKHOURS: number = 2;
    UNITAVAILABLE: number = 3;
    SPECIALTRIPS: number = 4;
    DESCRIPTION: number = 5;

    selected: OperationMode = <OperationMode>{};

    classname: String[] = [];
    isundodisabled: boolean[] = [];

    form!: FormGroup;

    constructor(
        private activatedRoute: ActivatedRoute
        , private router: Router
        , private http: HttpClient
        , private fb: FormBuilder
        , @Inject('BASE_URL') private baseUrl: string
        , private dialog: DialogService
        , public saveService: SaveService
    ) {
    }

    private onFieldChange(index: number, field1: any, field2: any): boolean {
        return this.saveService.onFieldChange(index, field1, field2, this);
    }

    private onFieldUndo(index: number) {
        this.saveService.onFieldUndo(index, this);
    }

    ngOnInit() {

        this.dialog.tthis = this;
        this.saveService.addListener(this);

        this.saveService.initUndo(this); //this.createForm() is called from here

        this.selected.OpStatusOutService = this.list[this.rowIndex].OpStatusOutService;
        this.selected.OpHours = this.list[this.rowIndex].OpHours;
        this.selected.BrkHours = this.list[this.rowIndex].BrkHours;
        this.selected.UnitAvailable = this.list[this.rowIndex].UnitAvailable;
        this.selected.SpecialTrips = this.list[this.rowIndex].SpecialTrips;
        this.updateForm();

    }

    createForm() {
        this.form = this.fb.group({
            OpStatusOutService: [''],
            OpHours: [''],
            BrkHours: [''],
            UnitAvailable: [''],
            SpecialTrips: [''],
        });
    }
    
    onBrkHoursChange() {
        if (this.form && this.onFieldChange(this.BRKHOURS, this.form.value.BrkHours, this.selected.BrkHours) && this.selected)
            this.list[this.rowIndex].BrkHours = this.form.value.BrkHours;
    }

    onBrkHoursUndo() {
        this.list[this.rowIndex].BrkHours = this.selected.BrkHours;
        this.onFieldUndo(this.BRKHOURS);
    }

    onCancel(index: number) {
        if (index == this.rowIndex) {
            this.onOpHoursUndo();
            this.onOpStatusOutServiceUndo();
            this.onSpecialTripsUndo();
            this.onUnitAvailableUndo();
            this.onBrkHoursUndo();
        }
    }

    onOpHoursChange() {
        if (this.form && this.onFieldChange(this.OPHOURS, this.form.value.OpHours, this.selected.OpHours) && this.selected)
            this.list[this.rowIndex].OpHours = this.form.value.OpHours;
    }

    onOpHoursUndo() {
        this.list[this.rowIndex].OpHours = this.selected.OpHours;
        this.onFieldUndo(this.OPHOURS);
    }

    onOpStatusOutServiceChange() {
        if (this.form && this.onFieldChange(this.OPSTATUSOUTSERVICE, this.form.value.OpStatusOutService, this.selected.OpStatusOutService) && this.selected)
            this.list[this.rowIndex].OpStatusOutService = this.form.value.OpStatusOutService;
    }

    onOpStatusOutServiceUndo() {
        this.list[this.rowIndex].OpStatusOutService = this.selected.OpStatusOutService;
        this.onFieldUndo(this.OPSTATUSOUTSERVICE);
    }

    onRowCancel() {
        this.saveService.fireCancel(this.rowIndex, this);
    }

    onSave() {
        if (this.form && this.form.valid) {
            this.http
                .post<OperationMode>(
                    this.baseUrl + Utils.getOperationModeAPI() + "counters"
                    , this.list[this.rowIndex]
                )
                .subscribe(
                    res => this.list[this.rowIndex] = res
                    , error => this.dialog.showErrorDialog(error)
                );
        }
        else
            this.dialog.showErrorStringDialog("Error Saving data!");
    }
    
    onSpecialTripsChange() {
        if (this.form && this.onFieldChange(this.SPECIALTRIPS, this.form.value.SpecialTrips, this.selected.SpecialTrips) && this.selected)
            this.list[this.rowIndex].SpecialTrips = this.form.value.SpecialTrips;
    }

    onSpecialTripsUndo() {
        this.list[this.rowIndex].SpecialTrips = this.selected.SpecialTrips;
        this.onFieldUndo(this.SPECIALTRIPS);
    }
    
    onUnitAvailableChange() {
        if (this.form && this.onFieldChange(this.UNITAVAILABLE, this.form.value.UnitAvailable, this.selected.UnitAvailable) && this.selected)
            this.list[this.rowIndex].UnitAvailable = this.form.value.UnitAvailable;
    }

    onUnitAvailableUndo() {
        this.list[this.rowIndex].UnitAvailable = this.selected.UnitAvailable;
        this.onFieldUndo(this.UNITAVAILABLE);
    }

    updateForm() {
        if (this.form && this.selected && this.form.valid) {

            var e = {
                OpStatusOutService: this.list[this.rowIndex].OpStatusOutService
                , OpHours: this.list[this.rowIndex].OpHours
                , BrkHours: this.list[this.rowIndex].BrkHours
                , UnitAvailable: this.list[this.rowIndex].UnitAvailable
                , SpecialTrips: this.list[this.rowIndex].SpecialTrips
            };

            this.form.setValue(e);
        }
    }
}