<div class="custom-mat-dialog-form">
    <div>
        <h3>Add New Contract Bundle to {{this.defaults.tenant}}</h3>
    </div>
    <div>
        <form>
            <div class="mt-2">
                <label>Contract Bundle*</label>
                <div class="input">
                    <mat-select [(ngModel)]="bundleId" class="form-control" readonly (ngModelChange)="loadFeatures()">
                        <mat-option *ngFor="let bundle of data.list" [value]="bundle.BundleId">
                            {{ bundle.Bundle }}
                        </mat-option>
                    </mat-select>
                </div>
            </div>
            <table mat-table #featureList
                   [dataSource]="dataSource"
                   multiTemplateDataRows
                   class="mat-elevation-z8 mat-table-custom" id="featurebundlelistpopup"
                   *ngIf="this.dataSource.data.length > 0">

                <ng-container matColumnDef="feature">
                    <th mat-header-cell *matHeaderCellDef> Included Feature/s </th>
                    <td mat-cell *matCellDef="let feature">
                        <p [innerHTML]="feature"></p>
                    </td>
                </ng-container>
                <tr mat-header-row class="tableheader" *matHeaderRowDef="columnsToDisplay"></tr>
                <tr mat-row *matRowDef="let element; let i = dataIndex; columns: columnsToDisplay;"
                    class="item">
                </tr>
            </table>
            <div class="mt-2 right">
                <button type="button"
                        class="btn btn-danger"
                        (click)="onNoClick()">
                    Close
                </button>
                <button type="button"
                        class="btn btn-primary"
                        (click)="onSubmit()">
                    Add
                </button>
            </div>
        </form>
    </div>
</div>
