<div class="custom-mat-dialog-form">
    <div>
        <h3>{{this.data ? 'Update' : 'Create new'}} Wave</h3>
    </div>
    <div>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-sm-12 form-group">

                    <!--Email Region-->
                    <div>
                        <mat-form-field appearance="outline">
                            <mat-label>Name</mat-label>
                            <input matInput formControlName="name" required>
                        </mat-form-field>

                        <p *ngIf="
                form.get('name')?.invalid &&
                  (form.get('name')?.dirty ||
                    form.get('name')?.touched)">
                            <mat-error *ngIf="form.get('name')?.hasError('required')">This field is required</mat-error>
                        </p>
                    </div>

                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 form-group">

                    <div>
                        <mat-form-field appearance="outline">
                            <mat-label>Start Date</mat-label>
                            <input matInput [matDatepicker]="pickerStartDate" formControlName="startDate"
                                   required readonly />
                            <mat-datepicker-toggle matSuffix [for]="pickerStartDate"></mat-datepicker-toggle>
                            <mat-datepicker #pickerStartDate></mat-datepicker>
                        </mat-form-field>
                    </div>

                </div>

                <div class="col-sm-6 form-group">

                    <div>
                        <mat-form-field appearance="outline">
                            <mat-label>End Date</mat-label>
                            <input matInput [matDatepicker]="pickerEndDate" formControlName="endDate"
                                   required readonly />
                            <mat-datepicker-toggle matSuffix [for]="pickerEndDate"></mat-datepicker-toggle>
                            <mat-datepicker #pickerEndDate></mat-datepicker>
                        </mat-form-field>
                    </div>

                </div>
            </div>
            <div class="row">
                <div class="panel-body">
                    <button (click)="backButtonHandler($event)" mat-raised-button class="btn  button-color-fix_red ">
                        <mat-icon class=" icon-color-fix ">arrow_back_ios</mat-icon>
                        Back
                    </button>
                    <button mat-raised-button type="submit" class="btn button-color-fix pull-right" [disabled]="!form.valid">
                        {{this.data ? 'Update' : 'Create'}}
                        <mat-icon class=" icon-color-fix ">save</mat-icon>
                    </button>

                </div>
            </div>
        </form>
    </div>
</div>
