import { Component, Inject, OnInit, OnDestroy } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Utils } from "../../../../utils/utils";
import { DefaultValuesService } from "../../../../services/defaultvalues.service";

@Component({
    selector: "controllertypebytenant",
    templateUrl: './controllertypebytenant.component.html',
    styleUrls: ['../../../../style/container-tab-list.component.less']
})

export class ControllerTypeByTenantComponent implements OnInit, OnDestroy {
    translationarea = {} as TranslationArea;
    controllertypebytenant: ControllerTypeByTenant[] = [];

    subscriptionLanguage: any;

    constructor(
        private http: HttpClient
        , private defaults: DefaultValuesService
        , @Inject('BASE_URL') private baseUrl: string
    ) {
    }

    ngOnInit() {
        if (this.controllertypebytenant.length === 0)
            this.refreshAll();
        this.subscriptionLanguage = this.defaults.languageMessageChanges$.subscribe(() => {
            this.refreshAll()
        });
    }

    ngOnDestroy() {
        this.subscriptionLanguage.unsubscribe();
    }

    refreshAll() {
        Utils.httpGetAllControllerTypeByTenant(
            this.http
            , this.baseUrl
            , this
            , function (tthis: ControllerTypeByTenantComponent, list: ControllerTypeByTenant[]) {
                tthis.controllertypebytenant = list;                      
            }
        );
    }
}