import { Component, EventEmitter, Inject, Output, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from "@angular/router";
import { DefaultValuesService } from '../../services/defaultvalues.service';
import { DialogService } from '../../services/dialog.service';
import { SaveService } from '../../services/save.service';
import { HierarchySetupPopupComponent } from './hierarchysetuppopup/hierarchysetuppopup.component';

@Component({
    selector: 'hierarchysetup-menu',
    templateUrl: './hierarchysetup-menu.component.html',
    styleUrls: ['../../style/menu.component.less', '../../style/dialogue-box.less'],
    encapsulation: ViewEncapsulation.None

})

export class HierarchySetupMenuComponent {
    @Output() rldBranchHierarchyKey = new EventEmitter();

    constructor(private activatedRoute: ActivatedRoute,
        private router: Router
        , @Inject('BASE_URL') private baseUrl: string
        , private saveService: SaveService
        , private dialog: DialogService
        , public defaults: DefaultValuesService
        , private dialogForm: MatDialog

    ) {

    }

    onCreateHierarchyKey() {
        const dialogRef = this.dialogForm.open(HierarchySetupPopupComponent, {
            panelClass: ["branch-hierarchy-dialogue", "custom-mat-dialog_v2"],
            data: { action: "add", content: "", country: this.defaults.country }
        })
        dialogRef.afterClosed().subscribe(() => this.rldBranchHierarchyKey.emit());

    }
}