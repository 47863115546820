<mat-accordion multi class="expand-collapse-panel">
    <mat-expansion-panel>
        <mat-expansion-panel-header>
        </mat-expansion-panel-header>
        <form autocomplete="off" [formGroup]="form" id="filterElevator">
        <tr>
            <td style="">
                <mat-form-field class="search-input">
                    <mat-label>Unit Id</mat-label>
                    <input placeholder="Unit Id"
                           matInput
                           [matChipInputFor]="chipUnitId"
                           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                           [matChipInputAddOnBlur]="addOnBlur"
                           (matChipInputTokenEnd)="applyFilter($event, 'unitids')">
                </mat-form-field>
                <mat-chip-list #chipUnitId>
                    <mat-chip *ngFor="let item of unitIds" [selectable]="selectable"
                              [removable]="removable" (removed)="remove(item, 'unitids')">
                        {{item}}
                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </td>
            <td style="">
                <mat-form-field class="search-input">
                    <mat-label>Branch Number</mat-label>
                    <input placeholder="Branch Number"
                           matInput
                           [matChipInputFor]="chipBranchNumbers"
                           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                           [matChipInputAddOnBlur]="addOnBlur"
                           (matChipInputTokenEnd)="applyFilter($event, 'branchnumbers')">
                </mat-form-field>
                <mat-chip-list #chipBranchNumbers>
                    <mat-chip *ngFor="let item of branchNumbers" [selectable]="selectable"
                              [removable]="removable" (removed)="remove(item, 'branchnumbers')">
                        {{item}}
                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </td>
            <td style="">
                <mat-form-field class="search-input">
                    <mat-label>Controller Type</mat-label>
                    <input placeholder="Controller Type"
                           matInput
                           [matChipInputFor]="chipControllerTypes"
                           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                           [matChipInputAddOnBlur]="addOnBlur"
                           (matChipInputTokenEnd)="applyFilter($event, 'controllertypes')">
                </mat-form-field>
                <mat-chip-list #chipControllerTypes>
                    <mat-chip *ngFor="let item of controllerTypes" [selectable]="selectable"
                              [removable]="removable" (removed)="remove(item, 'controllertypes')">
                        {{item}}
                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </td>
            <td style="">
                <mat-form-field class="search-input">
                    <mat-label>Building Addr1</mat-label>
                    <input placeholder="Building Addr1"
                           matInput
                           [matChipInputFor]="chipBuildingAddr1"
                           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                           [matChipInputAddOnBlur]="addOnBlur"
                           (matChipInputTokenEnd)="applyFilter($event, 'buildingaddr1')">
                </mat-form-field>
                <mat-chip-list #chipBuildingAddr1>
                    <mat-chip *ngFor="let item of buildingAddr1" [selectable]="selectable"
                              [removable]="removable" (removed)="remove(item, 'buildingaddr1')">
                        {{item}}
                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </td>
            <td style="">
                <mat-form-field class="search-input">
                    <mat-label>Building City</mat-label>
                    <input placeholder="Building City"
                           matInput
                           [matChipInputFor]="chipBuildingCities"
                           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                           [matChipInputAddOnBlur]="addOnBlur"
                           (matChipInputTokenEnd)="applyFilter($event, 'buildingcities')">
                </mat-form-field>
                <mat-chip-list #chipBuildingCities>
                    <mat-chip *ngFor="let item of buildingCities" [selectable]="selectable"
                              [removable]="removable" (removed)="remove(item, 'buildingcities')">
                        {{item}}
                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </td>
            <td style="">
                <mat-form-field class="search-input">
                    <mat-label>Elevator Nickname</mat-label>
                    <input placeholder="Elevator Nickname"
                           matInput
                           [matChipInputFor]="chipElevatorNicknames"
                           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                           [matChipInputAddOnBlur]="addOnBlur"
                           (matChipInputTokenEnd)="applyFilter($event, 'elevatornicknames')">
                </mat-form-field>
                <mat-chip-list #chipElevatorNicknames>
                    <mat-chip *ngFor="let item of elevatorNicknames" [selectable]="selectable"
                              [removable]="removable" (removed)="remove(item, 'elevatornicknames')">
                        {{item}}
                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </td>
            <td class="middle-align">
                <button type="button" class="btn mat-flat-button btn-primary" (click)="onFilter()">
                    {{ 
                    changeTypeButton() ? 'Reset' : 'Filter' 
                    }}
                </button>
            </td>
        </tr>
        </form>
    </mat-expansion-panel>
</mat-accordion>