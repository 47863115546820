import { Component, Inject, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { DialogService } from "../../../services/dialog.service";
import { Utils } from "../../../utils/utils";
import { HttpClient } from "@angular/common/http";
import { DefaultValuesService } from "../../../services/defaultvalues.service";
import { MatDialog } from "@angular/material/dialog";
import { ServiceTicketCategoryFormComponent } from "./serviceticketcategory-form.component";
import { CustomDialogService } from '../../../services/customdialog.service';
import { ContractTypeFormComponent } from "./contracttype-form.component";
import { MatTableDataSource } from "@angular/material/table";

@Component({
    selector: "contracttype-list",
    templateUrl: './contracttype-list.component.html',
    styleUrls: ['./contracttype-list.component.less']
})

export class ContractTypeListComponent implements OnInit {
    columnsToDisplay = ['ItemNo', 'TypeLocale', 'ContractType', 'isNew', 'actions'];
    list: any[] = [];

    public dataSource = new MatTableDataSource<any>();
    subscriptionControllerType: any;

    Filter = 0;


    constructor(
        private http: HttpClient
        , private dialogService: DialogService
        , private defaults: DefaultValuesService
        , @Inject('BASE_URL') private baseUrl: string
        , private dialog: MatDialog
        , private dialogForm: MatDialog
        , private customDialog: CustomDialogService

    ) {

    }



    ngOnInit() {
        this.dialogService.tthis = this;
        this.subscriptionControllerType = this.defaults.controllerTypeMessageChanges$.subscribe((item: ControllerType) => this.onControllerChanged(item));
        this.refresh();


    }

    ngOnChanges() {

        this.dataSource.data = this.list
    }

    onControllerChanged(ct: ControllerType) {
        this.refresh();
    }

    onRowEditInit(element) {

        const Dialog = this.dialogForm.open(ContractTypeFormComponent, {
            panelClass: 'custom-mat-dialog',
            disableClose: false,
            data: { "TypeLocale": element.TypeLocale, "ContractTypeId": element.ContractTypeId, "ContractType": element.ContractType, "TenantID": element.TenantId }
        });
        Dialog.afterClosed().subscribe(result => {
            if (result) this.refresh();
        });

    }
    onMoreInfo(element) {
        //let url = baseUrl + API_CDP_CONTROLLER_TYPE_MAPPING + "all/" + filter.toString();
        //http.get<ControllerTypeMapping[]>(url).subscribe(result => {
        //    if (tthis && callback)
        //        callback(tthis, result);
        //}, error => {
        //    Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        //}); //console.error(error));

        //let url = this.baseUrl + Utils.getCDPControllerTypeMappingAPI() + "referencedata/" + element._ControllerTypeMappingID;

        //this.http
        //    .get<controllerinfo>(url, {})
        //    .subscribe(res => {
        //        this.refresh();
        //    }, error => {
        //        Utils.writeLog(this.http, this.baseUrl, error); //Utils.showErrorDialog(null, error);

        //    }); //console.error(error));



        this.customDialog.openDisplayInfoDialog({
            title: element.ControllerTypeLocal,
            message: 'More information related to this controller',
            caller: this
        });

    }
    onDelete(element) {
        const tthis = this;

        this.customDialog.openConfirm({
            title: "DELETE HARMONIZED CORRESPONDENCE",
            message: 'Local contract type "' + element.TypeLocale + '" to "' + element.ContractType + '" corresponce will be deleted. Continue ?',
            caller: this
        });


        this.customDialog.confirmed().subscribe(res => {

            if (res.confirmed) {
                let TypeLocale = encodeURIComponent(element.TypeLocale);

                let url = this.baseUrl + "api/BackendHarmonization/contracttype_delete" ;
                const body = {
                    'TenantId': element.TenantId,
                    'TypeLocale': TypeLocale
                }
                this.http
                    .post<boolean>(url, body)
                    .subscribe(() => {
                        this.refresh();
                    }, error => {
                        Utils.writeLog(this.http, this.baseUrl, error); //Utils.showErrorDialog(null, error);

                    }); //console.error(error));
            }
        })

    }

    private btn_new() {
        this.dialogForm.open(ServiceTicketCategoryFormComponent, {
            panelClass: 'custom-mat-dialog',
            disableClose: false,
            data: { "isCustomerPortal": "A", "type": "B", "entity": "C" }
        });


    }


    public filterColumn(value: any, name: string) {
        switch (name) {
            case 'status':
                this.Filter = value.value;
        }
        this.refresh()
    }

    private btn_refresh() {
        this.refresh();

        //this.dialogForm.open(AzDataFactoryFormComponent, {
        //    panelClass: 'custom-mat-dialog',
        //    disableClose: true,
        //    data: { "isCustomerPortal": 0, "type": "", "entity": "Customer Portal" }
        //});


    }

    private refresh() {
        const tthis = this;
        /**
            * load all the data
            * */

        const url = this.baseUrl + "api/BackendHarmonization/contracttype_" + "all/" + this.Filter.toString();


        this.http.get<any[]>(url).subscribe(result => {
            tthis.list = result;
            tthis.dataSource.data = tthis.list
        }, error => {
            Utils.writeLog(this.http, this.baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));



        //Utils.httpGetAllControllerTypeLocal(
        //    this.http
        //    , this.baseUrl
        //    , this.Filter
        //    , this
        //    , function (tthis: any, values: ControllerTypeVMapping[]) {
        //        tthis.list = values;
        //        tthis.dataSource.data = tthis.list
        //    }
        //);
    }

    private sinceDays(date: Date) {
        let currentDate = new Date();

        let dateDeployment = new Date(date);
        return Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())
            - Date.UTC(dateDeployment.getFullYear(), dateDeployment.getMonth(), dateDeployment.getDate())) / (1000 * 60 * 60 * 24));
    }
}