import { Component, Inject, OnInit, Input } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Utils } from "../../utils/utils";
import { DialogService } from "../../services/dialog.service";
import { SaveService } from "../../services/save.service";

@Component({
    selector: "maintenance-control-plan-group-action-new",
    templateUrl: './mcp-new-group-action.component.html',
    styleUrls: ['../../style/form.component.less']
})

export class MaintenanceControlPlanGroupActionNewComponent implements OnInit {

    ACTION: number = 0;

    @Input() header: any[] = [];
    @Input() mcpgroup: MaintenanceControlPlanGroup = <MaintenanceControlPlanGroup>{};
    @Input() list: MaintenanceControlPlanGroupAction[] = [];
    @Input() rowIndex: number = -1;
    @Input() newRow: boolean = true;

    title: string | undefined;

    selected: MaintenanceControlPlanGroupAction = <MaintenanceControlPlanGroupAction>{};

    classname: String[] = [];
    isundodisabled: boolean[] = [];

    form!: FormGroup;

    constructor(
        private http: HttpClient
        , private fb: FormBuilder
        , @Inject('BASE_URL') private baseUrl: string
        , private dialog: DialogService
        , private saveService: SaveService
    ) {
        this.createForm();
    }

    private onFieldChange(index: number, field1: any, field2: any): boolean {
        return this.saveService.onFieldChange(index, field1, field2, this);
    }

    private onFieldUndo(index: number) {
        this.saveService.onFieldUndo(index, this);
    }

    ngOnInit() {

        this.dialog.tthis = this;

        this.saveService.addListener(this);

        this.title = "Create Maintenance Control Plan Group Action for " + this.mcpgroup.MCPGroup;
        this.saveService.initUndo(this); //it calls the method this.createForm()

        if (!this.newRow)
        {
            this.selected.ActionDescription = this.list[this.rowIndex].ActionDescription;
            this.selected.MCPGroupID = this.list[this.rowIndex].MCPGroupID;
            this.updateForm();
        }
    }

    createForm() {
        this.form = this.fb.group({
            Action: ['', Validators.required]
        });
    }

    onCancel(index: number) {
        if (!this.newRow && index == this.rowIndex) {
            this.onActionUndo();
        }
    }

    onActionChange() {
        if (this.form && this.onFieldChange(this.ACTION, this.form.value.Action, this.selected.ActionDescription) && this.selected)
            this.list[this.rowIndex].ActionDescription = this.form.value.Action;
    }

    onActionUndo() {
        if (this.rowIndex != -1)
            this.list[this.rowIndex].ActionDescription = this.selected.ActionDescription;
        this.onFieldUndo(this.ACTION);
    }

    //onDelete() {
    //    this.dialog.showConfirmDialog( this.selected.ActionDescription + "(" + this.selected.MCPActionID + ")?", "Delete MCP Group Action");
    //}

    onRowCancel() {
        this.saveService.fireCancel(this.rowIndex, this);
    }

    onSave() {
        if (this.form && this.form.valid) {
            if (this.newRow) {
                this.selected.MCPGroupID = this.mcpgroup.MCPGroupID;
                this.selected.ActionDescription = this.form.value.Action;

                this.http
                    .put<MaintenanceControlPlanGroupAction>(
                        this.baseUrl + Utils.getMaintenanceControlPlanAPI() + "addgroupaction"
                        , this.selected
                    )
                    .subscribe(
                        res => this.list.unshift(res)
                        , error => this.dialog.showErrorDialog(error)
                    );
            }
            else {
                var components = this.list[this.rowIndex].Components;
                this.http
                    .post<MaintenanceControlPlanGroupAction>(
                        this.baseUrl + Utils.getMaintenanceControlPlanAPI() + "editgroupaction"
                        , this.list[this.rowIndex]
                    )
                    .subscribe(
                        res => {
                            this.list[this.rowIndex] = res;
                            this.list[this.rowIndex].Components = components;
                        }
                        , error => this.dialog.showErrorDialog(error)
                    );
            }
        }
        else
            this.dialog.showErrorStringDialog("Error Saving data!");
    }

    //onYes() {
    //    this.http
    //        .delete<MaintenanceControlPlanGroupAction>(
    //            this.baseUrl + Utils.getMaintenanceControlPlanAPI() + "groupaction/" + this.selected.MCPActionID
    //        )
    //        .subscribe(res => {
    //            var v = res;
    //            this.onBack();
    //            //this.dialog.showSuccessDialog( "Maintenance Control Plan Group Action - " + this.selected.MCPActionID + "(" + this.selected.MCPGroupID + ") successfully deleted", this.title);
    //        }, error => this.dialog.showErrorDialog( error));
    //}

    updateForm() {
        if (this.form) {
            this.form.setValue({
                Action: this.list[this.rowIndex].ActionDescription
            });
        }
    }
}