import { Component, Inject, OnInit, OnDestroy } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DefaultValuesService } from "../../services/defaultvalues.service";
import { Utils } from "../../utils/utils";
import { DialogService } from "../../services/dialog.service";
import { SaveService } from "../../services/save.service";
import { MatDialog } from "@angular/material/dialog";

@Component({
    selector: "maintenancewarning",
    templateUrl: './maintenancewarning.component.html',
    styleUrls: ['../../style/container-tab-list.component.less', './maintenancewarning.less']
})

export class MaintenanceWarningComponent implements OnInit, OnDestroy {

    title: string;
    list: MaintenanceWarning[] = [];

    subscriptionLanguage: any;

    constructor(
        private http: HttpClient
        , private dialogService: DialogService
        , private saveService: SaveService
        , @Inject('BASE_URL') private baseUrl: string
        , private dialog: MatDialog
        , private defaults: DefaultValuesService
    ) {
        this.title = "Maintenance Warnings";
    }

    ngOnInit() {
        this.dialogService.tthis = this;
        this.subscriptionLanguage = this.defaults.languageMessageChanges$.subscribe((item: Language) => this.onLanguageChanged(item));
        this.refresh();
    }

    ngOnDestroy() {
        this.subscriptionLanguage.unsubscribe();
    }


    public refresh() {
        Utils.httpGetAllWarnings(
            this.http
            , this.baseUrl
            , this
            , function (tthis: any, list: MaintenanceWarning[]) {
                tthis.list = list;
            }
        );
    }
    onLanguageChanged(ct: Language) {
        this.refresh();
    }
}