import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Utils } from '../utils/utils';
import { DefaultValuesService } from './defaultvalues.service';
import { FilterService } from './filter.service';

@Injectable()
export class WaitingService {

    classwaiting: string = Utils.getDialogHideClassName();
    classopacity: string = "";

    constructor(
        public defaults: DefaultValuesService
        , private filtersservice: FilterService 
    ) {
        this.defaults.classname = "";
    }

    public toWait(value: boolean) {

        this.defaults.classname = this.classopacity = value == true ? Utils.getOpacityClassName() : "";
        this.classwaiting = value == true ? Utils.getWaitingClassName() : Utils.getDialogHideClassName();
    }
}