<div class="example-container-backendsetup">
    <h2>Technical User</h2>
    <table mat-table
           [dataSource]="dataSource"
           multiTemplateDataRows
           matSort
           class="mat-elevation-z8 mat-table-custom" id="technicaluser-list">

        <ng-container matColumnDef="user">
            <th mat-header-cell *matHeaderCellDef> Email </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.Email"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef> Created </th>
            <td mat-cell *matCellDef="let element">
                <mat-checkbox [checked]="element.Created" [disabled]="true"></mat-checkbox>

            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button matTooltip="Create" *ngIf="!element.Created" (click)="createTechnicalUser(element.Email)">
                    <mat-icon>add</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Reset password" *ngIf="element.Created" (click)="resetPassword(element.Email)">
                    <mat-icon>vpn_key</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; let i = dataIndex;
            columns: columnsToDisplay;"
            class="opmode-item">
        </tr>
    </table>
</div>