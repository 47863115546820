import { Component, Inject, OnInit, OnDestroy, ViewChild, Input, AfterViewInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Utils } from "../../utils/utils";
import { DefaultValuesService } from "../../services/defaultvalues.service";
import { DialogService } from "../../services/dialog.service";
import { SaveService } from "../../services/save.service";
import { Router } from "@angular/router";
import { ErrorCodeListPropertiesComponent } from './errorcode-list-properties.component';
import { ErrorCodeListDescriptionComponent } from './errorcode-list-description.component';
import { ControllerTypesDeploymentStatusPopup } from "../field/controllertypes/controllertypes-deploymentstatus-popup.component";
import { MatDialog } from "@angular/material/dialog";
import { MatTabChangeEvent, MatTabGroup } from "@angular/material/tabs";

@Component({
    selector: "errorcode",
    templateUrl: './errorcode.component.html',
    styleUrls: ['../../style/container-tab-list.component.less']
})

export class ErrorCodeComponent implements OnInit, OnDestroy {
    @Input('isDigitalServicesView') isDigitalServicesView = false;

    title = "Error Codes";

    @ViewChild(ErrorCodeListPropertiesComponent, { static: true }) properties!: ErrorCodeListPropertiesComponent;
    @ViewChild(ErrorCodeListDescriptionComponent, { static: true }) descriptions!: ErrorCodeListDescriptionComponent;
    @ViewChild('tabGroup') tabGroup!: MatTabGroup;

    firstIndex = -1;
    errorcodes: ErrorCode[] = [];
    errorcodesAll: ErrorCode[] = [];

    errorcodesnotmapped: ErrorCodeNotMapped[] = [];
    newMode: SaveService = <SaveService>{};
    numberOfChecks = 0;
    txtNewErrorCode = "0";

    // Allen
    errorcodesml: ErrorCodeML[] = [];
    possiblefailuremlhistories: PossibleFailureMLUndoHistory[] = [];

    subscriptionLanguage: any;

    keywords: string[] = [];


    constructor(
        private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , public defaults: DefaultValuesService
        , private dialog: DialogService
        , private matDialog: MatDialog


    ) { }

    ngAfterViewInit() {
        this.defaults.defaultComponentTab(this)
    }

    ngOnInit() {
        this.dialog.tthis = this;
        this.newMode = new SaveService();
        this.subscriptionLanguage = this.defaults.languageMessageChanges$.subscribe(async (language: Language) => { await this.getAll() });

        this.getAll();
    }

    ngOnDestroy() {
        this.subscriptionLanguage.unsubscribe();
    }


    equals(value: ErrorCode, value2: ErrorCode): boolean {
        return value.ErrorCodeID === value2.ErrorCodeID;
    }

    // Allen: Test async
    async getAll() {
        await Utils.httpGetAllErrorCodes(
            this.http
            , this.baseUrl
            , this
            , function (tthis: any, list: ErrorCode[]) {
                tthis.errorcodes = list;

                list.forEach(x => {
                    tthis.errorcodesAll.push(x);
                });

            }
        );

        Utils.httpGetAllTroubleShootingKeywords(
            this.http
            , this.baseUrl
            , this
            , function (tthis: any, list: string[]) {
                tthis.keywords = list;
            }
        );

        this.getAllErrorCodesNotMapped();
    }

    getAllErrorCodesNotMapped() {
        Utils.httpGetAllErrorCodesNotMapped(
            this.http
            , this.baseUrl
            , this
            , function (tthis: any, list: ErrorCodeNotMapped[]) {
                tthis.errorcodesnotmapped = list;
            }
        );
    }

    onTenantChanged(t: string) {
        this.getAll();
    }

    onControllerChanged(ct: ControllerType) {
        this.getAll();
    }

    /**
     * copy the error code not mapped
     * 
     * @param evt
     */
    onCopyErrorCode(evt: { index: number, value: ErrorCodeNotMapped }) {
        this.txtNewErrorCode = evt.value.ErrorCode.toString();
        this.onNew();
    }

    onChecked(value: any) {
        //if (value.Checked) {
        //    this.selected = value.Value;
        //    this.selectedIndex = value.Index;
        //}
        this.firstIndex = value.FirstCheckedIndex;
        this.numberOfChecks = value.NumberOfChecks;
    }

    onLanguageChanged(l: Language) {
        //console.log("Lang changed: " + l.Name);
        this.getAll();
    }

    onNew() {
        this.newMode.fireNew();
        //this.newMode = true;
    }

    onFilter(values: ErrorCode[]) {
        var tthis = this;
        if (values.length != 0)
            this.errorcodes = this.errorcodesAll.filter(
                function (value: any, index: number, array: any[]) {
                    var valueInList = value;
                    var elem = values.find(
                        function (value: ErrorCode, index: number, obj: ErrorCode[]) {
                            return tthis.equals(value, valueInList);
                        }
                    );
                    if (elem)
                        return true;
                    return false;
                }
            );
        else {
            this.errorcodesAll.forEach(x => { this.errorcodes.push(x); })
        }
    }

    onResetList() {
        this.getAll();
    }

    onSearchList(obj: any) {
        //{ ShowRef: boolean, Index: number, Value: string }
        var l = <ErrorCode[]>[];
        var value = obj.Value;
        for (var i = 0; i < this.errorcodes.length; ++i) {
            
            switch (obj.Index) {
                case 0:
                    if (this.errorcodes[i].ErrorCode) {
                        if (this.errorcodes[i].ErrorCode.toString().indexOf(obj.Value) != -1)
                            l.push(this.errorcodes[i]);
                    }
                    break;
                case 1:
                    if (obj.ShowRef) {
                        if (this.errorcodes[i].Description_ref)
                            if (this.errorcodes[i].Description_ref.toLowerCase().indexOf(obj.Value.toLowerCase()) != -1)
                                l.push(this.errorcodes[i]);

                    }
                    break;
                default:
                    if (this.errorcodes[i].Description)
                        if (this.errorcodes[i].Description.toLowerCase().indexOf(obj.Value.toLowerCase()) != -1)
                            l.push(this.errorcodes[i]);
                    break;

            }
        }
        //if (l.length != 0)
            this.errorcodes = l;
    }

    onSuccess(ec: ErrorCode) {
        this.getAll();
        //this.onCancel();
    }

    onSuccessResolution(ec: ErrorCodeResolution) {
        //this.getAll();
        this.errorcodes[this.firstIndex].ErrorCodeResolutions.push(ec);
        //this.onCancelResolution();
    }
    onUpdateDescriptions() {
        this.properties.updatefilter()
    }
    onUpdateTable() {
        this.descriptions.updatefilter()
    }
    

    tabClick(event: MatTabChangeEvent) {
        const tab = event.tab.textLabel;
        // console.log(tab);
        switch (tab) {
            case "ML Suggestions":
                if (this.errorcodesml.length === 0) {
                    Utils.httpGetErrorCodesML(
                        this.http
                        , this.baseUrl
                        , this
                        , function (tthis: any, list: ErrorCodeML[]) {
                            tthis.errorcodesml = list;
                        }
                    );
                }
                break;
            case "ML Suggestions History":
                //if (this.possiblefailuremlhistories.length === 0) {
                // Should always try to refresh when switch to that tab.
                // Or we need to use DataSource object instead of array as data source.
                Utils.httpGetPossibleFailureMLUndoHistories(
                    this.http
                    , this.baseUrl
                    , this
                    , function (tthis: any, list: PossibleFailureMLUndoHistory[]) {
                        tthis.possiblefailuremlhistories = list;
                    }
                );
                //}
                break;
            
            default:
        }
    }
    onReload() {
        this.getAll()
    }
    openDeploymentInformationPopup() {
        const dialogRef = this.matDialog.open(ControllerTypesDeploymentStatusPopup, {
            panelClass: 'custom-mat-dialog',
            data: { "component": 'errorcodes' } });
    }
}
