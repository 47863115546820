import { Component, Output, Inject, EventEmitter, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { DefaultValuesService } from '../../services/defaultvalues.service';
import { WaitingService } from '../../services/waiting.service';
import { DialogService } from '../../services/dialog.service';
import { SaveService } from '../../services/save.service';
import { Utils } from '../../utils/utils';

@Component({
    selector: 'tenant',
    template: `Tenant:<br />

                <ng-select  (change)="onSelect($event)" style="width:240px; border: 1px white solid" class="top-main-select"
                    [items]="tenantsList"
                   bindLabel="Label"
                   bindValue="Tenant"
                   [closeOnSelect]="true"
                   [searchable]="true"
                   [(ngModel)]="defaults.tenant">
                    <ng-template ng-option-tmp let-item="item">
                        {{ item.CountryName }} - ({{ item.Tenant }})
                      </ng-template>
                </ng-select>`,
    styleUrls: ['../../style/mat-select.component.less']
})
export class TenantComponent implements OnInit {
    @Input() isTenDlrCong: boolean = false;
    @Output() tenantChanged = new EventEmitter<string>();
    value = '';

    public filteredTenants: Tenant[] = [];
    public tenantsOpt: Tenant[] = [];
    public globalfilteredTenants: Tenant[] = [];
    public gloablTen: Tenant =
        { Tenant: 'ZZ', CountryName: 'Global', AllBranches: 0, Label: 'Global Defaults - (ZZ)' }


    /** Subject that emits when the component has been destroyed. */
    protected _onDestroy = new Subject<void>();

    constructor(
        private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , public defaults: DefaultValuesService
        , private waiting: WaitingService
        , private dialog: DialogService
        , public saveService: SaveService
    ) {
    }

    ngOnInit() {
        this.dialog.tthis = this;
        this.defaults.languageMessageChanges$.subscribe(() => {
            this.tenantsOpt = this.filteredTenants = this.defaults.tenants
            this.globalfilteredTenants = [...this.defaults.tenants]
            this.globalfilteredTenants.push(this.gloablTen);
        });
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }
    ngOnChanges() {
        if (this.defaults.isDESConfAdmUser) {
            //this.updateTenants();
            this.resetFilteredTenanats();
        }
    }

    changeTenant() {
        //FOr the ZZ donot make the request to backend as it does not have the details of the tenant 
        // We just want to update the selector so that when we request for dialer it can use the value of global 
        if (this.defaults.tenant == 'ZZ') {
            this.defaults.notifyCurrentTen(this.defaults.tenant)
        }
        else {
            const url = this.baseUrl + Utils.getTenantAPI();
            this.defaults.tenant = this.value;
            this.http
                .post<string>(url, { 'Tenant': this.defaults.tenant })
                .subscribe(() => {
                    this.tenantChanged.emit(this.defaults.tenant);
                    this.defaults.onTenantChanged.emit(this.defaults.tenant);
                    this.defaults.notifyTenantChange(this.defaults.tenant);
                    this.refreshControllerTypes();
                    Utils.getClusterComponent(this.http, this.baseUrl, this,
                        function (tthis: TenantComponent, res: any) {
                            tthis.defaults.isActualReportingEnabled = res.IsActualReportingEnabled
                            tthis.defaults.isMonthlyOPLEnabled = res.IsMonthlyOplEnabled
                            tthis.defaults.isYearlyOPLEnabled = res.IsYearlyOplEnabled
                            tthis.defaults.componentTool = res.ComponentTool
                            tthis.defaults.menuClusters = res.Clusters
                            tthis.defaults.components = [];

                            let counter = 0;
                            res.Clusters.forEach(x => {
                                x.ClusterId = counter;
                                if (x.ClusterComponents) {
                                    x.ClusterComponents.forEach(y => {
                                        tthis.defaults.components.push({ ...y, ClusterName: x.DisplayName, ClusterId: counter })
                                    }
                                    )
                                }
                                else {
                                    tthis.defaults.components.push({ ...x, ClusterId: counter })
                                }
                                counter++;
                            })
                        }
                    );
                }, error => Utils.writeLog(this.http, this.baseUrl, error));
        }
    }

    onNo() {
        this.saveService.fireCancelAll();
        this.changeTenant();
    }

    onSelect(tenant: any) {
        if (!tenant || !this.defaults.tenants)
            return;
        this.value = tenant.Tenant;
        if (this.saveService.isSaveButtonShown())
            this.dialog.showConfirmDialog("Do you want to save the changes? If you select No, all changes will be lost!", "Save", this);
        else
            this.onNo();
    }

    onYes() {
        this.saveService.fireSave();
        this.changeTenant();
    }

    refreshControllerTypes() {
        Utils.httpGetAllControllerTypesByTenant(
            this.http
            , this.baseUrl
            , this
            , this.defaults.tenant
            , function (tthis: TenantComponent, list: ControllerType[]) {
                if (list !== undefined) {
                    tthis.defaults.controllertypes = list;
                    Utils.httpGetSelectedControllerType(
                        tthis.http
                        , tthis.baseUrl
                        , tthis
                        , function (tthis: TenantComponent, value: ControllerType) {
                            tthis.defaults.controllertype = value;
                            tthis.defaults.notifyControllerTypeChange(tthis.defaults.controllertype);
                            tthis.refreshLanguages();
                        }
                    );
                }
            }
        );
        Utils.httpGetAllControllerTypesByTenantDigitalServices(
            this.http
            , this.baseUrl
            , this
            , this.defaults.tenant
            , function (tthis: TenantComponent, list: ControllerType[]) {
                if (list !== undefined) {
                    tthis.defaults.controllertypesdigitalservices = list;
                }
            }
        );
    }

    // Allen: Copy from controllertype.component and need to think of a way to reduce duplicate code.
    refreshLanguages() {
        /**
        * get all languages for this user/ct
        * */
        Utils.httpGetLanguageControllerTypeOperationMode(
            this.http
            , this.baseUrl
            , this
            , function (tthis: TenantComponent, list: Language[]) {
                if (list !== undefined) {
                    tthis.defaults.languages = list;

                    /**
                        * get selected language in server
                        * */
                    Utils.httpGetSelectedLanguage(
                        tthis.http
                        , tthis.baseUrl
                        , tthis
                        , function (tthis: TenantComponent, value: Language) {
                            tthis.defaults.language = value;
                            tthis.defaults.notifyLanguageChange(tthis.defaults.language);
                        }
                    );
                }
            });
    }

    //get isTenDlrCong(): boolean {
    //    return window.location.href.indexOf('tenantdialerconfig')  > 0
    //}
    //updateTenants() {
    //    this.tenantsOpt = this.isTenDlrCong ? this.globalfilteredTenants : this.filteredTenants;
    //}
    get tenantsList(): Tenant[] {
        return this.isTenDlrCong ? this.globalfilteredTenants : this.filteredTenants;
    }
    resetFilteredTenanats() {
        if (this.defaults.tenant == "ZZ") {
            this.defaults.tenant = this.defaults.tenants[0]?.Tenant;
        }
    }
}