<div class=" UMP_custom">

    <div class="row" style="background-color: transparent;">
        <div class="col-lg-12">

            <div class="panel" style="background-color: transparent;">
                <div class="panel-body">
                    <div class="row">
                        <div class="col-lg-12 no-padding">

                            <button *ngIf="!defaults.isDataAnalytStage()" mat-raised-button class="btn btn-labeled-right pull-right button-color-fix" (click)="addUserTrigger()">
                                Add User
                                <mat-icon class="icon-color-fix">group_add</mat-icon>
                            </button>

                            <button mat-raised-button class="btn btn-labeled-right pull-right button-color-fix"
                                    *ngIf="defaults.stage === 1 && defaults.isPBIWorkspaceCreatorUser"
                                    (click)="openPowerbiWorkspacePopup()" style="margin-right:5px">
                                Create PowerBI Workspace
                                <mat-icon class="icon-color-fix">science</mat-icon>
                            </button>

                        </div>
                    </div>
                </div>

                <div class="panel-body" style="background-color: transparent;">
                    <div class="row">
                        <form id="filter-users-frm" class="form-horizontal">
                            <div class="form-group">
                                <div class="col-lg-12">
                                    <div class="col-lg-2" *ngIf="defaults.componentTool === componentToolEnum.Rulebook && !defaults.isDataAnalytStage()">
                                        <label class="control-label">
                                            Tenant
                                        </label>
                                        <span class="glyphicon glyphicon-info-sign" data-toggle="tooltip" title="Country Code (following two characters ISO convention). This filter specifies user's primary or secondary tenant." aria-hidden="true"></span>
                                        <br />

                                        <ng-select (change)="onNgSelectChange()"
                                                   [matTooltip]="selectedTenantLabels.toString()"
                                                   [matTooltipPosition]="'above'"
                                                   [items]="defaults.umpFilters.tenants"
                                                   [multiple]="true"
                                                   groupBy="selectedAllGroup"
                                                   [selectableGroup]="true"
                                                   [selectableGroupAsModel]="false"
                                                   [closeOnSelect]="false"
                                                   [searchable]="true"
                                                   bindLabel="text"
                                                   bindValue="value"
                                                   [(ngModel)]="selectedTenants"
                                                   name="selectTenants">

                                            <ng-template ng-optgroup-tmp>
                                                Select All
                                            </ng-template>

                                            <ng-template ng-option-tmp
                                                         let-item="item">
                                                {{ item.text }}
                                            </ng-template>

                                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                                <div class="items-selected" *ngIf="selectedTenants.length <= 2">
                                                    <div class="ng-value" *ngFor="let item of selectedTenantLabels">
                                                        <span class="ng-value-label">{{item}}</span>
                                                    </div>
                                                </div>

                                                <div class="ng-value" *ngIf="selectedTenants.length > 2">
                                                    <span class="ng-value-label">{{selectedTenants.length}} Selected...</span>
                                                </div>
                                            </ng-template>
                                            <ng-template ng-option-tmp let-item="item">
                                                <span class="ng-option-label" [attr.title]="item.text">{{ item.text }}</span>
                                            </ng-template>
                                        </ng-select>
                                    </div>
                                    <div class="col-lg-2" *ngIf="defaults.componentTool === componentToolEnum.Rulebook && !defaults.isDataAnalytStage()">
                                        <label class="control-label">
                                            MAX Roles
                                        </label>
                                        <span class="glyphicon glyphicon-info-sign" data-toggle="tooltip" title="Role used for authorization level in MAX project. They are tenant based (e.g. ES_SystemAdmin)" aria-hidden="true"></span>
                                        <br />

                                        <ng-select (change)="onNgSelectChange()"
                                                   [matTooltip]="selectedRoleLabels.toString()"
                                                   [matTooltipPosition]="'above'"
                                                   [items]="defaults.umpFilters.personas"
                                                   [multiple]="true"
                                                   [closeOnSelect]="false"
                                                   [searchable]="true"
                                                   bindLabel="text"
                                                   bindValue="value"
                                                   [(ngModel)]="selectedRoles" name="selectRoles">

                                            <ng-template ng-multi-label-tmp let-items="selectedRoles" let-clear="clear">
                                                <div class="items-selected" *ngIf="selectedRoles.length <= 2">
                                                    <div class="ng-value" *ngFor="let item of selectedRoleLabels">
                                                        <span class="ng-value-label">{{item}}</span>
                                                    </div>
                                                </div>

                                                <div class="ng-value" *ngIf="selectedRoles.length > 2">
                                                    <span class="ng-value-label">{{selectedRoles.length}} Selected...</span>
                                                </div>

                                            </ng-template>

                                            <ng-template ng-option-tmp let-item="item">
                                                <span class="ng-option-label" [attr.title]="item.text">{{ item.text }}</span>
                                            </ng-template>

                                        </ng-select>

                                    </div>
                                    <div class="col-lg-2" *ngIf="defaults.componentTool === componentToolEnum.Rulebook && !defaults.isDataAnalytStage()">
                                        <label class="control-label">
                                            Data Analytics
                                        </label>
                                        <span class="glyphicon glyphicon-info-sign" data-toggle="tooltip" title="Group in charge of managing the access to the Common Data Platform reports and components. It is composed of the back bone groups (e.g. FLD_EA_ES ) which grant general access to the resources below and workspaces names (<BU>_<OU>_<OSU>_<GF> Workspace Name). The suffix ..-ROLE_DATACONTRIBUTOR grants admin/contributor access to the desired resource. In order to check which CDP groups grant access to a specific PowerBI Workspace, please check the Governance report provided by the MAX team." aria-hidden="true"></span>
                                        <br />
                                        <ng-select (change)="onNgSelectChange()"
                                                   [matTooltip]="selectedCdpGroupLabels.toString()"
                                                   [matTooltipPosition]="'above'"
                                                   [items]="defaults.umpFilters.cdpList"
                                                   [multiple]="true"
                                                   [closeOnSelect]="false"
                                                   [searchable]="true"
                                                   bindLabel="text"
                                                   bindValue="value"
                                                   [(ngModel)]="selectedCdpGroups" name="selectCdpGroups">

                                            <ng-template ng-multi-label-tmp let-items="selectedCdpGroups" let-clear="clear">
                                                <div class="items-selected" *ngIf="selectedCdpGroups.length <= 2">
                                                    <div class="ng-value" *ngFor="let item of selectedCdpGroupLabels">
                                                        <span class="ng-value-label">{{item}}</span>
                                                    </div>
                                                </div>
                                                <div class="ng-value" *ngIf="selectedCdpGroups.length > 2">
                                                    <span class="ng-value-label">{{selectedCdpGroups.length}} Selected...</span>
                                                </div>
                                            </ng-template>
                                            <ng-template ng-option-tmp let-item="item">
                                                <span class="ng-option-label" [attr.title]="item.text">{{ item.text }}</span>
                                            </ng-template>
                                        </ng-select>
                                    </div>
                                    <div [class.move-right]="defaults.isDataAnalytStage() " class="col-lg-6 ">
                                        <div class="col-lg-8">
                                            <label class="control-label">Search String</label>
                                            <br />
                                            <input class="CustomSearchInput" type="text" [(ngModel)]="queryFilter" [value]="queryFilter" name="searchString">
                                            <!--<button matSuffix mat-icon-button aria-label="Clear">
                                                <mat-icon class="search-clr">close</mat-icon>
                                            </button>-->

                                        </div>
                                        <div class="col-lg-4 break-heigt">
                                            <br />
                                            <br />
                                            <button mat-raised-button class="btn btn-labeled-right IndexMarginTop IndexMarginLeft button-color-fix" (click)="loadUsersByFilter()">
                                                Search
                                                <mat-icon class="icon-color-fix">person_search</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="col-lg-12">
    <umphome-list *ngIf="users !== null && users.length > 0" [users]="users" [totalResultsCount]="totalResultsCount"></umphome-list>
    <h5 *ngIf="users.length === 0 && !isLoading">Results are shown after a search is performed.</h5>
    <div *ngIf='isLoading' class="blackbg card__desc">
        <div *ngFor="let _ of [].constructor(8)" class="card__desc-text lg"></div>
    </div>
</div>


