import { Component, Inject, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Utils } from "../../../utils/utils";
import { DefaultValuesService } from "../../../services/defaultvalues.service";
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: "backendharmonization",
    templateUrl: './backendharmonization.component.html',
    styleUrls: ['../../../style/container-tab-list.component.less']
})




export class BackendHarmonizationComponent {

    title: string;
    selectedItem: number  =0
    constructor(
        private defaults: DefaultValuesService,
        private activatedRoute: ActivatedRoute
    ) {
        this.title = "Backend values harmonization";
    }

    ngOnInit() {
        switch (+this.activatedRoute.snapshot.params["entity"]) {
            case 4: { this.selectedItem = 1; console.log(1) } break;
            case 6: { this.selectedItem = 0; console.log(1) } break;
            default: this.selectedItem = 0; break;
        }
    }

}