import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'chunk'
})
export class ChunkPipe implements PipeTransform {
    transform(array: any[], size: number): any[][] {
        if (!array || !Array.isArray(array)) {
            return [];
        }

        const chunks: any[] = [];
        for (let i = 0; i < array.length; i += size) {
            chunks.push(array.slice(i, i + size));
        }

        return chunks;
    }
}