<div class='left-menu'>
    <ul class='nav navbar-nav'>
        <li [routerLinkActive]="['link-active']" [ngClass]="!defaults.isReadonlyUser ? 'show': 'hide'">
            <a class='menu-callback' (click)="openNewConfigurationPopup()" title="Create a new Configuration">
                <span class='glyphicon glyphicon-plus-sign'></span> Add Controller Type
            </a>
        </li>
        <li [routerLinkActive]="['link-active']">
            <a class='menu-callback' (click)="onRefresh.emit()" title="Refresh">
                <span class='glyphicon glyphicon-refresh'></span> Refresh
            </a>
        </li>
    </ul>
</div>