<ng-container>
    <!-- This span triggers the overlay and is its origin -->
    <span (click)="toggleOvl()" cdkOverlayOrigin #trigger="cdkOverlayOrigin" [ngClass]="{'alternative2': !inputControls?.isEditable}">
        {{ getCommentText() }}
    </span>

    <!-- This template displays the overlay content and is connected to the span -->
    <ng-template cdkConnectedOverlay
                 [cdkConnectedOverlayOrigin]="trigger"
                 [cdkConnectedOverlayOpen]="inputControls?.isOpenOverlay ?? false"
                 [cdkConnectedOverlayHasBackdrop]="inputControls?.isOpenOverlay ?? false"
                 (backdropClick)="toggleOvl();onCommentChange()"
                 (attach)="fitTextAreaToContent()"
                 [cdkConnectedOverlayOffsetY]="5">
        <textarea id={{inputControls?.textAreaId}}
                  oninput='this.style.height = "";this.style.height = this.scrollHeight + 3 + "px"'
                  style="max-height:250px"
                  [(ngModel)]="comment"
                  [maxlength]="inputControls?.maxCommentLength ?? 1000"
                  [matTooltip]=getMaxLengthTooltipText()
                  class="form-control"></textarea>
    </ng-template>
</ng-container>
