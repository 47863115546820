import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from
    "@angular/forms"
import { ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { CustomDialogService } from '../../../../../services/customdialog.service';
import { AddGroupDialogue } from '../addgroupdialogue/addgroupdialogue.component';
import { SpinnerOverlayService } from '../../../../../services/spinner-overlay.service';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { UMPAction, UMPCategory, Utils } from '../../../../../utils/utils';





@Component({
    selector: "specialpermissions",
    templateUrl: './specialpermissions.component.html',
    styleUrls: ['./specialpermissions.component.less', '../../../../../style/new-generic-styles.component.less'],
    encapsulation: ViewEncapsulation.None
})

export class SpecialPermissions {

    public entities: any [] = [];
    public userName: String = '';
    userId = ''



    Response_State: number = -2; //0 -> empty , 1 -> correct and not empty ,-1 -> error, -2 -> Calling API
    errorResponse: any;
    isCallSucceed: number = 0;

    selected: any[] = []

    constructor(
        private route: ActivatedRoute,
        private http: HttpClient,
        private toastrService: ToastrService,
        private dialogForm: MatDialog,
        private spinnerOverlayService: SpinnerOverlayService,


        @Inject('BASE_URL') private baseUrl: string
    ) {
        this.route.params.subscribe(
            (params: Params) => {
                this.resetAll();
            });


    }
    resetAll() {
        this.userName = '';
        this.userId = ''



        this.Response_State = -2; //0 -> empty , 1 -> correct and not empty ,-1 -> error, -2 -> Calling API
        this.isCallSucceed = 0;
        this.userId = this.route.snapshot.paramMap.get('userId') || '';
        this.funcGetSpecialPermissions();
    }
    funcGetSpecialPermissions() {
        this.Response_State = -2;
        Utils.httpGetSpecialPermissions(
            this.http
            , this.baseUrl
            , this.userId
            , this
            , function (tthis: SpecialPermissions, data: any) {
                tthis.entities = data;
                tthis.selected = tthis.entities.filter(x => x.isSelected)
                tthis.Response_State = 1;

            }
        )
    }
    funcNotifyDeselect() {
        if (this.isCallSucceed === 1) {
            this.toastrService.success('Special Permission request sent successfully !', 'Remove Special Permission Request!');
        }
        else if (this.isCallSucceed === -1) {
            this.toastrService.error('Special Permission request not sent successfully !', 'Remove Special Permission Request!');
        }
    }
    funcNotify() {
        if (this.isCallSucceed === 1) {
            this.toastrService.success('Special Permission request sent successfully !', 'Add Special Permission Request!');
        }
        else if (this.isCallSucceed === -1) {
            this.toastrService.error('Special Permission request not sent successfully !', 'Add Special Permission Request!');
        }
    }
    onSelect(id) {
        this.isCallSucceed = 0;

        

        Utils.httpPostGroupsByType(
            this.http
            , this.baseUrl
            //, { "Personae": id }
            , {
                "Personae": [id],
                "UmpAction": UMPAction.Assign, 
                "UmpCategory": UMPCategory.SpecialPermissions,
                "Type": 0
            }
            , this.userId
            , this
            , function (tthis: SpecialPermissions, data: any) {
                tthis.funcGetSpecialPermissions();

                tthis.funcNotify();

            }
        )
        

    }
    onDeselect(id) {

        
        this.isCallSucceed = 0;
        Utils.httpRevokeGroupPersonae(
            this.http
            , this.baseUrl
            , {
                "Personae": [id],
                "UmpAction": UMPAction.Unassign,
                "UmpCategory": UMPCategory.SpecialPermissions,
                "Type": 0
            }
            , this.userId
            , this
            , function (tthis: SpecialPermissions) {
                tthis.funcGetSpecialPermissions();
                tthis.funcNotifyDeselect();
            }
        )
        

    }
    itemSelected(event: any) {
        if (event.options[0]._selected) {
            this.onSelect(event.options[0]._value)
        } else {
            this.onDeselect(event.options[0].value)
        }
    }

    hasAdditionalCosts(persona: string) {
        return Utils.hasAdditionalLicenseCost(persona)
    }

}
