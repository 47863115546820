<customers-menu (onChangeCustomerType)="changeCustomerType($event)" (outputDownload)="downloadExcelFile()" (onReload)="onReload()" [currentType]="currentType"></customers-menu>
<div class="container-tab-lists">
    <div class="inner-tcontainer-tab">
        <div class="tab-body">
            <div class="tab-content">
                <mat-tab-group #tabGroup (selectedTabChange)="changeCustomerType($event); defaults.updateComponentTab($event.tab.textLabel)">
                    <!--Messages-->
                    <mat-tab label="Customers">
                        <div>
                            <customeritem #customerItemComponent></customeritem>
                        </div>
                    </mat-tab>
                    <mat-tab *ngIf="defaults.stage !== 3" label="Facilities Managers">
                        <div>
                            <customerfacilitymanager #managerComponent></customerfacilitymanager>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>
</div>
