<div class="wiki-nav "  [ngClass]="{'divHide': !rightSidenav.opened}">
   

    <mat-sidenav #rightSidenav
                 mode="over"
                 class="rightSidenav "
                 [ngClass]="isExpanded ? 'rightSidenavExp' : 'rightSidenavNorm'"
                 position="end">
        <div class="docTile">
            <h3>Documentation</h3>
            <mat-icon class="clickable_icon" matTooltip="Open Wiki in NewTab" (click)="openLinkInNewTab()">open_in_new</mat-icon>
        </div>
        <button mat-raised-button [matTooltip] = "isExpanded ? 'Collapse' : 'Expand' " class="resize-button" (click)="toggleExpand()">
            <mat-icon class="resize-icon">
                {{ isExpanded ? 'keyboard_arrow_right' : 'keyboard_arrow_left' }}
            </mat-icon>
        </button>
        <div class="custom_ngx_MD">
            <markdown class="variable-binding" [data]="markdown!"></markdown>
        </div>

    </mat-sidenav>
</div>
