<div class="container-tab-lists dialog.classname">
    <h2>{{title}}</h2>
    <div class="inner-tcontainer-tab">
        <div class="tab-body">
            <div class="tab-content">

                <mat-tab-group>
                    <!-- Connection Types -->
                    <mat-tab>
                        <ng-template mat-tab-label>
                            ConnectionType for {{defaults.controllertype.Description}}
                        </ng-template>

                        <div>
                            <connectiontype-list [selected]="selected"></connectiontype-list>
                        </div>
                    </mat-tab>

                    <!-- Predefined Items Descriptions -->
                    <mat-tab>
                        <ng-template mat-tab-label>
                            Predefined Items
                        </ng-template>

                        <div>
                            <controllerconfig-predefineditemsdescriptions-list [selected]="selected" [rowIndex]="rowIndex" (onRefresh)="onRefresh.emit($event)"></controllerconfig-predefineditemsdescriptions-list>
                        </div>
                    </mat-tab>

                </mat-tab-group>

            </div> <!--tab-content-->
        </div> <!--tab-body-->
    </div> <!--inner-tcontainer-tab-->
</div> <!--container-tab-lists-->
