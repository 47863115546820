import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import "rxjs/operators";
import { Data, Router } from '@angular/router';
import { DialogService } from '../../../../services/dialog.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Utils } from '../../../../utils/utils';
import { DefaultValuesService } from '../../../../services/defaultvalues.service';
import * as moment from 'moment';
import { DatePipe } from '@angular/common'
import { CustomerIdsListComponent } from '../customeridslist/customeridslist.component';


@Component({
    selector: 'azdatafactoryform',
    templateUrl: './azdatafactoryform.component.html',
    styleUrls: ['../../../../style/azdatafactory.component.less', '../../../../style/custom-mat-dialog.component.less']
})


export class AzDataFactoryFormComponent implements OnInit {

    body: any;
    type: string = '';
    item: string = '';
    targetSystemList: string[] = [];
    minDate = new Date();

    form!: FormGroup
    submitted = false;

    constructor(private http: HttpClient
        , private confimDialog: DialogService
        , private router: Router
        , @Inject(MAT_DIALOG_DATA) public data: any
        , @Inject('BASE_URL') private baseUrl: string
        , public dialogRef: MatDialogRef<AzDataFactoryFormComponent>
        , private fb: FormBuilder
        , private defaults: DefaultValuesService
        , private formBuilder: FormBuilder
        , private datepipe: DatePipe
        , private dialogForm: MatDialog
    ) {

        this.confimDialog.tthis = this;
        this.minDate.setDate(this.minDate.getDate() - 7);

    }

    ngOnInit(): void {
        let currentDate = new Date()
        currentDate = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()));
        if (this.data.isCustomerPortal) {
            this.body = {
                loadDate: currentDate,
                entity: this.data.type
            }
            this.type = 'CustomerPortal';
            this.item = this.data.type;
            this.form = this.formBuilder.group({
                argument: [this.dateValidator],
                loadDate: [this.dateValidator]
            });

        }
        else {
            switch (this.data.type) {
                case 'Elevator':
                case 'Escalator':
                    Utils.httpGetTargetSystemByTenant(
                        this.http
                        , this.baseUrl
                        , this.defaults.stage
                        , this.defaults.tenant
                        , this
                        , function (tthis: AzDataFactoryFormComponent, list: string[]) {
                            tthis.targetSystemList = list
                        }
                    );
                    this.body = {
                        TargetSystem: 'Default',
                        WindowEnd: currentDate,
                        WindowStart: currentDate,
                        SessionId: ''
                    }
                    this.type = 'MasterData'
                    this.item = this.data.type
                    this.form = this.formBuilder.group({
                        targetSystem: ['', [Validators.required]],
                        windowStart: [this.dateValidator],
                        windowEnd: [this.dateValidator],
                        sessionId: ['', this.sessionIdValidator],
                    });
                    break;
                case 'OtherAsset':
                    Utils.httpGetTargetSystemByTenant(
                        this.http
                        , this.baseUrl
                        , this.defaults.stage
                        , this.defaults.tenant
                        , this
                        , function (tthis: AzDataFactoryFormComponent, list: string[]) {
                            tthis.targetSystemList = list
                        }
                    );
                    this.body = {
                        TargetSystem: 'Default',
                        WindowStart: currentDate,
                    }
                    this.type = 'MasterData'
                    this.item = this.data.type
                    this.form = this.formBuilder.group({
                        targetSystem: ['', [Validators.required]],
                        windowStart: [this.dateValidator],
                    });
                    break;
                case 'Customer':
                case 'Contract':
                case 'CustomerFacilityManager':
                case 'ContractFacilityManager':
                case 'Kpi':
                    this.body = {
                        SelectionDateFrom: currentDate,
                        PagingCount: 5000,
                        CustomerIds: ''
                    }
                    this.type = 'InfoHub'
                    this.item = this.data.type
                    this.form = this.formBuilder.group({
                        selectionDateFrom: [this.dateValidator],
                        pagingCount: [5000, [Validators.min(1), Validators.required]],
                        customerIds: ['']
                    });
                    break;

                case 'ServiceTicket':
                    this.body = {
                        StartDateTime: null,
                        EndDateTime: null,
                        SelectionDateFrom: currentDate,
                        PagingCount: 5000,
                        CustomerIds: ''
                    }
                    this.type = 'InfoHub'
                    this.item = this.data.type
                    this.form = this.formBuilder.group({
                        startDateTime: [this.dateValidator],
                        endDateTime: [this.dateValidator],
                        selectionDateFrom: [this.dateValidator],
                        pagingCount: [5000, [Validators.min(1), Validators.required]],
                        customerIds: ['']
                    });
                    break;
            }
        }
    }

    // convenience getter for easy access to form fields
    get f() {
        return this.form.controls;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.form)
            if (this.form.invalid) {
                return;
            }
        switch (this.data.type) {
            case 'Elevator':
            case 'Escalator':
            case 'OtherAsset':
                if (this.body.TargetSystem === 'Default') this.body.TargetSystem = null
                break;
        }

        if ((this.data.type === 'Customer' || this.data.type === 'CustomerFacilityManager' || this.data.type === 'Contract' || this.data.type === 'ContractFacilityManager' || this.data.type === 'Kpi' || this.data.type === 'ServiceTicket') &&
            this.body.CustomerIds !== '' && !this.data.isCustomerPortal) {
            const customerIdDialog = this.dialogForm.open(CustomerIdsListComponent, {
                panelClass: 'custom-mat-dialog',
                data: { "customerids": this.body.CustomerIds }
            });
            customerIdDialog.afterClosed().subscribe(result => {
                if (Boolean(result.result) === true) {
                    this.executePostTriggerAzdf()
                }
            });
        }
        else {
            this.executePostTriggerAzdf()
        }

    }

    onBack() {

    }

    executePostTriggerAzdf(): void {
        Utils.httpPostTriggerAzdf(
            this.http
            , this.baseUrl
            , this.body
            , this.type
            , this.item
            , this
            , function (tthis: AzDataFactoryFormComponent, object: AzDataFactoryRun, type: string, item: string) {
                tthis.dialogRef.close({ event: 'submit' });
                if (object.RunId === '00000000-0000-0000-0000-000000000000')
                    tthis.confimDialog.showErrorDialog(
                        {
                            'error':
                                { 'message': type + ' - ' + item + ' Trigger cannot be executed. Due to security policies, it is needed to wait until ' + tthis.datepipe.transform(object.StartDate, 'yyyy-MM-dd HH:mm', '+000') + ' UTC' }
                        })
                else
                    tthis.confimDialog.showSuccessDialog(type + ' - ' + item + ' Trigger has been executed successfully with RunId - ' + object.RunId);
            }
        )
    }

    dateValidator(control: FormControl): { [s: string]: boolean } {
        if (control.value) {
            const date = moment(control.value);
            if (date.isValid()) {
                return { 'invalidDate': false }
            }
            else {
                return { 'invalidDate': true }
            }
        }
        return { 'invalidDate': false }
    }

    sessionIdValidator(control: FormControl): { [s: string]: boolean } {
        if (("" + control.value) === '')
            return {};
        const reEmail = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
        if (control.value) {
            const s = "" + control.value;
            return { 'invalid': !reEmail.test(s) };
        }
        else {
            return { 'invalid': true };
        }
    }
}


