<h2>{{title}} for {{defaults.tenant}}</h2>

<div id="virtualprovisioning">
    <div class="container-expansions">
        <mat-expansion-panel [expanded]="state === 0" (opened)="setState(0)" class="mat-expansion-panel-macaddress">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    First step
                </mat-panel-title>
                <mat-panel-description>
                    Select a valid MacAddress {{ selectedMacAddress !== 'Select' ? getMessageMacAddress() : ''}}
                </mat-panel-description>
                <mat-icon *ngIf="selectedMacAddress !== 'Select'">check_circle</mat-icon>
                <mat-icon *ngIf="selectedMacAddress === 'Select'">error</mat-icon>
            </mat-expansion-panel-header>

            <ng-select (change)="onMacAddressSelect($event)" class ="virtual-provisioning-select" style="width:400px; border: 1px white solid; height: 20px;" 
                       [items]="filteredMacAddress"
                       bindLabel="macaddress"
                       bindValue="macaddress"
                       [closeOnSelect]="true"
                       [searchable]="true"
                       [(ngModel)]="selectedMacAddress"
                       [virtualScroll]="true"
                       (search)="loadMacAddresses($event)"
                       >
            </ng-select>

            <button mat-icon-button matTooltip="Preregister new MacAddress" (click)="openPreregisterMacAddressPopup()">
                <mat-icon aria-label="Preregister new MacAddress">add_circle</mat-icon>
            </button>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="state === 1" (opened)="setState(2)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Second step
                </mat-panel-title>
                <mat-panel-description>
                    Select an unprovisioned UnitId {{ unitId !== '' ? getMessageUnitId() : ''}}
                </mat-panel-description>

                <mat-icon *ngIf="unitId !== ''">check_circle</mat-icon>
                <mat-icon *ngIf="unitId === ''">error</mat-icon>
            </mat-expansion-panel-header>
            <virtualprovisioning-list [unitlist]="unitlist" (filter)="filter($event)" (selectedUnitId)="onUnitIdSelect($event)" (unitListReload)="onUnitIdListReload()"></virtualprovisioning-list>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="state === 2" (opened)="setState(2)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Third step
                </mat-panel-title>
                <mat-panel-description>
                    Select a Controller Type {{ controllerType !== 'Select' ? getMessageControllerType() : ''}}
                </mat-panel-description>

                <mat-icon *ngIf="controllerType !== 'Select'">check_circle</mat-icon>
                <mat-icon *ngIf="controllerType === 'Select'">error</mat-icon>

            </mat-expansion-panel-header>

            <ng-select (change)="onControllerTypeSelect($event)"  class ="virtual-provisioning-select" style="width:400px; border: 1px white solid"
                       [items]="controllertypesList"
                       bindLabel="c"
                       bindValue="ct"
                       [closeOnSelect]="true"
                       [searchable]="true"
                       [(ngModel)]="controllerType">
            </ng-select>
        </mat-expansion-panel>
    </div>

    <div class="mt-2">
        <button mat-icon-button [disableRipple]="true" (click)="createProvisioningRequest()">
            <mat-icon>cloud_upload</mat-icon>
            Provision Unit
        </button>
    </div>
</div>