import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { DialogService } from '../../../services/dialog.service';
import { SaveService } from '../../../services/save.service';
import { Utils } from '../../../utils/utils';
import { CustomDialogService } from "../../../services/customdialog.service";
import { Subject } from "rxjs";
import { DefaultValuesService } from "../../../services/defaultvalues.service";
import { MatChipInputEvent } from "@angular/material/chips";
import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';

@Component({
    selector: "branchhierarchy-list",
    templateUrl: './branchhierarchy-list.component.html',
    styleUrls: ['../../../style/mat-table.component.less', 'branchhierarchy-list.component.less']
})

export class BranchHierarchyListComponent implements OnInit {
    @Input() list: BranchHierarchyView[] = [];
    @Input() listRef: BranchHierarchyRef[] = [];
    @Input() listKey: BranchHierarchyKey[] = [];
    @Input() equals: any | undefined;
    @Input() newService: SaveService | undefined;

    @Output() onDelete = new EventEmitter<{}>();

    listFilteredRef: BranchHierarchyRef[] = [];

    public show = false;

    columnsToDisplay = ['BA', 'BU', 'OU', 'OSU', 'Country', 'MgmtBranch', 'Region', 'District', 'Branch', 'Number', 'Key', 'actions'];

    @ViewChild(MatTable, { static: true }) table!: MatTable<any>;
    public dataSource = new MatTableDataSource<BranchHierarchyView>();
    selected: BranchHierarchyView | undefined;
    public clonedList: { [s: string]: BranchHierarchyView; } = {};

    subscriptionTenant: any;
    newBranch: any | BranchHierarchyView;
    addingNewBranch = false;

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [COMMA, TAB, ENTER];
    searchItems: SearchItem[] = [];

    protected _onDestroy = new Subject<void>();

    constructor(private activatedRoute: ActivatedRoute
        , private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , private dialog: DialogService
        , private saveService: SaveService
        , private customDialog: CustomDialogService
        , private defaults: DefaultValuesService

    ) {
    }

    ngOnChanges() {
        this.dataSource.data = this.list
        if (this.newService) {
            this.newService.addListener(this);
        }
        this.saveService.addListener(this)
        this.addingNewBranch = false;
        this.clonedList = {}
    }

    ngOnInit() {
        this.getHeader();
        this.subscriptionTenant = this.defaults.tenantMessageChanges$.subscribe(() => {
            this.getHeader()
        });
        this.saveService.addListener(this);
        this.dialog.tthis = this;
        this.dataSource.filterPredicate = (data: BranchHierarchyView, filters: string) => {
            const matchFilter: any[] = [];
            const filterArray = filters.split('+').filter(x => x.indexOf('notextfilters') < 0)
            if (filterArray.length > 0) {
                filterArray.forEach((filter) => {
                    let result = false;
                    const branch = data.Branch ? data.Branch : ''
                    const number = data.Number ? data.Number : ''
                    const district = data.District ? data.District : ''
                    const region = data.Region ? data.Region : ''
                    result = branch.toString().toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
                        number.toString().toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
                        district.toString().toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
                        region.toString().toLowerCase().indexOf(filter.toLowerCase()) > -1
                    return matchFilter.push(result);
                });
            }
            else {
                matchFilter.push(true);
            }
            return matchFilter.some(Boolean);
        };
    }

    getHeader() {
        if (!this.defaults.isReadonlyUser)
            this.columnsToDisplay = ['BA', 'BU', 'OU', 'OSU', 'Country', 'MgmtBranch', 'Region', 'District', 'Branch', 'Number', 'Key', 'actions'];
        else
            this.columnsToDisplay = ['BA', 'BU', 'OU', 'OSU', 'Country', 'MgmtBranch', 'Region', 'District', 'Branch', 'Number', 'Key'];
    }

    ngOnDestroy() {
        this.saveService.removeListener(this);
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    onRowEditInit(branchHierarchy: BranchHierarchyView) {
        this.saveService.addListener(this);
        this.clonedList[this.getDictionaryHash(branchHierarchy)] = { ...branchHierarchy };
        if (Object.keys(this.clonedList).length !== 0) this.onShowSavebutton()
    }

    isEditingRow(element: BranchHierarchyView) {
        return this.clonedList[this.getDictionaryHash(element)]
    }

    isNewRow(index: number) {
        return index === 0 && this.addingNewBranch
    }

    onRowEditCancel(branchHierarchy: BranchHierarchyView, index = -1) {
        if (index === 0 && this.addingNewBranch) {
            this.addingNewBranch = false;
            this.dataSource.data.shift()
            this.table.renderRows()
            if (Object.keys(this.clonedList).length === 0) this.saveService.showSaveButton(false)
        } else {
            let branchHierarchyIndex = this.dataSource.data.findIndex(x => x.Number === branchHierarchy.Number)
            this.dataSource.data[branchHierarchyIndex].Region = this.clonedList[this.getDictionaryHash(branchHierarchy)].Region
            this.dataSource.data[branchHierarchyIndex].District = this.clonedList[this.getDictionaryHash(branchHierarchy)].District
            this.dataSource.data[branchHierarchyIndex].Branch = this.clonedList[this.getDictionaryHash(branchHierarchy)].Branch
            delete this.clonedList[this.getDictionaryHash(branchHierarchy)];
            if (Object.keys(this.clonedList).length === 0) this.saveService.showSaveButton(false)
            this.table.renderRows();
        }
    }

    onNew() {
        if (!this.addingNewBranch) {
            this.addingNewBranch = true;
            this.resetNewBranch();
            this.saveService.addListener(this);
            this.dataSource.data.unshift(this.newBranch)
            this.table.renderRows()
            this.onShowSavebutton();
        }
    }

    onShowSavebutton() {
        this.saveService.showSaveButton();
    }

    onKeyDownBranchName(event: any) {
        const pattern = /^[-]/;
        const inputChar = event.key;
        if (pattern.test(inputChar)) {
            event.preventDefault();
        }
    }

    changeRef(value: any, element: BranchHierarchyView) {
        const ref = this.listRef.find(x => x.MgmtBranch === value.value)
        if (ref) {
            element.BA = ref.BA
            element.BU = ref.BU
            element.OU = ref.OU
            element.OSU = ref.OSU
            element.BranchHierarchyRefId = ref._BranchHierarchyRefId
        }
    }

    changeKey(value: any, element: BranchHierarchyView) {
        element.Key = value.value;
    }

    applyFilter(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            this.searchItems.push({ name: value.replace('+', '').trim() });
        }
        // Reset the input value
        if (input) {
            input.value = '';
        }
        this.updatefilter()
    }
    remove(item: SearchItem): void {
        const index = this.searchItems.indexOf(item);
        if (index >= 0) {
            this.searchItems.splice(index, 1);
        }
        this.updatefilter()
    }
    updatefilter() {
        let filterString: string
        if (this.searchItems.length === 0) {
            this.dataSource.filter = 'notextfilters'
        }
        else {
            filterString = this.searchItems.map(e => { return e.name }).join('+')
            this.dataSource.filter = filterString
        }
    }

    onDeleteFunc(element: BranchHierarchyView) {
        this.onDelete.emit({ element })
    }

    onSave() {
        for (let i in this.clonedList) {
            const branchHierarchyIndex = this.dataSource.data.findIndex(x => x.Number === this.clonedList[i].Number)
            if (Object.entries(this.clonedList[this.getDictionaryHash(this.dataSource.data[branchHierarchyIndex])]).toString()
                !== Object.entries(this.dataSource.data[branchHierarchyIndex]).toString()) {
                this.http
                    .post<BranchHierarchyView>(
                        this.baseUrl + Utils.getBranchHierarchyAPI() + "/merge"
                        , this.dataSource.data[branchHierarchyIndex]
                    )
                    .subscribe(
                        res => {
                            delete this.clonedList[this.getDictionaryHash(this.dataSource.data[branchHierarchyIndex])];
                            if (Object.keys(this.clonedList).length === 0 && !this.addingNewBranch) this.saveService.showSaveButton(false)
                            this.table.renderRows();
                        }, (error: { error: { message: any; }; }) => { this.customDialog.openDisplayInfoDialog({ title: 'Error', message: error.error.message }); }
                    );
            }
            else {
                delete this.clonedList[this.getDictionaryHash(this.dataSource.data[branchHierarchyIndex])];
            }
        }
        if (this.addingNewBranch) {
            if (this.dataSource.data.slice(1).findIndex(x => x.Number === this.newBranch.Number) > -1) {
                this.dialog.showErrorDialog({ 'error': { 'message': 'Branch Number already exists' } })
                this.addingNewBranch = false;
                this.dataSource.data.shift()
                this.table.renderRows()
                if (Object.keys(this.clonedList).length === 0) this.saveService.showSaveButton(false)
                return
            }
            this.http
                .post<BranchHierarchyView>(
                    this.baseUrl + Utils.getBranchHierarchyAPI() + "/merge"
                    , this.newBranch
                )
                .subscribe(
                    res => {
                        this.addingNewBranch = false;
                        if (Object.keys(this.clonedList).length === 0) this.saveService.showSaveButton(false)
                        this.table.renderRows();
                    }, (error: { error: { message: any; }; }) => {
                        this.customDialog.openDisplayInfoDialog({ title: 'Error', message: error.error.message });
                        this.addingNewBranch = false;
                        this.dataSource.data.shift()
                        this.table.renderRows()
                        if (Object.keys(this.clonedList).length === 0) this.saveService.showSaveButton(false)
                    }
                );
        }
    }

    getDictionaryHash(branchHierarchy: BranchHierarchyView) {
        return branchHierarchy.Number + branchHierarchy.Country
    }

    private resetNewBranch() {
        this.newBranch = {
            BA: null,
            BU: null,
            OU: null,
            OSU: null,
            Country: this.defaults.tenant,
            MgmtBranch: null,
            Region: null,
            District: null,
            Branch: null,
            Number: null
        }
    }
}
export interface SearchItem {
    name: string;
}