import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Params } from '@angular/router';
import { Utils } from '../../../utils/utils';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Observable, debounceTime, distinctUntilChanged, map, of, switchMap } from 'rxjs';



interface MGMTBranchNode {
    groupId: string;
    groupName: string;
    subGroupsAllowed: boolean,
    selected?: boolean;
    disabled?: boolean;
    indeterminate?: boolean;
    parent?: MGMTBranchNode;
    groups: MGMTBranchNode[];
    groupFunction: string;
}

@Component({
    selector: "hierarchysetuppopup",
    templateUrl: './hierarchysetuppopup.component.html',
    styleUrls: ['./hierarchysetuppopup.component.less',],
    encapsulation: ViewEncapsulation.None
})

export class HierarchySetupPopupComponent implements OnInit {

    public TREE_DATA: MGMTBranchNode[] = [];/*** */

    public treeControl: NestedTreeControl<MGMTBranchNode>;
    public dataSource = new MatTreeNestedDataSource<MGMTBranchNode>();

    public showOnlySelected = false;
    Tree_Response_State: number = -2; //0 -> empty , 1 -> correct and not empty ,-1 -> error, -2 -> Calling API
    public form: FormGroup;

    isCallSucceed: number = 0;
    isMerged = false;
    ngSelectItems: any;
    selectedBranch: any;
    public searchString: string = '';
    constructor(fb: FormBuilder,
        private route: ActivatedRoute,
        private http: HttpClient,
        public dialogRef: MatDialogRef<HierarchySetupPopupComponent>,
        @Inject('BASE_URL') private baseUrl: string,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private toastrService: ToastrService,

    ) {
        if (this.data.action != 'history') {
            this.route.params.subscribe(
                (params: Params) => {
                    this.resetAll();
                });
        }

        this.treeControl = new NestedTreeControl<MGMTBranchNode>((node) => node.groups)
        this.form = fb.group(
            {
                key: [data.content?.Key, data.content?.Key ? Validators.required : {
                    validators: [Validators.required],
                    asyncValidators: [this.keyExistsValidator.bind(this)],
                    updateOn: "blur"
                }],
                branchName: [data?.content?.BranchName, Validators.required],
                locationForMap: [data?.content?.LocationForMap],
                country: [data?.content?.Country ?? data?.country, Validators.required],
                address: [data?.content?.Address],
                mgmtId: [data?.content?.MgmtId, Validators.required]
            }, {
        });
        this.data.action == 'delete'  ? (this.ngSelectItems = data.content?.filter(x => x.Key != data.selected?.Key)) : null;
    }

    ngOnInit() {
    }
    resetAll() {
        this.treeControl = new NestedTreeControl<MGMTBranchNode>((node) => node.groups);
        this.dataSource = new MatTreeNestedDataSource<MGMTBranchNode>();
        this.funcGetManagementIdSelector();
    }

    keyExistsValidator(control: FormControl): Observable<any> {
        return new Observable((observer) => {
            const key = control.value;
            if (!key) {
                observer.next(null); // If key is empty, no need to check
                observer.complete();
                return;
            }
            this.isAvailable(key, observer);
        }).pipe(
            debounceTime(1000), // Adjust debounce time as per your requirement
            distinctUntilChanged() // Only trigger if value has changed
        );
    }


     isAvailable(key, observer) {
        Utils.httpGetIsAvailabelHK(
            this.http
            , this.baseUrl
            , key
            , this
            , (tthis: HierarchySetupPopupComponent, data: any) => {
                if (data) {
                    observer.next(null);
                } else {
                    observer.next({ isAvailable: true })

                }
                observer.complete();
            });

    }

    public hasChild = (_: number, node: MGMTBranchNode) =>
        !!node.groups && node.groups.length > 0;

    public itemToggle($event: any, checked: boolean, node: MGMTBranchNode) {
        if (checked) {
            this.unselectAll()
            node.selected = checked;
            this.form.get('mgmtId')?.setValue(node.groupName.split("-")[1].trim());
        }
    }

    private unselectAll() {
        this.treeControl.dataNodes.forEach((branchNode) => {
            branchNode.selected = undefined;
            if (branchNode.groups) {
                const alldecendents = this.treeControl.getDescendants(branchNode)
                alldecendents.forEach((node) => (node.selected = false))
            }
        });
    }

    funcGetManagementIdSelector() {
        Utils.httpGetFieldManagementIds(
            this.http
            , this.baseUrl
            , 'MANAGEMENT'
            , this
            , (tthis: HierarchySetupPopupComponent, data: any) => {
                tthis.TREE_DATA = data; ///******** */
                tthis.treeControl.dataNodes = tthis.TREE_DATA;
                tthis.dataSource.data = tthis.TREE_DATA;
                Object.keys(this.dataSource.data).forEach((key) => {
                    this.setParent(this.dataSource.data[key], undefined);
                })
                if (data)
                    tthis.selectByDefault(tthis.dataSource.data, this.data.content.MgmtId)

            });
    }

    selectByDefault(objects: MGMTBranchNode[], mgmtId: string): void {
        for (const object of objects) {
            if (object.groupName.includes(mgmtId)) {
                object.selected = true;
                this.expandParentNode(object)
            }
            if (object.groups) {
                this.selectByDefault(object.groups, mgmtId);
            }
        }
    }

    private setParent(node: MGMTBranchNode, Parent: MGMTBranchNode | undefined) {
        if (node)
            node.parent = Parent;
        if (node.groups) {
            node.groups.forEach((childNode) => {
                this.setParent(childNode, node);
            });
        }
    }
    expandParentNode(node: MGMTBranchNode) {
        if (node.parent) {
            this.treeControl.expand(node.parent);
            this.expandParentNode(node.parent);
        }
    }

    public hideLeafNode(node: MGMTBranchNode): boolean {
        return new RegExp(this.searchString, 'i').test(node.groupName) === false;
    }


    public hideParentNode(node: MGMTBranchNode): boolean {
        if (
            !this.searchString || node.groupName.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1) {
            return false
        }
        const descendants = this.treeControl.getDescendants(node)

        if (
            descendants.some(
                (descendantNode) =>
                    descendantNode.groupName
                        .toLowerCase()
                        .indexOf(this.searchString.toLowerCase()) !== -1
            )
        ) {
            return false
        }

        return true
    }

    onSubmit() {
        console.log("submitted")
        var requestBody;
        let TargetObj: HierarchyKeyUpdate = {
            Address: this.form.value.address,
            BranchName: this.form.value.branchName,
            Country: this.form.value.country,
            Key: this.form.value.key,
            LocationForMap: this.form.value.locationForMap,
            MgmtId: this.form.value.mgmtId,
        };
        if (this.data.action == "edit") {
            requestBody = { "TargetObj": TargetObj, "SourceObj": this.data.content, "Operation": "edit" };
        }
        else if (this.data.action == "add") {
            var SourceObj: any = {
                Address: "",
                BranchName: "",
                Country: "",
                Key: "",
                LocationForMap: "",
                MgmtId: ""
            }
            requestBody = { "TargetObj": TargetObj, "SourceObj": SourceObj, "Operation": "add" };
        }


        Utils.httpUpdateFieldManagementIds(
            this.http
            , this.baseUrl
            , requestBody
            , this
            , (tthis: HierarchySetupPopupComponent, data: any) => {
                this.responseHandler(data, this.data.action.toUpperCase());
                tthis.dialogRef.close();
            });
    }

    responseHandler(response, operation) {
        response === true ? this.toastrService.success(`Hierarchy key ${operation.toLowerCase()}ed successfully!`, `${operation} Hierarchy Key!`) : this.toastrService.error(response, `${operation} Hierarchy Key Failed!`)
    }

    onCancelClick(): void {
            this.dialogRef.close();
    }

    getToolTipText(entity) {
        let result = `Key: ${entity.Key} \n  BranchName:${entity.BranchName} \n LocationForMap:${entity.LocationForMap} \n Address:${entity.Address} \n MgmtId:${entity.MgmtId} `
        return result
    }

    onDelete(selectedBranch) {
        if (this.isMerged) {
            var TargetObj = selectedBranch;
        }
        else {
            var TargetObj: any = {
                Address: "",
                BranchName: "",
                Country: "",
                Key: "",
                LocationForMap: "",
                MgmtId: ""
            }
        }
        var requestBody = { "TargetObj": TargetObj, "SourceObj": this.data.selected, "Operation": "delete", "isMerged": this.isMerged };
        Utils.httpUpdateFieldManagementIds(
            this.http
            , this.baseUrl
            , requestBody
            , this
            , (tthis: HierarchySetupPopupComponent, data: any) => {
                this.responseHandler(data, "Delete");
                tthis.dialogRef.close();
            });
    }
    customSearchFn(term: string, item: any) {
        term = term.toLocaleLowerCase();
        return item.Address.toLocaleLowerCase().indexOf(term) > -1 ||
            item.BranchName.toLocaleLowerCase().indexOf(term) > -1 ||
            item.Country.toLocaleLowerCase().indexOf(term) > -1 ||
            item.Key.toLocaleLowerCase().indexOf(term) > -1 ||
            item.LocationForMap.toLocaleLowerCase().indexOf(term) > -1 ||
            item.MgmtId.toLocaleLowerCase().indexOf(term) > -1
    }

    deleteButtonToolTip() {
        return (this.isMerged && this.selectedBranch === undefined) ? 'Please Select a Replacement Branch from Dropdown to replace the current branch and do the merge ' : 'Delete Selected Branch';
    }
}