<div class="container-tab-lists new-table-style">

    <div class="inner-tcontainer-tab">
        <h2>{{label}}</h2>
        <div style="display:flow-root">
            <!-- <div style="float:left; width:40vw" *ngIf="!hideSections()">
                <mat-form-field class="search-input">
                    <input placeholder="Filter"
                           matInput
                           [matChipInputFor]="chipList"
                           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                           [matChipInputAddOnBlur]="addOnBlur"
                           (matChipInputTokenEnd)="applyFilter($event)"
                           [disabled]="isAllLoaded === false">
                    <mat-chip-list #chipList>
                    </mat-chip-list>
                </mat-form-field>
                <mat-icon style="color:#ddd !important;" (click)="openFilterPopup()">filter_alt</mat-icon>
                <mat-chip *ngFor="let item of searchItems" [selectable]="selectable"
                          [removable]="removable" (removed)="remove(item)">
                    {{item.name}}
                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
            </div> -->
            <div style="text-align:right; float:right">

                <a class='kpi-save-menu save-button' title="Save" *ngIf="isEditModeEnabled" (click)="onClickSave()">
                    <span class='glyphicon glyphicon-floppy-disk'></span> Save
                </a>

                <a class='kpi-button-menu' title="Edit values" (click)="onClickEdit()">
                    <span class='glyphicon glyphicon-pencil'></span> {{isEditModeEnabled ? 'Undo' : 'Edit'}}
                </a>

               <!--  <h5>
                    <span class="salmon-color" *ngIf="isEditModeEnabled === true">Editable kpi values</span>
                </h5> -->

                <h5 [style.visibility]="isEditModeEnabled === true ? 'visible' : 'hidden'">
                    Edit Mode is enabled. Please click on Save button to save all changes
                </h5>
            </div>
        </div>
        <br />
        <assessment-list [assessmentInfo]="assessmentInfo"
                         [stages]="stages"
                         [contacts]="contacts"
                         [isEditModeEnabled]="isEditModeEnabled"></assessment-list>
    </div>
</div>