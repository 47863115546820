<div class="custom-mat-dialog-form">
    <button mat-icon-button class="right" [mat-dialog-close]="true">
        <mat-icon>close</mat-icon>
    </button>
    <div>
        <h3>Last Runs {{this.data.entity}} to {{this.data.type}} Data Factory</h3>
    </div>

    <div class="mt-2">
        <small>Max Range: 30 days</small>
        <tr>
            <td style="width:46%;">
                <input [matDatepicker]="pickerFilterStart" [(ngModel)]="body.startDate" [max]="maxDate" class="form-control" readonly (dateChange)="onStartChanged($event)" />
                <mat-datepicker-toggle matSuffix [for]="pickerFilterStart"></mat-datepicker-toggle>
                <mat-datepicker #pickerFilterStart></mat-datepicker>
            </td>
            <td style="width:46%;">
                <input [matDatepicker]="pickerFilterEnd" [(ngModel)]="body.endDate" [max]="maxDate" class="form-control" readonly (dateChange)="onEndChanged($event)" />
                <mat-datepicker-toggle matSuffix [for]="pickerFilterEnd"></mat-datepicker-toggle>
                <mat-datepicker #pickerFilterEnd></mat-datepicker>
            </td>
            <td style="width:30%" class="middle-align">
                <button type="button" class="btn mat-flat-button btn-primary" (click)="onFilter()" style="color:white;">
                    Filter
                </button>
            </td>
        </tr>
    </div>

    <table mat-table
           [dataSource]="dataSource"
           multiTemplateDataRows
           class="mat-elevation-z8 mat-table-custom" id="azdatafactoryruns">

        <ng-container matColumnDef="arrow">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element"
                (click)="pushPopElement(element)">
                <span class="material-icons" *ngIf="checkExpanded(element); else show_expand_more">
                    expand_less
                </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="index">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element; let i = dataIndex">
                <p>{{i+1}}</p>
            </td>
        </ng-container>

        <ng-container matColumnDef="runId">
            <th mat-header-cell *matHeaderCellDef> Run Id </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.RunId"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="startDate">
            <th mat-header-cell *matHeaderCellDef> Triggered Date </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.StartDate | date:'yyyy-MM-dd HH:mm'"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="endDate">
            <th mat-header-cell *matHeaderCellDef> Completion Date </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.EndDate | date:'yyyy-MM-dd HH:mm'"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let element">
                <mat-icon *ngIf="element.Status === 'Succeeded'">check_circle</mat-icon>
                <mat-icon *ngIf="element.Status === 'Failed'">error</mat-icon>
                <mat-icon *ngIf="element.Status === 'InProgress'">schedule</mat-icon>
            </td>
        </ng-container>

        <ng-container matColumnDef="message">
            <th mat-header-cell *matHeaderCellDef> Message </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.Message"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="parameters">
            <th mat-header-cell *matHeaderCellDef> Parameters </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.Parameters"></p>
            </td>
        </ng-container>


        Expanded Content Column - The detail row is made up of this one column that spans across all columns
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                <div [@detailExpand]="checkExpanded(element) ? 'expanded' : 'collapsed'" *ngIf="element.Activities">
                    <table mat-table [dataSource]="element.Activities" class="mat-subtable-custom" id="azdatafactoryrunssubtable">

                        <ng-container matColumnDef="activityName">
                            <th mat-header-cell *matHeaderCellDef> Activity Name </th>
                            <td mat-cell *matCellDef="let element2">
                                <p [innerHTML]="element2.ActivityName"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef> Status </th>
                            <td mat-cell *matCellDef="let element2">
                                <p [innerHTML]="element2.Status"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="output">
                            <th mat-header-cell *matHeaderCellDef> Output </th>
                            <td mat-cell *matCellDef="let element2">
                                <p>{{element2.Output}}</p>
                            </td>
                        </ng-container>

                        <tr mat-header-row class="subtableheader" *matHeaderRowDef="subcolumnsToDisplay"></tr>
                        <tr mat-row *matRowDef="let row2; let i2 = index; columns: subcolumnsToDisplay;"
                            class="subitem"></tr>
                    </table>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; let i = dataIndex;
            columns: columnsToDisplay;"
            class="opmode-item">
        </tr>

        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="row-subitem"></tr>

    </table>
    <div class="mt-2 right">
        <button type="button"
                class="btn btn-danger"
                (click)="onNoClick()">
            Close
        </button>
    </div>
</div>


<ng-template #show_expand_more>
    <span class="material-icons">
        expand_more
    </span>
</ng-template>
