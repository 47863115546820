<div class="container-tab-lists dialog.classname">
    <harmonizedopmode-menu (onRefresh)="refreshAll()"></harmonizedopmode-menu>

    <div class="inner-tcontainer-tab">
        <div style="display:flow-root">
            <div style="float:left; width:40vw">
                <h2>{{title}}</h2>
            </div>
            <div style="text-align:right; float:right">
                <h5>**A PBI must be created and assigned to anyone from DevOps Team for deploying new changes.**</h5>
            </div>
        </div>
        <div class="tab-body">
            <div class="tab-content">
                <harmonizedopmode-list [list]="harmonizedoperationmodes" [language]="language" (onRefresh)="refreshAll()"></harmonizedopmode-list>
            </div> <!--tab-content-->
        </div> <!--tab-body-->
    </div> <!--inner-tcontainer-tab-->
</div> <!--container-tab-lists-->
