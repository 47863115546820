import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import "rxjs/operators";
import { Data, Router } from '@angular/router';
import { DialogService } from '../../../../services/dialog.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DefaultValuesService } from '../../../../services/defaultvalues.service';
import * as moment from 'moment';
import { DatePipe } from '@angular/common'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { CustomDialogService } from '../../../../services/customdialog.service';
import { Utils } from "../../../../utils/utils";


@Component({
    selector: "kpiadminnewkpiform",
    templateUrl: './kpiadminnewkpi-form.component.html',
    styleUrls: ['../../../../style/azdatafactory.component.less', '../../../../style/custom-mat-dialog.component.less']

})


export class KpiAdminNewKpiFormComponent implements OnInit {

    @ViewChild('TxtKpiName') txtKpiName: ElementRef | undefined;
    @ViewChild('TxtMersyID') txtMersyID: ElementRef | undefined;

    GroupFunctions: string[] = [];
    SelectedGF: string= "";

    MersyId: string = "";
    KpiName: string = "";
    KpiDefinitionId: any;

    NEWMersyId: string = "";
    NEWKpiName: string = "";

    OpcNew: any;

    body: any;
    title: string;
    submitted = false;
    form: FormGroup = new FormGroup({});

    constructor(private http: HttpClient
        , private confimDialog: DialogService
        , private router: Router
        , @Inject(MAT_DIALOG_DATA) public data: any
        , @Inject('BASE_URL') private baseUrl: string
        , public dialogRef: MatDialogRef<KpiAdminNewKpiFormComponent>
        , private fb: FormBuilder
        , private defaults: DefaultValuesService
        , private formBuilder: FormBuilder
        , private customDialog: CustomDialogService
        , private datepipe: DatePipe) {

        this.confimDialog.tthis = this;
        this.title = "";
        this.OpcNew = "0";

    }


    ngOnInit(): void {

        const tthis = this;

        this.MersyId = this.data.MersyId;
        this.KpiName = this.data.KpiName;
        this.KpiDefinitionId = this.data.KpiDefinitionId;

        //this.NEWMersyId =this.MersyId;
        //this.NEWKpiName =this.KpiName;

        this.SelectedGF = this.data.SelectedGF;
        this.KpiModeChange();
    }


    // convenience getter for easy access to form fields
    get f()
    {
        return this.form.controls;
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    KpiModeChange()
    {
        //this.txtKpiName.nativeElement.disabled = this.OpcNew !== "0";
        //this.txtMersyID.nativeElement.disabled = this.OpcNew !== "0";        
    }

    onSubmit() {

        this.AddKpi();
    }

    AddKpi() {


        if (
            this.NEWMersyId.trim() != ""
            &&
            this.NEWMersyId.trim() != ""
        ) {
            const param = {
                "OpcNew": this.OpcNew,
                "KpiDefinitionId_reference": this.KpiDefinitionId,
                "MersyId_NEW": this.NEWMersyId,
                "KpiName_NEW": this.NEWKpiName,
            };

            const url = this.baseUrl + "api/KPIManagement/Add_Kpi";
            this.http.post<any[]>(url, param).subscribe(kpiadded => {

                console.log(param);

                if (!isNaN(Number(kpiadded))) {
                    //const PopUp = this.customDialog.openDisplayInfoDialog({
                    //    message: 'KPI "' + this.NEWMersyId + '" "' + this.NEWKpiName + '" sucessfully added (' + kpiadded + ')',
                    //    caller: this
                    //});


                    const data = {
                        "OpcNew": this.OpcNew,
                        "KpiDefinitionId_reference": this.KpiDefinitionId,
                        "MersyId_NEW": this.NEWMersyId,
                        "KpiName_NEW": this.NEWKpiName,
                        "KpiAdded": kpiadded
                    };

                    this.dialogRef.close(data);

                } else {
                    // ERROR

                    this.customDialog.openDisplayInfoDialog({
                        message: kpiadded,
                        caller: this
                    });
                    this.dialogRef.close(0);
                }

            }, error => {
                Utils.writeLog(this.http, this.baseUrl, error);
            });


        }
        else
        {
            const PopUp = this.customDialog.openDisplayInfoDialog({
                message: 'Please input both MersyID and Kpi Name',
                caller: this
            })

        }

    }


    onBack() {

    }

}


