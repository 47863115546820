import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { EquipmentManagenementService } from 'src/app/services/equipmentmanagement.service';
import { SaveService } from 'src/app/services/save.service';

@Component({
  selector: 'emtconfiguration-list',
  templateUrl: './emtconfiguration-list.component.html',
    styleUrls: ['./emtconfiguration-list.component.less', '../../../style/new-generic-styles.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class EmtconfigurationListComponent implements OnInit {
  @Input() isRowEditMode = false;

  selectedLevel?: EquipmentConfigurationLevel;
  configurations: LevelEquipmentConfiguration[] = [];
  clonedList: { [s: string]: LevelEquipmentConfiguration; } = {};

  defaultColumnsToDisplay: Map<string, string[]>;
  columnsToDisplay: string[] = [];
  editableColumn: string;
  TENANT = "tenant";
  BRANCH = "branch";
  DEVICE = "device";

  configForm!: FormGroup;

  @Output() onRefresh = new EventEmitter<{}>();
  public dataSource = new MatTableDataSource<any>();
  @ViewChild(MatTable, { static: true }) table!: MatTable<any>;

  enumSelectors: Map<string,SelectList[]>= new Map<string,SelectList[]>();
  constructor(
    private emtConfigurationService: EquipmentManagenementService,
    private toastrService: ToastrService,
    private saveService: SaveService,
    private formBuilder: FormBuilder
  )
  {
    this.editableColumn = this.TENANT;
    this.defaultColumnsToDisplay = new Map<string, string[]>();
    this.defaultColumnsToDisplay.set( 
      this.TENANT,
      ['key', 'type','tenant', 'actions']
    );

    this.defaultColumnsToDisplay.set( 
      this.BRANCH, 
      ['key', 'type', 'branch', 'tenant', 'actions']
    );

    this.defaultColumnsToDisplay.set( 
      this.DEVICE, 
      ['key', 'type', 'device', 'branch', 'tenant', 'actions']
    );

    this.columnsToDisplay = this.defaultColumnsToDisplay.get(this.DEVICE)!;

    this.configForm = this.formBuilder.group({
      configRows: this.formBuilder.array([])
    });

    this.enumSelectors.set(
      'Grace Period Expired Consequence',
      [
       {value:'0',text:'No Consequence'},
       {value:'1',text:'Shutdown'}
      ]);

    this.enumSelectors.set(
      'Agent Certificate Invalid Consequence',
      [
        {value:'0',text:'No Consequence'},
        {value:'1',text:'Shutdown'}
      ]);
  }

  ngOnInit() {
    this.resetData()

    if(this.selectedLevel){
      this.loadEquipmentManagementConfiguration();
    }

    this.getHeader();
  }
  ngAfterViewInit() {       
  }

  resetData() {
    this.configForm = this.formBuilder.group({
      configRows: this.formBuilder.array([])
    });
    this.configForm.reset();
    this.dataSource.data = [];
  }

  loadEquipmentManagementConfiguration(){
    this.emtConfigurationService.searchEquipmentConfiguration(
      this.selectedLevel!,
      result =>{
        this.configurations = result;
        this.mapConfigurationToForm();
        this.dataSource = new MatTableDataSource((this.configForm.get('configRows') as FormArray).controls);
      },
      error => {
        this.toastrService.error("Unable to load equipment configuration");
      }
    );
  }

  getHeader(){
    if(!this.selectedLevel){
      this.columnsToDisplay = this.defaultColumnsToDisplay.get(this.TENANT)!;
      this.editableColumn = this.TENANT;
      return;
    }

    if(this.selectedLevel?.deviceId){
      this.columnsToDisplay = this.defaultColumnsToDisplay.get(this.DEVICE)!;
      this.editableColumn = this.DEVICE;
    }
    else if(this.selectedLevel?.branchNumber){
      this.columnsToDisplay = this.defaultColumnsToDisplay.get(this.BRANCH)!;
      this.editableColumn = this.BRANCH;
    }else{
      this.columnsToDisplay = this.defaultColumnsToDisplay.get(this.TENANT)!;
      this.editableColumn = this.TENANT;
    }
  }

  changeSelectedLevel(newLevel: EquipmentConfigurationLevel){
    this.resetData();
    this.selectedLevel = newLevel;
    this.getHeader();
    this.loadEquipmentManagementConfiguration();
  }

  onRowEditInit(index: number)
  {
    var configRows = this.configForm.get('configRows') as FormArray;
    var row = configRows.at(index);
    row.get('OnEdit')?.patchValue(true);
  }

  onRowEditCancel(index:number){
    var previousRow = this.configurations.find(x => x.configurationKey.id == this.configForm.value.configRows[index].Id);
    this.updateFormFromElement(index,previousRow!);
    this.updateFormElement(index,'OnEdit',false);
  }

  updateFormFromElement(index: number,element: LevelEquipmentConfiguration){
    switch(this.editableColumn){
      case this.DEVICE:
        this.updateFormElement(index,'DeviceValue',element.deviceValue);
        break;
      case this.BRANCH:
        this.updateFormElement(index,'BranchValue',element.branchValue);
        break;
      case this.TENANT:
        this.updateFormElement(index,'TenantValue',element.tenantValue);
        break;
    }
  }
  updateFormElement(index: number, fieldName: string, newValue:any)
  {
    var configRows = this.configForm.get('configRows') as FormArray;
    var row = configRows.at(index);
    row.get(fieldName)?.setValue(newValue);
  }

  updateElementsByForm(modifiedFormRows: any[]){
    modifiedFormRows.forEach(row => {
      var relatedElement = this.configurations.filter(config => config.configurationKey.id === row.Id)[0];
      switch(this.editableColumn){
        case this.DEVICE:
          relatedElement.deviceValue = row.DeviceValue;
          break;
        case this.BRANCH:
          relatedElement.branchValue = row.BranchValue
          break;
        case this.TENANT:
          relatedElement.tenantValue = row.TenantValue
          break;
      }
    });
  }

  onShowSavebutton() {
    this.saveService.showSaveButton();
  }
  onSave(){
    let updateRequest: UpdateEquipmentConfigurationValueRequest[] = [];
    var modifiedRows = this.configForm.value.configRows.filter(x => x.OnEdit);
    const requestLevel: EquipmentConfigurationLevel = {
      tenantId: this.selectedLevel!.tenantId,
      branchNumber: this.selectedLevel?.branchNumber,
      deviceId: this.selectedLevel?.deviceId
    };
    modifiedRows.forEach(row => {
      var newValue;
      switch(this.editableColumn){
        case this.DEVICE:
          newValue = row.DeviceValue;
          break;
        case this.BRANCH:
          newValue = row.BranchValue
          break;
        case this.TENANT:
          newValue = row.TenantValue
          break;
      }
      const newRequest: UpdateEquipmentConfigurationValueRequest = {
        level: requestLevel,
        configurationKey: row.Id,
        configurationValue: newValue
      }
      updateRequest.push(newRequest);
    });

    if(updateRequest.length > 0){
      this.emtConfigurationService.updateEquipmentConfiguration(
        updateRequest,
        response => {
          this.updateElementsByForm(modifiedRows);
          modifiedRows.forEach(row => row.OnEdit = false);
          this.toastrService.success('Configuration changes saved.');
        },
        error => {
          this.toastrService.error(error.error);
        });
    }
  }

  anyChange() {
    return this.configForm.value.configRows.filter(x => x.OnEdit).length > 0;
  }

  mapConfigurationToForm(){
    this.configForm = this.formBuilder.group({
      configRows: this.formBuilder.array(this.configurations.map(configKeyValue => this.formBuilder.group({
          Id: new FormControl(configKeyValue.configurationKey.id), 
          Key: new FormControl(configKeyValue.configurationKey.key),
          Type: new FormControl(configKeyValue.configurationKey.type),
          DeviceValue: new FormControl(configKeyValue.deviceValue, this.editableColumn == this.DEVICE ? this.getConfigurationKeyValidators(configKeyValue): []),
          BranchValue: new FormControl(configKeyValue.branchValue, this.editableColumn == this.BRANCH ? this.getConfigurationKeyValidators(configKeyValue): []),
          TenantValue: new FormControl(configKeyValue.tenantValue, this.editableColumn == this.TENANT ? this.getConfigurationKeyValidators(configKeyValue): []),
          OnEdit: new FormControl(false)
        })
      ))
    });
  }

  getConfigurationKeyValidators(configurationValue: LevelEquipmentConfiguration) {
    return (control: AbstractControl): ValidationErrors | null => {

        if (configurationValue.configurationKey.key === 'int') {
            return minValidator(0)(control);
        }
        return null;
    };
  }

  getFormValue(i: number){
    return this.configForm?.get('configRows')?.value[i];
  }

}

export const minValidator = function(min: number){
  return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if(value !== null && (isNaN(value) || value < min)){
          return {
              minError: `Value must be greater than ${min}`
          };
      }
      return null;
  }
}

