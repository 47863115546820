import { Component, Inject, OnInit, Input } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BusinessKeyTypes, Utils } from "../../utils/utils";
import { DialogService } from "../../services/dialog.service";
import { DefaultValuesService } from "../../services/defaultvalues.service";
import { SaveService } from "../../services/save.service";
import { Observable } from "rxjs";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "testcase-form-detail",
    templateUrl: './testcase-form-detail.component.html',
    styleUrls: ['../../style/form.component.less','../../style/custom-mat-dialog.component.less',]
})

export class TestCaseFormDetailComponent implements OnInit {

    /************************************************
     *      Message types
     * */
    DOOR_MOVEMENT: number = Utils.getDoorMovementMessageTypeValue();
    ERROR_CODE: number = Utils.getErrorMessageTypeValue();
    OPERATION_MODE: number = Utils.getOperationModeMessageTypeValue();
    TRIP: number = Utils.getTripMessageTypeValue();
    BLUEBOX: number = Utils.getBlueBoxMessageTypeValue();
    //CONTROLLER: number = Utils.getControllerTypeMessageTypeValue();
    Events: any[] = [{ Description: 'Operation Mode', EventTypeId: this.OPERATION_MODE }, { Description: 'Error Code', EventTypeId: this.ERROR_CODE }, { Description: 'Trip', EventTypeId: this.TRIP }, { Description: 'Door Movement', EventTypeId: this.DOOR_MOVEMENT }, { Description: 'BlueBox Event', EventTypeId: this.BLUEBOX }];

    /************************************************
     *      Schema Keys
     * */
    DOOR_END_STATE_KEY: number = Utils.getDoorEndStateSchemaKey();
    DOOR_FLOOR_KEY: number = Utils.getDoorFloorSchemaKey();
    DOOR_START_STATE_KEY: number = Utils.getDoorStartStateSchemaKey();
    START_FLOOR_KEY: number = Utils.getStartFloorSchemaKey();
    END_FLOOR_KEY: number = Utils.getEndFloorSchemaKey();
    ID_KEY: number = Utils.getIdSchemaKey();

    @Input() list: TestCaseMessageTypesSchemaKeysDescription[] = [];
    @Input() header: any[] = [];
    @Input() rowIndex: number | undefined;
    @Input() parent: TestCase = {} as TestCase;

    title: string;

    selected: TestCaseMessageTypesSchemaKeysDescription = {} as TestCaseMessageTypesSchemaKeysDescription;

    errorCodes: ErrorCode[] = [];
    operationModes: OperationMode[] = [];
    messageTypeKeys: MessageTypesSchemaKey[] = [];
    messageType: number = this.OPERATION_MODE;

    blueBoxEventList: Observable<BusinessKey[]>;
    doorStateList: Observable<BusinessKey[]>;
    
    classname: string[] = [];
    isundodisabled: boolean[] = [];

    newRow = true;
    isToShowRef = false;

    form: FormGroup = new FormGroup({});

    TEXT = 1;
    MILISECONDS = 2;
    OUTPUT = 3;

    constructor(private http: HttpClient
        , private dialog: DialogService
        , public defaults: DefaultValuesService
        , private saveService: SaveService
        , private matDialog: MatDialog
        , @Inject('BASE_URL') private baseUrl: string
        , @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.title = "New Test Case Message for Operation Mode";
        this.blueBoxEventList = new Observable<BusinessKey[]>();
        this.doorStateList = new Observable<BusinessKey[]>();
    }

    ngOnInit() {

        this.blueBoxEventList = Utils.getBusinessKeyLookups(this.http, this.baseUrl, BusinessKeyTypes.BlueBox);
        this.doorStateList = Utils.getBusinessKeyLookups(this.http, this.baseUrl, BusinessKeyTypes.DoorState);

        if (this.header.length === 0) { //this is a new form
            this.header = this.dialog.getHeader();
            this.parent = this.dialog.getParent();
        }
        this.newRow = (this.rowIndex === undefined);
        this.isToShowRef = this.defaults.isToShowReference();
        this.saveService.addListener(this);

        this.selected.Values = [];
        if (!this.newRow && this.rowIndex !== undefined)
            this.messageType = this.selected.MessageTypeID = this.list[this.rowIndex].MessageTypeID;
        this.createForm();
        if (!this.newRow && this.rowIndex !== undefined) {            
            this.selected.Name = this.list[this.rowIndex].Name;
            this.selected.MessageTypeID = this.list[this.rowIndex].MessageTypeID;
            this.selected.TestCaseID = this.list[this.rowIndex].TestCaseID;
            this.selected.IndexInTestCase = this.list[this.rowIndex].IndexInTestCase;
            this.selected.Text = this.list[this.rowIndex].Text;

            this.selected.MiliSecondsToWait = this.list[this.rowIndex].MiliSecondsToWait;
            this.selected.Output = this.list[this.rowIndex].Output;
            this.selected.OutputJSONID = this.list[this.rowIndex].OutputJSONID;

            Utils.httpGetTestCaseMessageTypeSchemaKeyValues(
                this.http
                , this.baseUrl
                , this.selected.TestCaseID
                , this.selected.MessageTypeID
                , this.selected.IndexInTestCase
                , this
                , function (tthis: any, list: TestCaseMessageTypesSchemaKeys[]) {
                    tthis.list[tthis.rowIndex].Values = list;
                    tthis.selected.Values = list;
                    tthis.updateForm();
                }
            );
            
        }
        const tthis = this;
        this.header.forEach(
            function (value: any, index: number, array: any[]) {
                tthis.classname[index] = "";
                tthis.isundodisabled[index] = true;
            }
        );

        this.getLists();
    }

    /**
     * called by the SaveService
     * */
    onCancelForm() {
        const value = this.rowIndex === undefined ? -1 : this.rowIndex;
        this.saveService.fireCancel(value, this);
    }

    onDoorFloorChange() {
        if (this.form && this.selected && this.rowIndex !== undefined
            && this.form.controls['Floor'].value !== this.selected.Values) {
            this.list[this.rowIndex].Values[2].Value = this.form.controls['Floor'].value;
        }
    }

    onEndStateChange(evt: any) {
        if (this.form && this.selected && this.rowIndex !== undefined
            && this.form.controls['EState'].value !== this.selected.Values) {
            this.list[this.rowIndex].Values[1].Value = this.form.controls['EState'].value;
        }
    }

    onEventTypeChange(evt: any) {
        this.messageType = this.selected.MessageTypeID = Number(this.form.controls['EventType'].value);
        this.title = "New Test Case Message for " + evt.source.triggerValue;
        /**
         * to enable the creation of the new Values in getLists
         */
        this.selected.Values = [];
        this.createForm();
        this.getLists();
    }

    onFloorChange(evt: any) {
        // start floor
        if (this.form && this.selected && this.rowIndex !== undefined
            && this.form.controls['SFloor'].value !== this.selected.Values[0].Value) {
            this.list[this.rowIndex].Values[0].Value = this.form.controls['SFloor'].value;
            this.setUndoDisable(this.TEXT, false);
        }

        // end floor
        if (this.form && this.selected && this.rowIndex !== undefined
            && this.form.controls['EFloor'].value !== this.selected.Values[1].Value) {
            this.list[this.rowIndex].Values[1].Value = this.form.controls['EFloor'].value;
            this.setUndoDisable(this.TEXT, false);
        }

        // calculate the time
        if (this.rowIndex !== undefined)
            this.list[this.rowIndex].MiliSecondsToWait = Math.abs(this.form.controls['EFloor'].value - this.form.controls['SFloor'].value) * 3000
        else {
            //this is new row
            this.selected.Values[0].Value = this.form.controls['SFloor'].value;
            this.selected.Values[1].Value = this.form.controls['EFloor'].value;

            this.selected.MiliSecondsToWait = Math.abs(this.form.controls['EFloor'].value - this.form.controls['SFloor'].value) * 3000
        }

        this.updateForm();
    }

    onMilisecondsChange(evt: any) {
        if (this.form && this.selected && this.rowIndex !== undefined
            && this.form.controls['Miliseconds'].value !== this.selected.MiliSecondsToWait) {
            this.list[this.rowIndex].MiliSecondsToWait = this.form.controls['Miliseconds'].value;
            this.setUndoDisable(this.MILISECONDS, false);
        }
    }

    onMilisecondsUndo() {
        if (this.rowIndex !== undefined) {
            this.list[this.rowIndex].MiliSecondsToWait = this.selected.MiliSecondsToWait;
            this.setUndoDisable(this.MILISECONDS, true);
            this.updateForm();
        }
    }

    onOutputChange(evt: any) {
        if (this.form && this.selected !== undefined && this.rowIndex !== undefined)
            this.list[this.rowIndex].OutputJSONID = evt.checked ? 1 : 0;
    }

    /**
     * called by the SaveService
     * */
    onSave() {
        if (this.newRow)
            this.createObject();
        else if (this.rowIndex !== undefined)
            this.selected = this.list[this.rowIndex];

        const tthis = this;
        const url = this.baseUrl + Utils.getTestCaseAPI() + "detail/edit";
        if (this.newRow) {
            this.http
                .put<TestCaseMessageTypesSchemaKeysDescription>(url, this.selected)
                .subscribe(res => {                    
                    tthis.list.unshift(res);
                    tthis.saveService.removeListener(tthis)
                    tthis.onCancel();
                }, error => this.dialog.showErrorDialog(error));
        }
        else {
            this.http
                .post<TestCaseMessageTypesSchemaKeysDescription>(url, this.selected)
                .subscribe(res => {
                    if (tthis.rowIndex !== undefined)
                        tthis.list[tthis.rowIndex] = res;
                    tthis.saveService.removeListener(tthis)
                    tthis.onCancel();
                }, error => this.dialog.showErrorDialog(error));
        }
    }

    /**
     * called by the save button on this form
     * 
     * */
    onSubmit() {
        const value = this.rowIndex === undefined ? -1 : this.rowIndex;
        this.saveService.fireSave(value, this);
    }

    onStartStateChange(evt: any) {
        if (this.form && this.selected && this.rowIndex !== undefined
            && this.form.controls['SState'].value !== this.selected.Values) {
            this.list[this.rowIndex].Values[0].Value = this.form.controls['SState'].value;
        }
    }

    onValueChange(evt: any) {
        if (this.form && this.selected && this.rowIndex !== undefined
            && this.form.controls['Value'].value !== this.selected.Values) {
            this.list[this.rowIndex].Values[0].Value = this.form.controls['Value'].value;
        }
    }

    private createForm() {
        if (this.messageType === this.TRIP) {
            this.form = new FormGroup({
                EventType: new FormControl(this.messageType, [Validators.nullValidator]),
                SFloor: new FormControl('1', [Validators.required]),
                EFloor: new FormControl('2', [Validators.required]),
                Miliseconds: new FormControl(3000, [Validators.required]),
            });
        }
        else if (this.messageType === this.DOOR_MOVEMENT) {
            this.form = new FormGroup({
                EventType: new FormControl(this.messageType, [Validators.nullValidator]),
                SState: new FormControl('200', [Validators.required]),
                EState: new FormControl('100', [Validators.required]),
                Floor: new FormControl('1', [Validators.required]),
                Miliseconds: new FormControl(3000, [Validators.required]),
            });
        }
        else {
            this.form = new FormGroup({
                EventType: new FormControl(this.messageType, [Validators.nullValidator]),
                Value: new FormControl('', [Validators.required]),
                Miliseconds: new FormControl(1000, [Validators.required])
            });
        }
    }

    private createObject() {
        this.selected.TestCaseID = this.parent.TestCaseID;
        this.selected.MessageTypeID = this.messageType;
        this.selected.MiliSecondsToWait = this.form.value.Miliseconds;

        if (this.messageType === this.TRIP) {
            this.selected.Values[0].Value = this.form.controls['SFloor'].value;
            this.selected.Values[1].Value = this.form.controls['EFloor'].value;
        }
        else if (this.messageType === this.DOOR_MOVEMENT) {
            this.selected.Values[0].Value = this.form.controls['SState'].value;
            this.selected.Values[1].Value = this.form.controls['EState'].value;
            this.selected.Values[2].Value = this.form.controls['Floor'].value;
        }
        else
            this.selected.Values[0].Value = this.form.controls['Value'].value;
    }

    /**
     * get the ErrorCodes or OpModes lists
     * 
     * create the SchemaKey Values
     * */
    private getLists() {
        if (this.messageType === this.OPERATION_MODE && this.operationModes.length === 0)
            Utils.httpGetAllOperationModes(
                this.http
                , this.baseUrl
                , this
                , function (tthis: any, list: OperationMode[]) {
                    tthis.operationModes = list;
                }
            );
        else if (this.messageType === this.ERROR_CODE && this.errorCodes.length === 0)
            Utils.httpGetAllErrorCodes(
                this.http
                , this.baseUrl
                , this
                , function (tthis: any, list: ErrorCode[]) {
                    tthis.errorCodes = list;
                }
            );

        if (this.newRow && (this.messageType === this.OPERATION_MODE || this.messageType === this.ERROR_CODE || this.messageType === this.BLUEBOX) && (this.selected.Values === undefined || this.selected.Values.length === 0))
            this.selected.Values.push({
                MessageTypeID: this.messageType
                , SchemaKeyID: this.ID_KEY
                , Value: "0"
                , IndexInTestCase: 0
                , KeyName: "none"
                , Version: "none"
            });
        else if (this.newRow && this.messageType === this.TRIP && (this.selected.Values === undefined || this.selected.Values.length === 0)) {
            // start floor
            this.selected.Values.push({
                MessageTypeID: this.messageType
                , SchemaKeyID: this.START_FLOOR_KEY
                , Value: this.form.controls['SFloor'].value
                , IndexInTestCase: 0
                , KeyName: "none"
                , Version: "none"
            });

            // end floor
            this.selected.Values.push({
                MessageTypeID: this.messageType
                , SchemaKeyID: this.END_FLOOR_KEY
                , Value: this.form.controls['EFloor'].value
                , IndexInTestCase: 0
                , KeyName: "none"
                , Version: "none"
            });
        }
        else if (this.newRow && this.messageType === this.DOOR_MOVEMENT && (this.selected.Values === undefined || this.selected.Values.length === 0)) {
            // door start state
            this.selected.Values.push({
                MessageTypeID: this.messageType
                , SchemaKeyID: this.DOOR_START_STATE_KEY
                , Value: this.form.controls['SState'].value
                , IndexInTestCase: 0
                , KeyName: "none"
                , Version: "none"
            });

            // door end state
            this.selected.Values.push({
                MessageTypeID: this.messageType
                , SchemaKeyID: this.DOOR_END_STATE_KEY
                , Value: this.form.controls['EState'].value
                , IndexInTestCase: 0
                , KeyName: "none"
                , Version: "none"
            });

            // floor
            this.selected.Values.push({
                MessageTypeID: this.messageType
                , SchemaKeyID: this.DOOR_FLOOR_KEY
                , Value: this.form.controls['Floor'].value
                , IndexInTestCase: 0
                , KeyName: "none"
                , Version: "none"
            });
        }
    }

    private getValueFromSchemaKey(key: number): string {
        let ret = "";
        if (this.selected && this.selected.Values)
            this.selected.Values.forEach(x => {
                if (x.SchemaKeyID === key) {
                    ret = x.Value;
                    return;
                }
            });
        return ret;
    }

    private setUndoDisable(index: number, value: boolean) {
        this.classname[index] = value ? "" : Utils.getEditingInputClassName();
        this.isundodisabled[index] = value;
    }

    private updateForm() {
        if (this.form) {
            if (this.messageType === this.TRIP) {
                const mili = this.rowIndex !== undefined ? this.list[this.rowIndex].MiliSecondsToWait : this.selected.MiliSecondsToWait;
                this.form.setValue({
                    EventType: this.selected.MessageTypeID
                    , SFloor: this.selected.Values[0].Value
                    , EFloor: this.selected.Values[1].Value
                    , Miliseconds: mili
                });
            }
            if (this.messageType === this.DOOR_MOVEMENT) {
                const mili = this.rowIndex !== undefined ? this.list[this.rowIndex].MiliSecondsToWait : this.selected.MiliSecondsToWait;
                this.form.setValue({
                    EventType: this.selected.MessageTypeID
                    , SState: this.selected.Values[0].Value
                    , EState: this.selected.Values[1].Value
                    , Floor: this.selected.Values[2].Value
                    , Miliseconds: mili
                });
            }
            else if (this.rowIndex !== undefined) {
                console.log(this.selected.Values)
                this.form.setValue({
                    EventType: this.selected.MessageTypeID
                    , Value: Number(this.selected.Values[0].Value) //this.getValueFromSchemaKey(this.ID_KEY)
                    , Miliseconds: this.list[this.rowIndex].MiliSecondsToWait
                });
            }
        }
    }

    onCancel() {
        this.matDialog.closeAll();
    }

}