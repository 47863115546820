<div class="example-container">
    <table mat-table
           [dataSource]="list" multiTemplateDataRows
           class="mat-elevation-z8 mat-table-custom" id="fingerprintrules">

        <!--</ng-container>-->

        <ng-container matColumnDef="arrow">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <span class="material-icons" *ngIf="checkExpanded(element); else show_expand_more">
                    expand_less
                </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="tenant">
            <th mat-header-cell *matHeaderCellDef> Tenant </th>
            <td mat-cell *matCellDef="let element"> {{element.Tenant}} ({{element.TenantName}})</td>
        </ng-container>

        <ng-container matColumnDef="rule">
            <th mat-header-cell *matHeaderCellDef> Fingerprint Rule </th>
            <td mat-cell *matCellDef="let element"> {{element.RuleCat}} </td>
        </ng-container>


        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                <div [@detailExpand]="checkExpanded(element) ? 'expanded' : 'collapsed'">
                    <table mat-table [dataSource]="element.ControllerTypeFingerprintRules" class="mat-subtable-custom" id="fingerprintrulesbycontrollertype">

                        Controller Types
                        <ng-container matColumnDef="controllertype">
                            <th mat-header-cell *matHeaderCellDef> ControllerTypeBK </th>
                            <td mat-cell *matCellDef="let element2">
                                <p [innerHTML]="element2.ControllerTypeName"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="isstableenabled">
                            <th mat-header-cell *matHeaderCellDef> Is Stable Enabled? </th>
                            <td mat-cell *matCellDef="let element2">
                                <mat-checkbox [checked]="element2.IsStableEnabled" (change)="changeElement(element2, 'stableenabled', $event.checked)" [ngClass]="isEditingRow(element2) ? 'black' :''" [disabled]="!isEditingRow(element2)"></mat-checkbox>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="isevalenabled">
                            <th mat-header-cell *matHeaderCellDef> Is Evaluation Enabled? </th>
                            <td mat-cell *matCellDef="let element2">
                                <mat-checkbox [checked]="element2.IsEvalEnabled" (change)="changeElement(element2, 'evalenabled', $event.checked)" [ngClass]="isEditingRow(element2) ? 'black' :''" [disabled]="!isEditingRow(element2)"></mat-checkbox>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="isprodenabled">
                            <th mat-header-cell *matHeaderCellDef> Is Prod Enabled? </th>

                            <td mat-cell *matCellDef="let element2">
                                <mat-checkbox [checked]="element2.IsProdEnabled" (change)="changeElement(element2, 'prodenabled', $event.checked)" [ngClass]="isEditingRow(element2) ? 'black' :''" [disabled]="!isEditingRow(element2)"></mat-checkbox>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="stableratiovalue">
                            <th mat-header-cell *matHeaderCellDef> Stable Ratio Value </th>

                            <td mat-cell *matCellDef="let element2">
                                <ng-container *ngIf="!isEditingRow(element2)">
                                    {{element2.StableRatioValue}}
                                </ng-container>
                                <ng-container *ngIf="isEditingRow(element2)">
                                    <input type="number" matInput [value]="element2.StableRatioValue" (change)="changeElement(element2, 'stableratiovalue', $event.target!)" [(ngModel)]="element2.StableRatioValue" min="0" max="100" />
                                </ng-container>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="evalratiovalue">
                            <th mat-header-cell *matHeaderCellDef> Evaluation Ratio Value </th>
                            <td mat-cell *matCellDef="let element2">
                                <ng-container *ngIf="!isEditingRow(element2)">
                                    {{element2.EvalRatioValue}}
                                </ng-container>
                                <ng-container *ngIf="isEditingRow(element2)">
                                    <input type="number" matInput [value]="element2.EvalRatioValue" (change)="changeElement(element2, 'evalratiovalue', $event.target!)" [(ngModel)]="element2.EvalRatioValue" min="0" max="100" />
                                </ng-container>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="prodratiovalue">
                            <th mat-header-cell *matHeaderCellDef> Production Ratio Value </th>
                            <td mat-cell *matCellDef="let element2">
                                <ng-container *ngIf="!isEditingRow(element2)">
                                    {{element2.ProdRatioValue}}
                                </ng-container>
                                <ng-container *ngIf="isEditingRow(element2)">
                                    <input type="number" matInput [value]="element2.ProdRatioValue" (change)="changeElement(element2, 'prodratiovalue', $event.target!)" [(ngModel)]="element2.ProdRatioValue" min="0" max="100" />
                                </ng-container>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef>Actions</th>
                            <td mat-cell *matCellDef="let element2">
                                <button mat-icon-button matTooltip="Edit Row"
                                        (click)="onRowEditInit(element2)"
                                        *ngIf="!isEditingRow(element2)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                                <button mat-icon-button matTooltip="Apply to all Controller Types"
                                        (click)="onApplyToAllCts(element2)"
                                        *ngIf="!isEditingRow(element2)">
                                    <mat-icon>playlist_add_check</mat-icon>
                                </button>
                                <button mat-icon-button matTooltip="Cancel Row"
                                        (click)="onRowEditCancel(element2)"
                                        *ngIf="isEditingRow(element2)">
                                    <mat-icon>rotate_left</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row class="subtableheader" *matHeaderRowDef="columnsToDisplay2"></tr>
                        <tr mat-row *matRowDef="let row2; let i2 = index; columns: columnsToDisplay2;"
                            [class.alternative2]="isEditingRow(row2)"
                            class="subitem"></tr>
                    </table>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row class="tableheader" *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; let i = dataIndex; columns: columnsToDisplay;"
            class="item"
            [class.example-expanded-row]="checkExpanded(element)"
            (click)="pushPopElement(element)">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="row-subitem"></tr>
    </table>
</div>

<ng-template #show_expand_more>
    <span class="material-icons">
        expand_more
    </span>
</ng-template>

