<div class="additionalUsergrp">
    <div class="row heading-background mt-5">

        <h4 style="padding-left:10px">{{data.title}}</h4>

    </div>

    <div class="">
        <div>
            <div class="row ">
                <h3 style=" display: flex; justify-content: center; padding-inline:20px">{{data.statement}}</h3>
                <mat-divider></mat-divider>
                <ul *ngFor="let item of additionalUsers">
                    <li> {{ item }}</li>
                </ul>
            </div>
        </div>
    </div>


    <div class="panel-body">

        <button (click)="backButtonHandler($event)" mat-raised-button class="btn  button-color-fix_red ">
            <mat-icon class=" icon-color-fix ">arrow_back_ios</mat-icon>
            Back
        </button>

    </div>

</div>
