<table-material *ngIf="list"
                id-table="connection-type-id"
                [header]="header"
                [data]="list"
                [getValue]="getData"
                (onDelete)="onDelete($event)"
                (onNewElement)="onNewConnectionType()"
                (onRefresh)="refresh()"
                [selectable]="false">
</table-material>

                [rowEditTemplate]="edit"
                [rowExpandTemplate]="expandPredefinedItemsDescriptions"
                [(expanded)]="expanded"
                (onExpand)="onExpand($event)"


<ng-template #edit let-index="index">
    <!--    <controllerconfig-form [header]="header" [list]="list" [rowIndex]="index"></controllerconfig-form>-->
</ng-template>
