import { Component, Inject, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { DialogService } from '../../../services/dialog.service';
import { Utils } from '../../../utils/utils';
import { SaveService } from '../../../services/save.service';

@Component({
    selector: 'controllerconfigdescription-form'
    , templateUrl: './controllerconfigdescription-form.component.html'
    , styleUrls: ['../../../style/form.component.less']
})
export class ControllerConfigDescriptionFormComponent implements OnInit {

    @Input() list: ControllerConfigDescription[] = [];
    @Input() header: any[] = [];
    @Input() rowIndex: number | undefined;

    title: string;
    
    selected: ControllerConfigDescription = <ControllerConfigDescription>{};

    classname: String[] = [];
    isundodisabled: boolean[] = [];

    newRow = true;

    form: FormGroup = new FormGroup({});

    DESCRIPTION = 0;

    constructor(private http: HttpClient
        , private dialog: DialogService
        , private saveService: SaveService
        , @Inject('BASE_URL') private baseUrl: string
    ) {

        this.title = "Manage Controller Config Description";
    }

    ngOnInit() {

        if (this.header.length === 0) //this is a new form
            this.header = this.dialog.getHeader();

        this.newRow = (this.rowIndex === undefined);
        this.saveService.addListener(this);
        this.saveService.initUndo(this);

        this.createForm();
        if (!this.newRow && this.rowIndex !== undefined) {
            this.selected.ControllerConfigDescriptionID = this.list[this.rowIndex].ControllerConfigDescriptionID;
            this.selected.BK = this.list[this.rowIndex].BK;
            this.updateForm();
        }
    }

    onDescriptionChange(evt: any) {
        if (this.form && this.rowIndex !== undefined && this.selected.BK
            && this.form.controls['Description'].value !== this.selected.BK) {
            this.list[this.rowIndex].BK = this.form.controls['Description'].value;
            this.saveService.setFieldUndoDisable(this.DESCRIPTION, false, this);
        }
        this.saveService.showSaveButton();
    }

    onDescriptionUndo() {
        if (this.rowIndex !== undefined) {
            this.list[this.rowIndex].BK = this.selected.BK;
            this.saveService.setFieldUndoDisable(this.DESCRIPTION, true, this);
            this.updateForm();
        }
    }

    onCancelForm() {
        this.saveService.fireCancel(this.rowIndex, this);
    }

    /**
     * called by the SaveService
     * */
    onSave() {
        if (this.newRow)
            this.createObject();
        else if (this.rowIndex !== undefined)
            this.selected = this.list[this.rowIndex];

        const tthis = this;
        this.http
            .post<ControllerConfigDescription>(
                this.baseUrl + Utils.getControllerTypeAPI() + "addcontrollerconfigdescription"
                , this.selected
            )
            .subscribe(
                res => {
                    if (tthis.rowIndex !== undefined)
                        tthis.list[tthis.rowIndex] = res;
                    else if (tthis.newRow)
                        tthis.list.unshift(res);
                }
                , error => {
                    tthis.dialog.showErrorDialog(error);
                }
            );
    }

    /**
     * called by the save button on this form
     * 
     * */
    onSubmit() {
        const value = this.rowIndex === undefined ? -1 : this.rowIndex;
        this.saveService.fireSave(value, this);
    }

    private createForm() {
        this.form = new FormGroup({
            Description: new FormControl('', [Validators.required]),
        });
    }

    private createObject() {
        this.selected.ControllerConfigDescriptionID = -1;
        this.selected.BK = this.form.controls['Description'].value;
    }

    private updateForm() {
        if (this.form && this.rowIndex !== undefined) {
            let e = {
                Description: this.list[this.rowIndex].BK
            };
            this.form.setValue(e);
        }
    }
}


