import { Component, Output, Inject, EventEmitter, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Utils } from '../../utils/utils';
import { DefaultValuesService } from '../../services/defaultvalues.service';
import { WaitingService } from '../../services/waiting.service';
import { DialogService } from '../../services/dialog.service';
import { SaveService } from '../../services/save.service';
import { Subject } from 'rxjs';

@Component({
    selector: 'country',
    template: `Country:<br />
                <ng-select (change)="onSelect($event)" style="width:240px; border: 1px white solid" class="top-main-select"
                    [items]="countries"
                   bindLabel="Name"
                   bindValue="CountryCode"
                   [closeOnSelect]="true"
                   [searchable]="true"
                   [(ngModel)]="value">
                    <ng-template ng-option-tmp let-item="item">
                        {{ item.CountryCode }} - ({{ item.Name }})
                      </ng-template>
                </ng-select>`,
    styleUrls: ['../../style/mat-select.component.less']
})
export class CountryComponent implements OnInit {

    @Output() countryChanged = new EventEmitter<string>();
    value = '';

    public countries: Country[] = [];

    /** Subject that emits when the component has been destroyed. */
    protected _onDestroy = new Subject<void>();

    constructor(
        private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , private defaults: DefaultValuesService
        , private waiting: WaitingService
        , private dialog: DialogService
        , private saveService: SaveService
    ) {
    }

    ngOnInit() {
        this.dialog.tthis = this;

        Utils.httpGetAllCountries(
            this.http
            , this.baseUrl
            , this
            , function (tthis: CountryComponent, list: Country[]) {
                tthis.countries = list.filter(x => x.CountryCode !== 'ZZ' && x.CountryCode !== '  ');
                tthis.value = tthis.countries[0].CountryCode
                tthis.defaults.notifyCountryChange(tthis.value);
            }
        );
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    onSelect(country: any) {
        if (!country)
            return;
        this.value = country.CountryCode;
        this.defaults.notifyCountryChange(this.value);
    }
}