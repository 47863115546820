import { Component, Inject, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Utils } from '../../utils/utils';
import { DefaultValuesService } from '../../services/defaultvalues.service';
import { ComponentTool } from '../../interfaces/componenttool';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { InteractionType } from '@azure/msal-browser';

enum ControllerOwnerMessages {
    IsOwner = "You are the controller owner.",
    NotOwner = "You are not the controller owner.",
    NotSet = '',
}

@Component({
    selector: 'nav-menu-tools',
    templateUrl: './navmenu-tools.component.html',
    styleUrls: ['../../style/menu.component.less']
})
export class NavMenuToolsComponent implements OnInit, OnDestroy {
    @Input() userUPN: string = '';
    @Input() isControllerOwner: boolean = false;
    @Input() hideOwnerMenuItem: boolean | undefined;

    controllerTypeSubscription: any;
    ControllerOwnerMessage: string = '';

    componentToolEnum: typeof ComponentTool = ComponentTool;

    constructor(
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
        , private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , public defaults: DefaultValuesService
        , private authService: MsalService
    ) {
    }

    ngOnInit() {
        this.controllerTypeSubscription = this.defaults.controllerTypeMessageChanges$.subscribe(async (item: ControllerType) => { await this.onGetControllerTypeChanges(item); });
    }

    ngOnDestroy() {
        this.controllerTypeSubscription.unsubscribe();
    }

    onLogout() {                 
        this.authService.logout()
    } 

    logout() {
        const activeAccount = this.authService.instance.getActiveAccount() || this.authService.instance.getAllAccounts()[0];

        if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
            this.authService.logoutPopup({
                account: activeAccount,
            });
        } else {
            this.authService.logoutRedirect({
                account: activeAccount,
            });
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['userUPN'])
            this.userUPN = changes['userUPN'].currentValue;
        if (changes['isControllerOwner']) {
            if (changes['isControllerOwner'].isFirstChange()) {
                this.ControllerOwnerMessage = ControllerOwnerMessages.NotSet;
                return;
            }

            this.isControllerOwner = changes['isControllerOwner'].currentValue;
            //this.isControllerOwner = changes['isControllerOwner'] === undefined ? this.isControllerOwner : changes['isControllerOwner'].currentValue;

            this.setControllerOwnerMessage(this.isControllerOwner);
            this.defaults.isControllerOwner = this.isControllerOwner;
        }

    }


    setControllerOwnerMessage(isControllerOwner: boolean) {
        if (isControllerOwner) {
            this.ControllerOwnerMessage = ControllerOwnerMessages.IsOwner;
        } else {
            this.ControllerOwnerMessage = "Controller owner: " + this.defaults.controllertype.CountryOwnerCode + " (" + this.defaults.controllertype.CountryOwnerName + ")";
        }
    }

    async onGetControllerTypeChanges(ct: ControllerType) {
        let tenant = this.defaults.tenant;
        let controllerTypeId = ct.ControllerTypeID;

        await Utils.httpGetIsControllerOwner(
            this.http,
            this.baseUrl,
            this,
            tenant,
            controllerTypeId,
            function (tthis: NavMenuToolsComponent, value: boolean) {
                tthis.isControllerOwner = value;
                tthis.setControllerOwnerMessage(tthis.isControllerOwner);
                tthis.defaults.isControllerOwner = tthis.isControllerOwner;
                tthis.defaults.notifyIsControllerOwnerChange();
            }
        )
    }

    getColor(isControllerOwner: boolean) {
        return isControllerOwner ? '#24fc03' : 'red';
    }
}
