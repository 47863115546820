import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from "@angular/router";
import { DefaultValuesService } from '../../services/defaultvalues.service';
import { SaveService } from '../../services/save.service';

@Component({
    selector: 'maintenance-control-plan-group-menu',
    templateUrl: './mcp-menu-group.component.html',
    styleUrls: ['../../style/menu.component.less']
})

export class MaintenanceControlPlanGroupMenuComponent {

    @Input() selected: MaintenanceControlPlan = <MaintenanceControlPlan>{};
    @Output() newEvent = new EventEmitter<{}>();

    constructor(
        private router: Router
        , public saveService: SaveService
        , public defaults: DefaultValuesService
    ) {
    }


    onBack() {
        this.router.navigate(["maintenancecontrolplan"]);
    }

    //onDelete() {
    //    this.dialog.showConfirmDialog( this.selected.Title + "(" + this.selected.MCPID + ")?", "Delete Maintenance Control Plan");
    //}

    //onEdit() {
    //    if (this.selected)
    //        this.router.navigate(["maintenancecontrolplan/edit", this.selected.MCPID]);
    //}

    //onYes() {
    //    this.http
    //        .delete<MaintenanceControlPlan>(
    //            this.baseUrl + Utils.getMaintenanceControlPlanAPI() + this.selected.MCPID
    //        )
    //        .subscribe(res => {
    //            var v = res;
    //            this.dialog.showSuccessDialog( "Maintenance Control Plan - " + this.selected.Title + "(" + this.selected.MCPID + ") successfully deleted", "Delete Maintenance Control Plan");
    //        }, error => this.dialog.showErrorDialog( error));
    //}
}
