import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { DialogService } from '../../../services/dialog.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Utils } from '../../../utils/utils';
import { DefaultValuesService } from '../../../services/defaultvalues.service';


@Component({
    selector: 'createasset',
    templateUrl: './createasset.component.html',
    styleUrls: ['../../../style/custom-mat-dialog.component.less']
})


export class CreateAssetComponent implements OnInit {

    body = {
        assettype: '0',
        unitid: '',
        branchnumber: '',
        regionnumber: '',
        buildingaddr1: '',
        zipcode: '',
        buildingcity: ''
    };

    public form: FormGroup;
    submitted = false;

    constructor(private http: HttpClient
        , private confimDialog: DialogService
        , @Inject(MAT_DIALOG_DATA) public data: any
        , @Inject('BASE_URL') private baseUrl: string
        , public dialogRef: MatDialogRef<CreateAssetComponent>
        , private formBuilder: FormBuilder
        , public defaults: DefaultValuesService

    ) {

        this.confimDialog.tthis = this;

        this.form = this.formBuilder.group({
            assettype: [''],
            unitid: ['', [Validators.required]],
            branchnumber: ['', [Validators.required]],
            regionnumber: ['', [Validators.required]],
            buildingaddr1: ['', [Validators.required]],
            zipcode: ['', [Validators.required]],
            buildingcity: ['', [Validators.required]]
        });
    }

    ngOnInit(): void {
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        switch (this.body.assettype) {
            case '0':
                Utils.httpUpdateElevators(
                    this.http
                    , this.baseUrl
                    , [this.body]
                    , this
                    , function (tthis: CreateAssetComponent) {
                        tthis.dialogRef.close({ event: 'submit' });
                        tthis.confimDialog.showSuccessDialog( 'Asset created successfully' )
                    },
                    function (tthis: any) {
                        tthis.confimDialog.showErrorDialog({ 'error': { 'message': 'Branch Number provided does not exist for the selected Tenant. Please check all available branches through Branch Hierarchy section.' } })
                    }
                );
                break;

            case '1':
                Utils.httpUpdateEscalators(
                    this.http
                    , this.baseUrl
                    , this.defaults.stage
                    , [this.body]
                    , this
                    , function (tthis: CreateAssetComponent) {
                        tthis.dialogRef.close({ event: 'submit' });
                        tthis.confimDialog.showSuccessDialog('Asset created successfully')
                    },
                    function (tthis: any) {
                        tthis.confimDialog.showErrorDialog({ 'error': { 'message': 'Branch Number provided does not exist for the selected Tenant. Please check all available branches through Branch Hierarchy section.' } })
                    }
                );
                break;
        }

    }

    onBack() { }

    onNoClick(): void {
        this.dialogRef.close();
    }
}


