import { Component, OnInit, OnDestroy, Inject, ViewChild } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { DefaultValuesService } from '../../../../services/defaultvalues.service';
import { Utils } from '../../../../utils/utils';
import { SaveService } from '../../../../services/save.service';
import { ContractItemListComponent } from "./contractitem-list.component";
import { ContractItemFilterComponent } from "./contractitem-filter.component";
import { Subscription } from "rxjs";

@Component({
    selector: "contractitems",
    templateUrl: 'contractitem.component.html',
    styleUrls: []
})

export class ContractItemComponent implements OnInit, OnDestroy {

    list: Contract[] = [];

    title = "Contracts";
    showLocalFilter = false;

    subscriptionStage: any;
    subscriptionTenant: any;
    subscriptions: Subscription[] = [];

    @ViewChild(ContractItemListComponent, { static: true }) listComponent!: ContractItemListComponent;
    @ViewChild(ContractItemFilterComponent, { static: true }) filterComponent!: ContractItemFilterComponent;

    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string
        , private defaults: DefaultValuesService
        , private saveService: SaveService
    ) {

    }

    ngOnInit(): void {
        this.subscriptionStage = this.defaults.stageMessageChanges$.subscribe(() => {
            this.getAllContractsByTenant()
            this.listComponent.clonedList = {}
        });
        this.subscriptionTenant = this.defaults.tenantMessageChanges$.subscribe(() => {
            this.getAllContractsByTenant()
            this.listComponent.clonedList = {}
        });
        if (this.defaults.tenant)
            this.getAllContractsByTenant()
    }


    ngOnDestroy() {
        this.subscriptionStage.unsubscribe();
        this.subscriptions.forEach(x => x.unsubscribe())
        this.subscriptionTenant.unsubscribe();
    }

    getAllContractsByTenant() {
        Utils.httpGetContracts(
            this.http
            , this.baseUrl
            , this.defaults.stage
            , {} as ContractFilter
            , this
            , function (tthis: any, list: Contract[]) {
                tthis.list = list;
            }
        );
    }

    onFilter(event: ContractFilter) {
        Utils.httpGetContracts(
            this.http
            , this.baseUrl
            , this.defaults.stage
            , event
            , this
            , function (tthis: any, list: Contract[]) {
                tthis.list = list;
                tthis.listComponent.clonedList = {}
                tthis.listComponent.expandedElements = new Set();
                tthis.saveService.showSaveButton(false)
                if (event.ContractNumbers.length < 1 && event.CustomerIds.length < 1
                    && event.UnitIds.length < 1 && event.ContractLines.length < 1 && event.ContractLinesLocale.length < 1
                    && event.ContractTypes.length < 1 && event.ContractTypesLocale.length < 1)
                    tthis.showLocalFilter = false;
                else
                    tthis.showLocalFilter = true;
            }
        );
    }

    onReload() {
        this.getAllContractsByTenant();
        this.listComponent.clonedList = {}
        this.saveService.showSaveButton(false)
    }

    downloadContractsFiltered() {
        let filter: ContractFilter;
        if (this.filterComponent.form) {
            filter = {
                'CustomerIds': this.filterComponent.customerIds,
                'ContractNumbers': this.filterComponent.contractNumbers,
                'UnitIds': this.filterComponent.unitIds,
                'ContractLines': this.filterComponent.contractLines,
                'ContractLinesLocale': this.filterComponent.contractLinesLocale,
                'ContractTypes': this.filterComponent.contractTypes,
                'ContractTypesLocale': this.filterComponent.contractTypesLocale
            }
        }
        else return;
        Utils.httpDownloadContracts(
            this.http
            , this.baseUrl
            , this.defaults.stage
            , this.defaults.tenant
            , filter
            , this
            , null)
    }
}
