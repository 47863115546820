<div style="display:flow-root">
    <div style="float:left">
        <h5>This is the EMT Manufacturing Center Products management screen. </h5>
    </div>
</div>
<div class="flex-center">
    <div class="contentWidth">
        <div style="height: calc(90vh - 100px); overflow-y: auto;">
            <form [formGroup]="CPForm" autocomplete="off">
                <ng-container formArrayName="CPRows">
                    <table #objTable
                           mat-table
                           matSort
                           [dataSource]="dataSource"
                           class="table-add-butt-header-orange mat-elevation-z8 mat-table-custom">

                        <ng-container matColumnDef="headertitle">
                            <th class="title-col" mat-header-cell *matHeaderCellDef colspan="4">
                                <div class="pull-left">
                                    <h4>Manufacturing Center Products</h4>
                                </div>
                            </th>
                        </ng-container>
                        <ng-container matColumnDef="headeraddbutton">
                            <th class="butt-col" mat-header-cell *matHeaderCellDef>
                                <div class="OrangeButton">
                                    <button mat-raised-button (click)="addNewRow()"
                                            matTooltip="Add Product" class="button-color-fix pull-right">
                                        <mat-icon class="icon-color-fix icon-padding-fix">add_box</mat-icon>
                                    </button>
                                </div>
                            </th>
                        </ng-container>

                        <!-- Product Code Column -->
                        <ng-container matColumnDef="productcode">
                            <th mat-header-cell *matHeaderCellDef style="width:25%">Product Code</th>
                            <td mat-cell *matCellDef="let element; let i = index" style="width:25%">
                                <div [innerHTML]="CPForm?.get('CPRows')?.value[i].productCode" *ngIf="!CPForm?.get('CPRows')?.value[i]?.isNewRow"></div>
                                <mat-form-field style="width: 100%" *ngIf="CPForm?.get('CPRows')?.value[i]?.isNewRow">
                                    <input matInput type="text"
                                           [ngModelOptions]="{standalone: true}"
                                           [(ngModel)]="CPForm?.get('CPRows')?.value[i].productCode"
                                           maxlength="30"
                                           matTooltip="Max 30 characters" />
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <!-- Center Column -->
                        <ng-container matColumnDef="center">
                            <th mat-header-cell *matHeaderCellDef style="width:15%">MFG Center</th>
                            <td mat-cell *matCellDef="let element; let i = index" style="width:15%">
                                <div [innerHTML]="getCenterName(CPForm?.get('CPRows')?.value[i].mfgCenterId)" *ngIf="CPForm?.get('CPRows')?.value[i]?.isEditable"></div>
                                <mat-form-field floatLabel="never" *ngIf="!CPForm?.get('CPRows')?.value[i]?.isEditable">
                                    <mat-select style="min-width: 200px; color:red" [(ngModel)]="CPForm.get('CPRows')?.value[i].mfgCenterId"
                                                [ngModelOptions]="{standalone: true}">
                                        <mat-option [value]="-1" [disabled]="true">Select</mat-option>
                                        <mat-option *ngFor="let center of availableCenters" [value]="center.centerId">
                                            {{ center.centerName }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <!-- Product Usage Column-->
                        <ng-container matColumnDef="productusage">
                            <th mat-header-cell *matHeaderCellDef style="width:25%">Product Usage</th>
                            <td mat-cell *matCellDef="let element; let i = index" style="width:25%">
                                <div [innerHTML]="getProductUsageName(CPForm?.get('CPRows')?.value[i].productUsageId)" *ngIf="CPForm?.get('CPRows')?.value[i]?.isEditable"></div>
                                <mat-form-field floatLabel="never" *ngIf="!CPForm?.get('CPRows')?.value[i]?.isEditable">
                                    <mat-select style="min-width: 200px; color:red" [(ngModel)]="CPForm.get('CPRows')?.value[i].productUsageId"
                                                [ngModelOptions]="{standalone: true}">
                                        <mat-option [value]="-1" [disabled]="true">Select</mat-option>
                                        <mat-option *ngFor="let pUsage of availableProductUsages" [value]="pUsage.productUsageID">
                                            {{ pUsage.name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <!-- Product Name Column -->
                        <ng-container matColumnDef="productname">
                            <th mat-header-cell *matHeaderCellDef style="width:25%">Product Name</th>
                            <td mat-cell *matCellDef="let element; let i = index" style="width:25%">
                                <div [innerHTML]="CPForm?.get('CPRows')?.value[i].productName" *ngIf="CPForm?.get('CPRows')?.value[i]?.isEditable"></div>
                                <mat-form-field style="width: 100%" *ngIf="!CPForm?.get('CPRows')?.value[i]?.isEditable">
                                    <input matInput type="text"
                                           [ngModelOptions]="{standalone: true}"
                                           [(ngModel)]="CPForm?.get('CPRows')?.value[i].productName"
                                           maxlength="50"
                                           matTooltip="Max 50 characters" />
                                </mat-form-field>
                            </td>
                        </ng-container>

                    


                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef style="width:10%"></th>
                            <td mat-cell
                                *matCellDef="let element; let i = index" style="width:10%">

                                <button mat-icon-button
                                        *ngIf="!CPForm.get('CPRows')?.value[i]?.isEditable"
                                        matTooltip="Cancel Changes"
                                        color="warn"
                                        (click)="resetRow(i)">
                                    <mat-icon class="actionButtons">rotate_left</mat-icon>
                                </button>

                                <button mat-icon-button
                                        *ngIf="CPForm.get('CPRows')?.value[i]?.isEditable"
                                        matTooltip="Edit"
                                        color="primary"
                                        (click)="editRow(i)">
                                    <mat-icon class="actionButtons">edit</mat-icon>
                                </button>

                                <button mat-icon-button
                                        *ngIf="false"
                                        matTooltip="Delete"
                                        color="warn"
                                        (click)="deleteRow(i)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="savefooter">
                            <th mat-footer-cell *matFooterCellDef colspan="5">
                                <div class="OrangeButton">
                                    <button mat-raised-button type="submit" (click)="onSave()"
                                            [disabled]="!anyChange()"
                                            [matTooltip]="anyChange()? 'Save changes' : 'Save disabled'"
                                            class="button-color-fix pull-right">
                                        Save
                                        <mat-icon style="color:white !important" class="icon-color-fix">save</mat-icon>
                                    </button>
                                </div>
                            </th>

                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="['headertitle', 'headeraddbutton']; sticky: true" class="stickyTopz100"></tr>
                        <tr class="col-headers" mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; let i = index; columns: columnsToDisplay;" [class.alternative2]="!CPForm.get('CPRows')?.value[i]?.isEditable"></tr>
                        <tr mat-footer-row *matFooterRowDef="['savefooter']; sticky: true"></tr>
                    </table>
                </ng-container>
            </form>
        </div>
    </div>
</div>