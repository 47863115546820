import { Component, Inject, Input, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { MatChipInputEvent } from "@angular/material/chips";
import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';
import { SaveService } from '../../../services/save.service';
import { Utils } from '../../../utils/utils';
import { DefaultValuesService } from '../../../services/defaultvalues.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DialogService } from "../../../services/dialog.service";

@Component({
    selector: "chairlift-list",
    templateUrl: './chairlift-list.component.html',
    styleUrls: ['./chairlift-list.component.less', '../../../style/mat-table.component.less'],

    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' })),
            transition('expanded => collapsed', [animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
        ]),
    ]
})

export class ChairliftListComponent implements OnInit {
    @Input('list') list: Chairlift[] = [];
    @Input('showLocalFilter') showLocalFilter: boolean = false;

    columnsToDisplay = ['arrow', 'index', 'UnitId', 'BranchNumber', 'ControllerType', 'BuildingAddr1', 'BuildingCity', 'ChairliftNickname', '_ChangedAtUtc', 'actions'];

    subcolumnsToDisplay = ['_ChairliftId', 'DeviceId', 'TechnicianId', 'MaintenanceFrequencyPerYear', 'HasResidentMechanic', 'BuildingType', 'RegionNumber', 'BuildingAddr2',
        'BuildingName', 'ZipCode', 'BuildingState', 'CountryCode', 'Longitude', 'Latitude', '_CreatedAtUtc', '_ChangedByUser', '_ChangedByMsgType', 'ProvisioningStatus',
        'Timezone', 'ProvisioningStatusChangeDate', 'IsMAXCompatible', 'AssetType', 'SwivelType', 'NumberCurves', 'RailStart', 'RailFinish', 'BackToForehead', 'BackColor',
        'NumberOfreductions', 'ChargerType', 'SwivelSeat', 'CommModule', 'BaseStation', 'FootrestFolding', 'ParkingAngleEnd', 'ParkingAngleStart', 'FootrestHeight',
        'OverrunUpstairs', 'OverrunDownstairs', 'Itemcarrier', 'Keylock', 'AttendentControl', 'NumberParkpositions', 'NumberLevels', 'Uiposition', 'Repeater', 'Rfversion',
        'NonStandardRailColor', 'Railcolour', 'SafetybeltDetection', 'SafetybeltPosition', 'Seatheight', 'Chair', 'Telephone1', 'Telephone2', 'Telephone3', 'RailLength',
        'RailPosition', 'ChairUpholstory', 'Usersize', 'FootrestType', 'Wheeltransfer', 'BackToKneeClient', 'BackToHeadClient', 'PrimaryCustomerEmailAddress',
        'PrimaryCustomerFirstName', 'PrimaryCustomerLastName', 'MAXSubscriptionlevel',

        'ChairliftType', 'CustomerID', 'MaxSoftwareVersion', 'CbSoftwareVersion', 'AbSoftwareVersion', 'FbSoftwareVersion', 'UnitGovId', 'IsNIProvisioning'
    ];

    booleanColumns = ['HasResidentMechanic', 'IsMAXCompatible', 'Repeater', 'SafetybeltDetection', 'Wheeltransfer']
    dateColumns = ['_ChangedAtUtc', '_CreatedAtUtc', 'ProvisioningStatusChangeDate']
    numberColumns = ['_ChairliftId', '_ChangedByMsgType', 'NumberCurves', 'BackToForehead', 'NumberOfreductions', 'ParkingAngleEnd', 'ParkingAngleStart', 'FootrestHeight', 'Seatheight',
        'OverrunUpstairs', 'OverrunDownstairs', 'AttendentControl', 'NumberParkpositions', 'NumberLevels', 'RailLength', 'BackToKneeClient', 'BackToHeadClient']

    notEditableColumns = ['_ChairliftId', 'DeviceId', 'TechnicianId', 'UnitId', 'ControllerType', '_CreatedAtUtc',
        '_ChangedAtUtc', '_ChangedByUser', 'TechnicianId', 'MaintenanceFrequencyPerYear', '_ChangedByMsgType',
        'HasResidentMechanic', 'RegionNumber', 'BuildingState', 'ProvisioningStatus', 'Timezone', 'ProvisioningStatusChangeDate', 'IsMAXCompatible', 'AssetType']

    @ViewChild(MatTable, { static: true }) table!: MatTable<any>;

    public dataSource = new MatTableDataSource<Chairlift>();
    selected: Chairlift | undefined;
    expanded: boolean[] = [];
    expandedElements = new Set();
    clonedList: { [s: string]: Chairlift; } = {};

    filter: string = '';

    subscriptionLanguage: any;
    subscriptionTenant: any;

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [COMMA, TAB, ENTER];
    searchItems: SearchItem[] = [];

    isPrimarychairlift = '-1'

    constructor(private activatedRoute: ActivatedRoute
        , private router: Router
        , private http: HttpClient
        , private saveService: SaveService
        , private confimDialog: DialogService
        , private defaults: DefaultValuesService
        , @Inject('BASE_URL') private baseUrl: string

    ) {
    }

    ngOnChanges() {
        this.dataSource.data = this.list
        this.clonedList = {}
        this.saveService.showSaveButton(false)
        this.expandedElements.clear();
        this.saveService.addListener(this)
    }

    ngOnInit() {
        this.getHeader();
        this.saveService.addListener(this);
        this.dataSource.filterPredicate = (data: Chairlift, filters: string) => {
            const matchFilter: any[] = [];
            const filterArray = filters.split('+').filter(x => x.indexOf('notextfilters') < 0)
            if (filterArray.length > 0) {
                filterArray.forEach((filter) => {
                    const fields: string[] = []
                    fields.push(data.UnitId ? data.UnitId : '')
                    fields.push(data.BranchNumber ? data.BranchNumber : '')
                    fields.push(data.ControllerType ? data.ControllerType : '')
                    fields.push(data.BuildingAddr1 ? data.BuildingAddr1 : '')
                    fields.push(data.BuildingCity ? data.BuildingCity : '')
                    fields.push(data.ChairliftNickname ? data.ChairliftNickname : '')
                    return matchFilter.push(fields.some(item => item.includes(filter)));
                });
            }
            else {
                let result = true;
                matchFilter.push(result);
            }
            return matchFilter.some(Boolean);
        };
    }

    getHeader() {
        if (!this.defaults.isReadonlyUser)
            this.columnsToDisplay = ['arrow', 'index', 'UnitId', 'BranchNumber', 'ControllerType', 'BuildingAddr1', 'BuildingCity', 'ChairliftNickname', '_ChangedAtUtc', 'actions'];
        else
            this.columnsToDisplay = ['arrow', 'index', 'UnitId', 'BranchNumber', 'ControllerType', 'BuildingAddr1', 'BuildingCity', 'ChairliftNickname', '_ChangedAtUtc'];
        this.subcolumnsToDisplay.sort((x, y) => (x > y ? 1 : -1));
    }


    getInput(data: Chairlift, property: string): any {
        return data[property];
    }

    getDataType(property: string) {
        let result = ''
        if (this.booleanColumns.indexOf(property) > -1) result = 'boolean'
        else if (this.dateColumns.indexOf(property) > -1) result = 'date'
        else if (this.numberColumns.indexOf(property) > -1) result = 'number'
        else result = 'string'
        return result
    }

    changeElement(object: any, type: string, value: boolean) {
        object[type] = value;
        this.table.renderRows();
    }

    changeSubsLevel(value: any, element: ChairliftDetails) {
        element.MAXSubscriptionlevel = value.value
        this.table.renderRows();
    }

    pushPopElement(element: Chairlift) {
        if (this.expandedElements.has(element)) {
            this.expandedElements.delete(element);
        }
        else {
            this.expandedElements.add(element);
            this.onExpand(element)
        }
    }

    checkExpanded(element: Chairlift) {
        return this.expandedElements.has(element);
    }

    onExpand(element: Chairlift) {
        Utils.httpGetDetailedChairlift(
            this.http
            , this.baseUrl
            , this.defaults.stage
            , element
            , this
            , function (tthis: any, list: ChairliftDetails[]) {
                element.Details = list;
                tthis.expandedElements.add(element);
            }
        );
    }

    onRowEditInit(chairlift: Chairlift) {
        this.saveService.addListener(this);
        this.clonedList[this.getDictionaryHash(chairlift)] = { ...chairlift };
        if (Object.keys(this.clonedList).length !== 0) this.onShowSavebutton()
        this.onExpand(chairlift)
    }

    onRowEditCancel(chairlift: Chairlift) {
        this.dataSource.data[this.dataSource.data.findIndex(x => x.UnitId === chairlift.UnitId)] = this.clonedList[this.getDictionaryHash(chairlift)];
        delete this.clonedList[this.getDictionaryHash(chairlift)];
        if (Object.keys(this.clonedList).length === 0) this.saveService.showSaveButton(false)
        this.table.renderRows();
    }

    isEditingRow(chairlift: Chairlift, property: string) {
        return this.clonedList[this.getDictionaryHash(chairlift)] &&
            (this.notEditableColumns.indexOf(property) < 0)
    }

    onShowSavebutton() {
        this.saveService.showSaveButton();
    }

    onSave() {
        let updatedList: any[] = []
        for (let i in this.clonedList) {
            let index = this.dataSource.data.findIndex(x => x.UnitId === this.clonedList[i].UnitId)
            var item = { ...this.dataSource.data[index].Details[0], ...this.dataSource.data[index] }
            updatedList.push(item)
        }
        Utils.httpUpdateChairlifts(
            this.http
            , this.baseUrl
            , this.defaults.stage
            , updatedList
            , this
            , function (tthis: any, list: Chairlift[]) {
                tthis.clonedList = {}
                tthis.saveService.showSaveButton(false)
                tthis.expandedElements.clear();
                tthis.table.renderRows();
            }
            , function (tthis: ChairliftListComponent, message: string) {
                tthis.saveService.showSaveButton(true)
                tthis.saveService.addListener(tthis)
                tthis.confimDialog.showErrorDialog(message);
            }
        );
    }

    applyFilter(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            this.searchItems.push({ name: value.replace('+', '').trim() });
        }
        // Reset the input value
        if (input) {
            input.value = '';
        }
        this.updatefilter()
    }

    remove(item: SearchItem): void {
        const index = this.searchItems.indexOf(item);
        if (index >= 0) {
            this.searchItems.splice(index, 1);
        }
        this.updatefilter()
    }
    updatefilter() {
        let filterString: string
        if (this.searchItems.length === 0) {
            this.dataSource.filter = 'notextfilters'
        }
        else {
            filterString = this.searchItems.map(e => { return e.name }).join('+') + '+checkboxes'
            this.dataSource.filter = filterString
        }
    }

    getDictionaryHash(chairlift: Chairlift) {
        return chairlift.UnitId
    }

}
export interface SearchItem {
    name: string;
}