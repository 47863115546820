import { Component, Inject, Input, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { MatChipInputEvent } from "@angular/material/chips";
import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';
import { SaveService } from '../../../../services/save.service';
import { Utils } from '../../../../utils/utils';
import { DefaultValuesService } from '../../../../services/defaultvalues.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DialogService } from "../../../../services/dialog.service";

@Component({
    selector: "escalator-list",
    templateUrl: './escalator-list.component.html',
    styleUrls: ['./escalator-list.component.less', '../../../../style/mat-table.component.less'],

    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*', })),
            transition('expanded => collapsed', [animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
        ]),
    ]
})

export class EscalatorListComponent implements OnInit {
    @Input('list') list: Escalator[] = [];
    @Input('showLocalFilter') showLocalFilter: boolean = false;

    columnsToDisplay = ['arrow', 'index', 'UnitID', 'BranchNumber', 'ControllerType', 'EscalatorControllerType', 'ProvisioningStatus', '_ChangedAtUtc', 'actions'];

    subcolumnsToDisplay = ['DeviceId', 'ElevatorType', 'TechnicianID', 'Supervisor8id', 'SupervisorName', 'MaintenanceFrequencyPerYear', 'HasResidentMechanic',
        'CountryCode', 'RegionNumber', 'BuildingAddr1', 'BuildingAddr2', 'BuildingType',
        'BuildingCity', 'BuildingState', 'ZipCode', 'BuildingName', 'Nickname', 'Longitude', 'Latitude',
        'Timezone', 'ProvisioningStatusChangeDate', 'ManufacturingId', 'StartOfOperation', 'WaterInfluence', 'SunInfluence', 'VandalismInfluence', 'Manufacturer',
        'Series', 'LoadSpectrum', 'BalustradeType', 'Rise_Length', 'AngleInclination', 'Width', 'GEC_model', 'Speed',
        'ClimatClass', 'Embarquement', 'Disembarquement', 'Code', 'TrolleyUse', 'OperationMode', 'ElectricalIP',
        'PLCModel', 'DiagnosticCapabilities', 'ControllerLocation', 'Voltage', 'Frequency', 'Neutral', 'LightingVoltage',
        'StartMode', 'EnergySaving', 'VVVFBraking', 'VVVFModel', 'VVVFLocation', 'PeopleDetection', 'SpeedChangeDetection',
        'LigthBarrier', 'NominalPower', 'NumberMotors', 'MotorRPM', 'MotorIP', 'GearboxType', 'MainDriveChain',
        'MainDriveChainProtection', 'MainDriveChainType', 'AuxiliaryBrake', 'StepChainPitch', 'StepChainLoad',
        'StepChainType', 'AutomaticLubrication', 'StepChainRollerDiameter', 'SkirtBrushes', 'HandrailInletType',
        'StepType', 'StepSupplier', 'StepDemarcation', 'HandrailMaterial', 'HandrailSupplier', 'HandrailType',
        'HandrailWidth', 'HandrailGuide', 'HandrailDrive', 'HandrailChain', 'HandrailHeating', 'CombplateHeating',
        'StepbandHeating', 'BalustradeHeight', 'UnderHandrailLigthing', 'UnderStepLigthing', 'CombLigth',
        'RemoteControl', 'SkirtLigth', 'FieldSerialNumber', 'SoftwareVersionA', 'SoftwareVersionB', 'SoftwareVersionC', 'ControllerNumber',
        'GovernmentAssetId', 'IsMAXCompatible',
        'BankLocationReference', 'IsSeasonalUnit', 'SeasonalUnitFromMonth', 'SeasonalUnitToMonth', 'PreferredMaintenanceServiceWeekOfMonth', 'SourceSystemId',
        'ContractExternalReference', 'MaintenanceRoute', 'Office', 'UnitType', 'CommercialSector',
        '_ChangedByUser', 'ConfigItProjectID', 'ProjectName', 'ProjectType', 'ProjectBranchCode', 'ProjectVersion',
        'PM8ID', 'PMName', 'PMEmail', 'UEID', 'ProductModel', 'PONumber', 'POItemNo', 'ExpectedDeliveryDate', 'TP2', 'TP2Currency', 'UnitGovId', 'IsNIProvisioning',
        'ModelId', 'OrderNumber', 'SerialNumber', 'ManufactoringDate', 'MAXSoftwareVersion', 'TechnicianName', 'AcquiredCompany', 'AcquisitionDate', 'RegulatoryCode',
        '_CreatedAtUtc', '_ChangedByMsgType', 'InstalledControllerType', 'ErpControllerType', 'Client', 'CreatorSource', 'ChangedSource', 'OptOut', 'AcceptanceDate', 'CellularNetwork',
        'MgmtId', 'BU'
    ];

    booleanColumns = ['TrolleyUse', 'Neutral', 'VVVFBraking', 'LigthBarrier', 'MainDriveChainProtection', 'AutomaticLubrication', 'UnderStepLigthing',
        'CombLigth', 'RemoteControl', 'HasResidentMechanic', 'IsMAXCompatible', 'IsNIProvisioning']
    dateColumns = ['StartOfOperation', '_ChangedAtUtc', 'StartOfOperation', 'ManufactoringDate', 'AcquisitionDate', '_CreatedAtUtc', 'ProvisioningStatusChangeDate',
        'AcceptanceDate']
    numberColumns = ['Frequency', 'LightningVoltage', 'NumberMotors', 'MotorIP', 'Speed', '_ChangedByMsgType', 'MaintenanceFrequencyPerYear', 'Rise_Length']

    notEditableColumns = ['DeviceId', 'UnitID', '_ChangedAtUtc', 'ProvisioningStatusChangeDate', 'ProvisioningStatus',
        'Location', 'MAXSoftwareVersion', 'ManufactoringDate', '_CreatedAtUtc', '_ChangedByMsgType', '_ChangedByUser', 'WrappingType']

    @ViewChild(MatTable, { static: true }) table!: MatTable<any>;

    public dataSource = new MatTableDataSource<Escalator>();
    selected: Escalator | undefined;
    expanded: boolean[] = [];
    expandedElements = new Set();
    clonedList: { [s: string]: Escalator; } = {};

    filter: string = '';

    subscriptionTenant: any;

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [COMMA, TAB, ENTER];
    searchItems: SearchItem[] = [];

    isPrimaryEscalator = '-1'

    constructor(private activatedRoute: ActivatedRoute
        , private router: Router
        , private http: HttpClient
        , private saveService: SaveService
        , private defaults: DefaultValuesService
        , private confimDialog: DialogService
        , @Inject('BASE_URL') private baseUrl: string

    ) {
    }

    ngOnChanges() {
        this.dataSource.data = this.list
        this.clonedList = {}
        this.saveService.showSaveButton(false)
        this.expandedElements.clear();
        this.saveService.addListener(this)
    }

    ngOnInit() {
        this.getHeader();
        this.subscriptionTenant = this.defaults.tenantMessageChanges$.subscribe(() => {
            this.getHeader()
        });
        this.saveService.addListener(this);
        this.dataSource.filterPredicate = (data: Escalator, filters: string) => {
            const matchFilter: any[] = [];
            const filterArray = filters.split('+').filter(x => x.indexOf('notextfilters') < 0)
            if (filterArray.length > 0) {
                filterArray.forEach((filter) => {
                    let result = false;
                    const unitId = data.UnitID ? data.UnitID : ''
                    const branchNumber = data.BranchNumber ? data.BranchNumber : ''
                    const ct = data.ControllerType ? data.ControllerType : ''
                    const ect = data.EscalatorControllerType ? data.EscalatorControllerType : ''
                    result = unitId.toString().toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
                        branchNumber.toString().toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
                        ct.toString().toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
                        ect.toString().toLowerCase().indexOf(filter.toLowerCase()) > -1
                    return matchFilter.push(result);
                });
            }
            return matchFilter.some(Boolean);
        };
    }
    getHeader() {
        if (!this.defaults.isReadonlyUser)
            this.columnsToDisplay = ['arrow', 'index', 'UnitID', 'BranchNumber', 'ControllerType', 'EscalatorControllerType', 'ProvisioningStatus', '_ChangedAtUtc', 'actions'];
        else
            this.columnsToDisplay = ['arrow', 'index', 'UnitID', 'BranchNumber', 'ControllerType', 'EscalatorControllerType', 'ProvisioningStatus', '_ChangedAtUtc'];
        this.subcolumnsToDisplay.sort((x, y) => (x > y ? 1 : -1));
    }

    getInput(data: any, property: string): any {
        return data[property];
    }

    getDataType(property: string) {
        let result = ''
        if (this.booleanColumns.indexOf(property) > -1) result = 'boolean'
        else if (this.dateColumns.indexOf(property) > -1) result = 'date'
        else if (this.numberColumns.indexOf(property) > -1) result = 'number'
        else result = 'string'
        return result
    }

    changeElement(escalator: Escalator, type: string, value: boolean) {
        let index = this.dataSource.data.findIndex(x => x['UnitId'] === escalator['UnitId'])
        if (this.columnsToDisplay.find(x => x === type)) {
            this.dataSource.data[index][type] = value
        } else {
            this.dataSource.data[index].Details[0][type] = value
        }
        this.table.renderRows();
    }

    pushPopElement(element: Escalator) {
        if (this.expandedElements.has(element)) {
            this.expandedElements.delete(element);
        }
        else {
            this.expandedElements.add(element);
            this.onExpand(element)
        }
    }

    checkExpanded(element: Escalator) {
        return this.expandedElements.has(element);
    }

    onExpand(element: Escalator) {
        Utils.httpGetDetailedEscalator(
            this.http
            , this.baseUrl
            , this.defaults.stage
            , element
            , this
            , function (tthis: any, list: EscalatorDetails[]) {
                element.Details = list;
                tthis.expandedElements.add(element);
            }
        );
    }

    onRowEditInit(escalator: Escalator) {
        this.saveService.addListener(this);
        this.clonedList[this.getDictionaryHash(escalator)] = { ...escalator };
        if (Object.keys(this.clonedList).length !== 0) this.onShowSavebutton()
        this.onExpand(escalator)
    }

    onRowEditCancel(escalator: Escalator) {
        this.dataSource.data[this.dataSource.data.findIndex(x => x.UnitID === escalator.UnitID)] = this.clonedList[this.getDictionaryHash(escalator)];
        delete this.clonedList[this.getDictionaryHash(escalator)];
        if (Object.keys(this.clonedList).length === 0) this.saveService.showSaveButton(false)
        this.table.renderRows();
    }

    isEditingRow(escalator: Escalator, property: string) {
        return this.clonedList[this.getDictionaryHash(escalator)] &&
            (this.notEditableColumns.indexOf(property) < 0)
    }

    onShowSavebutton() {
        this.saveService.showSaveButton();
    }

    onSave() {
        const updatedList: any[] = []
        for (let i in this.clonedList) {
            const index = this.dataSource.data.findIndex(x => x.UnitID === this.clonedList[i].UnitID)
            const obj = Utils.OmitKeysSpreadOperator(['Details'], this.dataSource.data[index])
            updatedList.push(Utils.RemoveBlankFields({ ...this.dataSource.data[index].Details[0], ...obj }))
        }
        if (updatedList.length > 0)
            Utils.httpUpdateEscalators(
                this.http
                , this.baseUrl
                , this.defaults.stage
                , updatedList
                , this
                , function (tthis: EscalatorListComponent) {
                    tthis.clonedList = {}
                    tthis.saveService.showSaveButton(false)
                    tthis.expandedElements.clear();
                    tthis.table.renderRows();
                }, function (tthis: EscalatorListComponent) {
                    tthis.saveService.addListener(tthis)
                    tthis.saveService.showSaveButton(true)
                    tthis.confimDialog.showErrorDialog({ error: { message: "Incorrect Branch Number. Please, indicate a proper branch for selected country checking Branch Hierarchy section." } });
                }
            );
    }

    applyFilter(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            this.searchItems.push({ name: value.replace('+', '').trim() });
        }
        // Reset the input value
        if (input) {
            input.value = '';
        }
        this.updatefilter()
    }

    remove(item: SearchItem): void {
        const index = this.searchItems.indexOf(item);
        if (index >= 0) {
            this.searchItems.splice(index, 1);
        }
        this.updatefilter()
    }
    updatefilter() {
        let filterString: string
        if (this.searchItems.length === 0) {
            this.dataSource.filter = 'notextfilters'
        }
        else {
            filterString = this.searchItems.map(e => { return e.name }).join('+') + '+checkboxes'
            this.dataSource.filter = filterString
        }
    }

    getDictionaryHash(escalator: Escalator) {
        return escalator.UnitID + escalator.TenantID
    }

}
export interface SearchItem {
    name: string;
}