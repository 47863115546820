<div class="custom-mat-dialog-form">
    <div>
        <h3>Assign Levers</h3>
    </div>
    <div class="custom_small_panelbody_fix">

        <table mat-table
               [dataSource]="dataSource"
               multiTemplateDataRows
               matSort
               class="mat-elevation-z8 mat-table-custom">

            <!--</ng-container>-->
            <!-- <ng-container matColumnDef="arrow">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let lever"
                    (click)="pushPopElement(lever)">
                    <ng-container>
                        <span class="material-icons"
                              *ngIf="checkExpanded(lever); else show_expand_more">
                            expand_less
                        </span>
                    </ng-container>
                </td>
            </ng-container> -->

            <ng-container matColumnDef="selector">
                <th mat-header-cell *matHeaderCellDef>  </th>
                <td mat-cell *matCellDef="let selector">
                    <div class="defCheckbox">
                        <mat-checkbox (click)="$event.stopPropagation()"
                                      (change)="accountItemsSelected($event.checked,selector);">
                        </mat-checkbox>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="Lever">
                <th mat-header-cell *matHeaderCellDef> Lever Name </th>
                <td mat-cell *matCellDef="let lever">
                    <p [innerHTML]="lever.ExcellenceLeverName"></p>
                </td>
            </ng-container>

            <ng-container matColumnDef="Function">
                <th mat-header-cell *matHeaderCellDef> Function </th>
                <td mat-cell *matCellDef="let lever">
                    <p [innerHTML]="lever.Function"></p>
                </td>
            </ng-container>

            <ng-container matColumnDef="LineOfBusiness">
                <th mat-header-cell *matHeaderCellDef> Line Of business </th>
                <td mat-cell *matCellDef="let lever">
                    <p [innerHTML]="lever.LineOfBusiness"></p>
                </td>
            </ng-container>

            <!-- <!-- <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <!--<ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let lever" [attr.colspan]="columnsToDisplay.length">
                    <div [@detailExpand]="checkExpanded(lever) ? 'expanded' : 'collapsed'">
                        <table mat-table
                               [dataSource]="lever.subtable"
                               class="mat-subtable-custom">

                            <ng-container matColumnDef="Lever">
                                <th mat-header-cell *matHeaderCellDef> Lever </th>
                                <td mat-cell *matCellDef="let factor">
                                    <div class="defCheckbox">
                                        <mat-checkbox (click)="$event.stopPropagation();"
                                                      (change)="expandedItemSelected($event.checked,factor)"
                                                      [checked]="selection.isSelected(factor)">
                                        </mat-checkbox>
                                        <p [innerHTML]="factor.Lever"></p>
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="Description">
                                <th mat-header-cell *matHeaderCellDef> Description </th>
                                <td mat-cell *matCellDef="let factor">
                                    <div class="defCheckbox">
                                        <p [innerHTML]="factor.Description"></p>
                                    </div>
                                </td>
                            </ng-container>
                            <tr mat-header-row class="subtableheader" *matHeaderRowDef="subcolumnsToDisplay"></tr>
                            <tr mat-row *matRowDef="let row2; let i2 = index; columns: subcolumnsToDisplay"
                                class="subitem"></tr>
                        </table>
                    </div>
                </td>
            </ng-container>
 -->

            <tr mat-header-row class="tableheader" *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let lever; let i = dataIndex; columns: columnsToDisplay;"
                class="item">
            </tr>
            <!-- <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="row-subitem"></tr> -->

        </table>

    </div>

    <div class="panel-body">

        <button (click)="backButtonHandler($event)" mat-raised-button class="btn  button-color-fix_red ">
            <mat-icon class=" icon-color-fix ">arrow_back_ios</mat-icon>
            Back
        </button>
        <button mat-raised-button type="submit" (click)="addButtonHandler()" class="btn button-color-fix pull-right ">
            Save
            <mat-icon class="icon-color-fix">save</mat-icon>
        </button>

    </div>
</div>

<ng-template #show_expand_more>
    <span class="material-icons">
        expand_more
    </span>
</ng-template>
