import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { Data, Router } from '@angular/router';
import { DialogService } from '../../../services/dialog.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Utils } from '../../..//utils/utils';


@Component({
    selector: 'preregistermacaddress',
    templateUrl: './preregistermacaddress.component.html',
    styleUrls: ['../../../style/custom-mat-dialog.component.less']
})


export class PreregisterMacAddressComponent implements OnInit {

    body = {
        macaddress: '',
        dialupnumber: '',
        boardserialnumber: '',
        iccid: ''
    };

    form!: FormGroup
    submitted = false;

    constructor(private http: HttpClient
        , private confimDialog: DialogService
        , private router: Router
        , @Inject(MAT_DIALOG_DATA) public data: any
        , @Inject('BASE_URL') private baseUrl: string
        , public dialogRef: MatDialogRef<PreregisterMacAddressComponent>
        , private formBuilder: FormBuilder

    ) {

        this.confimDialog.tthis = this;
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            macaddress: ['', [Validators.required, this.macAddressValidator]],
            dialupnumber: [''],
            boardserialnumber: [''],
            iccid: ['']
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.form ? this.form.controls : ''; }

    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.form)
            if (this.form.invalid) {
                return;
            }

        Utils.httpPreRegisterBluebox(
            this.http
            , this.baseUrl
            , this.body
            , this
            , function (tthis: PreregisterMacAddressComponent, result: any) {
                tthis.onBack()
                tthis.dialogRef.close({ event: 'submit' });
                if (result.Success === true)
                    tthis.confimDialog.showSuccessDialog('MacAddress preregistered successfully');
                else
                    tthis.confimDialog.showErrorDialog(
                        {
                            'error':
                            {
                                'message': 'Error Preregistering Macaddress: ' + result.Message
                            }
                        }
                    )
            }
        );
    }

    onBack() { }

    onNoClick(): void {
        this.dialogRef.close();
    }

    macAddressValidator(control: FormControl) {
        const reMacAddress = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
        if (control.value) {
            const s = "" + control.value;
            const beginAddress = "00:00:00:00:00:00";
            const endAddress = "00:00:00:11:00:00";
            return reMacAddress.test(s) === true && s.toUpperCase().localeCompare(beginAddress) >= 0
                && s.toUpperCase().localeCompare(endAddress) <= 0 ? null : { invalid: false };
        }
        else {
            return null;
        }
    }
}


