import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from
    "@angular/forms"
import { ActivatedRoute } from '@angular/router';
import { Utils } from '../../../../../utils/utils';
import { ToastrService } from 'ngx-toastr';
import { AddGroupDialogue } from '../addgroupdialogue/addgroupdialogue.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SpinnerOverlayService } from '../../../../../services/spinner-overlay.service';
import { ComponentTool } from '../../../../../interfaces/componenttool';
import { DialogService } from '../../../../../services/dialog.service';
import { CustomDialogService } from '../../../../../services/customdialog.service';
import { DefaultValuesService } from '../../../../../services/defaultvalues.service';
import {AUTO_STYLE,animate,state,style,transition, trigger} from '@angular/animations';
import { ResultsDialogue } from './resultsdialogue.component';
import { HostBinding } from '@angular/core';

const DEFAULT_DURATION = 3000;




@Component({
    selector: "searchaddon",
    templateUrl: './searchaddon.component.html',
    styleUrls: ['./searchaddon.component.less'],
     animations: [

        trigger('collapse', [
            state('show', style({ opacity: 1, height: '*', display: 'block' })),
            state('hide', style({ opacity: 0, height: '0px', display: 'none' })),
            transition('show => hide', [animate( '300ms')]),
            transition('hide => show', [animate( '300ms')])
        ])
    ]
})

export class SearchAddon {
    collapsedFilters = true;

    selectedTenants: string[] = [];
    selectedTenantLabels: string[] = [];
    selectedRoles: string[] = [];
    selectedRoleLabels: string[] = [];
    selectedCdpGroups: string[] = [];
    selectedCdpGroupLabels: string[] = [];
    users: UserSummary[] = [];
    totalResultsCount: number = 0;

    queryFilter = ""

    subscriptionStage: any;
    componentToolEnum: typeof ComponentTool = ComponentTool;

    dialogResult: MatDialogRef<ResultsDialogue, any> | undefined


    constructor(
        private http: HttpClient
        , private dialogService: DialogService
        , @Inject('BASE_URL') private baseUrl: string
        , private dialogForm: MatDialog
        , private customDialog: CustomDialogService
        , public defaults: DefaultValuesService
        , private spinnerOverlayService: SpinnerOverlayService,

    ) {

    }

    ngOnInit() {
        this.subscriptionStage = this.defaults.stageMessageChanges$.subscribe(() => { this.loadInformation() });
        this.loadInformation();

    }

    private loadInformation() {
        if (this.defaults.passedTenants.length > 0 && this.defaults.componentTool === this.componentToolEnum.Rulebook && !this.defaults.isDataAnalytStage()) {
            this.selectedTenants = this.defaults.passedTenants;
            this.selectedRoles = this.defaults.passedRoles;
            this.selectedCdpGroups = this.defaults.passedCdpGroups;
            this.onNgSelectChange();

        }

        else if (this.defaults.umpFilters.tenants.length === 0 && this.defaults.componentTool === this.componentToolEnum.Rulebook && !this.defaults.isDataAnalytStage()) {
            Utils.httpGetInitialFilters(
                this.http
                , this.baseUrl
                , this
                , function (tthis: SearchAddon, data: UserFilter) {
                    tthis.users = [];
                    tthis.defaults.umpFilters = data
                    tthis.selectedTenants = [tthis.defaults.umpFilters.tenants[0].value]
                    tthis.selectedRoles = [tthis.defaults.umpFilters.personas[0].value]
                    tthis.selectedCdpGroups = [tthis.defaults.umpFilters.cdpList[0].value]
                    tthis.onNgSelectChange()
                    tthis.selectAllForDropdownItems(tthis.defaults.umpFilters.tenants);

                }
            )
        }
        else if (this.defaults.componentTool === this.componentToolEnum.Rulebook && !this.defaults.isDataAnalytStage()) {
            this.selectedTenants = [this.defaults.umpFilters.tenants[0].value]
            this.selectedRoles = [this.defaults.umpFilters.personas[0].value]
            this.selectedCdpGroups = [this.defaults.umpFilters.cdpList[0].value]
            this.onNgSelectChange()
        }
    }
    selectAllForDropdownItems(items) {

        let allSelect = items => {
            items.forEach(element => {
                element['selectedAllGroup'] = 'selectedAllGroup';
            });
        };

        allSelect(items);
    }
    onNgSelectChange() {
        this.selectedTenantLabels = this.defaults.umpFilters.tenants.filter(x => this.selectedTenants.includes(x.value)).map(x => " " + x.text)
        this.selectedRoleLabels = this.defaults.umpFilters.personas.filter(x => this.selectedRoles.includes(x.value)).map(x => " " + x.text)
        this.selectedCdpGroupLabels = this.defaults.umpFilters.cdpList.filter(x => this.selectedCdpGroups.includes(x.value)).map(x => " " + x.text)
    }
    loadUsersByFilter() {

        if (this.queryFilter === '')
            return;

        Utils.httpGetUsersByFilter(
            this.http
            , this.baseUrl
            , {
                "UserPrincipalName": "",
                "Page": 0,
                "PageSize": 25,
                "GivenName": this.queryFilter,
                "CdpGroup": this.selectedCdpGroups,
                "NodeId": this.selectedRoles,
                "Tenant": this.selectedTenants,
                "MailO365Account": null
            }, this
            , function (tthis: SearchAddon, data: UserList) {
                tthis.users = data.users;
                tthis.totalResultsCount = data.totalResultsCount;
                tthis.opnedialoguebox();
            }, this.defaults.isDataAnalytStage())
            


    }

    toggle() {
        this.collapsedFilters = !this.collapsedFilters;
    }

    expand() {
        this.collapsedFilters = false;
    }

    collapse() {
        this.collapsedFilters = true;
    }
    opnedialoguebox() {
        this.dialogResult = this.dialogForm.open(ResultsDialogue, {
            width: '750px',
            data: { users: this.users, totalResultsCount: this.totalResultsCount }
        });
       
    }
}