import { Component, Inject, OnInit, EventEmitter, Output, ViewEncapsulation } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatChipInputEvent } from "@angular/material/chips";
import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';
import { SaveService } from '../../../services/save.service';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
    selector: "service-ticket-filter",
    templateUrl: './service-ticket-filter.component.html',
    styleUrls: ['../../../style/form.component.less', 'service-ticket-filter.component.less', '../../../style/expand-collapse-panel.component.less'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*', })),
            transition('expanded => collapsed', [animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
        ]),
    ],
    encapsulation: ViewEncapsulation.None
})

export class ServiceTicketFilterComponent implements OnInit {

    form!: FormGroup;
    @Output() outputFilter = new EventEmitter<ServiceTicketFilter>();

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [COMMA, TAB, ENTER];

    unitIds: string[] = [];
    technicianNames: string[] = [];
    serviceRecords: string[] = [];

    constructor(
        private http: HttpClient
        , private fb: FormBuilder
        , @Inject('BASE_URL') private baseUrl: string
        , private saveService: SaveService
    ) {
    }

    ngOnInit() {
        this.saveService.addListener(this);
        this.createForm();
    }

    createForm() {
        this.form = this.fb.group({
        });
    }

    changeTypeButton() {
        return this.unitIds.length < 1 && this.technicianNames.length < 1
            && this.serviceRecords.length < 1 
    }

    applyFilter(event: MatChipInputEvent, list: string): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            switch (list) {
                case 'techniciannames':
                    this.technicianNames.push(value.replace('+', '').trim()); break;
                case 'unitids':
                    this.unitIds.push(value.replace('+', '').trim()); break;
                case 'servicerecords':
                    this.serviceRecords.push(value.replace('+', '').trim()); break;
            }
        }
        // Reset the input value
        if (input) {
            input.value = '';
        }
    }

    remove(item: string, list: string): void {
        let index = -1
        switch (list) {
            case 'techniciannames':
                index = this.technicianNames.indexOf(item);
                if (index >= 0)
                    this.technicianNames.splice(index, 1);
                break;
            case 'unitids':
                index = this.unitIds.indexOf(item);
                if (index >= 0)
                    this.unitIds.splice(index, 1);
                break;
            case 'servicerecords':
                index = this.serviceRecords.indexOf(item);
                if (index >= 0)
                    this.serviceRecords.splice(index, 1);
                break;         
        }
    }

    onFilter() {
        let filter: ServiceTicketFilter = {
            'UnitIds': this.unitIds,
            'TechnicianNames': this.technicianNames,
            'ServiceRecords': this.serviceRecords
        }
        this.outputFilter.emit(filter);
    }
}