<div class="custom-mat-dialog-form">
    <div>
        <h3>Create new Function Key</h3>
    </div>
    <div>
        <form [formGroup]="form!" (ngSubmit)="onSubmit()">
            <div class="mt-2">
                <label>Function Key *</label>
                <div class="input">
                    <input type="text" class="form-control" formControlName="functionConfig" [(ngModel)]="body.FunctionKey" />
                    <div *ngIf="submitted && f['functionConfig'].errors" class="invalid-feedback">
                        <div class="error-div">Key must be populated</div>
                    </div>
                </div>
            </div>
            <div class="mt-2 right">
                <button type="button"
                        class="btn btn-danger"
                        (click)="onNoClick()">
                    Close
                </button>
                <button type="button"
                        class="btn btn-primary"
                        (click)="onSubmit()">
                    Send
                </button>
            </div>
        </form>
    </div>
</div>