import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { DefaultValuesService } from '../../../../services/defaultvalues.service';
import { Utils } from "../../../../utils/utils";
import { MatTableDataSource } from "@angular/material/table";
import { DialogService } from "../../../../services/dialog.service";
import { CustomDialogService } from "../../../../services/customdialog.service";

@Component({
    selector: "technicaluser",
    templateUrl: 'technicaluser.component.html',
})

export class TechnicalUserSetupComponent implements OnInit, OnDestroy {

    subscriptionStage: any;
    subscriptionTenant: any;
    columnsToDisplay = ['user', 'created', 'actions'];

    public dataSource = new MatTableDataSource<TechnicalUser>();
    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string
        , private defaults: DefaultValuesService
        , private confimDialog: DialogService
        , private customDialog: CustomDialogService
    ) {

    }

    ngOnInit(): void {
        this.subscriptionStage = this.defaults.stageMessageChanges$.subscribe(() => {
            this.getTechnicalUsers();
        });
        this.subscriptionTenant = this.defaults.tenantMessageChanges$.subscribe(() => {
            this.getTechnicalUsers();
        });
        this.getTechnicalUsers()
    }


    ngOnDestroy() {
        this.subscriptionStage.unsubscribe();
        this.subscriptionTenant.unsubscribe();
    }

    getTechnicalUsers() {
        Utils.httpGetTechnicalUsersSetup(
            this.http
            , this.baseUrl
            , this.defaults.stage
            , this
            , function (tthis: any, result: TechnicalUser[]) {
                tthis.dataSource.data = result.filter(x => x.CouldBeCreated || x.Created)
            }
        )
    }

    resetPassword(email: string) {
        this.customDialog.openConfirm({
            title: "Reset Password",
            message: `The user '${email}' will be assigned a new password. To display the password, click 'Yes'.`,
            caller: this
        });
        this.customDialog.confirmed().subscribe(res => {
            if (res.confirmed) {
                let url = this.baseUrl + Utils.getBackendSetup() + 'technicaluser/resetPassword/' + email;
                this.http.put<any>(url, {}).subscribe(result => {
                    this.confimDialog.showSuccessDialog("New password is " + result + " Please, save password before closing that popup.", "Password has been reset successfully");
                }, error => {
                    Utils.writeLog(this.http, this.baseUrl, error);
                });
            }
        })
    }

    createTechnicalUser(email: string) {
        this.customDialog.openConfirm({
            title: "Create User",
            message: `The user '${email}' will be created. To create user and display the password, click 'Yes'.`,
            caller: this
        });
        this.customDialog.confirmed().subscribe(res => {
            if (res.confirmed) {
                let url = this.baseUrl + Utils.getBackendSetup() + 'technicaluser/create/' + email;
                this.http.get<any>(url).subscribe(result => {
                    this.dataSource.data[this.dataSource.data.findIndex(x => x.Email === email)].Created = true;
                    this.confimDialog.showSuccessDialog("User has been created successfully. Password is " + result + " Please, save password before closing that popup.", "Password has been reset successfully");
                }, error => {
                    Utils.writeLog(this.http, this.baseUrl, error);
                });
            }
        })
    }
}
