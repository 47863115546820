import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SaveService } from "../../../services/save.service";
import { DefaultValuesService } from "../../../services/defaultvalues.service";
import { CustomDialogService } from "../../../services/customdialog.service";
import { Utils } from "../../../utils/utils";

@Component({
    selector: "provisioningtemplate-list",
    templateUrl: './provisioningratemplate-list.component.html',
    styleUrls: ['./provisioningratemplate-list.component.less'],

    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' })),
            transition('expanded => collapsed', [animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
        ]),
    ]
})

export class ProvisioningTemplateListComponent implements OnInit {
    @Input('list') list: any[] = [];
    @Input('isEditableMethod') isEditable: any
    @Output() onReload = new EventEmitter<any>();

    columnsToDisplay = ['CommandId', 'CommandName', 'Category', 'Description', 'FirstActivationUtc', 'LastChangeUtc', 'actions'];
    dateColumns = ['FirstActivationUtc', 'LastChangeUtc']


    @ViewChild(MatTable, { static: true }) table!: MatTable<any>;

    public dataSource = new MatTableDataSource<any>();

    subscriptionTenant: any;

    constructor(private activatedRoute: ActivatedRoute
        , private router: Router
        , private http: HttpClient
        , private saveService: SaveService
        , public defaults: DefaultValuesService
        , private customDialog: CustomDialogService
        , @Inject('BASE_URL') private baseUrl: string
        ,
        private changeDetectorRefs: ChangeDetectorRef

    ) {
    }

    ngOnChanges() {
        this.dataSource.data = [];
        this.dataSource.data = this.list
    }

    ngOnInit() {
        this.getHeader();
        this.subscriptionTenant = this.defaults.tenantMessageChanges$.subscribe(() => {
            this.getHeader()
        });
        this.saveService.addListener(this);
    }

    ngOnDestroy() {
        this.subscriptionTenant.unsubscribe();
    }

    getHeader() {
        if (!this.defaults.isReadonlyUser)
            this.columnsToDisplay = ['CommandId', 'CommandName', 'Category', 'Description', 'FirstActivationUtc', 'LastChangeUtc', 'actions'];
        else
            this.columnsToDisplay = ['CommandId', 'CommandName', 'Category', 'Description', 'FirstActivationUtc', 'LastChangeUtc'];
    }

    getInput(data: CommandConfigControllerType, property: string): any {
        return data[property];
    }

    getDataType(property: string) {
        let result = ''
        if (this.dateColumns.indexOf(property) > -1) result = 'date'
        else result = 'string'
        return result
    }

    changeElement(object: any, type: string, value: boolean) {
        this.http
            .post<any>(
                this.baseUrl + Utils.getRemoteActionAPI() + 'commandconfigtemplate' + '/' + object.CommandId + '/' + value, {}
            ).subscribe((res) => {
                this.onReload.emit(res);
            }, (error: {
                error: { message: any }
            }) => {
                this.customDialog.openDisplayInfoDialog({ title: 'Error', message: error.error.message });
            });
    }
}