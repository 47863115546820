import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { DialogService } from '../../services/dialog.service';
import { DefaultValuesService } from '../../services/defaultvalues.service';
import { SaveService } from '../../services/save.service';
import { Utils } from '../../utils/utils';
import { Router } from '@angular/router';

@Component({
    selector: 'period',
    template: `Period:<br />
                <ng-select (change)="onSelect($event)" style="width:180px; border: 1px white solid" class="top-main-select"
                    [items]="filteredPeriod"
                   bindLabel="label"
                   bindValue="id"
                   [closeOnSelect]="true"
                   [searchable]="true"
                   [(ngModel)]="defaults.period">
                </ng-select>`
})

export class PeriodComponent implements OnInit {
    list: any[] = [{ 'id': 1, 'label': 'Period 1 (10-{{year}})' },
    { 'id': 2, 'label': 'Period 2 (11-{{year}})' },
    { 'id': 3, 'label': 'Period 3 (12-{{year}})' },
    { 'id': 4, 'label': 'Period 4 (01-{{year}})' },
    { 'id': 5, 'label': 'Period 5 (02-{{year}})' },
    { 'id': 6, 'label': 'Period 6 (03-{{year}})' },
    { 'id': 7, 'label': 'Period 7 (04-{{year}})' },
    { 'id': 8, 'label': 'Period 8 (05-{{year}})' },
    { 'id': 9, 'label': 'Period 9 (06-{{year}})' },
    { 'id': 10, 'label': 'Period 10 (07-{{year}})' },
    { 'id': 11, 'label': 'Period 11 (08-{{year}})' },
    { 'id': 12, 'label': 'Period 12 (09-{{year}})' }
    ];

    value: number = 0;

    public filteredPeriod: any[] = [];

    protected _onDestroy = new Subject<void>();

    constructor(
        private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , public defaults: DefaultValuesService
        , private dialog: DialogService
        , public saveService: SaveService
        , private router: Router
    ) {
    }

    ngOnInit() {
        this.dialog.tthis = this;
        this.defaults.languageMessageChanges$.subscribe(() => {
            this.reloadFilteredPeriod()
        })
        this.defaults.fiscalYearMessageChanges$.subscribe(() => {
            this.reloadFilteredPeriod()
        });

    }


    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }
    changeFiscalYear() {
        this.sendPost();
    }

    onNo() {
        this.saveService.fireCancelAll();
        this.changeFiscalYear();
    }

    onSelect(l: any) {
        if (!l)
            return;
        this.value = l.id;
        if (this.saveService.isSaveButtonShown())
            this.dialog.showConfirmDialog("Do you want to save the changes? If you select No, all changes will be lost!", "Save", this);
        else
            this.onNo();

    }

    reloadFilteredPeriod() {
        this.filteredPeriod = this.list.map(x => {
            return {
                'id': x.id,
                'label': x.label.replaceAll('{{year}}',
                    x.id === 1 || x.id === 2 || x.id === 3 ?
                        (this.defaults.fiscalYear ?? 0) - 1 : this.defaults.fiscalYear)
            }
        });
    }

    onYes() {
        this.saveService.fireSave();
        this.changeFiscalYear();
    }

    sendPost() {
        var tthis = this;
        this.defaults.period = this.value;
        this.http
            .post<number>(this.baseUrl + Utils.getPeriodAPI(), this.defaults.period)
            .subscribe(() => {
                this.defaults.notifyPeriodChange();
            }, error => Utils.writeLog(tthis.http, tthis.baseUrl, error)); //error => console.log(error));
    }
}