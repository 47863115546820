<div class="custom-mat-dialog-form">
    <div>
        <h3>Create new Asset (Elevator / Escalator)</h3>
    </div>
    <div>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="mt-2">
                <label>Select Asset Type *</label>
                <div class="input">
                    <mat-select class="form-control" title="Select Asset Type" [(ngModel)]="body.assettype" formControlName="assettype">
                        <mat-option value="0" title="Elevator">Elevator</mat-option>
                        <mat-option value="1" title="Escalator">Escalator</mat-option>
                    </mat-select>
                </div>
            </div>
            <div class="mt-2">
                <label>Unit Id *</label>
                <div class="input">
                    <input [(ngModel)]="body.unitid" class="form-control" formControlName="unitid"
                           required [ngClass]="{ 'is-invalid': submitted && f['unitid'].errors }" />
                    <div *ngIf="submitted && f['unitid'].errors" class="invalid-feedback">
                        <div class="error-div">Unit Id required</div>
                    </div>
                </div>
            </div>
            <div class="mt-2">
                <label>Branch Number *</label>
                <div class="input">
                    <input [(ngModel)]="body.branchnumber" class="form-control" formControlName="branchnumber"
                           required [ngClass]="{ 'is-invalid': submitted && f['branchnumber'].errors }" />
                    <div *ngIf="submitted && f['branchnumber'].errors" class="invalid-feedback">
                        <div class="error-div">Branch Number required</div>
                    </div>
                </div>
            </div>
            <div class="mt-2">
                <label>Region Number *</label>
                <div class="input">
                    <input [(ngModel)]="body.regionnumber" class="form-control" formControlName="regionnumber"
                           required [ngClass]="{ 'is-invalid': submitted && f['regionnumber'].errors }" />
                    <div *ngIf="submitted && f['regionnumber'].errors" class="invalid-feedback">
                        <div class="error-div">Region Number required</div>
                    </div>
                </div>
            </div>
            <div class="mt-2">
                <label>Building Address 1 *</label>
                <div class="input">
                    <input [(ngModel)]="body.buildingaddr1" class="form-control" formControlName="buildingaddr1"
                           required [ngClass]="{ 'is-invalid': submitted && f['buildingaddr1'].errors }" />
                    <div *ngIf="submitted && f['buildingaddr1'].errors" class="invalid-feedback">
                        <div class="error-div">Building Address 1 required</div>
                    </div>
                </div>
            </div>
            <div class="mt-2">
                <label>Zip Code *</label>
                <div class="input">
                    <input [(ngModel)]="body.zipcode" class="form-control" formControlName="zipcode"
                           required [ngClass]="{ 'is-invalid': submitted && f['zipcode'].errors }" />
                    <div *ngIf="submitted && f['zipcode'].errors" class="invalid-feedback">
                        <div class="error-div">Zip code required</div>
                    </div>
                </div>
            </div>

            <div class="mt-2">
                <label>Building City *</label>
                <div class="input">
                    <input [(ngModel)]="body.buildingcity" class="form-control" formControlName="buildingcity"
                           required [ngClass]="{ 'is-invalid': submitted && f['buildingcity'].errors }" />
                    <div *ngIf="submitted && f['buildingcity'].errors" class="invalid-feedback">
                        <div class="error-div">Building city required</div>
                    </div>
                </div>
            </div>

            <div class="mt-2 right">
                <button type="button"
                        class="btn btn-danger"
                        (click)="onNoClick()">
                    Close
                </button>
                <button type="button"
                        class="btn btn-primary"
                        (click)="onSubmit()">
                    Send
                </button>
            </div>
        </form>
    </div>
</div>
