<!--The Heading sections (Heading + Serach )-->
<div class="resetpwd">
    <mat-toolbar class="header heading-background" style="margin-bottom:18px">

        <h5>
            Reset Password
        </h5>

    </mat-toolbar>
    <div class="container-fluid">


        <div class="row">


            <div class="col-lg-1">
            </div>

            <div class="col-lg-10 form-group">
                <h5 style="width:100%; margin-bottom: 8px ">
                    {{data.useremail}}
                </h5>

                <form [formGroup]="tkeUserForm" class="internalMax" (ngSubmit)="onSubmit()">
                    <mat-form-field appearance="outline" class="mat-form-field-custom">
                        <mat-label>Password</mat-label>

                        <input matInput formControlName="password" required [type]="passHide ? 'password' : 'text'">
                        <button mat-icon-button matSuffix (click)="passHideFunc($event)" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="passHide">
                            <mat-icon>{{passHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                        <button mat-icon-button matSuffix (click)="genPass($event)">
                            <mat-icon>settings_suggest</mat-icon>
                        </button>

                    </mat-form-field>

                    <p *ngIf="
          tkeUserForm.get('password')?.invalid &&
            (tkeUserForm.get('password')?.dirty ||
              tkeUserForm.get('password')?.touched)">
                        <mat-error *ngIf="tkeUserForm.get('password')?.hasError('required')">This field is required</mat-error>
                        <mat-error *ngIf="tkeUserForm?.get('password')?.hasError('passwordStrength')">
                            {{tkeUserForm?.get('password') ? tkeUserForm['password']?.errors['passwordStrength'] : ''}}
                        </mat-error>
                        <mat-error *ngIf="tkeUserForm.get('password')?.hasError('passwordContained')">
                            Password cannot be contained in UserName
                        </mat-error>
                    </p>
                    <p *ngIf="!(
          tkeUserForm.get('password')?.invalid &&
            (tkeUserForm.get('password')?.dirty ||
              tkeUserForm.get('password')?.touched))"><br> </p>

                    <!--Confirm Password Region-->
                    <mat-form-field appearance="outline" class="mat-form-field-custom">
                        <mat-label>Confirm Password</mat-label>
                        <input matInput formControlName="confirmPassword" required [type]="confirmPassHide ? 'password' : 'text'">
                        <button mat-icon-button matSuffix (click)="handleConfirmPass($event)" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="confirmPassHide">
                            <mat-icon>{{confirmPassHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                    </mat-form-field>


                    <p *ngIf="
    tkeUserForm.get('confirmPassword')?.invalid &&
      (tkeUserForm.get('confirmPassword')?.dirty ||
        tkeUserForm.get('confirmPassword')?.touched)">
                        <mat-error *ngIf="tkeUserForm.get('confirmPassword')?.hasError('required')">This field is required</mat-error>
                        <mat-error *ngIf="tkeUserForm.get('confirmPassword')?.hasError('confirmPasswordValidator')">
                            Confirm Password is not same
                        </mat-error>
                    </p>
                    <p *ngIf="!(
          tkeUserForm.get('confirmPassword')?.invalid &&
            (tkeUserForm.get('confirmPassword')?.dirty ||
              tkeUserForm.get('confirmPassword')?.touched))"><br> </p>

                    <!--<mat-checkbox formControlName="active">-->
                    <mat-checkbox formControlName="ForceChangePasswordNextLogin">
                        Must change password next login
                    </mat-checkbox>


                    
                    <div class="panel-body">

                        <button (click)="backButtonHandler($event)" mat-raised-button class="btn  button-color-fix_red ">
                            <mat-icon class=" icon-color-fix ">arrow_back_ios</mat-icon>
                            Back
                        </button>

                        <button mat-raised-button type="submit" class="btn button-color-fix pull-right" [disabled]="!tkeUserForm.valid">Reset
                            <mat-icon class=" icon-color-fix ">arrow_forward_ios</mat-icon>
                        </button>

                    </div>

                </form>
            </div>
            <div class="col-lg-1">
            </div>
        </div>
    </div>

</div>