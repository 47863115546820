import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class DataTransferService {
    private consolidatedPFML = new Subject<PossibleFailureMLUndoHistory>();
    //  consolidatedPFML = new BehaviorSubject({} as PossibleFailureMLUndoHistory);
    consolidatedPFMLMessage = this.consolidatedPFML.asObservable();

    constructor() { }

    sendConsolidatedPFMLMessage(message: PossibleFailureMLUndoHistory) {
        this.consolidatedPFML.next(message);
    }

}