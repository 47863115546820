import { Component, Inject, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DefaultValuesService } from "../../../services/defaultvalues.service";
import { SignalRService } from "../../../services/signal-r";
import { ElevatorComponent } from "./elevator/elevator.component";
import { EscalatorComponent } from "./escalator/escalator.component";
import { OtherAssetComponent } from "./otherasset/otherasset.component";
import { CountryBackendEntity } from "../../../interfaces/countrybackendentity";
import { MatTabGroup } from "@angular/material/tabs";

@Component({
    selector: "assets",
    templateUrl: './assets.component.html',
    styleUrls: ['../../../style/container-tab-list.component.less']
})

export class AssetsComponent implements OnInit {
    title: string;

    currentType: CountryBackendEntity = CountryBackendEntity.Elevator;

    @ViewChild('elevatorComponent', { static: true }) elevatorComponent!: ElevatorComponent;
    @ViewChild('escalatorComponent', { static: true }) escalatorComponent!: EscalatorComponent;
    @ViewChild('otherAssetComponent', { static: true }) otherAssetComponent!: OtherAssetComponent;
    @ViewChild('tabGroup') tabGroup!: MatTabGroup;

    constructor(
        private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , public defaults: DefaultValuesService
        , public signalRService: SignalRService

    ) {
        this.title = "All Assets";
    }

    ngOnInit(): void { }
    ngAfterViewInit() {
        this.defaults.defaultComponentTab(this)
    }

    changeAssetType($event) {
        this.currentType = $event.index
    }

    onReload() {
        switch (this.currentType) {
            case CountryBackendEntity.Elevator:
                this.elevatorComponent.onReload();
                break;
            case CountryBackendEntity.Escalator:
                this.escalatorComponent.onReload();
                break;
            case CountryBackendEntity.OtherAsset:
                this.otherAssetComponent.onReload();
                break;
            default: break;
        }
    }
    downloadAssetFiltered() {
        switch (this.currentType) {
            case CountryBackendEntity.Elevator:
                this.elevatorComponent.downloadElevatorFiltered();
                break;
            case CountryBackendEntity.Escalator:
                this.escalatorComponent.downloadEscalatorFiltered();
                break;
            case CountryBackendEntity.OtherAsset:
                this.otherAssetComponent.downloadOtherAssetFiltered();
                break;
            default: break;
        }
    }

}

