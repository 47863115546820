import { Component, EventEmitter, Inject, Input, OnInit, Output, Self, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { Utils } from '../../../utils/utils';
import { HttpClient } from '@angular/common/http';
import { DefaultValuesService } from '../../../services/defaultvalues.service';
import { DialogService } from '../../../services/dialog.service';
import { DatePipe } from '@angular/common';
import { DestroyService } from '../../../services/destroyservice';
import { takeUntil } from 'rxjs';

@Component({
    selector: 'commandsmasterdata',
    templateUrl: './commandsmasterdata.component.html',
    styleUrls: ['../../../style/new-generic-styles.component.less',
        '../../../style/tables/table-add-butt-header-orange.less',
        '../jcobrules.component.less',
        './commandsmasterdata.component.less'],
        providers: [DestroyService]
})
export class CommandsMasterdata implements OnInit {
    public dataSource = new MatTableDataSource<any>();
    @ViewChild(MatTable) objTable!: MatTable<any>;
    CSRForm!: FormGroup;
    genType: number = 0;
    originalJcobCommandFiles: JcobCommandFilesItem[] = [];
    modifiedJcobCommandFiles: JcobCommandFilesItem[] = [];
    newJcobCommandFiles: JcobCommandFilesItem[] = [];
    uniqueCondition: string[] = [];
    isCallSucceed: number = 0;
    subscriptionStage: any;
    forbiddenchars = ['/', '\\', ':', '*', '?', '<', '>', '|', '"'];
    @Output() controllerSoftwareEvent = new EventEmitter<EMTControllerSoftwareReleaseItem[]>();


    @Input() originalSoftwareReleases: EMTControllerSoftwareReleaseItem[] = [];
    minDate: Date = new Date()


    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string,
        private _formBuilder: FormBuilder,
        private fb: FormBuilder,
        private toastrService: ToastrService,
        public defaults: DefaultValuesService,
        public datePipe: DatePipe,
        private confimDialog: DialogService,
        @Self() private readonly destroy$: DestroyService    ) {
        this.confimDialog.tthis = this;
    }
    @Input() availableFamilyCodes: FamilyCode[] = [];

    columnsToDisplay = ["JcobCommandFilesSchemaVersion", "ControllerSoftwareVersion", "file", "UseforConfigGen","date", "action"];
    //columnsToDisplay = ["commandFileVersion", "ManufacturingCenterProduct", "file", "UseforConfigGen","action"];

    ngOnInit(): void {
        this.subscriptionStage = this.defaults.stageMessageChanges$.pipe(takeUntil(this.destroy$)).subscribe(() => { this.getJCOBRuleFiles() });
        this.genType = 0;
        this.CSRForm = this._formBuilder.group({
            CSRRows: this._formBuilder.array([])
        });
        this.getJCOBRuleFiles();
    }
    resetItems() {
        this.genType = 0;
        this.CSRForm = this._formBuilder.group({
            CSRRows: this._formBuilder.array([])
        });
        this.uniqueCondition = [];
        this.originalJcobCommandFiles.splice(0, this.originalJcobCommandFiles.length);
        this.modifiedJcobCommandFiles.splice(0, this.modifiedJcobCommandFiles.length);
        this.newJcobCommandFiles.splice(0, this.newJcobCommandFiles.length);
        this.dataSource = new MatTableDataSource<any>();
    }
    getJCOBRuleFiles() {
        this.resetItems()
        Utils.httpGetJcobCommandFiles(
            this.http,
            this.baseUrl,
            this,
            function (tthis: CommandsMasterdata, result: any) {
                tthis.originalJcobCommandFiles = result;
                tthis.originalJcobCommandFiles.length > 0 ? tthis.mapDataToForm(tthis.originalJcobCommandFiles) : null;
                //    tthis.controllerSoftwareEvent.emit(tthis.originalJcobCommandFiles);
            }
        );
    }



    mapDataToForm(data) {
        this.CSRForm = this._formBuilder.group({
            CSRRows: this._formBuilder.array([])
        });
        //isEditable => Can we go to edit phase 
        this.CSRForm = this.fb.group({
            CSRRows: this.fb.array(data.map(val => this.fb.group({
                jCobCommandId: new FormControl(val.jCobCommandId),
                commandFileVersion: new FormControl(val.commandFileVersion),
                controllerSoftwareVersionID: new FormControl(val.controllerSoftwareVersionID),
                commandFile: new FormControl(val.commandFile),
                useForConfigGeneration: new FormControl(val.useForConfigGeneration),
                date: new FormControl(val.date != null ? new Date(val.date) : ""),
                isEditable: new FormControl(true),
                isNewRow: new FormControl(false),
                action: new FormControl('existingRecord'),
            })
            )) //end of fb array
        }); // end of form group cretation
        this.dataSource.data = (this.CSRForm.get('CSRRows') as FormArray).value;
    }

    initCSRForm(): FormGroup {
        return this.fb.group({
            commandFileVersion: new FormControl(""),
            date: new FormControl(),
            controllerSoftwareVersionID: new FormControl(-1),
            commandFile: new FormControl(null),
            useForConfigGeneration: new FormControl(false),


            action: new FormControl('newRecord'),
            isEditable: new FormControl(false),
            isNewRow: new FormControl(true),
        });
    }

    addNewRow() {
        const control = this.CSRForm.get('CSRRows') as FormArray;
        control.insert(0, this.initCSRForm());
        this.dataSource = new MatTableDataSource(control.controls);
        //    this.updateFileUploadControls();
    }



    getMfgCenterProductText(MfgItem: MFGCenterProductItem) {
        return `${MfgItem.productCode} - ${MfgItem.productName}`;
    }

    resetRow(i) {
        if (this.CSRForm.value.CSRRows[i]?.isNewRow)
            this.deleteRow(i);
        else {
            this.updateFormFeature(i);
            this.CSRForm.value.CSRRows[i].isEditable = true;
        }
    }

    deleteRow(i) {
        const control = this.CSRForm.get('CSRRows') as FormArray;
        control.removeAt(i);
        this.dataSource.data = control.value;
        this.refreshTable();
    }

    updateFormFeature(index) {
        var obj = this.CSRForm.value.CSRRows;
        var updated = this.originalJcobCommandFiles.find(s => s.jCobCommandId == this.CSRForm.value.CSRRows[index]?.jCobCommandId);
        obj[index].commandFileVersion = updated?.commandFileVersion;
        obj[index].controllerSoftwareVersionID = updated?.controllerSoftwareVersionID;
        obj[index].commandFile = updated?.commandFile;
        obj[index].useForConfigGeneration = updated?.useForConfigGeneration;
        obj[index].date = updated?.date;
    }

    editRow(index) {
        this.CSRForm.value.CSRRows[index].isEditable = false;
    }

    refreshTable() {
        this.objTable.renderRows();
        //    this.updateFileUploadControls();
    }

    onSave() {
        if (this.anyChange()) {

            let formData: FormData = new FormData();

            let releasesChanges = { 'modifiedReleases': this.modifiedJcobCommandFiles, 'newReleases': this.newJcobCommandFiles }
            formData.append('releasesChanges', JSON.stringify(releasesChanges));

            Utils.httpUpdateJcobCommandFiles(
                this.http
                , this.baseUrl
                , formData
                , this
                , function (tthis: CommandsMasterdata, result: any) {
                    if (result?.error && result.errorMssg) {
                        tthis.confimDialog.showErrorDialog({
                            'error':
                            {
                                'message': result.errorMssg
                            }
                        })
                    }
                    tthis.getJCOBRuleFiles();
                    tthis.funcNotify();
                }
            )
        }

    }

    anyChange() {
        this.mapFormToData();
        return (this.modifiedJcobCommandFiles?.length > 0 || this.newJcobCommandFiles?.length > 0) && !(this.uniqueCondition?.length > 0);
    }

    mapFormToData() {
        this.modifiedJcobCommandFiles = this.CSRForm.get('CSRRows')?.value.filter(row =>
            !row.isNewRow
            && !row.isEditable
            && row.controllerSoftwareVersionID !== -1
            && row.commandFileVersion !== "" && row.commandFileVersion.length <= 30
        );
        this.newJcobCommandFiles = this.CSRForm.get('CSRRows')?.value.filter(row =>
            row.isNewRow
            && row.controllerSoftwareVersionID !== -1
            && row.commandFileVersion !== "" && row.commandFileVersion.length <= 30
            && row.commandFile != null
            && row.date != null

        );
        this.uniqueCondition = this.findObjWithMultipleActive(this.CSRForm.get('CSRRows')?.value)

    }
    findObjWithMultipleActive(items): string[] {
        const itemCounts = items.reduce((acc, item) => {
            if (item.useForConfigGeneration) {
                if (!acc[this.getControllerSoftwareVersionbyId(item.controllerSoftwareVersionID)]) {
                    acc[this.getControllerSoftwareVersionbyId(item.controllerSoftwareVersionID)] = 0;
                }
                acc[this.getControllerSoftwareVersionbyId(item.controllerSoftwareVersionID)]++;
            }
            return acc;
        }, {} as { [key: string]: number });

        // Filter item names that have more than one active entry
        const result = Object.keys(itemCounts).filter(itemName => itemCounts[itemName] > 1);

        return result;
    }

    toggleOvl(index) {
        this.CSRForm.value.CSRRows[index].isOpenOverlay = !this.CSRForm.value.CSRRows[index].isOpenOverlay;
    }


    getFileUploadId(index) {
        return `fu_${index}`;
    }

    onFileChange(event: any, index) {
        const reader = new FileReader();
        const file = event.target.files[0];
        if (file) {
            reader.onload = (e: any) => {
                const xmlContent = e.target.result;
                this.assignToRuleFile(xmlContent, index);
            };
            reader.readAsText(file);
        }
    }

    assignToRuleFile(xmlContent: string, index) {

        this.CSRForm.value.CSRRows[index].commandFile = xmlContent;
    }

    funcNotify() {
        if (this.isCallSucceed === 1) {
            this.toastrService.success('Save request successfully sent!');
        }
        else if (this.isCallSucceed === -1) {
            this.toastrService.error('Save action could not be performed. Please, contact with administrator');
        }
    }

    checkInput(event) {
        if (this.forbiddenchars.some(x => x === event.key))
            event.preventDefault();
    }

 
    getControllerSoftwareVersionbyId(id): string {
        var result = this.originalSoftwareReleases.find(x => x.controllerSoftwareVersionID == id);
        return result ? result.controllerSoftwareVersion : '';
    }
    truncateFile(value: string, limit) {
        var res = value.length > limit ? value.substring(0, limit) : value;
        return res;
    }
    getRuleFile(i) {
        return this.CSRForm?.get('CSRRows')?.value[i]?.commandFile;
    }

    onCheckboxChange(event, index): void {
        //this.CSRForm?.get('CSRRows')?.value[i].useForConfigGeneration = event.checked;

        if (event.checked) {
            var sameSoftVerProdIds = this.CSRForm.value.CSRRows.filter(
                x =>  x.controllerSoftwareVersionID == this.CSRForm.value.CSRRows[index].controllerSoftwareVersionID)
            sameSoftVerProdIds.forEach(x => x.useForConfigGeneration = false)
        }

        this.CSRForm.value.CSRRows[index].useForConfigGeneration = event.checked;

    }
    getDateUTCChange(event, index) {
        let utcDate = Utils.dateUTCchange(event);
        this.CSRForm.value.CSRRows[index].date = utcDate;
    }
}
