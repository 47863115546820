import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DefaultValuesService } from '../../../../../services/defaultvalues.service';
import { Utils } from '../../../../../utils/utils';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'branchconfig',
    templateUrl: './branchconfig.component.html',
    styleUrls: ['./branchconfig.component.less',
        '../branchexcellenceadmin.component.less',
        '../../../../../style/new-generic-styles.component.less']
})
export class BranchConfigComponent implements OnInit {

    public editBranchConfigForm: FormGroup;
    subscriptionManagementId: any;
    subscriptions: any[] = [];

    data: FieldBranchDetails

    constructor(
        private fb: FormBuilder,
        public defaults: DefaultValuesService,
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string,
        private toastrService: ToastrService
    ) {
        this.data = {
            MgmtId: '',
            MgmtName: '',
            Currency: '',
            CurrencyShort: '',
            CurrencySymbol: '',
            DecimalDigits: ''
        }
        this.editBranchConfigForm = this.fb.group(
            {
                responsiblePerson: ['', Validators.required],
                archetype: ['', Validators.required],
            });
    }

    ngOnInit(): void {
        this.subscriptionManagementId = this.defaults.managementIdMessageChanges$.subscribe(() => {
            this.getBranchDetails()
        });
        if (this.data.MgmtId === '')
            this.getBranchDetails();
    }

    // unsubscribe to events when component is destroyed
    ngOnDestroy(): void {
        this.subscriptionManagementId.unsubscribe();
    }

    getBranchDetails() {
        Utils.httpGetfieldBranchDetails(
            this.http,
            this.baseUrl,
            this,
            function (tthis: BranchConfigComponent, result: any) {
                tthis.data = result;
                tthis.editBranchConfigForm.controls['responsiblePerson'].setValue(result.ResponsiblePerson)
                tthis.editBranchConfigForm.controls['archetype'].setValue(result.Archetype)
            },
            null
        )
    }

    onSave() {
        var formvalues = this.editBranchConfigForm.getRawValue();

        Utils.httpUpdateFieldBranchDetails(
            this.http
            , this.baseUrl
            , formvalues
            , this
            , function (tthis: BranchConfigComponent, result: boolean) {
                if (result)
                    tthis.toastrService.success('Branch details were updated successfully!');
                else
                    tthis.toastrService.error('Details could not be updated. Please, contact with administrator');
            },
            null
        )
    }
}
