<div class="custom-mat-dialog-form">
    <div>
        <h3>{{this.data ? 'Update' : 'Assign'}} Excellence Lever - KPI</h3>
    </div>
    <div>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-sm-4 form-group">
                    <br />
                    <mat-label> <span>Excellence Lever</span><span class="orange-asterisk"> *</span></mat-label>
                </div>
                <div class="col-sm-8 form-group">

                    <ng-select [items]="excellenceLevers"
                               [searchable]="true"
                               [clearable]="false"
                               bindLabel="Name"
                               bindValue="ExcellenceLeverId"
                               formControlName="excellenceLever"
                               required>
                    </ng-select>
                    <br>

                </div>
            </div>
            <div class="row">
                <div class="col-sm-4 form-group">
                    <br />
                    <mat-label> <span>Account</span><span class="orange-asterisk"> *</span></mat-label>
                </div>
                <div class="col-sm-8 form-group">

                    <ng-select [items]="accounts"
                               [searchable]="true"
                               [clearable]="false"
                               bindLabel="Label"
                               bindValue="KpiDefinitionId"
                               formControlName="kpiDefinitionId"
                               required>
                    </ng-select>
                    <br>

                </div>
            </div>
            <div class="row">
                <div class="panel-body">
                    <button (click)="backButtonHandler($event)" mat-raised-button class="btn  button-color-fix_red ">
                        <mat-icon class=" icon-color-fix ">arrow_back_ios</mat-icon>
                        Back
                    </button>
                    <button mat-raised-button type="submit" class="btn button-color-fix pull-right" [disabled]="!form.valid">
                        {{this.data ? 'Update' : 'Create'}}
                        <mat-icon class=" icon-color-fix ">save</mat-icon>
                    </button>

                </div>
            </div>
        </form>
    </div>
</div>
