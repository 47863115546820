import { Component, Inject, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Utils } from "../../utils/utils";
import { DefaultValuesService } from "../../services/defaultvalues.service";
import { DialogService } from "../../services/dialog.service";
import { SaveService } from "../../services/save.service";

@Component({
    selector: "maintenancecontrolplan",
    templateUrl: './mcp.component.html',
    styleUrls: ['../../style/container-tab-list.component.less']
})

export class MaintenanceControlPlanComponent implements OnInit {
    title: string = "Maintenance Control Program";
//    language: Language = <Language>{};
    list: MaintenanceControlPlan[] = [];
    //tenant: Country

    newMode: SaveService = <SaveService>{};

    constructor(
        private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , private defaults: DefaultValuesService
        , private dialog: DialogService
    ) {
}

    ngOnInit() {
        this.dialog.tthis = this;
        this.getAll();
        this.newMode = new SaveService();
    }

    getAll() {
        Utils.httpGetAllMaintenanceControlPlan(
            this.http
            , this.baseUrl
            , this
            , function (tthis: any, list: MaintenanceControlPlan[]) {
                tthis.list = list;
            }
        );
    }

    onNew() {
        this.newMode.fireNew();
    }

    //onResetList() {
    //    this.getAll();
    //}

    //onSearchList(obj: any) {
    //    ////{ ShowRef: boolean, Index: number, Value: string }
    //    var l = <MaintenanceControlPlan[]>[];
    //    var value = obj.Value;
    //    for (var i = 0; i < this.list.length; ++i) {
            
    //        switch (obj.Index) {
    //            case 0:
    //                if (this.list[i].Title)
    //                    if (this.list[i].Title.toLowerCase().indexOf(obj.Value.toLowerCase()) != -1)
    //                        l.push(this.list[i]);
    //                break;
    //            case 1:
    //                if (this.list[i].AssetType)
    //                    if (this.list[i].AssetType.toLowerCase().indexOf(obj.Value.toLowerCase()) != -1)
    //                        l.push(this.list[i]);
    //                break;
    //            case 2:
    //                if (this.list[i].IsHydraulic)
    //                    if (this.list[i].IsHydraulic == obj.Value)
    //                        l.push(this.list[i]);
    //                break;
    //            case 3:
    //                if (this.list[i].Name)
    //                    if (this.list[i].Name.toLowerCase().indexOf(obj.Value.toLowerCase()) != -1)
    //                        l.push(this.list[i]);
    //            default:
    //                if (this.list[i].MCPID)
    //                    if (this.list[i].MCPID.toString().indexOf(obj.Value) != -1)
    //                        l.push(this.list[i]);
    //        }
    //    }
    //    this.list = l;
    //}

    //onSearchListProperties(obj: any) {
    //    ////{ ShowRef: boolean, Index: number, Value: string }
    //    //var l = <ErrorCode[]>[];
    //    //var value = obj.Value;
    //    //for (var i = 0; i < this.errorcodes.length; ++i) {

    //    //    switch (obj.Index) {
    //    //        case 0:
    //    //            if (this.errorcodes[i].ErrorCode)
    //    //                if (this.errorcodes[i].ErrorCode.toString().indexOf(obj.Value) != -1)
    //    //                    l.push(this.errorcodes[i]);
    //    //            break;
    //    //        case 1:
    //    //            if (this.errorcodes[i].Description)
    //    //                if (this.errorcodes[i].Description.toLowerCase().indexOf(obj.Value.toLowerCase()) != -1)
    //    //                    l.push(this.errorcodes[i]);
    //    //            break;
    //    //        case 2:
    //    //            if (this.errorcodes[i].Resolutions)
    //    //                if (this.errorcodes[i].Resolutions == obj.Value)
    //    //                    l.push(this.errorcodes[i]);
    //    //        case 3:
    //    //            if (this.errorcodes[i].ErrorWeight)
    //    //                if (this.errorcodes[i].ErrorWeight == obj.Value)
    //    //                    l.push(this.errorcodes[i]);
    //    //            break;
    //    //        case 4:
    //    //            if (this.errorcodes[i].ControllerBlocked)
    //    //                if (this.errorcodes[i].ControllerBlocked == obj.Value)
    //    //                    l.push(this.errorcodes[i]);
    //    //            break;
    //    //    }
    //    //}
    //    ////if (l.length != 0)
    //    //    this.errorcodes = l;
    //}

    //onLanguageChanged(l: Language) {
    //    //console.log("Lang changed: " + l.Name);
    //    this.getAll();
    //}
}