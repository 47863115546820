<div id="login-overlay" class="d-flex align-items-center justify-content-center" 
     *ngIf="authService.instance.getAllAccounts().length < 1"
     [ngClass]="currentLocation === 'Mersy' ?
            'mersy_background' : currentLocation === 'Rulebook' ? 'rulebook_background' : ''">
    <div class="login-panel text-center">
        <img src="assets/TKE_Logo.svg" class="logo img-fluid" />
        <div class="bg-tke-gradient color-white lead">
            {{ currentLocation === 'Mersy' ?
            'MERSY@CDP' : currentLocation === 'Rulebook' ? 'Rulebook Editor' : ''
            }}
        </div>
        <div class="content-box">
            <h4 class="login-title">Please, use your TK Elevator account to login</h4>
            <button (click)="login()" class="login-button w-100">Login</button>
        </div>
    </div>
</div>
<!--<router-outlet></router-outlet>-->

<div class='full-page-container' *ngIf="authService.instance.getAllAccounts().length > 0">

    <div class='header-menu'>
        <mat-toolbar style="background-color:inherit;">

            <span>
                <a [routerLink]="['/']">
                    <img src="../assets/tke-white-80.png" alt="Tke Logo" />
                </a>
            </span>
            <stage [hidden]="hideContractMenuItem" [reload]="reload" (stageChanged)="onStageSelectedChanged($event)" *ngIf="defaults.componentTool === componentToolEnum.Rulebook"></stage>

            <tenant [hidden]="hideTenantMenuItem" [isTenDlrCong]="!hideRegulatoryAndDialerItem" (tenantChanged)="onTenantSelectedChanged($event)" *ngIf="defaults.componentTool === componentToolEnum.Rulebook"></tenant>

            <!--two drop downs (Dialer & Regulatory code )-->
            <regulatoryanddialer [hidden]="hideRegulatoryAndDialerItem" *ngIf="defaults.componentTool === componentToolEnum.Rulebook && (this.defaults.RegCode.value || !hideRegulatoryAndDialerItem)"></regulatoryanddialer>


            <!-- controller-type select -->
            <controller-type [isDigitalServiceView]="isDigitalServiceView" [hidden]="hideControllerTypeMenuItem" (controllerChanged)="onControllerTypeSelectedChanged($event)" *ngIf="defaults.componentTool === componentToolEnum.Rulebook"></controller-type>

            <!-- language select -->
            <language [hidden]="hideLanguageMenuItem" (languageChanged)="onLanguageSelectedChanged($event)" *ngIf="defaults.componentTool === componentToolEnum.Rulebook"></language>
            <population [hidden]="hidePopulationMenuItem" *ngIf="defaults.componentTool === componentToolEnum.Rulebook && (this.defaults.healthScorePopulation.value || !hidePopulationMenuItem)"></population>

            <managementid [hidden]="hideManagementIdMenuItem" (managementIdChanged)="onManagementIdSelectedChanged($event)" *ngIf="defaults.componentTool === componentToolEnum.Mersy"></managementid>
            <fiscalyear [hidden]="hideFiscalYearMenuItem" *ngIf="defaults.componentTool === componentToolEnum.Mersy"></fiscalyear>

            <oplversion [hidden]="hideOplVersionMenuItem" *ngIf="defaults.componentTool === componentToolEnum.Mersy"></oplversion>
            <period [hidden]="hidePeriodMenuItem" *ngIf="defaults.componentTool === componentToolEnum.Mersy"></period>
            <country [hidden]="hideCountryMenuItem" *ngIf="defaults.componentTool === componentToolEnum.Rulebook"></country>
            <wave-selector [hidden]="hideWaveItem" *ngIf="defaults.componentTool === componentToolEnum.Mersy"></wave-selector>

            <span style="flex: 1 1 auto;"></span>

            <span *ngIf="defaults.componentTool === componentToolEnum.Mersy">
                <a target="_blank" href="https://tke.service-now.com/tkeserviceportal?id=report_an_issue" [matTooltip]="'Create a Ticket / Report a Bug'">
                    <mat-icon  class="wiki_icon" style="margin-right: 0.5rem !important">
                            flag_circle
                    </mat-icon>
                </a>
            </span>
            <span>
                <a [matTooltip]="isWikiBtnDisabled ? 'Inline Documentation not Available' : 'Inline Documentation'" (click)="onInlineDocClick()">
                    <mat-icon [ngStyle]="{color: isWikiBtnDisabled ?
                                '#494949' : hideInlineDoc ? '#a31aff' : '#ffffff'}"
                              [ngClass]="isWikiBtnDisabled ? 'non-clickable-icon' :  'clickable_icon'" class="wiki_icon">
                        {{isWikiBtnDisabled ? 'info-icon' : hideInlineDoc ? 'info-icon':'info-icon_off' }}
                    </mat-icon>
                    <mat-progress-bar class="wikiPrgressBar" *ngIf="showWikiLoadBar" mode="indeterminate" style=" width: 54%; bottom: 6px;"></mat-progress-bar>
                </a>
            </span>
            <nav-menu-tools [hideOwnerMenuItem]="hideOwnerMenuItem" [userUPN]="currentUser" [isControllerOwner]="isControllerOwner"></nav-menu-tools>
        </mat-toolbar>
    </div>
    <sidenav-menu style="float:left;" [selectedItem]="defaults.selectedItem"></sidenav-menu>
    <div id="main-content" #maincontent [ngStyle]="{'background-image':
        router.url === '/' && defaults.componentTool === componentToolEnum.Mersy ? 'url(../assets/mersy_background.jpg)':
            router.url === '/' && defaults.componentTool === componentToolEnum.Rulebook ? 'url(../assets/rulebook_background.jpg)' : ''}">
        <div class='container-fluid' style=" position: relative; ">
            <div class='main-container' [ngClass]="waiting.classopacity">
                <div [ngClass]="dialog.classname!">
                    <router-outlet></router-outlet>
                </div>
            </div>
            <alert-confirm [message]="dialog.message!" [title]="dialog.title!" [className]="dialog.classdialog!" [type]="dialog.type!" [items]="dialog.items!" [showTypeIcon]="dialog.showTypeIcon" [progressValue]="dialog.progressValue!" [text_icon_items]="dialog.text_icon_items" (onClickOk)="dialog.onOk()" (onClickNo)="dialog.onNo()" (onClickYes)="dialog.onYes()"></alert-confirm>
            <wikinav (disableWikiBtn)="receiveWikiAvBool($event)" (showLoadingBar)="receiveWikiLdBarBool($event)" [hideInlineDoc]="hideInlineDoc"></wikinav>
        </div>
    </div>
</div>
