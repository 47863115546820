import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DefaultValuesService } from "../../../services/defaultvalues.service";
import { SignalRService } from "../../../services/signal-r";

@Component({
    selector: "backendsetup",
    templateUrl: './backendsetup.component.html',
    styleUrls: ['../../../style/container-tab-list.component.less','./backendsetup.component.less']
})

export class BackendSetupComponent implements OnInit {
    title: string;


    constructor(
        private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , private defaults: DefaultValuesService
        , public signalRService: SignalRService

    ) {
        this.title = "Backend Setup";
    }

    ngOnInit(): void { }
}

