
<button mat-icon-button (click)="btn_new()" matTooltip="Add new row">
    <mat-icon>add_circle</mat-icon>
</button>
<button mat-icon-button (click)="btn_refresh()" matTooltip="Refresh the current table">
    <mat-icon>refresh</mat-icon>
</button>
<br />


<table mat-table
       matSort
       [dataSource]="dataSource"
       class="mat-elevation-z8 mat-table-custom"
       id="transmissionmode-table">

    <ng-container matColumnDef="ItemNo">
        <th mat-header-cell *matHeaderCellDef> Item no.</th>
        <td mat-cell *matCellDef="let element;index as i;">
            <p [innerHTML]="(i+1)"></p>
        </td>
    </ng-container>
    <ng-container matColumnDef="ControllerType">
        <th mat-header-cell *matHeaderCellDef> Controller type </th>
        <td mat-cell *matCellDef="let element">
            <p [innerHTML]="element.ControllerType.toUpperCase()"></p>
        </td>
    </ng-container>
    <ng-container matColumnDef="Description">
        <th mat-header-cell *matHeaderCellDef> Controller type </th>
        <td mat-cell *matCellDef="let element">
            <p [innerHTML]="element.Description.toUpperCase()"></p>
        </td>
    </ng-container>
    <ng-container matColumnDef="TimeWindowInSeconds">
        <th mat-header-cell *matHeaderCellDef> Time Window (seconds) </th>
        <td mat-cell *matCellDef="let element">
            <p [innerHTML]="element.TimeWindowInSeconds"></p>
        </td>
    </ng-container>
    <ng-container matColumnDef="ChangedAtUtc">
        <th mat-header-cell *matHeaderCellDef> Changed at (UTC) </th>
        <td mat-cell *matCellDef="let element;">
            <p [innerHTML]="element.ChangedAtUtc"></p>
        </td>
    </ng-container>
    <ng-container matColumnDef="ChangedByUser">
        <th mat-header-cell *matHeaderCellDef> Changed by </th>
        <td mat-cell *matCellDef="let element">
            <p [innerHTML]="element.ChangedByUser"></p>
        </td>
    </ng-container>
    <ng-container matColumnDef="CreatedAtUtc">
        <th mat-header-cell *matHeaderCellDef> Created at (UTC) </th>
        <td mat-cell *matCellDef="let element;">
            <p [innerHTML]="element.CreatedAtUtc"></p>
        </td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Actions </th>
        <td class="actionscolumn th-center" mat-cell *matCellDef="let element">
            <button mat-icon-button matTooltip="Edit Row"
                    (click)="onRowEditInit(element)">
                <mat-icon>edit</mat-icon>
            </button>
            <button *ngIf="element._ControllerTypeMappingID != -1" mat-icon-button matTooltip="Delete row"
                    (click)="onDelete(element)">
                <mat-icon>delete</mat-icon>
            </button>
        </td>
    </ng-container>



    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let element; index as i;
            columns: columnsToDisplay;"
        class="opmode-item">
    </tr>
</table>