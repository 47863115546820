import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, OnInit, Output, Self, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Utils } from '../../../utils/utils';
import { ToastrService } from 'ngx-toastr';
import { DialogService } from '../../../services/dialog.service';
import { DefaultValuesService } from '../../../services/defaultvalues.service';
import { DestroyService } from '../../../services/destroyservice';
import { takeUntil } from 'rxjs';

@Component({
    selector: 'tdcfamilycode',
    templateUrl: './tdcfamilycode.component.html',
    styleUrls: ['../../../style/new-generic-styles.component.less', 
        '../../../style/tables/table-add-butt-header-orange.less',
        './tdcfamilycode.component.less', '../tdccomponents.component.less'],
    providers: [DestroyService]
})
export class TDCFamilyCode implements OnInit {
    public dataSource = new MatTableDataSource<any>();
    FCForm!: FormGroup;
    originalFamilyCodes: FamilyCode[] = [];
    modifiedFamilyCodes: FamilyCode[] = [];
    newFamilyCodes: FamilyCode[] = [];
    @ViewChild(MatTable) objTable!: MatTable<any>;
    genType: number = 0;
    @Input() availableComponentFunctions: ComponentFunction[] = [];
    @Output() familyCodesEvent = new EventEmitter<FamilyCode[]>();
    forbiddenchars = ['/', '\\', ':', '*', '?', '<', '>', '|', '"'];
    subscriptionStage: any;
    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string,
        public datePipe: DatePipe,
        private _formBuilder: FormBuilder,
        private fb: FormBuilder,
        private toastrService: ToastrService,
        private confimDialog: DialogService,
        public defaults: DefaultValuesService,
        @Self() private readonly destroy$: DestroyService) {
        this.confimDialog.tthis = this;
    }

    columnsToDisplay = ["familycode", "familycodename", "functiontype", "date", "user", "action"];
    isCallSucceed: number = 0;

    ngOnInit(): void {
        this.subscriptionStage = this.defaults.stageMessageChanges$.pipe(takeUntil(this.destroy$)).subscribe(() => { this.getFamilyCodes() });
        this.genType = 0;
        this.FCForm = this._formBuilder.group({
            FCRows: this._formBuilder.array([])
        });
        this.getFamilyCodes();
    }

    getFunctionTypeName(funcTypeId: number) {
        return this.availableComponentFunctions.find(x => x.functionTypeId === funcTypeId)?.functionTypeName ?? "---";
    }

    getFamilyCodes() {
        Utils.httpGetComponentFamilyCodes(
            this.http,
            this.baseUrl,
            this,
            function (tthis: TDCFamilyCode, result: ComponentFamilyCodes) {
                tthis.originalFamilyCodes = result.familyCodeList;
                tthis.mapDataToForm(tthis.originalFamilyCodes);
                tthis.familyCodesEvent.emit(tthis.originalFamilyCodes);
            }
        )
    }

    mapFormToData() {
        this.modifiedFamilyCodes = this.FCForm.get('FCRows')?.value.filter(row => !row.isNewRow
                                                                && !row.isEditable
                                                                && row.functionTypeId !== -1
                                                                && row.familyCodeName.length <= 50);
        this.newFamilyCodes = this.FCForm.get('FCRows')?.value.filter(row => row.isNewRow
                                                                && row.familyCode !== ""
                                                                && row.familyCode.length <= 10
                                                                && row.familyCodeName !== ""
                                                                && row.familyCodeName.length <= 50
                                                                && row.functionTypeId !== -1);        
    }

    mapDataToForm(data) {
        this.FCForm = this._formBuilder.group({
            FCRows: this._formBuilder.array([])
        });
        this.FCForm = this.fb.group({
            FCRows: this.fb.array(data.map(val => this.fb.group({
                familyCodeId: new FormControl(val.familyCodeId),
                familyCode: new FormControl(val.familyCode),
                familyCodeName: new FormControl(val.familyCodeName),
                functionTypeId: new FormControl(val.functionTypeId ?? -1),
                utcModifDate: new FormControl(val.utcModifDate),
                modifUser: new FormControl(val.modifUser),
                isEditable: new FormControl(true),
                isNewRow: new FormControl(false),
                action: new FormControl('existingRecord'),
            })
            )) //end of fb array
        }); // end of form group cretation
        this.dataSource.data = (this.FCForm.get('FCRows') as FormArray).value;


    }

    initFCForm(): FormGroup {
        return this.fb.group({
            familyCode: new FormControl(`NewCode_${this.genType}`),
            familyCodeName: new FormControl(`New_FamilyCode_${this.genType++}`),
            functionTypeId: new FormControl(-1),
            action: new FormControl('newRecord'),
            isEditable: new FormControl(false),
            isNewRow: new FormControl(true),
        });
    }

    onSave() {
        if (this.anyChange()) {
            var familyCodeChanges = { 'modifiedFamilyCodes': this.modifiedFamilyCodes, 'newFamilyCodes': this.newFamilyCodes }
            Utils.httpUpdateComponentFamilyCodes(
                this.http
                , this.baseUrl
                , familyCodeChanges
                , this
                , function (tthis: TDCFamilyCode, result: any) {
                    if (result?.error && result.errorMssg) {
                        tthis.confimDialog.showErrorDialog({
                            'error':
                            {
                                'message': result.errorMssg
                            }
                        })
                    }
                    tthis.getFamilyCodes();
                    tthis.funcNotify();
                }
            )
        }
    }

    editRow(FCFormElement, i) {
        FCFormElement.get('FCRows').at(i).get('isEditable').patchValue(false);
    }

    addNewRow() {
        const control = this.FCForm.get('FCRows') as FormArray;
        control.insert(0, this.initFCForm());
        this.dataSource = new MatTableDataSource(control.controls)
    }

    resetRow(i) {
        if (this.FCForm.value.FCRows[i]?.isNewRow)
            this.deleteRow(i);
        else {
            var defaultObjectValues = this.originalFamilyCodes.find(ft => ft.familyCode == this.FCForm.value.FCRows[i]?.familyCode)
            this.updateFormFeature(defaultObjectValues, i)
            this.FCForm.value.FCRows[i].isEditable = true;
        }
    }

    updateFormFeature(newSelectedObject, index) {
        var obj = this.FCForm.value.FCRows;
        obj[index].familyCodeName = newSelectedObject.familyCodeName;
        obj[index].functionTypeId = newSelectedObject.functionTypeId ?? -1;
    }

    deleteRow(i) {
        const control = this.FCForm.get('FCRows') as FormArray;
        control.removeAt(i);
        this.dataSource.data = control.value;
        this.refreshTable();
    }

    refreshTable() {
        this.objTable.renderRows();
    }

    anyChange() {
        this.mapFormToData();
        return this.modifiedFamilyCodes?.length > 0 || this.newFamilyCodes?.length > 0;
    }

    funcNotify() {
        if (this.isCallSucceed === 1) {
            this.toastrService.success('Save request successfully sent!');
        }
        else if (this.isCallSucceed === -1) {
            this.toastrService.error('Save action could not be performed. Please, contact with administrator');
        }
    }

    checkInput(event) {
        if (this.forbiddenchars.some(x => x === event.key))
            event.preventDefault();
    }
}