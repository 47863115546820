import { Component, Inject, Input, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { MatChipInputEvent } from "@angular/material/chips";
import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';
import { SaveService } from '../../../../services/save.service';
import { Utils } from '../../../../utils/utils';
import { DefaultValuesService } from '../../../../services/defaultvalues.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Subject } from "rxjs";
import { DialogService } from "../../../../services/dialog.service";

@Component({
    selector: "contractitem-list",
    templateUrl: './contractitem-list.component.html',
    styleUrls: ['./contractitem-list.component.less', '../../../../style/mat-table.component.less'],

    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*', })),
            transition('expanded => collapsed', [animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
        ]),
    ]
})

export class ContractItemListComponent implements OnInit {
    @Input('list') list: Contract[] = [];
    @Input('showLocalFilter') showLocalFilter: boolean = false;

    columnsToDisplay = ['arrow', 'index', 'status', 'ContractNumber', 'UnitId', 'CustomerId', 'ContractLine', 'ContractLineLocale',
        'ContractType', 'ContractTypeLocale', 'IsPrimaryContract', '_ChangedAtUtc', 'actions'];

    subcolumnsToDisplay = ['ContractStartDate', 'ContractEndDate', 'ContractCancelationDate', 'ContractIsAllin', 'ContractIsAllinExceptOvertime',
        'ContractIsSparePartsIncluded', 'SiteId', 'SiteAddress',
        'SiteZipCode', 'SiteCity', 'SiteRegion', 'SiteState', 'SiteCountry', 'SitePhone', 'SiteEMail', 'NextPlannedMaintenanceVisit',
        'MaintenanceVisitInterval', 'ContractDocumentUri', 'IsDeleted', 'AgreedAvailability',
        'MasterContractNumber', 'YearlyContractValue', 'Currency', 'ContractOnHold', 'ContractOnHoldReason', 'StartOnHoldDateUTC',
        'EndOnHoldDateUTC', 'IsWarrantyContract', 'Source', 'LegacyContractNumber', 'MasterContractType', 'IsCSP', 'IsVIP', 'ContractMonthlyValue',
        'UnitMonthlyValue', 'BillingFrequency', 'BillingFrequencyOthers', 'PaymentTerms', 'IsCallChargeable', 'IsAfterHoursChargeable',
        'IsMaterialExcluded', 'HasDirectOffering', 'CancellationTerms', 'CancellationNoticeInDays', 'CancellationNoticePeriodInitial',
        'RolloverPeriodMonths', 'NumberRollovers', 'CancellationRolloverNoticePeriodInDays', 'EscalationType', 'TargetCallRate',
        'ServiceProvider', 'HoldType', 'HoldReason', 'HoldStartDate', 'HoldEndDate', 'IsOnHoldMaintenanceVisits', 'IsOnHoldRepairs',
        'IsOnHoldCallbacks', 'IsOnHoldBilling', 'ContractLocalNotes', 'ContractLocalDispatchNotes', 'ContractLocalCSPNotes',

        'CoverageInH', 'NumberOfCallbacks', 'AssetType', 'LostReason', 'LostReasonLocal', 'CompetitorYearlyValue', 'TenderLostReason',
        'ContractTypeLocaleKey', 'ContractLineLocaleKey', 'ContractStatus', 'SourceSystemId'];

    booleanColumns = ['IsPrimaryContract', 'ContractIsAllin', 'ContractIsAllinExceptOvertime', 'ContractIsSparePartsIncluded', 'IsDeleted',
        'ContractOnHold', 'IsWarrantyContract', 'IsCSP', 'IsVIP', 'IsCallChargeable', 'IsAfterHoursChargeable',
        'IsMaterialExcluded', 'HasDirectOffering', 'IsOnHoldMaintenanceVisits', 'IsOnHoldRepairs',
        'IsOnHoldCallbacks', 'IsOnHoldBilling']

    dateColumns = ['ContractStartDate', 'ContractEndDate', 'ContractCancelationDate', 'NextPlannedMaintenanceVisit',
        'StartOnHoldDateUTC', 'EndOnHoldDateUTC', 'HoldStartDate', 'HoldEndDate', '_ChangedAtUtc']
    numberColumns = ['AgreedAvailability', 'YearlyContractValue', 'ContractMonthlyValue', 'UnitMonthlyValue',
        'CancellationNoticeInDays', 'CancellationNoticePeriodInitial',
        'RolloverPeriodMonths', 'NumberRollovers', 'CancellationRolloverNoticePeriodInDays', 'TargetCallRate']

    notEditableColumns = ['UnitId', 'ContractNumber', 'CustomerId']

    @ViewChild(MatTable, { static: true }) table!: MatTable<any>;

    public dataSource = new MatTableDataSource<Contract>();
    selected: Contract | undefined;
    expanded: boolean[] = [];
    expandedElements = new Set();
    clonedList: { [s: string]: Contract; } = {};

    filter: string = '';

    subscriptionLanguage: any;
    subscriptionTenant: any;

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [COMMA, TAB, ENTER];
    searchItems: SearchItem[] = [];

    isPrimaryContract = '-1'

    protected _onDestroy = new Subject<void>();
    constructor(private activatedRoute: ActivatedRoute
        , private router: Router
        , private http: HttpClient
        , private saveService: SaveService
        , private defaults: DefaultValuesService
        , @Inject('BASE_URL') private baseUrl: string
        , private confirmDialog: DialogService


    ) {
    }

    ngOnChanges() {
        this.dataSource.data = this.list
        this.clonedList = {}
        this.saveService.showSaveButton(false)
        this.expandedElements.clear();
        this.saveService.addListener(this)
    }

    ngOnInit() {
        this.getHeader();
        this.subscriptionTenant = this.defaults.tenantMessageChanges$.subscribe(() => {
            this.getHeader()
        });
        this.saveService.addListener(this);
        this.dataSource.filterPredicate = (data: Contract, filters: string) => {
            const matchFilter: any[] = [];
            const filterArray = filters.split('+').filter(x => x.indexOf('notextfilters') < 0)
            if (filterArray.length > 0) {
                filterArray.forEach((filter) => {
                    let result = true;
                    const fields: string[] = []
                    fields.push(data.ContractNumber ? data.ContractNumber : '')
                    fields.push(data.UnitId ? data.UnitId : '')
                    fields.push(data.CustomerId ? data.CustomerId : '')
                    fields.push(data.ContractLine ? data.ContractLine : '')
                    fields.push(data.ContractLineLocale ? data.ContractLineLocale : '')
                    fields.push(data.ContractType ? data.ContractType : '')
                    fields.push(data.ContractTypeLocale ? data.ContractTypeLocale : '')
                    if (this.isPrimaryContract === '1')
                        result = data.IsPrimaryContract
                    if (this.isPrimaryContract === '0')
                        result = !data.IsPrimaryContract
                    return matchFilter.push(result && fields.some(item => item.includes(filter)));
                });
            }
            else {
                let result = true;
                if (this.isPrimaryContract === '1')
                    result = result && data.IsPrimaryContract
                if (this.isPrimaryContract === '0')
                    result = result && !data.IsPrimaryContract
                matchFilter.push(result);
            }
            return matchFilter.some(Boolean);
        };
    }

    ngOnDestroy() {
        this.saveService.removeListener(this);
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    getHeader() {
        if (!this.defaults.isReadonlyUser)
            this.columnsToDisplay = ['arrow', 'index', 'status', 'ContractNumber', 'UnitId', 'CustomerId', 'ContractLine', 'ContractLineLocale',
                'ContractType', 'ContractTypeLocale', 'IsPrimaryContract', '_ChangedAtUtc', 'actions'];
        else
            this.columnsToDisplay = ['arrow', 'index', 'status', 'ContractNumber', 'UnitId', 'CustomerId', 'ContractLine', 'ContractLineLocale',
                'ContractType', 'ContractTypeLocale', 'IsPrimaryContract', '_ChangedAtUtc'];
        this.subcolumnsToDisplay.sort((x, y) => (x > y ? 1 : -1));
    }

    getInput(data: Contract, property: string): any {
        return data[property];
    }

    getDataType(property: string) {
        let result = ''
        if (this.booleanColumns.indexOf(property) > -1) result = 'boolean'
        else if (this.dateColumns.indexOf(property) > -1) result = 'date'
        else if (this.numberColumns.indexOf(property) > -1) result = 'number'
        else result = 'string'
        return result
    }

    isActive(element: Contract) {
        const d = new Date();
        if (!element.ContractEndDate && !element.ContractCancelationDate)
            return new Date(element.ContractStartDate).getTime() < d.getTime()
        else if (element.ContractEndDate && !element.ContractCancelationDate)
            return new Date(element.ContractStartDate).getTime() < d.getTime() && new Date(element.ContractEndDate).getTime() > d.getTime()
        else {
            return new Date(element.ContractStartDate).getTime() < d.getTime() &&
                (new Date(element.ContractEndDate).getTime() < new Date(element.ContractCancelationDate).getTime() ?
                    new Date(element.ContractEndDate).getTime() : new Date(element.ContractCancelationDate).getTime()) > d.getTime()
        }
    }

    isDeleted(element: Contract) {
        return element.IsDeleted
    }

    changeElement(contract: Contract, type: string, value: boolean) {
        let index = this.dataSource.data.findIndex(x => x.ContractNumber === contract.ContractNumber
            && x.UnitId === contract.UnitId
            && x.ContractLine === contract.ContractLine)
        if (this.columnsToDisplay.find(x => x === type)) {
            this.dataSource.data[index][type] = value
        } else {
            this.dataSource.data[index].Details[0][type] = value
        }
        this.table.renderRows();
    }

    pushPopElement(element: Contract) {
        if (this.expandedElements.has(element)) {
            this.expandedElements.delete(element);
        }
        else {
            this.expandedElements.add(element);
            this.onExpand(element)
        }
    }

    checkExpanded(element: Contract) {
        return this.expandedElements.has(element);
    }

    onExpand(element: Contract) {
        Utils.httpGetDetailedContract(
            this.http
            , this.baseUrl
            , this.defaults.stage
            , element
            , this
            , function (tthis: any, list: ContractDetails[]) {
                element.Details = list;
                tthis.expandedElements.add(element);
            }
        );
    }

    onRowEditInit(contract: Contract) {
        this.saveService.addListener(this);
        this.clonedList[this.getDictionaryHash(contract)] = { ...contract };
        if (Object.keys(this.clonedList).length !== 0) this.onShowSavebutton()
        this.onExpand(contract)
    }

    onRowEditCancel(contract: Contract) {
        this.dataSource.data[this.dataSource.data.findIndex(x => x.ContractNumber === contract.ContractNumber
            && x.UnitId === contract.UnitId
            && x.ContractLine === contract.ContractLine)] = this.clonedList[this.getDictionaryHash(contract)];
        delete this.clonedList[this.getDictionaryHash(contract)];
        if (Object.keys(this.clonedList).length === 0) this.saveService.showSaveButton(false)
        this.table.renderRows();
    }

    isEditingRow(contract: Contract, property: string) {
        return this.clonedList[this.getDictionaryHash(contract)] &&
            (property !== 'UnitId' && property !== 'ContractNumber' && property !== 'CustomerId' && property !== 'ContractLine' && property !== '_ChangedAtUtc' && property !== 'Source')
    }

    onShowSavebutton() {
        this.saveService.showSaveButton();
    }

    onSave() {
        let updatedList: any[] = []
        for (let i in this.clonedList) {
            let index = this.dataSource.data.findIndex(x => x.UnitId === this.clonedList[i].UnitId &&
                x.ContractNumber === this.clonedList[i].ContractNumber &&
                x.ContractLine === this.clonedList[i].ContractLine)
            updatedList.push({ ...this.dataSource.data[index], ...this.dataSource.data[index].Details[0] })
        }
        Utils.httpUpdateContracts(
            this.http
            , this.baseUrl
            , this.defaults.stage
            , updatedList
            , this
            , function (tthis: any, result: any) {

                if (result === 1) {
                    tthis.confirmDialog.showErrorDialog(
                        {
                            'error':
                                { 'message': 'Please, be sure all mandatory fields (Contract Number, unitId, CustomerId, ContractType, Contract Locale and Contract Start Date) are populated' }
                        })
                    tthis.saveService.showSaveButton(true)
                    tthis.saveService.addListener(tthis);


                }
                else if (result === 2) {
                    tthis.confirmDialog.showErrorDialog(
                        {
                            'error':
                                { 'message': 'Please, check IsPrimaryContract and ContractLine fields. If item is primary contract, both ContractLine and ContractLineLocale must be empty. Otherwise, both fields must be populated.' }
                        })
                    tthis.saveService.showSaveButton(true)
                    tthis.saveService.addListener(tthis);
                }
                else {
                    // Update mat-tool-tips after contract update
                    for (let i in tthis.clonedList) {
                        let index = tthis.dataSource.data.findIndex(x => x.UnitId === tthis.clonedList[i].UnitId &&
                            x.ContractNumber === tthis.clonedList[i].ContractNumber &&
                            x.ContractLine === tthis.clonedList[i].ContractLine)
                        tthis.dataSource.data[index].ContractStartDate = tthis.dataSource.data[index].Details[0].ContractStartDate
                        tthis.dataSource.data[index].ContractCancelationDate = tthis.dataSource.data[index].Details[0].ContractCancelationDate
                        tthis.dataSource.data[index].ContractEndDate = tthis.dataSource.data[index].Details[0].ContractEndDate
                        tthis.dataSource.data[index].IsDeleted = tthis.dataSource.data[index].Details[0].IsDeleted
                    }
                    tthis.clonedList = {}
                    tthis.saveService.showSaveButton(false)
                    tthis.expandedElements.clear();
                    tthis.table.renderRows();
                }
            }
            , function (tthis: ContractItemListComponent, message: string) {
                tthis.saveService.addListener(tthis)
                tthis.saveService.showSaveButton(true)
                tthis.confirmDialog.showErrorDialog(message);
            }
        );
    }

    filterCheckbox(value: any, name: string) {
        switch (name) {
            case 'isprimarycontract': this.isPrimaryContract = value.value; break;
        }
        this.updatefilter()
    }

    applyFilter(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            this.searchItems.push({ name: value.replace('+', '').trim() });
        }
        // Reset the input value
        if (input) {
            input.value = '';
        }
        this.updatefilter()
    }

    remove(item: SearchItem): void {
        const index = this.searchItems.indexOf(item);
        if (index >= 0) {
            this.searchItems.splice(index, 1);
        }
        this.updatefilter()
    }
    updatefilter() {
        let filterString: string
        if (this.searchItems.length === 0) {
            this.dataSource.filter = 'notextfilters'
        }
        else {
            filterString = this.searchItems.map(e => { return e.name }).join('+') + '+checkboxes'
            this.dataSource.filter = filterString
        }
    }

    getDictionaryHash(contract: Contract) {
        return contract.UnitId + contract.ContractNumber + contract.ContractLine
    }

}
export interface SearchItem {
    name: string;
}