import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class FilterService {

    listeners: { tthis: any, callback: any }[] = [];

    public addListener(tthis: any, callback: any = undefined) {
        let obj = { tthis: tthis, callback: callback };
        let exists = false;
        this.listeners.forEach(x => {
            if (x.tthis == tthis)
                exists = true;
        });

        if (!exists)
            this.listeners.push(obj);
    }

    public fireInit() {
        this.listeners.forEach(x => {
            if (x.tthis.onInit && x.tthis.isToInitiate)
                x.tthis.onInit();
        });
    }

    //public IsToInitiate(): boolean {
    //    return this.istoinitiate;
    //}

    public setToInitiate(value: boolean = true) {
        this.listeners.forEach(x => {
            if (x.tthis.isToInitiate != undefined)
                x.tthis.isToInitiate = value;
        });
    }
}