import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { HttpClient } from '@angular/common/http';
import "rxjs/operators";
import { DialogService } from '../../../../services/dialog.service';
import {  FormGroup } from '@angular/forms';


@Component({
    selector: 'customeridslist',
    templateUrl: './customeridslist.component.html',
    styleUrls: ['../../../../style/azdatafactory.component.less', '../../../../style/custom-mat-dialog.component.less',]
})


export class CustomerIdsListComponent implements OnInit {

    columnsToDisplay = ['customerid'];

    public dataSource = new MatTableDataSource<string>();

    form: FormGroup | undefined
    submitted = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any
        , @Inject('BASE_URL') private baseUrl: string
        , public dialogRef: MatDialogRef<CustomerIdsListComponent>
    ) {

    }
    ngOnInit(): void {
        this.dataSource.data = this.data.customerids.split(';');
    }

    onNoClick(): void {
        this.dialogRef.close({ result: false});
    }

    onSubmit() {
        this.dialogRef.close({ result: true });
    }
}


