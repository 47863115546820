<mat-accordion multi class="expand-collapse-panel">
    <mat-expansion-panel>
        <mat-expansion-panel-header>
        </mat-expansion-panel-header>
        <form autocomplete="off" [formGroup]="form" id="filterCustomerFacilityManager">
        <tr>
            <td>
                <mat-form-field class="search-input">
                    <mat-label>Customer Id</mat-label>
                    <input placeholder="Customer Id"
                           matInput
                           [matChipInputFor]="chipCustomerId"
                           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                           [matChipInputAddOnBlur]="addOnBlur"
                           (matChipInputTokenEnd)="applyFilter($event, 'customerids')">
                </mat-form-field>
                <mat-chip-list #chipCustomerId>
                    <mat-chip *ngFor="let item of customerIds" [selectable]="selectable"
                              [removable]="removable" (removed)="remove(item, 'customerids')">
                        {{item}}
                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </td>
            <td style="">
                <mat-form-field class="search-input">
                    <mat-label>Facilities Manager Id</mat-label>
                    <input placeholder="Facilities Manager Id"
                           matInput
                           [matChipInputFor]="chipFacilityManagerIds"
                           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                           [matChipInputAddOnBlur]="addOnBlur"
                           (matChipInputTokenEnd)="applyFilter($event, 'facilitymanagerids')">
                </mat-form-field>
                <mat-chip-list #chipFacilityManagerIds>
                    <mat-chip *ngFor="let item of facilityManagerIds" [selectable]="selectable"
                              [removable]="removable" (removed)="remove(item, 'facilitymanagerids')">
                        {{item}}
                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </td>
            <td class="middle-align">
                <button type="button" class="btn mat-flat-button btn-primary" (click)="onFilter()">
                    {{ changeTypeButton() ? 'Reset' : 'Filter' }}
                </button>
            </td>
        </tr>
        </form>
    </mat-expansion-panel>
</mat-accordion>