<div style="display:flow-root">
    <div style="float:left">
        <h5>This is the EMT Function Types management screen. </h5>
    </div>
</div>
<div class="flex-center">
    <div class="contentWidth">
        <div style="height: calc(90vh - 100px); overflow-y: auto;">
            <form [formGroup]="FTForm" autocomplete="off">
                <ng-container formArrayName="FTRows">
                    <table #objTable
                           mat-table
                           matSort
                           [dataSource]="dataSource"
                           class="table-add-butt-header-orange mat-elevation-z8 mat-table-custom">

                        <ng-container matColumnDef="headertitle">
                            <th class="title-col" mat-header-cell *matHeaderCellDef colspan="4">
                                <div class="pull-left">
                                    <h4>Function Types</h4>
                                </div>
                            </th>
                        </ng-container>
                        <ng-container matColumnDef="headeraddbutton">
                            <th class="butt-col" mat-header-cell *matHeaderCellDef>
                                <div class="OrangeButton">
                                    <button mat-raised-button (click)="addNewRow()"
                                            matTooltip="Add new function type" class="button-color-fix pull-right">
                                        <mat-icon class="icon-color-fix icon-padding-fix">add_box</mat-icon>
                                    </button>
                                </div>
                            </th>
                        </ng-container>

                        <!-- Function Type Column -->
                        <ng-container matColumnDef="functiontype">
                            <th mat-header-cell *matHeaderCellDef style="width:15%">Function Type</th>
                            <td mat-cell *matCellDef="let element; let i = index" style="width:15%">
                                <div [innerHTML]="FTForm?.get('FTRows')?.value[i].functionType" *ngIf="FTForm?.get('FTRows')?.value[i]?.isEditable"></div>
                                <mat-form-field style="width: 100%" *ngIf="!FTForm?.get('FTRows')?.value[i]?.isEditable">
                                    <input matInput type="number"
                                           [(ngModel)]="FTForm?.get('FTRows')?.value[i].functionType"
                                           [ngModelOptions]="{standalone: true}"
                                           name="functionType{{i}}"
                                           required />
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <!-- Type Name Column -->
                        <ng-container matColumnDef="functiontypename">
                            <th mat-header-cell *matHeaderCellDef style="width:25%">Function Type Name</th>
                            <td mat-cell *matCellDef="let element; let i = index" style="width:25%">
                                <div [innerHTML]="FTForm?.get('FTRows')?.value[i].functionTypeName" *ngIf="FTForm?.get('FTRows')?.value[i]?.isEditable"></div>
                                <mat-form-field style="width: 100%" *ngIf="!FTForm?.get('FTRows')?.value[i]?.isEditable">
                                    <input matInput type="text"
                                           [ngModelOptions]="{standalone: true}"
                                           [(ngModel)]="FTForm?.get('FTRows')?.value[i].functionTypeName"
                                           maxlength="40"
                                           matTooltip="Max 40 characters" />
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <!-- Date Column -->
                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef style="width:25%">UTC modification date</th>
                            <td mat-cell *matCellDef="let element; let i = index" style="width:25%">
                                {{datePipe.transform(FTForm?.get('FTRows')?.value[i]?.utcModifDate,'yyyy-MM-dd HH:mm')}}
                            </td>
                        </ng-container>

                        <!-- User Column -->
                        <ng-container matColumnDef="user">
                            <th mat-header-cell *matHeaderCellDef style="width:25%">Modified by</th>
                            <td mat-cell *matCellDef="let element; let i = index" style="width:25%">
                                {{FTForm?.get('FTRows')?.value[i]?.modifUser}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef style="width:10%"></th>
                            <td mat-cell
                                *matCellDef="let element; let i = index" style="width:10%">

                                <button mat-icon-button
                                        *ngIf="!FTForm.get('FTRows')?.value[i]?.isEditable"
                                        matTooltip="Cancel Changes"
                                        color="warn"
                                        (click)="resetRow(i)">
                                    <mat-icon class="actionButtons">rotate_left</mat-icon>
                                </button>

                                <button mat-icon-button
                                        *ngIf="FTForm.get('FTRows')?.value[i]?.isEditable"
                                        matTooltip="Edit"
                                        color="primary"
                                        (click)="editRow(FTForm, i)">
                                    <mat-icon class="actionButtons">edit</mat-icon>
                                </button>

                                <button mat-icon-button
                                        *ngIf="false"
                                        matTooltip="Delete"
                                        color="warn"
                                        (click)="deleteRow(i)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="savefooter">
                            <th mat-footer-cell *matFooterCellDef colspan="5">
                                <div class="OrangeButton">
                                    <button mat-raised-button type="submit" (click)="onSave()"
                                            [disabled]="!anyChange()"
                                            [matTooltip]="anyChange()? 'Save changes' : 'Save disabled'"
                                            class="button-color-fix pull-right">
                                        Save
                                        <mat-icon style="color:white !important" class="icon-color-fix">save</mat-icon>
                                    </button>
                                </div>
                            </th>

                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="['headertitle', 'headeraddbutton']; sticky: true" class="stickyTopz100"></tr>
                        <tr class="col-headers" mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; let i = index; columns: columnsToDisplay;" [class.alternative2]="!FTForm.get('FTRows')?.value[i]?.isEditable"></tr>
                        <tr mat-footer-row *matFooterRowDef="['savefooter']; sticky: true"></tr>
                    </table>
                </ng-container>

            </form>
        </div>
    </div>
</div>
