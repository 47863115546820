import { Component, Inject, Input, OnChanges, Output, EventEmitter } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { DialogService } from "../../services/dialog.service";

@Component({
    selector: "errorcode-list-not-mapped",
    templateUrl: './errorcode-list-notmapped.component.html',
    styleUrls: ['../../style/list.component.less', './errorcode-list.component.less']
})

export class ErrorCodeListNotMappedComponent{
    @Input('list') list: ErrorCodeNotMapped[] = [];
    @Output() onCopy = new EventEmitter<{ index: number, value: ErrorCodeNotMapped }>();
    @Output() onRefresh = new EventEmitter<{}>();

    header: any[] = [
        { label: 'Error Code', show: true }
        , { label: 'Number of times Received', show: true }
    ];

    constructor(private activatedRoute: ActivatedRoute,
        private router: Router,
        private http: HttpClient,
        private dialog: DialogService,
        @Inject('BASE_URL') private baseUrl: string) {
    }

    /**
     * this is from who calls (inner component)
     * @param list
     * @param row
     * @param column
     */
    getData(list: ErrorCodeNotMapped[], row: number, column: number): string {
        if (list) {
            var e = list[row];
            switch (column) {
                case 0:
                    return e.ErrorCode.toString();
                default:
                    return e.NTimesReceived.toString();
            }
        }
        else
            return "";
    }

    onCopyErrorCode(evt: { index: number, value: ErrorCodeNotMapped }) {
        this.onCopy.emit(evt);
        this.dialog.doBack = false;
        this.dialog.showSuccessDialog("ErrorCode: " + evt.value.ErrorCode + " successfully copied. Please select Properties, to finish the ErrorCode creation.");
    }

    public refresh() {
        this.onRefresh.emit({});
    }
}