<div class='left-menu'>
    <ul class='nav navbar-nav'>

        <li [routerLinkActive]="['link-active']">
            <a class='menu-callback' (click)="onReload.emit()" title="Refresh">
                <span class='glyphicon glyphicon-refresh'></span> Refresh
            </a>
        </li>
        <li [routerLinkActive]="['link-active']" *ngIf="currentType.toString() !== '10'">
            <a class='menu-callback' target="_blank" (click)="openDocumentationModal()" title="Wiki">
                <span class='glyphicon glyphicon-info-sign'></span> Documentation
            </a>
        </li>
        <li class="separator"></li>
        <li [routerLinkActive]="['link-active']" *ngIf="currentType.toString() === '10'">
            <a class='menu-callback' target="_blank" (click)="openFeatureBundlePopup()">
                <span class='glyphicon glyphicon-plus'></span> Add New Bundle
            </a>
        </li>
        <li [routerLinkActive]="['link-active']" *ngIf="currentType.toString() === '10'">
            <a class='menu-callback' target="_blank" (click)="addAllBundles()">
                <span class='glyphicon glyphicon-plus'></span> Add All Bundle/s
            </a>
        </li>
        <li class="submenu-title" *ngIf="currentType.toString() !== '10'">
            <h4>Excel</h4>
        </li>
        <li [routerLinkActive]="['link-active']" *ngIf="currentType.toString() !== '10'">
            <a class='menu-callback' target="_blank" (click)="outputDownload.emit(this.currentType)" title="Download">
                <span class='glyphicon glyphicon-download'></span> Download
            </a>
        </li>
        <li [routerLinkActive]="['link-active']" *ngIf="!defaults.isReadonlyUser && currentType.toString() !== '10'">
            <a class='menu-callback' title="Upload" (click)="onUpload()">
                <span class='glyphicon glyphicon-upload'></span> Upload
            </a>
        </li>
        <ng-container *ngIf="defaults.stage !== 2 && currentType.toString() !== '10' && (defaults.stage !== 3)">
            <li class="separator"></li>
            <li class="submenu-title">
                <h4>Web Services</h4>
            </li>
            <li [routerLinkActive]="['link-active']" *ngIf="!defaults.isReadonlyUser">
                <a class='menu-callback' title="" (click)="openAzureDataFactoryTriggerModal(false)">
                    <span class='glyphicon glyphicon-play'></span> Trigger
                </a>
            </li>
            <li [routerLinkActive]="['link-active']" (click)="showLastPipelineRuns()">
                <a class='menu-callback' title="">
                    <span class='glyphicon glyphicon-list'></span> Last Runs
                </a>
            </li>
            <ng-container *ngIf="!defaults.isReadonlyUser">
                <li class="separator"></li>
                <li class="submenu-title">
                    <h4>Customer Portal</h4>
                </li>
                <li [routerLinkActive]="['link-active']" (click)="openAzureDataFactoryTriggerModal(true)">
                    <a class='menu-callback' title="">
                        <span class='glyphicon glyphicon-upload'></span> Push Changes
                    </a>
                </li>
            </ng-container>
        </ng-container>
        <li class="separator" [ngClass]="saveService.classSaveButton" *ngIf="currentType.toString() !== '10'"></li>
        <li [routerLinkActive]="['link-active']" [ngClass]="saveService.classSaveButton" *ngIf="currentType.toString() !== '10'">
            <a class='menu-callback' (click)="saveService.fireSave()" title="Save">
                <span class='glyphicon glyphicon-floppy-disk'></span> Save
            </a>
        </li>
    </ul>
</div>