<div class="multiselect">
    <div class="selectBox" (click)="showCheckboxes($event)">
        <select [ngClass]="getClassName()" title="Search by {{header.label}}">
            <option>{{header.label}}</option>
        </select>
        <div class="overSelect"></div>
    </div>

    <div class="checkboxes-selected" *ngIf="header.expanded">
        <div *ngFor="let l of selectable; index as i">
            <label for="{{i}}">
                <input type="checkbox" checked (change)="onRemoveFilter($event, l, i)" />{{selectable[i].show}}
            </label>
        </div>
    </div>

    <div class="checkboxes-empty" *ngIf="header.expanded">
        <input *ngIf="header.searchtype != 'checkbox'" class="textbox-search" type="{{header.searchtype}}" (keyup)="onChange($event)" (change)="onChange($event)" />

        <div *ngFor="let l of toSelectable; index as i">
            <label for="{{i}}">
                <input type="checkbox" (change)="onSelectToFilter($event, l, i)" />{{toSelectable[i].show}}
            </label>
        </div>
    </div>

</div>