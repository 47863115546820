import { Component, Inject, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DefaultValuesService } from "../../services/defaultvalues.service";
import { Utils } from "../../utils/utils";
import { ProvisioningTemplateComponent } from "./provisioningratemplate/provisioningratemplate.component";
import { AllowedActionControllerTypeComponent } from "./allowedactionscontrollertype/allowedactionscontrollertype.component";
import { MatTabGroup } from "@angular/material/tabs";

@Component({
    selector: "remoteactions",
    templateUrl: './remoteactions.component.html',
    styleUrls: ['../../style/container-tab-list.component.less', './remoteactions.component.less']
})

export class RemoteActionsComponent implements OnInit {

    commandList: Command[] = [];
    @ViewChild('provisioningTemplateComponent', { static: true }) provisioningTemplateComponent!: ProvisioningTemplateComponent;
    @ViewChild('allowedActionsControllerTypeComponent', { static: true }) allowedActionsControllerTypeComponent!: AllowedActionControllerTypeComponent;
    @ViewChild('tabGroup') tabGroup!: MatTabGroup;

    constructor(
        private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , public defaults: DefaultValuesService

    ) {
    }

    ngOnInit(): void {
        this.getCommandsByTenant();
    }

    ngAfterViewInit() {
        this.defaults.defaultComponentTab(this)
    }

    onReload() {
        this.getCommandsByTenant();
    }

    onReloadCommandTemplate() {
        this.provisioningTemplateComponent.getCommandConfigTemplate();
    }

    public showTabsByAllowedActionCT(command: string): boolean {
        if (this.allowedActionsControllerTypeComponent) {
            const exists = this.allowedActionsControllerTypeComponent.mergedList.find(x => x.CommandName === command)
            if (exists && exists.IsSupported === true)
                return true;
            else
                return false;
        }
        return false;
    }

    getCommandsByTenant() {
        Utils.httpGetCommands(
            this.http
            , this.baseUrl
            , this
            , function (tthis: RemoteActionsComponent, list: Command[]) {
                tthis.commandList = list;
            }
        );
    }

    isEditable(category: string): boolean {
        return this.defaults.isadminuser === true ||
            (category?.toLocaleUpperCase() !== 'MAX DEVICE' &&
                category?.toLocaleUpperCase() !== 'DES' &&
                category?.toLocaleUpperCase() !== 'XPU')
    }
}

