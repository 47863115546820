import { Component, OnInit, Inject } from '@angular/core';
import "rxjs/operators";
import { FormBuilder, FormGroup } from '@angular/forms';
import { DialogService } from '../../../../services/dialog.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';


@Component({
    selector: 'filterpopup',
    templateUrl: './filterpopup.component.html',
    styleUrls: ['../../../../style/custom-mat-dialog.component.less',]
})


export class FilterPopupComponent implements OnInit {

    form!: FormGroup;
    submitted = false;

    selectors: any[] = [{ 'label': 'First level', 'value': '0' },
    { 'label': 'All levels', 'value': '1' }
    ];

    selectorFilter = '0';
    inclusiveMode = false;

    constructor(private confimDialog: DialogService
        , @Inject(MAT_DIALOG_DATA) public data: any
        , public dialogRef: MatDialogRef<FilterPopupComponent>
        , private formBuilder: FormBuilder
        , private router: Router
    ) {

        this.confimDialog.tthis = this;
    }

    ngOnInit(): void {
        this.selectorFilter = this.data.selectorFilter
        this.inclusiveMode = this.data.inclusiveMode
        this.form = this.formBuilder.group({
            selectorFilter: [this.selectorFilter],
            inclusiveMode: [this.inclusiveMode]
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSelectorChange($event: string) {
        this.selectorFilter = $event
    }

    onSubmit() {
        this.submitted = true;
        this.dialogRef.close(this.form.value)
    }

    onBack() { }

    onNoClick(): void {
        this.dialogRef.close();
    }
}


