import { Component, Output, Inject, EventEmitter, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Utils } from '../../utils/utils';
import { DefaultValuesService } from '../../services/defaultvalues.service';
import { DialogService } from '../../services/dialog.service';
import { SaveService } from '../../services/save.service';
import { FilterService } from '../../services/filter.service';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';     

@Component({
    selector: 'controller-type',
    template: `{{isDigitalServiceView === true ? 'Digital Services' : isDigitalServiceView === false ? 'Controller Type' : 'Cont. Type / Dig. Service' }}:<br />
                <ng-select (change)="onSelect($event)" style="width:280px; border: 1px white solid" class="top-main-select"
                    [items]="filteredControllerTypes"
                   bindLabel="Description"
                   bindValue="ControllerTypeID"
                   [closeOnSelect]="true"
                   [searchable]="true"
                   [(ngModel)]="defaults.controllertype.ControllerTypeID">
                </ng-select>`,
    styleUrls: ['../../style/mat-select.component.less']
})
export class ControllerTypeComponent implements OnInit {

    @Input() isDigitalServiceView: boolean = false;
    @Output() controllerChanged = new EventEmitter<ControllerType>();

    value = "0";

    public filteredControllerTypes: ControllerType[] = [];

    protected _onDestroy = new Subject<void>();

    allCts: ControllerType[] = [];

    constructor(
        private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , public defaults: DefaultValuesService
        , private dialog: DialogService
        , public saveService: SaveService
        , private filterservice: FilterService
        , private routeService: Router
    ) {
    }

    ngOnInit() {
        this.dialog.tthis = this;
        this.defaults.languageMessageChanges$.subscribe(() => {
            this.allCts = this.defaults.controllertypes.concat(this.defaults.controllertypesdigitalservices)
            this.reloadFilteredControllerTypes()
        }
        );
    }

    ngOnChanges() {
        this.reloadFilteredControllerTypes()
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    reloadFilteredControllerTypes() {
        // In case of not being the controller types loaded 
        if (this.defaults.controllertypes.length <= 0)
            return;
        if (this.defaults.controllertype?.ControllerTypeID) {
            this.value = this.defaults.controllertype.ControllerTypeID.toString()
        }

        if (this.isDigitalServiceView === true) {
            this.filteredControllerTypes = this.defaults.controllertypesdigitalservices
            if (this.defaults.controllertypesdigitalservices.findIndex(x => this.defaults.controllertype.ControllerTypeID === x.ControllerTypeID) < 0) {
                this.changeCT()
            }
        }
        else if (this.isDigitalServiceView === false) {
            this.filteredControllerTypes = this.defaults.controllertypes
            if (this.defaults.controllertypes.findIndex(x => this.defaults.controllertype.ControllerTypeID === x.ControllerTypeID) < 0) {
                this.changeCT()
            }
        }
        else {
            if (this.defaults.controllertypesdigitalservices.length < 1 && this.defaults.controllertypes.length < 1) {
                this.routeService.navigate(['/']); // navigate to home page.
            }
            this.filteredControllerTypes = this.allCts
        }
    }

    protected filterControllerTypes() {
        if (!this.defaults.controllertypes && !this.defaults.controllertypesdigitalservices) {
            return;
        }
    }

    changeCT() {
        if (this.isDigitalServiceView === true) {
            if (this.defaults.controllertypesdigitalservices.length < 1) {
                this.filteredControllerTypes = this.defaults.controllertypes
                this.routeService.navigate(['/']); // navigate to home page.
                return;
            }
            this.filteredControllerTypes = this.defaults.controllertypesdigitalservices
            if (this.defaults.controllertypesdigitalservices.findIndex(x => x.ControllerTypeID.toString() === this.value) < 0) {
                this.defaults.controllertype = { ...this.filteredControllerTypes[0] };
            }
            else {
                this.defaults.controllertype = { ... this.filteredControllerTypes.filter(x => x.ControllerTypeID.toString() === this.value)[0] };
            }
        }
        else if (this.isDigitalServiceView === false) {
            this.filteredControllerTypes = this.defaults.controllertypes
            if (this.defaults.controllertypes.findIndex(x => x.ControllerTypeID.toString() === this.value) < 0) {
                this.defaults.controllertype = { ...this.filteredControllerTypes[0] };
            }
            else {
                this.defaults.controllertype = { ...this.defaults.controllertypes.filter(x => x.ControllerTypeID.toString() === this.value)[0] };
            }
        }
        else {
            if (this.defaults.controllertypesdigitalservices.length < 1 && this.defaults.controllertypes.length < 1) {
                this.routeService.navigate(['/']); // navigate to home page.
            }
            this.filteredControllerTypes = this.allCts
            this.defaults.controllertype = this.allCts.filter(x => x.ControllerTypeID.toString() === this.value)[0];
        }
        if (this.defaults.controllertype && this.defaults.controllertype.BK) {
            this.editControllerTypeSession();
        }
    }

    editControllerTypeSession() {
        const url = this.baseUrl + Utils.getControllerTypeAPI();
        this.http
            .post<ControllerType>(url, this.defaults.controllertype)
            .subscribe(res => {
                this.controllerChanged.emit(this.defaults.controllertype);
                this.defaults.onControllerTypeChanged.emit(this.defaults.controllertype);
                this.defaults.notifyControllerTypeChange(this.defaults.controllertype);
                this.refreshLanguages();
            }, error => Utils.writeLog(this.http, this.baseUrl, error));
    }

    onNo() {
        this.saveService.fireCancelAll();
        this.filterservice.setToInitiate(true);
        this.changeCT();
    }

    onSelect(ct: any) {
        if (!ct || !this.defaults.controllertypes)
            return;

        this.value = ct.ControllerTypeID.toString();
        if (this.saveService.isSaveButtonShown())
            this.dialog.showConfirmDialog("Do you want to save the changes? If you select No, all changes will be lost!", "Save", this);
        else
            this.onNo();
    }

    onYes() {
        this.saveService.fireSave();
        this.changeCT();
    }

    refreshLanguages() {
        /**
        * get all languages for this user/ct
        * */
        Utils.httpGetLanguageControllerTypeOperationMode(
            this.http
            , this.baseUrl
            , this
            , function (tthis: ControllerTypeComponent, list: Language[]) {
                if (list !== undefined) {
                    tthis.defaults.languages = list;

                    /**
                        * get selected language in server
                        * */
                    Utils.httpGetSelectedLanguage(
                        tthis.http
                        , tthis.baseUrl
                        , tthis
                        , function (tthis: ControllerTypeComponent, value: Language) {
                            tthis.defaults.language = value;
                            tthis.defaults.notifyLanguageChange(tthis.defaults.language);
                        }
                    );
                }
            });
    }
}