import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogService } from '../../../../../../services/dialog.service';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Utils } from '../../../../../../utils/utils';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'createcontact-popup',
    templateUrl: './createcontact-popup.component.html',
    styleUrls: ['./createcontact-popup.component.less']
})
export class CreateContactPopupComponent implements OnInit {     

    form!: FormGroup
    submitted = false;

    constructor(private http: HttpClient
        , private confimDialog: DialogService
        , private router: Router
        , @Inject(MAT_DIALOG_DATA) public data: any
        , @Inject('BASE_URL') private baseUrl: string
        , public dialogRef: MatDialogRef<CreateContactPopupComponent>
        , private formBuilder: FormBuilder
        , private toastrService: ToastrService

    ) {

        this.confimDialog.tthis = this;
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            contactName: [''],
            contactEmail: ['', [Validators.email]],
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.form ? this.form.controls : ''; }

    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.form)
            if (this.form.invalid) {
                return;
            }
        Utils.httpCreateContact(
            this.http
            , this.baseUrl
            , this.form.value
            , this
            , function (tthis: CreateContactPopupComponent, result: any) {
                tthis.onBack()
                tthis.dialogRef.close({ event: 'submit' });
                if (result)
                    tthis.toastrService.success('Contact was created successfully!');
                else
                    tthis.toastrService.error('Contact could not be created. Please, contact with administrator');
            },
            function (tthis: CreateContactPopupComponent) {
                tthis.toastrService.error('Contact could not be created. It already exists with same name or email.');
            },
        );
    }

    onBack() { }

    backButtonHandler(event: any) {
        event.preventDefault();
        this.dialogRef.close();

    }
}
