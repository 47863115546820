<div class="example-container-controllerharmonization">
    <button mat-icon-button (click)="btn_refresh()" matTooltip="Refresh the current table">
        <mat-icon>refresh</mat-icon>
    </button>

    <!--<button mat-icon-button (click)="btn_new()" matTooltip="New Row" >
        <mat-icon aria-label="Add new element to the current table">add_circle</mat-icon>
    </button>-->

    <table mat-table
           matSort
           [dataSource]="dataSource"
           class="mat-elevation-z8 mat-table-custom"
           id="controllertype-harmonization">

        <ng-container matColumnDef="ItemNo">
            <th mat-header-cell *matHeaderCellDef> Item no</th>
            <td mat-cell *matCellDef="let element;index as i;" >
                <p [innerHTML]="(i+1)"></p>
            </td>
        </ng-container>
        <ng-container matColumnDef="_ControllerTypeMappingID">
            <th mat-header-cell *matHeaderCellDef> MAPPING ID </th>
            <td mat-cell *matCellDef="let element" >
                <p [innerHTML]="element._ControllerTypeMappingID.toUpperCase()"></p>
            </td>
        </ng-container>
        <ng-container matColumnDef="controllertypelocal">
            <th mat-header-cell *matHeaderCellDef> Controller type (LOCAL NAME) </th>
            <td mat-cell *matCellDef="let element" >
                <p [innerHTML]="element.ControllerTypeLocal.toUpperCase()"></p>
            </td>
        </ng-container>
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> Harmonized value </th>
            <td mat-cell *matCellDef="let element;index as i;" >
                <p [innerHTML]="element.Description.toUpperCase()"></p>
            </td>
        </ng-container>
        <ng-container matColumnDef="controllertypebk">
            <th mat-header-cell *matHeaderCellDef> Controller type (BK) </th>
            <td mat-cell *matCellDef="let element;index as i;" >
                <p [innerHTML]="element.ControllerTypeBK.toUpperCase()"></p>
            </td>
        </ng-container>
        <ng-container matColumnDef="isNew">
            <th mat-header-cell *matHeaderCellDef>
                Harmonization Status<br />
                <mat-select id="DDFilter" class='form-control filter' placeholder='ALL' (selectionChange)="filterColumn($event, 'status')">
                    <mat-option [value]="0">ALL</mat-option>
                    <mat-option value="1">Pending</mat-option>
                    <mat-option value="2">Harmonized</mat-option>
                </mat-select>
            </th>
            <td class="statuscolumn" mat-cell *matCellDef="let element;index as i;" >
                <span *ngIf="element._ControllerTypeMappingID == -1">
                    <mat-icon>fiber_new</mat-icon>
                </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td class="actionscolumn th-left" mat-cell *matCellDef="let element">
                <button mat-icon-button matTooltip="More info"
                        (click)="onMoreInfo(element)">
                    <mat-icon>info</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Edit Row"
                        (click)="onRowEditInit(element)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button *ngIf="element._ControllerTypeMappingID != -1" mat-icon-button matTooltip="Delete harmonized correspondence"
                        (click)="onDelete(element)">
                    <mat-icon>delete</mat-icon>
                </button>
            </td>
        </ng-container>



        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; index as i;
            columns: columnsToDisplay;"
            class="opmode-item">
        </tr>
    </table>
</div>