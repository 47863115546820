
<table-fixed-header-search-collapse *ngIf="list"
                                    id-table="operationmode-opcounters-id"
                                    [header]="header"
                                    [data]="list"
                                    [getValue]="getData"
                                    [rowEditTemplate]="opmodeeditcounters"
                                    [selectable]="false">
</table-fixed-header-search-collapse>

<ng-template #opmodeeditcounters let-index="index">
    <operationmode-edit-opcounters [header]="header" [list]="list" [rowIndex]="index"></operationmode-edit-opcounters>
</ng-template>
