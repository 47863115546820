import { Component, Inject, Input, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Utils } from "../../utils/utils";
import { DialogService } from "../../services/dialog.service";
import { SaveService } from "../../services/save.service";
import { DefaultValuesService } from "../../services/defaultvalues.service";

@Component({
    selector: "maintenance-control-plan-group-action-component-error-list",
    templateUrl: './mcp-list-group-action-component-error.component.html',
    styleUrls: ['../../style/list.component.less', './mcp-list.component.less']
})

export class MaintenanceControlPlanGroupActionComponentErrorListComponent implements OnInit {
    @Input() component: MaintenanceControlPlanGroupActionComponent = <MaintenanceControlPlanGroupActionComponent>{};
    @Input() newService: SaveService | undefined;

    header: any[] = [
        { label: 'ControllerType', index: 0, show: true }
        , { label: 'Error Code', index: 1, show: true }
        , { label: 'Description', index: 2, show: true }
    ];

    constructor(
        private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , private dialog: DialogService
        , public defaults: DefaultValuesService
    ) {
    }

    /**
     * this is from who calls (inner component)
     * @param list
     * @param row
     * @param column
     */
    getData(list: MaintenanceControlPlanGroupActionComponentError[], row: number, column: number): string {
        if (list) {
            var e = list[row];
            switch (column) {
                case 0:
                    return e.ControllerType;                    
                case 1:
                    return e.ErrorCode.toString();                    
                default:
                    return e.ErrorDescription;
            }
        }
        else
            return "";
    }

    ngOnInit() {
        this.dialog.tthis = this;
    }

    onDelete(value: { index: number, value: MaintenanceControlPlanGroupActionComponentError }) {
        this.dialog.showConfirmDialog(value.value.ErrorDescription + " '" + value.value.ErrorCode + "' in " + value.value.Component + "?", "Delete Action Component Error", this, value);
    }

    onYes(value: { index: number, value: MaintenanceControlPlanGroupActionComponentError }) {
        this.http
            .delete<MaintenanceControlPlanGroupActionComponent>(
                this.baseUrl + Utils.getMaintenanceControlPlanAPI() + "groupactioncomponenterror/" + value.value.MCPActionID + "/" + value.value.ComponentItemID + "/" + value.value.ErrorCodeID
            )
            .subscribe(
                res => this.component.Errors.splice(value.index, 1)
                , error => this.dialog.showErrorDialog(error)
            );
    }

}