import { Component, Inject, Input, OnInit, Output, EventEmitter, OnDestroy } from "@angular/core";
import { DialogService } from "../../../services/dialog.service";
import { SaveService } from "../../../services/save.service";
import { MatDialog } from "@angular/material/dialog";
import { ControllerConfigDescriptionFormComponent } from "./controllerconfigdescription-form.component";
import { Utils } from "../../../utils/utils";
import { HttpClient } from "@angular/common/http";

@Component({
    selector: "controllerconfig-description-list",
    templateUrl: './controllerconfigdescription-list.component.html',
    styleUrls: ['../../../style/list.component.less', 'controllertypes-list.component.less']
})

export class ControllerConfigDescriptionListComponent implements OnInit {
    @Input('list') list: ControllerConfigDescription[] = [];

    header: any[] = [
        { label: 'Description', show: true }
    ];


    constructor(
        private http: HttpClient
        , private dialogService: DialogService
        , private saveService: SaveService
        , @Inject('BASE_URL') private baseUrl: string
        , private dialog: MatDialog
    ) {
    }

    ngOnInit() {
        this.refresh();
    }


    /**
     * called by the SaveService
     * */
    onCancel() {
        this.dialog.closeAll();
    }

    onNewForm() {
        /**
         * set the tthis on dialgService to the child forms call the getHeader
         **/
        this.dialogService.tthis = this;
        this.saveService.addListener(this);
        const dialogRef = this.dialog.open(ControllerConfigDescriptionFormComponent, { panelClass: 'custom-mat-dialog' });
        dialogRef.afterClosed().subscribe(result => {
            this.refresh();
            console.log(result);
        });

    }

    /**
     * called by the SaveService
     * */
    onSave() {
        this.dialog.closeAll();
        //this.refresh();
    }

    /**
     * this is from who calls (inner component)
     * @param list
     * @param row
     * @param column
     */
    getData(list: ControllerConfigDescription[], row: number, column: number): string {
        if (list) {
            let e = list[row];
            switch (column) {
                default:
                    return e.BK;
            }
        }
        else
            return "";
    }

    public refresh() {
        /**
         * load all controllerConfigs
         * */
        Utils.httpGetAllControllerConfigDescription(
            this.http
            , this.baseUrl
            , this
            , function (tthis: ControllerConfigDescriptionListComponent, values: ControllerConfigDescription[]) {
                tthis.list = values;
            }
        );
    }

}