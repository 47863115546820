import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Utils } from '../../../../utils/utils';
import { HttpClient } from '@angular/common/http';
import { DefaultValuesService } from '../../../../services/defaultvalues.service';
import { AssessmentListComponent } from './assessment-list/assessment-list.component';
import { Observable, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'assessment',
    templateUrl: './assessment.component.html',
    styleUrls: ['./assessment.component.less', '../../../../style/container-tab-list.component.less', '../../../../style/new-generic-styles.component.less']
})
export class AssessmentComponent implements OnInit, OnDestroy {

    @ViewChild(AssessmentListComponent) viewList: AssessmentListComponent = {} as AssessmentListComponent;
    private readonly _destroying$ = new Subject<void>();

    assessmentInfo: Assessment | undefined;
    stages: ExcellenceStage[] = [];
    contacts: BranchContact[] = [];
    subscriptionWaveId: any;
    subscriptions: any[] = [];

    label: string = '';
    waveId: number = -1;
    isEditModeEnabled: boolean = false;
    constructor(
        private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , public defaults: DefaultValuesService
        , private toastrService: ToastrService
        , private datePipe: DatePipe) { }

    ngOnInit(): void {
        this.getExcellenceStages();
        this.getBranchContacts();
        this.subscriptionWaveId = this.defaults.wave$.subscribe(
            (waveId: number | undefined) => {
                if (waveId)
                    this.waveId = waveId; this.loadInformation(this.waveId)
            })
    }

    // unsubscribe to events when component is destroyed
    ngOnDestroy(): void {
        this.subscriptionWaveId.unsubscribe();
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }


    loadInformation(waveId) {
        if (!waveId || waveId < 0) {
            this.label = `There is no assigned wave for ${this.defaults.managementid}`
            this.assessmentInfo = undefined
            return;
        }
        this.isEditModeEnabled = false;
        this.viewList.updatedItems = [];
        Utils.httpGetAssessmentInformation(
            this.http
            , this.baseUrl
            , waveId
            , this
            , function (tthis: AssessmentComponent, result: Assessment) {
                setTimeout(() => {
                    tthis.assessmentInfo = result
                    tthis.label = `Start Date [${tthis.datePipe.transform(tthis.assessmentInfo?.StartDate, 'yyyy-MM-dd')}] End Date [${tthis.datePipe.transform(tthis.assessmentInfo?.EndDate, 'yyyy-MM-dd')}]`
                }, 50)
            }
            , function (tthis: AssessmentComponent, error: string) {
                tthis.toastrService.error('Cannot load assessment information for selected wave - Field Branch. Please, contact with administrator');
            }
        )
    }

    getExcellenceStages() {
        Utils.httpGetExcellenceStages(
            this.http
            , this.baseUrl
            , this
            , function (tthis: AssessmentComponent, result: ExcellenceStage[]) {
                setTimeout(() => {
                    tthis.stages = result
                }, 50)
            }
            , function (tthis: AssessmentComponent, error: string) {
                tthis.assessmentInfo = undefined
            }
        );
    }
    getBranchContacts() {
        Utils.httpGetFieldBranchContacts(
            this.http,
            this.baseUrl,
            this,
            function (tthis: AssessmentComponent, list: any) {
                tthis.contacts = list
            },
            null

        )
    }

    onClickSave() {
        // Getting all factors
        const data = (this.viewList.dataSource.data as AssessmentExcellenceLever[])
        const updatedLevers: any[] = [];
        data.forEach(x => {
            if (this.viewList.updatedItems.includes(x.WaveExcellenceLeverConfigId)) {
                updatedLevers.push(x)
            }
        })

        Utils.httpPostAssessmentInformation(
            this.http,
            this.baseUrl,
            updatedLevers,
            this,
            function (tthis: AssessmentComponent, result: any) {
                if (result) {
                    tthis.toastrService.success('Assessment information was saved successfully!');
                    tthis.loadInformation(tthis.waveId)
                }
                else
                    tthis.toastrService.error('Assessment information could not be saved. Please, contact with administrator');
            },
            null
        )
    }

    onClickEdit() {
        if (this.isEditModeEnabled === true) {
            this.isEditModeEnabled = false
            if (this.viewList.updatedItems.length > 0) {
                this.loadInformation(this.waveId)
            }
        }
        else {
            this.isEditModeEnabled = true
        }
    }
}
