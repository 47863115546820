import { Component, Inject, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from "@angular/router";
import { Utils } from '../../utils/utils';
import { DefaultValuesService } from '../../services/defaultvalues.service';
import { DialogService } from '../../services/dialog.service';
import { SaveService } from '../../services/save.service';

@Component({
    selector: 'maintenance-control-plan-group-action-menu',
    templateUrl: './mcp-menu-group-action.component.html',
    styleUrls: ['../../style/menu.component.less']
})

export class MaintenanceControlPlanGroupActionMenuComponent implements OnInit {
    title: string;
    @Input() selected: MaintenanceControlPlanGroup = <MaintenanceControlPlanGroup>{};

    @Output() newEvent = new EventEmitter<{}>();

    constructor(
        private router: Router
        , @Inject('BASE_URL') private baseUrl: string
        , public saveService: SaveService
        , private dialog: DialogService
        , public defaults: DefaultValuesService
    ) {
        this.title = "Maintenance Control Plan Group Action";
    }

    ngOnInit() {
        this.dialog.tthis = this;
    }

    onBack() {
        this.router.navigate(["maintenancecontrolplangroup", this.selected.MCPID]);
    }

    onNewProposedErrors() {
        this.router.navigate(["maintenancecontrolplangroupactioncomponentsproposederrors", this.selected.MCPGroupID]);
    }

}
