<div class="custom-mat-dialog-form">
    <div>
        <h3>Following customer ids will be sent to Data Factory. Do you want to trigger pipeline? </h3>
    </div>
    <div>
        <div >
            <table mat-table
                   [dataSource]="dataSource"
                   multiTemplateDataRows
                   class="mat-elevation-z8 mat-table-custom">

                <ng-container matColumnDef="customerid">
                    <th mat-header-cell *matHeaderCellDef> Customer Id </th>
                    <td mat-cell *matCellDef="let element">
                        <p [innerHTML]="element"></p>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                <tr mat-row *matRowDef="let element; let i = dataIndex;columns: columnsToDisplay;" class="opmode-item">
                </tr>
            </table>
            <div class="mt-2 right">
                <button type="button"
                        class="btn btn-danger"
                        (click)="onNoClick()">
                    No
                </button>
                <button type="button"
                        class="btn btn-primary"
                        (click)="onSubmit()">
                    Yes
                </button>
            </div>
        </div>
    </div>
</div>
