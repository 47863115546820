<div class="custom-mat-dialog-form">
        <div>
            <div>
                <span style="font-size:larger">
                    New KPI creation<br /><br />
                </span>
                <!--<span style="font-size:larger">MersyID </span> {{ MersyId }}<br />
                <span style="font-size:larger">Kpi Name </span>{{ KpiName }}
                <br />
                <br />-->
                <span>
                </span>
            </div>
        </div>
        <div>
            <div>
                Please select:<br />
                <div>
                    <mat-radio-group [(ngModel)]="OpcNew" (change)="KpiModeChange()">
                        <mat-radio-button value="0">Create a new KPI with mostly default values, GF "<u>{{ SelectedGF }}</u>" and empty breakdowns.</mat-radio-button><br />
                        <mat-radio-button value="1">Create a new KPI as a copy from [{{ KpiDefinitionId }}] "<u>{{ KpiName }}</u>" and GF "<u>{{ SelectedGF }}</u>" values and breakdowns.</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <br />
            <div>
                Please input MERSY ACCOUNT CODE and KPI NAME:<br />
                <div>
                    <span class="title">Account (MersyID)</span>
                    <div >
                        <input class="text_input" maxlength="50" #TxtMersyID style="width: 100%" [(ngModel)]="NEWMersyId" type="text" />
                    </div>
                    <br /><br />
                    <span class="title">Kpi Name</span>
                    <div >
                        <input class="text_input" maxlength="100" #TxtKpiName style="width: 100%" [(ngModel)]="NEWKpiName" type="text" />
                    </div>
                </div>
            </div>
            <br />
            <div class="mt-2 right">
                <button type="button"
                        class="btn btn-danger"
                        (click)="onNoClick()">
                    Cancel
                </button>
                <button type="button"
                        class="btn btn-primary"
                        (click)="onSubmit()">
                    CONFIRM
                </button>
            </div>
        </div>
</div>
