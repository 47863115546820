<form [formGroup]="form">
    <table>
        <tbody class="inner">
            <tr>
                <td>&nbsp;</td>
                <td>
                    <span>{{selected.OpStatusOutService}}<br /></span>
                    <input id="OpStatusOutService"
                           type="checkbox"
                           formControlName="OpStatusOutService"
                           title="{{header[OPSTATUSOUTSERVICE].label}}: {{header[OPSTATUSOUTSERVICE].title}}"
                           (change)="onOpStatusOutServiceChange()"
                           class="form-control" />
                    <button type="button" class="btn btn-default" title="Undo changes" [disabled]="isundodisabled[OPSTATUSOUTSERVICE] == true" (click)="onOpStatusOutServiceUndo()">
                        <span class='glyphicon glyphicon-arrow-left'></span>
                    </button>
                    <br />
                </td>
                <td>
                    <span>{{selected.OpHours}}<br /></span>
                    <input id="OpHours"
                           type="checkbox"
                           formControlName="OpHours"
                           title="{{header[OPHOURS].label}}: {{header[OPHOURS].title}}"
                           [ngClass]="classname[OPHOURS]"
                           (change)="onOpHoursChange()"
                           class="form-control" />
                    <button type="button" class="btn btn-default" title="Undo changes" [disabled]="isundodisabled[OPHOURS] == true" (click)="onOpHoursUndo()">
                        <span class='glyphicon glyphicon-arrow-left'></span>
                    </button>
                    <br />
                </td>
                <td>
                    <span>{{selected.BrkHours}}<br /></span>
                    <input id="BrkHours"
                           type="checkbox"
                           formControlName="BrkHours"
                           title="{{header[BRKHOURS].label}}: {{header[BRKHOURS].title}}"
                           [ngClass]="classname[BRKHOURS]"
                           (change)="onBrkHoursChange()"
                           class="form-control" />
                    <button type="button" class="btn btn-default" title="Undo changes" [disabled]="isundodisabled[BRKHOURS] == true" (click)="onBrkHoursUndo()">
                        <span class='glyphicon glyphicon-arrow-left'></span>
                    </button>
                    <br />
                </td>
                <td>
                    <span>{{selected.UnitAvailable}}<br /></span>
                    <input id="UnitAvailable"
                           type="checkbox"
                           formControlName="UnitAvailable"
                           title="{{header[UNITAVAILABLE].label}}: {{header[UNITAVAILABLE].title}}"
                           [ngClass]="classname[UNITAVAILABLE]"
                           (change)="onUnitAvailableChange()"
                           class="form-control" />
                    <button type="button" class="btn btn-default" title="Undo changes" [disabled]="isundodisabled[UNITAVAILABLE] == true" (click)="onUnitAvailableUndo()">
                        <span class='glyphicon glyphicon-arrow-left'></span>
                    </button>
                    <br />
                </td>
                <td>
                    <span>{{selected.UnitAvailable}}<br /></span>
                    <input id="SpecialTrips"
                           type="checkbox"
                           formControlName="SpecialTrips"
                           title="{{header[SPECIALTRIPS].label}}: {{header[SPECIALTRIPS].title}}"
                           [ngClass]="classname[SPECIALTRIPS]"
                           (change)="onSpecialTripsChange()"
                           class="form-control" />
                    <button type="button" class="btn btn-default" title="Undo changes" [disabled]="isundodisabled[SPECIALTRIPS] == true" (click)="onSpecialTripsUndo()">
                        <span class='glyphicon glyphicon-arrow-left'></span>
                    </button>
                    <br />
                </td>
                <td>
                    <button type="button"
                            class="btn btn-warning"
                            (click)="onRowCancel()">
                        Cancel
                    </button>
                </td>

            </tr>
        </tbody>
    </table>
</form>