import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from
    "@angular/forms"
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SpinnerOverlayService } from '../../../../services/spinner-overlay.service';
import { AddGroupDialogue } from '../../home/edituser/addgroupdialogue/addgroupdialogue.component';
import { UMPCategory, Utils } from '../../../../utils/utils';
import { DefaultValuesService } from '../../../../services/defaultvalues.service';





@Component({
    selector: "group-mersygroups",
    templateUrl: './mersygroups.component.html',
    styleUrls: ['./mersygroups.component.less'],
    encapsulation: ViewEncapsulation.None


})

export class GroupMersyGroups implements OnInit {
    public userName: String = '';
    userId = ''
    dataCopy: any;
    
    searchString: string = '';
    filteredMersyGroups: any[] = [];

    MersyGroups = [];
    Response_State: number = -2; //0 -> empty , 1 -> correct and not empty ,-1 -> error, -2 -> Calling API
    errorResponse: any;
    isCallSucceed: number = 0;

    dialogRef: MatDialogRef<AddGroupDialogue, any> | undefined

    AllAzDevProj = [];
    UserAzDevProj = [];
    RemainAzDevProj = [];


    

    constructor(
        private route: ActivatedRoute,
        private http: HttpClient,
        private toastrService: ToastrService,
        private dialogForm: MatDialog,
        private spinnerOverlayService: SpinnerOverlayService,
        private defaults: DefaultValuesService,

   
        @Inject('BASE_URL') private baseUrl: string
    ) {

        this.updateRolesResults();

        this.userId = this.route.snapshot.paramMap.get('userId') || '';
        

        
        this.funcGetMersyGroups();
        defaults.resetBulkGroupTrigger$.subscribe(() => this.resetData())

        
    }

    ngOnInit() {

    }
    async updateRolesResults() {
        this.filteredMersyGroups = this.searchByValue(this.MersyGroups, "persona", this.searchString);
    }

    searchByValue(items:any,filterProperty:string,query:string) {
        
        return items.filter(item => {
            if (query.trim() === '') {
                return true;
            } else {
                return item[filterProperty].toLowerCase().includes(query.trim().toLocaleLowerCase())
            }
        })   
    }
    
    funcGetMersyGroups() {
        this.Response_State = -2;
        Utils.httpGetMersyGroups(
            this.http
            , this.baseUrl
            , this.userId
            , this
            , function (tthis: GroupMersyGroups, data: any) {
                tthis.dataCopy = structuredClone(data)
                tthis.assignValues(data)
            }
        )
    }
    assignValues(data) {
        this.MersyGroups = data.personae
        this.filteredMersyGroups = this.MersyGroups

        if (this.MersyGroups?.length) {
            this.Response_State = 1;
        }
        else {
            this.Response_State = 0;

        }
    }
    resetData() {
        this.assignValues(structuredClone(this.dataCopy))
    }

    funcNotify(persona) {
        if (this.isCallSucceed === 1) {
            this.toastrService.success('Delete "' + persona + '"  request sent successfully !', 'Max Role Delete Request!');
        }
        else if (this.isCallSucceed === -1) {
            this.toastrService.error('Delete "' + persona + '"  request not sent successfully !', 'Max Role Delete Request!');
        }
    }
    itemSelected(event) {
        if (event.options[0]._selected) {
            this.onSelect(event.options[0]._value);
        } else {
            this.onDeselect(event.options[0]._value);
        }
    }

    onSelect(selectedItem) {
        this.defaults.addGroupListItem({ Personae: selectedItem.persona, Id: selectedItem.id, UmpCategory: UMPCategory.MersyGroupFunctions });

    }

    onDeselect(selectedItem) {
        this.defaults.removeGroupListItem(selectedItem.id);

    }

   
}