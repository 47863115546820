import { Component, Inject, Input, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { DialogService } from '../../../services/dialog.service';
import { SaveService } from '../../../services/save.service';
import { Utils } from '../../../utils/utils';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DefaultValuesService } from "../../../services/defaultvalues.service";

@Component({
    selector: "fingerprintrules-list",
    templateUrl: './fingerprintrules-list.component.html',
    styleUrls: ['fingerprintrules-list.component.less'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*', })),
            transition('expanded => collapsed', [ animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)') ]),
        ]),
    ]
})

export class FingerprintRulesListComponent implements OnInit {
    @Input() list: TenantControllerTypeFingerprintRule[] = [];
    @Input() equals: any | undefined;

    public show = false;

    columnsToDisplay = ['arrow', 'tenant', 'rule'];
    columnsToDisplay2 = ['controllertype', 'isstableenabled', 'isevalenabled', 'isprodenabled', 'stableratiovalue', 'evalratiovalue', 'prodratiovalue', 'actions'];

    @ViewChild(MatTable, { static: true }) table!: MatTable<any>;

    public dataSource = new MatTableDataSource<TenantControllerTypeFingerprintRule>();
    selected: TenantControllerTypeFingerprintRule | undefined;
    expanded: boolean[] = [];
    expandedElements = new Set();
    public clonedList: { [s: string]: ControllerTypeFingerprintRule; } = {};

    alertRules: TenantControllerTypeFingerprintRule[] = [];

    subscriptionLanguage: any;
    subscriptionTenant: any;

    showCheckboxes = false;

    constructor(private activatedRoute: ActivatedRoute
        , private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , private dialog: DialogService
        , private saveService: SaveService
        , private defaults: DefaultValuesService

    ) {
    }

    ngOnChanges() {
        this.dataSource.data = this.list
        this.saveService.addListener(this)
    }

    ngOnInit() {
        this.saveService.addListener(this);
        this.dialog.tthis = this;
        this.getHeader()
        this.subscriptionLanguage = this.defaults.languageMessageChanges$.subscribe(() => {
            this.getHeader()
        });
        this.subscriptionTenant = this.defaults.tenantMessageChanges$.subscribe(() => {
            this.getHeader()
        });
    }

    getHeader() {
        if (!this.defaults.isReadonlyUser)
            this.columnsToDisplay2 = ['controllertype', 'isstableenabled', 'isevalenabled', 'isprodenabled', 'stableratiovalue', 'evalratiovalue', 'prodratiovalue', 'actions'];
        else
            this.columnsToDisplay2 = ['controllertype', 'isstableenabled', 'isevalenabled', 'isprodenabled', 'stableratiovalue', 'evalratiovalue', 'prodratiovalue'];
    }

    checkExpanded(element: TenantControllerTypeFingerprintRule) {
        return this.expandedElements.has(element);
    }

    pushPopElement(element: TenantControllerTypeFingerprintRule) {
        if (this.expandedElements.has(element)) {
            this.expandedElements.delete(element);
        }
        else {
            this.expandedElements.add(element);
        }
    }

    onRowEditInit(fingerprintRule: ControllerTypeFingerprintRule) {
        this.saveService.addListener(this);
        this.clonedList[this.getDictionaryHash(fingerprintRule)] = { ...fingerprintRule };
        if (Object.keys(this.clonedList).length !== 0) this.onShowSavebutton()
    }

    onApplyToAllCts(fingerprintRule: ControllerTypeFingerprintRule) {
        this.saveService.addListener(this);
        let rules = this.dataSource.data.filter(x => x.Tenant === fingerprintRule.Tenant && x.RuleCatId === fingerprintRule.RuleCatId)[0]
        rules.ControllerTypeFingerprintRules
            .filter(z => z.ControllerTypeId != fingerprintRule.ControllerTypeId)
            .forEach(x => {
                this.clonedList[this.getDictionaryHash(x)] = { ...x };
                x.IsEvalEnabled = fingerprintRule.IsEvalEnabled
                x.IsStableEnabled = fingerprintRule.IsStableEnabled
                x.IsProdEnabled = fingerprintRule.IsProdEnabled
                x.StableRatioValue = fingerprintRule.StableRatioValue
                x.EvalRatioValue = fingerprintRule.EvalRatioValue
                x.ProdRatioValue = fingerprintRule.ProdRatioValue
            })
        //this.table.renderRows();
        if (Object.keys(this.clonedList).length !== 0) this.onShowSavebutton()
    }

    isEditingRow(element: ControllerTypeFingerprintRule) {
        return this.clonedList[element.Tenant + element.RuleCatId + element.ControllerTypeBK]
    }

    onRowEditCancel(fingerprintRule: ControllerTypeFingerprintRule) {
        let rulesIndex = this.dataSource.data.findIndex(x => x.RuleCatId === fingerprintRule.RuleCatId && x.Tenant === fingerprintRule.Tenant)
        let subsetIndex = this.dataSource.data[rulesIndex].ControllerTypeFingerprintRules.findIndex(x => x.ControllerTypeBK == fingerprintRule.ControllerTypeBK)
        this.dataSource.data[rulesIndex].ControllerTypeFingerprintRules[subsetIndex].IsEvalEnabled = this.clonedList[this.getDictionaryHash(fingerprintRule)].IsEvalEnabled
        this.dataSource.data[rulesIndex].ControllerTypeFingerprintRules[subsetIndex].IsStableEnabled = this.clonedList[this.getDictionaryHash(fingerprintRule)].IsStableEnabled
        this.dataSource.data[rulesIndex].ControllerTypeFingerprintRules[subsetIndex].IsProdEnabled = this.clonedList[this.getDictionaryHash(fingerprintRule)].IsProdEnabled
        this.dataSource.data[rulesIndex].ControllerTypeFingerprintRules[subsetIndex].EvalRatioValue = this.clonedList[this.getDictionaryHash(fingerprintRule)].EvalRatioValue
        this.dataSource.data[rulesIndex].ControllerTypeFingerprintRules[subsetIndex].StableRatioValue = this.clonedList[this.getDictionaryHash(fingerprintRule)].StableRatioValue
        this.dataSource.data[rulesIndex].ControllerTypeFingerprintRules[subsetIndex].ProdRatioValue = this.clonedList[this.getDictionaryHash(fingerprintRule)].ProdRatioValue
        delete this.clonedList[this.getDictionaryHash(fingerprintRule)];

        if (Object.keys(this.clonedList).length === 0) this.saveService.showSaveButton(false)
        //this.table.renderRows();

    }

    onExpand(value: any) {
        this.selected = value.value;
    }

    onNew() {
        this.show = true
        this.saveService.addListener(this);
    }

    public onNewRowEvent() {
        this.table.renderRows();
        this.show = false
    }

    onShowSavebutton() {
        this.saveService.showSaveButton();
    }

    changeElement(fingerprintRule: ControllerTypeFingerprintRule, type: string, value: boolean | number | EventTarget) {
        let rulesIndex = this.dataSource.data.findIndex(x => x.RuleCatId === fingerprintRule.RuleCatId && x.Tenant === fingerprintRule.Tenant)
        let subsetIndex = this.dataSource.data[rulesIndex].ControllerTypeFingerprintRules.findIndex(x => x.ControllerTypeBK == fingerprintRule.ControllerTypeBK)
        if (typeof (value) === 'number') {
            if (value > 100)
                value = 100
            if (value < 0)
                value = 0
        }
        switch (type) {
            case 'stableenabled':
                this.dataSource.data[rulesIndex].ControllerTypeFingerprintRules[subsetIndex].IsStableEnabled = value as boolean;
                break;
            case 'evalenabled':
                this.dataSource.data[rulesIndex].ControllerTypeFingerprintRules[subsetIndex].IsEvalEnabled = value as boolean;
                break;
            case 'prodenabled':
                this.dataSource.data[rulesIndex].ControllerTypeFingerprintRules[subsetIndex].IsProdEnabled = value as boolean;
                break;
            case 'stableratiovalue':
                this.dataSource.data[rulesIndex].ControllerTypeFingerprintRules[subsetIndex].StableRatioValue = value as number;
                break;
            case 'evalratiovalue':
                this.dataSource.data[rulesIndex].ControllerTypeFingerprintRules[subsetIndex].EvalRatioValue = value as number;
                break;
            case 'prodratiovalue':
                this.dataSource.data[rulesIndex].ControllerTypeFingerprintRules[subsetIndex].ProdRatioValue = value as number;
                break;
        }
        this.table.renderRows();
    }

    onSave() {
        for (let i in this.clonedList) {
            let rulesIndex = this.dataSource.data.findIndex(x => x.RuleCatId === this.clonedList[i].RuleCatId && x.Tenant === this.clonedList[i].Tenant)
            let subsetIndex = this.dataSource.data[rulesIndex].ControllerTypeFingerprintRules.findIndex(x => x.ControllerTypeBK == this.clonedList[i].ControllerTypeBK)
            if (Object.entries(this.clonedList[this.getDictionaryHash(this.dataSource.data[rulesIndex].ControllerTypeFingerprintRules[subsetIndex])]).toString()
                !== Object.entries(this.dataSource.data[rulesIndex].ControllerTypeFingerprintRules[subsetIndex]).toString()) {
                this.http
                    .post<ControllerTypeFingerprintRule>(
                        this.baseUrl + Utils.getFingerprintAPI() + "edit?tenant=" + this.dataSource.data[rulesIndex].ControllerTypeFingerprintRules[subsetIndex].Tenant
                        + "&ruleCatID=" + this.dataSource.data[rulesIndex].ControllerTypeFingerprintRules[subsetIndex].RuleCatId
                        , this.dataSource.data[rulesIndex].ControllerTypeFingerprintRules[subsetIndex]
                    )
                    .subscribe(
                        res => {
                            delete this.clonedList[this.getDictionaryHash(this.dataSource.data[rulesIndex].ControllerTypeFingerprintRules[subsetIndex])];
                            this.table.renderRows();
                        }
                    );
            }
            else {
                delete this.clonedList[this.getDictionaryHash(this.dataSource.data[rulesIndex].ControllerTypeFingerprintRules[subsetIndex])];
            }
        }
        this.show = false
    }

    getDictionaryHash(rule: ControllerTypeFingerprintRule) {
        return rule.Tenant + rule.RuleCatId + rule.ControllerTypeBK
    }
}