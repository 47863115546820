import { Component, Inject, Input, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { MatChipInputEvent } from "@angular/material/chips";
import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';
import { SaveService } from '../../../../services/save.service';
import { Utils } from '../../../../utils/utils';
import { DefaultValuesService } from '../../../../services/defaultvalues.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DialogService } from "../../../../services/dialog.service";

@Component({
    selector: "facilitymanager-list",
    templateUrl: './facilitymanager-list.component.html',
    styleUrls: ['./facilitymanager-list.component.less', '../../../../style/mat-table.component.less'],

    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' })),
            transition('expanded => collapsed', [animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
        ]),
    ]
})

export class FacilityManagerListComponent implements OnInit {
    @Input('list') list: FacilityManager[] = [];
    @Input('showLocalFilter') showLocalFilter = false;

    columnsToDisplay = ['arrow-details', 'index', 'ContractNumber', 'FacilityManagerId', 'FacilityManagerSince', 'actions'];

    subcolumnsDetailsToDisplay = ['FacilityManagerName'
        , 'FacilityManagerAddress'
        , 'FacilityManagerZipCode'
        , 'FacilityManagerCity'
        , 'FacilityManagerVat'
        , 'FacilityManagerRegion'
        , 'FacilityManagerState'
        , 'FacilityManagerCountry'
        , 'FacilityManagerPhone'
        , 'IsDeleted'
        , 'FacilityManagerEmail'];

    booleanColumns = ['IsDeleted']
    dateColumns = ['_ChangedAtUtc', 'FacilityManagerSince']
    notEditableColumns = ['ContractNumber', 'FacilityManagerId', 'FacilityManagerSince', '_ChangedAtUtc']

    @ViewChild(MatTable, { static: true }) table!: MatTable<any>;

    public dataSource = new MatTableDataSource<FacilityManager>();
    selected: FacilityManager | undefined;
    expanded: boolean[] = [];
    expandedElements = new Set();
    expandedElementsAttrs = new Set();

    clonedList: { [s: string]: FacilityManager } = {};

    filter = '';

    subscriptionTenant: any;

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [COMMA, TAB, ENTER];
    searchItems: SearchItem[] = [];

    constructor(private activatedRoute: ActivatedRoute
        , private router: Router
        , private http: HttpClient
        , private saveService: SaveService
        , private defaults: DefaultValuesService
        , private confimDialog: DialogService
        , @Inject('BASE_URL') private baseUrl: string

    ) {
    }

    ngOnChanges() {
        this.dataSource.data = this.list
        this.clonedList = {}
        this.saveService.showSaveButton(false)
        this.expandedElements.clear();
        this.expandedElementsAttrs.clear();
        this.saveService.addListener(this)
    }

    ngOnInit() {
        this.getHeader();
        this.subscriptionTenant = this.defaults.tenantMessageChanges$.subscribe(() => {
            this.getHeader()
        });
        this.saveService.addListener(this);
        this.dataSource.filterPredicate = (data: FacilityManager, filters: string) => {
            const matchFilter: any[] = [];
            const filterArray = filters.split('+').filter(x => x.indexOf('notextfilters') < 0)
            if (filterArray.length > 0) {
                filterArray.forEach((filter) => {
                    let result = false;
                    this.columnsToDisplay.forEach((column) => {
                        const value = data[column]
                        if (value)
                            result = result || value.toString().toLowerCase().indexOf(filter.toLowerCase()) > -1
                    })
                    return matchFilter.push(result);
                });
            }
            else {
                let result = true;
                matchFilter.push(result);
            }
            return matchFilter.some(Boolean);
        };
    }

    getHeader() {
        if (!this.defaults.isReadonlyUser) {
            this.columnsToDisplay = ['arrow-details', 'index', 'ContractNumber', 'FacilityManagerId', 'FacilityManagerSince', '_ChangedAtUtc', 'actions'];
        }
        else {
            this.columnsToDisplay = ['arrow-details', 'index', 'ContractNumber', 'FacilityManagerId', 'FacilityManagerSince', '_ChangedAtUtc'];
        }
    }

    getInput(data: FacilityManager, property: string) {
        return data[property];
    }

    getDataType(property: string) {
        let result = ''
        if (this.booleanColumns.indexOf(property) > -1) result = 'boolean'
        else if (this.dateColumns.indexOf(property) > -1) result = 'date'
        else result = 'string'
        return result
    }

    changeElement(object: any, type: string, value: boolean) {
        object[type] = value;
        this.table.renderRows();
    }

    pushPopElement(element: FacilityManager) {
        if (this.expandedElements.has(element)) {
            this.expandedElements.delete(element);
        }
        else {
            this.expandedElements.add(element);
            this.onExpand(element)
        }
    }

    checkExpanded(element: FacilityManager, isAttr: boolean) {
        if (!isAttr)
            return this.expandedElements.has(element);
        else
            return this.expandedElementsAttrs.has(element);
    }

    onExpand(element: FacilityManager) {
        Utils.httpGetDetailedContractFacilityManager(
            this.http
            , this.baseUrl
            , this.defaults.stage
            , element
            , this
            , function (tthis: any, list: FacilityManagerDetails[]) {
                element.Details = list;
                tthis.expandedElements.add(element);
            }
        );
    }

    onRowEditInit(facilitymanager: FacilityManager) {
        this.saveService.addListener(this);
        this.clonedList[this.getFacilityManagerDictionaryHash(facilitymanager)] = { ...facilitymanager };
        if (Object.keys(this.clonedList).length !== 0) this.onShowSavebutton()
        this.onExpand(facilitymanager)
    }

    onRowEditCancel(facilitymanager: FacilityManager) {
        this.dataSource.data[this.dataSource.data.findIndex(x => x.ContractNumber === facilitymanager.ContractNumber && x.FacilityManagerId === facilitymanager.FacilityManagerId)] = this.clonedList[this.getFacilityManagerDictionaryHash(facilitymanager)];
        delete this.clonedList[this.getFacilityManagerDictionaryHash(facilitymanager)];
        if (Object.keys(this.clonedList).length === 0) this.saveService.showSaveButton(false)
        this.table.renderRows();
    }

    isEditing(facilitymanager: FacilityManager, property: string) {
        let result = true;
        if (property === null) {
            result = result && this.clonedList[this.getFacilityManagerDictionaryHash(facilitymanager)] !== undefined
        }
        else {
            result = result && this.clonedList[this.getFacilityManagerDictionaryHash(facilitymanager)] !== undefined && this.notEditableColumns.indexOf(property) < 0
        }
        return result;
    }

    onShowSavebutton() {
        this.saveService.showSaveButton();
    }

    onSave() {
        let updatedList: any[] = []
        for (let i in this.clonedList) {
            let index = this.dataSource.data.findIndex(x => x.ContractNumber === this.clonedList[i].ContractNumber && x.FacilityManagerId === this.clonedList[i].FacilityManagerId)
            var item = { ...this.dataSource.data[index].Details[0], ...this.dataSource.data[index] }
            updatedList.push(item)
        }
        if (updatedList.length > 0)
            Utils.httpUpdateContractFacilityManagers(
                this.http
                , this.baseUrl
                , this.defaults.stage
                , updatedList
                , this
                , function (tthis: any, list: FacilityManager[]) {
                    tthis.clonedList = {}
                    tthis.saveService.showSaveButton(false)
                    tthis.expandedElements.clear();
                    tthis.table.renderRows();
                }, function (tthis: FacilityManagerListComponent, message: string) {
                    tthis.saveService.addListener(tthis)
                    tthis.saveService.showSaveButton(true)
                    tthis.confimDialog.showErrorDialog(message);
                }
            );
        updatedList = [];
    }

    applyFilter(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            this.searchItems.push({ name: value.replace('+', '').trim() });
        }
        // Reset the input value
        if (input) {
            input.value = '';
        }
        this.updatefilter()
    }

    remove(item: SearchItem): void {
        const index = this.searchItems.indexOf(item);
        if (index >= 0) {
            this.searchItems.splice(index, 1);
        }
        this.updatefilter()
    }
    updatefilter() {
        let filterString: string
        if (this.searchItems.length === 0) {
            this.dataSource.filter = 'notextfilters'
        }
        else {
            filterString = this.searchItems.map(e => { return e.name }).join('+') + '+checkboxes'
            this.dataSource.filter = filterString
        }
    }

    getFacilityManagerDictionaryHash(facilitymanager: FacilityManager) {
        return facilitymanager.ContractNumber + facilitymanager.FacilityManagerId
    }

}
export interface SearchItem {
    name: string;
}