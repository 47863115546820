<div class="custom-mat-dialog-form non-overflow">
    <div>
        <h3 *ngIf="!executed">Execute Test Case: {{this.testCase.Name}} ({{this.testCase.TestCaseID}})</h3>
        <h3 *ngIf="executed">Test Case: {{this.testCase.Name}} ({{this.testCase.TestCaseID}}) initialized successfully in {{body.UnitId}}</h3>
    </div>
    <div>
        <form [formGroup]="form" (ngSubmit)="onSubmit()"> 
            <div class="mt-2" *ngIf="!executed">
                <div class="" *ngIf="!executed">
                    <p class="p-darkgrey">** For security reasons, all simulations will be performed in already provisioned units in STABLE stage.</p>
                </div>
                <div class="input div-flex">
                    <mat-form-field class="search-input" *ngIf="showFilter || !deviceList">
                        <!--<mat-label>Search Device Id / Unit Id</mat-label>-->
                        <input placeholder="Device Id / Unit Id"
                               matInput
                               (keyup)="applyFilter($event)">
                    </mat-form-field>
                </div>
                <div class="input div-flex">
                    <mat-select class="form-control" formControlName="unitId"
                                panelClass="testcase-class"
                                [ngClass]="{ 'is-invalid': submitted && f['unitId'].errors }" readonly [(ngModel)]="unitId" *ngIf="deviceList">
                        <mat-option [value]="''">Select</mat-option>
                        <mat-option *ngFor="let device of deviceList" [value]="device.UnitID">
                            {{ device.DeviceId }} - {{ device.UnitID }} - {{device.ElevatorNickname}} - {{device.BuildingAddr1}} - {{device.BranchNumber}}
                        </mat-option>
                    </mat-select>
                </div>
                <div *ngIf="submitted && f['unitId'].errors" class="invalid-feedback">
                    <div class="error-div">Please select an unit in order to execute that Test Case</div>
                </div>
            </div>

            <div>
                <div class="mt-2 right">
                    <button type="button"
                            class="btn btn-danger"
                            (click)="onNoClick()">
                        Close
                    </button>
                    <button type="button"
                            class="btn btn-primary"
                            (click)="onSubmit()" *ngIf="!executed">
                        Send
                    </button>
                    <button type="button"
                            class="btn btn-primary"
                            (click)="openDscLink()" *ngIf="executed && defaults.stage === 0">
                        Go to DSC
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
