import { HttpClient } from "@angular/common/http";
import { Component, Inject, EventEmitter, Output, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { CountryBackendEntity } from "../../../interfaces/countrybackendentity";
import { DefaultValuesService } from "../../../services/defaultvalues.service";
import { DialogService } from '../../../services/dialog.service';
import { SaveService } from '../../../services/save.service';


@Component({
    selector: 'backendsetup-menu',
    templateUrl: './backendsetup-menu.component.html',
    styleUrls: ['../../../style/menu.component.less']
})

export class BackendSetupMenuComponent implements OnInit {

    DOC_URL = "https://tkebbxdev.visualstudio.com/E2E%20MAX/_wiki/wikis/E2E-MAX.wiki/1968/Country-Backend-Integration";

    constructor(private http: HttpClient
        , private activatedRoute: ActivatedRoute
        , private router: Router
        , @Inject('BASE_URL') private baseUrl: string
        , private dialogForm: MatDialog
        , public saveService: SaveService
        , private dialog: DialogService
        , public defaults: DefaultValuesService
    ) {
    }

    ngOnInit() {}

    openDocumentationModal() {
        window.open(this.DOC_URL, "_blank");

    }
}
