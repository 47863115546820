import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { SaveService } from "../../services/save.service";
import { DefaultValuesService } from "../../services/defaultvalues.service";
import { CustomDialogService } from "../../services/customdialog.service";
import { Utils } from "../../utils/utils";

@Component({
    selector: "fingerprintrules_controllerconfig-list",
    templateUrl: './fingerprintrules_controllerconfig-list.component.html',
    styleUrls: ['./fingerprintrules_controllerconfig-list.component.less'],
})

export class FingerprintRulesControllerConfigListComponent implements OnInit {
    @Input('list') list: any[] = [];
    @Output() onUpdateTable = new EventEmitter<{}>();

    Filter = 0;

    columnsToDisplay = ['Offset', 'Value', 'Mode', 'ChangedAtUtc', 'actions'];
    dateColumns = ['ChangedAtUtc'];

    @ViewChild(MatTable, { static: true }) table!: MatTable<any>;

    public dataSource = new MatTableDataSource<any>();

    subscriptionTenant: any;
    subscriptionControllerType: any;


    timeoutFunction: any;

    constructor(private activatedRoute: ActivatedRoute
        , private router: Router
        , private http: HttpClient
        , private saveService: SaveService
        , private defaults: DefaultValuesService
        , private customDialog: CustomDialogService
        , @Inject('BASE_URL') private baseUrl: string
        , private changeDetectorRefs: ChangeDetectorRef
    ) {
    }

    ngOnChanges() {
        this.dataSource.data = this.list
    }

    ngOnInit() {
        this.subscriptionTenant = this.defaults.languageMessageChanges$.subscribe(() => this.refresh());
        this.saveService.addListener(this);
        this.refresh();
    }


    private refresh() {
        const tthis = this;
        /**
         * 
            * load all the data
            * 
            * */
        if (this.defaults.isControllerOwner === true)
            this.columnsToDisplay = ['Offset', 'Value', 'Mode', 'ChangedAtUtc', 'actions'];
        else
            this.columnsToDisplay = ['Offset', 'Value', 'Mode', 'ChangedAtUtc', 'seeactions'];


        // this.onReload.emit();
    }

    onControllerChanged(ct: ControllerType) {
        this.refresh();
    }


    getInput(data: AllowedRemoteAction, property: string): any {
        return data[property];
    }

    getDataType(property: string) {
        let result = ''
        if (this.dateColumns.indexOf(property) > -1) result = 'date'
        else result = 'string'
        return result
    }


    applyFilter($event: any) {
        if (this.timeoutFunction) {
            clearTimeout(this.timeoutFunction);
            this.timeoutFunction = null;
        }
        this.timeoutFunction = setTimeout(() => {
            this.dataSource.filter = $event.target.value.trim()
        }, 300)
    }

    changeElement(object: any, value: boolean) {
        this.http
            .post<any>(
                this.baseUrl + Utils.getAPIFingerPrint() + 'editFPconfig' + '/' + object.Value + '/' + value, {}
            ).subscribe(() => {
                this.onUpdateTable.emit()
                console.log('edited');
            }, (error: {
                error: { message: any }
            }) => {
                this.customDialog.openDisplayInfoDialog({ title: 'Error', message: error.error.message });
            });
    }
}