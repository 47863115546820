import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DefaultValuesService } from '../../services/defaultvalues.service';
import { HttpClient } from "@angular/common/http";

@Component({
  selector: 'tdccomponents',
  templateUrl: './tdccomponents.component.html',
    styleUrls: ['./tdccomponents.component.less'],
})
export class TDCComponents implements OnInit {
    @Input() availableComponentFunctions: ComponentFunction[] = [];
    @Input() availableFamilyCodes: FamilyCode[] = [];
    constructor(private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , public defaults: DefaultValuesService) { }

  ngOnInit(): void {
  }

    loadCompFunctions(compFunctions: ComponentFunction[]) {
        this.availableComponentFunctions = compFunctions;
    }

    loadFamilyCodes(familyCodes: FamilyCode[]) {
        this.availableFamilyCodes = familyCodes;
    }
}
