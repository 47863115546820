<div class="custom-mat-dialog-form">
    <div>
        <h3>{{data.functionParameterId ? 'Update' : 'Create'}} argument for {{this.data.functionKey}} function</h3>
    </div>
    <div>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="mt-2" *ngIf="data.functionParameterId">
                <div class="input">
                    <p>Parameter Id : {{data.functionParameterId}}</p>

                    <!--<p type="text" class="form-control" [readonly]="true"
                    [(ngModel)]="data.functionParameterId" />-->
                </div>
            </div>
            <div class="mt-2">
                <label>Argument *</label>
                <div class="input">
                    <input type="text" class="form-control" formControlName="argument" [(ngModel)]="body.Argument" />
                    <div *ngIf="submitted && f['argument'].errors" class="invalid-feedback">
                        <div class="error-div">Argument must be populated</div>
                    </div>
                </div>
            </div>
            <div class="mt-2">
                <label>Display Name</label>
                <div class="input">
                    <input type="text" class="form-control" formControlName="displayName" [(ngModel)]="body.DisplayName" />
                </div>
            </div>
            <div class="mt-2 right">
                <button type="button"
                        class="btn btn-danger"
                        (click)="onNoClick()">
                    Close
                </button>
                <button type="button"
                        class="btn btn-primary"
                        (click)="onSubmit()">
                    Send
                </button>
            </div>
        </form>
    </div>
</div>