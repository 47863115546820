import { Component, Inject, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { SaveService } from '../../services/save.service';
import { DialogService } from '../../services/dialog.service';
import { CustomDialogService } from '../../services/customdialog.service';
import { DefaultValuesService } from '../../services/defaultvalues.service';
import { MatDialog } from "@angular/material/dialog";
import { MaintenanceWarningFormComponent } from './maintenancewarning-form.component';
import { Utils } from '../../utils/utils';

@Component({
    selector: 'maintenancewarning-menu',
    templateUrl: './maintenancewarning-menu.component.html',
    styleUrls: ['../../style/menu.component.less']
})

export class MaintenanceWarningMenuComponent {
    @Input('list') list: MaintenanceWarning[] = [];
    @Output() onRefresh = new EventEmitter<{}>();


    headerMainWarning = [
        { label: 'Type', show: true, searchtype: 'text' }
        , { label: 'Description', show: true, searchtype: 'text' }
    ];

    constructor(
        private router: Router
        , @Inject('BASE_URL') private baseUrl: string
        , public saveService: SaveService
        , private dialog: DialogService
        , private customDialog: CustomDialogService
        , private http: HttpClient
        , public defaults: DefaultValuesService
        , private matDialog: MatDialog

    ) {
    }

    onNewMaintenanceWarning() {
        this.dialog.tthis = this;
        this.saveService.addListener(this);
        const dialog = this.matDialog.open(MaintenanceWarningFormComponent, { panelClass: ['custom-mat-dialog'] });
        dialog.componentInstance.list = this.list
        dialog.componentInstance.header = this.headerMainWarning
        dialog.afterClosed().subscribe(result => {
            this.onRefresh.emit({});
        });
    }

    onDownload() {
        Utils.httpDownloadMaintenanceWarnings(
            this.http
            , this.baseUrl
            , this
            , null)
    }
}
