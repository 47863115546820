import { Component, Inject, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Utils } from "../../utils/utils";
import { DefaultValuesService } from "../../services/defaultvalues.service";
import { DialogService } from "../../services/dialog.service";
import { SaveService } from "../../services/save.service";

@Component({
    selector: "errorcode-detail-list-description",
    templateUrl: './errorcode-list-detail-description.component.html',
    styleUrls: ['../../style/list.component.less', './errorcode-list.component.less']
})

export class ErrorCodeDetailListDescriptionComponent {
    @Input() selected: ErrorCode = <ErrorCode>{};
    //@Input() list: ErrorCode = <ErrorCode>{};

    //list: ErrorCodeResolution[] = [];
    //@Input() newService: SaveService | undefined;

    header: any[] = [
        { label: 'Maintenance Failure Ref', show: true }
        , { label: 'Maintenance Failure', show: true }
        , { label: 'Troubleshooting Action Ref', show: true }
        , { label: 'Troubleshooting Action', show: true }
    ];
    

    //@Input('language') language: Language = <Language>{};

    constructor(
        private activatedRoute: ActivatedRoute
        , private router: Router
        , private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , public defaults: DefaultValuesService
    ) {
        //this.header = this.getHeader();
    }

    //getHeader(): any {
    //    //if (this.isToShowReference())
    //        return [
    //            { label: 'Maintenance Failure Ref' }
    //            , { label: 'Maintenance Failure' }
    //            , { label: 'Troubleshooting Action Ref' }
    //            , { label: 'Troubleshooting Action' }
    //        ];
    //    //else
    //    //    return [
    //    //        { label: 'Maintenance Failure' }
    //    //        , { label: 'Troubleshooting Action' }
    //    //    ];
    //}

    /**
     * this is from who calls (inner component)
     * @param list
     * @param row
     * @param column
     */
    getData(list: ErrorCodeResolution[], row: number, column: number): string {

        //<th * ngIf="isToShowReference()" > Maintenance Failure Ref < /th>     0
        //< th > Maintenance Failure < /th>                                     1   0
        //< th * ngIf="isToShowReference()" > TroubleShootingAction Ref < /th>  2
        //< th > TroubleShootingAction < /th>                                   3   1


        if (list) {
            var e = list[row];
            switch (column) {
                case 0:
                    //if (this.isToShowReference())
                        return e.MaintenanceFailure_Ref;
                    //return e.MaintenanceFailure;
                case 1:
                    //if (this.isToShowReference())
                        return e.MaintenanceFailure;
                    //return e.TroubleShootingAction;
                case 2:
                    //if (this.isToShowReference())
                        return e.TroubleShootingAction_Ref;
                    //return "";
                case 3:
                    return e.TroubleShootingAction;
                default:
                    return "";
            }
        }
        else
            return "";
    }

    //isToShowReference(): boolean {
    //    return this.defaults.language.LanguageId != Utils.getDefaultLaguangeID();
    //}

    notInView(row: number, column: number, data: ErrorCodeResolution[]): string {
        if (column == 0)
            return Utils.notTranslatedClass(data[row].MaintenanceFailure)
        else if (column == 1)
            return Utils.notTranslatedClass(data[row].TroubleShootingAction)
        return "";
    }

    //onEdit(value: ErrorCodeResolution) {
    //    //this.router.navigate(["errorcodedetail/translate", value.PossibleFailureID, value.ErrorCodeID]);
    //    this.router.navigate(["errorcodedetail/edit", value.ErrorCodeID, value.PossibleFailureID]);
    //}


    //onSelect(selected: ErrorCode) {
    //    this.selected = selected;
    //    //this.router.navigate(["testcase", this.selected.TestCaseID]);
    //}

}