<!-- Allen: Try with Angular Material Tab -->
<errorcode-menu (newEvent)="onNew()" (onReload)="onReload()"></errorcode-menu>
<div style="display:flow-root">
    <div style="float:left; width:40vw">
        <h2>{{title}} for {{defaults.controllertype?.Description}} in {{defaults.language.Name}}</h2>
    </div>
</div>
<mat-tab-group animationDuration="500ms" #errorcodeTabGroup (selectedTabChange)="tabClick($event);  defaults.updateComponentTab($event.tab.textLabel)" #tabGroup >
    <mat-tab label="Properties">
        <errorcode-list-properties [list]="errorcodes" [keywords]="keywords" [equals]="equals" 
                                   [newService]="newMode" (eventFilter)="onFilter($event)" 
                                   (checkedEvent)="onChecked($event)" (onUpdateTable)="onUpdateTable()" 
                                   [isDigitalServicesView]="isDigitalServicesView" 
                                   [openDeploymentInformationPopup]="openDeploymentInformationPopup"></errorcode-list-properties>
    </mat-tab>
    <mat-tab label="Description">
        <errorcode-list-description [list]="errorcodes" [keywords]="keywords" (eventFilter)="onFilter($event)" (onUpdateDescriptions)="onUpdateDescriptions()" [openDeploymentInformationPopup]="openDeploymentInformationPopup"></errorcode-list-description>
    </mat-tab>
    <mat-tab label="Not Mapped">
        <errorcode-list-not-mapped [list]="errorcodesnotmapped" (onCopy)="onCopyErrorCode($event)" (onRefresh)="getAllErrorCodesNotMapped()"></errorcode-list-not-mapped>
    </mat-tab>
    <mat-tab label="Virtual Coach Simulator">
        <errorcode-edit-virtualcoach [list]="errorcodes"></errorcode-edit-virtualcoach>
    </mat-tab>
    <mat-tab label="ML Suggestions" *ngIf="defaults.getIsControllerOwner() && !defaults.isReadonlyUser">
        <errorcode-list-mlsuggestions [list]="errorcodesml"></errorcode-list-mlsuggestions>
    </mat-tab>
    <mat-tab label="ML Suggestions History" *ngIf="defaults.getIsControllerOwner() && !defaults.isReadonlyUser">
        <errorcode-list-mlsuggestions-undo [list]="possiblefailuremlhistories"></errorcode-list-mlsuggestions-undo>
    </mat-tab>
</mat-tab-group>