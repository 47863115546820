import { Component, Inject, OnInit, OnDestroy, ViewChild, Input, AfterViewInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Utils } from "../../utils/utils";
import { DefaultValuesService } from "../../services/defaultvalues.service";
import { SaveService } from "../../services/save.service";
import { OperationModePropertiesListComponent } from "./operationmode-list-properties.component";
import { OperationModeDescriptionListComponent } from './operationmode-list-description.component';
import { ControllerTypesDeploymentStatusPopup } from "../field/controllertypes/controllertypes-deploymentstatus-popup.component";
import { MatDialog } from "@angular/material/dialog";
import { MatTabGroup } from "@angular/material/tabs";

@Component({
    selector: "operationmode",
    templateUrl: './operationmode.component.html',
    styleUrls: ['../../style/container-tab-list.component.less']
})

export class OperationModeComponent implements OnInit, OnDestroy {

    @Input('isDigitalServicesView') isDigitalServicesView = false;
    @ViewChild(OperationModePropertiesListComponent, { static: true }) properties!: OperationModePropertiesListComponent;
    @ViewChild(OperationModeDescriptionListComponent, { static: true }) descriptions!: OperationModeDescriptionListComponent;

    @ViewChild('tabGroup') tabGroup!: MatTabGroup;

    title = "Operation Modes";
    selected: OperationMode = {} as OperationMode;
    list: any[] = [];
    expandlist: any[] = [];

    subscriptionControllerType: any;
    subscriptionLanguage: any;

    newMode: SaveService = {} as SaveService;

    constructor(
        private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , public defaults: DefaultValuesService
        , private matDialog: MatDialog

    ) {

    }

    ngOnInit() {
        this.subscriptionLanguage = this.defaults.languageMessageChanges$.subscribe((item: Language) => { this.getAll() });
        this.newMode = new SaveService();
        this.getAll();
    }
    ngOnDestroy() {
        // this.subscriptionControllerType.unsubscribe();
        this.subscriptionLanguage.unsubscribe();
    }

    ngAfterViewInit() {
        console.log("changing the tab name ")
        this.defaults.defaultComponentTab(this)
    }

    equals(value: OperationMode, value2: OperationMode): boolean {
        return value.Value === value2.Value;
    }

    getAll() {
        Utils.httpGetAllOperationModes(
            this.http
            , this.baseUrl
            , this
            , function (tthis: any, list: OperationMode[]) {
                tthis.list = list;
            }
        );
        this.newMode.fireCancelAll();
    }

    onControllerChanged(ct: ControllerType) {
        this.getAll();
    }

    //onExpand(value: any) {
    //    let values = [];
    //    values[0] = value.Value;
    //    this.expandlist[value.Index] = { Description: values, Counters: values };
    //}

    onLanguageChanged(l: Language) {
        this.getAll();
    }

    onNew() {
        this.newMode.fireNew();
    }
    onUpdateDescriptions() {
        this.properties.updatefilter()
    }
    onUpdateTable() {
        this.descriptions.updatefilter()
    }

    onReload() {
        this.getAll()
    }

    openDeploymentInformationPopup() {
        this.matDialog.open(ControllerTypesDeploymentStatusPopup, {
            panelClass: 'custom-mat-dialog',
            data: { "component": 'opmodes' }
        });
    }
}