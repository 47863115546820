<div class="dialog">
    <div style="min-width:100%; height:30px">
        <button mat-icon-button class="right" [mat-dialog-close]="true" [disableRipple]="true">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div>
        <span class="material-icons">help</span>
        <span mat-dialog-title>{{ data.title }}</span>
    </div>


    <mat-dialog-content style="margin-bottom: 20px;">
        <p>{{ data.message }}</p>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-raised-button color="success" (click)="onYes()">Yes</button>
        <button mat-raised-button color="warn" (click)="onNo()">No</button>
    </mat-dialog-actions>
</div>

