import { Component, Inject, OnInit, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Utils } from "../../../utils/utils";
import { DefaultValuesService } from "../../../services/defaultvalues.service";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SaveService } from "../../../services/save.service";
import { DialogService } from "../../../services/dialog.service";

@Component({
    selector: "connectiontype-form",
    templateUrl: './connectiontype-form.component.html',
    styleUrls: ['../../../style/form.component.less']
})

export class ConnectionTypeFormComponent implements OnInit {

    title: string;

    predefinedItems: ControllerConfigPredefinedItemsDescription[] = [];
    selected: ControllerTypeToConnectionType = {} as ControllerTypeToConnectionType;

    newRow = true;
    form: FormGroup = new FormGroup({});

    constructor(
        private activatedRoute: ActivatedRoute
        , private dialog: DialogService
        , private router: Router
        , private defaults: DefaultValuesService
        , private http: HttpClient
        , private saveService: SaveService
        , @Inject(MAT_DIALOG_DATA) public data: ControllerConfig
        , @Inject('BASE_URL') private baseUrl: string)
    {

        this.title = "Create new Controller Type";
        this.createForm();
    }

    ngOnInit() {
        this.saveService.addListener(this);

        Utils.httpGetAllControllerConfigPredefinedItemsDescription(
            this.http
            , this.baseUrl
            , this.data.BK
            , this
            , function (tthis: ConnectionTypeFormComponent, list: ControllerConfigPredefinedItemsDescription[]) {
                tthis.predefinedItems = list;
            }
        );
    }

    onCancelForm() {
        this.saveService.fireCancel();
    }

    onSubmit() {
        if (this.form && this.form.valid && this.newRow) {
            this.createObject();
            const tthis = this;
            const url = this.baseUrl + Utils.getControllerTypeAPI() + "controllertypetoconnectiontype";
            this.http
                .put<ControllerTypeCreation>(url, this.selected)
                .subscribe(res => {
                        tthis.saveService.fireCancel();
                    }
                    , error => {
                        tthis.dialog.showErrorDialog(error);
                    }
                );
        }
    }

    private createForm() {
        this.form = new FormGroup({
            predefinedItem: new FormControl('', [Validators.required]),
        });
    }

    private createObject() {
        this.selected.ControllerConfigPredefinedItemsDescriptionID = this.form.controls['predefinedItem'].value;
        this.selected.ControllerConfigID = this.data.ControllerConfigID;
    }
}