import { Component, OnInit } from '@angular/core';
import { DefaultValuesService } from '../../../../services/defaultvalues.service';

@Component({
    selector: 'branchexcellenceadmin',
    templateUrl: './branchexcellenceadmin.component.html',
    styleUrls: ['./branchexcellenceadmin.component.less', '../../../../style/container-tab-list.component.less']
})
export class BranchExcellenceAdminComponent implements OnInit {

    selectedWave: Wave;
    constructor(
        public defaults: DefaultValuesService
    ) {
        this.selectedWave = {
            WaveId: -1,
            Name: '',
            ChangedBy: ''
        }
    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        this.defaults.defaultComponentTab(this)
    }

    onSelectedWave(wave: any) {
        this.selectedWave = wave
    }

}
