import { Component, Input } from '@angular/core';

@Component({
    selector: 'deployerrorcode-opmode'
    , templateUrl: './deployerrorcodesopmodes-form.component.html'
    , styleUrls: ['./deploy-form.less']
})
export class DeployErrorCodesOpModesFormComponent {

    @Input() deployment = {} as Deployment;

    title: string;

    constructor() {

        this.title = "Deployment of Error Codes and Operation Modes";
    }

    onErrorCodesChange(evt: any, index: number) {
        this.deployment.ControllerTypes[index].DeployErrorCodes = evt.checked;
    }

    onOpModesChange(evt: any, index: number) {
        this.deployment.ControllerTypes[index].DeployOpModes = evt.checked;
    }    
}


