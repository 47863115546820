import { Component, Inject, Input, OnInit, ViewChild, OnChanges, ViewEncapsulation } from "@angular/core";
import { DialogService } from "../../../services/dialog.service";
import { HttpClient } from "@angular/common/http";
import { DefaultValuesService } from "../../../services/defaultvalues.service";
import { CustomDialogService } from '../../../services/customdialog.service';
import { Router } from "@angular/router";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";

@Component({
    selector: "umphome-list",
    templateUrl: './umphome-list.component.html',
    styleUrls: ['./umphome-list.component.less'],
    encapsulation: ViewEncapsulation.None
})

export class UmpHomeListComponent implements OnInit, OnChanges {

    @Input('users') users: UserSummary[] = [];
    @Input('totalResultsCount') count: number = 0;

    columnsToDisplay = ['upn', 'displayname', 'tenant','active'];

    public dataSource = new MatTableDataSource<UserSummary>();
    subscriptionControllerType: any;

    @ViewChild(MatPaginator, { static: true }) matPaginator!: MatPaginator;       

    constructor(
        private http: HttpClient
        , private dialogService: DialogService
        , private defaults: DefaultValuesService
        , @Inject('BASE_URL') private baseUrl: string
        , private dialog: MatDialog
        , private dialogForm: MatDialog
        , private router: Router
        , private customDialog: CustomDialogService
    ) {

    }

    ngOnInit() {
        this.dialogService.tthis = this;
        //this.refresh();
        if (this.matPaginator) {
            this.dataSource.paginator = this.matPaginator;
        }

    }

    ngOnChanges() {
        this.dataSource.data = this.users
    }


    onRowEditInit(element) {

    }
    onMoreInfo(element) {

        this.customDialog.openDisplayInfoDialog({
            title: element.ControllerTypeLocal,
            message: 'More information related to this controller',
            caller: this
        });

    } 

    onEditUser(userId: string) {
        this.router.navigate(['/EditUser', userId])
    }
}