import { Component, Inject, Input, OnInit, OnChanges, EventEmitter, Output } from "@angular/core";
import { DialogService } from "../../../../services/dialog.service";
import { DefaultValuesService } from "../../../../services/defaultvalues.service";
import { MatDialog } from "@angular/material/dialog";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { KpiDetailFormComponent } from "../kpidetail-form.component";
import { MatTableDataSource } from "@angular/material/table";
import { OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { Utils } from "../../../../utils/utils";
import { SelectionModel } from "@angular/cdk/collections";




@Component({
    selector: "oplmonthlykpimanagement-list",
    templateUrl: './oplmonthlykpimanagement-list.component.html',
    styleUrls: ['./oplmonthlykpimanagement-list.component.less',
                '../../../../style/new-generic-styles.component.less'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*', })),
            transition('expanded => collapsed', [animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
        ]),
    ]

})

export class OPLMonthlyKPIManagementListComponent implements OnInit, OnChanges, OnDestroy {
    @Input('list') list: KpiManagement[] = [];
    @Input('filter') filter: string = ''
    @Input('inclusiveMode') inclusiveMode: boolean = false
    @Input('selectorFilter') selectorFilter: string = ''

    @Input('isEditModeEnabled') isEditModeEnabled: boolean = false;
    @Input('isApprovalModeEnabled') isApprovalModeEnabled: boolean = false;
    @Input('auxFilterPredicate') auxFilterPredicate!: (filters: string, data: any, parentLevel: boolean, showAllEnabled: boolean) => boolean;
    @Input('isShowAllEnabled') isShowAllEnabled: boolean = false;


    @Input('isMonthlyRevokeActive') isRevokeActive: boolean = false;
    @Input('isMonthlyReleaseActive') isReleaseActive: boolean = false;
    @Output() onClickReleaseMode: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onClickRevokeMode: EventEmitter<boolean> = new EventEmitter<boolean>();

    FY1: string | undefined;
    currentFY: string | undefined;

    columnsToDisplay = ['arrow', 'Account', 'KpiName', 'KpiOwner', 'KpiUnit', 'LocalCurrency', 'AvailabilityTime', 'ReportingType', 'actions'];
    columnsToDisplay2 = ['ItemNo', 'COL1', 'COL2', 'COL3', 'COL4', 'ACTPY', 'ACTBUD', 'YTDACT', 'PYBUD', 'FY1BUD', 'P1', 'P2', 'P3', 'P4', 'P5', 'P6', 'P7', 'P8', 'P9', 'P10', 'P11', 'P12','actions']
    public dataSource: MatTableDataSource<KpiManagement>;

    expandedElements = new Set();
    isAllCollapsed = false;
    Filter = 0;

    updatedItems: KpiManagementUpdate[] = []
    clickedItems: string[] = [];


    releaseList: selectedReleaseRow[] = []
    selection = new SelectionModel<UserSummary>(true, []);
    protected _onDestroy = new Subject<void>();

    constructor(
        private dialogService: DialogService
        , @Inject('BASE_URL') private baseUrl: string
        , private dialogForm: MatDialog
        , private defaults: DefaultValuesService
    ) {
        this.dataSource = new MatTableDataSource<KpiManagement>();
        this.getFiscalYearHeaders()
    }
    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    ngOnChanges(changes: any) {
        if (changes.list) {
            this.getFiscalYearHeaders();
            this.dataSource.data = Utils.deepCopyArray(this.list)
            this.expandedElements.clear()
            this.updatedItems = [];
        }

        if (changes.filter || changes.inclusiveFilter || changes.selectorFilter) {
            this.dataSource.filter = this.filter;
        }

        if (changes.isApprovalModeEnabled) {
            this.expandedElements.clear()
            this.updatedItems = []
        }
    }

    ngOnInit() {
        this.dialogService.tthis = this;
        this.dataSource.filterPredicate = (data: KpiManagement, filters: string) => {
            if (this.selectorFilter === '0') {  // Just applying filter on first level
                if (data.subtable)
                    data.subtable.filter = this.filter
                return this.auxFilterPredicate(filters, data, true, this.isShowAllEnabled)
            }
            else {
                if (!data.subtable)
                    this.createMatSubtable(data)
                data.subtable.filter = this.filter
                if (data.subtable.filteredData.length > 0 && this.filter[0] !== '+')
                    this.expandElement(data)
                return this.auxFilterPredicate(filters, data, true, this.isShowAllEnabled) || data.subtable.filteredData.length > 0
            }
        };
        this.dataSource.data = Utils.deepCopyArray(this.list)
        this.dataSource.filter = this.filter;
    }

    checkExpanded(element) {
        return this.expandedElements.has(element.AccountID);
    }

    pushPopElement(element) {
        if (this.expandedElements.has(element.AccountID)) {
            this.expandedElements.delete(element.AccountID);
        }
        else {
            this.expandElement(element.AccountID)
            if (!element.subtable) {
                this.createMatSubtable(element)
                element.subtable.filter = this.filter
            }
        }
    }

    collapseOrExpandAll() {
        if (this.isAllCollapsed === false) {
            this.isAllCollapsed = true;
            this.dataSource.data.forEach((el: any) => {
                if (!this.expandedElements.has(el)) {
                    if (!el.subtable) {
                        this.createMatSubtable(el)
                    }
                    this.expandElement(el.AccountID)
                }
            })
        }
        else {
            this.isAllCollapsed = false;
            this.expandedElements.clear()
        }
    }

    private createMatSubtable(el: any) {
        let dataSource = new MatTableDataSource<any>();
        dataSource.data = el.KpiDetail
        dataSource.filterPredicate = (data2: any, filters: string) => {
            return this.auxFilterPredicate(filters, data2, false, this.isShowAllEnabled)
        }
        el.subtable = dataSource
    }

    private expandElement(el: any) {
        this.expandedElements.add(el);
    }

    onChange(account: any, breakdown: any, $e: any, field: string) {
        let field_value = $e !== null && $e !== undefined ? parseFloat($e).toFixed(account.DecimalDigits) : '0';

        /* Wait a sec. If we are editing a month in a YTD kpi defined BUDGET, value to update  
         * is the difference from the original to the new. */

        // We need to update both updated and next one in order to keep consistency between values
        let nextField = ''
        let nextFieldValue = ''

        if (account.ReportingType == "YTD" && field.startsWith("P")) {
            switch (field) {
                case "P1": {
                    field_value = field_value;
                    nextField = 'P2'
                    nextFieldValue = (breakdown.P2 - parseFloat(field_value)).toFixed(account.DecimalDigits);
                    break;
                }
                case "P2": {
                    field_value = (parseFloat(field_value) - breakdown.P1).toFixed(account.DecimalDigits); breakdown.P2 = this.getBreakdownPeriodValue(breakdown.P1, field_value);
                    nextField = 'P3'
                    nextFieldValue = (breakdown.P3 - breakdown.P2).toFixed(account.DecimalDigits); break;
                }
                case "P3": {
                    field_value = (parseFloat(field_value) - breakdown.P2).toFixed(account.DecimalDigits); breakdown.P3 = this.getBreakdownPeriodValue(breakdown.P2, field_value);
                    nextField = 'P4'
                    nextFieldValue = (breakdown.P4 - breakdown.P3).toFixed(account.DecimalDigits); break;
                }
                case "P4": {
                    field_value = (parseFloat(field_value) - breakdown.P3).toFixed(account.DecimalDigits); breakdown.P4 = this.getBreakdownPeriodValue(breakdown.P3, field_value);
                    nextField = 'P5'
                    nextFieldValue = (breakdown.P5 - breakdown.P4).toFixed(account.DecimalDigits); break;
                }
                case "P5": {
                    field_value = (parseFloat(field_value) - breakdown.P4).toFixed(account.DecimalDigits); breakdown.P5 = this.getBreakdownPeriodValue(breakdown.P4, field_value);
                    nextField = 'P6'
                    nextFieldValue = (breakdown.P6 - breakdown.P5).toFixed(account.DecimalDigits); break;
                }
                case "P6": {
                    field_value = (parseFloat(field_value) - breakdown.P5).toFixed(account.DecimalDigits); breakdown.P6 = this.getBreakdownPeriodValue(breakdown.P5, field_value);
                    nextField = 'P7'
                    nextFieldValue = (breakdown.P7 - breakdown.P6).toFixed(account.DecimalDigits); break;
                }
                case "P7": {
                    field_value = (parseFloat(field_value) - breakdown.P6).toFixed(account.DecimalDigits); breakdown.P7 = this.getBreakdownPeriodValue(breakdown.P6, field_value);
                    nextField = 'P8'
                    nextFieldValue = (breakdown.P8 - breakdown.P7).toFixed(account.DecimalDigits); break;
                }
                case "P8": {
                    field_value = (parseFloat(field_value) - breakdown.P7).toFixed(account.DecimalDigits); breakdown.P8 = this.getBreakdownPeriodValue(breakdown.P7, field_value);
                    nextField = 'P9'
                    nextFieldValue = (breakdown.P9 - breakdown.P8).toFixed(account.DecimalDigits); break;
                }
                case "P9": {
                    field_value = (parseFloat(field_value) - breakdown.P8).toFixed(account.DecimalDigits); breakdown.P9 = this.getBreakdownPeriodValue(breakdown.P8, field_value);
                    nextField = 'P10'
                    nextFieldValue = (breakdown.P10 - breakdown.P9).toFixed(account.DecimalDigits); break;
                }
                case "P10": {
                    field_value = (parseFloat(field_value) - breakdown.P9).toFixed(account.DecimalDigits); breakdown.P10 = this.getBreakdownPeriodValue(breakdown.P9, field_value);
                    nextField = 'P11'
                    nextFieldValue = (breakdown.P11 - breakdown.P10).toFixed(account.DecimalDigits); break;
                }
                case "P11": {
                    field_value = (parseFloat(field_value) - breakdown.P10).toFixed(account.DecimalDigits); breakdown.P11 = this.getBreakdownPeriodValue(breakdown.P10, field_value);
                    nextField = 'P12'
                    nextFieldValue = (breakdown.P12 - breakdown.P11).toFixed(account.DecimalDigits); break;
                }
                case "P12": { field_value = (parseFloat(field_value) - breakdown.P11).toFixed(account.DecimalDigits); breakdown.P12 = this.getBreakdownPeriodValue(breakdown.P11, field_value);; break; }
            }
        }
        let updatedValue: KpiManagementUpdate = {
            'KpiId': breakdown.KpiId,
            'KpiMovementTypeId': breakdown.KpiMovementTypeId,
            'KpiProductHierarchyId': breakdown.KpiProductHierarchyId,
            'KpiVersion': 'BUDGET',
            'KpiDefinitionId': account.KpiDefinitionId,
            'KpiBreakdown1Id': breakdown.KpiBreakdown1Id,
            'KpiBreakdown2Id': breakdown.KpiBreakdown2Id,
            'KpiValue': field_value,
            'Field': field,
            'FY1BUD': breakdown.FY1BUD,
            'ReportingType': account.ReportingType,
            'SumMonthlyValues': account.ReportingType === 'YTD' ? breakdown.P12 : this.sumAllMonthValues(breakdown),
            ApprovalStatus: breakdown.ApprovalStatus,
            prev_FYFC: 0,
            KpiUnit: account.KpiUnit,
            DecimalDigits: account.DecimalDigits,
            auxFYFC: '0',
            IsAccumulative: false,
            AccountId: account.AccountID
        }

        this.addUpdatedItem(updatedValue);
        if (nextField !== '') {
            let nextValue: KpiManagementUpdate = {
                'KpiId': breakdown.KpiId,
                'KpiMovementTypeId': breakdown.KpiMovementTypeId,
                'KpiProductHierarchyId': breakdown.KpiProductHierarchyId,
                'KpiVersion': 'BUDGET',
                'KpiDefinitionId': account.KpiDefinitionId,
                'KpiBreakdown1Id': breakdown.KpiBreakdown1Id,
                'KpiBreakdown2Id': breakdown.KpiBreakdown2Id,
                'KpiValue': nextFieldValue,
                'Field': nextField,
                'FY1BUD': breakdown.FY1BUD,
                'ReportingType': account.ReportingType,
                'SumMonthlyValues': account.ReportingType === 'YTD' ? breakdown.P12 : this.sumAllMonthValues(breakdown),
                ApprovalStatus: breakdown.ApprovalStatus,
                prev_FYFC: 0,
                KpiUnit: account.KpiUnit,
                DecimalDigits: account.DecimalDigits,
                auxFYFC: '0',
                IsAccumulative: false,
                AccountId: account.AccountID


            }
            this.addUpdatedItem(nextValue);
        }
    }

    private getBreakdownPeriodValue(previousBreakdownValue: string, field_value) {
        return Math.round((parseFloat(previousBreakdownValue) + parseFloat(field_value)) * 100) / 100 
    }

    private addUpdatedItem(newObject: KpiManagementUpdate) {
        const index = this.updatedItems.findIndex(
            (obj) =>
                obj.KpiDefinitionId === newObject.KpiDefinitionId &&
                obj.KpiBreakdown1Id === newObject.KpiBreakdown1Id &&
                obj.KpiBreakdown2Id === newObject.KpiBreakdown2Id &&
                obj.KpiId === newObject.KpiId &&
                obj.KpiVersion === newObject.KpiVersion &&
                obj.KpiMovementTypeId === newObject.KpiMovementTypeId &&
                obj.KpiProductHierarchyId === newObject.KpiProductHierarchyId &&
                obj.Field === newObject.Field
        );

        if (index !== -1) {
            this.updatedItems[index] = newObject;
        } else {
            this.updatedItems.push(newObject);
        }
    }

    private sumAllMonthValues(subelement: any): number {
        return parseFloat(subelement.P1) + parseFloat(subelement.P2) + parseFloat(subelement.P3) + parseFloat(subelement.P4) + parseFloat(subelement.P5)
            + parseFloat(subelement.P6) + parseFloat(subelement.P7) + parseFloat(subelement.P8) + parseFloat(subelement.P9) + parseFloat(subelement.P10)
            + parseFloat(subelement.P11) + parseFloat(subelement.P12)
    }


    formatCurrency(value, decimalDigits): string {
        if (value == undefined)
            return '';
        else
            return parseFloat(value).toLocaleString('de', { maximumFractionDigits: decimalDigits });
    }

    onClickItem(item: string) {
        this.clickedItems.push(item)
    }

    isItemClicked(item: any) {
        return this.clickedItems.findIndex(x => x === item.KpiDefinitionId + item.Granularity) >= 0
    }


    onDetail(element: any) {

        /*
        const Dialog = this.dialogForm.open(KpiDetailFormComponent, {
            panelClass: 'custom-mat-dialog',
            disableClose: false,
            data: { "KpiDefinitionId": element.KpiDefinitionId }
        });
        Dialog.afterClosed().subscribe(result => {
        });
*/
        var KpiId = "null";

        // Truko
        //KpiId = '206288';


        if (element.KpiId != undefined)
            KpiId = element.KpiId;

        const Dialog = this.dialogForm.open(KpiDetailFormComponent, {
            panelClass: 'custom-mat-dialog',
            disableClose: false,
            data: {
                "KpiDefinitionId": element.KpiDefinitionId,
                "KpiId": KpiId,
                "KpiBreakDown1Id": element.KpiBreakDown1Id,
                "KpiBreakDown2Id": element.KpiBreakDown2Id,
                "KpiMovementTypeId": element.KpiBreakDown2Id,
                "KpiPorductHierarchyId": element.KpiPorductHierarchyId,
                "DataType": 'BUDM'
            }
        });
        Dialog.afterClosed().subscribe(() => {
        });




    }

    private getFiscalYearHeaders() {
        this.FY1 = (this.defaults.fiscalYear).toString() + '-' + (this.defaults.fiscalYear + 1).toString();
        this.currentFY = (this.defaults.fiscalYear - 1).toString() + '-' + (this.defaults.fiscalYear).toString();
    }

    disableArrowKeys(event: KeyboardEvent) {
        if (
            event.key === 'ArrowUp' ||
            event.key === 'ArrowDown'
        ) {
            event.preventDefault();
        }
    }


    mapReleaseRow(row) {
        var selectedReleaseRow: selectedReleaseRow = {
            KpiDefinitionId: row.KpiDefinitionId,
            KpiMovementTypeId: row.KpiMovementTypeId,
            KpiProductHierarchyId: row.KpiProductHierarchyId,
            KpiBreakdown1Id: row.KpiBreakdown1Id,
            KpiBreakdown2Id: row.KpiBreakdown2Id

        };
        return selectedReleaseRow;
    }

    accountCheckboxCheck(row) {
        var Checked = row.KpiDetail.every((row) => {
            var mappedRow = this.mapReleaseRow(row);
            return this.releaseList.some((object) => this.objectsEqual(mappedRow, object));
        })
        return Checked
    }

    accountItemsIndetermine(row) {
        var Indeterminate = this.releaseList.some((selected) => (selected.KpiDefinitionId === row.KpiDefinitionId))
        return Indeterminate;
    }
    accountItemsDisable(row) {
        //console.log(row)
        var Disable = !row.KpiDetail.some((item) => {
            return (this.isReleaseActive ? (item.ApprovalStatus == "PRE-RELEASED") :
                (item.ApprovalStatus == "RELEASED"))
        }
        )
        return Disable;
    }

    accountItemsSelected(ischecked, row) {
        row.KpiDetail.forEach((item) => {
            this.isReleaseActive ? (item.ApprovalStatus == "PRE-RELEASED" ? this.expandedItemSelected(ischecked, item) : null) :
                (item.ApprovalStatus == "RELEASED" ? this.expandedItemSelected(ischecked, item) : null)
        });

    }
    expandedItemSelected(ischecked, row) {

        if ((this.isReleaseActive || this.isRevokeActive) && (this.isReleaseActive ? (row.ApprovalStatus == 'PRE-RELEASED') : (row.ApprovalStatus == 'RELEASED'))) {
        var selectedReleaseRow = this.mapReleaseRow(row);

            if (ischecked) {
                this.selection.select(row)
                this.onSelect(selectedReleaseRow);
            } else {
                this.selection.deselect(row)
                this.onDeselect(selectedReleaseRow);
            }
        }

    }
    onSelect(selectedReleaseRow) {
        //this.releaseList.push(selectedReleaseRow)

        //An extra layer of protection to not to add already selected rows (Implemented but not active)
        if (!this.releaseList.some((object) => this.objectsEqual(object, selectedReleaseRow))){
            this.releaseList.push(selectedReleaseRow)
        }

    }
    onDeselect(selectedReleaseRow) {
        var mappedRow = this.mapReleaseRow(selectedReleaseRow)
        const index = this.releaseList.findIndex((object) => this.objectsEqual(mappedRow, object));
        if (index !== -1) {
            this.releaseList.splice(index, 1);
        }
    }

    objectsEqual(object1, object2) {
        if (object1 === object2) {
            return true;
        }
        if (Object.keys(object1).length !== Object.keys(object2).length) {
            return false;
        }
        for (const property in object1) {
            if (object1[property] !== object2[property]) {
                return false;
            }
        }
        return true;
    }
    getSelectionList() {
        return this.releaseList;
    }
    clearSelectionList() {
        this.releaseList = [];
        this.selection?.clear();
    }
    onClickReleaseMode_emitter(yearly: boolean) {
        this.onClickReleaseMode.emit(yearly);
    }
    onClickRevokeMode_emitter(yearly: boolean) {
        this.onClickRevokeMode.emit(yearly);
    }
    anyActionableItemExist() {
        return this.isReleaseActive ?
            this.list.some((kpi) => kpi.KpiDetail.some((kpidef) => (kpidef.ApprovalStatus == "PRE-RELEASED"))) :
            this.list.some((kpi) => kpi.KpiDetail.some((kpidef) => (kpidef.ApprovalStatus == "RELEASED")))
    }
    textRelRevbuttonState() {
        return this.anyActionableItemExist() ?
            (((this.isReleaseActive || this.isRevokeActive && this.isReleaseActive) ? 'Release ' : 'Revoke ') + (!this.selection.hasValue() ? 'All' : 'Selected')) :
            (this.isReleaseActive ? 'Nothing to Release' : 'Nothing to Revoke')
    }

}
export interface SearchItem {
    name: string;
}
