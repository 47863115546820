<div class="HS DialerTable zoomIn long-header">
    <form [formGroup]="configForm" autocomplete="off" class="box weightageTable">
        <ng-container formArrayName="configRows" >
            <table mat-table
                [dataSource]="dataSource"
                class="mat-elevation-z8 mat-table-custom">

                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef> Id </th>
                    <td 
                        mat-cell 
                        *matCellDef="let element; let i = index"
                        [formGroup]="element"
                    >
                        <p>{{getFormValue(i).Id}}</p>
                    </td>
                </ng-container>

                <ng-container matColumnDef="key">
                    <th mat-header-cell *matHeaderCellDef> Configuration Key </th>
                    <td 
                        mat-cell 
                        [matTooltip]="getFormValue(i).Description"
                        *matCellDef="let element; let i = index"
                        [formGroup]="element"
                    >
                        <p>{{getFormValue(i).Key}}</p>
                    </td>
                </ng-container>

                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef> Type </th>
                    <td 
                        mat-cell 
                        *matCellDef="let element; let i = index"
                        [formGroup]="element"
                    >
                        <p>{{getFormValue(i).Type}}</p>
                    </td>
                </ng-container>

                <ng-container matColumnDef="tenant">
                    <th mat-header-cell *matHeaderCellDef> Tenant Value </th>
                    <td 
                        mat-cell 
                        *matCellDef="let element; let i = index"
                        [formGroup]="element">
                        <!-- TEXT -->
                        <mat-form-field 
                            floatLabel="never" 
                            *ngIf="getFormValue(i).Type == 'string' && editableColumn == TENANT">
                            <input matInput [value]="getFormValue(i).TenantValue" formControlName="TenantValue">
                        </mat-form-field>
                        <!-- BOOL -->
                        <p *ngIf="getFormValue(i).Type == 'bool' && !getFormValue(i).OnEdit">{{element.value.TenantValue}}</p>
                        <mat-checkbox 
                            *ngIf="getFormValue(i).Type == 'bool' && getFormValue(i).OnEdit" 
                            formControlName="TenantValue"
                            [disabled]="!getFormValue(i).OnEdit || editableColumn != TENANT">
                            {{getFormValue(i).TenantValue}}
                        </mat-checkbox>
                        <!-- NUMBER -->
                        <mat-form-field 
                            floatLabel="never" 
                            *ngIf="getFormValue(i).Type == 'number' && editableColumn == TENANT"
                        >
                            <input 
                                matInput type="number" 
                                [value]="getFormValue(i).TenantValue" 
                                formControlName="TenantValue"
                                [readonly]="!getFormValue(i).OnEdit || editableColumn != TENANT">
                        </mat-form-field>
                        <ng-select 
                            *ngIf="getFormValue(i).Type == 'enum'"
                            [items]="enumSelectors.get(getFormValue(i).Key)!"
                            bindLabel="text"
                            bindValue="value"
                            [clearable]="false"
                            [clearOnBackspace]="false"
                            formControlName="TenantValue"
                            [readonly]="!getFormValue(i).OnEdit || editableColumn != TENANT">
                        </ng-select>
                    </td>
                </ng-container>

                <ng-container matColumnDef="branch">
                    <th mat-header-cell *matHeaderCellDef> Branch Value </th>
                    <td 
                        mat-cell 
                        *matCellDef="let element; let i = index"
                        [formGroup]="element">
                        <!-- TEXT -->
                        <mat-form-field 
                            floatLabel="never" 
                            *ngIf="getFormValue(i).Type == 'string' && editableColumn == BRANCH">
                            <input matInput [value]="getFormValue(i).BranchValue" formControlName="BranchValue">
                        </mat-form-field>
                        <!-- BOOL -->
                        <p *ngIf="getFormValue(i).Type == 'bool' && !getFormValue(i).OnEdit">{{element.value.BranchValue}}</p>
                        <mat-checkbox 
                            *ngIf="getFormValue(i).Type == 'bool' && getFormValue(i).OnEdit" 
                            formControlName="BranchValue"
                            [disabled]="!getFormValue(i).OnEdit || editableColumn != BRANCH">
                            {{getFormValue(i).BranchValue}}
                        </mat-checkbox>
                        <!-- NUMBER -->
                        <mat-form-field 
                            floatLabel="never" 
                            *ngIf="getFormValue(i).Type == 'number' && editableColumn == BRANCH"
                        >
                            <input 
                                matInput type="number" 
                                [value]="getFormValue(i).BranchValue" 
                                formControlName="BranchValue"
                                [readonly]="!getFormValue(i).OnEdit || editableColumn != BRANCH">
                        </mat-form-field>
                        <ng-select 
                            *ngIf="getFormValue(i).Type == 'enum'"
                            [items]="enumSelectors.get(getFormValue(i).Key)!"
                            bindLabel="text"
                            bindValue="value"
                            [clearable]="false"
                            [clearOnBackspace]="false"
                            formControlName="BranchValue"
                            [readonly]="!getFormValue(i).OnEdit || editableColumn != BRANCH">
                        </ng-select>
                    </td>
                </ng-container>

                <ng-container matColumnDef="device">
                    <th mat-header-cell *matHeaderCellDef> Device Value </th>
                    <td 
                        mat-cell 
                        *matCellDef="let element; let i = index"
                        [formGroup]="element">
                        <!-- TEXT -->
                        <mat-form-field 
                            floatLabel="never" 
                            *ngIf="getFormValue(i).Type == 'string' && editableColumn == DEVICE">
                            <input matInput [value]="getFormValue(i).DeviceValue" formControlName="DeviceValue">
                        </mat-form-field>
                        <!-- BOOL -->
                        <p *ngIf="getFormValue(i).Type == 'bool' && !getFormValue(i).OnEdit">{{element.value.DeviceValue}}</p>
                        <mat-checkbox 
                            *ngIf="getFormValue(i).Type == 'bool' && getFormValue(i).OnEdit" 
                            formControlName="DeviceValue"
                            [disabled]="!getFormValue(i).OnEdit || editableColumn != DEVICE">
                            {{getFormValue(i).DeviceValue}}
                        </mat-checkbox>
                        <!-- NUMBER -->
                        <mat-form-field 
                            floatLabel="never" 
                            *ngIf="getFormValue(i).Type == 'number' && editableColumn == DEVICE"
                        >
                            <input 
                                matInput type="number" 
                                [value]="getFormValue(i).DeviceValue" 
                                formControlName="DeviceValue"
                                [readonly]="!getFormValue(i).OnEdit || editableColumn != DEVICE">
                        </mat-form-field>
                        <ng-select 
                            *ngIf="getFormValue(i).Type == 'enum'"
                            [items]="enumSelectors.get(getFormValue(i).Key)!"
                            bindLabel="text"
                            bindValue="value"
                            [clearable]="false"
                            [clearOnBackspace]="false"
                            formControlName="DeviceValue"
                            [readonly]="!getFormValue(i).OnEdit || editableColumn != DEVICE">
                        </ng-select>
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef> Actions </th>
                    <td 
                        mat-cell 
                        *matCellDef="let element; let i = index"
                        [formGroup]="element">
                        <button mat-icon-button color="primary" *ngIf="!getFormValue(i).OnEdit" matTooltip="Edit Row"
                                (click)="onRowEditInit(i)">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button mat-icon-button color="warn" *ngIf="getFormValue(i).OnEdit" matTooltip="Cancel Row"
                                (click)="onRowEditCancel(i)">
                            <mat-icon>rotate_left</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="savefooter">
                    <th mat-footer-cell *matFooterCellDef colspan="6">
                        <div class="OrangeButton">
                            <button mat-raised-button type="submit" (click)="onSave()"
                                    [disabled]="!anyChange()"
                                    [matTooltip]="anyChange()? 'Save changes' : 'Save disabled'"
                                    class="button-color-fix pull-right">
                                Save
                                <mat-icon style="color:white !important" class="icon-color-fix">save</mat-icon>
                            </button>
                        </div>
                    </th>

                </ng-container>

                <tr 
                    mat-header-row 
                    *matHeaderRowDef="columnsToDisplay; sticky: true">
                </tr>
                <tr mat-row *matRowDef="let element; let i = dataIndex;
                    columns: columnsToDisplay;"
                    class="item"
                    [class.alternative2]="element.value.OnEdit">
                </tr>
                <tr 
                    mat-footer-row 
                    *matFooterRowDef="['savefooter']; sticky: true">
                </tr>
            </table>
        </ng-container>
    </form>
</div>