import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output, Self, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Utils } from '../../../utils/utils';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { DefaultValuesService } from '../../../services/defaultvalues.service';
import { DestroyService } from '../../../services/destroyservice';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'productusage',
  templateUrl: './productusage.component.html',
    styleUrls: ['../../../style/new-generic-styles.component.less',
        '../../../style/tables/table-add-butt-header-orange.less',
        '../emtcontrollersoftware.component.less',
        './productusage.component.less'],
    providers: [DestroyService]
})
export class EmtProductusageComponent implements OnInit {
    public dataSource = new MatTableDataSource<any>();
    @ViewChild(MatTable) objTable!: MatTable<any>;
    PUForm!: FormGroup;
    isCallSucceed: number = 0;
    originalProductsUsage: EMTProductUsageItem[] = [];
    modifiedProductsUsage: EMTProductUsageItem[] = [];
    newProductsUsage: EMTProductUsageItem[] = [];
    genType: number = 0;
    @Output() productUsageEvent = new EventEmitter<EMTProductUsageItem[]>();
    subscriptionStage: any;
    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string,
        private _formBuilder: FormBuilder,
        private toastrService: ToastrService,
        public defaults: DefaultValuesService,
        @Self() private readonly destroy$: DestroyService) { }

    columnsToDisplay = ["name", "action"];

    ngOnInit(): void {
        this.subscriptionStage = this.defaults.stageMessageChanges$.pipe(takeUntil(this.destroy$)).subscribe(() => { this.getProductsUsage() });
        this.PUForm = this._formBuilder.group({
            PURows: this._formBuilder.array([])
        });
        this.getProductsUsage();      
  }

    getProductsUsage() {
        Utils.httpGetEMTProductsUsage(
            this.http,
            this.baseUrl,
            this,
            function (tthis: EmtProductusageComponent, result: EMTProductsUsage) {
                tthis.originalProductsUsage = result.usagesList;
                tthis.mapDataToForm(tthis.originalProductsUsage);
                tthis.productUsageEvent.emit(tthis.originalProductsUsage);
            }
        );
    }

    mapDataToForm(data) {
        this.PUForm = this._formBuilder.group({
            PURows: this._formBuilder.array([])
        });
        this.PUForm = this._formBuilder.group({
            PURows: this._formBuilder.array(data.map(val => this._formBuilder.group({
                productUsageID: new FormControl(val.productUsageID),
                name: new FormControl(val.name),
                configurable: new FormControl(val.configurable),               
                isEditable: new FormControl(true),
                isNewRow: new FormControl(false),
                action: new FormControl('existingRecord'),
            })
            )) //end of fb array
        }); // end of form group cretation
        this.dataSource.data = (this.PUForm.get('PURows') as FormArray).value;
    }

    initPUForm(): FormGroup {
        return this._formBuilder.group({
            name: new FormControl(`Product_${this.genType++}`),
            configurable: new FormControl(false),
            action: new FormControl('newRecord'),
            isEditable: new FormControl(false),
            isNewRow: new FormControl(true),
        });
    }

    addNewRow() {
        const control = this.PUForm.get('PURows') as FormArray;
        control.insert(0, this.initPUForm());
        this.dataSource = new MatTableDataSource(control.controls);
    }

    editRow(index) {
        this.PUForm.value.PURows[index].isEditable = false;
    }

    resetRow(i) {
        if (this.PUForm.value.PURows[i]?.isNewRow)
            this.deleteRow(i);
        else {
            this.updateFormFeature(i);
            this.PUForm.value.PURows[i].isEditable = true;
        }
    }

    deleteRow(i) {
        const control = this.PUForm.get('PURows') as FormArray;
        control.removeAt(i);
        this.dataSource.data = control.value;
        this.refreshTable();
    }

    updateFormFeature(index) {
        var obj = this.PUForm.value.PURows;
        var updated = this.originalProductsUsage.find(s => s.productUsageID == this.PUForm.value.PURows[index]?.productUsageID);
        obj[index].name = updated?.name;
        obj[index].configurable = updated?.configurable;
    }

    mapFormToData() {
        this.modifiedProductsUsage = this.PUForm.get('PURows')?.value.filter(row => !row.isNewRow
            && !row.isEditable
            && row.name !== "" && row.name.length <= 50
        );
        this.newProductsUsage = this.PUForm.get('PURows')?.value.filter(row => row.isNewRow
            && row.name !== "" && row.name.length <= 50
        );
    }

    changeIsConfigurable(index, value: boolean) {
        this.PUForm.value.PURows[index].configurable = value
        this.refreshTable();
    }

    anyChange() {
        this.mapFormToData();
        return this.modifiedProductsUsage?.length > 0 || this.newProductsUsage?.length > 0;
    }

    onSave() {
        if (this.anyChange()) {
            var productUsageChanges = { 'modifiedProductsUsage': this.modifiedProductsUsage, 'newProductsUsage': this.newProductsUsage }
            Utils.httpUpdateEMTProductsUsage(
                this.http
                , this.baseUrl
                , productUsageChanges
                , this
                , function (tthis: EmtProductusageComponent, result: any) {
                    tthis.getProductsUsage();
                    tthis.funcNotify();
                }
            )
        }
    }

    funcNotify() {
        if (this.isCallSucceed === 1) {
            this.toastrService.success('Save request successfully sent!');
        }
        else if (this.isCallSucceed === -1) {
            this.toastrService.error('Save action could not be performed. Please, contact with administrator');
        }
    }

    refreshTable() {
        this.objTable.renderRows();
    }
}
