import { Component, Inject, OnInit, Input } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Utils } from "../../utils/utils";
import { DialogService } from "../../services/dialog.service";
import { DefaultValuesService } from "../../services/defaultvalues.service";
import { SaveService } from "../../services/save.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: "testcase-form",
    templateUrl: './testcase-form.component.html',
    styleUrls: ['../../style/form.component.less','../../style/custom-mat-dialog.component.less',]
})

export class TestCaseFormComponent implements OnInit {

    @Input() list: TestCase[] = [];
    header: any[] = [
        { label: 'Name', show: true, searchtype: 'text' }
        , { label: 'Description', show: true, searchtype: 'text' }
        , { label: 'Minutes To Wait Response', show: true, searchtype: 'number' }
        , { label: 'Area', show: true, searchtype: 'text' }
        , { label: 'Is IT Process Relevant?', show: true, searchtype: 'checkbox', checkbox: true }
        , { label: 'Id', show: true, searchtype: 'number' }
    ];
    @Input() rowIndex: number | undefined;

    title: string;

    selected: TestCase = {} as TestCase;

    listTA: TestArea[] = [];

    classname: string[] = [];
    isundodisabled: boolean[] = [];

    isToShowRef = false;

    form: FormGroup = new FormGroup({});

    NAME = 0;
    DESCRIPTION = 1;
    MINUTESTOWAIT = 2;
    AREA = 3;
    ISITPROCESSRELEVANT = 4;

    constructor(private http: HttpClient
        , private dialog: DialogService
        , public defaults: DefaultValuesService
        , private saveService: SaveService
        , public matDialog: MatDialog
        , @Inject('BASE_URL') private baseUrl: string
    ) {
        this.title = "Create new Test Case";
    }

    ngOnInit() {

        Utils.httpGetAllTestAreas(
            this.http
            , this.baseUrl
            , this
            , function (tthis: any, list: TestArea[]) {
                tthis.listTA = list;
            }
        );

        if (this.header.length === 0) { //this is a new form
            this.header = this.dialog.getHeader();
        }
        this.isToShowRef = this.defaults.isToShowReference();
        this.saveService.addListener(this);
        this.saveService.initUndo(this);

        this.createForm();

        const tthis = this;
        this.header.forEach(
            function (value: any, index: number, array: any[]) {
                tthis.classname[index] = "";
                tthis.isundodisabled[index] = true;
            }
        );

    }

    onAreaChange(evt: any) {
        if (this.form && this.selected && this.rowIndex !== undefined
            && this.form.controls['Area'].value !== this.selected.TestAreaID) {
            this.list[this.rowIndex].TestAreaID = this.form.controls['Area'].value;
            this.setUndoDisable(this.AREA, false);
        }
    }

    onCancelForm() {
        const value = this.rowIndex === undefined ? -1 : this.rowIndex;
        this.saveService.fireCancel(value, this);
    }

    onDescriptionChange(evt: any) {
        if (this.form && this.selected && this.rowIndex !== undefined
            && this.form.controls['Description'].value !== this.selected.Description) {
            this.list[this.rowIndex].Description = this.form.controls['Description'].value;
            this.setUndoDisable(this.DESCRIPTION, false);
        }
    }

    onIsItProcessRelevantChange(evt: any) {
        if (this.form && this.selected !== undefined && this.rowIndex !== undefined)
            this.list[this.rowIndex].IsITProcessRelevant = evt.checked ? true : false;
    }

    onDescriptionUndo() {
        if (this.rowIndex !== undefined) {
            this.list[this.rowIndex].Description = this.selected.Description;
            this.setUndoDisable(this.DESCRIPTION, true);
            this.updateForm();
        }
    }

    onMinutesChange(evt: any) {
        if (this.form && this.selected && this.rowIndex !== undefined
            && this.form.controls['Minutes'].value !== this.selected.MinutesToWait) {
            this.list[this.rowIndex].MinutesToWait = this.form.controls['Minutes'].value;
            this.setUndoDisable(this.MINUTESTOWAIT, false);
        }
    }

    onNameChange(evt: any) {
        if (this.form && this.selected && this.rowIndex !== undefined
            && this.form.controls['Name'].value !== this.selected.Name) {
            this.list[this.rowIndex].Name = this.form.controls['Name'].value;
            this.setUndoDisable(this.NAME, false);
        }
    }

    onNameUndo() {
        if (this.rowIndex !== undefined) {
            this.list[this.rowIndex].Name = this.selected.Name;
            this.setUndoDisable(this.NAME, true);
            this.updateForm();
        }
    }

    /**
     * called by the SaveService
     * */
    onSave() {
        this.createObject();
        const tthis = this;
        const url = this.baseUrl + Utils.getTestCaseAPI() + "edit";
        this.http
            .put<TestCase>(url, this.selected)
            .subscribe(res => {
                tthis.list.unshift(res);
                tthis.matDialog.closeAll();
            }, error => this.dialog.showErrorDialog(error));
    }

    /**
     * called by the save button on this form
     * 
     * */
    onSubmit() {
        const value = this.rowIndex === undefined ? -1 : this.rowIndex;
        this.saveService.fireSave(value, this);
    }

    private createForm() {
        this.form = new FormGroup({
            Name: new FormControl('', [Validators.required]),
            Description: new FormControl('', [Validators.required]),
            Minutes: new FormControl('', [Validators.required, Validators.min(0)]),
            Area: new FormControl('1'),
            IsITProcessRelevant: new FormControl(false, [Validators.required]),
        });
    }

    private createObject() {
        this.selected.Name = this.form.value.Name;
        this.selected.Description = this.form.value.Description;
        this.selected.MinutesToWait = this.form.value.Minutes;
        this.selected.TestAreaID = this.form.value.Area;
        this.selected.IsITProcessRelevant = this.form.value.IsITProcessRelevant
    }

    private setUndoDisable(index: number, value: boolean) {
        this.classname[index] = value ? "" : Utils.getEditingInputClassName();
        this.isundodisabled[index] = value;
    }

    private updateForm() {
        if (this.form && this.rowIndex !== undefined) {
            const e = {
                Name: this.list[this.rowIndex].Name
                , Description: this.list[this.rowIndex].Description
                , Minutes: this.list[this.rowIndex].MinutesToWait
                , Area: this.list[this.rowIndex].TestAreaID
            };
            this.form.setValue(e);
        }
    }


    onBack() {
    }
    onNoClick(): void {
        this.matDialog.closeAll();
    }
    onCancel() {
        this.matDialog.closeAll();
    }

}