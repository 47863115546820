import { Component, Inject, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { DialogService } from "../../../services/dialog.service";
import { Utils } from "../../../utils/utils";
import { HttpClient } from "@angular/common/http";
import { DefaultValuesService } from "../../../services/defaultvalues.service";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";

@Component({
    selector: "controllertypedeploymentstatus-list",
    templateUrl: './controllertypedeploymentstatus-list.component.html',
    styleUrls: ['./controllertypedeploymentstatus-list.component.less']
})

export class ControllerTypeDeploymentStatusListComponent implements OnInit {
    columnsToDisplay = ['environment', 'opmodes', 'errorcodes', 'infoupdateddate'];
    list: EventArchiveStatus[] = [];

    public dataSource = new MatTableDataSource<EventArchiveStatus>();
    subscriptionControllerType: any;
        constructor(
        private http: HttpClient
        , private dialogService: DialogService
        , private defaults: DefaultValuesService
        , @Inject('BASE_URL') private baseUrl: string
        , private dialog: MatDialog
    ) {
    }

    ngOnInit() {     
        this.dialogService.tthis = this;
        this.subscriptionControllerType = this.defaults.controllerTypeMessageChanges$.subscribe((item: ControllerType) => this.onControllerChanged(item));
        this.refresh();
    }

    ngOnChanges() {
        
        this.dataSource.data = this.list
    }

    onControllerChanged(ct: ControllerType) {
        this.refresh();
    }

    public refresh() {
        const tthis = this;
        /**
            * load all the data
            * */
        Utils.httpGetAllDeploymentStatusByControllerType(
            this.http
            , this.baseUrl
            , this
            , function (tthis: any, values: EventArchiveStatus[]) {
                tthis.list = values;

                tthis.dataSource.data = tthis.list
            }
        );
    }

    public sinceDays(date: Date) {
        let currentDate = new Date();

        let dateDeployment = new Date(date);
        return Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())
            - Date.UTC(dateDeployment.getFullYear(), dateDeployment.getMonth(), dateDeployment.getDate())) / (1000 * 60 * 60 * 24));
    }
}