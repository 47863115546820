import { Component, Input, EventEmitter, Output, OnInit, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Utils } from "../../utils/utils";
import { SaveService } from "../../services/save.service";
import { DialogService } from "../../services/dialog.service";
import { DefaultValuesService } from "../../services/defaultvalues.service";

@Component({
    selector: "maintenance-control-plan-list",
    templateUrl: './mcp-list.component.html',
    styleUrls: ['../../style/list.component.less', './mcp-list.component.less']
})

export class MaintenanceControlPlanListComponent implements OnInit{
    @Input('list') list: MaintenanceControlPlan[] = [];
    selected: MaintenanceControlPlan = <MaintenanceControlPlan>{};
    //innerList: TableFixedHeaderSearchComponent | undefined;
    @Input() newService: SaveService | undefined;

    header: any[] =  [
        { label: 'Title', show: true, searchtype: 'text' }
        , { label: 'Asset', show: true, searchtype: 'text' }
        , { label: 'Is Hydraulic?', show: true, searchtype: 'checkbox', checkbox: true }
        , { label: 'Country', show: false }
        , { label: 'Id', show: true, searchtype: 'number' }
    ];

    @Output() onSearch = new EventEmitter<{ ShowRef:boolean, Index: number, Value: any }>();
    @Output() onReset = new EventEmitter<{}>();

    constructor(
        private router: Router
        , private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , private dialog: DialogService
        , public defaults: DefaultValuesService
    ) {
    }

    ngOnInit() {
        this.dialog.tthis = this;
    }

    /**
     * this is from who calls (inner component)
     * @param list
     * @param row
     * @param column
     */
    getData(list: MaintenanceControlPlan[], row: number, column: number): string {
        if (list) {
            var e = list[row];
            switch (column) {
                case 0: 
                    return e.Title; 
                case 1:
                    return e.AssetType; 
                case 2:
                    return e.IsHydraulic ? "1" : "0"; 
                case 3:
                    return e.Name;
                default: //MCP
                    return e.MCPID.toString();
            }
        }
        else
            return "";
    }

    onDelete(value: { index: number, value: MaintenanceControlPlan }) {
        this.dialog.showConfirmDialog(value.value.Title + "?", "Delete Maintenance Control Program", this, value);
    }

    onYes(value: { index: number, value: MaintenanceControlPlan }) {
        this.http
            .delete<MaintenanceControlPlan>(
                this.baseUrl + Utils.getMaintenanceControlPlanAPI() + value.value.MCPID
            )
            .subscribe(
                res => this.list.splice(value.index, 1)
                , error => this.dialog.showErrorDialog(error)
            );
    }

    onRowClick(value: { index: number, value: MaintenanceControlPlan }) {
        this.router.navigate(["maintenancecontrolplangroup", value.value.MCPID]);
    }
}