import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { DefaultValuesService } from '../../../../services/defaultvalues.service';
import { Utils } from "../../../../utils/utils";
import { MatTableDataSource } from "@angular/material/table";

@Component({
    selector: "wsconfiguration",
    templateUrl: 'wsconfiguration.component.html',
})

export class WsConfigurationComponent implements OnInit, OnDestroy {

    subscriptionStage: any;
    subscriptionTenant: any;
    columnsToDisplay = ['servicename', 'url', 'certificatename', 'branchnumber', 'targetsystem'];

    public dataSource = new MatTableDataSource<WebServiceConfigurationItem>();
    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string
        , private defaults: DefaultValuesService
    ) {

    }

    ngOnInit(): void {
        this.subscriptionStage = this.defaults.stageMessageChanges$.subscribe(() => {
            this.getWebServiceConfigurationList();
        });
        this.subscriptionTenant = this.defaults.tenantMessageChanges$.subscribe(() => {
            this.getWebServiceConfigurationList();
        });
        this.getWebServiceConfigurationList()
    }


    ngOnDestroy() {
        this.subscriptionStage.unsubscribe();
        this.subscriptionTenant.unsubscribe();
    }

    getWebServiceConfigurationList() {
        Utils.httpGetWebServiceConfigurationList(
            this.http
            , this.baseUrl
            , this.defaults.stage
            , this
            , function (tthis: any, result: WebServiceConfigurationItem[]) {
                tthis.dataSource.data = result
            }
        )
    }
}
