import { Component, Inject, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Utils } from "../../utils/utils";
import { DialogService } from "../../services/dialog.service";
import { SaveService } from "../../services/save.service";
//import { forEach } from "@angular/router/src/utils/collection";

@Component({
    selector: "errorcode-list-detail-properties",
    templateUrl: './errorcode-list-detail-properties.component.html',
    styleUrls: ['../../style/list.component.less', './errorcode-list.component.less']
})

export class ErrorCodeDetailListPropertiesComponent implements OnInit {
    @Input() selected: ErrorCode = <ErrorCode>{};
    @Input() newService: SaveService | undefined;

    @Output() onChecked = new EventEmitter<{ Index: number, Value: any, Checked: boolean, List: boolean[], NumberOfChecks: number, FirstCheckedIndex: number }>();

    header: any[] = [
        { label: 'Failure', index: 0, show:true }
        , { label: 'Action', index: 1, show: true }
        , { label: 'Weight', index: 2, show: true }
        , { label: 'Location', index: 3, show: true }
        , { label: 'Component', index: 4, show: true }
        , { label: 'Cause', index: 5, show: true }
        , { label: 'Resolution', index: 6, show: true }
        , { label: 'Parts Required ?', checkbox: true, index: 7, show: true }
        , { label: 'High Value Part ?', checkbox: true, index: 8, show: true }
    ];

    constructor(private activatedRoute: ActivatedRoute,
        private router: Router
        , private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , private dialog: DialogService

    ) {
    }

    ngOnInit() {
        this.dialog.tthis = this;
    }

    /**
     * this is from who calls (inner component)
     * @param list
     * @param row
     * @param column
     */
    getData(list: ErrorCodeResolution[], row: number, column: number): string {

        //<th * ngIf="isToShowReference()" > Maintenance Failure Ref < /th>     0
        //< th > Maintenance Failure < /th>                                     1   0
        //< th * ngIf="isToShowReference()" > TroubleShootingAction Ref < /th>  2
        //< th > TroubleShootingAction < /th>                                   3   1


        if (list) {
            var e = list[row];
            switch (column) {
                case 0:
                    return e.MaintenanceFailure;
                case 1:
                    return e.TroubleShootingAction;
                case 2:
                    return e.TroubleShootingActionWeight.toString();
                case 3:
                    return e.SubSystemGroup;
                case 4:
                    return e.ComponentItem;
                case 5:
                    return e.Cause;
                case 6:
                    return e.Resolution
                case 7:
                    return e.PartsRequired ? "1" : "0";
                case 8:
                    return e.HighValuePart ? "1" : "0";
                default:
                    return "";
            }
        }
        else
            return "";
    }

    notInView(row: number, column: number, data: ErrorCodeResolution[]): string {
        if (column == 0 && (!data[row].CauseInView || !data[row].ComponentInView))
            return Utils.getNotTranslatedClassName();
        return "";
    }

    onDelete(value: { index: number, value: ErrorCodeResolution }) {
        this.dialog.showConfirmDialog("Delete Possible Failure - " + value.value.MaintenanceFailure + " - " + value.value.TroubleShootingAction + " (" + value.value.PossibleFailureID + ")?", "Delete Possible Failure", this, value);
    }

    onYes(value: { index: number, value: ErrorCodeResolution }) {
        this.http
            .delete<ErrorCodeResolution>(
                this.baseUrl + Utils.getErrorCodeAPI() + "deletepossiblefailure/" + value.value.PossibleFailureID
            )
            .subscribe(
                res => this.selected.ErrorCodeResolutions.splice(value.index, 1)
                , error => this.dialog.showErrorDialog(error)
            );
    }

    //onSaveRow(event: any) {
    //    this.http
    //        .post<BusinessKey>(
    //            this.baseUrl + Utils.getErrorCodeAPI() + "editpossiblefailure"
    //            , event.Value
    //        )
    //        .subscribe(
    //            res => { }
    //            , error => this.dialog.showErrorDialog(error)
    //        );
    //}

}