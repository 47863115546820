import { Inject } from "@angular/core";
import { Component, Injectable, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: 'dialog-alert',
    templateUrl: "./dialog-alert.component.html",
    // Use the same less file as confirmation dialog
    styleUrls: ["./dialog-confirmation.component.less"]
})
export class AlertDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<AlertDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    onOk() {
        this.dialogRef.close();
    }
}