import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Utils } from '../utils/utils';
import { DefaultValuesService } from './defaultvalues.service';

@Injectable()
export class DialogService {

    classname: string | undefined;
    classdialog: string = Utils.getDialogHideClassName();
    type: number = Utils.getSuccessValue();
    progressValue: number | undefined;


    title: string | undefined;
    message: string | undefined;
    items: string[] | undefined;
    obj: any | undefined;

    //incase to control ignore onBack;
    doBack: boolean = true;

    //to show the principal type (success, error, etc.) icon
    showTypeIcon: boolean = true;
    //array to show text and associated icon (in order to show specific icon for each text)
    text_icon_items: TextIconItem[] = [];
    extraClasses: string[] | undefined;

    /**
     * from the caller
     * */
    tthis: any | undefined;

    constructor(
        private http: HttpClient
        , public defaults: DefaultValuesService
        , @Inject('BASE_URL') private baseUrl: string
    ) { }

    /**
    * hide the dialog
    * 
    */
    private hideDialog() {
        //this.classdialog = Utils.getDialogHideClassName();
        //this.classname = "";
        this.toShow(false);
    }

    private toShow(value: boolean) {
        this.classdialog = value == true ? `${Utils.getDialogShowClassName()} ${this.extraClasses ? this.extraClasses.join(' ') : "" }` : Utils.getDialogHideClassName();
        this.classname = value == true ? Utils.getOpacityClassName() : "";
    }

    /**
     * get the header array from the tthis object
     * 
     * */
    public getHeader() {
        if (this.tthis.header)
            return this.tthis.header;
    }

    /**
     * get the parent object 
     * 
     * on the child list, the parent is the selected object
     * 
     * */
    public getParent() {
        if (this.tthis.selected)
            return this.tthis.selected;
    }

    public onOk() {
        if (this.type !== Utils.getErrorValue())
            this.onBack();
        else this.hideDialog();
    }

    public onBack() {
        if (this.tthis.onBack && this.doBack) {
            this.tthis.onBack();
            this.hideDialog();
        }
        if (!this.doBack) {
            this.hideDialog();
        }
    }

    public onNo() {
        if (this.tthis.onNo)
            this.tthis.onNo();
        this.hideDialog();
    }

    public onYes() {
        if (this.tthis.onYes) {
            if (this.obj)
                this.tthis.onYes(this.obj);
            else
                this.tthis.onYes();
            this.hideDialog();
        }
    }

    /**
     * show dialogs confirm, the object that uses must have the fields type; message; classdialog and classname
     * change classname for alert and form
     * @param question
     */
    public showConfirmDialog(question: string, title: string = "", tthis: any = undefined, obj: any = undefined) {
        if (tthis)
            this.tthis = tthis;
        if (obj)
            this.obj = obj;
        this.title = title;
        this.type = Utils.getConfirmValue();
        this.message = question;
        this.toShow(true);
    }

    /**
     * show dialogs confirm, the object that uses must have the fields type; message; classdialog and classname
     * change classname for alert and form
     * @param tthis
     * @param question
     */
    public showErrorDialog(error: any) {
        if ((error.status == 200 && this.tthis == null) || error.status == Utils.getStatusServerError()) {
            Utils.redirectLogout('', this.baseUrl, this.http);//window.location.href = "https://login.microsoftonline.com/common/oauth2/logout"; //?post_logout_redirect_uri=http%3A%2F%2Flocalhost%2Fhome%2F"; //error.url + "/logout";

            //console.debug("We HAVE an ERROR!!!!!");
            //console.error(error);
        }
        else {
            this.showErrorStringDialog(error.error.message);
        }
    }

    /**
     * show dialogs confirm, the object that uses must have the fields type; message; classdialog and classname
     * change classname for alert and form
     * @param tthis
     * @param question
     */
    public showErrorStringDialog(error: string) {
        this.type = Utils.getErrorValue();
        this.title = "Error";
        this.message = error;
        this.toShow(true);
    }

    /**
     * show dialogs confirm, the object that uses must have the fields type; message; classdialog and classname
     * change classname for alert and form
     * @param question
     */
    public showSuccessDialog(message: string, title: string = "", items: string[] =[]) {
        this.title = title;
        this.type = Utils.getSuccessValue();
        this.message = message;
        this.items = items;
        this.toShow(true);
    }

    /**
     * show dialogs error, the object that uses must have the fields type; message; classdialog and classname
     * change classname for alert and form
     * @param question
     */
    public showRegularErrorDialog(error: string) {
        this.title = "Information:"
        this.type = Utils.getErrorValue();
        this.message = error;
        this.toShow(true);
    }

    public showInfoBackgroundDialog(message: string, progressValue = -1, title = "Information:",) {

        this.title = title
        this.type = Utils.getInfoBackgroundValue();
        this.message = message;
        this.toShow(true);
        this.progressValue = progressValue
    }
    
}