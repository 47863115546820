<div class="custom-mat-dialog-form">
    <div>
        <h3>{{data.Enabled ? 'Disable' : 'Enable'}} parameter {{this.data.parameterKey}}</h3>
    </div>
    <div>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="mt-2">
                <label>
                    Controller Parameter Id 
                    <mat-icon matTooltipClass='opmodes-custom-tooltip'
                              (click)="onShowKeywords()"
                              matTooltip="Add keyword">star</mat-icon>
                </label>
                <div class="input">
                    <input type="text" class="form-control" formControlName="controllerParameterId" [(ngModel)]="body.ControllerParameterId" id="controllerParameterId" />
                    <mat-select style="max-width:100px"
                                id="keywords"
                                (selectionChange)="onSelectItem($event.value)"
                                (openedChange)="openedChange($event)"
                                 *ngIf="isShowingKeywordsSelector"
                                #keywords>
                        <mat-option *ngFor="let keyword of parameterKeywords" [value]="keyword">
                            {{ keyword }}
                        </mat-option>
                    </mat-select>
                    <div *ngIf="submitted && f['controllerParameterId'].errors" class="invalid-feedback">
                        <div class="error-div">Controller Paranmeter Id must be populated</div>
                    </div>
                </div>
            </div>
            <div class="mt-2">
                <label>Unit</label>
                <div class="input">
                    <input type="text" class="form-control" formControlName="unit" [(ngModel)]="body.Unit" />
                    <div *ngIf="submitted && f['unit'].errors" class="invalid-feedback">
                        <div class="error-div">Unit must be populated</div>
                    </div>
                </div>
            </div>

            <div class="input div-flex mt-2">
                <label>Asset Parameter Functionality</label>
                <mat-select class="form-control" formControlName="assetParameterFunctionalityId"
                            style="min-width: 150px"
                            [(ngModel)]="body.AssetParameterFunctionalityId"
                            panelClass="testcase-class">
                    <mat-option *ngFor="let param of this.data.assetParameters" [value]="param.AssetParameterFunctionalityId">
                        {{ param.AssetParameterFunctionalityName }}
                    </mat-option>
                </mat-select>
            </div>
            <div class="mt-2 right">
                <button type="button"
                        class="btn btn-danger"
                        (click)="onNoClick()">
                    Close
                </button>
                <button type="button"
                        class="btn btn-primary"
                        (click)="onSubmit()">
                    Send
                </button>
            </div>
        </form>
    </div>
</div>