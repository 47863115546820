import { Component, Inject, Input, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Utils } from "../../utils/utils";
import { DialogService } from "../../services/dialog.service";
import { SaveService } from "../../services/save.service";

@Component({
    selector: "maintenance-control-plan-group-action-component-proposed-errors-list",
    templateUrl: './mcp-list-group-action-component-proposed-errors.component.html',
    styleUrls: ['../../style/list.component.less', './mcp-list.component.less']
})

export class MaintenanceControlPlanGroupActionComponentProposedErrorsListComponent implements OnInit {
    @Input() list: MaintenanceControlPlanGroupActionComponentProposedErrors[] = [];

    @Input() checked: MaintenanceControlPlanGroupActionComponentProposedErrors[] = [];

    header: any[] = [
        { label: 'Action', index: 0, show: true }
        , { label: 'Location', index: 1, show: true }
        , { label: 'Component', index: 2, show: true }
        , { label: 'Controller Type', index: 3, show: true }
        , { label: 'Error Code', index: 4, show: true }
        , { label: 'Error Description', index: 5, show: true }
    ];

    constructor(
        private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , private dialog: DialogService
    ) {
    }

    /**
     * this is from who calls (inner component)
     * @param list
     * @param row
     * @param column
     */
    getData(list: MaintenanceControlPlanGroupActionComponentProposedErrors[], row: number, column: number): string {
        if (list) {
            var e = list[row];
            switch (column) {
                case 0:
                    return e.ActionDescription;                    
                case 1:
                    return e.Location;                    
                case 2:
                    return e.ComponentItem;                    
                case 3:
                    return e.ControllerType;                    
                case 4:
                    return e.ErrorCode.toString();                    
                default:
                    return e.Description;
            }
        }
        else
            return "";
    }

    ngOnInit() {
        this.dialog.tthis = this;
    }
}