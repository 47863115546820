<div class="container-tab-lists">
    <maintenance-control-plan-group-menu [selected]="selected" (newEvent)="onNew()"></maintenance-control-plan-group-menu>

    <h2>{{title}}</h2>
    <div class="tab-body">
        <p>Maintenance Control Plan: {{selected.Title}}</p>

        <div class="tab-content">
            <maintenance-control-plan-group-list [list]="list" [mcp]="selected" [newService]="newMode"></maintenance-control-plan-group-list>
        </div> <!--tab-body-->
    </div> <!--inner-tcontainer-tab-->
</div> <!--container-tab-lists-->