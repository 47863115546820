import { Component, Inject, OnInit, EventEmitter, Output, ViewEncapsulation } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatChipInputEvent } from "@angular/material/chips";
import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';
import { SaveService } from '../../../../services/save.service';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
    selector: "facilitymanager-filter",
    templateUrl: './facilitymanager-filter.component.html',
    styleUrls: ['../../../../style/form.component.less', './facilitymanager-filter.component.less', '../../../../style/expand-collapse-panel.component.less'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*', })),
            transition('expanded => collapsed', [animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
        ]),
    ],
    encapsulation: ViewEncapsulation.None
})

export class FacilityManagerFilterComponent implements OnInit {

    form!: FormGroup;
    @Output() outputFilter = new EventEmitter<FacilityManagerFilter>();

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [COMMA, TAB, ENTER];

    contractNumbers: string[] = [];
    facilityManagerIds: string[] = [];

    constructor(
        private http: HttpClient
        , private fb: FormBuilder
        , @Inject('BASE_URL') private baseUrl: string
        , private saveService: SaveService
    ) {
    }

    ngOnInit() {
        this.saveService.addListener(this);
        this.createForm();
    }

    createForm() {
        this.form = this.fb.group({
        });
    }

    changeTypeButton() {
        return this.contractNumbers.length < 1 && this.facilityManagerIds.length < 1
    }

    applyFilter(event: MatChipInputEvent, list: string): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            switch (list) {
                case 'contractnumbers':
                    this.contractNumbers.push(value.replace('+', '').trim()); break;
                case 'facilitymanagerids':
                    this.facilityManagerIds.push(value.replace('+', '').trim()); break;
            }
        }
        // Reset the input value
        if (input) {
            input.value = '';
        }
    }

    remove(item: string, list: string): void {
        let index = -1
        switch (list) {
            case 'contractnumbers':
                index = this.contractNumbers.indexOf(item);
                if (index >= 0)
                    this.contractNumbers.splice(index, 1);
                break;
            case 'facilitymanagerids':
                index = this.facilityManagerIds.indexOf(item);
                if (index >= 0)
                    this.facilityManagerIds.splice(index, 1);
                break;
        }
    }

    onFilter() {
        const filter: FacilityManagerFilter = {
            'ContractNumbers': this.contractNumbers,
            'FacilityManagerIds': this.facilityManagerIds
        }
        this.outputFilter.emit(filter);
    }
}