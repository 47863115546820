import { Component, Inject, OnInit, OnDestroy, ViewChild, AfterViewInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Utils } from "../../utils/utils";
import { DefaultValuesService } from "../../services/defaultvalues.service";
import { SaveService } from "../../services/save.service";
import { FingerprintRulesListComponent } from './legacy/fingerprintrules-list.component';
import { MatTabGroup } from "@angular/material/tabs";

@Component({
    selector: "fingerprintrules",
    templateUrl: './fingerprintrules.component.html',
    styleUrls: ['../../style/container-tab-list.component.less']
})

export class FingerprintRulesComponent implements OnInit, OnDestroy {
    @ViewChild('tabGroup') tabGroup!: MatTabGroup;

    subscriptionLanguage: any;
    list: TenantControllerTypeFingerprintRule[] = [];
    @ViewChild(FingerprintRulesListComponent, { static: true }) itemsList!: FingerprintRulesListComponent;

    errorCodeList: ErrorCode[] = [];
    controllerConfigurationList: ControllerTypeVMapping[] = []
    definitionList: ErrorCode[] = []
    keywords: string[] = [];

    constructor(
        private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , public defaults: DefaultValuesService
        , private saveService: SaveService
    ) {

    }

    ngOnInit() {
        /* Legacy Fingerprint */
        this.getAll();
        this.subscriptionLanguage = this.defaults.languageMessageChanges$.subscribe(() => { this.getAll() });
        /* /Legacy Fingerprint */

        /* FP Error Codes */
        Utils.httpGetAllTroubleShootingKeywords(
            this.http
            , this.baseUrl
            , this
            , function (tthis: any, list: string[]) {
                tthis.keywords = list;
            }
        );

        this.getErrorCodeList()
        this.getControllerConfiguration();
        this.getDefinitionList()

    }
    ngOnDestroy() {
        this.subscriptionLanguage.unsubscribe();
    }

    ngAfterViewInit() {
        this.defaults.defaultComponentTab(this)
    }

    /* Legacy Fingerprint */
    getAll() {
        let tenants: string[] = [];
        this.defaults.tenants.forEach(x => tenants.push(x.Tenant))
        Utils.httpGetAllFingerprintRulesByTenant(
            this.http
            , this.baseUrl
            , tenants
            , this
            , function (tthis: any, list: TenantControllerTypeFingerprintRule[]) {
                tthis.list = list
            }
        );
    }
    /* /Legacy Fingerprint */

    onReload() {
        this.getErrorCodeList()
        this.getControllerConfiguration()
        this.getDefinitionList()

        /* Legacy Fingerprint */
        this.getAll();
        this.itemsList.clonedList = {}
        this.saveService.showSaveButton(false)
        /* /Legacy Fingerprint */
    }

    onUpdateTable() {
        this.onReload();
    }


    getErrorCodeList() {
        let url = this.baseUrl + Utils.getAPIFingerPrint() + 'errorcodes';
        this.http.get<ErrorCode[]>(url).subscribe(result => {
            this.errorCodeList = result;
        });
    }

    getControllerConfiguration() {
        Utils.httpFPControllerConfiguration(
            this.http
            , this.baseUrl
            , this
            , function (tthis: FingerprintRulesComponent, values: ControllerTypeVMapping[]) {
                tthis.controllerConfigurationList = values;
            }
        );
    }

    getDefinitionList() {

        let url2 = this.baseUrl + Utils.getAPIFingerPrint() + 'fp-ruledefinition';
        this.http.get<ErrorCode[]>(url2).subscribe(result => {
            this.definitionList = result;
        }); 
    }
}