import { Component, OnInit, OnDestroy, Inject, ViewChild } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { DefaultValuesService } from '../../../../services/defaultvalues.service';
import { Utils } from '../../../../utils/utils';
import { CustomerItemListComponent } from './customeritem-list.component';
import { SaveService } from '../../../../services/save.service';
import { CustomerItemFilterComponent } from './customeritem-filter.component';
import { Subscription } from "rxjs";

@Component({
    selector: "customeritem",
    templateUrl: 'customeritem.component.html',
    styleUrls: []
})

export class CustomerItemComponent implements OnInit, OnDestroy {

    list: Customer[] = [];

    title = "Customers";
    showLocalFilter = false;

    subscriptionStage: any;
    subscriptionTenant: any;
    subscriptions: Subscription[] = [];

    @ViewChild(CustomerItemListComponent, { static: true }) listComponent!: CustomerItemListComponent;
    @ViewChild(CustomerItemFilterComponent, { static: true }) filterComponent!: CustomerItemFilterComponent;

    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string
        , private defaults: DefaultValuesService
        , private saveService: SaveService
    ) {

    }

    ngOnInit(): void {
        this.subscriptionStage = this.defaults.stageMessageChanges$.subscribe(() => { this.getAllCustomersByTenant() });
        this.subscriptionTenant = this.defaults.tenantMessageChanges$.subscribe(() => { this.getAllCustomersByTenant() });
        if (this.defaults.tenant)
            this.getAllCustomersByTenant()
    }

    ngOnDestroy() {
        this.subscriptionStage.unsubscribe();
        this.subscriptionTenant.unsubscribe();
        this.subscriptions.forEach(x => x.unsubscribe())
    }

    getAllCustomersByTenant() {
        this.onFilter({} as CustomerFilter);
    }

    onFilter(event: CustomerFilter) {
        if (this.subscriptions.length < 1) {
            Utils.httpGetCustomers(
                this.http
                , this.baseUrl
                , this.defaults.stage
                , event
                , this
                , function (tthis: CustomerItemComponent, list: Customer[]) {
                    tthis.list = list;
                    tthis.listComponent.clonedList = {}
                    tthis.listComponent.expandedElements = new Set();
                    tthis.saveService.showSaveButton(false)
                    if (!event.CustomerName && !event.CustomerId)
                        tthis.showLocalFilter = false;
                    else
                        tthis.showLocalFilter = true;
                    tthis.subscriptions.pop();
                }
            );
        }
    }
    onReload() {
        this.getAllCustomersByTenant();
        this.listComponent.clonedList = {}
        this.saveService.showSaveButton(false)
    }

    downloadCustomersFiltered() {
        let filter: CustomerFilter
        if (this.filterComponent.form) {
            filter = {
                'CustomerId': this.filterComponent.form.value.CustomerId,
                'CustomerName': this.filterComponent.form.value.CustomerName
            }

            Utils.httpDownloadCustomers(
                this.http
                , this.baseUrl
                , this.defaults.stage
                , this.defaults.tenant
                , filter
                , this
                , null)
        }
    }
}