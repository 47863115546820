<operationmode-menu (onNew)="onNew()" (onReload)="onReload()"></operationmode-menu>
<div style="display:flow-root">
    <div style="float:left; width:40vw">
        <h2>{{title}} for {{defaults.controllertype?.Description}} in {{defaults.language.Name}}</h2>
    </div>
</div>
<mat-tab-group animationDuration="500ms" #errorcodeTabGroup #tabGroup (selectedTabChange)="defaults.updateComponentTab($event.tab.textLabel)">
    <mat-tab label="Properties">
        <operationmode-list-properties [equals]="equals" [newService]="newMode" (onUpdateTable)="onUpdateTable()" [list]="list" 
                                       [isDigitalServicesView]="isDigitalServicesView"
                                       [openDeploymentInformationPopup]="openDeploymentInformationPopup"></operationmode-list-properties>
    </mat-tab>
    <mat-tab label="Description">
        <operationmode-description-list [list]="list" [newService]="newMode" (onUpdateDescriptions)="onUpdateDescriptions()"
                                        [openDeploymentInformationPopup]="openDeploymentInformationPopup"></operationmode-description-list>
    </mat-tab>
</mat-tab-group>