
<div class="container-tab-lists hrarchySetupCusotm">

    <div class="inner-tcontainer-tab tab-body">

        <h3>Assign assessment to branches</h3>
        <div class="new-form-style custom-mat-dialog_v2">
            <div class="tab-content">
                <form [formGroup]="form" class="internalMax ">

                    <div class="managementid-tree">
                        <div class="row heading" style="margin:2%">
                            <div style="width:40%">
                                <h4>
                                    <span>ManagementId</span>
                                    <span style="color:orange"> *</span>
                                </h4>
                            </div>

                            <div style="width:25%">
                                <input (keyup)="treeControl.expandAll()" class="form-control" [(ngModel)]="searchString" [ngModelOptions]="{standalone: true}" type="text" placeholder="Search" />
                            </div>

                            <div style="width:15%; margin:0px 20px">
                                <mat-checkbox (change)="selectAll($event.checked)"
                                              [checked]="selectAllCheckbox"
                                                [indeterminate]="manualItemSelected"> {{selectAllCheckbox ? 'Deselect' : 'Select'}} all Branches</mat-checkbox>
                            </div>

                        </div>
                        <div class="custom_small_panelbody_fix">
                            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
                                <!-- This is the tree node template for leaf nodes -->
                                <mat-tree-node *matTreeNodeDef="let node"
                                               matTreeNodeToggle
                                               [style.display]="showOnlySelected && !node.selected || this.searchString && hideLeafNode(node) ? 'none' : 'block'">
                                    <li class="mat-tree-node">
                                        <mat-checkbox class="checklist-leaf-node"
                                                      (change)="itemToggle($event.checked,node)"
                                                      [checked]="node.selected">{{node.groupName}} </mat-checkbox>
                                    </li>
                                </mat-tree-node>
                                <!-- This is the tree node template for expandable nodes -->
                                <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild"
                                                      [style.display]="(showOnlySelected && !(node.selected || node.indeterminate)) || this.searchString && hideParentNode(node) ? 'none' : 'block'">
                                    <li>
                                        <div class="mat-tree-node">
                                            <button mat-icon-button matTreeNodeToggle
                                                    [attr.aria-label]="'toggle ' + node.groupName">
                                                <mat-icon class="mat-icon-rtl-mirror">
                                                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                                </mat-icon>
                                            </button>
                                            <mat-checkbox [checked]="node.selected" [disabled]="true">{{node.groupName}}</mat-checkbox>
                                        </div>
                                        <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
                                            <ng-container matTreeNodeOutlet></ng-container>
                                        </ul>
                                    </li>
                                </mat-nested-tree-node>
                            </mat-tree>
                        </div>
                    </div>
                    <div class="row">
                        <div class="panel-body">
                            <button (click)="onCancelClick()" mat-raised-button class="btn  button-color-fix_red ">
                                <mat-icon class=" icon-color-fix ">arrow_back_ios</mat-icon>
                                Back
                            </button>
                            <button mat-raised-button (click)="onSubmit()" type="submit" class="btn button-color-fix pull-right" [disabled]="selectedBranches.length <= 0">
                                Save
                                <mat-icon class=" icon-color-fix ">save</mat-icon>
                            </button>

                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>