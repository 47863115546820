<div class="container-tab-lists new-table-style">

    <div class="inner-tcontainer-tab">

        <h2>{{title}} {{isAllLoaded === false ? waitHeader : null}}</h2>
        <div style="display:flow-root">
            <div style="float:left; width:40vw">
                <mat-form-field class="search-input">
                    <input placeholder="Filter"
                           matInput
                           [matChipInputFor]="chipList"
                           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                           [matChipInputAddOnBlur]="addOnBlur"
                           (matChipInputTokenEnd)="applyFilter($event)"
                           [disabled]="isAllLoaded === false">
                    <mat-chip-list #chipList>
                    </mat-chip-list>
                </mat-form-field>
                <mat-icon style="color:#ddd !important;" (click)="openFilterPopup()">filter_alt</mat-icon>
                <mat-chip *ngFor="let item of searchItems" [selectable]="selectable"
                          [removable]="removable" (removed)="remove(item)">
                    {{item.name}}
                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
            </div>
            <div style="text-align:right; float:right">

                <a class='kpi-save-menu save-button' title="Save" *ngIf="isEditModeEnabled" (click)="onClickSave()">
                    <span class='glyphicon glyphicon-floppy-disk'></span> Save
                </a>

                <!-- <a class='kpi-button-menu' title="Release Mode" (click)="onClickReleaseMode()" *ngIf="canUserRelease()">
                    {{'Release All'}}
                </a> -->

                <a class='kpi-button-menu' title="Release Mode" (click)="toggleRelease();isRevokeActive ? toggleRevoke():null" *ngIf="!isEditModeEnabled && canUserRelease()">
                    {{releaseButtonText}}
                </a>

                <!-- <a class='kpi-button-menu' title="Revoke all released values" (click)="onClickRevoke()" *ngIf="canUserRevoke()">
                    {{'Revoke All'}}
                </a> -->

                <a class='kpi-button-menu' title="Revoke all released values" (click)="toggleRevoke();isReleaseActive ? toggleRelease():null " *ngIf="!isEditModeEnabled && showReleaseLabel && canUserRevoke()">
                    {{revokeButtonText}}
                </a>

                <a class='kpi-button-menu' title="Edit values" (click)="onClickEdit()" *ngIf="!isExcelModeEnabled && canUserUpdate()">
                    <span class='glyphicon glyphicon-pencil'></span> {{isEditModeEnabled ? 'Undo' : 'Edit'}}
                </a>

                <a class='kpi-button-menu' title="Excel" (click)="onClickExcel()" *ngIf="!isExcelModeEnabled && !isEditModeEnabled">
                    Excel
                </a>

                <a class='kpi-button-menu' title="Dowload" (click)="onClickDownload()" *ngIf="isExcelModeEnabled">
                    <span class='glyphicon glyphicon-download'></span> Download
                </a>

                <a class='kpi-button-menu' title="Upload" (click)="onClickUpload()" *ngIf="isExcelModeEnabled && canUserUpdate()">
                    <span class='glyphicon glyphicon-upload'></span> Upload
                </a>

                <a class='kpi-button-menu' title="Excel" (click)="onClickExitExcel()" *ngIf="isExcelModeEnabled">
                    Exit Excel Mode
                </a>

                <a class='kpi-button-menu' title="Show All" (click)="onClickShowAllButton()" *ngIf="!isShowAllEnabled">
                    <span class='glyphicon glyphicon-eye-open'></span> Show All
                </a>

                <a class='kpi-button-menu' title="Hide empty" (click)="onClickShowAllButton()" *ngIf="isShowAllEnabled === true">
                    <span class='glyphicon glyphicon-eye-close'></span> Hide empty
                </a>

                <h5>
                    <span *ngIf="isShowAllEnabled === true">Showing all available entries </span>
                    <span *ngIf="isShowAllEnabled === false">Excluding empty values </span>
                    <span *ngIf="isEditModeEnabled === true"> / </span>
                    <span class="salmon-color" *ngIf="isEditModeEnabled === true">Editable kpi values</span>
                    <span *ngIf="showReleaseLabel === true && isEditModeEnabled === true">/</span>
                    <span class="green-release-color" *ngIf="showReleaseLabel === true">Released kpi values </span>
                </h5>

                <h5 [style.visibility]="isEditModeEnabled === true ? 'visible' : 'hidden'">
                    Edit Mode is enabled. Please click on Save button to save all changes
                </h5>
            </div>
        </div>
        <br />

        <segment-sheet-list [list]="list"
                            [isEditModeEnabled]="isEditModeEnabled"
                            [filter]="filter"
                            [auxFilterPredicate]="auxFilterPredicate"
                            [selectorFilter]="selectorFilter"
                            [selectorKpiUnitFilter]="selectorKpiUnitFilter"
                            (onUpdateSelectorKpiUnit)="updateSelectorKpiUnit($event)"
                            [inclusiveMode]="inclusiveMode"
                            [isShowAllEnabled]="isShowAllEnabled"
                            [isReleaseActive]="isReleaseActive && !isEditModeEnabled"
                            [isRevokeActive]="isRevokeActive && !isEditModeEnabled"
                            (onClickRevokeMode)="onClickRevoke()"
                            (onClickReleaseMode)="onClickReleaseMode()"></segment-sheet-list>
        <!--<div *ngIf='isLoading' class="blackbg card__desc">
            <div *ngFor="let _ of [].constructor(8)" class="card__desc-text lg"></div>
        </div> -->
    </div>
</div>