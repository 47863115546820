import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { Subject } from "rxjs";
import { SaveService } from "../../services/save.service";
import { DefaultValuesService } from "../../services/defaultvalues.service";
import { Utils } from "../../utils/utils";
import { DialogService } from "../../services/dialog.service";
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatDialog } from "@angular/material/dialog";
import { MaintenanceWarningResolutionFormComponent } from "./maintenancewarning-resolution-form.component";

@Component({
    selector: "maintenancewarning-list",
    templateUrl: './maintenancewarning-list.component.html',
    styleUrls: ['./maintenancewarning-list.component.less'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*', })),
            transition('expanded => collapsed', [animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
        ]),
    ]
})

export class MaintenanceWarningListComponent implements OnInit {
    @Input('list') list: MaintenanceWarning[] = [];
    @Output() onRefresh = new EventEmitter<{}>();

    columnsToDisplay = ['arrow', 'index', 'warningtype', 'description', 'actions'];
    subcolumnsToDisplay = ['possiblefailure', 'location', 'component', 'resolution', 'actions'];

    headerResol = [
        { label: 'Possible Failure', show: true, searchtype: 'text' }
        , { label: 'Location', show: true, searchtype: 'text' }
        , { label: 'Component', show: true, searchtype: 'text' }
        , { label: 'Resolution', show: true, searchtype: 'text' }
    ];

    @ViewChild(MatTable, { static: true }) table!: MatTable<any>;
    public dataSource = new MatTableDataSource<MaintenanceWarning>();

    expandedElements = new Set();
    expanded: boolean[] = [];
    selected: MaintenanceWarning | undefined;
    clonedList: { [s: string]: MaintenanceWarning } = {};

    protected _onDestroy = new Subject<void>();

    constructor(private activatedRoute: ActivatedRoute
        , private http: HttpClient
        , private saveService: SaveService
        , private defaults: DefaultValuesService
        , private matDialog: MatDialog
        , private dialog: DialogService
        , @Inject('BASE_URL') private baseUrl: string

    ) {
    }

    ngOnChanges() {
        this.dataSource.data = this.list
        this.expandedElements.forEach(x => {
            this.onExpand(x as number)
        })
        this.clonedList = {}
        this.saveService.showSaveButton(false)
        this.saveService.addListener(this)
    }

    ngOnInit() {
        this.getHeader();
        this.saveService.addListener(this);
    }

    ngOnDestroy() {
        this.saveService.removeListener(this);
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    getHeader() {
        if (!this.defaults.isReadonlyUser) {
            this.columnsToDisplay = ['arrow', 'index', 'warningtype', 'description', 'actions'];
            this.subcolumnsToDisplay = ['possiblefailure', 'location', 'component', 'resolution', 'actions'];
        }
        else {
            this.columnsToDisplay = ['arrow', 'index', 'warningtype', 'description'];
            this.subcolumnsToDisplay = ['possiblefailure', 'location', 'component', 'resolution'];
        }
    }

    checkExpanded(element: MaintenanceWarning) {
        return this.expandedElements.has(element.MaintenanceWarningID);
    }

    pushPopElement(element: MaintenanceWarning) {
        if (this.expandedElements.has(element.MaintenanceWarningID)) {
            this.expandedElements.delete(element.MaintenanceWarningID);
        }
        else {
            this.expandedElements.add(element.MaintenanceWarningID);
            this.onExpand(element.MaintenanceWarningID)
        }
    }

    onExpand(mwId: number) {
        const foundIndex = this.dataSource.data.findIndex((x: MaintenanceWarning) => x.MaintenanceWarningID === mwId);
        Utils.httpGetAllWarningResolutions(
            this.http
            , this.baseUrl
            , mwId
            , this
            , function (tthis: MaintenanceWarningListComponent, list: MaintenanceWarningResolution[]) {
                tthis.dataSource.data[foundIndex].MaintenanceWarningResolution = list;
            }
        );
    }

    onRowEditInit(maintenancewarning: MaintenanceWarning) {
        this.saveService.addListener(this);
        this.clonedList[maintenancewarning.MaintenanceWarningID] = { ...maintenancewarning };
        if (Object.keys(this.clonedList).length !== 0) this.onShowSavebutton()
    }

    onRowEditCancel(maintenancewarning: MaintenanceWarning) {
        this.dataSource.data[this.dataSource.data.findIndex(x => x.MaintenanceWarningID === maintenancewarning.MaintenanceWarningID)] = this.clonedList[maintenancewarning.MaintenanceWarningID];
        delete this.clonedList[maintenancewarning.MaintenanceWarningID];
        if (Object.keys(this.clonedList).length === 0) this.saveService.showSaveButton(false)
        this.table.renderRows();
    }

    isEditingRow(maintenancewarning: MaintenanceWarning) {
        return this.clonedList[maintenancewarning.MaintenanceWarningID]
    }

    onCreateNewResolution(element: MaintenanceWarning) {
        this.dialog.tthis = this;
        this.saveService.addListener(this);
        const dialog = this.matDialog.open(MaintenanceWarningResolutionFormComponent, { panelClass: ['custom-mat-dialog'] });
        dialog.componentInstance.list = element.MaintenanceWarningResolution
        dialog.componentInstance.parent = element
        dialog.componentInstance.header = this.headerResol
        dialog.afterClosed().subscribe(() => {
            this.onRefresh.emit({})
        });
    }

    onResolutionEditInit(event: MaintenanceWarningResolution) {
        const index = this.dataSource.data.findIndex(x => x.MaintenanceWarningID === event.MaintenanceWarningID)
        this.dialog.tthis = this;
        this.saveService.addListener(this);
        const dialog = this.matDialog.open(MaintenanceWarningResolutionFormComponent, { panelClass: ['custom-mat-dialog'] });
        dialog.componentInstance.list = this.dataSource.data[index].MaintenanceWarningResolution
        dialog.componentInstance.rowIndex = this.dataSource.data[index].MaintenanceWarningResolution.findIndex(x => x.ResolutionID === event.ResolutionID)
        dialog.componentInstance.header = this.headerResol
        dialog.afterClosed().subscribe(() => {
            this.onRefresh.emit({})
        });
    }

    // DELETION METHODS

    onDelete(value: MaintenanceWarningResolution) {
        this.dialog.showConfirmDialog("Delete Resolution '" + value.ResolutionID + "' for Maintenance Warning (" + value.MaintenanceWarningID + ")?", "Delete Resolution", this, value);
    }

    onYes(value: any) {
        this.http
            .delete<MaintenanceWarningResolution>(
                this.baseUrl + Utils.getMaintenanceWarningAPI() + 'resolution/' + value.ResolutionID
            )
            .subscribe(
                () => { 
                    this.onRefresh.emit({});
                }
                , error => this.dialog.showErrorDialog(error)
            );
    }

    onSave() {
        for (const i in this.clonedList) {
            const index = this.dataSource.data.findIndex(x => x.MaintenanceWarningID === this.clonedList[i].MaintenanceWarningID)
            const url = this.baseUrl + Utils.getMaintenanceWarningAPI() + "mergemaintenancewarning";
            this.http
                .post<MaintenanceWarning>(url, this.dataSource.data[index])
                .subscribe(() => {
                    this.onRefresh.emit({})
                },
                error => this.dialog.showErrorDialog(error));
        }
        this.clonedList = {}
        this.saveService.showSaveButton(false)
    }

    onShowSavebutton() {
        this.saveService.showSaveButton();
    }
}
