<div class="custom-mat-dialog-form">
    <div>
        <h3>Manage Controller Config</h3>
    </div>
    <div>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="mt-2">
                <label>Business Key *</label>
                <div class="input">
                    <div class="input">
                        <mat-select [(ngModel)]="body.ControllerConfigDescriptionID" class="form-control" formControlName="ControllerConfigDescriptionID" readonly>
                            <mat-option *ngFor="let desc of data.descriptions" [value]="desc.ControllerConfigDescriptionID">
                                {{ desc.BK }}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>
            </div>
            <div class="mt-2">
                <label>DataType *</label>
                <div class="input">
                    <mat-select [(ngModel)]="body.DataTypeID" class="form-control" formControlName="DataTypeID" readonly>
                        <mat-option *ngFor="let datatype of data.datatypes" [value]="datatype.ControllerconfigDataTypeID">
                            {{ datatype.BK }}
                        </mat-option>
                    </mat-select>
                </div>
            </div>

            <div class="mt-2 right">
                <button type="button"
                        class="btn btn-danger"
                        (click)="onNoClick()">
                    Close
                </button>
                <button type="button"
                        class="btn btn-primary"
                        (click)="onSubmit()">
                    Send
                </button>
            </div>
        </form>
    </div>
</div>
