<div class="container-tab-lists">
  
    <h2>{{title}}</h2>
    <mat-tab-group orientation="vertical" [selectedIndex]="selectedItem">
        <!--Messages-->
        <mat-tab>
            <ng-template mat-tab-label>
                Service Ticket Category
            </ng-template>
            <div class="inner-tcontainer-tab">
                <serviceticketcategory-list></serviceticketcategory-list>
            </div>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                Contract Line
            </ng-template>
            <div class="inner-tcontainer-tab">
                <contractline-list></contractline-list>
            </div>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                Contract Type
            </ng-template>
            <div class="inner-tcontainer-tab">
                <contracttype-list></contracttype-list>
            </div>
        </mat-tab>

    </mat-tab-group>
</div>