import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from
    "@angular/forms";
import { ActivatedRoute, Params } from '@angular/router';
import { forkJoin, EMPTY } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from "@angular/forms";
import { DialogService } from '../../../../../services/dialog.service';
import { UMPAction, UMPCategory, Utils } from '../../../../../utils/utils';


interface group {
    id: string;
    persona: string;
    isDirect: boolean;
    hidden: boolean;
    parentIds: any;
    IsPrimaryRole: boolean

    // Add more properties if needed
}



@Component({
    selector: "addgroupdialogue",
    templateUrl: './addgroupdialogue.component.html',
    styleUrls: ['../edituser.component.less', './addgroupdialogue.component.less'],
    encapsulation: ViewEncapsulation.None

})

export class AddGroupDialogue implements OnInit {
    public userName: String = '';
    userId = ''


    searchString: string = '';
    filteredSearchResults: any[] = [];

    AllGroups = [];
    UserAssignedGroups: group[] = [];
    UserUnAssignedGroups: group[] = [];
    IsDirIndirComponent = false;

    Response_State: number = 0; //0 -> empty , 1 -> correct and not empty ,-1 -> error
    errorResponse: any;
    isCallSucceed: number = 0;
    userPrimaryRole: group | null = null
    primaryRoles: string | undefined
    formControlGroups = new FormControl();

    constructor(
        private http: HttpClient
        , public dialogRef: MatDialogRef<AddGroupDialogue>
        , private confimDialog: DialogService
        , private toastrService: ToastrService,
        private route: ActivatedRoute,
        @Inject('BASE_URL') private baseUrl: string,
        @Inject(MAT_DIALOG_DATA) public data: any,

    ) {
        this.route.params.subscribe(
            (params: Params) => {
                this.resetAll();
            });

    }

    ngOnInit() {

    }
    resetAll() {
        this.userName = '';
        this.userId = ''


        this.searchString = '';
        this.filteredSearchResults = [];

        this.AllGroups = [];
        this.UserAssignedGroups = [];
        this.UserUnAssignedGroups = [];
        this.IsDirIndirComponent = false;

        this.Response_State = 0; //0 -> empty , 1 -> correct and not empty ,-1 -> error
        this.isCallSucceed = 0;

        this.formControlGroups = new FormControl();
        this.confimDialog.tthis = this;

        this.userId = this.data.userId;
        this.AllGroups = this.data.AllGroups;
        this.UserAssignedGroups = this.data.UserAssignedGroups;
        this.UserUnAssignedGroups = this.data.UserUnAssignedGroups;
        this.IsDirIndirComponent = this.data.IsDirIndirComponent ?? false;
        this.filteredSearchResults = this.UserUnAssignedGroups;
        this.updateRolesResults();
        this.getAssignedPrimaryRole()
    }
    async updateRolesResults() {
        //this.filteredSearchResults = this.searchByValue(this.UserUnAssignedGroups, "persona", this.searchString);
        this.filteredSearchResults = this.filterAndShowResults(this.filteredSearchResults, "persona", this.searchString);
    }

    searchByValue(items: any, filterProperty: string, query: string) {

        return items.filter(item => {
            if (query.trim() === '') {
                return true;
            } else {
                return item[filterProperty].toLowerCase().includes(query.trim().toLocaleLowerCase())
            }
        })
    }
    filterAndShowResults(list: any, filterProperty: string, searchString: string) {
        // If the search string is empty, show all items
        if (searchString === "") {
            return list.map(item => ({ ...item, hidden: false }));
        }

        // Filter and update hidden property based on search
        const filteredList = list.map(item => ({
            ...item,
            hidden: !item[filterProperty].toLowerCase().includes(searchString.toLowerCase())
        }));

        return filteredList;
    }


    funcNotify() {
        if (this.isCallSucceed === 1) {
            this.toastrService.success(this.data.title + ' request sent succefully !', 'Add ' + this.data.title + ' Request!');
        }
        else if (this.isCallSucceed === -1) {
            this.toastrService.error(this.data.title + ' request not sent succefully !', 'Add ' + this.data.title + ' Request!');
        }
    }

    getUnselectedItems(allList: any, selectedList: any) {

        this.UserUnAssignedGroups = allList.filter(item => !selectedList.some(selectedItem => selectedItem.id === item.id));
        this.updateRolesResults();

    }

    addButtonHandler(ids) {
        this.isCallSucceed = 0;
        Utils.httpPostGroupsByType(
            this.http
            , this.baseUrl
            , {
                "Personae": ids,
                "UmpAction": UMPAction.Assign,
                "UmpCategory": this.data.umpCategory,
                "Type": this.data.type
            }
            , this.userId            
            , this
            , function (tthis: AddGroupDialogue, data: any) {
                tthis.funcNotify();
                tthis.dialogRef.close();
                if (tthis.data.callback)
                    tthis.data.callback(data);
            }
        )
    }
    backButtonHandler(event: any) {
        event.preventDefault();
        this.dialogRef.close();

    }

    getAssignedPrimaryRole() {
        const combinedArray: any[] = [];
        for (const obj of this.UserAssignedGroups) {
            const group = this.data.AllGroups.filter(x => x.persona === obj.persona)[0]
            combinedArray.push({ ...obj, isPrimaryRole: group.isPrimaryRole });
        }

        this.userPrimaryRole = combinedArray.filter(x => x.isPrimaryRole === true)[0]
        this.primaryRoles = this.data.AllGroups.filter(x => x.isPrimaryRole).map(x => x.persona).join(", ");
        if (this.userPrimaryRole)
            this.filteredSearchResults = this.filteredSearchResults.filter(x => !x.isPrimaryRole)
    }

    hasAdditionalCosts(persona: string) {
        return Utils.hasAdditionalLicenseCost(persona)
    }
}
