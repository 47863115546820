<div class="container-tab-lists new-table-style">

    <div class="inner-tcontainer-tab">

        <h2>{{title}} {{isAllLoaded === false && !hideSections() ? waitHeader : null}}</h2>
        <div style="display:flow-root">
            <div style="float:left; width:40vw" *ngIf="!hideSections()">
                <mat-form-field class="search-input">
                    <input placeholder="Filter"
                           matInput
                           [matChipInputFor]="chipList"
                           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                           [matChipInputAddOnBlur]="addOnBlur"
                           (matChipInputTokenEnd)="applyFilter($event)"
                           [disabled]="isAllLoaded === false">
                    <mat-chip-list #chipList>
                    </mat-chip-list>
                </mat-form-field>
                <mat-icon style="color:#ddd !important;" (click)="openFilterPopup()">filter_alt</mat-icon>
                <mat-chip *ngFor="let item of searchItems" [selectable]="selectable"
                          [removable]="removable" (removed)="remove(item)">
                    {{item.name}}
                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
            </div>
            <div style="text-align:right; float:right">

                <a class='kpi-save-menu save-button' title="Save" *ngIf="isEditModeEnabled && !isExcelModeEnabled" (click)="onClickSave()">
                    <span class='glyphicon glyphicon-floppy-disk'></span> Save
                </a>

                <a class='kpi-button-menu' title="Release Mode" (click)="onClickReleaseMode()" *ngIf="(defaults.isMersyReporterUser || defaults.isMersyRevokerUser || defaults.isMersyRevokerBranchReportingUser || defaults.isMersyAdminUser) && hideSections()">
                    {{'Release All'}}
                </a>

                <a class='kpi-button-menu' title="Release Mode" (click)="toggleRelease();isRevokeActive ? toggleRevoke():null" *ngIf="!isEditModeEnabled && (defaults.isMersyReporterUser || defaults.isMersyRevokerUser || defaults.isMersyRevokerBranchReportingUser || defaults.isMersyAdminUser) && !hideSections()">
                    {{releaseButtonText}}
                </a>

                <a class='kpi-button-menu' title="Revoke all released values" (click)="onClickRevoke()" *ngIf="(defaults.isMersyRevokerUser || defaults.isMersyRevokerBranchReportingUser || defaults.isMersyAdminUser) && hideSections()">
                    {{'Revoke All'}}
                </a>

                <a class='kpi-button-menu' title="Revoke all released values" (click)="toggleRevoke();isReleaseActive ? toggleRelease():null " *ngIf="!isEditModeEnabled && showReleaseLabel && (defaults.isMersyRevokerUser || defaults.isMersyRevokerBranchReportingUser || defaults.isMersyAdminUser) && !hideSections()">
                    {{revokeButtonText}}
                </a>

                <a class='kpi-button-menu' title="Excel" (click)="onClickExcelButton()" *ngIf="!isExcelModeEnabled && !isEditModeEnabled && !isApprovalModeEnabled">
                    Excel
                </a>

                <a class='kpi-button-menu' title="Download OPL previous year" (click)="downloadOplInformationFromLastYear()">
                    <span class='glyphicon glyphicon-download'></span> Download Monthly OPL Previous Year
                </a>

                <a class='kpi-button-menu' title="Download" *ngIf="isExcelModeEnabled" (click)="downloadExcelInformation()">
                    <span class='glyphicon glyphicon-download'></span> Download
                </a>

                <a class='kpi-button-menu' title="Upload" *ngIf="isExcelModeEnabled" (click)="goUploadFileView()">
                    <span class='glyphicon glyphicon-upload'></span> Upload
                </a>

                <a class='kpi-button-menu' title="Cancel" *ngIf="isExcelModeEnabled" (click)="onClickCancelButton()">
                    {{'Exit Excel mode'}}
                </a>

                <a class='kpi-button-menu' title="Edit values" (click)="onClickEdit()" *ngIf="!isApprovalModeEnabled && !isExcelModeEnabled && !hideSections()">
                    <span class='glyphicon glyphicon-pencil'></span> {{isEditModeEnabled ? 'Undo' : 'Edit'}}
                </a>

                <a class='kpi-button-menu' title="Show All" (click)="onClickShowAllButton()" *ngIf="isShowAllEnabled === false && !hideSections()">
                    <span class='glyphicon glyphicon-eye-open'></span> Show All
                </a>

                <a class='kpi-button-menu' title="Hide empty" (click)="onClickShowAllButton()" *ngIf="isShowAllEnabled === true && !hideSections()">
                    <span class='glyphicon glyphicon-eye-close'></span> Hide empty
                </a>

                <h5 *ngIf="!hideSections()">
                    <span *ngIf="isShowAllEnabled === true">Showing all available entries </span>
                    <span *ngIf="isShowAllEnabled === false">Excluding empty values </span>
                    <span *ngIf="showReleaseLabel === true || isEditModeEnabled === true"> / </span>
                    <span class="salmon-color" *ngIf="isEditModeEnabled === true">Editable kpi values</span>
                    <span *ngIf="showReleaseLabel === true && isEditModeEnabled === true">/</span>
                    <span class="green-release-color" *ngIf="showReleaseLabel === true">Released kpi values </span>
                </h5>

                <h5 *ngIf="!hideSections" [style.visibility]="isEditModeEnabled === true ? 'visible' : 'hidden'">
                    Edit Mode is enabled. Please click on Save button to save all changes
                </h5>
            </div>
        </div>
        <br />

        <actualreporting-list [list]="list"
                              [isEditModeEnabled]="isEditModeEnabled"
                              [filter]="filter"
                              [selectorFilter]="selectorFilter"
                              [inclusiveMode]="inclusiveMode"
                              [isReleaseModeEnabled]="isApprovalModeEnabled"
                              [auxFilterPredicate]="auxFilterPredicate"
                              [isShowAllEnabled]="isShowAllEnabled"
                              [isReleaseActive]="isReleaseActive && !isEditModeEnabled"
                              [isRevokeActive]="isRevokeActive && !isEditModeEnabled"
                              (onClickRevokeMode)="onClickRevoke()"
                              (onClickReleaseMode)="onClickReleaseMode()"
                              *ngIf="!hideSections()"></actualreporting-list>
    </div>
</div>