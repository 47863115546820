import { Component, Inject, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DefaultValuesService } from "../../../services/defaultvalues.service";
import { CountryBackendEntity } from "../../../interfaces/countrybackendentity";
import { ContractItemComponent } from "./contractitem/contractitem.component";
import { FacilityManagerComponent } from "./facilitymanager/facilitymanager.component";
import { ContractBundleComponent } from "./contractbundle/contractbundle.component";
import { MatDialog } from "@angular/material/dialog";
import { ContractBundlePopupComponent } from "./contractbundle/contractbundlepopup.component";
import { MatTabGroup } from "@angular/material/tabs";

@Component({
    selector: "contracts",
    templateUrl: './contract.component.html',
    styleUrls: ['../../../style/container-tab-list.component.less']
})

export class ContractComponent implements OnInit {
    title: string;

    currentType: CountryBackendEntity = CountryBackendEntity.Contract;

    @ViewChild('contractItemComponent', { static: true }) contractItemComponent!: ContractItemComponent;
    @ViewChild('managerComponent', { static: false }) managerComponent!: FacilityManagerComponent;
    @ViewChild('contractBundle', { static: false }) contractBundle!: ContractBundleComponent;
    @ViewChild('tabGroup') tabGroup!: MatTabGroup;

    constructor(
        private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , public defaults: DefaultValuesService
        , private dialogForm: MatDialog

    ) {
        this.title = "All Assets";
    }

    ngOnInit(): void { }
    ngAfterViewInit() {
        this.defaults.defaultComponentTab(this)
    }

    changeAssetType($event) {
        switch ($event.index) {
            case 0:
                this.currentType = CountryBackendEntity.Contract; break;
            case 1:
                this.currentType = CountryBackendEntity.ContractFacilityManager; break;
            case 2:
                this.currentType = CountryBackendEntity.FeatureGroups; break;
            default:
                break;
        }
    }

    onReload() {
        switch (this.currentType) {
            case CountryBackendEntity.Contract:
                this.contractItemComponent.onReload();
                break;
            case CountryBackendEntity.ContractFacilityManager:
                this.managerComponent.onReload();
                break;
            default:
                this.contractBundle.onReload();
                break;
        }
    }
    downloadExcelFile() {
        switch (this.currentType) {
            case CountryBackendEntity.Contract:
                this.contractItemComponent.downloadContractsFiltered();
                break;
            case CountryBackendEntity.ContractFacilityManager:
                this.managerComponent.downloadFacilityManagerFiltered();
                break;
            default: break;
        }
    }
    openContractBundlePopup() {

        const availableBundles = this.contractBundle.contractBundleOptions.filter(x => !this.contractBundle.list.some(y => y.Bundle === x.Bundle));

        const Dialog = this.dialogForm.open(ContractBundlePopupComponent, {
            panelClass: 'custom-mat-dialog',
            disableClose: true,
            data: { "list": availableBundles }
        })
        Dialog.afterClosed().subscribe(result => {
            this.onReload();
        });
    }
}

