<div class="dialog">
    <div>
        <span class="material-icons" *ngIf="data.title === 'Info'">info</span>
        <span class="material-icons" *ngIf="data.title === 'Warning'">warning</span>
        <span class="material-icons" *ngIf="data.title === 'Error'">error</span>
        <span mat-dialog-title>{{ data.title }}</span>
    </div>
    
    <mat-dialog-content style="margin-bottom: 20px;">
        <p>{{ data.message }}</p>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-raised-button color="success" (click)="onOk()">OK</button>
    </mat-dialog-actions>
</div>

