import { Component, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Utils } from "../../utils/utils";
import { DefaultValuesService } from "../../services/defaultvalues.service";
import { Subscription } from "rxjs";

@Component({
    selector: "changelog",
    templateUrl: './changelog.component.html',
    styleUrls: ['../../style/container-tab-list.component.less']
})

export class ChangeLogComponent {
    title: string;
    list: ChangeLog[] = [];
    subscriptions: Subscription[] = [];
    subscriptionControllerType: any;

    constructor(
        private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , private defaults: DefaultValuesService
    ) {
        this.title = "Change Log";
        this.getAll();
    }
    ngOnInit() {
        this.subscriptionControllerType = this.defaults.controllerTypeMessageChanges$.subscribe(() => { this.getAll() });
    }
    ngOnDestroy() {
        this.subscriptionControllerType.unsubscribe();
    }
    getAll() {
        Utils.httpGetAllChangeLog(
            this.http
            , this.baseUrl
            , this
            , function (tthis: any, list: ChangeLog) {
                tthis.list = list;
            }
        );
    }
}