import { Component, Inject, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { DialogService } from '../../services/dialog.service';
import { Utils } from '../../utils/utils';
import { SaveService } from '../../services/save.service';
import { DefaultValuesService } from '../../services/defaultvalues.service';
import { MatDialog } from "@angular/material/dialog";

@Component({
    selector: 'maintenancewarning-resolution-form'
    , templateUrl: './maintenancewarning-resolution-form.component.html'
    , styleUrls: ['../../style/form.component.less', './maintenancewarning.less']
})
export class MaintenanceWarningResolutionFormComponent implements OnInit {

    @Input() list: MaintenanceWarningResolution[] = [];
    @Input() header: any[] = [];
    @Input() rowIndex!: number;
    @Input() parent: MaintenanceWarning = {} as MaintenanceWarning;

    title: string;

    selected: MaintenanceWarningResolution = {} as MaintenanceWarningResolution;
    locations: ComponentSubSystem[] = [];
    components: ComponentItem[] = [];

    classname: string[] = [];
    isundodisabled: boolean[] = [];

    newRow = true;
    isToShowRef = false;

    form: FormGroup = new FormGroup({});

    POSSIBLEFAILURE = 0;
    LOCATION = 1;
    COMPONENT = 2;
    RESOLUTION = 3;

    constructor(private http: HttpClient
        , private dialog: DialogService
        , private defaults: DefaultValuesService
        , private matDialog: MatDialog
        , private saveService: SaveService
        , @Inject('BASE_URL') private baseUrl: string
    ) {

        this.title = "Manage Maintenance Warning Resolution";
    }

    ngOnInit() {

        if (this.header.length === 0) { //this is a new form
            this.header = this.dialog.getHeader();
            this.parent = this.dialog.getParent();
        }
        this.newRow = (this.rowIndex === undefined);
        this.isToShowRef = this.defaults.isToShowReference();
        this.saveService.addListener(this);
        this.saveService.initUndo(this);

        Utils.httpGetAllLocations(
            this.http
            , this.baseUrl
            , this
            , function (tthis: MaintenanceWarningResolutionFormComponent, list: ComponentSubSystem[]) {
                tthis.locations = list;
                tthis.createForm();
                if (!tthis.newRow && tthis.rowIndex !== undefined) {
                    tthis.selected.MaintenanceWarningID = tthis.list[tthis.rowIndex].MaintenanceWarningID;
                    tthis.selected.PossibleFailure = tthis.list[tthis.rowIndex].PossibleFailure;
                    tthis.selected.Location = tthis.list[tthis.rowIndex].Location;
                    tthis.selected.ComponentSubSystemID = tthis.list[tthis.rowIndex].ComponentSubSystemID;
                    tthis.selected.Component = tthis.list[tthis.rowIndex].Component;
                    tthis.selected.ComponentItemID = tthis.list[tthis.rowIndex].ComponentItemID;
                    tthis.selected.Resolution = tthis.list[tthis.rowIndex].Resolution;
                    tthis.updateForm();
                }

                const ttthis = tthis;
                tthis.header.forEach(
                    function (value: any, index: number, array: any[]) {
                        ttthis.classname[index] = "";
                        ttthis.isundodisabled[index] = true;
                    }
                );
            }
        );
    }

    onCancelForm() {
        const value = this.rowIndex === undefined ? -1 : this.rowIndex;
        this.saveService.fireCancel(value, this);
    }

    /**
     * called by the SaveService
     * */
    onSave() {
        if (this.newRow)
            this.createObject();
        else if (this.rowIndex !== undefined) {
            this.selected = this.list[this.rowIndex];
            this.selected.PossibleFailure = this.form.controls['PossibleFailure'].value
            this.selected.Resolution = this.form.controls['Resolution'].value
        }


        const tthis = this;
        this.http
            .post<MaintenanceWarningResolution>(
                this.baseUrl + Utils.getMaintenanceWarningAPI() + "mergemaintenancewarningresolution"
                , this.selected
            )
            .subscribe(
                res => {
                    if (tthis.rowIndex !== undefined) {
                        tthis.list[tthis.rowIndex] = res;
                    }
                    else if (tthis.newRow)
                        tthis.list.unshift(res);

                    tthis.saveService.removeListener(tthis)
                    tthis.onCancel();
                }
                , error => {
                    tthis.dialog.showErrorDialog(error);
                }
            );
    }

    /**
     * called by the save button on this form
     * 
     * */
    onSubmit() {
        const value = this.rowIndex === undefined ? -1 : this.rowIndex;
        this.saveService.fireSave(value, this);
    }

    private createForm() {
        this.form = new FormGroup({
            PossibleFailure: new FormControl(''),
            Location: new FormControl('', [Validators.required]),
            Component: new FormControl(''),
            Resolution: new FormControl('', [Validators.required]),
        });

        let tthis = this;
        this.form.controls['Location'].valueChanges.subscribe(
            value => {
                if (!value) return;
                Utils.httpGetAllComponents(
                    tthis.http
                    , tthis.baseUrl
                    , tthis
                    , value
                    , function (tthis: any, list: ComponentItem[]) {
                        tthis.components = list;
                    }
                );
                if (tthis.form && tthis.selected && tthis.rowIndex !== undefined
                    && tthis.form.controls['Location'].value !== tthis.selected.ComponentSubSystemID) {
                    tthis.list[tthis.rowIndex].ComponentSubSystemID = tthis.form.controls['Location'].value;
                    tthis.setUndoDisable(tthis.LOCATION, false);
                }
            });
        this.form.controls['Component'].valueChanges.subscribe(
            value => {
                if (tthis.form && tthis.selected && tthis.rowIndex !== undefined
                    && tthis.form.controls['Component'].value !== tthis.selected.ComponentItemID) {
                    tthis.list[tthis.rowIndex].ComponentItemID = value;
                    tthis.setUndoDisable(tthis.COMPONENT, false);
                }
            });

    }

    private createObject() {
        this.selected.MaintenanceWarningID = this.parent.MaintenanceWarningID;

        this.selected.PossibleFailure = this.form.controls['PossibleFailure'].value;
        this.selected.ComponentSubSystemID = this.form.controls['Location'].value;
        this.selected.ComponentItemID = this.form.controls['Component'].value;
        this.selected.Resolution = this.form.controls['Resolution'].value;
    }

    private updateForm() {
        if (this.form && this.rowIndex !== undefined) {
            Utils.httpGetAllComponents(
                this.http
                , this.baseUrl
                , this
                , this.list[this.rowIndex].ComponentSubSystemID
                , function (tthis: any, list: ComponentItem[]) {
                    tthis.components = list;
                }
            );

            const e = {
                PossibleFailure: this.list[this.rowIndex].PossibleFailure
                , Location: this.list[this.rowIndex].ComponentSubSystemID
                , Component: this.list[this.rowIndex].ComponentItemID
                , Resolution: this.list[this.rowIndex].Resolution
            };

            console.log(e)
            this.form.setValue(e);
        }
    }

    private setUndoDisable(index: number, value: boolean) {
        this.classname[index] = value ? "" : Utils.getEditingInputClassName();
        this.isundodisabled[index] = value;
    }

    onCancel() {
        this.matDialog.closeAll();
    }
}


