import { Component, EventEmitter, Inject, Input, Output, SimpleChanges, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Utils } from "../../utils/utils";
import { DefaultValuesService } from "../../services/defaultvalues.service";
import { SaveService } from "../../services/save.service";
import { DialogService } from "../../services/dialog.service";
import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { Sort } from "@angular/material/sort";
import { MatChipInputEvent } from "@angular/material/chips";

@Component({
    selector: "operationmode-description-list",
    templateUrl: './operationmode-list-description.component.html',
    styleUrls: ['../../style/mat-table.component.less', './operationmode-list-description.component.less']
})
export class OperationModeDescriptionListComponent {
    @Input('list') list: OperationMode[] = [];
    @Input() newService: SaveService | undefined;
    @Input('openDeploymentInformationPopup') openDeploymentInformationPopup!: Function

    @Output() onUpdateDescriptions = new EventEmitter<{}>();

    @ViewChild(MatTable, { static: true }) table!: MatTable<any>;

    selected: OperationMode | undefined;

    columnsToDisplay = ['offset', 'value', 'moderef', 'mode', 'descref', 'description', 'actions'];
    clonedList: { [s: string]: OperationMode; } = {};
    public dataSource = new MatTableDataSource<any>();


    filter: string = '';

    subscriptionLanguage: any;
    subscriptionTenant: any;
    subscriptionIsControllerOwner: any;

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [COMMA, TAB, ENTER];
    searchItems: SearchItem[] = [];

    constructor(private activatedRoute: ActivatedRoute,
        private router: Router
        , private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , public defaults: DefaultValuesService
        , public saveService: SaveService
        , private dialog: DialogService
        , private matDialog: MatDialog

    ) {

    }

    ngOnChanges(changes: SimpleChanges) {
        this.dataSource.data = this.list
        console.log(".....changes data.... ");
        this.saveService.addListener(this);
    }

    ngOnInit() {
        this.clonedList = {}
        this.getHeader()
        this.subscriptionLanguage = this.defaults.languageMessageChanges$.subscribe(() => {
            this.getHeader()
        });
        this.subscriptionTenant = this.defaults.tenantMessageChanges$.subscribe(() => {
            this.getHeader()
        });
        this.subscriptionIsControllerOwner = this.defaults.isControllerOwnerChange$.subscribe(() => {
            this.getHeader()
        });

        this.dataSource.filterPredicate = (data: any, filters: string) => {
            const matchFilter: any[] = [];
            const filterArray = filters.split('+').filter(x => x);

            // Main
            if (filterArray.length === 1 && filterArray[0] === 'checkboxes')
                return true;
            else {
                filterArray.forEach((filter) => {
                    let result = false;
                    const value = data.Value ? data.Value : ''
                    const mode = data.Mode ? data.Mode : ''
                    const modeRef = data.ModeReference ? data.ModeReference : ''
                    const desc = data.Description ? data.Description : ''
                    const descRef = data.DescriptionReference ? data.DescriptionReference : ''

                    result = this.contains(mode, filter) ||
                        this.contains(value, filter) ||
                        this.contains(desc, filter)

                    if ((this.isToShowReference() && this.defaults.getIsControllerOwner()) ||
                        (this.defaults.language.LanguageCultureName && this.defaults.language.LanguageCultureName.includes(this.defaults.tenant)) ||
                        (this.defaults.isadminuser && this.isToShowReference()))
                        result = result ||
                            this.contains(descRef, filter) ||
                            this.contains(modeRef, filter)

                    return matchFilter.push(result);
                });
                return matchFilter.some(Boolean);
            }
        };
    }


    ngOnDestroy() {
        this.subscriptionTenant.unsubscribe();
        this.subscriptionLanguage.unsubscribe();
    }

    contains(text: string | number, searchValue: string) {
        return text.toString().toLowerCase().indexOf(searchValue.toLowerCase()) > -1
    }

    getHeader() {
        if (((this.isToShowReference() && this.defaults.getIsControllerOwner())
            || (this.defaults.isadminuser && this.isToShowReference())
            || (this.isToShowReference() && this.defaults.language.LanguageCultureName.indexOf(this.defaults.tenant) > -1)) && !this.defaults.isReadonlyUser)
            this.columnsToDisplay = ['offset', 'value', 'moderef', 'mode', 'descref', 'description', 'actions'];
        else if (this.isToShowReference() && !this.defaults.getIsControllerOwner())
            this.columnsToDisplay = ['offset', 'value', 'moderef', 'mode', 'descref', 'description'];
        else if ((this.defaults.getIsControllerOwner() ||
            (this.defaults.isadminuser && !this.isToShowReference()) ||
            (this.defaults.language.LanguageCultureName.indexOf(this.defaults.tenant) > -1)) && !this.defaults.isReadonlyUser)
            this.columnsToDisplay = ['offset', 'value', 'mode', 'description', 'actions'];
        else
            this.columnsToDisplay = ['offset', 'value', 'mode', 'description'];
        this.clonedList = {}
    }

    isToShowReference(): boolean {
        return this.defaults.language.LanguageId != Utils.getDefaultLaguangeID();
    }

    notTranslatedClass(text: string): string {
        return Utils.notTranslatedClass(text);
    }

    onEdit(operationmode: OperationMode) {
        this.selected = operationmode;
        this.router.navigate(["opmode/edit", this.selected.Value]);
    }

    onRowEditInit(opMode: OperationMode) {
        this.saveService.addListener(this);
        this.clonedList[opMode.Value] = { ...opMode };
        if (Object.keys(this.clonedList).length !== 0) this.onShowSavebutton()
    }

    onRowEditSave(opMode: OperationMode) {
        delete this.clonedList[opMode.Value];
    }

    onRowEditCancel(opMode: OperationMode) {
        let index = this.dataSource.data.findIndex(x => x.Value === opMode.Value)
        this.dataSource.data[index].Description = this.clonedList[opMode.Value].Description;
        this.dataSource.data[index].DescriptionReference = this.clonedList[opMode.Value].DescriptionReference;
        this.dataSource.data[index].Mode = this.clonedList[opMode.Value].Mode;
        this.dataSource.data[index].ModeReference = this.clonedList[opMode.Value].ModeReference;
        delete this.clonedList[opMode.Value];
        if (Object.keys(this.clonedList).length === 0) this.saveService.showSaveButton(false)
        this.table.renderRows();
    }
    isEditingRow(element: OperationMode) {
        return this.clonedList[element.Value]
    }
    onShowSavebutton() {
        this.saveService.showSaveButton();
    }
    onSave() {
        for (let i in this.clonedList) {
            let index = this.dataSource.data.findIndex(x => x.Value === this.clonedList[i].Value)
            if (Object.entries(this.clonedList[i]).toString() !== Object.entries(this.dataSource.data[index]).toString()) {
                this.http
                    .post<OperationMode>(
                        this.baseUrl + Utils.getOperationModeAPI() + "translate"
                        , this.dataSource.data[index]
                    )
                    .subscribe(
                        (res: OperationMode) => {
                            this.dataSource.data[index] = this.list[index] = res
                            delete this.clonedList[this.clonedList[i].Value];
                            this.table.renderRows();
                            this.updatefilter()
                            this.onUpdateDescriptions.emit();
                        }, error => {
                            this.dataSource.data[index] = this.clonedList[i];
                            delete this.clonedList[i];
                            this.dialog.showErrorDialog(error);
                        }
                    );
            }
            else {
                delete this.clonedList[this.clonedList[i].Value];
            }
        }
    }

    public onNewRowEvent() {
        this.table.renderRows();
        this.updatefilter()
        this.saveService.addListener(this)
    }

    // Mat table functions
    sortData(sort: Sort) {
        const data = this.dataSource.data.slice();
        if (!sort.active || sort.direction === '') {
            this.dataSource.data = data;
            return;
        }
        this.dataSource.data = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'value':
                    return this.compare(a.Value, b.Value, isAsc);
                case 'moderef':
                    return this.compare(a.ModeReference ? a.ModeReference.toUpperCase() : '', b.ModeReference ? b.ModeReference.toUpperCase() : '', isAsc);
                case 'mode':
                    return this.compare(a.Mode ? a.Mode.toUpperCase() : '', b.Mode ? b.Mode.toUpperCase() : '', isAsc);
                case 'descref':
                    return this.compare(a.DescriptionReference ? a.DescriptionReference.toUpperCase() : '', b.DescriptionReference ? b.DescriptionReference.toUpperCase() : '', isAsc);
                case 'desc':
                    return this.compare(a.Description ? a.Description.toUpperCase() : '', b.Description ? b.Description.toUpperCase() : '', isAsc);
                default:
                    return 0;
            }
        });
    }
    compare(a: number | string, b: number | string, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    applyFilter(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            this.searchItems.push({ name: value.replace('+', '').trim() });
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }
        this.updatefilter()
    }

    remove(item: SearchItem): void {
        const index = this.searchItems.indexOf(item);

        if (index >= 0) {
            this.searchItems.splice(index, 1);
        }
        this.updatefilter()
    }

    updatefilter() {
        let filterString: string
        if (this.searchItems.length === 0) {
            this.dataSource.filter = ''
        }
        else {
            filterString = this.searchItems.map(e => { return e.name }).join('+')
            this.dataSource.filter = filterString
        }
    }
}

export interface SearchItem {
    name: string;
}