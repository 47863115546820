<form [formGroup]="form">
    <table>
        <tbody class="inner">
            <tr>
                <td>&nbsp;</td>
                <td>
                    <span *ngIf="!newRow">{{selected.Location}}</span>
                    <select id="Location"
                            formControlName="Location" required
                            class="form-control"
                            [ngClass]="classname[LOCATION]"
                            title="{{header[LOCATION].label}}"
                            (change)="onLocationChange($event)">
                        <option *ngFor="let v of locations" value="{{v.ComponentSubSystemID}}">{{v.Description}}</option>
                    </select>
                    <button *ngIf="!newRow" type="button" class="btn btn-default" title="Undo changes" [disabled]="isundodisabled[LOCATION] == true" (click)="onLocationUndo()">
                        <span class='glyphicon glyphicon-arrow-left'></span>
                    </button>
                </td>
                <td>
                    <span *ngIf="!newRow">{{selected.Component}}</span>
                    <select id="Component"
                            formControlName="Component" required
                            class="form-control"
                            [ngClass]="classname[COMPONENT]"
                            title="{{header[COMPONENT].label}}"
                            (change)="onComponentChange()">
                        <option *ngFor="let v of components" value="{{v.ComponentItemID}}">{{v.Description}}</option>
                    </select>
                    <button *ngIf="!newRow" type="button" class="btn btn-default" title="Undo changes" [disabled]="isundodisabled[COMPONENT] == true" (click)="onComponentUndo()">
                        <span class='glyphicon glyphicon-arrow-left'></span>
                    </button>
                </td>
                <td>&nbsp;</td>
                <td>
                    <button type="button"
                            class="btn btn-warning"
                            (click)="onRowCancel()">
                        Cancel
                    </button>
                </td>

            </tr>
        </tbody>
    </table>
</form>