<div class="example-container">
    <table mat-table
           [dataSource]="dataSource"
           multiTemplateDataRows
           class="mat-elevation-z8 mat-table-custom" id="testcase-databricks-list">

        <ng-container matColumnDef="index">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element; let i = dataIndex">
                <p>{{i+1}}</p>
            </td>
        </ng-container>

        <ng-container matColumnDef="deviceId">
            <th mat-header-cell *matHeaderCellDef> DeviceId </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.DeviceId"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="dateFrom">
            <th mat-header-cell *matHeaderCellDef> DateFrom </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.DateFrom"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="dateTo">
            <th mat-header-cell *matHeaderCellDef> dateTo </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.DateTo"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="executed">
            <th mat-header-cell *matHeaderCellDef> Executed </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.Executed"></p>
            </td>
        </ng-container>

        <tr mat-header-row class="tableheader" *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; let i = dataIndex; columns: columnsToDisplay;" class="item">
        </tr>
    </table>
</div>
