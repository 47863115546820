<div class="example-container">
    <mat-form-field class="search-input max-30w">
        <input placeholder="Filter"
               matInput
               (keyup)="applyFilter($event)">
    </mat-form-field>
    <table mat-table
           [dataSource]="dataSource"
           multiTemplateDataRows
           matSort
           class="mat-elevation-z8 mat-table-custom" id="allowedactionslist">
        <ng-container *ngFor="let item of columnsToDisplay" matColumnDef="{{item}}">
            <th mat-header-cell *matHeaderCellDef>
                {{item === 'actions' ? '' : item}}
            </th>
            <ng-container *ngIf="item==='actions'">
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="defaults.isControllerOwner">
                        <button mat-icon-button (click)="changeElement(element,'IsSupported' , true)" matTooltip="Allow Remote Action Value" *ngIf="!getInput(element,'IsSupported') && isEditable(element.Category)">
                            <mat-icon class="mat-icon-grey">toggle_off</mat-icon>
                        </button>
                        <button mat-icon-button (click)="changeElement(element,'IsSupported', false)" matTooltip="Disallow Remote Action" *ngIf="getInput(element,'IsSupported') && isEditable(element.Category)">
                            <mat-icon class="mat-icon-green">toggle_on</mat-icon>
                        </button>
                        <button mat-icon-button matTooltip="{{element.Category}} Remote Action - Cannot be enabled/disabled" *ngIf="!getInput(element,'IsSupported') && !isEditable(element.Category)">
                            <mat-icon class="mat-icon-grey">toggle_off</mat-icon>
                        </button>
                        <button mat-icon-button matTooltip="{{element.Category}} Remote Action - Cannot be enabled/disabled" *ngIf="getInput(element,'IsSupported') && !isEditable(element.Category)">
                            <mat-icon class="mat-icon-grey">toggle_on</mat-icon>
                        </button>
                    </ng-container>
                    <ng-container *ngIf="!defaults.isControllerOwner">
                        <p class="mat-icon-green" *ngIf="getInput(element,'IsSupported') === true">Enabled</p>
                        <p class="mat-icon-grey" *ngIf="getInput(element,'IsSupported') === false">Disabled</p>
                    </ng-container>
                </td>
            </ng-container>
            <ng-container *ngIf="item!=='actions'">
                <ng-container *matCellDef="let element" [ngSwitch]="getDataType(item)">
                    <td mat-cell *ngSwitchCase="'string'">
                        <p [innerHTML]="getInput(element,item)"></p>
                    </td>
                    <td mat-cell *ngSwitchCase="'date'">
                        <p [innerHTML]="getInput(element,item) | date:'yyyy-MM-dd HH:mm'"></p>
                    </td>
                </ng-container>
            </ng-container>
        </ng-container>

        <tr mat-header-row class="tableheader" *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; let i = dataIndex; columns: columnsToDisplay;" class="item">
    </table>
</div>
