<div class="container-tab-lists dialog.classname">
    <!--<controllertypes-menu></controllertypes-menu>--> <!--(newEvent)="onNewControllerConfig()"-->

    <h2>{{title}} for {{defaults.controllertype.Description}}</h2>
    <div class="inner-tcontainer-tab">
        <div class="tab-body">
            <div class="tab-content">

                <mat-tab-group #tabGroup (selectedTabChange)="defaults.updateComponentTab($event.tab.textLabel)">
                    <!-- Controller Types -->
                    <mat-tab label="Controller Types">

                        <div>
                            <controllertypes-devops-list></controllertypes-devops-list>
                        </div>
                    </mat-tab>

                    <!-- ControllerConfigDescription -->
                    <mat-tab label="Controller Configuration Description">

                        <div>
                            <controllerconfig-description-list></controllerconfig-description-list>
                        </div>
                    </mat-tab>

                </mat-tab-group>

            </div> <!--tab-content-->
        </div> <!--tab-body-->
    </div> <!--inner-tcontainer-tab-->
</div> <!--container-tab-lists-->
