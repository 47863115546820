<div class="container-tab-lists dialog.classname">
    <div class="inner-tcontainer-tab">
    <h2>{{title}} for {{defaults.controllertype.Description}}</h2>
    <div class="inner-tcontainer-tab">
        <div class="tab-body">
            <div class="tab-content">
                <mat-tab-group>
                    <!-- ControllerTypeByTenant -->
                    <mat-tab>
                        <ng-template mat-tab-label>
                            ControllerType by Tenant
                        </ng-template>

                        <div>
                            <controllertypebytenant-list></controllertypebytenant-list>
                        </div>
                    </mat-tab>

                    <!-- ControllerTypeToMsterdataRequired -->
                    <mat-tab>
                        <ng-template mat-tab-label>
                            ControllerType to Masterdata Required
                        </ng-template>

                        <div>
                            <controllertypetomasterdatarequired-list></controllertypetomasterdatarequired-list>
                        </div>
                    </mat-tab>

                </mat-tab-group>
            </div>
        </div>
    </div> <!--inner-tcontainer-tab-->
</div> <!--container-tab-lists-->
