import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild, AfterViewInit, ViewEncapsulation } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Utils } from "../../../../utils/utils";
import { DefaultValuesService } from "../../../../services/defaultvalues.service";
import { OPLKPIManagementListComponent } from "./oplkpimanagement-list.component";
import { OPLMonthlyKPIManagementListComponent, SearchItem } from "./oplmonthlykpimanagement-list.component";
import { MatDialog } from "@angular/material/dialog";
import { MatChipInputEvent } from "@angular/material/chips";
import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';
import { DialogService } from "../../../../services/dialog.service";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import { CustomDialogService } from "../../../../services/customdialog.service";
import { ToastrService } from "ngx-toastr";
import { MatTabGroup } from "@angular/material/tabs";
import { FilterPopupComponent } from "../../shared-components/filterpopup/filterpopup.component";

@Component({
    selector: "oplkpimanagement",
    templateUrl: './oplkpimanagement.component.html',
    styleUrls: ['../../../../style/container-tab-list.component.less', './oplkpimanagement.component.less', '../../../../style/new-generic-styles.component.less']
})

export class OPLKPIManagementComponent implements OnInit, OnDestroy {

    //@HostListener('keydown', ['$event'])

    @ViewChild('tabGroup') tabGroup!: MatTabGroup;
    @ViewChild(OPLKPIManagementListComponent) OPLKPIYearlyMmgmtList: OPLKPIManagementListComponent = {} as OPLKPIManagementListComponent;
    @ViewChild(OPLMonthlyKPIManagementListComponent) OPLKPIMonthlyMmgmtList: OPLMonthlyKPIManagementListComponent = {} as OPLMonthlyKPIManagementListComponent;
    isReleaseActive: boolean = false;
    releaseButtonText: string = 'Initiate Release';

    isRevokeActive: boolean = false;
    revokeButtonText: string = 'Initiate Revoke';

    isMonthlyReleaseActive: boolean = false;
    releaseMonthlyButtonText: string = 'Initiate Release';

    isMonthlyRevokeActive: boolean = false;
    revokeMonthlyButtonText: string = 'Initiate Revoke';


    title: string;
    waitHeader = '   -  **Please, wait until all breakdowns are properly loaded**';

    isEditModeEnabled = false;
    isExcelModeEnabled = false;
    isApprovalModeEnabled = false;
    isShowAllEnabled = true;

    subscriptionManagementId: any;
    subscriptionFiscalYear: any;
    subscriptions: any[] = [];

    list: KpiManagement[] = [];
    selectedTabIndex = 0;
    @ViewChild(OPLKPIManagementListComponent, { static: false }) listComponent!: OPLKPIManagementListComponent;
    @ViewChild(OPLMonthlyKPIManagementListComponent, { static: false }) MlistComponent!: OPLMonthlyKPIManagementListComponent;

    // Filter section
    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [COMMA, TAB, ENTER];
    searchItems: SearchItem[] = [];

    filter = ''
    selectorFilter = '0';
    inclusiveMode = false;
    isAllLoaded = false;

    yearlyColumnsToDisplay = ['ItemNo', 'COL1', 'COL2', 'COL3', 'COL4', 'YTDACT', 'PYBUD', 'FYFC', 'FY1BUD', 'FY2BUD', 'FY3BUD', 'actions']
    monthlyColumnsToDisplay = ['ItemNo', 'COL1', 'COL2', 'COL3', 'COL4', 'YTDACT', 'PYBUD', 'FY1BUD', 'P1', 'P2', 'P3', 'P4', 'P5', 'P6', 'P7', 'P8', 'P9', 'P10', 'P11', 'P12', 'actions']

    showReleaseLabel = false;
    showPrereleaseLabel = false;

    constructor(
        public defaults: DefaultValuesService
        , private http: HttpClient
        , private dialogForm: MatDialog
        , private dialog: DialogService
        , public router: Router
        , @Inject('BASE_URL') private baseUrl: string
        , private datePipe: DatePipe
        , private customDialog: CustomDialogService
        , private toastrService: ToastrService
        , private changeDetector: ChangeDetectorRef

    ) {
        this.title = `Operational planning / Budget ${this.isBranchReporting() ? `Branch Reporting ` : ``} (${this.defaults.managementName} - ${this.defaults.fiscalYear - 1}/${this.defaults.fiscalYear})`;
        this.dialog.tthis = this
    }


    ngOnInit() {
        this.subscriptionManagementId = this.defaults.managementIdMessageChanges$.subscribe(() => { this.loadInformation() });
        this.subscriptionFiscalYear = this.defaults.fiscalYearMessageChanges$.subscribe(() => {
            this.loadInformation();
        });
        if (this.defaults.managementids.length !== 0)
            this.loadInformation();
    }

    ngOnDestroy() {
        this.subscriptionManagementId.unsubscribe();
        this.subscriptionFiscalYear.unsubscribe();
        this.subscriptions.forEach(x => x.unsubscribe())
    }

    ngAfterViewInit() {
        this.defaults.defaultComponentTab(this)
    }
    onClickExcelButton() {
        this.isExcelModeEnabled = true;
    }

    onClickCancelButton() {
        this.isEditModeEnabled = this.isExcelModeEnabled = false;
    }

    onClickEdit() {
        this.changeDetector.detectChanges();
        if (this.isEditModeEnabled === true) {
            this.isEditModeEnabled = false
            if (this.listComponent?.updatedItems?.length > 0 || this.MlistComponent?.updatedItems?.length > 0) {
                this.loadInformation()
            }
        }
        else {
            this.isEditModeEnabled = true
        }
    }

    onClickShowAllButton() {
        if (this.isShowAllEnabled === false) {
            this.isShowAllEnabled = true
        }
        else {
            this.isShowAllEnabled = false
        }
        this.updatefilter()
    }

    onClickSave() {
        if (this.listComponent?.updatedItems.length === 0 && this.MlistComponent?.updatedItems.length === 0)
            this.isEditModeEnabled = false

        // Check for incorrect hours or units values
        if ((this.listComponent?.updatedItems.filter(x =>
            (x.KpiUnit?.toUpperCase() === 'UNITS' || x.KpiUnit?.toUpperCase() === 'HOURS')
            && x.KpiValue.indexOf('.00') < 0).length > 0) ||
            (this.MlistComponent?.updatedItems.filter(x =>
                (x.KpiUnit?.toUpperCase() === 'UNITS' || x.KpiUnit?.toUpperCase() === 'HOURS')
                && x.KpiValue.indexOf('.00') < 0).length > 0)) {
            this.dialog.showErrorDialog({ 'error': { 'message': 'Incorrect value provided with decimals for a Units or Hours KPI.' } });
            return;
        }
        // Check for incorrect decimal value
        if ((this.listComponent?.updatedItems.filter(x =>
            x.KpiUnit?.toUpperCase() === 'DECIMAL'
            && this.isCorrectCurrencyValue(x.KpiValue, x.DecimalDigits) === false).length > 0) ||
            (this.MlistComponent?.updatedItems.filter(x =>
                x.KpiUnit?.toUpperCase() === 'DECIMAL'
                && this.isCorrectCurrencyValue(x.KpiValue, x.DecimalDigits) === false).length > 0)) {
            this.dialog.showErrorDialog({ 'error': { 'message': 'Incorrect decimal value provided' } });
            return;
        }
        // Check for incorrect currency values based on local currency
        if ((this.listComponent?.updatedItems.filter(x =>
            (x.KpiUnit?.toUpperCase() === 'VALUES' || x.KpiUnit?.toUpperCase() === 'VALUE LC')
            && this.isCorrectCurrencyValue(x.KpiValue, x.DecimalDigits) === false).length > 0) ||
            (this.MlistComponent?.updatedItems.filter(x =>
                (x.KpiUnit?.toUpperCase() === 'VALUES' || x.KpiUnit?.toUpperCase() === 'VALUE LC')
                && this.isCorrectCurrencyValue(x.KpiValue, x.DecimalDigits) === false).length > 0)) {
            this.dialog.showErrorDialog({ 'error': { 'message': 'Incorrect currency value provided.' } });
            return;
        }
        //Yearly
        if (this.listComponent?.updatedItems.length > 0) {
            const url = this.baseUrl + "api/KPIManagement/setopl/BUDY";
            this.http.post<any[]>(url, this.listComponent.updatedItems).subscribe(() => {
                const kpisWithoutForecast = this.listComponent.updatedItems.filter(x => x.IsAccumulative === true && x.Field === 'FY1BUD' && !x.auxFYFC)
                if (kpisWithoutForecast.length > 0)
                    this.dialog.showSuccessDialog(kpisWithoutForecast.map((object) => object.AccountId).join('; '),
                        'Following accumulative Accounts did not include a forecast for current fiscal year. Consequently, automatic monthly splitting may not be accurate',
                        [])
                this.loadInformation()
                this.isEditModeEnabled = false
                this.toastrService.success('Kpi values were updated successfully!');


            }, error => {
                Utils.writeLog(this.http, this.baseUrl, error);
            });
        }

        //Monthly
        if (this.MlistComponent?.updatedItems.length > 0) {
            let validator = true;

            for (let i = 0; i < this.MlistComponent.updatedItems.length; i++) {
                if (this.MlistComponent.updatedItems[i].SumMonthlyValues !== parseFloat(this.MlistComponent.updatedItems[i].FY1BUD ?? '0')) {
                    validator = false;
                }
            }
            if (validator === false) {
                this.dialog.showErrorDialog({
                    'error':
                        { 'message': 'Validation check: split by month budget must match with indicated yearly budget.' }
                })
            } else {
                const url = this.baseUrl + "api/KPIManagement/setopl/BUDM";
                this.http.post<any[]>(url, this.MlistComponent.updatedItems).subscribe(() => {
                    this.loadInformation()
                    this.toastrService.success('Kpi values were updated successfully!');
                    this.isEditModeEnabled = false
                }, error => {
                    Utils.writeLog(this.http, this.baseUrl, error);
                });
            }
        }
    }

    isCorrectCurrencyValue(value: string, decimalDigits: number) {
        const dotIndex = value.indexOf('.');
        const colonIndex = value.indexOf(',');
        if (dotIndex === -1 && colonIndex === -1) {
            return true;
        }

        const maxSymbol = Math.max(dotIndex, colonIndex);
        if (maxSymbol === -1 || maxSymbol === value.length - 1) {
            return true;
        }
        return value.length - maxSymbol - 1 <= decimalDigits;
    }


    onClickReleaseMode(oplyearly: boolean) {
        var selectedRows = oplyearly ? this.OPLKPIYearlyMmgmtList?.getSelectionList() : this.OPLKPIMonthlyMmgmtList?.getSelectionList();
        if (!selectedRows)
            selectedRows = []
        this.customDialog.openConfirm({
            title: "Release Kpi values",
            message: `Do you want to release all current values for next fiscal year with selected parameters? Please note that you cannot edit any values after the release!.`,
            caller: this
        });
        this.customDialog.confirmed().subscribe(res => {
            if (res.confirmed === true) {
                Utils.httpReleaseKpiDefinitions(
                    this.http
                    , this.baseUrl
                    , selectedRows
                    , false
                    , oplyearly
                    , this.isBranchReporting()
                    , this
                    , function (tthis: OPLKPIManagementComponent) {
                        tthis.loadInformation();
                        tthis.toastrService.success('Kpi values were released successfully!');
                        if (!tthis.hideSections())
                            oplyearly ? tthis.toggleRelease() : tthis.toggleMonthlyRelease();
                    }
                );
            }
        })
    }

    onClickRevoke(oplyearly: boolean) {
        var selectedRows = oplyearly ? this.OPLKPIYearlyMmgmtList?.getSelectionList() : this.OPLKPIMonthlyMmgmtList?.getSelectionList();
        if (!selectedRows)
            selectedRows = []
        this.customDialog.openConfirm({
            title: "Reovke Kpi values",
            message: `Do you want to revoke all populated values for selected period?`,
            caller: this
        });
        this.customDialog.confirmed().subscribe(res => {
            if (res.confirmed === true) {
                Utils.httpRevokeKpiValues(
                    this.http
                    , this.baseUrl
                    , selectedRows
                    , false
                    , oplyearly
                    , this.isBranchReporting()
                    , this
                    , function (tthis: OPLKPIManagementComponent) {
                        tthis.loadInformation();
                        tthis.toastrService.success('Kpi values were revoked successfully!');
                        if (!tthis.hideSections())
                            oplyearly ? tthis.toggleRevoke() : tthis.toggleMonthlyRevoke();

                    }
                );
            }
        })
    }


    openFilterPopup() {
        const dialog = this.dialogForm.open(FilterPopupComponent, {
            panelClass: ['custom-mat-dialog', 'popup-dialog'],
            disableClose: true,
            data: { "selectorFilter": this.selectorFilter, "inclusiveMode": this.inclusiveMode }
        })
        dialog.afterClosed().subscribe((result) => {
            if (result) {
                this.selectorFilter = result.selectorFilter;
                this.inclusiveMode = result.inclusiveMode
            }
        })
    }

    onSelectorChange($event: string) {
        this.selectorFilter = $event
    }

    private loadInformation() {
        this.subscriptions.forEach(x => x.unsubscribe())
        this.showReleaseLabel = false;
        this.showPrereleaseLabel = false;
        this.isAllLoaded = false
        this.title = `Operational planning / Budget ${this.isBranchReporting() ? `Branch Reporting ` : ``} (${this.defaults.managementName} - ${this.defaults.fiscalYear - 1}/${this.defaults.fiscalYear})`;

        if (this.hideSections())
            return;
        //const managementName = this.defaults.managementids.filter(x => x.Value === this.defaults.managementid)[0].GroupName;
        Utils.httpGetOPLYearlyReportingInformation(
            this.http
            , this.baseUrl
            , false
            , this
            , function (tthis: OPLKPIManagementComponent, result: KpiManagement[]) {
                setTimeout(() => {
                    result.forEach(x => {
                        const row = x.KpiDetail[0]
                        let maxBreakdown = ''
                        let yearlyColumns = Object.assign([], tthis.yearlyColumnsToDisplay);
                        let monthlyColumns = Object.assign([], tthis.monthlyColumnsToDisplay);


                        if (row.COL4 === '') {
                            yearlyColumns.splice(yearlyColumns.indexOf('COL4'), 1)
                            monthlyColumns.splice(monthlyColumns.indexOf('COL4'), 1)
                            maxBreakdown = 'COL3'
                        }
                        if (row.COL3 === '') {
                            yearlyColumns.splice(yearlyColumns.indexOf('COL3'), 1)
                            monthlyColumns.splice(monthlyColumns.indexOf('COL3'), 1)
                            maxBreakdown = 'COL2'
                        }
                        if (row.COL2 === '') {
                            yearlyColumns.splice(yearlyColumns.indexOf('COL2'), 1)
                            monthlyColumns.splice(monthlyColumns.indexOf('COL2'), 1)
                            maxBreakdown = 'COL1'
                        }
                        if (row.COL1 === '') {
                            yearlyColumns.splice(yearlyColumns.indexOf('COL1'), 1)
                            monthlyColumns.splice(monthlyColumns.indexOf('COL1'), 1)
                            maxBreakdown = 'COL0'
                        }
                        x.yearlyColumns = yearlyColumns
                        x.monthlyColumns = monthlyColumns
                        x.maxBreakdown = maxBreakdown
                        tthis.showReleaseLabel = tthis.showReleaseLabel || x.KpiDetail.filter(x => x.ApprovalStatus === 'RELEASED' ||
                            x.ApprovalStatus_FY2 === 'RELEASED' ||
                            x.ApprovalStatus_FY3 === 'RELEASED').length > 0
                        tthis.showPrereleaseLabel = tthis.showPrereleaseLabel || x.KpiDetail.filter(x => x.ApprovalStatus === 'PRE-RELEASED' ||
                            x.ApprovalStatus_FY2 === 'PRE-RELEASED' ||
                            x.ApprovalStatus_FY3 === 'PRE-RELEASED').length > 0
                    })
                    tthis.list = [];
                    tthis.list = result
                    if ((!tthis.defaults.isYearlyOPLEnabled && tthis.router.url.includes('oplkpimanagement'))
                        && (!tthis.defaults.isYearlyOPLBranchReportingEnabled && tthis.router.url.includes('oplkpibranchreporting')))
                        tthis.selectedTabIndex = 1
                    tthis.isAllLoaded = true;
                }, 50)
            }
            , function (tthis: OPLKPIManagementComponent, error: string) {
                tthis.dialog.showErrorDialog(error);
                tthis.list = [];
            }
        );
    }

    downloadExcelInformation(isYearlyOpl: boolean = true) {
        let date = new Date()
        const year = date.getFullYear().toString(); // año
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // mes con dos dígitos
        const day = date.getDate().toString().padStart(2, '0'); // día con dos dígitos

        if (isYearlyOpl === true)
            Utils.httpDownloadYearlyOPLInformation(
                this.http
                , this.baseUrl
                , `${year}${month}${day}_${this.defaults.managementid}_${this.defaults.groupFunction}_${this.defaults.fiscalYear - 1}-${this.defaults.fiscalYear}_BUD_Y`
                , this.isShowAllEnabled
                , this.isBranchReporting()
                , this
                , function (tthis: OPLKPIManagementComponent, error: string) {
                    tthis.dialog.showErrorDialog(error);
                });
        else
            Utils.httpDownloadMonthlyOPLInformation(
                this.http
                , this.baseUrl
                , `${year}${month}${day}_${this.defaults.managementid}_${this.defaults.groupFunction}_${this.defaults.fiscalYear - 1}-${this.defaults.fiscalYear}_BUD_M`
                , this.isShowAllEnabled
                , this.isBranchReporting()
                , false
                , this
                , function (tthis: OPLKPIManagementComponent, error: string) {
                    tthis.dialog.showErrorDialog(error);
                });

    }

    goUploadFileView(isYearlyOpl: boolean = true) {
        if (isYearlyOpl === true) {
            if (!this.isBranchReporting())
                this.router.navigate(["file/" + Utils.getFileUploadOplYearlyInformation()]);
            else
                this.router.navigate(["file/" + Utils.getFileUploadOplYearlyBranchReportingInformation()]);
        }
        else {
            if (!this.isBranchReporting())
                this.router.navigate(["file/" + Utils.getFileUploadOplMonthlyInformation()]);
            else
                this.router.navigate(["file/" + Utils.getFileUploadOplMonthlyBranchReportingInformation()]);
        }
    }


    //Filter section

    auxFilterPredicate(filters: string, data: any, parentLevel: boolean, showAllEnabled: boolean) {
        const matchFilter: any[] = [];
        const filterArray = filters.split('+').filter(x => x)
        filterArray.pop() // Removing last item for showing or not all kpi items
        filterArray.pop() // Removing last item for showing or not all kpi items
        filterArray.pop() // Removing last item for showing or not all kpi items
        if (filterArray.length > 0) {
            filterArray.forEach((filter) => {
                let result = false;
                if (parentLevel === true) {
                    const kpiname = data.KpiName ? data.KpiName : ''
                    const id = data.AccountID ? data.AccountID : ''
                    //const plan = data.KpiPlan ? data.KpiPlan : ''
                    result = kpiname.toString().toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
                        id.toString().toLowerCase().indexOf(filter.toLowerCase()) > -1
                    //|| plan.toString().toLowerCase().indexOf(filter.toLowerCase()) > -1
                }
                else {
                    if (this.selectorFilter == '1') {
                        const granularity = data.Granularity ? data.Granularity : ''
                        result = granularity.toString().toLowerCase().indexOf(filter.toLowerCase()) > -1
                    }
                    else {
                        result = true;
                    }
                    if (!showAllEnabled) {
                        result = result && (data.FY1BUD || data.FY2BUD || data.FY3BUD || data.YTDACT || data.PYBUD || data.FYFC)
                    }
                }
                matchFilter.push(result);
            });
        }
        else {
            let result = true
            if (parentLevel === true) {
                result = true
            }
            else {
                if (!showAllEnabled) {
                    result = result && (data.FY1BUD || data.FY2BUD || data.FY3BUD || data.YTDACT || data.PYBUD || data.FYFC)
                }
            }
            matchFilter.push(result);
        }
        if (!this.inclusiveMode)
            return matchFilter.some(Boolean);
        else
            return matchFilter.every(Boolean);

    }

    applyFilter(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            this.searchItems.push({ name: value.replace('+', '').trim() });
        }
        // Reset the input value
        if (input) {
            input.value = '';
        }
        this.updatefilter()
    }

    remove(item: SearchItem): void {
        const index = this.searchItems.indexOf(item);
        if (index >= 0) {
            this.searchItems.splice(index, 1);
        }
        this.updatefilter()
    }
    updatefilter() {
        let filterString: string
        if (this.searchItems.length === 0) {
            this.filter = ''
                .concat(`+${(this.isShowAllEnabled === true) ? 'ShowAllKpiItems' : 'HideEmptyKpiItems'}`)
                .concat(`+${(this.selectorFilter === '1') ? 'FilterAllLevels' : 'FilterParentLevel'}`)
                .concat(`+${(this.inclusiveMode === true) ? 'InclusiveMode' : 'NoInclusiveMode'}`)
        }
        else {
            filterString = this.searchItems.map(e => { return e.name }).join('+')
                .concat(`+${(this.isShowAllEnabled === true) ? 'ShowAllKpiItems' : 'HideEmptyKpiItems'}`)
                .concat(`+${(this.selectorFilter === '1') ? 'FilterAllLevels' : 'FilterParentLevel'}`)
                .concat(`+${(this.inclusiveMode === true) ? 'InclusiveMode' : 'NoInclusiveMode'}`)
            this.filter = filterString
        }
    }

    private getDistinctAccountId(arr: KpiManagementUpdate[]): KpiManagementUpdate[] {
        return arr.filter((object, index, array) => {
            const firstOccurrenceIndex = array.findIndex((o) => o.AccountId === object.AccountId);
            return index === firstOccurrenceIndex;
        });
    }

    public toggleRelease() {
        this.isReleaseActive = !this.isReleaseActive;
        this.releaseButtonText = this.isReleaseActive ? 'Abort Release' : 'Initiate Release', this.OPLKPIYearlyMmgmtList.clearSelectionList()
    }

    public toggleRevoke() {
        this.isRevokeActive = !this.isRevokeActive;
        this.revokeButtonText = this.isRevokeActive ? 'Abort Revoke' : 'Initiate Revoke', this.OPLKPIYearlyMmgmtList.clearSelectionList()
    }

    public toggleMonthlyRelease() {
        this.isMonthlyReleaseActive = !this.isMonthlyReleaseActive;
        this.releaseMonthlyButtonText = this.isMonthlyReleaseActive ? 'Abort Release' : 'Initiate Release', this.OPLKPIMonthlyMmgmtList.clearSelectionList()
    }

    public toggleMonthlyRevoke() {
        this.isMonthlyRevokeActive = !this.isMonthlyRevokeActive;
        this.revokeMonthlyButtonText = this.isMonthlyRevokeActive ? 'Abort Revoke' : 'Initiate Revoke', this.OPLKPIMonthlyMmgmtList.clearSelectionList()
    }


    isBranchReporting() {
        const management = Utils.GetManagementChildrenByParent(this.defaults.managementids[0], this.defaults.managementid)
        return (this.router.url.toLowerCase() === '/oplkpibranchreporting' && (management?.BusinessLevel === 'MANAGEMENT' && management?.Groups?.length >= 1) || management?.BusinessLevel === 'FIELDBRANCH') as boolean
    }

    hideSections() {
        const management = Utils.GetManagementChildrenByParent(this.defaults.managementids[0], this.defaults.managementid)
        return (this.router.url.toLowerCase() === '/oplkpibranchreporting' && management?.BusinessLevel === 'MANAGEMENT' && management?.Groups?.length >= 1) as boolean
    }

    onBack() { }
}