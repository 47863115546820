<div class='left-menu'>
    <ul class='nav navbar-nav'>
        <li [routerLinkActive]="['link-active']" *ngIf="!defaults.isReadonlyUser">
            <a class='menu-callback' (click)="newBranch.emit({})" title="Create a new Branch">
                <span class='glyphicon glyphicon-plus-sign'></span> New Branch
            </a>
        </li>
        <li [routerLinkActive]="['link-active']">
            <a class='menu-callback' (click)="onReload.emit()" title="Refresh">
                <span class='glyphicon glyphicon-refresh'></span> Refresh
            </a>
        </li>
        <li class="separator"></li>
        <li [routerLinkActive]="['link-active']">
            <a class='menu-callback' target="_blank" (click)="outputDownload.emit()" title="Dowload">
                <span class='glyphicon glyphicon-download'></span> Download
            </a>
        </li>
        <li [routerLinkActive]="['link-active']" *ngIf="!defaults.isReadonlyUser">
            <a class='menu-callback' title="Upload" (click)="onUpload()">
                <span class='glyphicon glyphicon-upload'></span> Upload
            </a>
        </li>
        <li class="separator" [ngClass]="saveService.classSaveButton" *ngIf="!defaults.isReadonlyUser"></li>
        <li [routerLinkActive]="['link-active']" [ngClass]="saveService.classSaveButton" *ngIf="!defaults.isReadonlyUser">
            <a class='menu-callback' (click)="saveService.fireSave()" title="Save all">
                <span class='glyphicon glyphicon-floppy-disk'></span> Save
            </a>
        </li>
    </ul>
</div>