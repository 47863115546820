<div class="custom-mat-dialog-form">
    <div>
        <div>
            <H5>Default Transmission Mode configuration</H5><br />
        </div>
    </div>
    <div>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">

            <div class="mt-2">
                Controller type: <br />
                <mat-select placeholder="Please select" style="width:300px;" class='form-control'
                            name="Controller Type" required formControlName="ControllerType">
                    <mat-option *ngFor="let c of controllerTypes; index as i" value="{{c.BK}}">
                        {{c.Description}}
                    </mat-option>
                </mat-select>

                <br />
                <br />
                Transmission Mode: <br />
                <mat-select placeholder="Please select" style="width:300px;" class='form-control'
                            name="Controller Type" required formControlName="DDTransmissionMode"
                            (selectionChange)="onTransmissionModeChange($event)">
                    <mat-option *ngFor="let c of transmissionModes; index as i" value="{{c}}">
                        {{c}}
                    </mat-option>
                </mat-select>
                <br />
                <span *ngIf="errorTM" style="color:red;">*Mandatory field<br /></span>
                <br />
                <div id="DivInterval input">
                    Batch Interval (seconds): <br />
                    <input class="form-control" type="number" name="Batch Interval" max="86400" min="0" maxlength="5" required formControlName="TxtBatchInterval" (input)="onTransmissionModeChange($event)">
                    <br />
                    <span *ngIf="errorBI" style="color:red;">*Mandatory field<br /></span>
                    <span *ngIf="errorBIvalue" style="color:red;">*Valid values between 0 and 86400 <br /></span>
                </div>

            </div>

            <br />


            <div class="mt-2 right">
                <button type="button"
                        class="btn btn-danger"
                        (click)="onNoClick()">
                    Cancel
                </button>
                <button type="button"
                        class="btn btn-primary"
                        (click)="onSubmit()">
                    Save
                </button>
            </div>
        </form>
    </div>
</div>
