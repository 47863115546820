import { Component, Inject, OnInit, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Utils } from "../../utils/utils";

@Component({
    selector: "testcase-form-json",
    templateUrl: './testcase-form-json.component.html',
    styleUrls: ['../../style/form.component.less', './testcase-list.component.less','../../style/custom-mat-dialog.component.less',]
})

export class TestCaseFormJsonComponent implements OnInit {

    @Input() parent: TestCase = {} as TestCase;

    title: string;
    
    json = {} as TestCaseJson;
    result = "";
    isGenerated = false;
    schemaVersionList: any[];

    form: FormGroup = new FormGroup({});

    constructor(private activatedRoute: ActivatedRoute,
        private router: Router,
        private http: HttpClient,
        private fb: FormBuilder,
        @Inject('BASE_URL') private baseUrl: string)
    {

        this.schemaVersionList = [{ label: "Sv1", value: 1 }, { label: "Sv2", value: 2 }, { label: "Sv3", value: 3 }];
        this.title = "Test Cases - Response in JSON";
        this.createForm();
    }

    ngOnInit() {
        this.createForm();
        this.json = { TestCaseID: this.parent.TestCaseID, Tenant: "TA", Countries: "US", Version: "1", Message: "", Idx: 0, Results: "", ToRegressionTest: false };
    }

    onDownload() {
        const data = "data:text/json;charset=utf-8," + encodeURIComponent(this.result);
        const downloader = document.createElement('a');

        downloader.setAttribute('href', data);
        downloader.setAttribute('download', 'input_json.json');
        downloader.click();
    }

    onSubmit() {
        if (this.form && this.json && this.parent) {
            // build a temporary quiz object from form values
            //var tc = <TestCase>{};
            this.json.Version = this.form.value.Version;
            this.json.Tenant = this.form.value.Tenant;
            this.json.Countries = this.form.value.Country;
            this.json.ToRegressionTest = this.form.value.RT === "" || this.form.value.RT === false ? false : true;

            const url = this.baseUrl + Utils.getTestCaseAPI() + "json";
            this.http
                .put<TestCaseJson>(url, this.json)
                .subscribe(res => {
                    this.result = res.Results;
                    this.isGenerated = true;
                }, error => console.log(error));
        }
    }

    private createForm() {
        this.json.Version = '1';
        this.json.Results = "Expected results";

        this.form = new FormGroup({
            Version: new FormControl(this.json.Version, [Validators.required]),
            Tenant: new FormControl('TA', [Validators.required]),
            Country: new FormControl('US', [Validators.required]),
            RT: new FormControl('', [Validators.nullValidator])
        });
    }

}