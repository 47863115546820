<div class="example-container-sectionscheduler">
    <table mat-table
           matSort
           [dataSource]="dataSource"
           class="mat-elevation-z8 mat-table-custom"
            id="SectionScheduler">

        <ng-container matColumnDef="section">
            <th mat-header-cell *matHeaderCellDef> Section </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.Section"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="startperiod">
            <th mat-header-cell *matHeaderCellDef> Start Period (MM-dd) </th>
            <td mat-cell *matCellDef="let element">
                <mat-form-field floatLabel="never">
                    <input matInput [value]="element.StartPeriod" [(ngModel)]="element.StartPeriod" [pattern]="pattern" maxlength="5">
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container matColumnDef="endperiod">
            <th mat-header-cell *matHeaderCellDef> End Period (MM-dd)</th>
            <td mat-cell *matCellDef="let element">
                <mat-form-field floatLabel="never">
                    <input matInput [value]="element.EndPeriod" [(ngModel)]="element.EndPeriod" [pattern]="pattern" maxlength="5">
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container matColumnDef="startperiod2">
            <th mat-header-cell *matHeaderCellDef> Start Period 2 (MM-dd)</th>
            <td mat-cell *matCellDef="let element">
                <mat-form-field floatLabel="never">
                    <input matInput [value]="element.StartPeriod2" [(ngModel)]="element.StartPeriod2" [pattern]="pattern" maxlength="5">
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container matColumnDef="endperiod2">
            <th mat-header-cell *matHeaderCellDef> End Period 2 (MM-dd)</th>
            <td mat-cell *matCellDef="let element">
                <mat-form-field floatLabel="never">
                    <input matInput [value]="element.EndPeriod2" [(ngModel)]="element.EndPeriod2" [pattern]="pattern" maxlength="5">
                </mat-form-field>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; index as i;
            columns: columnsToDisplay;"
            class="item">
        </tr>
    </table>
</div>