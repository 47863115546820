import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from
    "@angular/forms";
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, EMPTY } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from "@angular/forms";
import { DialogService } from '../../../../../services/dialog.service';
import { Utils } from '../../../../../utils/utils';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { DefaultValuesService } from '../../../../../services/defaultvalues.service';
import { CustomDialogService } from '../../../../../services/customdialog.service';





@Component({
    selector: "resultsdialogue",
    templateUrl: './resultsdialogue.component.html',
    styleUrls: ['./resultsdialogue.component.less'],

})

export class ResultsDialogue implements OnInit {
    //@Input('users') users: UserSummary[] = [];
    //@Input('totalResultsCount') count: number = 0;

    users: UserSummary[] = [];
    count: number = 0;
    columnsToDisplay = ['upn', 'displayname', 'tenant', 'active'];

    public dataSource = new MatTableDataSource<UserSummary>();
    subscriptionControllerType: any;

    @ViewChild(MatPaginator, { static: true }) matPaginator!: MatPaginator;


    constructor(
        private http: HttpClient
        , private dialogService: DialogService
        , public dialogRef: MatDialogRef<ResultsDialogue>

        , private defaults: DefaultValuesService
        , @Inject('BASE_URL') private baseUrl: string
        , private dialog: MatDialog
        , private dialogForm: MatDialog
        , private router: Router
        , private customDialog: CustomDialogService
        , @Inject(MAT_DIALOG_DATA) public data: any


    ) {
        this.users = data.users;
        this.count = data.totalResultsCount;

    }

    ngOnInit() {
        this.dataSource.data = this.users
        this.dialogService.tthis = this;
        //this.refresh();
        if (this.matPaginator) {
            this.dataSource.paginator = this.matPaginator;
        }

    }

    ngOnChanges() {
        this.dataSource.data = this.users
    }


    onRowEditInit(element) {

    }
    onMoreInfo(element) {

        this.customDialog.openDisplayInfoDialog({
            title: element.ControllerTypeLocal,
            message: 'More information related to this controller',
            caller: this
        });

    }

    onEditUser(userId: string) {
        this.router.navigate(['/EditUser', userId]);
        //window.location.reload();
        this.dialogRef.close();
       

    }
    backButtonHandler() {
        this.dialogRef.close();

    }


}
