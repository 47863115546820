<div class="example-container">
    <table mat-table #itemsList
           [dataSource]="dataSource"
           multiTemplateDataRows
           class="mat-elevation-z8 mat-table-custom" id="contractbundle">

        <ng-container matColumnDef="arrow">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element"
                (click)="pushPopElement(element)">
                <span class="material-icons" *ngIf="checkExpanded(element); else show_expand_more" matTooltip="Collapse">
                    expand_less
                </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="bundle">
            <th mat-header-cell *matHeaderCellDef> Bundle </th>
            <th mat-header-cell *matHeaderCellDef> Bundle </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.Bundle"></p>
            </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element; let i = dataIndex">
                <button mat-icon-button matTooltip="Delete Bundle"
                        (click)="deleteBundle(element.BundleId)">
                    <mat-icon>delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                <div [@detailExpand]="checkExpanded(element) ? 'expanded' : 'collapsed'">
                    <table mat-table [dataSource]="element.BundleList" class="mat-subtable-custom" id="bundlelist">

                        <ng-container matColumnDef="feature">
                            <th mat-header-cell *matHeaderCellDef> Feature </th>
                            <td mat-cell *matCellDef="let feature">
                                <p [innerHTML]="feature"></p>
                            </td>
                        </ng-container>

                        <tr mat-header-row class="subtableheader" *matHeaderRowDef="subcolumnsToDisplay"></tr>
                        <tr mat-row *matRowDef="let element2; let i2 = index; columns: subcolumnsToDisplay;"
                            class="subitem"></tr>
                    </table>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row class="tableheader" *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; let i = dataIndex; columns: columnsToDisplay;"
            class="item">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="row-subitem"></tr>

    </table>
</div>


<ng-template #show_expand_more>
    <span class="material-icons" matTooltip="More Details">
        expand_more
    </span>
</ng-template>
