<div *ngIf="['edit','add'].includes(data?.action) " class="container-tab-lists hrarchySetupCusotm">

    <div class="inner-tcontainer-tab tab-body">

            <h3>{{data?.content ? 'Update Hierarchy Key' : 'Create Hierarchy Key'}}</h3>
            <div class="new-form-style custom-mat-dialog_v2">
                <div class="tab-content">
                    <form [formGroup]="form" class="internalMax ">
                        <div class="row">
                            <div class="form-group col-lg-12 " *ngIf="data?.content">
                                <label class="col-lg-4 control-label">Key</label>
                                <div class="col-lg-8">
                                    <p *ngIf="data">{{data?.content?.Key}}</p>
                                </div>
                            </div>
                            <div class="form-group col-lg-12 " *ngIf="!data?.content">
                                <div>
                                    <mat-form-field class="hkeyClass" appearance="outline">
                                        <mat-label>Key</mat-label>
                                        <input matInput formControlName="key" required>
                                        <mat-icon matSuffix matTooltipClass="error-tooltip" [matTooltip]="'Hierarchy Key already exists.'" style="color : red !important" *ngIf="form?.get('key')?.hasError('isAvailable') ">error</mat-icon>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12 form-group">
                                <div>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Branch Name</mat-label>
                                        <input matInput formControlName="branchName" required>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 form-group">
                                <div>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Location For Map</mat-label>
                                        <input matInput formControlName="locationForMap" required>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12 form-group">
                                <div>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Address</mat-label>
                                        <input matInput formControlName="address" required>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>

                        <div class="managementid-tree">
                            <div class="row heading" style="margin:2%">
                                <div style="width:65%">
                                    <h4>
                                        <span>ManagementId</span>
                                        <span style="color:orange"> *</span>
                                    </h4>
                                </div>

                                <div style="width:35%">
                                    <input (keyup)="treeControl.expandAll()" class="form-control" [(ngModel)]="searchString" [ngModelOptions]="{standalone: true}" type="text" placeholder="Search" />
                                </div>

                            </div>
                            <div class="custom_small_panelbody_fix">
                                <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
                                    <!-- This is the tree node template for leaf nodes -->
                                    <mat-tree-node *matTreeNodeDef="let node"
                                                   matTreeNodeToggle
                                                   [style.display]="showOnlySelected && !node.selected || this.searchString && hideLeafNode(node) ? 'none' : 'block'">
                                        <li class="mat-tree-node">
                                            <mat-checkbox [disabled]="node.selected"
                                                          class="checklist-leaf-node"
                                                          (change)="itemToggle($event, $event.checked,node)"
                                                          [checked]="node.selected">{{node.groupName}} </mat-checkbox>
                                        </li>
                                    </mat-tree-node>
                                    <!-- This is the tree node template for expandable nodes -->
                                    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild"
                                                          [style.display]="(showOnlySelected && !(node.selected || node.indeterminate)) || this.searchString && hideParentNode(node) ? 'none' : 'block'">
                                        <li>
                                            <div class="mat-tree-node">
                                                <button (click)="$event.preventDefault()" mat-icon-button matTreeNodeToggle
                                                        [attr.aria-label]="'toggle ' + node.groupName">
                                                    <mat-icon class="mat-icon-rtl-mirror">
                                                        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                                    </mat-icon>
                                                </button>
                                                <mat-checkbox [checked]="node.selected" [disabled]="true">{{node.groupName}}</mat-checkbox>
                                            </div>
                                            <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
                                                <ng-container matTreeNodeOutlet></ng-container>
                                            </ul>
                                        </li>
                                    </mat-nested-tree-node>
                                </mat-tree>
                            </div>
                        </div>
                        <div class="row">
                            <div class="panel-body">
                                <button (click)="onCancelClick()" mat-raised-button class="btn  button-color-fix_red ">
                                    <mat-icon class=" icon-color-fix ">arrow_back_ios</mat-icon>
                                    Back
                                </button>
                                <button mat-raised-button (click)="onSubmit()" type="submit" class="btn button-color-fix pull-right" [disabled]="!form.valid">
                                    Save
                                    <mat-icon class=" icon-color-fix ">save</mat-icon>
                                </button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

<div class="deleteDialogue " *ngIf="data?.action=='delete'">
    <h1 class="dialogue-heading" mat-dialog-title>Delete Hierarchy Key</h1>
    <div class="dialogue-content ngSelectBlackOrange">
        <mat-checkbox [(ngModel)]="isMerged" class="dialogue-content-child">Merge child branches to another branch</mat-checkbox>
        <ng-select style="width: 280px; " class=" dialogue-content-child"
                   [items]="ngSelectItems"
                   bindLabel="BranchName"
                   placeholder="Select replacement branch"
                   [(ngModel)]="selectedBranch"
                   [clearable]="false"
                   [clearOnBackspace]="false"
                   [disabled]="!isMerged"
                   [searchFn]="customSearchFn">
            <ng-template ng-option-tmp let-item="item">
                <span class="ng-option-label " [matTooltip]="getToolTipText(item)" matTooltipClass="my-tooltip">{{ item.BranchName }} - {{item.Key}}</span>
            </ng-template>
        </ng-select>
    </div>

    <div class="row dialogue-buttons">
        <div class="panel-body">
            <button (click)="onCancelClick()" mat-raised-button class="btn  button-color-fix_red ">
                <mat-icon class=" icon-color-fix ">arrow_back_ios</mat-icon>
                Back
            </button>
            <button mat-raised-button [disabled]="isMerged && selectedBranch=== undefined" [matTooltip]="deleteButtonToolTip() " (click)=" onDelete(selectedBranch)" type="submit" class="btn button-color-fix pull-right">
                Delete
                <mat-icon class=" icon-color-fix ">delete</mat-icon>
            </button>

        </div>
    </div>
</div>

<hierarchysetuppopup-list [hkey]="data?.hkSelected" (onBackEvent)="onCancelClick()" *ngIf="data?.action=='history'"></hierarchysetuppopup-list>