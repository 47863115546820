<div class="container-tab-lists">
    <div class="form-edit">
        <form [formGroup]="form">
            <div class="form-group">
                <label for="ct">Error Code:</label>
                <br />
                <select name="ct"
                        formControlName="ErrorCode"
                        class="form-control">
                    <option *ngFor="let ec of list; index as i" value="{{i}}">{{ec.ErrorCode}} - {{ec.Description}}</option> <!--[selected]="isSelected(ec)"-->
                </select>
                <button type="button"
                        class="btn btn-group"
                        (click)="onAdd()">
                    Add Error Code
                </button>
                <button type="button"
                        class="btn btn-danger"
                        (click)="onReset()">
                    Reset
                </button>
                <button type="button"
                        [disabled]="form.invalid"
                        class="btn btn-success"
                        (click)="onSimulate()">
                    Simulate
                </button>
            </div>

            <!--<div class="form-group commands">
            </div>-->
        </form>

        <div class="inner-tcontainer-tab">
            <ul class="navigation">
                <li class="{{classerrorlist}}">
                    <a data-toggle="tab" href="#error-codes-list">
                        Error Codes To Simulate
                    </a>
                </li>
                <li class="{{classvirtualcoach}}">
                    <a data-toggle="tab" href="#virtualcoach-simulator-list">
                        Virtual Coach
                    </a>
                </li>
            </ul>
            <div class="tab-body">
                <div class="tab-content">
                    <div id="error-codes-list" class="tab-pane {{classerrorlist}}">
                        <table-fixed-header-search id-table="errorcodes-simulate-id-table"
                                                   [header]="getHeader()"
                                                   [data]="data"
                                                   [getValue]="getData">
                        </table-fixed-header-search>
                    </div>
                    <div id="virtualcoach-simulator-list" class="tab-pane {{classvirtualcoach}}">
                        <table-fixed-header-search id-table="virtualcoach-simulate-id-table"
                                                   [header]="getHeaderVirtualCoach()"
                                                   [data]="virtualcoach"
                                                   [getValue]="getDataVirtualCoach">
                        </table-fixed-header-search>
                    </div>
                </div> <!--tab-content-->
            </div> <!--tab-body-->
        </div> <!--inner-tcontainer-tab-->




    </div> <!--form-edit-->
</div>