<div class="example-container">
    <table mat-table
           [dataSource]="list"
           class="mat-elevation-z8 mat-table-custom" id="possiblefailuremlundotable">

        <ng-container matColumnDef="errorcode">
            <th mat-header-cell *matHeaderCellDef> Error Code </th>
            <td mat-cell *matCellDef="let element"> {{element.ErrorCode}} </td>
        </ng-container>

        <ng-container matColumnDef="faultdescription">
            <th mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let element"> {{element.FaultDescription}} </td>
        </ng-container>

        <ng-container matColumnDef="maintenancefailure">
            <th mat-header-cell *matHeaderCellDef> Maintenance Failure </th>
            <td mat-cell *matCellDef="let element"> {{element.MaintenanceFailure}} </td>
        </ng-container>

        <ng-container matColumnDef="troubleshootingaction">
            <th mat-header-cell *matHeaderCellDef> Troubleshooting Action </th>
            <td mat-cell *matCellDef="let element"> {{element.TroubleshootingAction}} </td>
        </ng-container>

        <ng-container matColumnDef="troubleshootingactionweight">
            <th mat-header-cell *matHeaderCellDef> Weight </th>
            <td mat-cell *matCellDef="let element"> {{element.TroubleshootingActionWeight}} </td>
        </ng-container>

        <ng-container matColumnDef="previousweight">
            <th mat-header-cell *matHeaderCellDef> Previous Weight </th>
            <td mat-cell *matCellDef="let element"> {{element.PreviousWeight}} </td>
        </ng-container>

        <ng-container matColumnDef="isnew">
            <th mat-header-cell *matHeaderCellDef> New Suggestion? </th>
            <td mat-cell *matCellDef="let element">
                <mat-checkbox disabled [checked]="element.IsNew"></mat-checkbox>
            </td>
        </ng-container>

        <ng-container matColumnDef="changedate">
            <th mat-header-cell *matHeaderCellDef> Change Date </th>
            <td mat-cell *matCellDef="let element"> {{element.ChangeDate | date:'yyyy/MM/dd, HH:mm:ss'}} </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Actions  </th>
            <td mat-cell *matCellDef="let element">
                <button class="mlbuttons" mat-raised-button (click)="performUndo(element)">Undo</button>
            </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; let i = index; columns: columnsToDisplay;"
            class="example-element-row"
            [class.alternative]="i % 2 != 0">
        </tr>
    </table>
</div>
