<div class='left-menu'>
    <ul class='nav navbar-nav'>
        <li [routerLinkActive]="['link-active']" [ngClass]="defaults.getIsControllerOwner() && !defaults.isReadonlyUser ? 'show': 'hide'">
            <a class='menu-callback' (click)="openNewConfigurationPopup()" title="Create a new Configuration">
                <span class='glyphicon glyphicon-plus-sign'></span> New Configuration
            </a>
        </li>
        <li [routerLinkActive]="['link-active']">
            <a class='menu-callback' (click)="onRefresh.emit()" title="Refresh">
                <span class='glyphicon glyphicon-refresh'></span> Refresh
            </a>
        </li>
        <li class="separator" *ngIf="!defaults.isReadonlyUser" [ngClass]="saveService.classSaveButton"></li>
        <li [routerLinkActive]="['link-active']" [ngClass]="saveService.classSaveButton" *ngIf="!defaults.isReadonlyUser">
            <a class='menu-callback' (click)="onSave()" title="Save Controller Configuration">
                <span class='glyphicon glyphicon-floppy-disk'></span> Save All
            </a>
        </li>
    </ul>
</div>