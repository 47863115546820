import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { APICallStatus, Utils } from "../../../utils/utils";
import { DefaultValuesService } from "../../../services/defaultvalues.service";
import { Observable } from "rxjs";
import { DialogService } from "../../../services/dialog.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { CustomDialogService } from "../../../services/customdialog.service";
//import { AddUserTabComponent } from './adduser/adduser-tab.component';
import { SpinnerInterceptor } from "../../../interceptors/spinner.interceptor";
import { SpinnerOverlayService } from "../../../services/spinner-overlay.service";
import { ComponentTool } from "../../../interfaces/componenttool";
import { CreateDaworkspaceComponent } from "../group/daworkspace/createdaworkspace/createdaworkspace.component";
import { AddUserTabComponent } from "./adduser/adduser-tab.component";

@Component({
    selector: "umphome",
    templateUrl: './umphome.component.html',
    styleUrls: ['./umphome.component.less'
        , '../../../style/dialogue-box.less'
        , '../../../style/new-generic-styles.component.less'],
    encapsulation: ViewEncapsulation.None
})

export class UmpHomeComponent implements OnInit {

    selectedTenants: string[] = [];
    selectedTenantLabels: string[] = [];
    selectedRoles: string[] = [];
    selectedRoleLabels: string[] = [];
    selectedCdpGroups: string[] = [];
    selectedCdpGroupLabels: string[] = [];
    users: UserSummary[] = [];
    totalResultsCount: number = 0;
    dialogRef: MatDialogRef<AddUserTabComponent, any> | undefined

    queryFilter = ""

    subscriptionStage: any;
    componentToolEnum: typeof ComponentTool = ComponentTool;
    tableResponseState: APICallStatus = APICallStatus.NotRequested;

    constructor(
        private http: HttpClient
        , private dialogService: DialogService
        , @Inject('BASE_URL') private baseUrl: string
        , private dialogForm: MatDialog
        , private customDialog: CustomDialogService
        , public defaults: DefaultValuesService
    ) {

    }

    ngOnInit() {
        this.subscriptionStage = this.defaults.stageMessageChanges$.subscribe(() => { this.loadInformation() });
        this.loadInformation();

    }
    private loadInformation() {
        this.users = [];
        if (this.defaults.umpFilters.tenants.length === 0 && this.defaults.componentTool === this.componentToolEnum.Rulebook && !this.defaults.isDataAnalytStage()) {
            Utils.httpGetInitialFilters(
                this.http
                , this.baseUrl
                , this
                , function (tthis: UmpHomeComponent, data: UserFilter) {
                    tthis.defaults.umpFilters = data
                    tthis.selectedTenants = [tthis.defaults.umpFilters.tenants[0].value]
                    tthis.selectedRoles = [tthis.defaults.umpFilters.personas[0].value]
                    tthis.selectedCdpGroups = [tthis.defaults.umpFilters.cdpList[0].value]
                    tthis.onNgSelectChange()
                    tthis.selectAllForDropdownItems(tthis.defaults.umpFilters.tenants);

                }
            )
        }
        else if (this.defaults.componentTool === this.componentToolEnum.Rulebook && !this.defaults.isDataAnalytStage()) {
            this.selectedTenants = [this.defaults.umpFilters.tenants[0].value]
            this.selectedRoles = [this.defaults.umpFilters.personas[0].value]
            this.selectedCdpGroups = [this.defaults.umpFilters.cdpList[0].value]
            this.onNgSelectChange()
        }
    }
    get isLoading(): boolean {
        return this.tableResponseState === APICallStatus.Waiting4Response;
    }

    selectAllForDropdownItems(items) {

        let allSelect = items => {
            items.forEach(element => {
                element['selectedAllGroup'] = 'selectedAllGroup';
            });
        };

        allSelect(items);
    }
    onNgSelectChange() {
        this.selectedTenantLabels = this.defaults.umpFilters.tenants.filter(x => this.selectedTenants.includes(x.value)).map(x => " " + x.text)
        this.selectedRoleLabels = this.defaults.umpFilters.personas.filter(x => this.selectedRoles.includes(x.value)).map(x => " " + x.text)
        this.selectedCdpGroupLabels = this.defaults.umpFilters.cdpList.filter(x => this.selectedCdpGroups.includes(x.value)).map(x => " " + x.text)

    }
    loadUsersByFilter() {
        if (this.queryFilter === '')
            return;
        this.tableResponseState = APICallStatus.Waiting4Response;
        this.defaults.passedTenants = this.selectedTenants;
        this.defaults.passedRoles = this.selectedRoles;
        this.defaults.passedCdpGroups = this.selectedCdpGroups;
        Utils.httpGetUsersByFilter(
            this.http
            , this.baseUrl
            , {
                "UserPrincipalName": "",
                "Page": 0,
                "PageSize": 25,
                "GivenName": this.queryFilter,
                "CdpGroup": this.selectedCdpGroups,
                "NodeId": this.selectedRoles,
                "Tenant": this.selectedTenants,
                "MailO365Account": null
            }, this
            , function (tthis: UmpHomeComponent, data: UserList) {
                tthis.tableResponseState = APICallStatus.ResponseDataOk;
                tthis.users = data.users;
                tthis.totalResultsCount = data.totalResultsCount;
            }
            , this.defaults.isDataAnalytStage()
        )
    }

    addUserTrigger() {

        this.dialogRef = this.dialogForm.open(AddUserTabComponent, {
            panelClass: ['custom-mat-dialog_v2', 'create-user-dialogue'],
        });
    };

    openPowerbiWorkspacePopup() {
        const dialog = this.dialogForm.open(CreateDaworkspaceComponent, {
            disableClose: true,
            width: '550px',
            panelClass: ['custom-mat-dialog_v2'],
            data: {},
        })
    }
}