<div class='left-menu'>
    <ul class='nav navbar-nav'>
        <!--<li class="inline">
        <a class='menu-callback' (click)="onEdit()" title="Edit Maintenance Control Plan Group">
            <span class='glyphicon glyphicon-edit'></span> Edit
        </a>

        <a class='menu-callback' (click)="onDelete()" title="Delete Maintenance Control Plan Group">
            <span class='glyphicon glyphicon-trash'></span> Delete
        </a>
    </li>-->

        <li [routerLinkActive]="['link-active']" *ngIf="!defaults.isReadonlyUser">
            <a class='menu-callback' (click)="newEvent.emit({})" title="Create a new Maintenance Control Plan Group Action">
                <span class='glyphicon glyphicon-plus-sign'></span> New Action
            </a>
        </li>

        <li [routerLinkActive]="['link-active']" *ngIf="!defaults.isReadonlyUser">
            <a class='menu-callback' (click)="onNewProposedErrors()" title="Add all selected proposed Errors, for all controller types, to the Action Components">
                <span class='glyphicon glyphicon-plus-sign'></span> Proposed Errors
            </a>
        </li>

        <li>
            <a class='menu-callback' (click)="onBack()" title="Back">
                <span class='glyphicon glyphicon-arrow-left'></span> Back
            </a>
        </li>

        <li class="separator" [ngClass]="saveService.classSaveButton" *ngIf="!defaults.isReadonlyUser"></li>
        <li [routerLinkActive]="['link-active']" [ngClass]="saveService.classSaveButton" *ngIf="!defaults.isReadonlyUser">
            <a class='menu-callback' (click)="saveService.fireSave()" title="Save all Error Code(s) and Resolution(s)">
                <span class='glyphicon glyphicon-floppy-disk'></span> Save
            </a>
        </li>

    </ul>
</div>