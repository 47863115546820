import { Component, Inject, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { DialogService } from "../../../services/dialog.service";
import { Utils } from "../../../utils/utils";
import { HttpClient } from "@angular/common/http";
import { DefaultValuesService } from "../../../services/defaultvalues.service";
import { MatDialog } from "@angular/material/dialog";
import { CustomDialogService } from '../../../services/customdialog.service';
import { MatTableDataSource } from "@angular/material/table";
import { ControllerTypeHarmonizationFormComponent } from "./controllertypeharmonization-form.component";

@Component({
    selector: "controllertypeharmonization-list",
    templateUrl: './controllertypeharmonization-list.component.html',
    styleUrls: ['./controllertypeharmonization-list.component.less']
})

export class ControllerTypeHarmonizationListComponent implements OnInit {
    columnsToDisplay = ['ItemNo','controllertypelocal', 'description', 'isNew','actions'];
    list: ControllerTypeVMapping[] = [];

    public dataSource = new MatTableDataSource<ControllerTypeVMapping>();
    subscriptionControllerType: any;

    Filter = 0;


    constructor(
        private http: HttpClient
        , private dialogService: DialogService
        , private defaults: DefaultValuesService
        , @Inject('BASE_URL') private baseUrl: string
        , private dialog: MatDialog
        , private dialogForm: MatDialog
        , private customDialog: CustomDialogService

    ) {

    }



    ngOnInit() {
        this.dialogService.tthis = this;
        this.subscriptionControllerType = this.defaults.controllerTypeMessageChanges$.subscribe((item: ControllerType) => this.onControllerChanged(item));
        this.refresh();

        
    }

    ngOnChanges() {

        this.dataSource.data = this.list
    }

    onControllerChanged(ct: ControllerType) {
        this.refresh();
    }

    onRowEditInit(element) {

        const Dialog = this.dialogForm.open(ControllerTypeHarmonizationFormComponent, {
            panelClass: 'custom-mat-dialog',
            disableClose: false,
            data: { "ControllerTypeLocal": element.ControllerTypeLocal, "ControllerTypeBK": element.ControllerTypeBK, "_ControllerTypeMappingID": element._ControllerTypeMappingID, "TenantID": "" }
        });
        Dialog.afterClosed().subscribe(result => {
            if (result) this.refresh();
        });

        

    }
    onMoreInfo(element)
    {
        //let url = baseUrl + API_CDP_CONTROLLER_TYPE_MAPPING + "all/" + filter.toString();
        //http.get<ControllerTypeMapping[]>(url).subscribe(result => {
        //    if (tthis && callback)
        //        callback(tthis, result);
        //}, error => {
        //    Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        //}); //console.error(error));

        //let url = this.baseUrl + Utils.getCDPControllerTypeMappingAPI() + "referencedata/" + element._ControllerTypeMappingID;

        //this.http
        //    .get<controllerinfo>(url, {})
        //    .subscribe(res => {
        //        this.refresh();
        //    }, error => {
        //        Utils.writeLog(this.http, this.baseUrl, error); //Utils.showErrorDialog(null, error);

        //    }); //console.error(error));



        this.customDialog.openDisplayInfoDialog({
            title: element.ControllerTypeLocal,
            message: 'More information related to this controller',
            caller: this
        });

    }
    onDelete(element) {
        const tthis = this;

        this.customDialog.openConfirm({
            title: "DELETE HARMONIZED CORRESPONDENCE",
            message: 'Local controller description "' + element.ControllerTypeLocal + '" to "' + element.Description + '" controller corresponce will be deleted. Continue ?',
            caller: this
        });


        this.customDialog.confirmed().subscribe(res => {

            if (res.confirmed) {
                let url = this.baseUrl + Utils.getCDPControllerTypeMappingAPI() + "delete/" + element._ControllerTypeMappingID;

                this.http
                    .post<boolean>(url, {})
                    .subscribe(res => {
                        this.refresh();
                    }, error => {
                        Utils.writeLog(this.http, this.baseUrl, error); //Utils.showErrorDialog(null, error);

                    }); //console.error(error));
            }
        })

    }

    private btn_new()
    {
        this.dialogForm.open(ControllerTypeHarmonizationFormComponent, {
            panelClass: 'custom-mat-dialog',
            disableClose: false,
            data: { "isCustomerPortal": "A", "type": "B", "entity": "C" }
        });


    }


    public filterColumn(value: any, name: string)
    {
        switch (name) {
            case 'status':
                this.Filter = value.value ;
        }
        this.refresh()
    }

    public btn_refresh()
    {
        this.refresh();

        //this.dialogForm.open(AzDataFactoryFormComponent, {
        //    panelClass: 'custom-mat-dialog',
        //    disableClose: true,
        //    data: { "isCustomerPortal": 0, "type": "", "entity": "Customer Portal" }
        //});


    }

    private refresh() {
        const tthis = this;
        /**
            * load all the data
            * */

        Utils.httpGetAllControllerTypeLocal(
            this.http
            , this.baseUrl
            , this.Filter
            , this
            , function (tthis: any, values: ControllerTypeVMapping[])
            {
                tthis.list = values;
                tthis.dataSource.data = tthis.list
            }
        );
    }

    private sinceDays(date: Date) {
        let currentDate = new Date();

        let dateDeployment = new Date(date);
        return Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())
            - Date.UTC(dateDeployment.getFullYear(), dateDeployment.getMonth(), dateDeployment.getDate())) / (1000 * 60 * 60 * 24));
    }
}