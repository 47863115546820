<mat-sidenav-container class="example-sidenav-container" [hasBackdrop]="false" autosize>
    <mat-sidenav #snav [opened]="true" mode="over">
        <mat-nav-list>
            <span *ngFor="let item of defaults.menuClusters">
                <span *ngIf="item.ClusterComponents && item.ClusterComponents.length > 0 && item.Enabled === true">
                    <mat-accordion multi>
                        <mat-expansion-panel [@.disabled]="true" [expanded]="defaults.selectedItem === item.ClusterId" (opened)="setSelectedItem(item.ClusterId!)">
                            <mat-expansion-panel-header *ngIf="item.IconName != 'group'" matTooltip="{{item.DisplayName}}" matTooltipDisabled="{{isExpanded}}">
                                <mat-panel-title>
                                    <div fxLayout="row" fxLayoutAlign="space-between center">
                                        <mat-icon *ngIf="item.IconName.includes('img/')">
                                            <img src="{{item.IconName}}" class="icon-png" />
                                        </mat-icon>
                                        <mat-icon *ngIf="!item.IconName.includes('img/')">
                                            {{item.IconName}}
                                        </mat-icon>
                                        <div *ngIf="isExpanded">
                                            {{item.DisplayName}}
                                        </div>
                                    </div>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <mat-expansion-panel-header *ngIf="item.IconName === 'group'" routerLink="{{item.ClusterComponents[0].Route}}" routerLinkActive="active" matTooltip="{{item.DisplayName}}" matTooltipDisabled="{{isExpanded}}">
                                <mat-panel-title>
                                    <div fxLayout="row" fxLayoutAlign="space-between center">
                                        <mat-icon *ngIf="item.IconName.includes('img/')">
                                            <img src="{{item.IconName}}" class="icon-png" />
                                        </mat-icon>
                                        <mat-icon *ngIf="!item.IconName.includes('img/')">
                                            {{item.IconName}}
                                        </mat-icon>
                                        <div *ngIf="isExpanded">{{item.DisplayName}}</div>
                                    </div>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <span *ngFor="let child of item.ClusterComponents">
                                <mat-list-item routerLink="{{child.Route}}" routerLinkActive="active" *ngIf="child.Enabled === true && !isExternalLink(child.Route)" (click)="setSelectedItem(item.ClusterId!)" matTooltip="{{child.DisplayName}}" matTooltipDisabled="{{isExpanded}}">
                                    <div fxLayout="row" fxLayoutAlign="space-between center" class="leafnode">
                                        <mat-icon *ngIf="child.IconName.includes('img/')" class='sidenav-icon'>
                                            <img src="{{child.IconName}}" class="icon-png" />
                                        </mat-icon>
                                        <mat-icon *ngIf="!child.IconName.includes('img/')" class='sidenav-icon'>
                                            {{child.IconName}}
                                        </mat-icon>
                                        <div *ngIf="isExpanded">{{child.DisplayName}}</div>
                                    </div>
                                </mat-list-item>
                                <mat-list-item *ngIf="child.Enabled === true && isExternalLink(child.Route)" (click)="externalRedirect(child.Route)" matTooltip="{{child.DisplayName}}" matTooltipDisabled="{{isExpanded}}">
                                    <div fxLayout="row" fxLayoutAlign="space-between center" class="leafnode">
                                        <mat-icon *ngIf="child.IconName.includes('img/')" class='sidenav-icon'>
                                            <img src="{{child.IconName}}" class="icon-png" />
                                        </mat-icon>
                                        <mat-icon *ngIf="!child.IconName.includes('img/')" class='sidenav-icon'>
                                            {{child.IconName}}
                                        </mat-icon>
                                        <div *ngIf="isExpanded">{{child.DisplayName}}</div>
                                    </div>
                                </mat-list-item>
                            </span>
                        </mat-expansion-panel>
                    </mat-accordion>
                </span>
                <span *ngIf="(!item.ClusterComponents || item.ClusterComponents.length === 0) && item.Enabled === true">
                    <mat-list-item routerLink="{{item.Route}}" routerLinkActive="active" (click)="setSelectedItem(item.ClusterId!)" matTooltip="{{item.DisplayName}}" matTooltipDisabled="{{isExpanded}}">
                        <div fxLayout="row" fxLayoutAlign="space-between center" class="leafnode">
                            <mat-icon *ngIf="item.IconName.includes('img/')">
                                <img src="{{item.IconName}}" class="icon-png" />
                            </mat-icon>
                            <mat-icon *ngIf="!item.IconName.includes('img/')">
                                {{item.IconName}}
                            </mat-icon>
                            <div *ngIf="isExpanded">{{item.DisplayName}}</div>
                        </div>
                    </mat-list-item>
                </span>
            </span>
        </mat-nav-list>

        <div class="nav-container" style="position:relative; top: 20px;display:flex;justify-content:flex-end">
            <button mat-icon-button (click)="isExpanded = !isExpanded" style="float:right">
                <mat-icon *ngIf="!isExpanded" style="font-size:30px;" matTooltip="Expand">chevron_right</mat-icon>
                <mat-icon *ngIf="isExpanded" style="font-size: 30px;" matTooltip="Collapse">chevron_left</mat-icon>
            </button>
        </div>
        <div class="nav-container" style="position:relative; top: 25px;text-align:center; font-weight:bold">
            <span style="color:white">{{version}}</span>
        </div>
    </mat-sidenav>
</mat-sidenav-container>