<div class='left-menu'>
    <ul class='nav navbar-nav'>
        <!--<li [routerLinkActive]="['link-active']" [ngClass]="defaults.getIsControllerOwner() && !defaults.isReadonlyUser ? 'show': 'hide'">
            <a class='menu-callback' (click)="newEvent.emit({})" title="Create a new Error Code">
                <span class='glyphicon glyphicon-plus-sign'></span> New Error
            </a>
        </li>

        <li [routerLinkActive]="['link-active']" *ngIf="!defaults.isReadonlyUser">
            <a class='menu-callback' (click)="onNewLanguage()" title="Create a new Language for this controller type">
                <span class='glyphicon glyphicon-plus-sign'></span> New Language
            </a>
        </li>
        <li [routerLinkActive]="['link-active']">
            <a class='menu-callback' (click)="openDocumentationModal()" title="Wiki">
                <span class='glyphicon glyphicon-info-sign'></span> Documentation
            </a>
        </li>
        <li class="separator"></li>
        <li class="submenu-title">
            <h4>Excel</h4>
        </li>
        <li [routerLinkActive]="['link-active']">
            <a class='menu-callback' target="_blank" href="api/ImportExportModel/download/errorcodes/error-codes" title="Dowload an Excel file with all ErrorCodes and Possible Resolutions, to manage and upload afterwards">
                <span class='glyphicon glyphicon-download'></span> Download
            </a>
        </li>
        <li [routerLinkActive]="['link-active']" [ngClass]="defaults.canShowUploadButtons() && !defaults.isReadonlyUser ? 'show': 'hide'">
            <a class='menu-callback' (click)="onUpload()" title="Upload Excel file with Error Codes and Resolutions for the selected Controller Type">
                <span class='glyphicon glyphicon-upload'></span> Upload
            </a>
        </li>

        <li class="separator" *ngIf="this.defaults.getIsControllerOwner() && !defaults.isReadonlyUser"></li>
        <li [routerLinkActive]="['link-active']" *ngIf="this.defaults.getIsControllerOwner() && !defaults.isReadonlyUser">
            <a class='menu-callback' (click)="onCopyRulesToProd()">
                <span class='glyphicon glyphicon-upload'></span> Copy Rules to Prod
            </a>
        </li>

        <li class="separator" *ngIf="!defaults.isReadonlyUser" [ngClass]="saveService.classSaveButton"></li>-->
        <li [routerLinkActive]="['link-active']" [ngClass]="saveService.classSaveButton" *ngIf="!defaults.isReadonlyUser">
            <a class='menu-callback' (click)="saveService.fireSave()" title="Save all Error Code(s) and Resolution(s)">
                <span class='glyphicon glyphicon-floppy-disk'></span> Save
            </a>
        </li>
    </ul>
</div>