import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import "rxjs/operators";
import { Router } from '@angular/router';
import { DialogService } from '../../../../services/dialog.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DefaultValuesService } from '../../../../services/defaultvalues.service';
import { DatePipe } from '@angular/common'
import { Utils } from '../../../../utils/utils';


@Component({
    selector: 'add-functionkeypopup',
    templateUrl: './add-functionkeypopup.component.html',
    styleUrls: ['../../../../style/custom-mat-dialog.component.less']
})


export class AddFunctionKeyPopupComponent implements OnInit {

    body: any;

    submitted = false;
    form: FormGroup

    constructor(private http: HttpClient
        , private confimDialog: DialogService
        , private router: Router
        , @Inject(MAT_DIALOG_DATA) public data: any
        , @Inject('BASE_URL') private baseUrl: string
        , public dialogRef: MatDialogRef<AddFunctionKeyPopupComponent>
        , private fb: FormBuilder
        , public defaults: DefaultValuesService
        , private formBuilder: FormBuilder
        , private datepipe: DatePipe) {

        this.confimDialog.tthis = this;
        this.form = this.formBuilder.group({
            functionConfig: ['', [Validators.required]],
        });
    }

    ngOnInit(): void {
        this.body = {
            FunctionKey: '',
        }
        
    }
    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.form)
            if (this.form.invalid) {
                return;
            }
        Utils.httpCreateFunctionConfig(
            this.http
            , this.baseUrl
            , this.body
            , this
            , function (tthis: AddFunctionKeyPopupComponent, object: any) {
                tthis.dialogRef.close();
            }
        );
    }

    onBack() {

    }
}


