<div class="container-tab-lists dialog.classname">
    <div class="inner-tcontainer-tab">
    <h2>{{title}} for {{defaults.controllertype.Description}} (for all languages)</h2>
    <div class="inner-tcontainer-tab">
        <div class="tab-body">
            <div class="tab-content">
                <mat-tab-group #tabGroup (selectedTabChange)="defaults.updateComponentTab($event.tab.textLabel)">
                    <!-- Deployment Status-->
                    <mat-tab label="Deployment Status">

                        <div>
                            <controllertypedeploymentstatus-list></controllertypedeploymentstatus-list>
                        </div>
                    </mat-tab>

                </mat-tab-group>
            </div>
        </div>
    </div> <!--inner-tcontainer-tab-->
</div> <!--container-tab-lists-->
