<div class="custom-mat-dialog-form">
    <div>
        <h3>{{this.data.ActionItemId ? 'Update' : 'Create new'}} Action Item for {{this.data.measureDescription}}</h3>
    </div>
    <div>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-sm-6 form-group">
                    <div>
                        <mat-form-field appearance="outline">
                            <mat-label>Name</mat-label>
                            <input matInput formControlName="name" required>
                        </mat-form-field>

                        <p *ngIf="
                           form.get('name')?.invalid &&
                             (form.get('name')?.dirty ||
                               form.get('name')?.touched)">
                            <mat-error *ngIf="form.get('name')?.hasError('required')">This field is required</mat-error>
                        </p>
                    </div>

                </div>
                <div class="col-sm-6 form-group">
                    <div>
                        <mat-form-field appearance="outline">
                            <mat-label>Owner</mat-label>
                            <input matInput formControlName="owner" required>
                        </mat-form-field>

                        <p *ngIf="
                           form.get('owner')?.invalid &&
                             (form.get('owner')?.dirty ||
                               form.get('owner')?.touched)">
                            <mat-error *ngIf="form.get('owner')?.hasError('required')">This field is required</mat-error>
                        </p>
                    </div>

                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 form-group">
                    <div>
                        <mat-form-field appearance="outline">
                            <mat-label>Description</mat-label>
                            <input matInput formControlName="description" required>
                        </mat-form-field>

                        <p *ngIf="
                form.get('description')?.invalid &&
                  (form.get('description')?.dirty ||
                    form.get('description')?.touched)">
                            <mat-error *ngIf="form.get('description')?.hasError('required')">This field is required</mat-error>
                        </p>
                    </div>

                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 form-group">
                    <div>
                        <mat-form-field appearance="outline">
                            <mat-label>Deadline</mat-label>
                            <input matInput [matDatepicker]="picker" [min]="minDate" formControlName="deadline" format="yyyy-MM-dd">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                        <p *ngIf="
                           form.get('deadline')?.invalid &&
                             (form.get('deadline')?.dirty ||
                               form.get('deadline')?.touched)">
                            <mat-error *ngIf="form.get('deadline')?.hasError('deadline')">This field is required</mat-error>
                        </p>
                    </div>

                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 form-group">
                    <div>
                        <mat-form-field appearance="outline">
                            <mat-label>Comments</mat-label>
                            <input matInput formControlName="comments" required>
                        </mat-form-field>

                        <p *ngIf="
                           form.get('comments')?.invalid &&
                             (form.get('comments')?.dirty ||
                               form.get('comments')?.touched)">
                            <mat-error *ngIf="form.get('comments')?.hasError('required')">This field is required</mat-error>
                        </p>
                    </div>

                </div>
            </div>
            <div class="row">
                <div class="panel-body">
                    <button (click)="backButtonHandler($event)" mat-raised-button class="btn  button-color-fix_red ">
                        <mat-icon class=" icon-color-fix ">arrow_back_ios</mat-icon>
                        Back
                    </button>
                    <button mat-raised-button type="submit" class="btn button-color-fix pull-right" [disabled]="!form.valid">
                        {{buttonLabel}}
                        <mat-icon class=" icon-color-fix ">{{buttonIcon}}</mat-icon>
                    </button>

                </div>
            </div>
        </form>
    </div>
</div>
