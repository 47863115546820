import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { DialogService } from '../../services/dialog.service';
import { DefaultValuesService } from '../../services/defaultvalues.service';
import { SaveService } from '../../services/save.service';
import { Utils } from '../../utils/utils';
import { Router } from '@angular/router';

@Component({
    selector: 'fiscalyear',
    template: `Fiscal Year:<br />
                <ng-select (change)="onSelect($event)" style="width:120px; border: 1px white solid" class="top-main-select"
                    [items]="list"
                   bindLabel="label"
                   bindValue="value"
                   [closeOnSelect]="true"
                   [searchable]="true"
                   [(ngModel)]="defaults.fiscalYear">
                </ng-select>`
})

export class FiscalYearComponent implements OnInit {
    list: any[] = [];
    value: number = 0;

    protected _onDestroy = new Subject<void>();

    constructor(
        private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , public defaults: DefaultValuesService
        , private dialog: DialogService
        , public saveService: SaveService
        , private router: Router

    ) {
    }

    async ngOnInit() {
        this.dialog.tthis = this;

        const now = new Date();
        let year = now.getFullYear();
        let month = now.getMonth();
        while (!this.defaults.loadInitialData) {
            await new Promise(r => setTimeout(r, 1000));
        }


        const localArrayList: any[] = []
        if (this.defaults.isMersyAdminUser === true) {
            for (let i = year + 3; i > year - 6; i--) {
                localArrayList.push({ 'label': `${i - 1}/${i}`, 'value': i })
            }
        }
        else {
            if (month < 9)
                localArrayList.push({ 'label': `${year - 1}/${year}`, 'value': year })
            else
                localArrayList.unshift({ 'label': `${year}/${year + 1}`, 'value': year + 1 })
        }

        this.list = localArrayList;
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }
    changeFiscalYear() {
        this.defaults.fiscalYear = this.value
        this.sendPost();
    }

    onNo() {
        this.saveService.fireCancelAll();
        this.changeFiscalYear();
    }

    onSelect(l: any) {
        if (!l.value)
            return;
        this.value = +l.value;
        if (this.saveService.isSaveButtonShown())
            this.dialog.showConfirmDialog("Do you want to save the changes? If you select No, all changes will be lost!", "Save", this);
        else
            this.onNo();

    }

    onYes() {
        this.saveService.fireSave();
        this.changeFiscalYear();
    }

    sendPost() {
        var tthis = this;
        this.defaults.fiscalYear = this.value;
        this.http
            .post<string>(this.baseUrl + Utils.getFiscalYearAPI(), this.defaults.fiscalYear)
            .subscribe(() => {
                this.defaults.notifyFiscalYearChange();
            }, error => Utils.writeLog(tthis.http, tthis.baseUrl, error)); //error => console.log(error));
    }
}