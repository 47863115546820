import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { Subject } from "rxjs";
import { SaveService } from "../../services/save.service";
import { DefaultValuesService } from "../../services/defaultvalues.service";
import { Utils } from "../../utils/utils";
import { DialogService } from "../../services/dialog.service";
import { animate, state, style, transition, trigger } from '@angular/animations';
import { TestCaseFormCopyComponent } from "./testcase-form-copy.component";
import { MatDialog } from "@angular/material/dialog";
import { TestCaseFormDetailComponent } from "./testcase-form-detail.component";
import { TestCaseFormJsonComponent } from "./testcase-form-json.component";
import { TestCaseExecuteComponent } from "./testcase-execute.component";

@Component({
    selector: "testcase-list",
    templateUrl: './testcase-list.component.html',
    styleUrls: ['./testcase-list.component.less', '../../style/mat-table.component.less'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*', })),
            transition('expanded => collapsed', [animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
        ]),
    ]
})

export class TestCaseListComponent implements OnInit {
    @Input('list') list: TestCase[] = [];
    @Output() onRefresh = new EventEmitter<{}>();

    columnsToDisplay = ['arrow', 'index', 'id', 'name', 'description', 'minutes', 'area', 'isITProcessRelevant', 'actions'];
    subcolumnsToDisplay = ['index', 'name', 'text', 'miliseconds', 'output', 'actions'];

    header = [
        { label: 'Name', show: true, searchtype: 'text' }
        , { label: 'Text', show: true, searchtype: 'text' }
        , { label: 'MiliSeconds In Event', show: true, searchtype: 'number' }
        , { label: 'Output', show: true, searchtype: 'checkbox', checkbox: true }
    ];


    @ViewChild(MatTable, { static: true }) table!: MatTable<any>;
    public dataSource = new MatTableDataSource<TestCase>();

    expandedElements = new Set();
    expanded: boolean[] = [];
    selected: TestCase | undefined;
    clonedList: { [s: string]: TestCase } = {};
    subscriptionTenant: any;

    protected _onDestroy = new Subject<void>();

    constructor(private activatedRoute: ActivatedRoute
        , private router: Router
        , private http: HttpClient
        , private saveService: SaveService
        , public defaults: DefaultValuesService
        , private matDialog: MatDialog
        , private dialog: DialogService
        , @Inject('BASE_URL') private baseUrl: string

    ) {
    }

    ngOnChanges() {
        this.dataSource.data = this.list
        this.expandedElements.forEach(x => {
            this.onExpand(x as number)
        })
        this.clonedList = {}
        this.saveService.showSaveButton(false)
        this.saveService.addListener(this)
    }

    ngOnInit() {
        this.getHeader();
        this.subscriptionTenant = this.defaults.tenantMessageChanges$.subscribe(() => {
            this.getHeader()
        });
        this.saveService.addListener(this);
    }

    ngOnDestroy() {
        this.subscriptionTenant.unsubscribe();
        this.saveService.removeListener(this);
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    getHeader() {
        if ((this.defaults.isDomainExpertUser || this.defaults.isadminuser || this.defaults.isExternalUser) && !this.defaults.isReadonlyUser) {
            this.columnsToDisplay = ['arrow', 'index', 'id', 'name', 'description', 'minutes', 'area', 'isITProcessRelevant', 'actions'];
            this.subcolumnsToDisplay = ['index', 'name', 'text', 'miliseconds', 'output', 'actions'];
        }
        else if ((this.defaults.isCountryBackendStableUser || this.defaults.isCountryBackendProdUser) && !this.defaults.isReadonlyUser) {
            this.columnsToDisplay = ['arrow', 'index', 'id', 'name', 'description', 'minutes', 'area', 'actions'];
            this.subcolumnsToDisplay = ['index', 'name', 'text', 'miliseconds', 'output'];
        }
        else {
            this.columnsToDisplay = ['arrow', 'index', 'id', 'name', 'description', 'minutes', 'area'];
            this.subcolumnsToDisplay = ['index', 'name', 'text', 'miliseconds', 'output'];
        }
    }

    checkExpanded(element: TestCase) {
        return this.expandedElements.has(element.TestCaseID);
    }

    pushPopElement(element: TestCase) {
        if (this.expandedElements.has(element.TestCaseID)) {
            this.expandedElements.delete(element.TestCaseID);
        }
        else {
            this.expandedElements.add(element.TestCaseID);
            this.onExpand(element.TestCaseID)
        }
    }

    onExpand(testCaseID: number) {
        const foundIndex = this.dataSource.data.findIndex((x: TestCase) => x.TestCaseID === testCaseID);
        Utils.httpGetTestCaseMessageTypeSchemaKeyDescriptions(
            this.http
            , this.baseUrl
            , testCaseID
            , this
            , function (tthis: TestCaseListComponent, list: TestCaseMessageTypesSchemaKeysDescription[]) {
                tthis.dataSource.data[foundIndex].Messages = list;
            }
        );
    }

    onRowEditInit(testcase: TestCase) {
        this.saveService.addListener(this);
        this.clonedList[testcase.TestCaseID] = { ...testcase };
        if (Object.keys(this.clonedList).length !== 0) this.onShowSavebutton()
    }

    onRowEditCancel(testcase: TestCase) {
        this.dataSource.data[this.dataSource.data.findIndex(x => x.TestCaseID === testcase.TestCaseID)] = this.clonedList[testcase.TestCaseID];
        delete this.clonedList[testcase.TestCaseID];
        if (Object.keys(this.clonedList).length === 0) this.saveService.showSaveButton(false)
        this.table.renderRows();
    }

    isEditingRow(testcase: TestCase) {
        return this.clonedList[testcase.TestCaseID]
    }

    changeIsITProcessRelevant(element: TestCase, value: boolean) {
        element.IsITProcessRelevant = value
        this.table.renderRows();
    }

    onCreateNewEvent(element: TestCase) {
        this.dialog.tthis = this;
        this.saveService.addListener(this);
        const dialog = this.matDialog.open(TestCaseFormDetailComponent, { panelClass: ['custom-mat-dialog', 'popup-dialog'] });
        dialog.componentInstance.list = element.Messages
        dialog.componentInstance.parent = element
        dialog.componentInstance.header = this.header
        dialog.afterClosed().subscribe(() => {
            this.onRefresh.emit({})
        });
    }

    onCopyTestcase(value: TestCase) {
        this.dialog.tthis = this;
        this.saveService.addListener(this);
        this.matDialog.open(TestCaseFormCopyComponent, { panelClass: ['custom-mat-dialog', 'popup-dialog'], data: value });
    }

    openJsonIngressPopup(element: TestCase) {
        this.dialog.tthis = this;
        this.saveService.addListener(this);
        const dialog = this.matDialog.open(TestCaseFormJsonComponent, {
            panelClass: ['custom-mat-dialog', 'popup-dialog']
        });
        dialog.componentInstance.parent = element
    }

    onRowEventEditInit(event: TestCaseMessageTypesSchemaKeysDescription) {
        const index = this.dataSource.data.findIndex(x => x.TestCaseID === event.TestCaseID)
        this.dialog.tthis = this;
        this.saveService.addListener(this);
        const dialog = this.matDialog.open(TestCaseFormDetailComponent, { panelClass: ['custom-mat-dialog', 'popup-dialog'] });
        dialog.componentInstance.list = this.dataSource.data[index].Messages
        dialog.componentInstance.parent = this.dataSource.data[index]
        dialog.componentInstance.rowIndex = this.dataSource.data[index].Messages.findIndex(x => x.IndexInTestCase === event.IndexInTestCase)
        dialog.componentInstance.header = this.header
        dialog.afterClosed().subscribe(() => {
            this.onRefresh.emit({})
        });
    }

    // DELETION METHODS

    onDelete(value: TestCase) {
        this.dialog.showConfirmDialog("Delete Test Case '" + value.Name + "' (" + value.TestCaseID + ")?", "Delete Test Case", this, value);
    }

    onDeleteEvent(value: TestCaseMessageTypesSchemaKeysDescription) {
        this.dialog.showConfirmDialog("Delete Test Case Message  '" + value.Text + "'?", "Delete Test Case Message", this, value);
    }

    onYes(value: any) {
        if ("IndexInTestCase" in (value as any)) {  // TestCaseMessageTypesSchemaKeysDescription
            this.http
                .delete<TestCaseMessageTypesSchemaKeysDescription>(
                    this.baseUrl + Utils.getTestCaseAPI() + value.TestCaseID + "/" + value.MessageTypeID + "/" + value.IndexInTestCase
                )
                .subscribe(
                    () => {
                        //this.list.splice(value.index, 1)
                        this.onRefresh.emit({});
                    }
                    , error => this.dialog.showErrorDialog(error)
                );
        }
        else {
            this.http
                .delete<TestCase>(
                    this.baseUrl + Utils.getTestCaseAPI() + value.TestCaseID
                )
                .subscribe(
                    () => {
                        //this.list.splice(this.list.findIndex(x => x.TestCaseID === value.TestCaseID), 1)
                        this.onRefresh.emit({});
                    }
                    , error => this.dialog.showErrorDialog(error)
                );
        }
    }

    onSave() {
        for (let i in this.clonedList) {
            let index = this.dataSource.data.findIndex(x => x.TestCaseID === this.clonedList[i].TestCaseID)
            const url = this.baseUrl + Utils.getTestCaseAPI() + "edit";
            this.http
                .post<TestCase>(url, this.dataSource.data[index])
                .subscribe(res => {
                    this.onRefresh.emit({})
                }, error => this.dialog.showErrorDialog(error));
        }
        this.clonedList = {}
        this.saveService.showSaveButton(false)
    }

    onDown(value: TestCaseMessageTypesSchemaKeysDescription) {
        this.move(value, "up")
    }

    onUp(value: TestCaseMessageTypesSchemaKeysDescription) {
        this.move(value, "down")
    }

    private move(value: TestCaseMessageTypesSchemaKeysDescription, direction: string) {
        this.http
            .put<TestCaseMessageTypesSchemaKeysDescription>(
                this.baseUrl + Utils.getTestCaseAPI() + direction
                , value
            )
            .subscribe(
                () => this.onRefresh.emit({})
                , error => this.dialog.showErrorDialog(error)
            );
    }

    openExecuteTestCaseModal(element: TestCase) {
        this.matDialog.open(TestCaseExecuteComponent, {
            panelClass: ['custom-mat-dialog', 'popup-dialog'],
            data: {
                testCase: element
            }
        }
        );
    }

    onShowSavebutton() {
        this.saveService.showSaveButton();
    }
}
