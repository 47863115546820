import { Component, Input, OnInit, } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";

@Component({
    selector: "sectionscheduler-list",
    templateUrl: './sectionscheduler-list.component.html',
    styleUrls: ['./sectionscheduler-list.component.less']
})

export class SectionSchedulerListComponent implements OnInit {

    columnsToDisplay = ['section', 'startperiod', 'endperiod', 'startperiod2', 'endperiod2'];
    @Input() list: SectionScheduler[] = [];
    pattern = "^(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$"

    public dataSource = new MatTableDataSource<SectionScheduler>();
    constructor(
    ) {
    }

    ngOnInit() {
    }

    ngOnChanges() {
        this.dataSource.data = this.list
    }
}