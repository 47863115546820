import { Component, OnInit, Inject } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { DefaultValuesService } from '../../../../services/defaultvalues.service';
import { Utils } from "../../../../utils/utils";
import { MatTableDataSource } from "@angular/material/table";
import { DialogService } from "../../../../services/dialog.service";
import { CustomDialogService } from "../../../../services/customdialog.service";
@Component({
    selector: "certificate",
    templateUrl: 'certificate.component.html',
})

export class CertificateComponent implements OnInit {

    subscriptionStage: any;
    subscriptionTenant: any;
    columnsToDisplay = ['certificate', 'actions'];

    rowsToDisplay = [{
        'Name': 'Server Certificate', 'isSSL': true
    },
    {
        'Name': 'Client Certificates', 'isSSL': false
    }]

    public dataSource = new MatTableDataSource<any>();

    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string
        , private defaults: DefaultValuesService
        , private confimDialog: DialogService
        , private customDialog: CustomDialogService
    ) {

    }

    ngOnInit(): void {
        this.dataSource.data = this.rowsToDisplay;
    }

    downloadCertificates(isSSL: boolean) {
        Utils.httpGetCertificates(
            this.http,
            this.baseUrl,
            isSSL,
            (password: string) => {
                navigator.clipboard.writeText(password).then(()=>{
                    this.confimDialog.showSuccessDialog("Certificates have been downloaded as a zip file. Zip password has been copied to the clipboard.");
                })
                .catch((err)=>{
                    this.customDialog.openError({ 'message': 'Unable to copy the password in the clipboard. Please allow the function in the browser and do not switch screens while downloading the file.' });
                });
                
            },
            () => {
                this.customDialog.openError({ 'message': 'Certificates are not available. Please contact DevOps team.' });
            },
        )
    }
}
