
<iframe title="STABLE" *ngIf='this.ReportType === "ST" '
        style="height: 90vh; display: block; width: -webkit-fill-available"
        src="https://app.powerbi.com/reportEmbed?reportId=8d05426d-d8b9-4af2-9787-6fc55f0fe92f&autoAuth=true&ctid=84d9a216-e285-4aac-b163-0dfd0c074546"
        frameborder="0"
        allowFullScreen="true">
</iframe>
<iframe title="AMS" *ngIf='this.ReportType === "AMS" '
        style="height: 90vh; display: block; width: -webkit-fill-available"
        src="https://app.powerbi.com/reportEmbed?reportId=2f23e940-1100-48ef-a99f-762d9c5acea1&autoAuth=true&ctid=84d9a216-e285-4aac-b163-0dfd0c074546"
        frameborder="0"
        allowFullScreen="true">
</iframe>
<iframe title="EA" *ngIf='this.ReportType === "EA" '
        style="height: 90vh; display: block; width: -webkit-fill-available"
        src="https://app.powerbi.com/reportEmbed?reportId=886ab47f-d7b9-4a7d-945d-0e1a72cfef37&autoAuth=true&ctid=84d9a216-e285-4aac-b163-0dfd0c074546"
        frameborder="0"
        allowFullScreen="true">
</iframe>
<iframe title="AP" *ngIf='this.ReportType === "AP" '
        style="height: 90vh; display: block; width: -webkit-fill-available"
        src="https://app.powerbi.com/reportEmbed?reportId=935c955b-d380-4f83-a5b0-c263feae7210&autoAuth=true&ctid=84d9a216-e285-4aac-b163-0dfd0c074546"
        frameborder="0"
        allowFullScreen="true">
</iframe>

