import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Component, ElementRef, ViewChild, Inject, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { Injectable, AfterViewInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';


import { HttpClient } from '@angular/common/http';

import { ActivatedRoute, Params } from '@angular/router';
import { UMPCategory, Utils } from '../../../../../utils/utils';
import { SpinnerOverlayService } from '../../../../../services/spinner-overlay.service';
import { SaveService } from '../../../../../services/save.service';


interface MGMTBranchNode {
    groupId: string;
    groupName: string;
    subGroupsAllowed: boolean,
    selected?: boolean;
    disabled?: boolean;
    indeterminate?: boolean;
    parent?: MGMTBranchNode;
    groups: MGMTBranchNode[];
    groupFunction: string;
}




@Component({
    selector: "usermgmtbranch",
    templateUrl: './usermgmtbranch.component.html',
    styleUrls: ['./usermgmtbranch.component.less', '../../../../../style/new-generic-styles.component.less'],
    encapsulation: ViewEncapsulation.None,
})

export class UserMgmtBranch implements OnInit {
    @Input() public groupmgmtcallback: Function | undefined;
    selectorValue: string = 'All';
    allToggelButtonOption = [];

    userId = '';
    public TREE_DATA: MGMTBranchNode[] = [];/*** */

    public selectedNodes: string[] = [];
    public unSelectedNodes: string[] = [];

    public treeControl: NestedTreeControl<MGMTBranchNode>;
    public dataSource = new MatTreeNestedDataSource<MGMTBranchNode>();

    public searchString = '';
    public showOnlySelected = false;
    Tree_Response_State: number = -2; //0 -> empty , 1 -> correct and not empty ,-1 -> error, -2 -> Calling API

    isCallSucceed: number = 0;


    data: TreeManagement<UMPCategory> = {
        SelectedBranches: [],
        UnselectedBranches: [],
        UmpCategory: UMPCategory.MgmtBranchHierarchy
    }

    constructor(
        private toastrService: ToastrService,
        private route: ActivatedRoute,
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string,
        private spinnerOverlayService: SpinnerOverlayService,
        private saveService: SaveService
    ) {
        this.route.params.subscribe(
            (params: Params) => {
                this.resetAll();
            });
        this.treeControl = new NestedTreeControl<MGMTBranchNode>((node) => node.groups)

    }
    ngOnInit() {
    }
    resetAll() {
        this.treeControl = new NestedTreeControl<MGMTBranchNode>((node) => node.groups);
        this.dataSource = new MatTreeNestedDataSource<MGMTBranchNode>();
        this.userId = this.route.snapshot.paramMap.get('userId') || '';
        this.allToggelButtonOption = [];
        this.funcGetUserMgmtBranches();
    }
    private preselect() {
        this.unSelectedNodes = [] as string[];
        for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
            if (this.selectedNodes.find(e => e === this.treeControl.dataNodes[i].groupId)) {
                this.itemToggle(true, this.treeControl.dataNodes[i]);
            }
            else
                this.unSelectedNodes.push(this.treeControl.dataNodes[i].groupId);
            if (this.treeControl.dataNodes[i].groups) {

                const alldecendents = this.treeControl.getDescendants(this.treeControl.dataNodes[i]);

                for (let j = 0; j < alldecendents.length; j++) {
                    if (this.selectedNodes.find(e => e === alldecendents[j].groupId)) {
                        this.itemToggle(true, alldecendents[j]);
                    }
                    else
                        this.unSelectedNodes.push(alldecendents[j].groupId);
                }
            }
        }

    }
    public hasChild = (_: number, node: MGMTBranchNode) =>
        !!node.groups && node.groups.length > 0;

    private setParent(node: MGMTBranchNode, parent: MGMTBranchNode | undefined) {
        if (node)
            node.parent = parent;
        if (node.groups) {
            node.groups.forEach((childNode) => {
                this.setParent(childNode, node);
            });
        }
    }

    private checkAllParents(node: MGMTBranchNode) {
        if (node.parent) {
            const descendants = this.treeControl.getDescendants(node.parent);
            node.parent.indeterminate = descendants.some((child) => child.selected);
            this.checkAllParents(node.parent);
        }
    }

    public itemToggle(checked: boolean, node: MGMTBranchNode, manualUpdate: boolean = false) {
        if (manualUpdate) {
            this.saveService.addListener(this)
            this.saveService.showSaveButton();
        }
        node.selected = checked;
        if (node.groups && checked) {
            node.indeterminate = false;
            this.itemUnselect(node);

        }
        if (node.groups) {
            this.itemDisable(checked, node);
        }
        if (manualUpdate) {
            if (checked) {
                this.data.UnselectedBranches = this.data.UnselectedBranches.filter(x => x !== node.groupId)
                if (this.unSelectedNodes.find(e => e === node.groupId))
                    this.data.SelectedBranches.push(node.groupId);
            }
            else {
                this.data.SelectedBranches = this.data.SelectedBranches.filter(x => x !== node.groupId)
                if (this.selectedNodes.find(e => e === node.groupId))
                    this.data.UnselectedBranches.push(node.groupId);
            }
        }
        this.checkAllParents(node);
    }
    private itemUnselect(node: MGMTBranchNode) {
        const descendants = this.treeControl.getDescendants(node);
        descendants.forEach(node => { node.selected ? this.data.UnselectedBranches.push(node.groupId) : null; node.indeterminate = false; node.selected = false });
    }

    private itemDisable(checked: boolean, node: MGMTBranchNode) {
        const descendants = this.treeControl.getDescendants(node);
        descendants.forEach(node => node.disabled = checked);
    }

    public onSave() {
        if (this.data.SelectedBranches.length > 0 || this.data.UnselectedBranches.length > 0) {
            this.spinnerOverlayService.setFalseskipInterceptor()
            Utils.httpPostTreeGroupsByType(
                this.http
                , this.baseUrl
                , this.data
                , this.userId
                , this
                , function (tthis: UserMgmtBranch, data: any) {
                    tthis.data.SelectedBranches = []
                    tthis.data.UnselectedBranches = []
                    tthis.spinnerOverlayService.setTrueskipInterceptor()
                    tthis.funcNotify();
                    tthis.funcGetUserMgmtBranches();
                    if (tthis.groupmgmtcallback)
                        tthis.groupmgmtcallback(data);
                }
            )
        }
    }

    public hideLeafNode(node: MGMTBranchNode): boolean {
        return new RegExp(this.searchString, 'i').test(node.groupName) === false;
    }
    public hideLeafNodeselector(selVal, node: MGMTBranchNode): boolean {
        return this.groupFuncExist(selVal, node) === false;
    }
    groupFuncExist(selVal, node: MGMTBranchNode) {
        if (node.groupFunction) {
            var groupFunction = node.groupFunction;
            // Check if namesString contains a "/"
            if (groupFunction.includes('/')) {
                // Split the namesString into an array using "/"
                const namesArray = groupFunction.split('/').map(name => name.trim().toLowerCase());

                // Check if the targetName is present in the array
                return namesArray.includes(selVal.toLowerCase());
            } else {
                // If there are no slashes, compare the namesString directly with targetName
                return groupFunction.trim().toLowerCase() === selVal.toLowerCase();
            }
        }
        return false

    }


    public hideParentNode(node: MGMTBranchNode): boolean {
        if (
            !this.searchString ||
            node.groupName.toLowerCase().indexOf(this.searchString.toLowerCase()) !==
            -1
        ) {
            return false
        }
        const descendants = this.treeControl.getDescendants(node)

        if (
            descendants.some(
                (descendantNode) =>
                    descendantNode.groupName
                        .toLowerCase()
                        .indexOf(this.searchString.toLowerCase()) !== -1
            )
        ) {
            return false
        }

        return true
    }
    public hideParentNodeselector(selVal, node: MGMTBranchNode): boolean {

        if (!selVal || (node.groupFunction && node.groupFunction.toLowerCase().indexOf(selVal.toLowerCase()) !== -1)) {
            return false;
        }

        const descendants = this.treeControl.getDescendants(node);

        if (descendants.some((descendantNode) => descendantNode.groupFunction && this.groupFuncExist(selVal, descendantNode))) {
            return false;
        }

        return true;
    }
    funcGetUserMgmtBranches() {
        this.Tree_Response_State = -2;
        this.treeControl.dataNodes = [];
        this.TREE_DATA.splice(0, this.TREE_DATA.length)
        Utils.httpGetUserMgmtBranches(
            this.http
            , this.baseUrl
            , this.userId
            , this
            , (tthis: UserMgmtBranch, data: any) => {
                tthis.allToggelButtonOption = data.groupFunctions;
                tthis.TREE_DATA = data.treeView; ///******** */
                tthis.treeControl.dataNodes = tthis.TREE_DATA;
                tthis.dataSource.data = tthis.TREE_DATA;
                tthis.selectedNodes = data.excludedPersonae;
                Object.keys(tthis.dataSource.data).forEach((key) => {
                    tthis.setParent(tthis.dataSource.data[key], undefined);

                }
                )
                tthis.preselect();


            });
    }

    funcNotify() {
        if (this.isCallSucceed === 1) {
            this.toastrService.success('Update Management Branch Hierarchy request sent successfully !', 'Update Management Branch Hierarchy Request!');
        }
        else if (this.isCallSucceed === -1) {
            this.toastrService.error('Update Management Branch Hierarchy request not sent successfully !', 'Update Management Branch Hierarchy Request!');
        }
    }

}