import { Component, Inject, Input, OnInit, EventEmitter, Output, ViewChild, ViewChildren, QueryList } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Utils } from "../../utils/utils";
import { DefaultValuesService } from "../../services/defaultvalues.service";
import { DialogService } from "../../services/dialog.service";
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SaveService } from '../../services/save.service';
import { COMMA, END, ENTER, TAB } from '@angular/cdk/keycodes';
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { MatSelect } from "@angular/material/select";
import { MatDialog } from "@angular/material/dialog";
import { MatChipInputEvent } from "@angular/material/chips";

@Component({
    selector: "errorcode-list-description",
    templateUrl: './errorcode-list-description.component.html',
    styleUrls: ['errorcode-list-properties.component.less', './errorcode-list-description.component.less'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*', })),
            transition('expanded => collapsed', [animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
        ]),
    ]
})

export class ErrorCodeListDescriptionComponent implements OnInit {
    @Input('list') list: ErrorCode[] = [];
    @Output() onUpdateDescriptions = new EventEmitter<{}>();
    @Output() eventFilter = new EventEmitter<ErrorCode[]>();

    @Input('keywords') keywords: string[] = [];
    @Input('openDeploymentInformationPopup') openDeploymentInformationPopup!: Function

    @ViewChild(MatTable, { static: true }) table!: MatTable<any>;

    public clonedList: { [s: string]: ErrorCode; } = {};
    public clonedResolutionDescriptionList: { [s: string]: ErrorCodeResolution; } = {};
    expandedElements = new Set();

    public dataSourceDescription = new MatTableDataSource<any>();

    columnsToDisplay = ['arrow', 'errorcode', 'descref', 'desc', 'actions'];
    subcolumnsToDisplay = ['failure_ref', 'failure', 'troubleshooting_ref', 'troubleshooting', 'actions'];

    subscriptionLanguage: any;
    subscriptionTenant: any;
    subscriptionIsControllerOwner: any;

    //////////////////
    // Keyword section
    showKeywords = -1;
    selectedTroubleshooting: ErrorCodeResolution = {
        'PossibleFailureID': -1,
        ErrorCode: 0,
        ControllerTypeID: 0,
        ErrorCodeID: 0,
        SubSystemGroupID: 0,
        ComponentItemID: 0,
        MaintenanceFailureID: 0,
        MaintenanceFailure_Ref: "",
        MaintenanceFailure: "",
        PartsRequired: false,
        HighValuePart: false,
        TroubleShootingAction_Ref: "",
        TroubleShootingActionID: 0,
        TroubleShootingAction: "",
        TroubleShootingActionWeight: 0,
        LanguageID: 0,
        LanguageCultureName: "",
        CONTROLLERTYPEBK: "",
        SystemGroup: "",
        SubSystemGroup: "",
        ComponentItem: "",
        Cause: "",
        CauseCode: "",
        CauseID: 0,
        Resolution: "",
        ResolutionCode: "",
        ResolutionID: 0,
        FaultDescriptionID: 0,
        TranslationAreaID: 0,
        ComponentInView: false,
        CauseInView: false,
        components: undefined
    };
    isShowingKeywordsSelector = false;
    @ViewChildren(MatSelect, { read: MatSelect }) items: QueryList<MatSelect> | undefined;
    caret = -1;

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [COMMA, TAB, ENTER];
    searchItems: SearchItem[] = [];

    constructor(
        private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , public defaults: DefaultValuesService
        , private dialog: DialogService
        , public saveService: SaveService
        , private matDialog: MatDialog

    ) {
    }

    ngOnInit() {
        this.dialog.tthis = this;
        this.subscriptionLanguage = this.defaults.languageMessageChanges$.subscribe(() => {
            this.getHeader()
        });
        this.subscriptionTenant = this.defaults.tenantMessageChanges$.subscribe(() => {
            this.getHeader()
        });
        this.subscriptionIsControllerOwner = this.defaults.isControllerOwnerChange$.subscribe(() => {
            this.getHeader()
        });

        this.dataSourceDescription.filterPredicate = (data: any, filters: string) => {
            const matchFilter: any[] = [];
            const filterArray = filters.split('+')
            filterArray.forEach((filter) => {
                let result = false;
                const errorCode = data.ErrorCode ? data.ErrorCode : ''
                const desc = data.Description ? data.Description : ''
                const desc_ref = data.Description_ref ? data.Description_ref : ''
                result = desc.toString().toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
                    errorCode.toString().toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
                    desc_ref.toString().toLowerCase().indexOf(filter.toLowerCase()) > -1
                return matchFilter.push(result);
            });
            return matchFilter.some(Boolean);
        };
    }

    ngOnChanges() {
        this.dataSourceDescription.data = this.list
    }

    ngOnDestroy() {
        this.subscriptionTenant.unsubscribe();
        this.subscriptionLanguage.unsubscribe();
    }

    getHeader() {
        if ((this.isToShowReference() && this.defaults.getIsControllerOwner() && !this.defaults.isReadonlyUser)
            || (this.defaults.isadminuser && this.isToShowReference())
            || (this.isToShowReference() && this.defaults.language.LanguageCultureName.indexOf(this.defaults.tenant) > -1) && !this.defaults.isReadonlyUser) {
            this.columnsToDisplay = ['arrow', 'errorcode', 'descref', 'desc', 'actions'];
            this.subcolumnsToDisplay = ['failure_ref', 'failure', 'troubleshooting_ref', 'troubleshooting', 'actions'];
        }
        else if (this.isToShowReference() && (!this.defaults.getIsControllerOwner() || this.defaults.isReadonlyUser)) {
            this.columnsToDisplay = ['arrow', 'errorcode', 'descref', 'desc'];
            this.subcolumnsToDisplay = ['failure_ref', 'failure', 'troubleshooting_ref', 'troubleshooting'];
        }
        else if (((this.defaults.getIsControllerOwner() && !this.defaults.isReadonlyUser)
            || (this.defaults.isadminuser && !this.isToShowReference())
            || (this.defaults.language.LanguageCultureName.indexOf(this.defaults.tenant) > -1) && !this.defaults.isReadonlyUser)) {
            this.columnsToDisplay = ['arrow', 'errorcode', 'desc', 'actions'];
            this.subcolumnsToDisplay = ['failure', 'troubleshooting', 'actions'];
        }
        else {
            this.columnsToDisplay = ['arrow', 'errorcode', 'desc'];
            this.subcolumnsToDisplay = ['failure', 'troubleshooting'];
        }
        this.clonedList = {}
        this.clonedResolutionDescriptionList = {}

    }

    checkExpanded(element: ErrorCode) {
        return this.expandedElements.has(element);
    }
    pushPopElement(element: ErrorCode) {
        if (this.expandedElements.has(element)) {
            this.expandedElements.delete(element);
        }
        else {
            this.expandedElements.add(element);
            this.onExpand(element)
        }
    }

    isToShowReference(): boolean {
        return this.defaults.language.LanguageId != Utils.getDefaultLaguangeID();
    }

    notTranslatedClass(value: string): string {
        return Utils.notTranslatedClass(value);
    }

    onExpand(element: any) {
        let foundIndex = this.dataSourceDescription.data.findIndex((x: ErrorCode) => x.ErrorCode === element.ErrorCode && x.ErrorCodeID === element.ErrorCodeID);
        Utils.httpGetAllErrorCodeResolutions(
            this.http
            , this.baseUrl
            , element.ErrorCodeID
            , this
            , function (tthis: any, list: ErrorCodeResolution[]) {
                tthis.dataSourceDescription.data[foundIndex].ErrorCodeResolutions = list;
            }
        );
    }

    isEditingRow(element: ErrorCode) {
        return this.clonedList[element.ErrorCodeID]
    }
    isEditingResol(element: ErrorCodeResolution) {
        return this.clonedResolutionDescriptionList[element.PossibleFailureID]
    }

    onRowEditInit(element: ErrorCode) {
        this.saveService.addListener(this);
        this.clonedList[element.ErrorCodeID] = { ...element }
        if (Object.keys(this.clonedList).length !== 0) this.onShowSavebutton()
    }

    onRowResolEditInit(element: ErrorCodeResolution) {
        this.saveService.addListener(this);
        Utils.httpGetAllComponents(
            this.http
            , this.baseUrl
            , this
            , element.SubSystemGroupID
            , function (tthis: any, list: ComponentItem[]) {
                element.components = list;
            }
        );
        this.clonedResolutionDescriptionList[element.PossibleFailureID] = Object.assign({}, element)
        if (this.clonedResolutionDescriptionList !== {}) this.onShowSavebutton()
        this.selectedTroubleshooting = { ...element }
    }

    showKeywordsTooltip(errorCode: ErrorCode) {  // Showing keywords tooltip whenever user is editing a resolution
        const currentEditResol = Object.values(this.clonedResolutionDescriptionList)
        return currentEditResol.find(x => x.ErrorCodeID === errorCode.ErrorCodeID)
    }

    selectTroubleshootingInput(element: ErrorCodeResolution) {
        this.selectedTroubleshooting = { ...element }
    }

    async onShowKeywords() {
        const input = document.getElementById('troubleshooting_' + this.selectedTroubleshooting.PossibleFailureID) as HTMLInputElement
        if (input)
            this.caret = input.selectionStart || 0;
        this.isShowingKeywordsSelector = true;
        let target: any;
        do {
            target = document.getElementById('keywords_' + this.selectedTroubleshooting.PossibleFailureID);
            await new Promise(r => setTimeout(r, 20));
        }
        while (!target)
        if (this.items)
            this.items.forEach((div) => {
                if (div.id === 'keywords_' + this.selectedTroubleshooting.PossibleFailureID) {
                    setTimeout(() => {
                        div.open()
                    })
                }
            });
    }

    openedChange($event: boolean) {
        if (!$event)
            this.isShowingKeywordsSelector = false;
    }

    onSelectItem(value: string) {

        // Get resol to be edited
        const index = this.dataSourceDescription.data.findIndex(x => x.ErrorCodeID === this.selectedTroubleshooting.ErrorCodeID)
        const subindex = this.dataSourceDescription.data[index].ErrorCodeResolutions.findIndex(x => x.PossibleFailureID === this.selectedTroubleshooting.PossibleFailureID)

        // Intersect or append depending on previous user event
        // If field is empty -> add directly
        if (!this.dataSourceDescription.data[index].ErrorCodeResolutions[subindex].TroubleShootingAction || this.dataSourceDescription.data[index].ErrorCodeResolutions[subindex].TroubleShootingAction === '') {
            this.dataSourceDescription.data[index].ErrorCodeResolutions[subindex].TroubleShootingAction =
                value
        }
        else {
            if (this.caret === 0) {
                this.dataSourceDescription.data[index].ErrorCodeResolutions[subindex].TroubleShootingAction =
                    value + ' ' + this.dataSourceDescription.data[index].ErrorCodeResolutions[subindex].TroubleShootingAction
            }
            else if (this.caret === this.dataSourceDescription.data[index].ErrorCodeResolutions[subindex].TroubleShootingAction.length) {
                this.dataSourceDescription.data[index].ErrorCodeResolutions[subindex].TroubleShootingAction =
                    this.dataSourceDescription.data[index].ErrorCodeResolutions[subindex].TroubleShootingAction + ' ' + value
            }
            else {
                this.dataSourceDescription.data[index].ErrorCodeResolutions[subindex].TroubleShootingAction =
                    this.dataSourceDescription.data[index].ErrorCodeResolutions[subindex].TroubleShootingAction.substring(0, this.caret) +
                    ' ' +
                    value +
                    ' ' +
                    this.dataSourceDescription.data[index].ErrorCodeResolutions[subindex].TroubleShootingAction.substring(this.caret);
            }
        }

        // Cleaining variables
        this.isShowingKeywordsSelector = false;
        this.caret = 0;
    }

    highlightTroubleshootingWithKeywords(text: string) {
        if (text) {
            const matches = text.split(' ')
            const tokens: string[] = []
            matches.forEach(sr => {
                if (this.keywords.findIndex(x => x === sr) > -1) {
                    tokens.push('<span class="orange-keyword"> ' + sr.replace(/</g, '').replace(/>/g, '') + '</span>')
                }
                else
                    tokens.push(sr);
            })
            return tokens.join(' ');
        }
        return null;
    }

    onRowEditCancel(element: ErrorCode) {
        const index = this.dataSourceDescription.data.findIndex(x => x.ErrorCodeID === element.ErrorCodeID)
        this.dataSourceDescription.data[index].Description_ref = this.clonedList[element.ErrorCodeID].Description_ref
        this.dataSourceDescription.data[index].Description = this.clonedList[element.ErrorCodeID].Description
        delete this.clonedList[element.ErrorCodeID];
        if (Object.keys(this.clonedList).length === 0 && Object.keys(this.clonedResolutionDescriptionList).length === 0) this.saveService.showSaveButton(false)
    }

    onResolEditCancel(element: ErrorCodeResolution) {
        const index = this.dataSourceDescription.data.findIndex(x => x.ErrorCodeID === element.ErrorCodeID)
        const resolIndex = this.dataSourceDescription.data[index].ErrorCodeResolutions.findIndex(x => x.PossibleFailureID === element.PossibleFailureID)
        this.dataSourceDescription.data[index].ErrorCodeResolutions[resolIndex].TroubleShootingAction = this.clonedResolutionDescriptionList[element.PossibleFailureID].TroubleShootingAction;
        this.dataSourceDescription.data[index].ErrorCodeResolutions[resolIndex].TroubleShootingAction_Ref = this.clonedResolutionDescriptionList[element.PossibleFailureID].TroubleShootingAction_Ref;
        this.dataSourceDescription.data[index].ErrorCodeResolutions[resolIndex].MaintenanceFailure_Ref = this.clonedResolutionDescriptionList[element.PossibleFailureID].MaintenanceFailure_Ref;
        this.dataSourceDescription.data[index].ErrorCodeResolutions[resolIndex].MaintenanceFailure = this.clonedResolutionDescriptionList[element.PossibleFailureID].MaintenanceFailure;
        delete this.clonedResolutionDescriptionList[element.PossibleFailureID];
        if (Object.keys(this.clonedResolutionDescriptionList).length === 0 && Object.keys(this.clonedList).length === 0) this.saveService.showSaveButton(false)

        const last = this.clonedResolutionDescriptionList[Object.keys(this.clonedResolutionDescriptionList)[Object.keys(this.clonedResolutionDescriptionList).length - 1]]
        this.isShowingKeywordsSelector = false;
        if (last)
            this.selectedTroubleshooting.PossibleFailureID = last.PossibleFailureID;
        else
            this.selectedTroubleshooting.PossibleFailureID = -1
        this.caret = -1
    }

    onShowSavebutton() {
        this.saveService.showSaveButton();
    }

    onSave() {
        for (let i in this.clonedList) {
            const index = this.dataSourceDescription.data.findIndex(x => x.ErrorCodeID === this.clonedList[i].ErrorCodeID)
            if (Object.entries(this.clonedList[i]).toString() !== Object.entries(this.dataSourceDescription.data[index]).toString()) {
                this.http
                    .post<ErrorCode>(
                        this.baseUrl + Utils.getErrorCodeAPI() + "translate"
                        , this.dataSourceDescription.data[index]
                    )
                    .subscribe(
                        res => {
                            console.log("Updated")
                            delete this.clonedList[this.clonedList[i].ErrorCodeID];
                            this.table.renderRows();
                            this.updatefilter();
                            this.onUpdateDescriptions.emit();
                        }
                        , error => {
                            Utils.httpGetErrorCode(
                                this.http
                                , this.baseUrl
                                , this.dataSourceDescription.data[index].ErrorCodeID
                                , this
                                , function (tthis: any, value: ErrorCode) {
                                    console.log("Rollback")
                                }
                            );
                            this.dialog.showErrorDialog(error);
                        }
                    );
            }
            else {
                delete this.clonedList[i];
            }
        }
        for (let i in this.clonedResolutionDescriptionList) {
            const index = this.dataSourceDescription.data.findIndex(x => x.ErrorCodeID === this.clonedResolutionDescriptionList[i].ErrorCodeID)
            const resolIndex = this.dataSourceDescription.data[index].ErrorCodeResolutions.findIndex(x => x.PossibleFailureID === this.clonedResolutionDescriptionList[i].PossibleFailureID)
            if (Object.entries(this.clonedResolutionDescriptionList[i]).filter(([key]) => key !== 'components').toString() !==
                Object.entries(this.dataSourceDescription.data[index].ErrorCodeResolutions[resolIndex]).filter(([key]) => key !== 'components').toString()) {
                this.http
                    .post<ErrorCodeResolution>(
                        this.baseUrl + Utils.getErrorCodeAPI() + "translatepossiblefailure"
                        , this.dataSourceDescription.data[index].ErrorCodeResolutions[resolIndex]
                    )
                    .subscribe(res => {
                        console.log("Updated")
                        delete this.clonedResolutionDescriptionList[this.clonedResolutionDescriptionList[i].PossibleFailureID];
                    }
                        , error => {
                            console.log(error)
                            Utils.httpGetPossibleFailure(
                                this.http
                                , this.baseUrl
                                , this.dataSourceDescription.data[index].ErrorCodeResolutions[resolIndex].PossibleFailureID
                                , this
                                , function (tthis: ErrorCodeListDescriptionComponent, value: ErrorCodeResolution) {
                                    tthis.dataSourceDescription.data[index].ErrorCodeResolutions[resolIndex] = value;
                                }
                            );

                            this.dialog.showErrorDialog(error);
                        }
                    );
            }
            else {
                delete this.clonedResolutionDescriptionList[i];
            }
        }
    }

    applyFilter(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            this.searchItems.push({ name: value.replace('+', '').trim() });
        }
        // Reset the input value
        if (input) {
            input.value = '';
        }
        this.updatefilter()
    }


    remove(item: SearchItem): void {
        const index = this.searchItems.indexOf(item);
        if (index >= 0) {
            this.searchItems.splice(index, 1);
        }
        this.updatefilter()
    }
    updatefilter() {
        let filterString = ''
        if (this.searchItems.length === 0) {
            filterString = ''
        }
        else {
            filterString = this.searchItems.map(e => { return e.name }).join('+')
        }
        this.dataSourceDescription.filter = filterString
    }
}

export interface SearchItem {
    name: string;
}