import { Component, Inject, OnInit, Output } from '@angular/core';

@Component({
    selector: "mersy-sharepoint",
    templateUrl: './mersy-sharepoint.component.html',
    styleUrls: [],

})

export class MersySharepointComponent implements OnInit {
    constructor(
    ) {
       
    }

    ngOnInit() {

    }
   
}
