<h3 *ngIf="newRow" mat-dialog-title>{{title}}</h3>
<mat-dialog-content id="maintenancewarningform">
    <form [formGroup]="form">

        <table>
            <tbody class="inner">
                <tr>
                    <td>&nbsp;</td>
                    <td style="width:250px;">
                        <mat-form-field>
                            <mat-label>{{header[TYPE].label}}</mat-label>
                            <input matInput type="text" name="Type" required formControlName="Type"
                                   title="{{header[TYPE].label}}"
                                   [ngClass]="classname[TYPE]">
                        </mat-form-field>
                    </td>
                    <td style="width:250px;">
                        <mat-form-field>
                            <mat-label>{{header[DESCRIPTION].label}}</mat-label>
                            <textarea matInput type="text" name="Description" required formControlName="Description"
                                   title="{{header[DESCRIPTION].label}}"
                                   [ngClass]="classname[DESCRIPTION]">
                            </textarea>
                        </mat-form-field>
                    </td>
                </tr>
            </tbody>
        </table>

        <div mat-dialog-actions *ngIf="newRow" class="mt-2">
            <button mat-raised-button [disabled]="!form.valid" (click)="onSubmit()">Save</button> <!--[mat-dialog-close]="1"-->
            <button mat-button (click)="onCancelForm()" tabindex="-1">Cancel</button>
        </div>
    </form>
</mat-dialog-content>
